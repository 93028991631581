import { useTranslation } from 'react-i18next';
import {
  H3,
  Modal,
  ModalHeader,
  ModalTitle,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent } from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import {
  SearchHelpModalContent,
  SearchHelpModalExampleString,
  SearchHelpModalSection,
  SearchHelpModalSectionLabel,
} from './SearchHelpModel.styles';

interface SearchHelpModalProps {
  onHide: () => void;
}

export const SearchHelpModal: FunctionComponent<SearchHelpModalProps> = ({
  onHide,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onHide={onHide}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.SearchBarHelpModalTitle)}
        </ModalTitle>
      </ModalHeader>
      <SearchHelpModalContent>
        <SearchHelpModalSection>
          <SearchHelpModalSectionLabel>
            {t(MessageKeys.SearchBarHelpModalUnquotedTextLabel)}
          </SearchHelpModalSectionLabel>
          {t(MessageKeys.SearchBarHelpModalUnquotedTextDescription)}
        </SearchHelpModalSection>
        <SearchHelpModalSection>
          <SearchHelpModalSectionLabel>
            {t(MessageKeys.SearchBarHelpModalQuotedTextLabel)}
          </SearchHelpModalSectionLabel>
          {t(MessageKeys.SearchBarHelpModalQuotedTextDescription)}
        </SearchHelpModalSection>
        <SearchHelpModalSection>
          <SearchHelpModalSectionLabel>
            {t(MessageKeys.SearchBarHelpModalOrTextLabel)}
          </SearchHelpModalSectionLabel>
          {t(MessageKeys.SearchBarHelpModalOrTextDescription)}
        </SearchHelpModalSection>
        <SearchHelpModalSection>
          <SearchHelpModalSectionLabel>
            {t(MessageKeys.SearchBarHelpModalExampleTextLabel)}
          </SearchHelpModalSectionLabel>
          <SearchHelpModalExampleString>
            {t(MessageKeys.SearchBarHelpModalExample1Label)}
          </SearchHelpModalExampleString>
          {t(MessageKeys.SearchBarHelpModalExample1Description)}
        </SearchHelpModalSection>
        <SearchHelpModalSection>
          {t(MessageKeys.SearchBarHelpModalFieldsDescription)}
        </SearchHelpModalSection>
        <H3>
          {t(MessageKeys.FeedArticleFilterAdvancedKeywordDialogHeading)}
        </H3>
        <SearchHelpModalSection>
          {t(MessageKeys.SearchBarHelpModalAdvancedSearchDescription)}
        </SearchHelpModalSection>
      </SearchHelpModalContent>
    </Modal>
  );
};
