import {
  DropdownButton,
  FoldingNotification,
  MenuItem,
  mobile,
  SecondaryButton,
  TextArea,
} from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const CommentFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;

export const CommentFieldActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 100%;
  margin-top: 0.5rem;
  flex-wrap: wrap;
`;

export const CommentFieldSubmitButton = styled(DropdownButton)`
  width: 15rem;
  margin-left: 0.4rem;
`;

export const CommentFieldCancelButton = styled(SecondaryButton)`
  width: 10rem;
`;

export const CommentFieldEditDescription = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  margin-bottom: 0.3rem;
  background-color: white;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 1rem;
  font-size: 1.2rem;
  max-height: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 244, 222, 1);
`;

export const CommentFieldEditDescriptionHeading = styled.div`
  font-weight: 600;
  margin-bottom: 1.4rem;
  font-size: 1.4rem;
`;

export const CommentTextFieldWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 10rem;

    & .mention.allowed-mention {
      color: ${theme.colors.info.dark};
      font-weight: 600;
      position: relative;
    }
  `}
`;

export const CommentFieldTextArea = styled(TextArea)`
  width: 100%;
  position: relative;
  z-index: 2;
  background: transparent;
`;

export const CommentTextFieldMemberMenuWrapper = styled.div`
  position: absolute;
  z-index: 20;
  transform: translateY(-102%);
  min-width: 30rem;
  max-width: 90vw;
`;

export const CommentHint = styled.div`
  margin: 0 1rem 1rem 0;
  font-size: 1.2rem;
  flex: 1;
  min-width: 50%;
  color: rgba(0, 0, 0, 0.7);

  ${mobile(
    () => css`
      min-width: 100%;
      text-align: right;
    `,
  )};
`;

export const CommentTextFieldMemberMenuItemWrapper = styled(MenuItem)`
  padding: 0;
`;

export const CommentFieldFoldingNotification = styled(FoldingNotification)`
  position: absolute;
  right: 0;
  bottom: 100%;
  margin-bottom: 0.4rem;
`;
