import { Showcase, ShowcaseReel } from '@fcg-tech/regtech-components';
import { format } from 'date-fns';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeShowcase, Translator } from '../../types';
import { ShowCaseContent } from './ShowCaseContent';
import { useShowcase } from './useShowcase';

export const WhatsNewAugustShowcase: FunctionComponent = () => {
  const { t } = useTranslation();
  const [display, handleExit] = useShowcase(RegeyeShowcase.WhatsNewAugust2022, {
    requireShowcaseCompleted: [
      RegeyeShowcase.GenericFeedArticleTable,
      RegeyeShowcase.NewUser,
    ],
    dontShowAfter: new Date('2022-09-15Z'),
  });

  return display ? (
    <Showcase reel={WhatsNewAugustShowcaseReel(t)} onExit={handleExit} />
  ) : null;
};

const contentStyle = { width: 940 };

const WhatsNewAugustShowcaseReel = (t: Translator): ShowcaseReel => ({
  id: RegeyeShowcase.WhatsNewAugust2022,
  skippable: true,
  waitForQuerySelector: '[data-kind="sidebar-logo"]',
  entries: [
    {
      content: (entry) => (
        <ShowCaseContent
          entry={entry}
          heading={t(MessageKeys.ShowCaseWhatsNewMonthHeading, {
            month: format(new Date('2022-08-15'), 'MMMM'),
          })}
          content={t(MessageKeys.ShowCaseWhatsNewAugustBody)}
        />
      ),
      contentStyle,
      illustrationStyle: { backgroundSize: 'contain', paddingBottom: '46.5%' },
      illustrationSrc: 'assets/showcase/whatsnewaugust/whatsnew-august.jpg',
    },
  ],
});
