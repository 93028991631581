import { Trans, useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  CheckIcon,
  DashboardCardTitleIconWrapper,
  DashboardHeaderNavIconButton,
  InboxIcon,
  UsersIcon,
} from '@fcg-tech/regtech-components';
import { stringifyUrlFilterValues } from '@fcg-tech/regtech-filter';
import { FunctionComponent, useMemo } from 'react';
import {
  useCurrentUser,
  useSettings,
  useTeamDashboard,
} from '../../../api/hooks';
import { TeamDashboardData, User } from '../../../api/schema';
import { routes } from '../../../routes';
import { MessageKeys } from '../../../translations/translationTypes';
import { FilterValues } from '../../../types';
import {
  DashboardCardBodyInner,
  DashboardCardCountWrapper,
  DashboardCardListItem,
  DashboardCardListItemIconWrapper,
  DashboardCardListItemText,
  DashboardCardListWrapper,
  DashboardCardNoContent,
  DashboardCardNoContentIconWrapper,
  DashboardCardRowWrapper,
  DashboardCardTitleWrapper,
  RegeyeDashboardCard as DashboardCard,
} from '../Dashboard.styles';

export const DashboardAssignedOverdueTeamCards: FunctionComponent = () => {
  const { data: settings } = useSettings();
  const { data } = useTeamDashboard({ suspense: false });
  const { data: user } = useCurrentUser();

  const hiddenTeams = useMemo(() => {
    const hiddenTeams = new Set();
    settings.teams?.forEach((team) => {
      if (team.hidden) {
        hiddenTeams.add(team.id);
      }
    });
    settings.companies?.forEach((company) => {
      if (company.hidden) {
        data?.items?.forEach((item) => {
          if (item.team.company?.id === company.id) {
            hiddenTeams.add(item.team.id);
          }
        });
      }
    });
    return hiddenTeams;
  }, [data?.items, settings.companies, settings.teams]);

  return (
    <>
      {data?.items
        ?.filter((item) => !hiddenTeams.has(item.team.id))
        .map((teamDashboardData) => (
          <DashboardAssignedOverdueCard
            key={teamDashboardData.team.id}
            user={user}
            teamDashboardData={teamDashboardData}
          />
        ))}
    </>
  );
};

interface DashboardAssignedOverdueCardBodyProps {
  user: User;
  teamDashboardData: TeamDashboardData;
}

const DashboardAssignedOverdueCard: FunctionComponent<
  DashboardAssignedOverdueCardBodyProps
> = ({ teamDashboardData, user }) => {
  const { t } = useTranslation();
  const [url, key] = useMemo<
    [url: string | undefined, key: string | undefined]
  >(() => {
    if (teamDashboardData?.overdueArticles?.length && teamDashboardData?.team) {
      return [
        constructUrl(
          routes.teamActions,
          { teamId: teamDashboardData.team.id },
          stringifyUrlFilterValues<FilterValues>({
            dueDate: {
              to: new Date(),
            },
          }),
        ),
        MessageKeys.DashboardViewAllOverdue,
      ];
    }
    if (
      teamDashboardData?.assignedArticles?.length &&
      teamDashboardData?.team
    ) {
      return [
        constructUrl(
          routes.teamActions,
          {
            teamId: teamDashboardData.team.id,
          },
          stringifyUrlFilterValues<FilterValues>({
            assignees: [user.username],
          }),
        ),
        MessageKeys.DashboardViewAllAssigned,
      ];
    }
    return [
      constructUrl(routes.teamSubscriptions, {
        teamId: teamDashboardData.team.id,
      }),
      MessageKeys.DashboardViewAllArticles,
    ];
  }, [
    teamDashboardData?.assignedArticles?.length,
    teamDashboardData?.overdueArticles?.length,
    teamDashboardData?.team,
    user.username,
  ]);
  return (
    <DashboardCard
      title={
        <>
          <DashboardCardTitleIconWrapper>
            <UsersIcon size="14" />
          </DashboardCardTitleIconWrapper>
          <DashboardCardTitleWrapper title={teamDashboardData.team.name}>
            {teamDashboardData.team.name}
          </DashboardCardTitleWrapper>
        </>
      }
      headerButton={
        url && key ? (
          <DashboardHeaderNavIconButton to={url}>
            {t(key)}
          </DashboardHeaderNavIconButton>
        ) : null
      }
    >
      <DashboardCardRowWrapper>
        <DashboardCardBodyInner>
          {teamDashboardData.overdueArticles?.length ? (
            <>
              <DashboardCardCountWrapper>
                <Trans
                  i18nKey={MessageKeys.DashboardOverdueCount as string}
                  values={{
                    countStr: teamDashboardData.overdueArticles?.length,
                  }}
                  components={{
                    count: <span className="count" />,
                  }}
                />
              </DashboardCardCountWrapper>
              <DashboardCardListWrapper>
                {teamDashboardData.overdueArticles.slice(0, 6).map((item) => (
                  <DashboardCardListItem key={item.id}>
                    <DashboardCardListItemIconWrapper>
                      <InboxIcon size="14" />
                    </DashboardCardListItemIconWrapper>
                    <DashboardCardListItemText
                      to={constructUrl(
                        routes.feedArticleById,
                        {
                          articleId: item.id,
                        },
                        {
                          teamId: teamDashboardData.team.id,
                        },
                      )}
                      title={item.title}
                    >
                      {item.title}
                    </DashboardCardListItemText>
                  </DashboardCardListItem>
                ))}
                {teamDashboardData.overdueArticles.length > 6 ? (
                  <DashboardCardListItem>
                    {t(MessageKeys.DashboardAndCountMoreArticles, {
                      count: teamDashboardData.overdueArticles.length - 6,
                    })}
                  </DashboardCardListItem>
                ) : null}
              </DashboardCardListWrapper>
            </>
          ) : (
            <DashboardCardNoContent>
              <DashboardCardNoContentIconWrapper>
                <CheckIcon size="18" />
              </DashboardCardNoContentIconWrapper>
              {t(MessageKeys.DashboardNoOverdue)}
            </DashboardCardNoContent>
          )}
        </DashboardCardBodyInner>
        <DashboardCardBodyInner>
          {teamDashboardData?.assignedArticles?.length ? (
            <>
              <DashboardCardCountWrapper>
                <Trans
                  i18nKey={MessageKeys.DashboardAssignedCount as string}
                  values={{
                    countStr: teamDashboardData.assignedArticles?.length,
                  }}
                  components={{
                    count: <span className="count" />,
                  }}
                />
              </DashboardCardCountWrapper>
              <DashboardCardListWrapper>
                {teamDashboardData.assignedArticles.slice(0, 6).map((item) => (
                  <DashboardCardListItem key={item.id}>
                    <DashboardCardListItemIconWrapper>
                      <InboxIcon size="14" />
                    </DashboardCardListItemIconWrapper>
                    <DashboardCardListItemText
                      to={constructUrl(
                        routes.feedArticleById,
                        {
                          articleId: item.id,
                        },
                        {
                          teamId: teamDashboardData.team.id,
                        },
                      )}
                      title={item.title}
                    >
                      {item.title}
                    </DashboardCardListItemText>
                  </DashboardCardListItem>
                ))}
                {teamDashboardData.assignedArticles.length > 6 ? (
                  <DashboardCardListItem>
                    {t(MessageKeys.DashboardAndCountMoreArticles, {
                      count: teamDashboardData.assignedArticles.length - 6,
                    })}
                  </DashboardCardListItem>
                ) : null}
              </DashboardCardListWrapper>
            </>
          ) : (
            <DashboardCardNoContent>
              <DashboardCardNoContentIconWrapper>
                <CheckIcon size="18" />
              </DashboardCardNoContentIconWrapper>
              {t(MessageKeys.DashboardNoAssigned)}
            </DashboardCardNoContent>
          )}
        </DashboardCardBodyInner>
      </DashboardCardRowWrapper>
    </DashboardCard>
  );
};
