import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  ConfirmDialog,
  PrimaryButton,
  useConfirmDialog,
  useFlash,
  useToggle,
} from '@fcg-tech/regtech-components';
import { MessageLevel } from '@fcg-tech/regtech-types';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { NameClashError } from '../../api/apiErrors';
import {
  useCurrentUser,
  useSettings,
  useTeam,
  useTeamInvitationActions,
  useTeamInvitations,
  useTeamMemberActions,
} from '../../api/hooks';
import { TeamActions } from '../../api/schema';
import { AccessControl } from '../../components/AccessControl';
import { useIamContext } from '../../components/IamContext';
import {
  TeamInviteMemberModal,
  TeamMemberInvitationsTable,
  TeamMemberTable,
} from '../../components/TeamMemberAdministrationList';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import {
  canTeamInvitationDelete,
  canTeamMemberDelete,
} from '../../utils/iamHelpers';
import { userSorter } from '../../utils/sorters';
import {
  TeamSettingsMemberDetailsInvitationsHeading,
  TeamSettingsTabButtonBar,
} from './TeamSettingsPage.styles';
import { TeamSettingsTabTopPaddedWrapper } from './TeamSettingsSubscriptionsTab.styles';

interface TeamSettingsMembersTabProps {
  teamId: string;
}

export const TeamSettingsMembersTab: FunctionComponent<
  TeamSettingsMembersTabProps
> = ({ teamId }) => {
  const navigate = useNavigate();
  const { data: user } = useCurrentUser();
  const { data: team } = useTeam(teamId);
  const { data: invitations } = useTeamInvitations(teamId);

  const { t } = useTranslation();
  const addFlash = useFlash();
  const { removeMemberFromTeam, leaveTeam } = useTeamMemberActions(teamId);
  const { createTeamInvitation, removeTeamInvitation } =
    useTeamInvitationActions(teamId);
  const { data: settings } = useSettings({ suspense: false });

  const sortedMembers = useMemo(
    () => team?.members?.slice().sort(userSorter(settings)),
    [team?.members, settings],
  );

  const iamContext = useIamContext();

  const [showRemoveConfirm, handleRemoveConfirm, confirmRemoveMember] =
    useConfirmDialog();

  const [showConfirmLeaveTeamDialog, handleConfirmLeaveTeam, confirmLeaveTeam] =
    useConfirmDialog();

  const isMember = team.members.find(
    (member) => member.username === user.username,
  );

  const handleRemoveMember = useCallback(
    async (userId: string) => {
      try {
        if (await confirmRemoveMember()) {
          await removeMemberFromTeam(userId);
          addFlash({
            level: MessageLevel.Success,
            content: t(
              MessageKeys.TeamSettingsTabMembersRemoveMemberSuccessful,
              { name: team.name },
            ),
            timeoutMS: 5000,
          });

          if (userId === user?.username) {
            navigate('/');
          }
        }
      } catch (e) {
        addFlash({
          level: MessageLevel.Error,
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    },
    [
      confirmRemoveMember,
      removeMemberFromTeam,
      addFlash,
      t,
      team.name,
      user?.username,
      navigate,
    ],
  );

  const canUnInvite = canTeamInvitationDelete(team.id, iamContext);
  const canRemove = canTeamMemberDelete(team.id, iamContext);

  const handleRemoveInvitation = useCallback(
    async (invitationId: string) => {
      try {
        await removeTeamInvitation(invitationId);
        addFlash({
          level: MessageLevel.Success,
          content: t(
            MessageKeys.TeamSettingsTabMembersRemoveInvitationSuccessful,
          ),
          timeoutMS: 5000,
        });
      } catch (e) {
        addFlash({
          level: MessageLevel.Error,
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    },
    [addFlash, removeTeamInvitation, t],
  );

  const [showInviteModal, setShowInviteModal, handleToggleInviteModal] =
    useToggle(false);

  const [isInviting, setIsInviting] = useState(false);

  const handleInviteMember = useCallback(
    async (emails: Array<string>, message: string) => {
      setShowInviteModal(false);
      setIsInviting(true);
      try {
        const result = await createTeamInvitation(emails, message);
        addFlash({
          level: 'info',
          timeoutMS: 7500,
          content: t(
            !result.failed?.length
              ? MessageKeys.TeamSettingsTabMembersInviteMemberSuccessful
              : result.failed?.length && result.succeed?.length
              ? MessageKeys.TeamSettingsTabMembersInviteMemberPartiallySuccessful
              : MessageKeys.TeamSettingsTabMembersInviteMemberFailure,
            { name: result.failed?.join(', ') ?? '(N/A)' },
          ),
        });
      } catch (e) {
        if (e instanceof NameClashError) {
          addFlash({
            level: MessageLevel.Error,
            timeoutMS: 8000,
            content: t(
              MessageKeys.TeamSettingsTabMembersInviteMemberPartiallySuccessful,
              { name: emails },
            ),
          });
        } else {
          addFlash({
            level: MessageLevel.Error,
            content: t(MessageKeys.LabelCouldNotSave),
          });
        }
      } finally {
        setIsInviting(false);
      }
    },
    [addFlash, createTeamInvitation, setShowInviteModal, t],
  );

  const handleLeaveTeamClick = useCallback(async () => {
    const confirmed = await confirmLeaveTeam();
    if (confirmed) {
      try {
        setIsInviting(true);
        await leaveTeam();
        setIsInviting(false);
        addFlash({
          level: MessageLevel.Success,
          content: t(
            MessageKeys.AdministrationTeamMembersLeaveTeamSuccessMessage,
            { name: team.name },
          ),
          timeoutMS: 5000,
        });
        navigate(constructUrl(routes.dashboard));
      } catch (e) {
        addFlash({
          level: MessageLevel.Error,
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    }
  }, [addFlash, confirmLeaveTeam, leaveTeam, navigate, t, team.name]);

  return (
    <TeamSettingsTabTopPaddedWrapper>
      {showConfirmLeaveTeamDialog ? (
        <ConfirmDialog
          title={t(MessageKeys.AdministrationTeamMembersLeaveTeamButtonLabel)}
          body={t(MessageKeys.AdministrationTeamMembersLeaveTeamConfirmLabel, {
            name: team.name,
          })}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleConfirmLeaveTeam}
        />
      ) : null}
      {showInviteModal ? (
        <TeamInviteMemberModal
          onCancel={handleToggleInviteModal}
          onConfirm={handleInviteMember}
        />
      ) : null}
      {showRemoveConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirmAction)}
          body={t(MessageKeys.TeamSettingsTabMembersRemoveMemberConfirmLabel)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleRemoveConfirm}
        />
      ) : null}
      <TeamSettingsTabButtonBar>
        {isMember ? (
          <AccessControl requiredPermissions={[TeamActions.TeamLeave]}>
            <PrimaryButton onClick={handleLeaveTeamClick} loading={isInviting}>
              {t(MessageKeys.AdministrationTeamMembersLeaveTeamButtonLabel)}
            </PrimaryButton>
          </AccessControl>
        ) : null}
        <AccessControl requiredPermissions={[TeamActions.TeamInvitationCreate]}>
          <PrimaryButton onClick={handleToggleInviteModal} loading={isInviting}>
            {t(MessageKeys.TeamSettingsTabMembersInviteMember)}
          </PrimaryButton>
        </AccessControl>
      </TeamSettingsTabButtonBar>
      <AccessControl
        requiredPermissions={[TeamActions.MemberList]}
        noAccessMessageKey={MessageKeys.AccessErrorTeamMemberList}
      >
        <TeamMemberTable
          members={sortedMembers ?? []}
          onRemoveMember={canRemove ? handleRemoveMember : null}
        />
      </AccessControl>
      <AccessControl requiredPermissions={[TeamActions.TeamInvitationList]}>
        {invitations?.length ? (
          <>
            <TeamSettingsMemberDetailsInvitationsHeading>
              {t(MessageKeys.AdministrationTeamMembersInvitationsHeading)}
            </TeamSettingsMemberDetailsInvitationsHeading>
            <TeamMemberInvitationsTable
              invitations={invitations}
              onRemoveInvitation={canUnInvite ? handleRemoveInvitation : null}
            />
          </>
        ) : null}
      </AccessControl>
    </TeamSettingsTabTopPaddedWrapper>
  );
};
