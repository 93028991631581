import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useCallback } from 'react';
import { useTeamActivitiesForArticleActions } from '../../api/hooks';
import { TeamActions, TeamArticleMetadata } from '../../api/schema';
import { MessageKeys } from '../../translations/translationTypes';
import { useAccessControl } from '../AccessControl';
import { ImportanceSelector } from '../ImportanceSelector';
import {
  FeedArticleTeamMetadataContentImportanceWrapper,
  FeedArticleTeamMetadataContentSectionRow,
  FeedArticleTeamMetadataContentSectionRowLabel,
} from './FeedArticleTeamMetadata.styles';

interface FeedArticleTeamMetadataImportanceProps {
  articleId: string;
  metadata: TeamArticleMetadata;
  teamId: string;
  disabled?: boolean;
  onMutated?: () => void;
}

export const FeedArticleTeamMetadataImportance: FunctionComponent<
  FeedArticleTeamMetadataImportanceProps
> = ({ articleId, metadata, teamId, disabled, onMutated }) => {
  const { t } = useTranslation();
  const { setImportance } = useTeamActivitiesForArticleActions(
    teamId,
    articleId,
  );

  const handleImportanceChange = useCallback(
    async (importances: Array<number>) => {
      await setImportance(importances?.[0] ?? -1);
      onMutated?.();
    },
    [onMutated, setImportance],
  );

  const changeAllowed = useAccessControl(TeamActions.ArticleImportanceChange);

  return (
    <FeedArticleTeamMetadataContentSectionRow>
      <FeedArticleTeamMetadataContentSectionRowLabel>
        {t(MessageKeys.ArticleMetadataImportance)}
      </FeedArticleTeamMetadataContentSectionRowLabel>
      <FeedArticleTeamMetadataContentImportanceWrapper>
        <ImportanceSelector
          currentImportance={[metadata?.importance?.importance]}
          disabled={disabled || !changeAllowed}
          withExplanation
          onChange={changeAllowed ? handleImportanceChange : null}
        />
      </FeedArticleTeamMetadataContentImportanceWrapper>
    </FeedArticleTeamMetadataContentSectionRow>
  );
};
