import { mobile } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const ActionsArticlesDueDateCalendarPageWrapper = styled.div`
  border-top: 1px solid #ccc;
  padding: 0 1.6rem;
  display: flex;
  flex: 1 0 0;
  min-width: 0;
`;

export const ActionsArticlesDueDateCalendarWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1.6rem 1.6rem 3rem 0;
  min-width: 0;
  overflow: auto;

  ${mobile(
    () => css`
      padding-right: 0;
    `,
  )}
`;

export const ActionsArticlesDueDateCalendarLoadingWrapper = styled.div`
  overflow: hidden;
  display: flex;
  max-height: 100%;
  width: 100%;
  min-height: 70vh;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const ActionsArticlesDueDateCalendarInfoWrapper = styled.aside`
  background-color: #b3defc;
  padding: 0.6rem 1.4rem 0.6rem 0.6rem;
  border-radius: 900px/1000px;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  color: darkblue;
  margin-bottom: 1rem;

  & > * + * {
    margin-left: 0.6rem;
  }
`;

export const ActionsArticlesDueDateCalendarNoArticlesMessage = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
`;
