import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  Badge,
  GridLayoutMainTabPanelWrapper,
  GridLayoutToolbarWrapper,
  TabBarNavigation,
  TabBarNavigationItem,
  TabBarPanel,
  ToolbarItemWrapper,
  ToolbarRow,
  ToolbarWrapper,
  TooltipNext as Tooltip,
  useTabLinkState,
} from '@fcg-tech/regtech-components';
import { stringifyUrlFilterValues } from '@fcg-tech/regtech-filter';
import {
  FilterValues,
  GlobalActions,
  PersonalDomain,
  TeamAction,
} from '@fcg-tech/regtech-types/regeye';
import {
  Bookmark,
  Inbox,
  Layers,
  MessageSquare,
  Settings,
  UserCheck,
} from 'lucide-react';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import {
  useAssignedToMeCount,
  useCurrentUser,
  useInvitations,
} from '../../api/hooks';
import { useAccessControl } from '../../components/AccessControl';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { FeedArticleTableContextProvider } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { getTableTypeByPath } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { FeedArticleTableTopActionBar } from '../../components/FeedArticleTable/FeedArticleTableTopActionBar';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routeFragments, routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { FeedArticleDomain } from '../../types';

export const PersonalPageOutlet: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tabState = useTabLinkState();
  const navigate = useNavigate();

  const { data: user } = useCurrentUser();
  const { data: assignedCount = 0 } = useAssignedToMeCount({ suspense: false });
  const { data: invitations } = useInvitations({ suspense: false });

  const { tableType, tableSubType } = useMemo(
    () => getTableTypeByPath(location.pathname) ?? {},
    [location.pathname],
  );

  const canArticleList = useAccessControl(GlobalActions.ArticleList);

  const canGetAllTeamActions = useAccessControl(
    GlobalActions.GetAllTeamActions,
  );
  const canGetUserActivityLogArticles = useAccessControl(
    GlobalActions.GetUserActivityLogArticles,
  );

  const onBaseRoute = useMatch(routes.personal);
  const profileMatch = useMatch(routes.personalProfile);
  const allArticlesMatch = useMatch(routes.personalAllTeamSubscriptions);
  const commentedByMeMatch = useMatch(routes.personalCommentedByMe);
  const assignedToMeMatch = useMatch(routes.personalAssignedToMe);
  const bookmarkedMatch = useMatch(routes.personalBookmarked);
  const actionLogMatch = useMatch(routes.personalEventLog);

  const personalDomain = useMemo<PersonalDomain>(() => {
    if (commentedByMeMatch) {
      return PersonalDomain.Commented;
    }
    if (assignedToMeMatch) {
      return PersonalDomain.Assigned;
    }
    if (bookmarkedMatch) {
      return PersonalDomain.Bookmarked;
    }
    if (actionLogMatch) {
      return PersonalDomain.ActionLog;
    }
    if (allArticlesMatch) {
      return PersonalDomain.AllArticles;
    }
    return null;
  }, [
    commentedByMeMatch,
    assignedToMeMatch,
    bookmarkedMatch,
    allArticlesMatch,
    actionLogMatch,
  ]);

  useEffect(() => {
    if (onBaseRoute) {
      navigate(constructUrl(routes.personalAllTeamSubscriptions));
    } else if (profileMatch) {
      navigate(
        constructUrl(routes.personalProfileSetting, {
          settingKey: routeFragments.personalProfileTeams,
        }),
      );
    } else if (commentedByMeMatch) {
      navigate(
        constructUrl(
          routes.personalCommentedByMe,
          null,
          stringifyUrlFilterValues<FilterValues>({
            actions: [TeamAction.Commented],
            commentedBy: [user.username],
          }),
        ),
      );
    } else if (assignedToMeMatch) {
      navigate(
        constructUrl(
          routes.personalAssignedToMe,
          null,
          stringifyUrlFilterValues<FilterValues>({
            actions: [TeamAction.Assigned],
            assignees: [user.username],
          }),
        ),
      );
    }
    // Only run on location change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const items = useMemo<Array<TabBarNavigationItem>>(() => {
    const items: Array<TabBarNavigationItem> = [
      canArticleList
        ? {
            label: (
              <ToolbarItemWrapper>
                <Inbox size="18" />
                {t(MessageKeys.ArticlesFromMyTeamsShort)}
              </ToolbarItemWrapper>
            ),
            to: constructUrl(routes.personalAllTeamSubscriptions),
          }
        : null,
      canArticleList
        ? {
            label: (
              <ToolbarItemWrapper>
                <Bookmark size="18" />
                {t(MessageKeys.ArticleBookmarkedFeed)}
              </ToolbarItemWrapper>
            ),
            to: constructUrl(routes.personalBookmarked),
          }
        : null,
      canGetAllTeamActions
        ? {
            label: (
              <ToolbarItemWrapper>
                <UserCheck size="18" />
                {t(MessageKeys.ArticleAssignedToMeFeed)}
                {assignedCount > 0 ? (
                  <Tooltip
                    placement="bottom"
                    content={t(MessageKeys.SideMenuAssignedCountBadgeTooltip, {
                      count: assignedCount,
                    })}
                  >
                    <Badge className="row-item-margin-left">
                      {assignedCount}
                    </Badge>
                  </Tooltip>
                ) : null}
              </ToolbarItemWrapper>
            ),
            to: constructUrl(
              routes.personalAssignedToMe,
              null,
              stringifyUrlFilterValues<FilterValues>({
                actions: [TeamAction.Assigned],
                assignees: [user.username],
              }),
            ),
            selectedForActiveSubPaths: true,
          }
        : null,
      canGetAllTeamActions
        ? {
            label: (
              <ToolbarItemWrapper>
                <MessageSquare size="18" />
                {t(MessageKeys.ArticleCommentedByMeFeed)}
              </ToolbarItemWrapper>
            ),
            to: constructUrl(
              routes.personalCommentedByMe,
              null,
              stringifyUrlFilterValues<FilterValues>({
                actions: [TeamAction.Commented],
                commentedBy: [user.username],
              }),
            ),
            selectedForActiveSubPaths: true,
          }
        : null,
      canGetUserActivityLogArticles
        ? {
            label: (
              <ToolbarItemWrapper>
                <Layers size="18" />
                {t(MessageKeys.SideMenuMyArticlesEventLog)}
              </ToolbarItemWrapper>
            ),
            to: constructUrl(routes.personalEventLog),
          }
        : null,
      {
        label: (
          <ToolbarItemWrapper>
            <Settings size="18" />
            {t(MessageKeys.MyProfileTabMySettingsLabel)}
            {invitations?.length ? (
              <Tooltip
                content={t(MessageKeys.SideMenuInvitationsBadgeTooltip, {
                  count: invitations?.length,
                })}
                placement="bottom"
              >
                <Badge className="row-item-margin-left">
                  {invitations.length}
                </Badge>
              </Tooltip>
            ) : null}
          </ToolbarItemWrapper>
        ),
        to: constructUrl(routes.personalProfile),
        selectedForActiveSubPaths: true,
      },
    ];
    return items.filter(Boolean);
  }, [
    assignedCount,
    canGetAllTeamActions,
    canGetUserActivityLogArticles,
    canArticleList,
    invitations.length,
    t,
    user.username,
  ]);
  const content = (
    <>
      <GridLayoutToolbarWrapper>
        <ToolbarWrapper>
          <ToolbarRow>
            <TabBarNavigation
              tabLinkState={tabState}
              tabBarAriaLabel="Team Toolbar"
              items={items}
            />
            {tableType ? (
              <ErrorBoundary>
                <SuspenseLoader silent>
                  <FeedArticleTableTopActionBar />
                </SuspenseLoader>
              </ErrorBoundary>
            ) : null}
          </ToolbarRow>
        </ToolbarWrapper>
      </GridLayoutToolbarWrapper>
      <ErrorBoundary>
        <GridLayoutMainTabPanelWrapper className="overflow-hidden">
          <TabBarPanel
            state={tabState}
            tabId={tabState.selectedId || undefined}
            className="tab-panel"
          >
            <SuspenseLoader>
              <Outlet />
            </SuspenseLoader>
          </TabBarPanel>
        </GridLayoutMainTabPanelWrapper>
      </ErrorBoundary>
    </>
  );

  return tableType ? (
    <FeedArticleTableContextProvider
      key={`${tableType}-${tableSubType}`}
      tableType={tableType}
      tableSubType={tableSubType}
      domain={FeedArticleDomain.Personal}
      personalDomain={personalDomain}
    >
      {content}
    </FeedArticleTableContextProvider>
  ) : (
    content
  );
};
