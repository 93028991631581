import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from '@fcg-tech/regtech-components';
import React, { FunctionComponent } from 'react';
import { components } from 'react-select';
import { User } from '../../api/schema';

import { MessageKeys } from '../../translations/translationTypes';
import {
  SelectArticleAssigneeOptionIcon,
  SelectArticleAssigneeOptionLabel,
  SelectArticleAssigneeOptionWrapper,
} from './SelectArticleAssigneeOption.styles';
import { classNames } from '@fcg-tech/regtech-utils';

type OptionsProps = React.ComponentPropsWithRef<typeof components.Option>;

export type UserWithReadStatus = User & { read: boolean };

export const SelectArticleAssigneeOption: FunctionComponent<OptionsProps> = ({
  ...props
}) => {
  const { t } = useTranslation();
  const read = (props.data as ReactSelectOption<UserWithReadStatus>)?.value
    ?.read;

  return (
    <components.Option {...props}>
      <SelectArticleAssigneeOptionWrapper
        title={t(
          read
            ? MessageKeys.ArticleMetadataAssignReadByUser
            : MessageKeys.ArticleMetadataAssignUnreadByUser,
          { name: props.label },
        )}
      >
        <SelectArticleAssigneeOptionIcon
          size="14"
          className={classNames(read && 'read')}
        />
        <SelectArticleAssigneeOptionLabel>
          {props.label}
        </SelectArticleAssigneeOptionLabel>
      </SelectArticleAssigneeOptionWrapper>
    </components.Option>
  );
};
