import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useState } from 'react';
import { useInvitations } from '../../../api/hooks';
import { MessageKeys } from '../../../translations/translationTypes';
import { TeamInvitation } from '../../TeamInvitation';
import { InvitationsSetupWizardItemWrapper } from '../SetupWizard.styles';

export const InvitationsSetupWizardInvitationList: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data } = useInvitations();
  const [invitations, setInvitations] = useState(data);
  return <>
    <p>
      {t(MessageKeys.WizardInvitationsIntro)}
    </p>
    {invitations.map((invitation) => (
      <InvitationsSetupWizardItemWrapper key={invitation.id}>
        <TeamInvitation invitation={invitation} narrow />
      </InvitationsSetupWizardItemWrapper>
    ))}
  </>;
};
