import { uploadToS3 } from '@fcg-tech/regtech-api-utils';
import { useMemo } from 'react';
import { useApi } from '../apiUtils';
import { TeamAttachmentsApi } from '../schema';

export const getFileUploadUrl = async (
  teamId: string,
  filename: string,
  api: TeamAttachmentsApi,
) => {
  const result = await api.createPresignedUploadUrlForTeam({
    teamId,
    fileInput: { filename },
  });
  return result;
};

export const getFileDownloadUrl = async (
  teamId: string,
  fileId: string,
  api: TeamAttachmentsApi,
) => {
  const result = await api.getPresignedFileUrlForTeam({ fileId, teamId });
  return result;
};

export const uploadAttachment = async (
  teamId: string,
  file: File,
  api: TeamAttachmentsApi,
) => {
  const uploadInfo = await getFileUploadUrl(teamId, file.name, api);
  await uploadToS3(uploadInfo.url, file);
  return uploadInfo;
};

export const deleteAttachment = async (
  teamId: string,
  fileId: string,
  api: TeamAttachmentsApi,
) => {
  await api.deleteFileForTeam({ fileId, teamId });
};

export const useAttachmentActions = (teamId: string) => {
  const api = useApi<TeamAttachmentsApi>('TeamAttachmentsApi');
  return useMemo(() => {
    return {
      getFileUploadUrl: async (filename: string) =>
        getFileUploadUrl(teamId, filename, api),
      getFileDownloadUrl: async (ref: string) =>
        getFileDownloadUrl(teamId, ref, api),
      uploadAttachment: async (file: File) =>
        uploadAttachment(teamId, file, api),
      deleteAttachment: async (ref: string) =>
        deleteAttachment(teamId, ref, api),
    };
  }, [teamId, api]);
};
