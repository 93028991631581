import React, { FunctionComponent } from 'react';

interface RegEyeTextProps {
  color?: string;
  className?: string;
}

export const RegEyeText: FunctionComponent<RegEyeTextProps> = ({
  color = 'currentColor',
  className,
}) => {
  return (
    <svg
      width="173"
      height="42"
      viewBox="0 0 173 42"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3281 32.0573L14.2531 23.2823H7.54806V32.0573H0.258057V0.557281H13.8931C16.6831 0.557281 19.0981 1.02228 21.1381 1.95228C23.2081 2.88228 24.7981 4.20228 25.9081 5.91228C27.0181 7.62228 27.5731 9.64728 27.5731 11.9873C27.5731 14.3273 27.0031 16.3523 25.8631 18.0623C24.7531 19.7423 23.1631 21.0323 21.0931 21.9323L28.1581 32.0573H20.3281ZM20.1931 11.9873C20.1931 10.2173 19.6231 8.86728 18.4831 7.93728C17.3431 6.97728 15.6781 6.49728 13.4881 6.49728H7.54806V17.4773H13.4881C15.6781 17.4773 17.3431 16.9973 18.4831 16.0373C19.6231 15.0773 20.1931 13.7273 20.1931 11.9873Z"
        fill={color}
      />
      <path
        d="M56.5689 20.0423C56.5689 20.1323 56.5239 20.7623 56.4339 21.9323H38.1189C38.4489 23.4323 39.2289 24.6173 40.4589 25.4873C41.6889 26.3573 43.2189 26.7923 45.0489 26.7923C46.3089 26.7923 47.4189 26.6123 48.3789 26.2523C49.3689 25.8623 50.2839 25.2623 51.1239 24.4523L54.8589 28.5023C52.5789 31.1123 49.2489 32.4173 44.8689 32.4173C42.1389 32.4173 39.7239 31.8923 37.6239 30.8423C35.5239 29.7623 33.9039 28.2773 32.7639 26.3873C31.6239 24.4973 31.0539 22.3523 31.0539 19.9523C31.0539 17.5823 31.6089 15.4523 32.7189 13.5623C33.8589 11.6423 35.4039 10.1573 37.3539 9.10728C39.3339 8.02728 41.5389 7.48728 43.9689 7.48728C46.3389 7.48728 48.4839 7.99728 50.4039 9.01728C52.3239 10.0373 53.8239 11.5073 54.9039 13.4273C56.0139 15.3173 56.5689 17.5223 56.5689 20.0423ZM44.0139 12.7973C42.4239 12.7973 41.0889 13.2473 40.0089 14.1473C38.9289 15.0473 38.2689 16.2773 38.0289 17.8373H49.9539C49.7139 16.3073 49.0539 15.0923 47.9739 14.1923C46.8939 13.2623 45.5739 12.7973 44.0139 12.7973Z"
        fill={color}
      />
      <path
        d="M86.2176 7.84728V28.0073C86.2176 32.4473 85.0625 35.7473 82.7525 37.9073C80.4425 40.0673 77.0675 41.1473 72.6275 41.1473C70.2875 41.1473 68.0676 40.8623 65.9676 40.2923C63.8676 39.7223 62.1275 38.8973 60.7475 37.8173L63.5375 32.7773C64.5575 33.6173 65.8475 34.2773 67.4075 34.7573C68.9675 35.2673 70.5275 35.5223 72.0875 35.5223C74.5175 35.5223 76.3026 34.9673 77.4426 33.8573C78.6126 32.7773 79.1975 31.1273 79.1975 28.9073V27.8723C77.3675 29.8823 74.8175 30.8873 71.5475 30.8873C69.3275 30.8873 67.2876 30.4073 65.4276 29.4473C63.5976 28.4573 62.1425 27.0773 61.0625 25.3073C59.9825 23.5373 59.4425 21.4973 59.4425 19.1873C59.4425 16.8773 59.9825 14.8373 61.0625 13.0673C62.1425 11.2973 63.5976 9.93228 65.4276 8.97228C67.2876 7.98228 69.3275 7.48728 71.5475 7.48728C75.0575 7.48728 77.7275 8.64228 79.5575 10.9523V7.84728H86.2176ZM72.9426 25.1273C74.8026 25.1273 76.3175 24.5873 77.4875 23.5073C78.6875 22.3973 79.2876 20.9573 79.2876 19.1873C79.2876 17.4173 78.6875 15.9923 77.4875 14.9123C76.3175 13.8023 74.8026 13.2473 72.9426 13.2473C71.0826 13.2473 69.5525 13.8023 68.3525 14.9123C67.1525 15.9923 66.5526 17.4173 66.5526 19.1873C66.5526 20.9573 67.1525 22.3973 68.3525 23.5073C69.5525 24.5873 71.0826 25.1273 72.9426 25.1273Z"
        fill={color}
      />
      <path
        d="M117.636 26.2073V32.0573H93.2463V0.557281H117.051V6.40728H100.491V13.2473H115.116V18.9173H100.491V26.2073H117.636Z"
        fill={color}
      />
      <path
        d="M146.752 7.84728L135.817 33.5423C134.707 36.3323 133.327 38.2973 131.677 39.4373C130.057 40.5773 128.092 41.1473 125.782 41.1473C124.522 41.1473 123.277 40.9523 122.047 40.5623C120.817 40.1723 119.812 39.6323 119.032 38.9423L121.597 33.9473C122.137 34.4273 122.752 34.8023 123.442 35.0723C124.162 35.3423 124.867 35.4773 125.557 35.4773C126.517 35.4773 127.297 35.2373 127.897 34.7573C128.497 34.3073 129.037 33.5423 129.517 32.4623L129.607 32.2373L119.122 7.84728H126.367L133.162 24.2723L140.002 7.84728H146.752Z"
        fill={color}
      />
      <path
        d="M172.672 20.0423C172.672 20.1323 172.627 20.7623 172.537 21.9323H154.222C154.552 23.4323 155.332 24.6173 156.562 25.4873C157.792 26.3573 159.322 26.7923 161.152 26.7923C162.412 26.7923 163.522 26.6123 164.482 26.2523C165.472 25.8623 166.387 25.2623 167.227 24.4523L170.962 28.5023C168.682 31.1123 165.352 32.4173 160.972 32.4173C158.242 32.4173 155.827 31.8923 153.727 30.8423C151.627 29.7623 150.007 28.2773 148.867 26.3873C147.727 24.4973 147.157 22.3523 147.157 19.9523C147.157 17.5823 147.712 15.4523 148.822 13.5623C149.962 11.6423 151.507 10.1573 153.457 9.10728C155.437 8.02728 157.642 7.48728 160.072 7.48728C162.442 7.48728 164.587 7.99728 166.507 9.01728C168.427 10.0373 169.927 11.5073 171.007 13.4273C172.117 15.3173 172.672 17.5223 172.672 20.0423ZM160.117 12.7973C158.527 12.7973 157.192 13.2473 156.112 14.1473C155.032 15.0473 154.372 16.2773 154.132 17.8373H166.057C165.817 16.3073 165.157 15.0923 164.077 14.1923C162.997 13.2623 161.677 12.7973 160.117 12.7973Z"
        fill={color}
      />
    </svg>
  );
};
