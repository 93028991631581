/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InvitationsCreate,
    InvitationsCreateFromJSON,
    InvitationsCreateToJSON,
    InvitationsCreateResult,
    InvitationsCreateResultFromJSON,
    InvitationsCreateResultToJSON,
    TeamInvitation,
    TeamInvitationFromJSON,
    TeamInvitationToJSON,
    TeamInvitationList,
    TeamInvitationListFromJSON,
    TeamInvitationListToJSON,
} from '../models';

export interface AcceptInvitationRequest {
    invitationId: string;
}

export interface CompanyCreateTeamInvitationRequest {
    companyId: string;
    teamId: string;
    invitationsCreate: InvitationsCreate;
}

export interface CompanyGetTeamInvitationRequest {
    companyId: string;
    teamId: string;
    invitationId: string;
}

export interface CompanyListTeamInvitationsRequest {
    companyId: string;
    teamId: string;
}

export interface CompanyRemoveInvitationToTeamRequest {
    companyId: string;
    teamId: string;
    invitationId: string;
}

export interface CreateTeamInvitationRequest {
    teamId: string;
    invitationsCreate: InvitationsCreate;
}

export interface GetTeamInvitationRequest {
    teamId: string;
    invitationId: string;
}

export interface ListTeamInvitationsRequest {
    teamId: string;
}

export interface RejectInvitationRequest {
    invitationId: string;
}

export interface RemoveInvitationToTeamRequest {
    teamId: string;
    invitationId: string;
}

/**
 * InvitationsApi - interface
 * 
 * @export
 * @interface InvitationsApiInterface
 */
export interface InvitationsApiInterface {
    /**
     * 
     * @summary accept invitation to team
     * @param {string} invitationId the invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    acceptInvitationRaw(requestParameters: AcceptInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>>;

    /**
     * accept invitation to team
     */
    acceptInvitation(requestParameters: AcceptInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList>;

    /**
     * 
     * @summary Create an invitation
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {InvitationsCreate} invitationsCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    companyCreateTeamInvitationRaw(requestParameters: CompanyCreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<InvitationsCreateResult>>;

    /**
     * Create an invitation
     */
    companyCreateTeamInvitation(requestParameters: CompanyCreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<InvitationsCreateResult>;

    /**
     * 
     * @summary Get team invitation
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {string} invitationId the invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    companyGetTeamInvitationRaw(requestParameters: CompanyGetTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitation>>;

    /**
     * Get team invitation
     */
    companyGetTeamInvitation(requestParameters: CompanyGetTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitation>;

    /**
     * 
     * @summary List invitations of team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    companyListTeamInvitationsRaw(requestParameters: CompanyListTeamInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>>;

    /**
     * List invitations of team
     */
    companyListTeamInvitations(requestParameters: CompanyListTeamInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList>;

    /**
     * 
     * @summary Remove invitation to team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {string} invitationId the invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    companyRemoveInvitationToTeamRaw(requestParameters: CompanyRemoveInvitationToTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>>;

    /**
     * Remove invitation to team
     */
    companyRemoveInvitationToTeam(requestParameters: CompanyRemoveInvitationToTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList>;

    /**
     * 
     * @summary Create an invitation
     * @param {string} teamId the team id
     * @param {InvitationsCreate} invitationsCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    createTeamInvitationRaw(requestParameters: CreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<InvitationsCreateResult>>;

    /**
     * Create an invitation
     */
    createTeamInvitation(requestParameters: CreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<InvitationsCreateResult>;

    /**
     * 
     * @summary List all invitations of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    getInvitationsForUserRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>>;

    /**
     * List all invitations of user
     */
    getInvitationsForUser(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList>;

    /**
     * 
     * @summary Get team invitation
     * @param {string} teamId the team id
     * @param {string} invitationId the invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    getTeamInvitationRaw(requestParameters: GetTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitation>>;

    /**
     * Get team invitation
     */
    getTeamInvitation(requestParameters: GetTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitation>;

    /**
     * 
     * @summary List invitations of team
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    listTeamInvitationsRaw(requestParameters: ListTeamInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>>;

    /**
     * List invitations of team
     */
    listTeamInvitations(requestParameters: ListTeamInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList>;

    /**
     * 
     * @summary Reject and remove invitation to team
     * @param {string} invitationId the invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    rejectInvitationRaw(requestParameters: RejectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>>;

    /**
     * Reject and remove invitation to team
     */
    rejectInvitation(requestParameters: RejectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList>;

    /**
     * 
     * @summary Remove invitation to team
     * @param {string} teamId the team id
     * @param {string} invitationId the invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApiInterface
     */
    removeInvitationToTeamRaw(requestParameters: RemoveInvitationToTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>>;

    /**
     * Remove invitation to team
     */
    removeInvitationToTeam(requestParameters: RemoveInvitationToTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList>;

}

/**
 * 
 */
export class InvitationsApi extends runtime.BaseAPI implements InvitationsApiInterface {

    /**
     * accept invitation to team
     */
    async acceptInvitationRaw(requestParameters: AcceptInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling acceptInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/invitations/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationListFromJSON(jsonValue));
    }

    /**
     * accept invitation to team
     */
    async acceptInvitation(requestParameters: AcceptInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList> {
        const response = await this.acceptInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an invitation
     */
    async companyCreateTeamInvitationRaw(requestParameters: CompanyCreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<InvitationsCreateResult>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyCreateTeamInvitation.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyCreateTeamInvitation.');
        }

        if (requestParameters.invitationsCreate === null || requestParameters.invitationsCreate === undefined) {
            throw new runtime.RequiredError('invitationsCreate','Required parameter requestParameters.invitationsCreate was null or undefined when calling companyCreateTeamInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/invitations`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvitationsCreateToJSON(requestParameters.invitationsCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationsCreateResultFromJSON(jsonValue));
    }

    /**
     * Create an invitation
     */
    async companyCreateTeamInvitation(requestParameters: CompanyCreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<InvitationsCreateResult> {
        const response = await this.companyCreateTeamInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get team invitation
     */
    async companyGetTeamInvitationRaw(requestParameters: CompanyGetTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitation>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyGetTeamInvitation.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyGetTeamInvitation.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling companyGetTeamInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/invitations/{invitationId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationFromJSON(jsonValue));
    }

    /**
     * Get team invitation
     */
    async companyGetTeamInvitation(requestParameters: CompanyGetTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitation> {
        const response = await this.companyGetTeamInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List invitations of team
     */
    async companyListTeamInvitationsRaw(requestParameters: CompanyListTeamInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyListTeamInvitations.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyListTeamInvitations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/invitations`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationListFromJSON(jsonValue));
    }

    /**
     * List invitations of team
     */
    async companyListTeamInvitations(requestParameters: CompanyListTeamInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList> {
        const response = await this.companyListTeamInvitationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove invitation to team
     */
    async companyRemoveInvitationToTeamRaw(requestParameters: CompanyRemoveInvitationToTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyRemoveInvitationToTeam.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyRemoveInvitationToTeam.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling companyRemoveInvitationToTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/invitations/{invitationId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationListFromJSON(jsonValue));
    }

    /**
     * Remove invitation to team
     */
    async companyRemoveInvitationToTeam(requestParameters: CompanyRemoveInvitationToTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList> {
        const response = await this.companyRemoveInvitationToTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an invitation
     */
    async createTeamInvitationRaw(requestParameters: CreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<InvitationsCreateResult>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling createTeamInvitation.');
        }

        if (requestParameters.invitationsCreate === null || requestParameters.invitationsCreate === undefined) {
            throw new runtime.RequiredError('invitationsCreate','Required parameter requestParameters.invitationsCreate was null or undefined when calling createTeamInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/teams/{teamId}/invitations`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvitationsCreateToJSON(requestParameters.invitationsCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationsCreateResultFromJSON(jsonValue));
    }

    /**
     * Create an invitation
     */
    async createTeamInvitation(requestParameters: CreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<InvitationsCreateResult> {
        const response = await this.createTeamInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all invitations of user
     */
    async getInvitationsForUserRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/invitations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationListFromJSON(jsonValue));
    }

    /**
     * List all invitations of user
     */
    async getInvitationsForUser(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList> {
        const response = await this.getInvitationsForUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get team invitation
     */
    async getTeamInvitationRaw(requestParameters: GetTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitation>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamInvitation.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling getTeamInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/invitations/{invitationId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationFromJSON(jsonValue));
    }

    /**
     * Get team invitation
     */
    async getTeamInvitation(requestParameters: GetTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitation> {
        const response = await this.getTeamInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List invitations of team
     */
    async listTeamInvitationsRaw(requestParameters: ListTeamInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling listTeamInvitations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/invitations`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationListFromJSON(jsonValue));
    }

    /**
     * List invitations of team
     */
    async listTeamInvitations(requestParameters: ListTeamInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList> {
        const response = await this.listTeamInvitationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject and remove invitation to team
     */
    async rejectInvitationRaw(requestParameters: RejectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling rejectInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/invitations/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationListFromJSON(jsonValue));
    }

    /**
     * Reject and remove invitation to team
     */
    async rejectInvitation(requestParameters: RejectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList> {
        const response = await this.rejectInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove invitation to team
     */
    async removeInvitationToTeamRaw(requestParameters: RemoveInvitationToTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamInvitationList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling removeInvitationToTeam.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling removeInvitationToTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/invitations/{invitationId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamInvitationListFromJSON(jsonValue));
    }

    /**
     * Remove invitation to team
     */
    async removeInvitationToTeam(requestParameters: RemoveInvitationToTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamInvitationList> {
        const response = await this.removeInvitationToTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
