import { classNames } from '@fcg-tech/regtech-utils';
import { TabState } from 'ariakit/ts/tab';
import { FunctionComponent } from 'react';
import { StyledTabLinkList } from './TabBarNavigation.styles';
import { TabLink } from './tabLink';

type Renderable = JSX.Element | string | (() => React.ReactNode);

export type TabBarNavigationItemBase = {
  label: string | Renderable;
  className?: string;
  selectedForActiveSubPaths?: boolean;
};

export type TabBarNavigationItem =
  | (TabBarNavigationItemBase & {
      id: string;
      to?: undefined;
      onClick?: (event: React.PointerEvent<HTMLDivElement>) => void;
    })
  | (TabBarNavigationItemBase & {
      to: string;
      id?: string;
      onClick?: never;
    });

interface TabBarNavigationProps {
  items: Array<TabBarNavigationItem>;
  tabLinkState: TabState;
  tabBarAriaLabel: string;
  vertical?: boolean;
  className?: string;
}

export const TabBarNavigation: FunctionComponent<TabBarNavigationProps> = ({
  items,
  tabBarAriaLabel,
  tabLinkState,
  vertical = false,
  className,
}) => {
  return (
    <StyledTabLinkList
      state={tabLinkState}
      className={classNames('tab-link-list', vertical && 'vertical', className)}
      aria-label={tabBarAriaLabel}
    >
      {items.map(
        ({ id, to, onClick, label, className, selectedForActiveSubPaths }) =>
          to ? (
            <TabLink
              key={id ?? to}
              id={id ?? to}
              to={to}
              selectedForActiveSubPaths={selectedForActiveSubPaths}
              className={classNames('tab-link-list__tab-item', className)}
            >
              {typeof label === 'function' ? label() : label}
            </TabLink>
          ) : (
            <div
              key={id}
              role={onClick ? 'button' : ''}
              className={classNames(
                'tab-link-list__tab-item',
                onClick ? '' : 'tab-link-list__tab-item--label',
                className,
              )}
              onClick={onClick}
            >
              {typeof label === 'function' ? label() : label}
            </div>
          ),
      )}
    </StyledTabLinkList>
  );
};
