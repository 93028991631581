import styled from 'styled-components';

export const Deemph = styled.span`
  font-size: 1rem;
  color: #555;
  &.with-margin {
    margin: 0.6rem 0;
  }
`;

export const SimpleList = styled.ul`
  margin: 0;
  padding: 0 0 0 2rem;
`;
