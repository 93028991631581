import { Element, Node } from 'domhandler';
import { getAttributeValue, isTag } from 'domutils';

export const hasClass = (className: string) => (node: Node) =>
  node &&
  isTag(node) &&
  Boolean(getAttributeValue(node, 'class') === className);

export const isTagName = (tagName: string) => (node: Element) =>
  node && isTag(node) && node.tagName.toLowerCase() === tagName.toLowerCase();

export const isAnchor = isTagName('a');
export const isImage = isTagName('img') || isTagName('image');

export const isHTMLElement = (node: unknown): node is HTMLElement =>
  node instanceof HTMLElement;
