import { css } from 'styled-components';

export const activityBlipp = css`
  ${({ theme }) => css`
    position: absolute;
    z-index: 0;
    content: ' ';
    top: -0.3rem;
    right: -0.7rem;
    width: 0.9rem;
    height: 0.9rem;
    background-color: ${theme?.colors?.attention?.main ??
    theme?.palette?.MainColor?.main ??
    'red'};
    border-radius: 50%;
  `}
`;
