import { useAuthContext } from '@fcg-tech/regtech-auth';
import { InitData } from '@fcg-tech/regtech-types/regeye';
import useSWR, { SWRConfiguration } from 'swr';
import { useApi } from '../apiUtils';
import { InitApi } from '../schema';
import { initDataKey } from './cacheKeys';

export const useInitData = (config: SWRConfiguration = {}) => {
  const authState = useAuthContext();
  const api = useApi<InitApi>('InitApi');

  return useSWR<InitData>(
    authState.isAuthenticated ? initDataKey() : null,
    () => api.getInitData(),
    authState.isAuthenticated
      ? {
          suspense: true,
          errorRetryCount: 0,
          dedupingInterval: 1000,
          revalidateOnFocus: false,
          ...config,
        }
      : {
          suspense: true,
          ...config,
        },
  );
};
