import {
  ApiFilter,
  ApiStoredFilter,
  Filter,
  FilterValues,
  StoredFilter,
} from '@fcg-tech/regtech-types/regeye';
import { single } from '@fcg-tech/regtech-utils';
import { convertToDateFilter, dateConverter } from './converterUtils';

export const convertStoredFilter = (obj: ApiStoredFilter): StoredFilter => {
  if (!obj) {
    return null;
  }
  return {
    ...obj,
    filter: convertFilter(obj.filter),
  };
};

export const convertFilter = (apiFilter: ApiFilter): Filter => {
  if (!apiFilter) {
    return null;
  }
  const {
    publicationDate,
    dueDate,
    performedDate,
    // eslint-disable-next-line prefer-const
    ...rest
  } = apiFilter;

  const filter: FilterValues = {
    ...rest,
  };

  if (publicationDate) {
    filter.publicationDate = dateConverter(publicationDate, 'from', 'to');
  }

  if (dueDate) {
    filter.dueDate = dateConverter(dueDate, 'from', 'to');
  }

  if (performedDate) {
    filter.performedDate = dateConverter(performedDate, 'from', 'to');
  }

  return filter;
};

export const convertFilterForApi = (filter: Filter): ApiFilter => {
  if (!filter) {
    return null;
  }
  const { publicationDate, dueDate, performedDate, searchString, ...rest } =
    filter;

  const apiFilter: Filter = {
    ...rest,
    searchString: single(searchString)?.toString(),
    publicationDate: convertToDateFilter(publicationDate),
    dueDate: convertToDateFilter(dueDate),
    performedDate: convertToDateFilter(performedDate),
  };

  if (apiFilter.importance && !Array.isArray(apiFilter.importance)) {
    apiFilter.importance = [apiFilter.importance];
  }

  return apiFilter;
};
