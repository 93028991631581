import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import update from 'immutability-helper';
import { GlobalActions } from '@fcg-tech/regtech-types/regeye';
import {
  Company,
  CompanyActions,
  InitData,
  Team,
  TeamActions,
} from '../../api/schema';
import { useAllTeams, useCompanies } from '../../api/hooks';
import { Maybe, EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import { useInitContext } from '../InitContext';

export type IamContextProps = {
  globalPermissions: Array<GlobalActions>;
  teamPermissions: Record<string, Array<TeamActions>>;
  companyPermissions: Record<string, Array<CompanyActions>>;
  updateTeamPermissions: (
    teamId: string,
    permissions: Array<TeamActions>,
  ) => void;
  error?: boolean;
};

export const IamContext = React.createContext<IamContextProps>({
  globalPermissions: [],
  teamPermissions: {},
  companyPermissions: {},
  updateTeamPermissions: () => null,
});

export const useIamContext = () => React.useContext(IamContext);

const getContextPermissions = (
  companies: Maybe<Array<Company>>,
  teams: Maybe<Array<Team>>,
  initData: InitData | null,
) =>
  initData
    ? {
        globalPermissions: initData.globalActions,
        teamPermissions:
          teams?.reduce<IamContextProps['teamPermissions']>((curr, team) => {
            curr[team.id] = team.teamActions;
            return curr;
          }, {}) ?? {},
        companyPermissions:
          companies?.reduce<IamContextProps['companyPermissions']>(
            (curr, company) => {
              curr[company.id] = company.companyActions;
              return curr;
            },
            {},
          ) ?? {},
      }
    : {
        companyPermissions: {},
        globalPermissions: [],
        teamPermissions: {},
        error: true,
      };

export const IamContextProvider: FunctionComponent<EmptyPropsWithChildren> = ({ children }) => {
  const { initData } = useInitContext();
  const { data: teams } = useAllTeams();
  const { data: companies } = useCompanies(
    !initData?.globalActions.includes(GlobalActions.CompanyList),
  );

  const [value, setValue] = useState<
    Pick<
      IamContextProps,
      'globalPermissions' | 'teamPermissions' | 'companyPermissions' | 'error'
    >
  >(getContextPermissions(companies, teams, initData));

  const updateTeamPermissions = useCallback<
    IamContextProps['updateTeamPermissions']
  >((teamId, permissions) => {
    setValue((old) => {
      return update(old, {
        teamPermissions: {
          [teamId]: { $set: permissions },
        },
      });
    });
  }, []);

  const contextValue = useMemo<IamContextProps>(
    () => ({
      ...value,
      updateTeamPermissions,
    }),
    [updateTeamPermissions, value],
  );

  useEffect(() => {
    setValue(getContextPermissions(companies, teams, initData));
  }, [companies, initData, teams]);

  return (
    <IamContext.Provider value={contextValue}>{children}</IamContext.Provider>
  );
};
