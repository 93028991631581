import { Pencil, Trash2 } from 'lucide-react';
import { FunctionComponent } from 'react';
import { ActionIconButton } from '../buttons';
import { DataTableActionBarWrapper } from './DataTableActionBar.styles';

interface DataTableActionBarProps {
  isRemoving?: boolean;
  onRemove?: () => Promise<unknown>;
  onEdit?: () => void;
}

export const DataTableActionBar: FunctionComponent<DataTableActionBarProps> = ({
  isRemoving,
  onEdit,
  onRemove,
}) => {
  if (!onEdit && !onRemove) {
    return null;
  }
  return (
    <DataTableActionBarWrapper>
      {onEdit ? (
        <ActionIconButton onClick={onEdit}>
          <Pencil size="18" />
        </ActionIconButton>
      ) : null}
      {onRemove ? (
        <ActionIconButton loading={isRemoving} onClick={onRemove}>
          <Trash2 size="18" />
        </ActionIconButton>
      ) : null}
    </DataTableActionBarWrapper>
  );
};
