import { useTranslation } from 'react-i18next';
import { RichTextMentionElement } from '@fcg-tech/regtech-richtext';
import { Comment, User } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import type { Descendant, Element, Text as SlateText } from 'slate';
import { useCurrentUser, useSettings } from '../../api/hooks';
import { MessageKeys } from '../../translations/translationTypes';
import { formatDateTime, formatName } from '../../utils/formatters';
import { Avatar } from '../Avatar';
import { Debug } from '../Debug';
import {
  CommentEntryActionButton,
  CommentEntryActions,
  CommentEntryAvatar,
  CommentEntryBody,
  CommentEntryContent,
  CommentEntryDeletedMessage,
  CommentEntryMetadata,
  CommentEntryMetadataName,
  CommentEntryWrapper,
  CommentMention,
} from './CommentEntry.styles';
import { convertLegacyCommentToDescendants } from './commentUtils';

export interface CommentEntryProps {
  comment: Comment;
  disableActions?: boolean;
  teamMembers?: Array<User>;
  onReply?: (replyToCommentId: string) => void;
  onEdit?: (commentId: string) => void;
  onDelete?: (commentId: string) => void;
}

export const CommentEntry: FunctionComponent<CommentEntryProps> = ({
  comment,
  disableActions,
  teamMembers,
  onReply,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser({ suspense: false });
  const { data: settings } = useSettings({ suspense: false });
  const handleReplyClick = useCallback(
    () => onReply(comment.id),
    [comment.id, onReply],
  );
  const handleEditClick = useCallback(
    () => onEdit(comment.id),
    [comment.id, onEdit],
  );
  const handleDeleteClick = useCallback(
    () => onDelete(comment.id),
    [comment.id, onDelete],
  );

  const allowedRefs = useMemo(
    () => teamMembers?.map((m) => m.username),
    [teamMembers],
  );

  return (
    <CommentEntryWrapper key={comment.id} isReply={Boolean(comment.replyTo)}>
      <CommentEntryAvatar>
        <Avatar user={comment.author} />
      </CommentEntryAvatar>
      <CommentEntryContent>
        <Debug>{comment.id}</Debug>
        <CommentEntryMetadata>
          <CommentEntryMetadataName>
            {formatName(comment.author, settings?.userNamePreference)}
          </CommentEntryMetadataName>
          {formatDateTime(comment.created, settings)}
        </CommentEntryMetadata>
        <CommentEntryBody>
          {comment.deleted ? (
            <CommentEntryDeletedMessage>
              {t(MessageKeys.CommentWasDeleted)}
            </CommentEntryDeletedMessage>
          ) : (
            <React.Fragment>
              {renderMarkup(comment, allowedRefs, currentUser?.username)}
            </React.Fragment>
          )}
        </CommentEntryBody>
        <CommentEntryActions>
          {!comment.replyTo && !comment.deleted && onReply ? (
            <CommentEntryActionButton
              onClick={handleReplyClick}
              disabled={disableActions}
            >
              {t(MessageKeys.LabelReply)}
            </CommentEntryActionButton>
          ) : null}
          {comment.author.username === currentUser?.username &&
          !comment.deleted ? (
            <>
              {onEdit ? (
                <CommentEntryActionButton
                  onClick={handleEditClick}
                  disabled={disableActions}
                >
                  {t(MessageKeys.LabelEdit)}
                </CommentEntryActionButton>
              ) : null}
              {onDelete ? (
                <CommentEntryActionButton
                  onClick={handleDeleteClick}
                  disabled={disableActions}
                >
                  {t(MessageKeys.LabelDelete)}
                </CommentEntryActionButton>
              ) : null}
            </>
          ) : null}
        </CommentEntryActions>
      </CommentEntryContent>
    </CommentEntryWrapper>
  );
};

const renderDescendant = (
  descendant: Descendant,
  index: number,
  allowedRefs?: Array<string>,
  currentUser?: string,
): JSX.Element | Array<JSX.Element> => {
  if (
    (!(descendant as Element).type ||
      (descendant as Element).type === 'text') &&
    Reflect.has(descendant as SlateText, 'text')
  ) {
    if ((descendant as SlateText).text.length === 0) {
      return (
        <span key={index} dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
      );
    }
    return <span key={index}>{(descendant as SlateText).text}</span>;
  }

  if ((descendant as Element).type === 'mention') {
    const mention = descendant as RichTextMentionElement;
    if (allowedRefs && allowedRefs.includes(mention.id)) {
      return (
        <CommentMention
          key={`${mention.id}-${index}`}
          className={classNames(mention.id === currentUser && 'current-user')}
        >
          @{mention.id}
        </CommentMention>
      );
    }
    return (
      <React.Fragment key={index}>
        {(mention as Element).children.map((c: Descendant, i: number) =>
          renderDescendant(c, i, allowedRefs, currentUser),
        )}
      </React.Fragment>
    );
  }

  if ((descendant as Element).type === 'paragraph') {
    return (
      <div key={index}>
        {!(descendant as Element).children?.length ? (
          <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
        ) : null}
        {(descendant as Element).children.map((c: Descendant, i: number) =>
          renderDescendant(c, i, allowedRefs, currentUser),
        )}
      </div>
    );
  }
};

const renderMarkup = (
  comment: Comment,
  allowedRefs?: Array<string>,
  currentUser?: string,
) => {
  const markup =
    comment.markup ?? convertLegacyCommentToDescendants(comment.comment);

  return (
    <React.Fragment>
      {markup.map((descendant, index) =>
        renderDescendant(descendant, index, allowedRefs, currentUser),
      )}
    </React.Fragment>
  );
};
