import {
  BasePoint,
  Descendant,
  Editor,
  Element as SlateElement,
  Node,
  Text,
  Range,
  Selection,
  Transforms,
} from 'slate';
import {
  RichTextAnnotation,
  RichTextMentionElement,
} from './RichTextArea.types';

export const withMentions = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return SlateElement.isElementType(element, 'mention')
      ? true
      : isInline(element);
  };

  editor.isVoid = (element) => {
    return SlateElement.isElementType(element, 'mention')
      ? true
      : isVoid(element);
  };

  return editor;
};

export const insertMention = (editor: Editor, range: Range, id: string) => {
  Transforms.select(editor, range);
  const mention: RichTextMentionElement = {
    type: 'mention',
    id,
    children: [{ text: `${id}` }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export const tagOrMentionRe = /^([@#])([^\s]+)$/;

export const detectTagsOrMentions = (
  editor: Editor,
  selection: Selection,
): [
  target: Range | null,
  search: string | null,
  type: RichTextAnnotation | null,
] => {
  if (selection) {
    const [start] = Range.edges(selection);

    const wordBefore = Editor.before(editor, start, { unit: 'word' });
    const before = wordBefore && Editor.before(editor, wordBefore);
    const beforeRange = before && Editor.range(editor, before, start);
    const beforeText = beforeRange && Editor.string(editor, beforeRange);

    const charBefore =
      before && Editor.before(editor, before, { unit: 'character' });
    const charBeforeRange =
      charBefore && Editor.range(editor, charBefore, before);
    const charBeforeText = charBeforeRange
      ? Editor.string(editor, charBeforeRange)
      : '';
    const charBeforeMatch = charBeforeText.match(/^\s?$/);

    const beforeMatch =
      charBeforeMatch && beforeText && beforeText.match(tagOrMentionRe);

    const after = Editor.after(editor, start);
    const afterRange = Editor.range(editor, start, after);
    const afterText = Editor.string(editor, afterRange);
    const afterMatch = afterText.match(/^(\s|$)/);

    if (charBeforeMatch && beforeMatch && afterMatch && beforeRange) {
      return [
        beforeRange,
        beforeMatch[2],
        beforeMatch[1] === '@'
          ? RichTextAnnotation.Mention
          : RichTextAnnotation.Tag,
      ];
    }
  }

  return [null, null, null];
};

export const serializePlainText = (nodes: Array<Node>) => {
  return nodes
    .map((n) => Node.string(n))
    .join('\n')
    .trimEnd();
};

export const unitBefore = (
  editor: Editor,
  start: BasePoint,
  unit: 'word' | 'block' | 'character' | 'line',
) => {
  const wordBefore = Editor.before(editor, start, { unit });
  const before = wordBefore && Editor.before(editor, wordBefore);
  const beforeRange = before && Editor.range(editor, before, start);
  const beforeText = beforeRange && Editor.string(editor, beforeRange);

  return beforeText;
};

export const pad = (markup: Array<Descendant>): Array<Descendant> => {
  return markup.map<Descendant>((n: Descendant) => {
    if (SlateElement.isElement(n)) {
      const lastChild = n.children[n.children.length - 1];
      if (
        lastChild &&
        SlateElement.isElement(lastChild) &&
        lastChild.type === 'mention'
      ) {
        return {
          ...n,
          children: [
            ...n.children,
            {
              text: ' ',
            } as Descendant,
          ],
        } as Descendant;
      } else if (
        Text.isText(lastChild) &&
        lastChild.text === '' &&
        n.children.length > 1
      ) {
        return {
          ...n,
          children: [
            ...n.children.slice(0, -1),
            {
              text: ' ',
            } as Descendant,
          ],
        } as Descendant;
      }
    }

    return n;
  });
};

export const getEmptyMarkup = () => [
  { type: 'paragraph', children: [{ text: '' }] },
];
