import { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { isMutableRef } from '../../guards';
import { Portal } from '../Portal';
import {
  ShowcaseHighlightContent,
  ShowcaseHighlightWrapper,
  ShowcaseSVG,
} from './Showcase.styles';
import {
  getContentPosition,
  getSeguePosition,
  useShowcaseWrapperPosition,
} from './showcaseUtils';

interface ShowcaseHighlightProps {
  element: string | HTMLElement | React.MutableRefObject<HTMLElement>;
  padding?: number;
}

export const ShowcaseHighlight: FunctionComponent<
  PropsWithChildren<ShowcaseHighlightProps>
> = ({ element, children, padding = 10 }) => {
  const theme = useTheme();
  const htmlElement = useMemo<HTMLElement>(() => {
    if (typeof element === 'string') {
      return document.querySelector(element);
    }

    if (isMutableRef(element)) {
      return element.current;
    }
    return element;
  }, [element]);

  const [radius] = useMemo<[radius: number]>(() => {
    const box = htmlElement?.getBoundingClientRect();
    const radius = Math.max(box?.width, box?.height) + padding + 2;

    return [radius];
  }, [htmlElement, padding]);

  const { placement, cx, cy } = useShowcaseWrapperPosition(htmlElement);

  return (
    <Portal>
      <ShowcaseSVG>
        <mask id="mask">
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <circle cx={cx} cy={cy} r={radius} fill="black" />
        </mask>
        <g>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="rgba(0,0,0,0.5)"
            mask="url(#mask)"
          />

          <circle
            cx={cx}
            cy={cy}
            r={radius}
            strokeWidth="4"
            stroke={theme?.palette?.MainColorAccent?.main ?? 'white'}
            fill="transparent"
          />

          <rect
            {...getSeguePosition(placement, cx, cy, radius)}
            fill={theme?.palette?.MainColorAccent?.main ?? 'white'}
            mask="url(#mask)"
          />
        </g>
      </ShowcaseSVG>
      <ShowcaseHighlightWrapper>
        <ShowcaseHighlightContent
          style={{ ...getContentPosition(placement, cx, cy, radius) }}
        >
          {children}
        </ShowcaseHighlightContent>
      </ShowcaseHighlightWrapper>
    </Portal>
  );
};
