import { Pagination, usePagination } from '@fcg-tech/regtech-components';
import {
  DateRange,
  FilterDateRangeInterval,
  FilterSelect,
  FilterSelectOption,
} from '@fcg-tech/regtech-filter';
import { FeedArticle, FeedArticleDomain } from '@fcg-tech/regtech-types/regeye';
import { useDevice } from '@fcg-tech/regtech-utils';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useActivityLogArticles, useSettings } from '../../api/hooks';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { FeedArticleTable } from '../../components/FeedArticleTable';
import {
  FeedArticleTableAndFilterWrapper,
  StyledFilterBar,
} from '../../components/FeedArticleTable/FeedArticleTable.styles';
import { useFeedArticleTableContext } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { useTableColumnOptions } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { Filter, FilterBorderWrapper } from '../../components/Filter';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { MessageKeys } from '../../translations/translationTypes';
import { TableType } from '../../types';
import { updateArticleUserMetadata } from '../../utils/articleMutations';
import { performedDateIntervals } from '../../utils/filterHelpers';
import { useFilter, useTagAndTypeClickHandlers } from '../../utils/filterHooks';
import { formatName } from '../../utils/formatters';
import { useNonEphemeralData } from '../../utils/historyHelpers';

interface ActivityLogArticlesTableProps {
  teamId?: string;
  companyId?: string;
}

export const ActivityLogArticlesTable: FunctionComponent<
  ActivityLogArticlesTableProps
> = ({ companyId, teamId }) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const { data: settings } = useSettings({ suspense: false });

  const [page, setPage] = usePagination();

  const wrapper = useRef<HTMLDivElement>();

  const { setFilterDisabled, domain, isFilterVisible, setFilterVisible } =
    useFeedArticleTableContext();

  const handleFilterRequestClose = useCallback(
    () => setFilterVisible(false),
    [setFilterVisible],
  );

  const {
    filter,
    extendedFilter,
    hasInitialFilter,
    sortBy,
    getFilterQuery,
    handleFilterClear,
    handleSortByChange,
    handleFilterChange,
    handleFilterValueChange,
    handleFilterValueClear,
  } = useFilter({
    teamId,
    companyId,
    tableType: TableType.ActionLog,
  });

  const [columns, handleColumnOptionsChange] = useTableColumnOptions(
    TableType.ActionLog,
    domain,
  );

  const { data, mutate, isValidating, error } = useActivityLogArticles(
    companyId ?? teamId,
    domain,
    extendedFilter,
    page,
    DEFAULT_PAGE_SIZE,
    {
      suspense: false,
    },
  );

  const handleArticleMutated = useCallback(
    (articleId: string, data: Partial<FeedArticle['userMetadata']>) => {
      updateArticleUserMetadata(articleId, data, mutate);
    },
    [mutate],
  );

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (data?.pagination) {
        const totalPages = Math.ceil(
          data.pagination?.totalResults / data.pagination.limit,
        );
        if (pageNumber <= totalPages && pageNumber >= 1) {
          setPage(pageNumber);
          return true;
        }
      }
      return false;
    },
    [data?.pagination, setPage],
  );

  const handlePageChangeByDelta = useCallback(
    (direction: 1 | -1) => {
      return handlePageChange(page + direction);
    },
    [handlePageChange, page],
  );

  const paginationData = useNonEphemeralData(data?.pagination);
  const filterData = useNonEphemeralData(data?.eventLogFilter);
  const isLoading = !data && isValidating;

  useEffect(() => {
    setFilterDisabled(!filterData);
  }, [filterData, setFilterDisabled]);

  const [actions, performedBy, teams] = useMemo<
    Array<Array<FilterSelectOption>>
  >(() => {
    return [
      filterData?.actions?.map<FilterSelectOption>((a) => ({
        value: { id: a },
        label: t(`team-activity-event-${a.replace(/\s/g, '-').toLowerCase()}`),
      })),
      filterData?.performedBy?.map<FilterSelectOption>((a) => ({
        value: { id: a.username },
        label: formatName(a, settings?.userNamePreference),
      })),
      filterData?.teams?.map<FilterSelectOption>((a) => ({
        value: a,
        label: a.name,
      })),
    ];
  }, [
    filterData?.actions,
    filterData?.performedBy,
    filterData?.teams,
    settings?.userNamePreference,
    t,
  ]);

  const maxTodayRange = useMemo<DateRange>(
    () => ({
      to: new Date(),
    }),
    [],
  );

  const { onTeamTagClick } = useTagAndTypeClickHandlers(
    filter,
    getFilterQuery,
    true,
    handleFilterChange,
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <StyledFilterBar>
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(
            paginationData?.totalResults / paginationData?.limit ?? 1,
          )}
          disabled={isLoading}
          narrow={isMobile}
          onPageClick={handlePageChange}
        />
      </StyledFilterBar>
      <FeedArticleTableAndFilterWrapper ref={wrapper}>
        <FeedArticleTable
          feedArticles={data?.items}
          columns={columns}
          loading={isLoading}
          allowArrowKeyNavigation
          mutateArticle={handleArticleMutated}
          mutateFeedArticleList={mutate}
          sortBy={sortBy}
          parentElement={wrapper.current}
          onColumnsChange={handleColumnOptionsChange}
          onChangePageByDelta={handlePageChangeByDelta}
          onSortByChange={handleSortByChange}
          onTeamTagClick={onTeamTagClick}
        />

        {isFilterVisible && filterData ? (
          <FilterBorderWrapper>
            <Filter
              filter={filter}
              clearLabel={t(MessageKeys.LabelClear)}
              excludeLabel={t(MessageKeys.LabelExclude)}
              noItemsLabel={t(MessageKeys.FeedArticleFilterTagsNoTags)}
              showDraftRestoredNotification={hasInitialFilter}
              onFilterClear={handleFilterClear}
              onFilterChange={handleFilterChange}
              onFilterValueChange={handleFilterValueChange}
              onFilterValueClear={handleFilterValueClear}
              onRequestClose={handleFilterRequestClose}
            >
              <FilterSelect
                filterPropKey="actions"
                label={t(MessageKeys.FeedArticleFilterTeamActivityEventFilter)}
                options={actions}
                allowMultipleSelect
              />
              {domain === FeedArticleDomain.Company ||
              domain === FeedArticleDomain.Personal ? (
                <FilterSelect
                  filterPropKey="teams"
                  label={t(MessageKeys.FeedArticleFilterTeamFilter)}
                  options={teams}
                  allowMultipleSelect
                />
              ) : null}
              {domain === FeedArticleDomain.Team ||
              domain === FeedArticleDomain.Company ? (
                <FilterSelect
                  filterPropKey="performedBy"
                  label={t(
                    MessageKeys.FeedArticleFilterTeamActivityPerformedByFilter,
                  )}
                  options={performedBy}
                  allowMultipleSelect
                />
              ) : null}
              <FilterDateRangeInterval
                filterPropKey="performedDate"
                label={t(MessageKeys.FeedArticleFilterPerformedDateFilter)}
                intervals={performedDateIntervals}
                allowedRange={maxTodayRange}
              />
            </Filter>
          </FilterBorderWrapper>
        ) : null}
      </FeedArticleTableAndFilterWrapper>
    </>
  );
};
