import styled from 'styled-components';

export const NoContentMessage = styled.div`
  padding: 3rem 0;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  flex: 1;

  &.narrow {
    padding: 1rem 0;
    font-size: 1.4rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;

  &.center {
    align-items: center;
  }
`;
