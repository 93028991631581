import { useTranslation } from 'react-i18next';
import { SpinningLoadingIcon } from '@fcg-tech/regtech-components';
import { AssignedUser } from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { useCurrentUser } from '../../api/hooks';
import { MessageKeys } from '../../translations/translationTypes';
import { AssignedTeamMamberStatusIcon } from './AssignedTeamMamberStatusIcon';
import {
  TeamMemberListItemAssignedIconWrapper,
  TeamMemberListItemMarkAsCompleteButton,
  TeamMemberListWrapper,
} from './TeamMemberList.styles';
import {
  GetTooltip,
  IconRenderer,
  TeamMemberListItem,
  TeamMemberListItemProps,
} from './TeamMemberListItem';

interface AssignedTeamMemberListProps {
  members: Array<AssignedUser>;
  icon?: IconRenderer;
  getTooltip?: GetTooltip;
  onRemove?: TeamMemberListItemProps['onRemoveClick'];
  onComplete?: () => Promise<unknown>;
  className?: string;
}

export const AssignedTeamMemberList: FunctionComponent<
  AssignedTeamMemberListProps
> = ({ members, icon, getTooltip, onComplete, onRemove, className }) => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const handleComplete = useCallback(async () => {
    try {
      setLoading(true);
      await onComplete();
    } catch (e) {
      // NOOP
    }
    setLoading(false);
  }, [onComplete]);

  return (
    <TeamMemberListWrapper
      className={className}
      data-testid="team-member-list-wrapper"
    >
      {members.map((member) => (
        <TeamMemberListItem
          key={member.user.username}
          member={member.user}
          getTooltip={getTooltip}
          icon={icon}
          emph={member.user.username === user.username}
          onRemoveClick={onRemove}
        >
          {member.user.username === user.username && onComplete ? (
            <TeamMemberListItemMarkAsCompleteButton
              onClick={handleComplete}
              data-testid="team-member-list-mark-as-complete-button"
            >
              {t(
                member.completed
                  ? MessageKeys.LabelMarkAsNotCompleted
                  : MessageKeys.LabelMarkAsComplete,
              )}
              <TeamMemberListItemAssignedIconWrapper>
                {loading ? (
                  <SpinningLoadingIcon size="18" />
                ) : (
                  <AssignedTeamMamberStatusIcon
                    data-testid={`team-member-list-mark-as-complete-icon-${
                      member.completed ? 'completed' : 'not-completed'
                    }`}
                    color={
                      member.completed
                        ? theme.colors.success.main
                        : theme.colors.warning.main
                    }
                  />
                )}
              </TeamMemberListItemAssignedIconWrapper>
            </TeamMemberListItemMarkAsCompleteButton>
          ) : (
            <AssignedTeamMamberStatusIcon
              color={member.completed ? theme.colors.success.main : undefined}
            />
          )}
        </TeamMemberListItem>
      ))}
    </TeamMemberListWrapper>
  );
};
