import {
  FormGridButtonRow,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  H2,
  PrimaryButton,
  useFlash,
  useInput,
} from '@fcg-tech/regtech-components';
import { Team } from '@fcg-tech/regtech-types/regeye';
import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { canCompanyTeamEdit } from '../../utils/iamHelpers';
import { useIamContext } from '../IamContext';
import { MainLayoutPaddedContentWrapper } from '../MainLayout/MainLayout.styles';
import {
  TeamAdministrationDetailsTeamNameInput,
  TeamAdministrationTeamDetailsForm,
} from './TeamAdministration.styles';

enum LoadingState {
  Saving,
  Deleting,
}

export interface TeamAdministrationTeamDetailsPropsCallbacks {
  onTeamUpdate: (team: Team) => Promise<Team>;
}

interface TeamAdministrationTeamDetailsProps
  extends TeamAdministrationTeamDetailsPropsCallbacks {
  team: Team;
}

export const TeamAdministrationTeamDetails: FunctionComponent<
  TeamAdministrationTeamDetailsProps
> = ({ team, onTeamUpdate }) => {
  const { t } = useTranslation();
  const companyId = team.company?.id;
  const [teamNameValue, handleTeamNameChange] = useInput(team?.name);
  const [loading, setLoading] = useState<LoadingState>();
  const addFlash = useFlash();
  const iamContext = useIamContext();

  const handleSubmitTeamDetails = useCallback(async () => {
    if (teamNameValue.trim().length === 0) {
      return;
    }
    setLoading(LoadingState.Saving);
    try {
      await onTeamUpdate({ ...team, name: teamNameValue });
      addFlash({
        level: 'success',
        content: t(MessageKeys.AdministrationTeamEditSuccess),
      });
    } catch (e) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.AdministrationTeamEditFailure),
      });
    }
    setLoading(null);
  }, [teamNameValue, onTeamUpdate, team, addFlash, t]);

  const canEdit = canCompanyTeamEdit(companyId, iamContext);

  return team ? (
    <MainLayoutPaddedContentWrapper>
      <TeamAdministrationTeamDetailsForm>
        <H2>{t(MessageKeys.AdministrationTeamDetails)}</H2>
        <FormGridRow>
          <FormGridLabel>
            {t(MessageKeys.AdministrationTeamDetailsTeamName)}
          </FormGridLabel>
          <FormGridControl>
            <TeamAdministrationDetailsTeamNameInput
              name="team-name"
              value={teamNameValue}
              isEditEnabled={canEdit}
              onChange={handleTeamNameChange}
            />
          </FormGridControl>
        </FormGridRow>
        {canEdit ? (
          <FormGridButtonRow>
            <PrimaryButton
              onClick={handleSubmitTeamDetails}
              loading={loading === LoadingState.Saving}
              disabled={Boolean(loading) || teamNameValue.trim().length === 0}
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </FormGridButtonRow>
        ) : null}
      </TeamAdministrationTeamDetailsForm>
    </MainLayoutPaddedContentWrapper>
  ) : null;
};
