import styled, { css } from 'styled-components';

export const FilterDraftRestoredNotificationWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme?.palette?.MainColorAccent.main ??
    'rgba(0, 228, 228, 1)'};

    padding: 1rem;
    color: ${theme?.palette?.MainColorAccent.mainContrast ?? 'rgb(0, 0, 0)'};
  `}
`;

export const FilterDraftRestoredNotificationLinkWrapper = styled.div`
  ${({ theme }) => css`
    margin: 1rem 0;
    color: ${theme?.palette?.MainColorAccent.lightContrast ?? 'rgb(0, 0, 0)'};
    text-decoration: underline;
    cursor: pointer;

    & > a {
      color: ${theme?.palette?.MainColorAccent.lightContrast ??
      'rgb(0, 0, 0)'}!important;
    }
  `}
`;
