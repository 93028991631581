import {
  htmlDecode,
  Importance,
  ImportanceIndicator,
} from '@fcg-tech/regtech-components';
import { DataTableCellProps } from '@fcg-tech/regtech-datatable';
import { classNames } from '@fcg-tech/regtech-utils';
import { format, isBefore, isSameDay, startOfDay } from 'date-fns';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { TABLE_DATE_FORMAT } from '../../constants';
import { FeedArticle, FeedArticleTableColumns } from '../../types';
import { ImportanceMessageKeyMap } from '../../utils/translationMaps';
import { ArticleTag, ArticleTeamTag, ArticleTypeTag } from '../Tag';
import { TagList } from '../TagList';
import { TeamActivityEventDescription } from '../TeamArticlesEventDescription';
import {
  FeedArticleAssignee,
  FeedArticleAssigneeCompletedIcon,
  FeedArticleTextContent,
} from './FeedArticleTable.styles';

type FeedArticleTableCellProps = DataTableCellProps<FeedArticle>;

export const FeedArticleTableCell: FunctionComponent<
  FeedArticleTableCellProps
> = ({ cell, row }) => {
  const { t } = useTranslation();
  const feedArticle = row.original;
  const isArchivedForCurrentTeam = feedArticle?.archived;

  switch (cell.column.id) {
    case FeedArticleTableColumns.Publisher:
      return (
        <FeedArticleTextContent title={feedArticle?.article?.publisher}>
          {feedArticle?.article?.publisherAbbreviation ??
            feedArticle?.article?.publisher}
        </FeedArticleTextContent>
      );

    case FeedArticleTableColumns.Title:
      return (
        <FeedArticleTextContent
          className="title"
          title={feedArticle?.article?.title?.trim()}
        >
          {htmlDecode(feedArticle?.article?.title)}
        </FeedArticleTextContent>
      );
    case FeedArticleTableColumns.Teams:
      return (
        <FeedArticleTextContent title={feedArticle?.team?.name}>
          {feedArticle?.team?.name}
        </FeedArticleTextContent>
      );

    case FeedArticleTableColumns.Type:
      return (
        <TagList
          tags={feedArticle?.article?.articleTypes ?? []}
          sortAscending
          maxQuantity={3}
          TagComponent={ArticleTypeTag}
        />
      );

    case FeedArticleTableColumns.Tags:
      return (
        <TagList
          tags={feedArticle?.article?.articleTags ?? []}
          sortAscending
          maxQuantity={3}
          TagComponent={ArticleTag}
        />
      );

    case FeedArticleTableColumns.TeamTags:
      return (
        <TagList
          tags={feedArticle?.teamArticleMetadata?.tags ?? []}
          sortAscending
          TagComponent={ArticleTeamTag}
          tagClassName="team-tag"
          maxQuantity={3}
        />
      );

    case FeedArticleTableColumns.Content:
      return (
        <FeedArticleTextContent
          title={feedArticle?.article?.description?.trim()}
        >
          {htmlDecode(feedArticle?.article?.description)}
        </FeedArticleTextContent>
      );

    case FeedArticleTableColumns.Date:
      return (
        <FeedArticleTextContent>
          {feedArticle?.article?.publicationDate
            ? format(feedArticle?.article?.publicationDate, TABLE_DATE_FORMAT)
            : ''}
        </FeedArticleTextContent>
      );

    case FeedArticleTableColumns.Assigned:
      return (
        <FeedArticleTextContent>
          {feedArticle?.teamArticleMetadata?.assigned?.map(
            ({ user, completed }) => (
              <FeedArticleAssignee key={user.username} title={user.username}>
                <FeedArticleAssigneeCompletedIcon
                  className={classNames(completed && 'completed')}
                />
                {user.alias}
              </FeedArticleAssignee>
            ),
          )}
        </FeedArticleTextContent>
      );

    case FeedArticleTableColumns.DueDate:
      return (
        <FeedArticleTextContent
          className={classNames(
            !isArchivedForCurrentTeam &&
              isBefore(
                feedArticle?.teamArticleMetadata?.dueDate?.dueDate,
                startOfDay(new Date()),
              ) &&
              'alert',
            !isArchivedForCurrentTeam &&
              isSameDay(
                feedArticle?.teamArticleMetadata?.dueDate?.dueDate,
                new Date(),
              ) &&
              'warning',
          )}
        >
          {feedArticle?.teamArticleMetadata?.dueDate?.dueDate
            ? format(
                feedArticle?.teamArticleMetadata?.dueDate?.dueDate,
                TABLE_DATE_FORMAT,
              )
            : ''}
        </FeedArticleTextContent>
      );

    case FeedArticleTableColumns.ActionDate:
      return (
        <FeedArticleTextContent>
          {feedArticle?.recentTeamActivity?.created
            ? format(
                feedArticle?.recentTeamActivity?.created,
                TABLE_DATE_FORMAT,
              )
            : ''}
        </FeedArticleTextContent>
      );
    case FeedArticleTableColumns.Action:
      return (
        <TeamActivityEventDescription
          recentTeamActivity={feedArticle.recentTeamActivity}
        />
      );

    case FeedArticleTableColumns.Importance:
      return feedArticle?.teamArticleMetadata?.importance?.importance ? (
        <ImportanceIndicator
          title={t(
            ImportanceMessageKeyMap[
              feedArticle.teamArticleMetadata.importance.importance
            ],
          )}
          importance={
            feedArticle.teamArticleMetadata.importance.importance as Importance
          }
        />
      ) : null;
  }
};
