import {
  DataTableColumn,
  RowRenderer,
  SortingRule,
} from '@fcg-tech/regtech-datatable';
import { StoredFilter } from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getFilterTypeMessageKey,
  MessageKeys,
} from '../../translations/translationTypes';
import { RegeyeDataTable } from '../datatable/RegeyeDataTable';
import { FilterListTableNoContentMessage } from './FilterListTable.styles';
import { FilterListTableRow, RowData } from './FilterListTableRow';

interface FilterListTableProps {
  filters: Array<StoredFilter>;
  onRemoveFilter?: (filterId: string) => Promise<unknown>;
  onEditFilter?: (filterId: string) => void;
}

export const FilterListTable: FunctionComponent<FilterListTableProps> = ({
  filters,
  onRemoveFilter,
  onEditFilter,
}) => {
  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState<SortingRule<RowData>>({
    desc: false,
    id: 'name',
  });

  const columns = useMemo<Array<DataTableColumn<RowData>>>(() => {
    const columns: Array<DataTableColumn<RowData>> = [
      {
        id: 'name',
        width: 200,
        minWidth: 200,
        defaultCanSort: true,
        Header: t(MessageKeys.LabelFilterName),
        accessor: (row: RowData) => row.name,
      },
      {
        id: 'filterType',
        width: 200,
        minWidth: 200,
        defaultCanSort: true,
        Header: t(MessageKeys.LabelFilterType),
        accessor: (row: RowData) => t(getFilterTypeMessageKey(row.filterType)),
      },
    ];
    return columns;
  }, [t]);

  const dataRows = useMemo<Array<RowData>>(() => {
    return filters
      .map<RowData>((filter) => ({
        id: filter.id,
        name: filter.name,
        filterType: filter.filterType,
      }))
      .sort((a, b) => {
        if (sortOrder?.desc === undefined) {
          return 0;
        }
        switch (sortOrder?.id) {
          case 'name':
            return a.name.localeCompare(b.name) * (sortOrder?.desc ? -1 : 1);
          case 'filterType':
            return (
              a.filterType.localeCompare(b.filterType) *
              (sortOrder?.desc ? -1 : 1)
            );
        }
      });
  }, [filters, sortOrder?.desc, sortOrder?.id]);

  const WrappedRow = useMemo<RowRenderer<RowData>>(
    () => (props) =>
      (
        <FilterListTableRow
          {...props}
          onEdit={onEditFilter}
          onRemove={onRemoveFilter}
        />
      ),
    [onEditFilter, onRemoveFilter],
  );

  if (!filters?.length) {
    return (
      <FilterListTableNoContentMessage>
        {t(MessageKeys.FilterListTableNoContent)}
      </FilterListTableNoContentMessage>
    );
  }

  return (
    <RegeyeDataTable
      columns={columns}
      data={dataRows}
      sortBy={sortOrder}
      Row={WrappedRow}
      onSortByChange={setSortOrder}
    />
  );
};
