import { TextArea } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const AttachmentListWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AttachmentListList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const AttachmentListAddUrlWrapper = styled.div`
  margin: 1rem 0 0 0.2rem;
`;

export const AttachmentListAddUrlTextAreaWrapper = styled.div`
  position: relative;
  padding-bottom: 3rem;
`;

export const AttachmentListAddUrlTextArea = styled(TextArea)`
  width: 100%;
  height: 16rem;
`;
