import { classNames } from '@fcg-tech/regtech-utils';
import React from 'react';
import { IconProps } from '../../types/icons';

export const UsersPlusIcon = ({
  size = '24',
  color = 'currentColor',
  className,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke={color}
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    className={classNames(className, 'icon', 'lucide')}
    {...props}
  >
    <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" />
    <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" />
    <path d="M23.0007 21.0001V19.0001C23.0001 18.1138 22.7051 17.2528 22.1621 16.5524" />
    <path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232" />
    <path d="M20 8V14" />
    <path d="M17 11H23" />
  </svg>
);
