/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NotificationSettingsList,
    NotificationSettingsListFromJSON,
    NotificationSettingsListToJSON,
    TeamReadMetadataResponse,
    TeamReadMetadataResponseFromJSON,
    TeamReadMetadataResponseToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserInput,
    UserInputFromJSON,
    UserInputToJSON,
} from '../models';

export interface BookmarkArticleRequest {
    articleId: string;
}

export interface MarkArticleAsReadRequest {
    articleId: string;
}

export interface MarkArticleAsUnreadRequest {
    articleId: string;
}

export interface UnbookmarkArticleRequest {
    articleId: string;
}

export interface UpdateCurrentUserRequest {
    userInput: UserInput;
}

export interface UpdateNotificationsSettingsRequest {
    notificationSettingsList?: NotificationSettingsList;
}

export interface UpdatePersonalSettingsRequest {
    body: object;
}

/**
 * PersonalApi - interface
 * 
 * @export
 * @interface PersonalApiInterface
 */
export interface PersonalApiInterface {
    /**
     * 
     * @summary Bookmark article
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    bookmarkArticleRaw(requestParameters: BookmarkArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Bookmark article
     */
    bookmarkArticle(requestParameters: BookmarkArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    getCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * get current user
     */
    getCurrentUser(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<User>;

    /**
     * 
     * @summary get notifications settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    getNotificationsSettingsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<NotificationSettingsList>>;

    /**
     * get notifications settings
     */
    getNotificationsSettings(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<NotificationSettingsList>;

    /**
     * 
     * @summary get personal settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    getPersonalSettingsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * get personal settings
     */
    getPersonalSettings(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object>;

    /**
     * 
     * @summary Mark article as read
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    markArticleAsReadRaw(requestParameters: MarkArticleAsReadRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamReadMetadataResponse>>;

    /**
     * Mark article as read
     */
    markArticleAsRead(requestParameters: MarkArticleAsReadRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamReadMetadataResponse>;

    /**
     * 
     * @summary Mark article as unread
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    markArticleAsUnreadRaw(requestParameters: MarkArticleAsUnreadRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamReadMetadataResponse>>;

    /**
     * Mark article as unread
     */
    markArticleAsUnread(requestParameters: MarkArticleAsUnreadRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamReadMetadataResponse>;

    /**
     * 
     * @summary Unbookmark article
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    unbookmarkArticleRaw(requestParameters: UnbookmarkArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Unbookmark article
     */
    unbookmarkArticle(requestParameters: UnbookmarkArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary update alias of user
     * @param {UserInput} userInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    updateCurrentUserRaw(requestParameters: UpdateCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * update alias of user
     */
    updateCurrentUser(requestParameters: UpdateCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<User>;

    /**
     * 
     * @summary update notifications settings of user
     * @param {NotificationSettingsList} [notificationSettingsList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    updateNotificationsSettingsRaw(requestParameters: UpdateNotificationsSettingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * update notifications settings of user
     */
    updateNotificationsSettings(requestParameters: UpdateNotificationsSettingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary update personal settings of user
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApiInterface
     */
    updatePersonalSettingsRaw(requestParameters: UpdatePersonalSettingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * update personal settings of user
     */
    updatePersonalSettings(requestParameters: UpdatePersonalSettingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

}

/**
 * 
 */
export class PersonalApi extends runtime.BaseAPI implements PersonalApiInterface {

    /**
     * Bookmark article
     */
    async bookmarkArticleRaw(requestParameters: BookmarkArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling bookmarkArticle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal/articles/{articleId}/bookmark`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bookmark article
     */
    async bookmarkArticle(requestParameters: BookmarkArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.bookmarkArticleRaw(requestParameters, initOverrides);
    }

    /**
     * get current user
     */
    async getCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * get current user
     */
    async getCurrentUser(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<User> {
        const response = await this.getCurrentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * get notifications settings
     */
    async getNotificationsSettingsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<NotificationSettingsList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal/notifications-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationSettingsListFromJSON(jsonValue));
    }

    /**
     * get notifications settings
     */
    async getNotificationsSettings(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<NotificationSettingsList> {
        const response = await this.getNotificationsSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * get personal settings
     */
    async getPersonalSettingsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal/appdata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * get personal settings
     */
    async getPersonalSettings(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPersonalSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Mark article as read
     */
    async markArticleAsReadRaw(requestParameters: MarkArticleAsReadRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamReadMetadataResponse>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling markArticleAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal/articles/{articleId}/read`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamReadMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Mark article as read
     */
    async markArticleAsRead(requestParameters: MarkArticleAsReadRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamReadMetadataResponse> {
        const response = await this.markArticleAsReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark article as unread
     */
    async markArticleAsUnreadRaw(requestParameters: MarkArticleAsUnreadRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamReadMetadataResponse>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling markArticleAsUnread.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal/articles/{articleId}/read`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamReadMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Mark article as unread
     */
    async markArticleAsUnread(requestParameters: MarkArticleAsUnreadRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamReadMetadataResponse> {
        const response = await this.markArticleAsUnreadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unbookmark article
     */
    async unbookmarkArticleRaw(requestParameters: UnbookmarkArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling unbookmarkArticle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal/articles/{articleId}/bookmark`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unbookmark article
     */
    async unbookmarkArticle(requestParameters: UnbookmarkArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.unbookmarkArticleRaw(requestParameters, initOverrides);
    }

    /**
     * update alias of user
     */
    async updateCurrentUserRaw(requestParameters: UpdateCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userInput === null || requestParameters.userInput === undefined) {
            throw new runtime.RequiredError('userInput','Required parameter requestParameters.userInput was null or undefined when calling updateCurrentUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserInputToJSON(requestParameters.userInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * update alias of user
     */
    async updateCurrentUser(requestParameters: UpdateCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<User> {
        const response = await this.updateCurrentUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update notifications settings of user
     */
    async updateNotificationsSettingsRaw(requestParameters: UpdateNotificationsSettingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/personal/notifications-settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationSettingsListToJSON(requestParameters.notificationSettingsList),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update notifications settings of user
     */
    async updateNotificationsSettings(requestParameters: UpdateNotificationsSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.updateNotificationsSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * update personal settings of user
     */
    async updatePersonalSettingsRaw(requestParameters: UpdatePersonalSettingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updatePersonalSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/personal/appdata`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update personal settings of user
     */
    async updatePersonalSettings(requestParameters: UpdatePersonalSettingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.updatePersonalSettingsRaw(requestParameters, initOverrides);
    }

}
