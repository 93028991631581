import { FunctionComponent, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Error404 } from '../../components/ErrorBoundary/Error404';
import { FeedArticleTableContextProvider } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { useIamContext } from '../../components/IamContext';
import { TeamContextProvider } from '../../components/TeamContext/TeamContext';
import { routes } from '../../routes';
import { pageContext } from '../../state/pageContext';
import { FeedArticleDomain, TableType } from '../../types';
import { canViewPageCompaniesAdministration } from '../../utils/iamHelpers';
import { AdministrationBaseContainer } from '../AdministrationBaseContainer/AdministrationBaseContainer';
import { CompanyBaseContainer } from '../CompanyBaseContainer/CompanyBaseContainer';
import { DashboardPage } from '../DashboardPage';
import { FallbackNoCompaniesPage } from '../fallback';
import { FeedArticlePage } from '../FeedArticlePage/FeedArticlePage';
import { FeedArticleSearchPage } from '../FeedArticleSearchPage/FeedArticleSearchPage';
import { NotificationsPage } from '../NotificationsPage';
import { PersonalBaseContainer } from '../PersonalBaseContainer/PersonalBaseContainer';
import { TeamBaseContainer } from '../TeamBaseContainer/TeamBaseContainer';

export const AppRoutes: FunctionComponent = () => {
  const [, setPageContext] = useRecoilState(pageContext);
  const location = useLocation();

  useEffect(() => {
    setPageContext(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const iamContext = useIamContext();

  return (
    <Routes>
      <Route
        path={routes.trending}
        element={<Navigate to={routes.dashboard} />}
      />

      {/*
        <GenericPaginatedFeedArticlePage
          headingMessageKey={MessageKeys.ArticleTrendingFeed}
          pageType={PageType.Trending}
          useFeedArticles={useTrendingFeedArticles}
        />
      */}
      <Route path={routes.dashboard} element={<DashboardPage />} />

      <Route path={routes.feedArticleById} element={<FeedArticlePage />} />

      <Route
        path={routes.personalCatchAll}
        element={<PersonalBaseContainer />}
      />

      <Route
        path={routes.companyBaseCatchAll}
        element={
          <TeamContextProvider>
            <CompanyBaseContainer />
          </TeamContextProvider>
        }
      />

      <Route
        path={routes.teamBaseCatchAll}
        element={
          <TeamContextProvider>
            <TeamBaseContainer />
          </TeamContextProvider>
        }
      />

      <Route
        path={routes.search}
        element={
          <FeedArticleTableContextProvider
            tableType={TableType.Generic}
            domain={FeedArticleDomain.Search}
          >
            <FeedArticleSearchPage />
          </FeedArticleTableContextProvider>
        }
      />

      {canViewPageCompaniesAdministration(iamContext) ? (
        <Route
          path={routes.superAdminCatchAll}
          element={<AdministrationBaseContainer />}
        />
      ) : null}
      <Route path={routes.notifications} element={<NotificationsPage />} />
      <Route
        path={routes.fallbackNoCompanies}
        element={<FallbackNoCompaniesPage />}
      />
      <Route path="/login" element={<Navigate to={routes.dashboard} />} />
      <Route path="/" element={<Navigate to={routes.dashboard} />} />
      <Route
        path="/auth/callback"
        element={<Navigate to={routes.dashboard} />}
      />
      <Route element={<Error404 />} />
    </Routes>
  );
};
