import { DataTable, DataTableComponent } from '@fcg-tech/regtech-datatable';
import styled from 'styled-components';

export const RegeyeDataTable = styled<DataTableComponent>(DataTable)`
  border-top: 1px solid #ccc;
  height: initial;

  .header .th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }

  .header .tr {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .tr .td {
    font-size: 1.2rem;
    overflow: hidden;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }

  .rowgroup .tr:hover {
    background-color: #edf5f7;
  }

  .rowgroup {
    border-bottom: 1px solid #ccc;
  }

  .td:first-child,
  .th:first-child {
    padding-left: 1.6rem;
  }

  & .tr > .tr-hover-container {
    position: sticky;
    right: 0;
    height: inherit;
  }
`;
