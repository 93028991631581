import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import { GridLayout } from '@fcg-tech/regtech-components';
import React, { FunctionComponent } from 'react';
import { MainSideNavigation } from '../MainSideNavigation';
import { Header } from './Header';
import { MainLayoutFlashNotificationWrapper } from './MainLayout.styles';

export const MainLayout: FunctionComponent<EmptyPropsWithChildren> = ({ children }) => {
  return (
    <>
      <GridLayout>
        <MainSideNavigation />
        <Header />
        <MainLayoutFlashNotificationWrapper>
          {children}
        </MainLayoutFlashNotificationWrapper>
      </GridLayout>
    </>
  );
};
