import React, { createContext, FunctionComponent, useContext, useMemo, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { useAllTeams } from '../../api/hooks';
import { Company, Team } from '../../types';

export const TeamContext = createContext<{
  teamId?: string;
  companyId?: string;
  team?: Team;
  company?: Company;
}>(null);

export interface TeamContextProviderProps {
  /**
   * Force the context teamId to this id and disregard any team id in the path
   */
  teamId?: string;
  companyId?: string;
}

export const TeamContextProvider: FunctionComponent<PropsWithChildren<TeamContextProviderProps>> = ({ children, teamId, companyId }) => {
  const params = useParams<{ teamId?: string; companyId?: string }>();
  const { data: teams } = useAllTeams();
  const actualTeamId = teamId ?? params.teamId;
  const actualCompanyId = companyId ?? params.companyId;

  const team = useMemo(
    () => teams?.find((t) => t.id === actualTeamId),
    [actualTeamId, teams],
  );
  const company = useMemo(() => {
    if (!companyId && !params.companyId && actualTeamId) {
      return teams.find((t) => t.id === actualTeamId)?.company;
    }
    return null;
  }, [actualTeamId, companyId, params.companyId, teams]);

  return (
    <TeamContext.Provider
      value={{
        teamId: actualTeamId,
        companyId: actualCompanyId ?? company?.id,
        team,
        company,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeamContext = () => {
  const val = useContext(TeamContext);
  return val ?? { teamId: null };
};
