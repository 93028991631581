/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ArchivedOptions,
    ArchivedOptionsFromJSON,
    ArchivedOptionsToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Excel,
    ExcelFromJSON,
    ExcelToJSON,
    InsightsSelector,
    InsightsSelectorFromJSON,
    InsightsSelectorToJSON,
    OrderOptions,
    OrderOptionsFromJSON,
    OrderOptionsToJSON,
} from '../models';

export interface CreateCompanyExcelReportRequest {
    companyId: string;
    selector?: Array<InsightsSelector>;
    importance?: Array<number>;
    teamTags?: Array<string>;
    commentedBy?: Array<string>;
    assignees?: Array<string>;
    attachedBy?: Array<string>;
    dueFrom?: Date;
    dueTo?: Date;
    performedFrom?: Date;
    performedTo?: Date;
    from?: Date;
    to?: Date;
    orderBy?: Array<OrderOptions>;
    archived?: ArchivedOptions;
    uncompletedAssignedOnly?: boolean;
    teams?: Array<string>;
}

export interface CreateExcelReportRequest {
    teamId: string;
    selector?: Array<InsightsSelector>;
    importance?: Array<number>;
    teamTags?: Array<string>;
    commentedBy?: Array<string>;
    assignees?: Array<string>;
    attachedBy?: Array<string>;
    dueFrom?: Date;
    dueTo?: Date;
    performedFrom?: Date;
    performedTo?: Date;
    from?: Date;
    to?: Date;
    orderBy?: Array<OrderOptions>;
    archived?: ArchivedOptions;
    uncompletedAssignedOnly?: boolean;
}

/**
 * ExcelApi - interface
 * 
 * @export
 * @interface ExcelApiInterface
 */
export interface ExcelApiInterface {
    /**
     * 
     * @summary Create excel report of team actions
     * @param {string} companyId 
     * @param {Array<InsightsSelector>} [selector] select which actions should be selected
     * @param {Array<number>} [importance] filter activities with specific importance
     * @param {Array<string>} [teamTags] filter activities with specific tags
     * @param {Array<string>} [commentedBy] filter activities commented by specific users
     * @param {Array<string>} [assignees] filter activities assigned to specific users
     * @param {Array<string>} [attachedBy] filter by attachments made by users
     * @param {Date} [dueFrom] filter activities with due date after date
     * @param {Date} [dueTo] filter activities with due date before date
     * @param {Date} [performedFrom] filter activities performed after date
     * @param {Date} [performedTo] filter activities performed before date
     * @param {Date} [from] filter articles published after date
     * @param {Date} [to] filter articles published before date
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {ArchivedOptions} [archived] Select archive status. Default is not-archived
     * @param {boolean} [uncompletedAssignedOnly] returns only not completed assigned articles
     * @param {Array<string>} [teams] select articles from specified teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApiInterface
     */
    createCompanyExcelReportRaw(requestParameters: CreateCompanyExcelReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Excel>>;

    /**
     * Create excel report of team actions
     */
    createCompanyExcelReport(requestParameters: CreateCompanyExcelReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Excel>;

    /**
     * 
     * @summary Create excel report of team actions
     * @param {string} teamId 
     * @param {Array<InsightsSelector>} [selector] select which actions should be selected
     * @param {Array<number>} [importance] filter activities with specific importance
     * @param {Array<string>} [teamTags] filter activities with specific tags
     * @param {Array<string>} [commentedBy] filter activities commented by specific users
     * @param {Array<string>} [assignees] filter activities assigned to specific users
     * @param {Array<string>} [attachedBy] filter by attachments made by users
     * @param {Date} [dueFrom] filter activities with due date after date
     * @param {Date} [dueTo] filter activities with due date before date
     * @param {Date} [performedFrom] filter activities performed after date
     * @param {Date} [performedTo] filter activities performed before date
     * @param {Date} [from] filter articles published after date
     * @param {Date} [to] filter articles published before date
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {ArchivedOptions} [archived] Select archive status. Default is not-archived
     * @param {boolean} [uncompletedAssignedOnly] returns only not completed assigned articles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApiInterface
     */
    createExcelReportRaw(requestParameters: CreateExcelReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Excel>>;

    /**
     * Create excel report of team actions
     */
    createExcelReport(requestParameters: CreateExcelReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Excel>;

}

/**
 * 
 */
export class ExcelApi extends runtime.BaseAPI implements ExcelApiInterface {

    /**
     * Create excel report of team actions
     */
    async createCompanyExcelReportRaw(requestParameters: CreateCompanyExcelReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Excel>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling createCompanyExcelReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.selector) {
            queryParameters['selector'] = requestParameters.selector.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.importance) {
            queryParameters['importance'] = requestParameters.importance.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.teamTags) {
            queryParameters['teamTags'] = requestParameters.teamTags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.commentedBy) {
            queryParameters['commentedBy'] = requestParameters.commentedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.assignees) {
            queryParameters['assignees'] = requestParameters.assignees.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.attachedBy) {
            queryParameters['attachedBy'] = requestParameters.attachedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.dueFrom !== undefined) {
            queryParameters['dueFrom'] = (requestParameters.dueFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.dueTo !== undefined) {
            queryParameters['dueTo'] = (requestParameters.dueTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedFrom !== undefined) {
            queryParameters['performedFrom'] = (requestParameters.performedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedTo !== undefined) {
            queryParameters['performedTo'] = (requestParameters.performedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.archived !== undefined) {
            queryParameters['archived'] = requestParameters.archived;
        }

        if (requestParameters.uncompletedAssignedOnly !== undefined) {
            queryParameters['uncompletedAssignedOnly'] = requestParameters.uncompletedAssignedOnly;
        }

        if (requestParameters.teams) {
            queryParameters['teams'] = requestParameters.teams.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/actions/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExcelFromJSON(jsonValue));
    }

    /**
     * Create excel report of team actions
     */
    async createCompanyExcelReport(requestParameters: CreateCompanyExcelReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Excel> {
        const response = await this.createCompanyExcelReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create excel report of team actions
     */
    async createExcelReportRaw(requestParameters: CreateExcelReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Excel>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling createExcelReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.selector) {
            queryParameters['selector'] = requestParameters.selector.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.importance) {
            queryParameters['importance'] = requestParameters.importance.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.teamTags) {
            queryParameters['teamTags'] = requestParameters.teamTags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.commentedBy) {
            queryParameters['commentedBy'] = requestParameters.commentedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.assignees) {
            queryParameters['assignees'] = requestParameters.assignees.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.attachedBy) {
            queryParameters['attachedBy'] = requestParameters.attachedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.dueFrom !== undefined) {
            queryParameters['dueFrom'] = (requestParameters.dueFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.dueTo !== undefined) {
            queryParameters['dueTo'] = (requestParameters.dueTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedFrom !== undefined) {
            queryParameters['performedFrom'] = (requestParameters.performedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedTo !== undefined) {
            queryParameters['performedTo'] = (requestParameters.performedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.archived !== undefined) {
            queryParameters['archived'] = requestParameters.archived;
        }

        if (requestParameters.uncompletedAssignedOnly !== undefined) {
            queryParameters['uncompletedAssignedOnly'] = requestParameters.uncompletedAssignedOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/actions/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExcelFromJSON(jsonValue));
    }

    /**
     * Create excel report of team actions
     */
    async createExcelReport(requestParameters: CreateExcelReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Excel> {
        const response = await this.createExcelReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
