import { Trans, useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  BookmarkIcon,
  DashboardCardTitleIconWrapper,
  DashboardHeaderNavIconButton,
  SuspenseLoader,
} from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useAllArticles } from '../../../api/hooks';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { routes } from '../../../routes';
import { MessageKeys } from '../../../translations/translationTypes';
import {
  DashboardCardBodyInner,
  DashboardCardCountWrapper,
  DashboardCardListItem,
  DashboardCardListItemIconWrapper,
  DashboardCardListItemText,
  DashboardCardListWrapper,
  DashboardCardNoContent,
  DashboardCardTitleWrapper,
  RegeyeDashboardCard as DashboardCard,
} from '../Dashboard.styles';
import { MAX_ITEMS } from '../dashboardConstants';

export const DashboardBookmarksCard: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <DashboardCard
      title={
        <>
          <DashboardCardTitleIconWrapper>
            <BookmarkIcon size="14" />
          </DashboardCardTitleIconWrapper>{' '}
          <DashboardCardTitleWrapper>Bookmarks</DashboardCardTitleWrapper>
        </>
      }
      headerButton={
        <DashboardHeaderNavIconButton
          to={constructUrl(routes.personalBookmarked)}
        >
          {t(MessageKeys.DashboardViewAllBookmarks)}
        </DashboardHeaderNavIconButton>
      }
    >
      <ErrorBoundary>
        <SuspenseLoader>
          <DashboardBookmarksCardBody />
        </SuspenseLoader>
      </ErrorBoundary>
    </DashboardCard>
  );
};

interface DashboardBookmarksCardBodyProps {
  maxItems?: number;
}

export const DashboardBookmarksCardBody: FunctionComponent<
  DashboardBookmarksCardBodyProps
> = ({ maxItems = MAX_ITEMS }) => {
  const { t } = useTranslation();
  const { data } = useAllArticles({
    bookmarked: true,
  });

  return (
    <DashboardCardBodyInner>
      {data.items?.length ? (
        <>
          <DashboardCardCountWrapper>
            <Trans
              i18nKey={MessageKeys.DashboardBookmarkCount as string}
              values={{
                countStr: data.pagination.totalResults ?? 0,
              }}
              components={{
                count: <span className="count" />,
              }}
            />
          </DashboardCardCountWrapper>
          <DashboardCardListWrapper>
            {data.items.slice(0, maxItems).map((item) => (
              <DashboardCardListItem key={item.article.id}>
                <DashboardCardListItemIconWrapper>
                  <BookmarkIcon size="14" />
                </DashboardCardListItemIconWrapper>
                <DashboardCardListItemText
                  to={constructUrl(routes.feedArticleById, {
                    articleId: item.article.id,
                  })}
                  title={item.article.title}
                >
                  {item.article.title}
                </DashboardCardListItemText>
              </DashboardCardListItem>
            ))}
            {data.items.length > maxItems ? (
              <DashboardCardListItem>
                {t(MessageKeys.DashboardAndCountMoreArticles, {
                  count: data.pagination.totalResults - maxItems,
                })}
              </DashboardCardListItem>
            ) : null}
          </DashboardCardListWrapper>
        </>
      ) : (
        <DashboardCardNoContent>
          {t(MessageKeys.DashboardNoBookmarks)}
        </DashboardCardNoContent>
      )}
    </DashboardCardBodyInner>
  );
};
