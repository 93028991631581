import { useTranslation } from 'react-i18next';
import {
  CheckIcon,
  CrossIcon,
  PrimaryButton,
  SecondaryButton,
  useFlash,
} from '@fcg-tech/regtech-components';
import { TeamInvitation as TeamInvitationType } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useSWRConfig } from 'swr';
import { useInvitationActions } from '../../api/hooks';
import { allTeamsKey } from '../../api/hooks/cacheKeys';
import { MessageKeys } from '../../translations/translationTypes';
import {
  TeamInvitationItemActions,
  TeamInvitationItemInfo,
  TeamInvitationItemInfoMessage,
  TeamInvitationItemLabel,
  TeamInvitationItemValue,
  TeamInvitationItemWrapper,
  TeamInvitationStatus,
} from './TeamInvitation.styles';

enum InvitationStatus {
  Accepted = 1,
  Rejected = 2,
}

interface TeamInvitationProps {
  invitation: TeamInvitationType;
  narrow?: boolean;
}

export const TeamInvitation: FunctionComponent<TeamInvitationProps> = ({
  invitation,
  narrow,
}) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const { acceptInvitation, rejectInvitation } = useInvitationActions(
    invitation.id,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<InvitationStatus | null | undefined>(
    null,
  );

  const addFlash = useFlash();

  const handleAcceptClick = useCallback(async () => {
    try {
      setIsLoading(true);
      setStatus(InvitationStatus.Accepted);
      await acceptInvitation();
      mutate(allTeamsKey());
      addFlash({
        level: 'success',
        content: t(MessageKeys.TeamInvitationAcceptedMessage, {
          name: invitation.team?.name ?? 'N/A',
        }),
      });
    } catch (err) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.LabelCouldNotSave),
      });
      setIsLoading(false);
    }
  }, [acceptInvitation, addFlash, invitation.team?.name, mutate, t]);

  const handleRejectClick = useCallback(async () => {
    try {
      setIsLoading(true);
      setStatus(InvitationStatus.Rejected);
      await rejectInvitation();
      addFlash({
        level: 'success',
        content: t(MessageKeys.TeamInvitationRejectedMessage, {
          name: invitation.team?.name ?? 'N/A',
        }),
      });
    } catch (err) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.LabelCouldNotSave),
      });
    }
    setIsLoading(false);
  }, [addFlash, invitation.team?.name, rejectInvitation, t]);

  return (
    <TeamInvitationItemWrapper className={classNames(narrow && 'narrow')}>
      <TeamInvitationItemInfo>
        <TeamInvitationItemLabel>
          {t(MessageKeys.LabelTeam)}
        </TeamInvitationItemLabel>
        <TeamInvitationItemValue>
          {invitation.team?.name}
        </TeamInvitationItemValue>
      </TeamInvitationItemInfo>
      <TeamInvitationItemInfo>
        <TeamInvitationItemLabel>
          {t(MessageKeys.LabelCompany)}
        </TeamInvitationItemLabel>
        <TeamInvitationItemValue>
          {invitation.team?.company?.name}
        </TeamInvitationItemValue>
      </TeamInvitationItemInfo>
      <TeamInvitationItemInfo>
        <TeamInvitationItemLabel>
          {t(MessageKeys.TeamInvitationsSender)}
        </TeamInvitationItemLabel>
        <TeamInvitationItemValue>
          {invitation.createdBy?.alias}
        </TeamInvitationItemValue>
      </TeamInvitationItemInfo>
      <TeamInvitationItemInfoMessage>
        <TeamInvitationItemLabel>
          {t(MessageKeys.LabelMessage)}
        </TeamInvitationItemLabel>
        <TeamInvitationItemValue>{invitation.message}</TeamInvitationItemValue>
      </TeamInvitationItemInfoMessage>
      <TeamInvitationItemActions
        style={{ visibility: status ? 'hidden' : 'visible' }}
      >
        <SecondaryButton
          loading={isLoading && status === InvitationStatus.Rejected}
          disabled={Boolean(status)}
          style={{ width: '10rem' }}
          onClick={handleRejectClick}
        >
          {t(MessageKeys.LabelReject)}
        </SecondaryButton>
        <PrimaryButton
          loading={isLoading && status === InvitationStatus.Accepted}
          disabled={Boolean(status)}
          style={{ width: '10rem' }}
          onClick={handleAcceptClick}
        >
          {t(MessageKeys.LabelAccept)}
        </PrimaryButton>
      </TeamInvitationItemActions>
      {status ? (
        <TeamInvitationStatus>
          {status === InvitationStatus.Rejected ? (
            <CrossIcon className="error-color" size="22" />
          ) : (
            <CheckIcon className="success-color" size="22" />
          )}
        </TeamInvitationStatus>
      ) : null}
    </TeamInvitationItemWrapper>
  );
};
