import { DataTableColumn, RowRenderer } from '@fcg-tech/regtech-datatable';
import { TeamInvitation } from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeDataTable } from '../datatable/RegeyeDataTable';
import {
  RowData,
  TeamMemberInvitationsTableRow,
} from './TeamMemberInvitationsTableRow';

interface TeamMemberInvitationsTableProps {
  invitations: Array<TeamInvitation>;
  onRemoveInvitation?: (userId: string) => Promise<void>;
}

export const TeamMemberInvitationsTable: FunctionComponent<
  TeamMemberInvitationsTableProps
> = ({ invitations, onRemoveInvitation }) => {
  const { t } = useTranslation();
  const columns = useMemo<Array<DataTableColumn<RowData>>>(
    () => [
      {
        id: 'username',
        width: 100,
        minWidth: 100,
        Header: t(MessageKeys.LabelUsername),
        accessor: (row: RowData) => row.username,
      },
    ],
    [t],
  );

  const dataRows = useMemo<Array<RowData>>(() => {
    return invitations.map((invitation) => ({
      id: invitation.id,
      username: invitation.username,
    }));
  }, [invitations]);

  const WrappedRow = useMemo<RowRenderer<RowData>>(
    () => (props) =>
      (
        <TeamMemberInvitationsTableRow
          {...props}
          onRemove={onRemoveInvitation}
        />
      ),
    [onRemoveInvitation],
  );

  return <RegeyeDataTable columns={columns} data={dataRows} Row={WrappedRow} />;
};
