import color from 'color';

const ratio = 0.618033988749895;

/**
 *
 * @param hue range 0-1
 * @param saturation
 * @param value
 * @returns
 */
export const getRandomColor = (hue: number, saturation = 0.5, value = 0.95) => {
  hue += ratio;
  hue %= 1;

  if (typeof saturation !== 'number') {
    saturation = 0.5;
  }

  if (typeof value !== 'number') {
    value = 0.95;
  }

  return color({
    h: hue * 360,
    s: saturation * 100,
    v: value * 100,
  });
};

export const generateHueFromString = (str: string) => {
  let sum = 0;
  for (let i = 0; i < str.length; i++)
    sum += ((i + 1) * (str.codePointAt(i) ?? 1)) / (1 << 8);
  return sum % 1;
};

interface RGB {
  b: number;
  g: number;
  r: number;
}
function rgbToYIQ({ r, g, b }: RGB): number {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

export const contrast = (rgb: RGB | undefined, threshold = 128): string => {
  if (rgb === undefined) {
    return '#000';
  }

  return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
};
