import { excludeProps } from '@fcg-tech/regtech-components';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { useDevMode } from '../dev';

const DebugWrapper = styled.div.withConfig(excludeProps('withMargin'))<{
  withMargin?: boolean;
}>`
  border: 1px solid #444;
  background-color: yellow;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  min-width: 1rem;
  display: inline-flex;
  align-items: center;
  padding: 0.3rem 0.5rem;
  line-height: 1rem;

  ${({ withMargin }) =>
    withMargin
      ? css`
          margin: 0 1rem;
        `
      : ''}
`;

interface DebugProps {
  asTooltip?: boolean;
  withMargin?: boolean;
}

export const Debug: FunctionComponent<PropsWithChildren<DebugProps>> = ({
  children,
  asTooltip,
  withMargin,
}) => {
  const devMode = useDevMode();
  return devMode ? (
    <DebugWrapper withMargin={withMargin}>
      {asTooltip ? '' : children}
    </DebugWrapper>
  ) : null;
};
