import styled from 'styled-components';

export const FallbackWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6rem 20rem 6rem;
  width: 100%;
  height: 100vh;
  font-size: 2.6rem;
  font-weight: 600;
  text-align: center;
`;
