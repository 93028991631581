import {
  Badge,
  GRID_AREA_SIDEBAR,
  SideBar,
  SideNavigationCenteredWhenCollapsed,
  SideNavigationItemIcon,
  SideNavigationMenu,
} from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';
import { RegEyeText } from '../icons';

export const SIDE_NAVIGATION_WIDTH_REM = 24;

export const StyledSideBar = styled(SideBar)`
  width: ${SIDE_NAVIGATION_WIDTH_REM}rem;
  grid-area: ${GRID_AREA_SIDEBAR};

  &.collapsed {
    min-width: initial;
  }
`;

export const MainSideNavigationContent = styled(
  SideNavigationCenteredWhenCollapsed,
)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainSideNavigationMenuWrapper = styled.div`
  flex: 1;
`;

export const MainSideNavigationLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .collapsed & {
    justify-content: flex-start;
    margin-left: 1.2rem;
  }
`;

export const MainSideNavigationLogo = styled.img`
  width: 10rem;
  transform: width 0.2s linear;
  .collapsed & {
    width: 4rem;
  }
`;

export const MainSideNavigationBadgeWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 2.6rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`;

export const MainSideNavigationBadge = styled(Badge)`
  font-size: 1.1rem;
  padding-top: 0.1rem;

  & + & {
    margin-left: 0.6rem;
  }
`;

export const MainSideNavigationLogout = styled(SideNavigationMenu)`
  ${({ theme }) => css`
    display: none !important;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      display: flex !important;
      margin-top: 3rem;
    }
  `}
`;

export const MyAssignedIcon = styled(SideNavigationItemIcon)`
  position: relative;
  left: 0.2rem;
`;

export const StyledRegEyeText = styled(RegEyeText)`
  width: 10rem;
  position: relative;
  top: 0.2rem;
`;

export const MainSideNavigationAdminMenu = styled(SideNavigationMenu)`
  margin-top: 4rem;
`;

export const MainSideNavigationHiddenCountMenu = styled(SideNavigationMenu)`
  margin-top: 3rem;

  & + & {
    margin-top: 1rem;
  }
`;
