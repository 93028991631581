import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  TabBarNavigation,
  TabBarNavigationItem,
  ToolbarItemWrapper,
  useTabLinkState,
} from '@fcg-tech/regtech-components';
import { Filter, Layers, Tag, Users } from 'lucide-react';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { useIamContext } from '../../components/IamContext';
import { MainLayoutSideSubNavigationOutletWrapper } from '../../components/MainLayout/MainLayout.styles';
import { routes, TeamSettingKey } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { canViewPageTeamsAdministration } from '../../utils/iamHelpers';

export const TeamSettingsPageOutlet: FunctionComponent = () => {
  const { t } = useTranslation();
  const params = useParams<{ teamId: string }>();
  const tabState = useTabLinkState();
  const iamContext = useIamContext();
  const items = useMemo<Array<TabBarNavigationItem>>(
    () => [
      {
        to: constructUrl(routes.teamSettingsPage, {
          teamId: params.teamId,
          settingKey: TeamSettingKey.Subscriptions,
        }),
        label: () => (
          <ToolbarItemWrapper>
            <Layers size="18" />
            {t(MessageKeys.TeamSettingsTabSubscriptionsLabel)}
          </ToolbarItemWrapper>
        ),
      },
      {
        to: constructUrl(routes.teamSettingsPage, {
          teamId: params.teamId,
          settingKey: TeamSettingKey.Members,
        }),
        label: () => (
          <ToolbarItemWrapper>
            <Users size="18" />
            {t(MessageKeys.TeamSettingsTabMembersLabel)}
          </ToolbarItemWrapper>
        ),
      },
      {
        to: constructUrl(routes.teamSettingsPage, {
          teamId: params.teamId,
          settingKey: TeamSettingKey.Tags,
        }),
        label: () => (
          <ToolbarItemWrapper>
            <Tag size="18" />
            {t(MessageKeys.TeamSettingsTabTagsLabel)}
          </ToolbarItemWrapper>
        ),
      },
      {
        to: constructUrl(routes.teamSettingsPage, {
          teamId: params.teamId,
          settingKey: TeamSettingKey.Filters,
        }),
        label: () => (
          <ToolbarItemWrapper>
            <Filter size="18" />
            {t(MessageKeys.TeamSettingsTabFiltersLabel)}
          </ToolbarItemWrapper>
        ),
      },
    ],
    [params.teamId, t],
  );

  return (
    <MainLayoutSideSubNavigationOutletWrapper>
      <div className="menu">
        <TabBarNavigation
          vertical
          items={items}
          tabLinkState={tabState}
          tabBarAriaLabel={t(
            canViewPageTeamsAdministration(params.teamId, iamContext)
              ? MessageKeys.SideMenuTeamAdministration
              : MessageKeys.SideMenuTeamAbout,
          )}
        />
      </div>
      <div className="content">
        <Outlet />
      </div>
    </MainLayoutSideSubNavigationOutletWrapper>
  );
};
