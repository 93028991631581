import { useTranslation } from 'react-i18next';
import { RichTextAreaProps } from '@fcg-tech/regtech-richtext';
import {
  Team,
  TeamActions,
  TeamArticleMetadata,
} from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useCallback } from 'react';
import { Descendant } from 'slate';
import { useTeamActivitiesForArticleActions } from '../../api/hooks';
import { MessageKeys } from '../../translations/translationTypes';
import { commentNotDeletedFilter } from '../../utils/sorters';
import { useAccessControl } from '../AccessControl';
import { Comments } from '../Comments/Comments';
import { findMentions } from '../Comments/commentUtils';
import { FeedArticleTeamMetadataContentSectionRowLabel } from './FeedArticleTeamMetadata.styles';

interface FeedArticleTeamMetadataCommentsProps
  extends Pick<RichTextAreaProps, 'portalContainer'> {
  articleId: string;
  metadata: TeamArticleMetadata;
  team: Team;
  disabled?: boolean;
}

export const FeedArticleTeamMetadataComments: FunctionComponent<FeedArticleTeamMetadataCommentsProps> =
  ({ articleId, metadata, team, disabled, portalContainer }) => {
    const { t } = useTranslation();
    const { addComment, updateComment, deleteComment } =
      useTeamActivitiesForArticleActions(team.id, articleId);

    const handleSubmitComment = useCallback(
      (plainText: string, markup: Array<Descendant>, replyTo?: string) =>
        addComment(plainText, markup, findMentions(markup), replyTo),
      [addComment],
    );

    const handleUpdateComment = useCallback(
      (commentId: string, plainText: string, markup: Array<Descendant>) =>
        updateComment(commentId, plainText, markup, findMentions(markup)),
      [updateComment],
    );

    const canComment = useAccessControl(TeamActions.ArticleCommentCreate);
    const canUpdate = useAccessControl(TeamActions.ArticleCommentUpdate);
    const canDelete = useAccessControl(TeamActions.ArticleCommentDelete);

    return (
      <React.Fragment>
        <FeedArticleTeamMetadataContentSectionRowLabel>
          {t(MessageKeys.ArticleMetadataNrOfComments, {
            count: metadata?.comments?.filter(commentNotDeletedFilter)?.length ?? 0
          })}
        </FeedArticleTeamMetadataContentSectionRowLabel>
        <Comments
          comments={metadata?.comments ?? []}
          teamMembers={team.members}
          portalContainer={portalContainer}
          onSubmitComment={!disabled && canComment ? handleSubmitComment : null}
          onDeleteComment={!disabled && canDelete ? deleteComment : null}
          onEditComment={!disabled && canUpdate ? handleUpdateComment : null}
        />
      </React.Fragment>
    );
  };
