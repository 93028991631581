import styled, { css } from 'styled-components';

export const IconLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    background: transparent;
    color: inherit;
    text-decoration: none;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      width: 130%;
      height: 130%;
      border-radius: 50%;
      top: calc(-15%-2px);
      left: calc(-15%-2px);
      z-index: -1;
      border: 2px solid transparent;
    }

    &:hover:before {
      background-color: ${theme?.palette?.MainColorAccent?.main ?? '#ccc'};
    }

    &:focus {
      outline: 0;
    }

    &:focus:before {
      border: 2px dashed rgba(0, 0, 0, 0.1);
    }

    &:active:before {
      background-color: ${theme?.palette?.MainColorAccent?.main ?? '#ccc'};
      border-color: ${theme?.palette?.MainColorAccent?.dark ?? '#444'};
    }

    &:disabled {
      opacity: 0.4;
    }
  `}
`;
