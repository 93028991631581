import {
  ColumnsIcon,
  useClickOutside,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  FilterBarButton,
  FilterBarColumnSelectMenu,
  FilterBarColumnSelectWrapper,
} from '@fcg-tech/regtech-filter';
import { useEscapeKey } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedArticleTableColumns } from '../../types';
import { ColumnSelector } from '../ColumnSelector';
import { useFeedArticleTableContext } from '../FeedArticleTable/FeedArticleTableContext';
import { getTableTypeColumns } from '../FeedArticleTable/feedArticleTableHelpers';

interface FilterBarColumnSelectorProps {
  disabled?: boolean;
}

export const FilterBarColumnSelector: FunctionComponent<
  FilterBarColumnSelectorProps
> = ({ disabled }) => {
  const { t } = useTranslation();

  const { columnOptions, updateColumnOptions, tableType, domain } =
    useFeedArticleTableContext();

  const allowedColumns = getTableTypeColumns(tableType, domain, t);

  const selectedColumns = useMemo(() => {
    if (!columnOptions) {
      return allowedColumns.map((c) => columnOptions?.[c.id]?.visible ?? true);
    }

    return allowedColumns
      .map((c) => c.id)
      .filter((c) => columnOptions?.[c]?.visible ?? true);
  }, [allowedColumns, columnOptions]);

  const columnSelectContainer = useRef<HTMLDivElement>();
  const [showColumnSelector, setShowColumnSelector, toggleShowColumnSelector] =
    useToggle();
  const closeColumnSelector = useCallback(
    () => setShowColumnSelector(false),
    [setShowColumnSelector],
  );
  useClickOutside(columnSelectContainer, closeColumnSelector);
  useEscapeKey({ callback: closeColumnSelector });

  const handleColumnsChanged = useCallback(
    (selected: Array<FeedArticleTableColumns>) => {
      updateColumnOptions((prevColumnOptions) => {
        const selectedSet = new Set(selected);
        let updatedOptions = prevColumnOptions;
        allowedColumns.forEach((c) => {
          if (!updatedOptions[c.id]) {
            updatedOptions = update(updatedOptions, {
              [c.id]: {
                $set: {
                  visible: selectedSet.has(c.id as FeedArticleTableColumns),
                },
              },
            });
          } else {
            updatedOptions = update(updatedOptions, {
              [c.id]: {
                visible: {
                  $set: selectedSet.has(c.id as FeedArticleTableColumns),
                },
              },
            });
          }
        });
        return updatedOptions;
      });
    },
    [allowedColumns, updateColumnOptions],
  );
  return (
    <FilterBarColumnSelectWrapper
      ref={columnSelectContainer}
      id="column-picker-button-wrapper"
    >
      <FilterBarButton disabled={disabled} onClick={toggleShowColumnSelector}>
        <ColumnsIcon size="18" />
      </FilterBarButton>
      {showColumnSelector ? (
        <FilterBarColumnSelectMenu>
          <ColumnSelector
            allowedColumns={allowedColumns}
            selectedColumns={selectedColumns as Array<FeedArticleTableColumns>}
            onChange={handleColumnsChanged}
          />
        </FilterBarColumnSelectMenu>
      ) : null}
    </FilterBarColumnSelectWrapper>
  );
};
