import { DataTableRowProps, DataTableRow } from '@fcg-tech/regtech-datatable';
import { classNames, useMouseHover } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { FeedArticle, FeedArticleMetadataMutationCallbacks } from '../../types';
import { FeedArticleExpandedInfo } from './FeedArticleExpandedInfo';
import { TableRowSuspenseLoader } from './FeedArticleTable.styles';
import { FeedArticleTableRowActionBar } from './FeedArticleTableRowActionBar';

interface FeedArticleTableRowProps
  extends DataTableRowProps<FeedArticle>,
    FeedArticleMetadataMutationCallbacks {
  maxHeight?: number;
  maxWidth?: number;
  onRequestClose?: (articleId: string) => void;
  onTagClick?: (tagId: string) => void;
  onTeamTagClick?: (tagId: string, teamId: string) => void;
  onArticleTypeClick?: (tagId: string) => void;
  onContentLoad?: (container: HTMLDivElement) => void;
  onArticleMetadataMutated?: (articleId: string) => void;
}

export const FeedArticleTableRow: FunctionComponent<
  FeedArticleTableRowProps
> = ({
  maxHeight,
  maxWidth,
  isAnyColumnResizing,
  onRequestClose,
  onTagClick,
  onTeamTagClick,
  onArticleTypeClick,
  onContentLoad,
  onArticleMetadataMutated,
  onBookmark,
  onMarkAsRead,
  onArchive,
  ...props
}) => {
  const expandedRowRef = useRef<HTMLDivElement>(undefined);
  const { row } = props;
  const expanded = useMemo(
    () => props.expanded?.includes(row.id),
    [props.expanded, row.id],
  );
  const feedArticle = row.original;

  const handleRequestClose = useCallback(() => {
    if (expanded) {
      onRequestClose(feedArticle.internalId);
    }
  }, [expanded, feedArticle.internalId, onRequestClose]);

  const height = useMemo(
    () => maxHeight ?? Math.round(window.innerHeight * 0.75),
    [maxHeight],
  );

  const handleContentLoad = useCallback(() => {
    onContentLoad?.(expandedRowRef.current);
  }, [onContentLoad]);

  const { isMouseOver, ...hoverProps } = useMouseHover({
    disable: isAnyColumnResizing,
  });

  if (expanded) {
    return (
      <div
        key={row.getRowProps().key}
        className="tr-expanded"
        ref={expandedRowRef}
        data-id={row.id}
        data-articleid={feedArticle.article.id}
      >
        <TableRowSuspenseLoader height={height}>
          <FeedArticleExpandedInfo
            feedArticle={row.original}
            maxHeight={height}
            maxWidth={maxWidth}
            className="tr-expanded-info"
            onRequestClose={handleRequestClose}
            onTagClick={onTagClick}
            onTeamTagClick={onTeamTagClick}
            onArticleTypeClick={onArticleTypeClick}
            onContentLoad={handleContentLoad}
            onArticleMetadataMutated={onArticleMetadataMutated}
            onBookmark={onBookmark}
            onMarkAsRead={onMarkAsRead}
            onArchive={onArchive}
          />
        </TableRowSuspenseLoader>
      </div>
    );
  }
  return (
    <DataTableRow
      {...props}
      {...hoverProps}
      data-articleid={feedArticle.article.id}
      className={classNames(
        props.className,
        feedArticle.userMetadata.read && 'read',
      )}
    >
      <div
        className={classNames('tr-hover-container', isMouseOver && 'visible')}
        data-kind="hover"
      >
        <FeedArticleTableRowActionBar
          feedArticle={feedArticle}
          onBookmark={onBookmark}
          onMarkAsRead={onMarkAsRead}
          onArchive={onArchive}
        />
      </div>
    </DataTableRow>
  );
};
