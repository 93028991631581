import { useTranslation } from 'react-i18next';
import { DateField } from '@fcg-tech/regtech-components';
import { format } from 'date-fns';
import React, { FunctionComponent, useCallback } from 'react';
import { useTeamActivitiesForArticleActions } from '../../api/hooks';
import { TeamActions, TeamArticleMetadata } from '../../api/schema';
import { TABLE_DATE_FORMAT } from '../../constants';
import { MessageKeys } from '../../translations/translationTypes';
import { AccessControl, useAccessControl } from '../AccessControl';
import {
  FeedArticleTeamMetadataContentWidgetDisabledWrapper,
  FeedArticleTeamMetadataContentDueDateWrapper,
  FeedArticleTeamMetadataContentSectionRow,
  FeedArticleTeamMetadataContentSectionRowLabel,
} from './FeedArticleTeamMetadata.styles';

interface FeedArticleTeamMetadataDueDateProps {
  articleId: string;
  metadata: TeamArticleMetadata;
  teamId: string;
  disabled?: boolean;
  onMutated?: () => void;
}

export const FeedArticleTeamMetadataDueDate: FunctionComponent<FeedArticleTeamMetadataDueDateProps> =
  ({ articleId, metadata, teamId, disabled, onMutated }) => {
    const { t } = useTranslation();
    const { setDueDate } = useTeamActivitiesForArticleActions(
      teamId,
      articleId,
    );

    const handleDueDateChange = useCallback(
      async (dueDate: Date) => {
        await setDueDate(dueDate);
        onMutated?.();
      },
      [setDueDate, onMutated],
    );

    const handleDueDateReset = useCallback(async () => {
      await setDueDate(null);
      onMutated?.();
    }, [setDueDate, onMutated]);

    return (
      <FeedArticleTeamMetadataContentSectionRow>
        <FeedArticleTeamMetadataContentSectionRowLabel>
          {t(MessageKeys.ArticleMetadataDueDate)}
        </FeedArticleTeamMetadataContentSectionRowLabel>
        <AccessControl requiredPermissions={TeamActions.ArticleDueDateChange}>
          <FeedArticleTeamMetadataContentDueDateWrapper>
            <DateField
              value={metadata?.dueDate?.dueDate}
              isEditEnabled={!disabled}
              onChange={handleDueDateChange}
              onReset={handleDueDateReset}
            />
          </FeedArticleTeamMetadataContentDueDateWrapper>
        </AccessControl>
        <AccessControl
          requiredPermissions={TeamActions.ArticleDueDateChange}
          inverted
        >
          <FeedArticleTeamMetadataContentWidgetDisabledWrapper>
            {metadata?.dueDate?.dueDate ? (
              format(metadata?.dueDate?.dueDate, TABLE_DATE_FORMAT)
            ) : (
              t(MessageKeys.AccessErrorSetDueDate)
            )}
          </FeedArticleTeamMetadataContentWidgetDisabledWrapper>
        </AccessControl>
      </FeedArticleTeamMetadataContentSectionRow>
    );
  };
