import { MultiTenantAuthProvider as AuthProvider } from '@fcg-tech/regtech-auth';
import {
  ApplicationStateContextProvider,
  GlobalStyle,
} from '@fcg-tech/regtech-components';
import { theme } from '@fcg-tech/regtech-design-system/neutral';
import { getCurrentPathAndQuery } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { css, ThemeProvider } from 'styled-components';
import { IamContextProvider } from '../../components/IamContext';
import { InitContextProvider } from '../../components/InitContext';
import { StyledRegEyeText } from '../../components/MainSideNavigation/MainSideNavigation.styles';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { environment } from '../../environments/environment';
import { AuthenticatedApp } from './AuthenticatedApp';
import { AuthErrorBoundary } from './AuthErrorBoundary';
import { SwrConfigWrapper } from './SwrConfigWrapper';

export const App: FunctionComponent = () => {
  const finalTheme = useMemo(() => {
    const copy = {
      ...theme,
    };
    copy.elements.sideBar.logoWrapper = css`
      & img {
        display: none;
      }
    `;

    return copy;
  }, []);

  const logo = useMemo(
    () => () =>
      (
        <StyledRegEyeText
          color={finalTheme?.palette?.MainColor?.main ?? 'black'}
        />
      ),
    [finalTheme?.palette?.MainColor?.main],
  );

  return (
    <ThemeProvider theme={finalTheme}>
      <ApplicationStateContextProvider>
        <RecoilRoot>
          <GlobalStyle />
          <AuthProvider
            hostedLoginBaseUrl={environment.hostedLoginBaseUrl}
            selectCognitoApiBaseUrl={environment.selectCognitoApiBaseUrl}
            returnUrl={getCurrentPathAndQuery}
            getEmailPromptContent={logo}
          >
            <SuspenseLoader className="maximized">
              <AuthErrorBoundary>
                <SwrConfigWrapper>
                  <InitContextProvider>
                    <IamContextProvider>
                      <Router>
                        <AuthenticatedApp />
                      </Router>
                    </IamContextProvider>
                  </InitContextProvider>
                </SwrConfigWrapper>
              </AuthErrorBoundary>
            </SuspenseLoader>
          </AuthProvider>
        </RecoilRoot>
      </ApplicationStateContextProvider>
    </ThemeProvider>
  );
};
