import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAllArticles } from '../../api/hooks';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routeFragments } from '../../routes';
import { ActivityLogArticlesPage } from '../ActivityLogArticles';
import { GenericPaginatedFeedArticlePage } from '../GenericPaginatedFeedArticlePage/GenericPaginatedFeedArticlePage';
import { ActionsArticlesPage } from '../Insights/ActionsArticlesPage';
import { MyProfileAdvancedSettings } from '../MyProfilePage/MyProfileAdvancedSettings';
import { MyProfileColumnSettings } from '../MyProfilePage/MyProfileColumnSettings';
import { MyProfileDisplaySettingsTab } from '../MyProfilePage/MyProfileDisplaySettingsTab';
import { MyProfileMyCompaniesAndTeamsTab } from '../MyProfilePage/MyProfileMyCompaniesAndTeamsTab';
import { MyProfileMyFiltersTab } from '../MyProfilePage/MyProfileMyFiltersTab';
import { MyProfileNotifcationSettingsTab } from '../MyProfilePage/MyProfileNotifcationSettingsTab';
import { MyProfilePageOutlet } from '../MyProfilePage/MyProfilePageOutlet';
import { PersonalPageOutlet } from './PersonalPageOutlet';

export const PersonalBaseContainer: FunctionComponent = () => {
  return (
    <ErrorBoundary>
      <SuspenseLoader>
        <Routes>
          <Route element={<PersonalPageOutlet />} path="/">
            <Route
              path={routeFragments.personalAllTeamSubscriptions}
              element={
                <GenericPaginatedFeedArticlePage
                  useFeedArticles={useAllArticles}
                />
              }
            />
            <Route
              path={routeFragments.personalBookmarked}
              element={
                <GenericPaginatedFeedArticlePage
                  useFeedArticles={useAllArticles}
                />
              }
            />
            <Route
              path={routeFragments.personalCommentedByMe}
              element={<ActionsArticlesPage />}
            />
            <Route
              path={routeFragments.personalAssignedToMe}
              element={<ActionsArticlesPage />}
            />
            <Route
              path={routeFragments.personalEventLog}
              element={<ActivityLogArticlesPage />}
            />
            <Route
              path={routeFragments.personalProfile}
              element={<MyProfilePageOutlet />}
            >
              <Route
                path={routeFragments.personalProfileTeams}
                element={<MyProfileMyCompaniesAndTeamsTab />}
              />
              <Route
                path={routeFragments.personalProfileFilters}
                element={<MyProfileMyFiltersTab />}
              />
              <Route
                path={routeFragments.personalProfileDisplaySettings}
                element={<MyProfileDisplaySettingsTab />}
              />
              <Route
                path={routeFragments.personalProfileColumnSettings}
                element={<MyProfileColumnSettings />}
              />
              <Route
                path={routeFragments.personalProfileNotifications}
                element={<MyProfileNotifcationSettingsTab />}
              />
              <Route
                path={routeFragments.personalProfileAdvancedSettings}
                element={<MyProfileAdvancedSettings />}
              />
            </Route>
          </Route>
        </Routes>
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
