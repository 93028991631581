/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatApiDate } from '@fcg-tech/regtech-api-utils';
import { Maybe } from '@fcg-tech/regtech-types';
import {
  ApiDocumentLocation,
  DocumentLocation,
  TeamActions,
  TeamArticleMetadata,
  TeamMetadataResponse,
} from '@fcg-tech/regtech-types/regeye';
import { single } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import { useMemo } from 'react';
import { Descendant } from 'slate';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import { ScopedMutator } from 'swr/dist/types';
import { useAccessControl } from '../../components/AccessControl';
import { convertTeamArticleMetadata } from '../../converters';
import { useApi } from '../apiUtils';
import { ApiResponse, TeamActionApi, TeamAttachmentsApi } from '../schema';
import { articleMetaDataForTeamKey, articleMetaDataKey } from './cacheKeys';
import { useTeamTagActions } from './teamApiHooks';
import { deleteAttachment, uploadAttachment } from './teamAttachmentApiHooks';

const getArticleMetadataForTeam = async (
  articleId: string,
  teamId: string,
  api: TeamActionApi,
): Promise<TeamArticleMetadata> => {
  const result = await api.getArticleMetadataForTeam({ articleId, teamId });
  return convertTeamArticleMetadata(result.metadata as TeamArticleMetadata);
};

export const useArticleMetadataForTeam = (
  teamId: string,
  articleId: string,
  config: SWRConfiguration = {},
) => {
  const api = useApi<TeamActionApi>('TeamActionApi');
  const allowed = useAccessControl(TeamActions.TeamMetadataGet);
  return useSWR<TeamArticleMetadata>(
    allowed ? articleMetaDataForTeamKey(teamId, articleId) : null,
    async () => getArticleMetadataForTeam(articleId, teamId, api),
    { suspense: true, ...config },
  );
};

export const useTeamArticleTagActions = (teamId: string, articleId: string) => {
  const api = useApi<TeamActionApi>('TeamActionApi');
  const { mutate } = useSWRConfig();
  const { createTags } = useTeamTagActions(teamId);

  return useMemo(
    () => ({
      createArticleTag: async (name: string, description = '') => {
        mutate(
          articleMetaDataForTeamKey(teamId, articleId),
          async (current: TeamArticleMetadata) =>
            update(current, {
              tags: { $push: [{ id: 'temporary', name }] },
            }),
        );
        const [created, failed] = await createTags([
          { name, description, active: true },
        ]);

        if (created.length) {
          return addOrRemoveArticleTag(
            articleId,
            teamId,
            single(created).id,
            true,
            api,
            mutate,
          );
        }
        throw new Error(`Failed to create tag: ${failed.join(', ')}`);
      },

      addArticleTag: (tagId: string) => {
        return addOrRemoveArticleTag(
          articleId,
          teamId,
          tagId,
          true,
          api,
          mutate,
        );
      },

      removeArticleTag: (tagId: string) => {
        return addOrRemoveArticleTag(
          articleId,
          teamId,
          tagId,
          false,
          api,
          mutate,
        );
      },
    }),
    [api, articleId, createTags, mutate, teamId],
  );
};

const addOrRemoveArticleTag = async (
  articleId: string,
  teamId: string,
  tagId: string,
  add = true,
  api: TeamActionApi,
  mutate: ScopedMutator<unknown>,
) => {
  mutate(
    articleMetaDataForTeamKey(teamId, articleId),
    (current: TeamArticleMetadata) => {
      if (!add) {
        const index = current.tags.findIndex(({ id }) => id === tagId);
        if (index >= 0) {
          return update(current, { tags: { $splice: [[index, 1]] } });
        }
      }
      return current;
    },
    false,
  );

  const response = await (add
    ? api.addArticleTeamTagRaw({ teamId, articleId, tagId })
    : api.deleteArticleTeamTagRaw({ teamId, articleId, tagId }));

  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const readAndMutateTeamArticleData = async (
  teamId: string,
  articleId: string,
  response: ApiResponse<TeamMetadataResponse>,
  mutate: ScopedMutator<unknown>,
) => {
  const data = await response.value();
  mutate(
    articleMetaDataForTeamKey(teamId, articleId),
    convertTeamArticleMetadata(data.metadata as TeamArticleMetadata),
    false,
  );

  return data.metadata;
};

const addOrRemoveAssigned = async (
  teamId: string,
  articleId: string,
  username: string,
  add: boolean,
  api: TeamActionApi,
  mutate: ScopedMutator<any>,
) => {
  mutate(
    articleMetaDataForTeamKey(teamId, articleId),
    (current: TeamArticleMetadata) => {
      if (add) {
        return update(current, {
          assigned: {
            $push: [{ user: { username, alias: '' }, completed: false }],
          },
        });
      } else {
        const index = current.assigned.findIndex(
          (assigned) => assigned.user.username === username,
        );
        if (index >= 0) {
          return update(current, { assigned: { $splice: [[index, 1]] } });
        }
      }
      return current;
    },
    false,
  );

  const response = await (add
    ? api.addArticleTeamAssigneeRaw({
        teamId,
        articleId,
        userReference: {
          username,
        },
      })
    : api.deleteArticleTeamAssigneeRaw({
        teamId,
        articleId,
        userReference: {
          username,
        },
      }));

  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

export const addArticleAttachment = async (
  teamId: string,
  articleId: string,
  attachments: Array<File | DocumentLocation>, // All entries must be of the same type
  api: TeamActionApi,
  attachmentApi: TeamAttachmentsApi,
  mutate: ScopedMutator<any>,
) => {
  let response: ApiResponse<TeamMetadataResponse>;
  if (attachments[0] instanceof File) {
    const uploadInfos = await Promise.all(
      attachments.map((a) =>
        uploadAttachment(teamId, a as File, attachmentApi),
      ),
    );
    response = await api.addArticleAttachmentRaw({
      articleId,
      teamId,
      documentLocationList: {
        items: uploadInfos.map((uploadInfo) => ({
          customFile: true,
          name: uploadInfo.fileInfo.name,
          ref: uploadInfo.fileInfo.id,
        })),
      },
    });
  } else {
    response = await api.addArticleAttachmentRaw({
      articleId,
      teamId,
      documentLocationList: {
        items: attachments as Array<ApiDocumentLocation>,
      },
    });
  }

  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

export const deleteArticleAttachment = async (
  teamId: string,
  articleId: string,
  attachmentId: string,
  documentLocation: DocumentLocation,
  api: TeamActionApi,
  attachmentApi: TeamAttachmentsApi,
  mutate: ScopedMutator<any>,
) => {
  const response = await api.deleteArticleAttachmentRaw({
    articleId,
    teamId,
    attachmentId,
  });

  if (documentLocation.customFile) {
    await deleteAttachment(
      teamId,
      documentLocation.ref as string,
      attachmentApi,
    );
  }
  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const setImportance = async (
  teamId: string,
  articleId: string,
  importance: number,
  api: TeamActionApi,
  mutate: ScopedMutator<any>,
) => {
  mutate(
    articleMetaDataForTeamKey(teamId, articleId),
    (current: TeamArticleMetadata) =>
      update(current, { importance: { $set: { importance } } }),
    false,
  );

  const response = await (importance <= 0
    ? api.deleteArticleTeamImportanceRaw({ articleId, teamId })
    : api.setArticleTeamImportanceRaw({
        articleId,
        teamId,
        importanceInput: { importance },
      }));

  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const setDueDate = async (
  teamId: string,
  articleId: string,
  dueDate: Date | null,
  api: TeamActionApi,
  mutate: ScopedMutator<any>,
) => {
  mutate(
    articleMetaDataForTeamKey(teamId, articleId),
    (current: TeamArticleMetadata) =>
      update(current, { dueDate: { $set: { dueDate } } }),
    false,
  );
  const response = await (dueDate
    ? api.setArticleTeamDueDateRaw({
        teamId,
        articleId,
        dateInput: { dueDate: formatApiDate(dueDate) },
      })
    : api.deleteArticleTeamDueDateRaw({ teamId, articleId }));

  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const addComment = async (
  teamId: string,
  articleId: string,
  comment: string,
  markup: Array<Descendant>,
  replyTo: Maybe<string>,
  mentions: Array<string>,
  api: TeamActionApi,
  mutate: ScopedMutator<any>,
) => {
  const response = await api.addArticleTeamCommentRaw({
    teamId,
    articleId,
    commentCreateInput: { comment, replyTo, markup: { markup }, mentions },
  });

  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const updateComment = async (
  teamId: string,
  articleId: string,
  commentId: string,
  comment: string,
  markup: Array<Descendant>,
  mentions: Array<string>,
  api: TeamActionApi,
  mutate: ScopedMutator<any>,
) => {
  const response = await api.updateArticleTeamCommentRaw({
    teamId,
    articleId,
    commentId,
    commentUpdateInput: { comment, markup: { markup }, mentions },
  });

  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const deleteComment = async (
  teamId: string,
  articleId: string,
  commentId: string,
  api: TeamActionApi,
  mutate: ScopedMutator<any>,
) => {
  const response = await api.deleteArticleTeamCommentRaw({
    teamId,
    articleId,
    commentId,
  });

  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const archiveArticle = async (
  teamId: string,
  articleId: string,
  api: TeamActionApi,
) => {
  await api.archiveArticle({ teamId, articleId });
};

const unarchiveArticle = async (
  teamId: string,
  articleId: string,
  api: TeamActionApi,
) => {
  await api.unarchiveArticle({ teamId, articleId });
};

const completeArticleTeamAssignment = async (
  teamId: string,
  articleId: string,
  api: TeamActionApi,
  mutate: ScopedMutator<any>,
) => {
  const response = await api.completeArticleTeamAssignmentRaw({
    articleId,
    teamId,
  });
  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const uncompleteArticleTeamAssignment = async (
  teamId: string,
  articleId: string,
  api: TeamActionApi,
  mutate: ScopedMutator<any>,
) => {
  const response = await api.uncompleteArticleTeamAssignmentRaw({
    articleId,
    teamId,
  });
  return readAndMutateTeamArticleData(teamId, articleId, response, mutate);
};

const notifyTeam = async (
  teamId: string,
  articleId: string,
  api: TeamActionApi,
) => {
  return await api.notifyTeam({
    teamId,
    articleId,
  });
};

export const useTeamActivitiesForArticleActions = (
  teamId?: string,
  articleId?: string,
) => {
  const api = useApi<TeamActionApi>('TeamActionApi');
  const attachmentApi = useApi<TeamAttachmentsApi>('TeamAttachmentsApi');
  const { mutate } = useSWRConfig();
  const callbacks = useMemo(
    () => ({
      setAssigned: (userId: string) =>
        addOrRemoveAssigned(teamId, articleId, userId, true, api, mutate),
      removeAssigned: (userId: string) =>
        addOrRemoveAssigned(teamId, articleId, userId, false, api, mutate),
      addAttachment: (attachment: Array<File | ApiDocumentLocation>) =>
        addArticleAttachment(
          teamId,
          articleId,
          attachment,
          api,
          attachmentApi,
          mutate,
        ),
      deleteAttachment: (id: string, documentLocation: ApiDocumentLocation) =>
        deleteArticleAttachment(
          teamId,
          articleId,
          id,
          documentLocation,
          api,
          attachmentApi,
          mutate,
        ),
      setImportance: (importance: number) =>
        setImportance(teamId, articleId, importance, api, mutate),
      setDueDate: (dueDate: Date) =>
        setDueDate(teamId, articleId, dueDate, api, mutate),
      addComment: (
        comment: string,
        markup: Array<Descendant>,
        mentions: Array<string>,
        replyTo?: string,
      ) =>
        addComment(
          teamId,
          articleId,
          comment,
          markup,
          replyTo,
          mentions,
          api,
          mutate,
        ),
      updateComment: (
        commentId: string,
        comment: string,
        markup: Array<Descendant>,
        mentions: Array<string>,
      ) =>
        updateComment(
          teamId,
          articleId,
          commentId,
          comment,
          markup,
          mentions,
          api,
          mutate,
        ),
      deleteComment: (commentId: string) =>
        deleteComment(teamId, articleId, commentId, api, mutate),
      completeArticleTeamAssignment: () =>
        completeArticleTeamAssignment(teamId, articleId, api, mutate),
      uncompleteArticleTeamAssignment: () =>
        uncompleteArticleTeamAssignment(teamId, articleId, api, mutate),
      unarchiveArticle: (forceTeamId?: string, forceArticleId?: string) =>
        unarchiveArticle(
          forceTeamId ?? teamId,
          forceArticleId ?? articleId,
          api,
        ),
      archiveArticle: (forceTeamId?: string, forceArticleId?: string) =>
        archiveArticle(forceTeamId ?? teamId, forceArticleId ?? articleId, api),

      notifyTeam: async (forceTeamId?: string, forceArticleId?: string) => {
        const result = await notifyTeam(
          forceTeamId ?? teamId,
          forceArticleId ?? articleId,
          api,
        );
        mutate(articleMetaDataKey(articleId));
        return result;
      },
    }),
    [teamId, articleId, api, mutate, attachmentApi],
  );

  return callbacks;
};
