import { useTranslation } from 'react-i18next';
import {
  ReactSelectOption,
  useCloseSelectOnScroll,
} from '@fcg-tech/regtech-components';
import memoizee from 'memoizee';
import { FunctionComponent, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import {
  useCurrentUser,
  useTeamActivitiesForArticleActions,
} from '../../api/hooks';
import {
  TeamActions,
  TeamArticleMetadata,
  TeamReadMetadata,
  User,
} from '../../api/schema';
import { MessageKeys } from '../../translations/translationTypes';
import { Settings } from '../../types';
import { formatName } from '../../utils/formatters';
import { userSorter } from '../../utils/sorters';
import { useAccessControl } from '../AccessControl';
import { SelectArticleAssigneeOption, UserWithReadStatus } from '../options';
import { teamMetadataSelectStyles } from '../selectStyles';
import { GetTooltip } from '../TeamMemberList';
import {
  FeedArticleTeamMetadataContentMailIconWrapper,
  FeedArticleTeamMetadataContentSectionRow,
  FeedArticleTeamMetadataContentSectionRowLabel,
  FeedArticleTeamMetadataContentSectionRowWidget,
  FeedArticleTeamMetadataContentSelectWrapper,
  FeedArticleTeamMetadataContentTeamList,
} from './FeedArticleTeamMetadata.styles';
import { Mail } from 'lucide-react';
import { classNames } from '@fcg-tech/regtech-utils';

const hasRead = memoizee(
  (teamReadMetadata: TeamReadMetadata, username: string) =>
    Boolean(
      teamReadMetadata.readByUsers.find((user) => user.username === username),
    ),
);

interface FeedArticleTeamMetadataAssigneesProps {
  settings: Settings;
  articleId: string;
  metadata: TeamArticleMetadata;
  teamReadMetadata: TeamReadMetadata;
  disabled?: boolean;
  portalRoot?: HTMLElement;
  onMutated?: () => void;
}

export const FeedArticleTeamMetadataAssignees: FunctionComponent<
  FeedArticleTeamMetadataAssigneesProps
> = ({
  settings,
  articleId,
  metadata,
  teamReadMetadata,
  disabled,
  portalRoot,
  onMutated,
}) => {
  const teamId = teamReadMetadata.team.id;
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const theme = useTheme();
  const { completeArticleTeamAssignment, uncompleteArticleTeamAssignment } =
    useTeamActivitiesForArticleActions(teamId, articleId);
  const assignedUsers = useMemo(
    () =>
      metadata?.assigned
        ?.filter(({ user }) =>
          teamReadMetadata.team.members?.find(
            (member) => member.username === user.username,
          ),
        )
        .sort((a, b) => userSorter(settings, user)(a.user, b.user)),
    [metadata?.assigned, settings, teamReadMetadata.team.members, user],
  );

  const allTeamMembers = useMemo<Array<ReactSelectOption<UserWithReadStatus>>>(
    () =>
      teamReadMetadata.team?.members
        ?.filter(
          (member) =>
            !assignedUsers.find(
              (assigned) => assigned.user.username === member.username,
            ),
        )
        .sort(userSorter(settings))
        .map<ReactSelectOption<UserWithReadStatus>>((member) => ({
          value: {
            ...member,
            read: hasRead(teamReadMetadata, member.username),
          },
          label: formatName(member, settings?.userNamePreference),
        })) ?? [],
    [assignedUsers, settings, teamReadMetadata],
  );

  const readIcon = useMemo(
    () => (member: User) => {
      const read = hasRead(teamReadMetadata, member.username);
      return (
        <FeedArticleTeamMetadataContentMailIconWrapper
          aria-label={t(
            read
              ? MessageKeys.ArticleMetadataAssignReadByUser
              : MessageKeys.ArticleMetadataAssignUnreadByUser,
            { name: formatName(member, settings?.userNamePreference) },
          )}
          className={classNames(
            teamReadMetadata.readByUsers.find(
              ({ username }) => username === member.username,
            ) && 'read',
          )}
        >
          <Mail size="14" />
        </FeedArticleTeamMetadataContentMailIconWrapper>
      );
    },
    [settings?.userNamePreference, t, teamReadMetadata],
  );

  const getTeamMemberTooltip = useCallback<GetTooltip>(
    (username, action) => {
      const assignedUser = assignedUsers.find(
        (a) => a.user.username === username,
      );
      const name = formatName(assignedUser.user, settings.userNamePreference);
      if (assignedUser) {
        switch (action) {
          case 'custom':
            return t(
              teamReadMetadata.readByUsers.find((a) => a.username === username)
                ? MessageKeys.ArticleMetadataAssignReadByUser
                : MessageKeys.ArticleMetadataAssignUnreadByUser,
              { name },
            );

          case 'remove':
            return t(MessageKeys.ArticleMetadataRemoveAssignee, {
              name,
            });
        }
      }
    },
    [
      assignedUsers,
      settings.userNamePreference,
      t,
      teamReadMetadata.readByUsers,
    ],
  );

  const { setAssigned, removeAssigned } = useTeamActivitiesForArticleActions(
    teamReadMetadata.team.id,
    articleId,
  );

  const handleTeamMemberSelected = useCallback(
    async (option: ReactSelectOption<User>) => {
      await setAssigned(option.value.username);
      onMutated?.();
    },
    [onMutated, setAssigned],
  );
  const handleTeamMemberRemoved = useCallback(
    async (username: string) => {
      await removeAssigned(username);
      onMutated?.();
    },
    [onMutated, removeAssigned],
  );

  const handleCompleteChange = useCallback(async () => {
    const assignedCurrentUser = assignedUsers.find(
      (a) => a.user.username === user?.username,
    );
    if (assignedCurrentUser) {
      if (assignedCurrentUser.completed) {
        await uncompleteArticleTeamAssignment();
      } else {
        await completeArticleTeamAssignment();
      }
    }
  }, [
    assignedUsers,
    completeArticleTeamAssignment,
    uncompleteArticleTeamAssignment,
    user?.username,
  ]);

  const canChangeAssignees = useAccessControl(
    TeamActions.ArticleAssigneeChange,
  );

  const [selectRef, containerRef] = useCloseSelectOnScroll<HTMLDivElement>();

  return (
    <FeedArticleTeamMetadataContentSectionRow ref={containerRef}>
      <FeedArticleTeamMetadataContentSectionRowLabel>
        {t(MessageKeys.ArticleMetadataAssigned)}
      </FeedArticleTeamMetadataContentSectionRowLabel>
      <FeedArticleTeamMetadataContentSectionRowWidget>
        {assignedUsers?.length ? (
          <FeedArticleTeamMetadataContentTeamList
            icon={readIcon}
            members={assignedUsers}
            getTooltip={getTeamMemberTooltip}
            onRemove={
              !disabled && canChangeAssignees ? handleTeamMemberRemoved : null
            }
            onComplete={handleCompleteChange}
          />
        ) : null}
        <FeedArticleTeamMetadataContentSelectWrapper>
          <Select
            ref={selectRef}
            options={allTeamMembers}
            value={null}
            placeholder={t(
              canChangeAssignees
                ? MessageKeys.ArticleMetadataAssignSelectPlaceholder
                : MessageKeys.AccessErrorAddAssignee,
            )}
            isDisabled={disabled || !canChangeAssignees}
            isMulti={false}
            styles={teamMetadataSelectStyles(theme)}
            components={{ Option: SelectArticleAssigneeOption }}
            menuPortalTarget={portalRoot}
            closeMenuOnScroll
            onChange={handleTeamMemberSelected}
          />
        </FeedArticleTeamMetadataContentSelectWrapper>
      </FeedArticleTeamMetadataContentSectionRowWidget>
    </FeedArticleTeamMetadataContentSectionRow>
  );
};
