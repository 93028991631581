import {
  IconButton,
  Sheet,
  SheetCloseButton,
} from '@fcg-tech/regtech-components';
import styled from 'styled-components';
import { FeedArticleAction } from '../FeedArticleReader/FeedArticleReader.styles';

export const FeedArticleOverlaySheetWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 10vh;
`;

export const FeedArticleOverlaySheetFrameWrapper = styled.div`
  padding: 3.6rem 1.6rem 10vh;
`;

export const FeedArticleOverlaySheetHeaderWrapper = styled.div`
  background-color: #f1f1f1;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;

  & ${FeedArticleAction} + ${FeedArticleAction} {
    margin-left: 0;
  }
`;

export const FeedArticleOverlaySheetBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
`;

export const FeedArticleOverlaySheetSheet = styled(Sheet)`
  transition: transform 0.15s ease-out;

  &.offscreen-previous {
    transform: translateY(-90vh) translateX(calc(2.5vw - 100vw));
  }

  &.offscreen-next {
    transform: translateY(-90vh) translateX(calc(2.5vw + 100vw));
  }

  & > ${SheetCloseButton} {
    top: 6.5rem;
  }
`;

export const FeedArticleOverlaySheetPermalinkMessage = styled.span`
  position: absolute;
  right: 100%;
  margin-right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  font-size: 1.2rem;
`;

export const FeedArticleOverlaySheetTeamMetadataButton = styled(IconButton)`
  position: fixed;
  bottom: calc(10vh + 2rem);
  right: 5vw;
`;

export const FeedArticleOverlaySheetMetadataClose = styled.div`
  padding: 1.6rem;
  background-color: #f1f1f1;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

export const FeedArticleOverlaySheetMetadataWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 90vw;
  background-color: white;
  transition: transform 0.1s ease-out;
  transform: translateX(110%);
  overflow-y: auto;
  padding-bottom: 2rem;
  box-shadow: -2px 1px 8px 2px rgb(0 0 0 / 40%);
  &.open {
    transform: translateX(0%);
  }
`;
