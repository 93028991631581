import { useTranslation } from 'react-i18next';
import {
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent } from 'react';
import { MessageKeys } from '../../../translations/translationTypes';
import { SuspenseLoader } from '../../SuspensLoader';
import { SetupWizardModalWrapper } from '../SetupWizard.styles';
import { InvitationsSetupWizardInvitationList } from './InvitationsSetupWizardInvitationList';

interface InvitationsSetupWizardModalProps {
  onRequestClose?: () => void;
}

export const InvitationsSetupWizardModal: FunctionComponent<InvitationsSetupWizardModalProps> =
  ({ onRequestClose }) => {
    const { t } = useTranslation();
    return (
      <SetupWizardModalWrapper>
        <ModalHeader>
          <ModalTitle>
            {t(MessageKeys.WizardWelcomeToRegeye)}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SuspenseLoader>
            <InvitationsSetupWizardInvitationList />
          </SuspenseLoader>
        </ModalBody>
        <ModalFooter>
          <ModalButtonRow>
            <PrimaryButton onClick={onRequestClose}>
              {t(MessageKeys.LabelClose)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFooter>
      </SetupWizardModalWrapper>
    );
  };
