import { useTranslation } from 'react-i18next';
import {
  H3,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useEffect } from 'react';
import { useAllPublishers } from '../../../api/hooks';
import { Publisher } from '../../../api/schema';
import { MessageKeys } from '../../../translations/translationTypes';
import { useSubscriptionsChangeHandler } from '../../../utils/changeHandlers';
import { PublisherBooleanTree } from '../../PublisherBooleanTree';
import { SetupWizardModalPage } from '../SetupWizard.styles';
import { WizardData, WizardPage } from './wizardTypes';

interface AdminUserSetupWizardSubscriptionPageProps {
  currentPage: WizardPage;
  onChange?: (publishers: Array<Publisher>) => void;
  onPrev: () => void;
  onNext: () => void;
}

export const AdminUserSetupWizardSubscriptionPage: FunctionComponent<AdminUserSetupWizardSubscriptionPageProps> =
  ({ currentPage, onChange, onPrev, onNext }) => {
    const { t } = useTranslation();
    const { data: publishers } = useAllPublishers();

    const [items, handleChange] = useSubscriptionsChangeHandler(publishers);

    useEffect(() => {
      onChange(items);
    }, [items, onChange]);

    return (
      <SetupWizardModalPage
        className={classNames(
          currentPage > WizardPage.SelectSources && 'after',
          currentPage < WizardPage.SelectSources && 'before',
        )}
      >
        <ModalBody>
          <H3>
            {t(MessageKeys.WizardSelectSubscriptions)}
          </H3>
          <PublisherBooleanTree publishers={items} onChange={handleChange} />
        </ModalBody>
        <ModalFooter>
          <ModalButtonRow className="space-between">
            <SecondaryButton onClick={onPrev}>
              {t(MessageKeys.LabelPrevious)}
            </SecondaryButton>
            <PrimaryButton onClick={onNext}>
              {t(MessageKeys.LabelNext)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFooter>
      </SetupWizardModalPage>
    );
  };
