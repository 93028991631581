import { IconButton } from '../Button';
import styled from 'styled-components';

export const SheetWrapper = styled.div`
  position: fixed;
  height: 90vh;
  width: 95vw;
  left: 2.5vw;
  top: 100vh;
  transition: transform 0.2s ease-out;
  transform: translateY(0);
  background-color: white;
  border-radius: 4vw 4vw 0 0;
  z-index: 10;
  overflow: hidden;
  box-shadow: 0px -4.5px 3.6px rgba(0, 0, 0, 0.024),
    0px -12.5px 10px rgba(0, 0, 0, 0.035),
    0px -30.1px 24.1px rgba(0, 0, 0, 0.046), 0px -100px 80px rgba(0, 0, 0, 0.07);

  &.open {
    transform: translateY(-90vh);
  }
`;

export const SheetCloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 2rem;
  right: 2rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
`;
