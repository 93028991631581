import { ReactSelectOption } from '@fcg-tech/regtech-components';
import { Company } from '@fcg-tech/regtech-types/regeye';
import { Building2 } from 'lucide-react';
import { components, OptionProps } from 'react-select';

type CompanySelectOptionProps = OptionProps<
  ReactSelectOption<Company | 'create'>,
  false
>;

export const CompanySelectOption = (props: CompanySelectOptionProps) => {
  return (
    <components.Option {...props}>
      {props.data.value === 'create' ? (
        <span className="emph">{props.label}</span>
      ) : (
        <div className="flex">
          <Building2 size="18" className="leading-icon" />
          {props.label}
        </div>
      )}
    </components.Option>
  );
};
