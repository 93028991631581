import {
  ApiTeamArticleMetadata,
  Comment,
  FeedArticle,
  FeedArticleList,
  TeamArticleMetadata,
} from '@fcg-tech/regtech-types/regeye';
import { Descendant } from 'slate';
import { convertArticle } from './articleConverters';
import { convertTag } from './tagConverter';

export const convertFeedArticleData = (
  apiFeedArticle: FeedArticle,
): FeedArticle => {
  const { article, teamArticleMetadata, ...rest } = apiFeedArticle;
  return {
    ...rest,
    internalId: `${article.id}-${
      rest.recentTeamActivity?.logId ?? rest.team?.id ?? '0'
    }`,
    article: convertArticle(apiFeedArticle.article),
    teamArticleMetadata: convertTeamArticleMetadata(
      teamArticleMetadata as TeamArticleMetadata,
    ),
  };
};

export const convertTeamArticleMetadata = (
  teamArticleMetadata: ApiTeamArticleMetadata,
): TeamArticleMetadata => {
  if (!teamArticleMetadata) {
    return undefined;
  }
  const { dueDate, tags, ...rest } = teamArticleMetadata;
  return {
    ...rest,
    dueDate: {
      ...(dueDate ?? {}),
      dueDate: dueDate?.dueDate ? new Date(dueDate.dueDate) : null,
    },
    tags: tags.map((tag) => convertTag(tag, false)),
    comments: teamArticleMetadata.comments.map<Comment>((comment) => ({
      ...comment,
      markup: (comment.markup as { markup?: Array<Descendant> })?.markup,
    })),
  };
};

export const convertFeedArticleList = (
  feedArticleList: FeedArticleList,
): FeedArticleList => {
  const { items, ...rest } = feedArticleList;
  return {
    ...rest,
    items: items.map(convertFeedArticleData),
  };
};
