import { classNames } from '@fcg-tech/regtech-utils';
import React from 'react';
import { IconProps } from '../../types/icons';

export const CompanyIcon = ({
  size = '24',
  color = 'currentColor',
  className,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={color}
    stroke="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={classNames(className, 'icon')}
    {...props}
  >
    <path d="M 9.9935,4.9693 C 9.4412,4.9693 8.9935,5.417 8.9935,5.9693 8.9935,6.5215 9.4412,6.9693 9.9935,6.9693 L 13.9984,6.9693 C 14.5507,6.9693 14.9984,6.5215 14.9984,5.9693 14.9984,5.417 14.5507,4.9693 13.9984,4.9693 L 9.9935,4.9693 Z M 9.9935,8.9693 C 9.4412,8.9693 8.9935,9.417 8.9935,9.9693 8.9935,10.5215 9.4412,10.9693 9.9935,10.9693 L 13.9984,10.9693 C 14.5507,10.9693 14.9984,10.5215 14.9984,9.9693 14.9984,9.417 14.5507,8.9693 13.9984,8.9693 L 9.9935,8.9693 Z M 9.9935,12.9693 C 9.4412,12.9693 8.9935,13.417 8.9935,13.9693 8.9935,14.5215 9.4412,14.9693 9.9935,14.9693 L 13.9984,14.9693 C 14.5507,14.9693 14.9984,14.5215 14.9984,13.9693 14.9984,13.417 14.5507,12.9693 13.9984,12.9693 L 9.9935,12.9693 Z M 9.9935,16.9693 C 9.4412,16.9693 8.9935,17.417 8.9935,17.9693 8.9935,18.5215 9.4412,18.9693 9.9935,18.9693 L 13.9984,18.9693 C 14.5507,18.9693 14.9984,18.5215 14.9984,17.9693 14.9984,17.417 14.5507,16.9693 13.9984,16.9693 L 9.9935,16.9693 Z M 1.9944,20.9816 C 1.4445,20.9843 0.9995,21.431 0.9995,21.9816 0.9995,22.5339 1.4472,22.9816 1.9995,22.9816 L 21.9866,22.9816 C 22.5388,22.9816 22.9866,22.5339 22.9866,21.9816 22.9866,21.4333 22.5453,20.988 21.9985,20.9816 L 21.9968,9.4163 C 21.9968,8.6235 21.3539,7.9806 20.5611,7.9806 L 17.9924,7.9806 17.9924,3.0881 C 17.9924,1.9345 17.057,0.9992 15.9034,0.9992 L 8.0908,0.9992 C 6.9372,0.9992 6.0018,1.9345 6.0018,3.0881 L 6.0018,10.9676 3.4316,10.9676 C 2.6383,10.9676 1.9951,11.6108 1.9951,12.404 L 1.9951,20.9834 1.9944,20.9816 Z M 15.9986,20.9816 L 15.9987,20.9994 8.0018,20.9992 8.0018,3.0881 C 8.0018,3.0391 8.0417,2.9992 8.0908,2.9992 L 15.9034,2.9992 C 15.9525,2.9992 15.9924,3.0391 15.9924,3.0881 L 15.9986,20.9816 Z M 17.9968,20.9816 L 17.9924,9.9806 19.9951,9.9988 19.9951,20.9816 M 3.9951,20.9816 L 3.9959,12.9944 5.9957,12.9944 6.0018,20.9816" />
  </svg>
);
