import { useTranslation } from 'react-i18next';
import {
  ConfirmDialog,
  PrimaryButton,
  useConfirmDialog,
  useFlash,
  useToggle,
} from '@fcg-tech/regtech-components';
import { MessageLevel } from '@fcg-tech/regtech-types';
import { Tag } from '@fcg-tech/regtech-types/regeye';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { NameClashError } from '../../api/apiErrors';
import { useTeamTagActions, useTeamTags } from '../../api/hooks';
import { TeamActions } from '../../api/schema';
import { AccessControl } from '../../components/AccessControl';
import { NoContentMessage } from '../../components/generic';
import { useIamContext } from '../../components/IamContext';
import { UpsertTagModal } from '../../components/modals/UpsertTagModal';
import { TagTable } from '../../components/TagTable';
import { MessageKeys } from '../../translations/translationTypes';
import {
  canTeamTagDelete,
  canTeamTagEdit,
  canTeamTagList,
} from '../../utils/iamHelpers';
import { TeamSettingsCopyTeamTagsModal } from './TeamSettingsCopyTeamTagsModal';
import { TeamSettingsTabButtonBar } from './TeamSettingsPage.styles';
import { TeamSettingsTabTopPaddedWrapper } from './TeamSettingsSubscriptionsTab.styles';

interface TeamSettingsTagsTabProps {
  teamId: string;
}

export const TeamSettingsTagsTab: FunctionComponent<
  TeamSettingsTagsTabProps
> = ({ teamId }) => {
  const { createTags, updateTag, removeTag } = useTeamTagActions(teamId);
  const { t } = useTranslation();
  const addFlash = useFlash();
  const [editTag, setEditTag] = useState<Tag>();
  const iamContext = useIamContext();
  const canListTags = canTeamTagList(teamId, iamContext);

  const { data } = useTeamTags(canListTags ? teamId : null);

  const [showUpsertModal, setShowUpsertModal, onShowUpsertModalClick] =
    useToggle(false);
  const [showRemoveConfirm, handleRemoveConfirm, confirmRemove] =
    useConfirmDialog();
  const [showCopyTeamTagsModal, , onShowCopyTeamTagsModalClick] =
    useToggle(false);

  const handleRemoveTag = useCallback(
    async (tagId: string) => {
      try {
        if (await confirmRemove()) {
          await removeTag(tagId);
          addFlash({
            level: MessageLevel.Success,
            content: t(
              MessageKeys.AdministrationTeamDetailsRemoveTagSuccessLabel,
            ),
          });
        }
      } catch (err) {
        if (err instanceof NameClashError) {
          addFlash({
            level: MessageLevel.Error,
            content: t(MessageKeys.TeamSettingsTabTagsNameClashErrorMesssage),
          });
        } else {
          addFlash({
            level: MessageLevel.Error,
            content: t(MessageKeys.LabelCouldNotSave),
          });
        }
      }
    },
    [addFlash, confirmRemove, removeTag, t],
  );

  const handleEditTag = useCallback(
    (tagId: string) => {
      setShowUpsertModal(true);
      setEditTag(data.find(({ id }) => id === tagId));
    },
    [data, setShowUpsertModal],
  );

  const handleUpsertTag = useCallback(
    async (name: string, description: string, active: boolean) => {
      try {
        if (editTag) {
          try {
            await updateTag({
              ...editTag,
              name,
              description,
              active,
            });
            addFlash({
              level: MessageLevel.Success,
              content: t(
                MessageKeys.AdministrationTeamDetailsUpdateTagSuccessLabel,
              ),
            });
            setShowUpsertModal(false);
            setEditTag(undefined);
          } catch (err) {
            addFlash({
              level: MessageLevel.Error,
              content: t(MessageKeys.TeamSettingsTabTagsNameClashErrorMesssage),
            });
          }
        } else {
          const [created] = await createTags([{ name, description, active }]);
          addFlash({
            level: created?.length ? MessageLevel.Success : MessageLevel.Error,
            content: t(
              created?.length
                ? MessageKeys.AdministrationTeamDetailsCreateTagSuccessLabel
                : MessageKeys.TeamSettingsTabTagsNameClashErrorMesssage,
            ),
          });
          setShowUpsertModal(false);
          setEditTag(undefined);
        }
      } catch (err) {
        addFlash({
          level: MessageLevel.Error,
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    },
    [addFlash, createTags, editTag, setShowUpsertModal, t, updateTag],
  );

  const handleModalCancel = useCallback(() => {
    setShowUpsertModal(false);
    setEditTag(undefined);
  }, [setShowUpsertModal]);

  const sortedTags = useMemo(
    () => data?.sort((a, b) => a.name.localeCompare(b.name)),
    [data],
  );

  const canDelete = canTeamTagDelete(teamId, iamContext);
  const canUpdate = canTeamTagEdit(teamId, iamContext);

  return (
    <TeamSettingsTabTopPaddedWrapper>
      {showUpsertModal ? (
        <UpsertTagModal
          tag={editTag}
          onCancel={handleModalCancel}
          onConfirm={handleUpsertTag}
        />
      ) : null}
      {showRemoveConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirmAction)}
          body={t(MessageKeys.AdministrationTeamDetailsTagConfirmLabel)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleRemoveConfirm}
        />
      ) : null}
      {showCopyTeamTagsModal ? (
        <TeamSettingsCopyTeamTagsModal
          targetTeamId={teamId}
          onRequestClose={onShowCopyTeamTagsModalClick}
        />
      ) : null}
      <TeamSettingsTabButtonBar>
        <AccessControl requiredPermissions={[TeamActions.TagCreate]}>
          <PrimaryButton
            onClick={onShowUpsertModalClick}
            data-kind="create-tag-button"
          >
            {t(MessageKeys.AdministrationTeamDetailsTagCreateLabel)}
          </PrimaryButton>
          <PrimaryButton onClick={onShowCopyTeamTagsModalClick}>
            {t(MessageKeys.TeamSettingsCopyTeamTagsLabel)}
          </PrimaryButton>
        </AccessControl>
      </TeamSettingsTabButtonBar>
      {sortedTags?.length === 0 ? (
        <NoContentMessage>{t(MessageKeys.LabelNoTagsFound)}</NoContentMessage>
      ) : (
        <AccessControl
          requiredPermissions={[TeamActions.TagList]}
          noAccessMessageKey={MessageKeys.AccessErrorTeamTagList}
        >
          <TagTable
            tags={sortedTags}
            onRemoveTag={canDelete ? handleRemoveTag : null}
            onEditTag={canUpdate ? handleEditTag : null}
          />
        </AccessControl>
      )}
    </TeamSettingsTabTopPaddedWrapper>
  );
};
