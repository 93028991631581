import { useTranslation } from 'react-i18next';
import {
  ConfirmDialog,
  useConfirmDialog,
  useFlash,
} from '@fcg-tech/regtech-components';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  usePersonalStoredFilters,
  useStoredFilterActions,
} from '../../api/hooks';
import { AccessControl } from '../../components/AccessControl';
import { FeedArticleFilterSaveDialog } from '../../components/Filter/FeedArticleFilterSaveDialog';
import { FilterListTable } from '../../components/FilterListTable';
import { NoContentMessage } from '../../components/generic';
import { MessageKeys } from '../../translations/translationTypes';
import { GlobalActions } from '../../types';
import { MyProfileTeamFiltersInfo } from './MyProfilePage.styles';

export const MyProfileMyFiltersTab: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data: personalFilters } = usePersonalStoredFilters();
  const addFlash = useFlash();
  const { deleteFilter, saveFilter } = useStoredFilterActions();

  const [
    showConfirmDeleteFilterDialog,
    handleConfirmDeleteFilter,
    confirmDeleteFilter,
  ] = useConfirmDialog();

  const handleRemoveFilter = useCallback(
    async (filterId: string) => {
      const confirmed = await confirmDeleteFilter();
      if (confirmed) {
        const filter = personalFilters.find(({ id }) => id === filterId);
        if (filter) {
          try {
            await deleteFilter(filterId, filter.filterType);
            addFlash({
              level: 'success',
              content: t(
                MessageKeys.AdministrationFiltersDeleteFilterSuccessLabel,
              ),
            });
          } catch (e) {
            addFlash({
              level: 'error',
              content: t(
                MessageKeys.AdministrationFiltersDeleteFilterFailureLabel,
              ),
            });
          }
        }
      }
    },
    [addFlash, confirmDeleteFilter, deleteFilter, personalFilters, t],
  );

  const [editFilterId, setEditFilterId] = useState<string>();

  const editFilter = useMemo(
    () => personalFilters?.find(({ id }) => id === editFilterId),
    [personalFilters, editFilterId],
  );

  const handleUpdateFilter = useCallback(
    async (name: string) => {
      try {
        await saveFilter(
          editFilter.filter,
          name,
          editFilter.filterType,
          undefined,
          editFilter.id,
        );
        addFlash({
          level: 'success',
          content: t(MessageKeys.FeedArticleFilterFilterSaveSuccess),
        });
        setEditFilterId(null);
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.FeedArticleFilterFilterSaveFailure),
        });
      }
    },
    [
      addFlash,
      editFilter?.filter,
      editFilter?.filterType,
      editFilter?.id,
      saveFilter,
      t,
    ],
  );

  const handleUpdateFilterCancel = useCallback(() => setEditFilterId(null), []);

  return personalFilters?.length === 0 ? (
    <>
      <NoContentMessage>
        {t(MessageKeys.LabelNoFiltersFound)}
        <MyProfileTeamFiltersInfo>
          {t(MessageKeys.AdministrationTeamFiltersHowToCreateFilters)}
        </MyProfileTeamFiltersInfo>
      </NoContentMessage>
    </>
  ) : (
    <>
      {editFilter ? (
        <FeedArticleFilterSaveDialog
          allowTeamFilters={false}
          storedFilter={editFilter}
          onRequestClose={handleUpdateFilterCancel}
          onSubmit={handleUpdateFilter}
        />
      ) : null}
      {showConfirmDeleteFilterDialog ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelDelete)}
          body={t(MessageKeys.LabelDeleteFilterConfirmation)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleConfirmDeleteFilter}
        />
      ) : null}
      <AccessControl
        requiredPermissions={GlobalActions.GetPersonalFilters}
        noAccessMessageKey={MessageKeys.AccessErrorPersonalFilterList}
      >
        <FilterListTable
          filters={personalFilters}
          onRemoveFilter={handleRemoveFilter}
          onEditFilter={setEditFilterId}
        />
      </AccessControl>
    </>
  );
};
