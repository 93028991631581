/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Filter,
    FilterFromJSON,
    FilterToJSON,
    FilterInputCreate,
    FilterInputCreateFromJSON,
    FilterInputCreateToJSON,
    FilterInputUpdate,
    FilterInputUpdateFromJSON,
    FilterInputUpdateToJSON,
    FilterType,
    FilterTypeFromJSON,
    FilterTypeToJSON,
    StoredFilter,
    StoredFilterFromJSON,
    StoredFilterToJSON,
    StoredFilterList,
    StoredFilterListFromJSON,
    StoredFilterListToJSON,
    TranslatedFilter,
    TranslatedFilterFromJSON,
    TranslatedFilterToJSON,
} from '../models';

export interface CreatePersonalFilterRequest {
    filterInputCreate: FilterInputCreate;
}

export interface CreateTeamFilterRequest {
    teamId: string;
    filterInputCreate: FilterInputCreate;
}

export interface DeletePersonalFilterRequest {
    filterId: string;
}

export interface DeleteTeamFilterRequest {
    teamId: string;
    filterId: string;
}

export interface GetPersonalFilterRequest {
    filterId: string;
}

export interface GetPersonalFiltersRequest {
    filterType?: FilterType;
}

export interface GetTeamFilterRequest {
    teamId: string;
    filterId: string;
}

export interface GetTeamFiltersRequest {
    teamId: string;
    filterType?: FilterType;
}

export interface TranslateFilterRequest {
    filter: Filter;
}

export interface UpdatePersonalFilterRequest {
    filterId: string;
    filterInputUpdate: FilterInputUpdate;
}

export interface UpdateTeamFilterRequest {
    teamId: string;
    filterId: string;
    filterInputUpdate: FilterInputUpdate;
}

/**
 * FiltersApi - interface
 * 
 * @export
 * @interface FiltersApiInterface
 */
export interface FiltersApiInterface {
    /**
     * 
     * @summary Create personal filter
     * @param {FilterInputCreate} filterInputCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    createPersonalFilterRaw(requestParameters: CreatePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>>;

    /**
     * Create personal filter
     */
    createPersonalFilter(requestParameters: CreatePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList>;

    /**
     * 
     * @summary Create filter available to entire team
     * @param {string} teamId the team id
     * @param {FilterInputCreate} filterInputCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    createTeamFilterRaw(requestParameters: CreateTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>>;

    /**
     * Create filter available to entire team
     */
    createTeamFilter(requestParameters: CreateTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList>;

    /**
     * 
     * @summary Delete personal filter
     * @param {string} filterId the filter id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    deletePersonalFilterRaw(requestParameters: DeletePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>>;

    /**
     * Delete personal filter
     */
    deletePersonalFilter(requestParameters: DeletePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList>;

    /**
     * 
     * @summary Delete team filter
     * @param {string} teamId the team id
     * @param {string} filterId the filter id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    deleteTeamFilterRaw(requestParameters: DeleteTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>>;

    /**
     * Delete team filter
     */
    deleteTeamFilter(requestParameters: DeleteTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList>;

    /**
     * 
     * @summary Get personal filter
     * @param {string} filterId the filter id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    getPersonalFilterRaw(requestParameters: GetPersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilter>>;

    /**
     * Get personal filter
     */
    getPersonalFilter(requestParameters: GetPersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilter>;

    /**
     * 
     * @summary List all personal filters
     * @param {FilterType} [filterType] optional parameter to query filters of specified type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    getPersonalFiltersRaw(requestParameters: GetPersonalFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>>;

    /**
     * List all personal filters
     */
    getPersonalFilters(requestParameters: GetPersonalFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList>;

    /**
     * 
     * @summary Get team filter
     * @param {string} teamId the team id
     * @param {string} filterId the filter id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    getTeamFilterRaw(requestParameters: GetTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilter>>;

    /**
     * Get team filter
     */
    getTeamFilter(requestParameters: GetTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilter>;

    /**
     * 
     * @summary List all filters in team
     * @param {string} teamId the team id
     * @param {FilterType} [filterType] optional parameter to query filters of specified type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    getTeamFiltersRaw(requestParameters: GetTeamFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>>;

    /**
     * List all filters in team
     */
    getTeamFilters(requestParameters: GetTeamFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList>;

    /**
     * 
     * @summary Tranlsate a filter to include names of publishers, tags, types, regions
     * @param {Filter} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    translateFilterRaw(requestParameters: TranslateFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TranslatedFilter>>;

    /**
     * Tranlsate a filter to include names of publishers, tags, types, regions
     */
    translateFilter(requestParameters: TranslateFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TranslatedFilter>;

    /**
     * 
     * @summary Update personal filter
     * @param {string} filterId the filter id
     * @param {FilterInputUpdate} filterInputUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    updatePersonalFilterRaw(requestParameters: UpdatePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>>;

    /**
     * Update personal filter
     */
    updatePersonalFilter(requestParameters: UpdatePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList>;

    /**
     * 
     * @summary Update team filter
     * @param {string} teamId the team id
     * @param {string} filterId the filter id
     * @param {FilterInputUpdate} filterInputUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApiInterface
     */
    updateTeamFilterRaw(requestParameters: UpdateTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>>;

    /**
     * Update team filter
     */
    updateTeamFilter(requestParameters: UpdateTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList>;

}

/**
 * 
 */
export class FiltersApi extends runtime.BaseAPI implements FiltersApiInterface {

    /**
     * Create personal filter
     */
    async createPersonalFilterRaw(requestParameters: CreatePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>> {
        if (requestParameters.filterInputCreate === null || requestParameters.filterInputCreate === undefined) {
            throw new runtime.RequiredError('filterInputCreate','Required parameter requestParameters.filterInputCreate was null or undefined when calling createPersonalFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/personal-filters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterInputCreateToJSON(requestParameters.filterInputCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterListFromJSON(jsonValue));
    }

    /**
     * Create personal filter
     */
    async createPersonalFilter(requestParameters: CreatePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList> {
        const response = await this.createPersonalFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create filter available to entire team
     */
    async createTeamFilterRaw(requestParameters: CreateTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling createTeamFilter.');
        }

        if (requestParameters.filterInputCreate === null || requestParameters.filterInputCreate === undefined) {
            throw new runtime.RequiredError('filterInputCreate','Required parameter requestParameters.filterInputCreate was null or undefined when calling createTeamFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/teams/{teamId}/filters`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterInputCreateToJSON(requestParameters.filterInputCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterListFromJSON(jsonValue));
    }

    /**
     * Create filter available to entire team
     */
    async createTeamFilter(requestParameters: CreateTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList> {
        const response = await this.createTeamFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete personal filter
     */
    async deletePersonalFilterRaw(requestParameters: DeletePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>> {
        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling deletePersonalFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal-filters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterListFromJSON(jsonValue));
    }

    /**
     * Delete personal filter
     */
    async deletePersonalFilter(requestParameters: DeletePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList> {
        const response = await this.deletePersonalFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete team filter
     */
    async deleteTeamFilterRaw(requestParameters: DeleteTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteTeamFilter.');
        }

        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling deleteTeamFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/filters/{filterId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterListFromJSON(jsonValue));
    }

    /**
     * Delete team filter
     */
    async deleteTeamFilter(requestParameters: DeleteTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList> {
        const response = await this.deleteTeamFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get personal filter
     */
    async getPersonalFilterRaw(requestParameters: GetPersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilter>> {
        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling getPersonalFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal-filters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterFromJSON(jsonValue));
    }

    /**
     * Get personal filter
     */
    async getPersonalFilter(requestParameters: GetPersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilter> {
        const response = await this.getPersonalFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all personal filters
     */
    async getPersonalFiltersRaw(requestParameters: GetPersonalFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>> {
        const queryParameters: any = {};

        if (requestParameters.filterType !== undefined) {
            queryParameters['filterType'] = requestParameters.filterType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/personal-filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterListFromJSON(jsonValue));
    }

    /**
     * List all personal filters
     */
    async getPersonalFilters(requestParameters: GetPersonalFiltersRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList> {
        const response = await this.getPersonalFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get team filter
     */
    async getTeamFilterRaw(requestParameters: GetTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilter>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamFilter.');
        }

        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling getTeamFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/filters/{filterId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterFromJSON(jsonValue));
    }

    /**
     * Get team filter
     */
    async getTeamFilter(requestParameters: GetTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilter> {
        const response = await this.getTeamFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all filters in team
     */
    async getTeamFiltersRaw(requestParameters: GetTeamFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamFilters.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterType !== undefined) {
            queryParameters['filterType'] = requestParameters.filterType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/filters`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterListFromJSON(jsonValue));
    }

    /**
     * List all filters in team
     */
    async getTeamFilters(requestParameters: GetTeamFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList> {
        const response = await this.getTeamFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Tranlsate a filter to include names of publishers, tags, types, regions
     */
    async translateFilterRaw(requestParameters: TranslateFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TranslatedFilter>> {
        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling translateFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/translate-filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterToJSON(requestParameters.filter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslatedFilterFromJSON(jsonValue));
    }

    /**
     * Tranlsate a filter to include names of publishers, tags, types, regions
     */
    async translateFilter(requestParameters: TranslateFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TranslatedFilter> {
        const response = await this.translateFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update personal filter
     */
    async updatePersonalFilterRaw(requestParameters: UpdatePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>> {
        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling updatePersonalFilter.');
        }

        if (requestParameters.filterInputUpdate === null || requestParameters.filterInputUpdate === undefined) {
            throw new runtime.RequiredError('filterInputUpdate','Required parameter requestParameters.filterInputUpdate was null or undefined when calling updatePersonalFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/personal-filters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FilterInputUpdateToJSON(requestParameters.filterInputUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterListFromJSON(jsonValue));
    }

    /**
     * Update personal filter
     */
    async updatePersonalFilter(requestParameters: UpdatePersonalFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList> {
        const response = await this.updatePersonalFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update team filter
     */
    async updateTeamFilterRaw(requestParameters: UpdateTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StoredFilterList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling updateTeamFilter.');
        }

        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling updateTeamFilter.');
        }

        if (requestParameters.filterInputUpdate === null || requestParameters.filterInputUpdate === undefined) {
            throw new runtime.RequiredError('filterInputUpdate','Required parameter requestParameters.filterInputUpdate was null or undefined when calling updateTeamFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/teams/{teamId}/filters/{filterId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FilterInputUpdateToJSON(requestParameters.filterInputUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFilterListFromJSON(jsonValue));
    }

    /**
     * Update team filter
     */
    async updateTeamFilter(requestParameters: UpdateTeamFilterRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StoredFilterList> {
        const response = await this.updateTeamFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
