import { mobile, PrimaryButton } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';
import { PublisherBooleanTreeNrOfItems } from '../../components/PublisherBooleanTree/PublisherBooleanTree.styles';

export const TeamSettingsSubscriptionsTabWrapper = styled.div`
  position: relative;
  padding: 1.6rem;
  ${mobile(
    () => css`
      padding-bottom: 8rem;
    `,
  )}

  & > .banner-wrapper {
    margin-bottom: 2rem;
  }
`;

export const TeamSettingsSubscriptionsActionsButtons = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: 1.6rem;
  padding: 1.6rem;
  display: flex;
  justify-content: flex-end;
  background-color: inherit;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  & > button + button {
    margin-left: 0.8rem;
  }
  ${mobile(
    () => css`
      border-top: 0;
      margin-top: 0;
    `,
  )}
`;

export const TeamSettingsSubscriptionsDetailsSummaryWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette?.MainColorAccent?.light ?? '#eee'};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 22rem;
    right: 4rem;
    max-height: 60vh;
    overflow-y: auto;
    z-index: 5;

    ${mobile(
      () => css`
        bottom: 0;
        left: 0;
        right: 0;
        top: initial;
      `,
    )}
  `}
`;

export const TeamSettingsSubscriptionsCopySubscriptionsButton = styled(
  PrimaryButton,
)`
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
`;

export const TeamSettingsSubscriptionsParagraph = styled.p`
  font-size: 1.7rem;
  background-color: #edf5f7;
  display: inline-flex;
  align-items: center;
  margin-top: 0;
  padding: 1.2rem 1.1rem;

  & > .icon {
    margin-right: 1ch;
    color: #3c788f;
  }
`;

export const TeamSettingsTabSubscriptionsDescriptionChevron = styled.span`
  padding: 0;
  margin: 0 0.8ch;
  border: 0;
  background: none;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background-color: #92cde3;
    border-radius: 0.3rem;
  }
`;

export const TeamSettingsTabSubscriptionsLegend = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #edf5f7;
  padding: 1.2rem 1.1rem;
  font-weight: 500;
`;

export const TeamSettingsTabSubscriptionSearchWrapper = styled.div`
  margin-right: 2rem;
`;

export const TeamSettingsTabSubscriptionsLegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  & > ${PublisherBooleanTreeNrOfItems} {
    margin: 0 1ch 0 0;
  }

  & + & {
    margin-left: 2rem;
  }
`;

export const TeamSettingsTabTopPaddedWrapper = styled.div`
  padding: 1.6rem 0;
`;
