import styled from 'styled-components';

export const DangerZone = styled.div`
  display: inline-block;
  border: 3px solid red;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px 0 rgba(255, 0, 0, 0.2);
  background-color: white;
  padding: 1.6rem;
`;

export const DangerBorder = styled.div`
  margin-top: 1.6rem;
  border-top: 2px solid red;
`;
