import { User } from '@fcg-tech/regtech-types/regeye';
import randomcolor from 'randomcolor';
import color from 'color';
import {
  contrast,
  generateHueFromString,
  getRandomColor,
} from '@fcg-tech/regtech-utils';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { AvatarWrapper } from './Avatar.styles';

interface AvatarProps {
  user: User;
  className?: string;
}

export const Avatar: FunctionComponent<AvatarProps> = ({ user, className }) => {
  const ref = useRef<HTMLDivElement>();
  const style = useMemo(() => {
    const nr = generateHueFromString(user.username);
    const hex = randomcolor({
      seed: user.username,
      luminosity: nr < 0.5 ? 'dark' : 'bright',
    });
    const bg = color(hex, 'hex');
    const fg = contrast({ r: bg.red(), g: bg.green(), b: bg.blue() });

    return {
      backgroundColor: bg.hex(),
      color: fg,
    };
  }, [user.username]);

  return (
    <AvatarWrapper ref={ref} className={className} style={style}>
      {(user.alias ?? user.username)[0].toUpperCase()}
    </AvatarWrapper>
  );
};
