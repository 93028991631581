import { useCallback, useMemo, useState } from 'react';

export const useLocalStorage = <T>(
  masterKey: string,
): [data: T, update: (updater: (oldData: T) => T) => void] => {
  const dataStr = useMemo(() => localStorage.getItem(masterKey), [masterKey]);
  const [data, setData] = useState<T>(JSON.parse(dataStr ?? 'null'));

  const update = useCallback(
    (updater: (oldData: T) => T) => {
      setData((oldData) => {
        const updatedData = updater(oldData);
        localStorage.setItem(masterKey, JSON.stringify(updatedData));
        return updatedData;
      });
    },
    [masterKey],
  );

  return [data, update];
};
