import { TextFieldWrapper } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const UpsertTagModalFields = styled.div`
  min-width: 30rem;

  & ${TextFieldWrapper} {
    margin-bottom: 0.5rem;
  }
`;
