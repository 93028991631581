import { SubscriptionItem } from '@fcg-tech/regtech-types/regeye';
import Fuse from 'fuse.js';
import update from 'immutability-helper';

export const getFilteredSearchResults = (
  searchTerm: string,
  regions: Array<SubscriptionItem>,
  publisherFuse: Fuse<SubscriptionItem>,
): Array<SubscriptionItem> | null => {
  const publishers: Array<SubscriptionItem> = [];

  if (searchTerm.length < 3) {
    return null;
  }

  const publisherSearchResults = publisherFuse.search(searchTerm);

  publisherSearchResults.forEach((result) => {
    const region = regions.find((r) => r.value.id === result.item.parentId);
    publishers.push(
      update(result.item, {
        expanded: { $set: false },
        value: { regionName: { $set: region.value.name } },
      }),
    );
  });

  return publishers;
};
