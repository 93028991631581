import styled, { css } from 'styled-components';
import { TagWrapper } from '../Tag/Tag.styles';

export const TagListWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    & > ${TagWrapper} {
      margin: 0 0.4rem 0.4rem 0;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      align-items: center;
      flex-wrap: nowrap;
      overflow: hidden;

      & > ${TagWrapper} {
        margin: 0 0.4rem 0 0;
      }
    }
  `}
`;

export const TagListOverflowMessage = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const TagListShowAllLessButton = styled.button`
  appearance: none;
  border: 0;
  background: transparent;
  border-bottom: 1px solid #999;
  padding: 0.4rem 0 0.2rem 0;

  ${TagWrapper} + & {
    margin-left: 1.6rem;
  }
`;
