import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  TabBarNavigation,
  TabBarNavigationItem,
  ToolbarItemWrapper,
  useTabLinkState,
  TooltipNext as Tooltip,
  Badge,
} from '@fcg-tech/regtech-components';
import {
  BellRing,
  Columns,
  Filter,
  Settings,
  Settings2,
  Users,
} from 'lucide-react';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useInvitations } from '../../api/hooks';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { MainLayoutSideSubNavigationOutletWrapper } from '../../components/MainLayout/MainLayout.styles';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routeFragments, routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';

export const MyProfilePageOutlet: FunctionComponent = () => {
  const { t } = useTranslation();
  const tabState = useTabLinkState();

  const { data: invitations } = useInvitations({ suspense: false });

  const items = useMemo<Array<TabBarNavigationItem>>(
    () => [
      {
        label: (
          <ToolbarItemWrapper>
            <Users size="18" />
            {t(MessageKeys.MyProfileTabMySettingsCompaniesAndTeamsHeading)}
            {invitations?.length ? (
              <Tooltip
                content={t(MessageKeys.SideMenuInvitationsBadgeTooltip, {
                  count: invitations?.length,
                })}
                placement="bottom"
              >
                <Badge className="row-item-margin-left">
                  {invitations.length}
                </Badge>
              </Tooltip>
            ) : null}
          </ToolbarItemWrapper>
        ),
        to: constructUrl(routes.personalProfileSetting, {
          settingKey: routeFragments.personalProfileTeams,
        }),
      },
      {
        label: (
          <ToolbarItemWrapper>
            <Filter size="18" />
            {t(MessageKeys.MyProfileTabMySettingsFilters)}
          </ToolbarItemWrapper>
        ),
        to: constructUrl(routes.personalProfileSetting, {
          settingKey: routeFragments.personalProfileFilters,
        }),
      },
      {
        label: (
          <ToolbarItemWrapper>
            <Settings2 size="18" />
            {t(MessageKeys.MyProfileTabMySettingsDisplaySettings)}
          </ToolbarItemWrapper>
        ),
        to: constructUrl(routes.personalProfileSetting, {
          settingKey: routeFragments.personalProfileDisplaySettings,
        }),
      },
      {
        label: (
          <ToolbarItemWrapper>
            <Columns size="18" />
            {t(MessageKeys.MyProfileTabMySettingsColumnSettings)}
          </ToolbarItemWrapper>
        ),
        to: constructUrl(routes.personalProfileSetting, {
          settingKey: routeFragments.personalProfileColumnSettings,
        }),
      },
      {
        label: (
          <ToolbarItemWrapper>
            <BellRing size="18" />
            {t(MessageKeys.MyProfileTabMySettingsNotifications)}
          </ToolbarItemWrapper>
        ),
        to: constructUrl(routes.personalProfileSetting, {
          settingKey: routeFragments.personalProfileNotifications,
        }),
      },
      {
        label: (
          <ToolbarItemWrapper>
            <Settings size="18" />
            {t(MessageKeys.MyProfileTabMySettingsAdvancedSettings)}
          </ToolbarItemWrapper>
        ),
        to: constructUrl(routes.personalProfileSetting, {
          settingKey: routeFragments.personalProfileAdvancedSettings,
        }),
      },
    ],

    [invitations.length, t],
  );
  return (
    <MainLayoutSideSubNavigationOutletWrapper>
      <div className="menu">
        <TabBarNavigation
          vertical
          items={items}
          tabLinkState={tabState}
          tabBarAriaLabel="Profile"
        />
      </div>
      <div className="content">
        <ErrorBoundary>
          <SuspenseLoader>
            <Outlet />
          </SuspenseLoader>
        </ErrorBoundary>
      </div>
    </MainLayoutSideSubNavigationOutletWrapper>
  );
};
