import React from 'react';
import { IconProps } from '../../types/icons';

export const DownloadIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg viewBox="0 0 129 129" width={size} height={size} {...props}>
    <g fill={color}>
      <path d="M81.7 5.538c-19.8 0-36.4 14-40.1 32.9-3.5-1.4-7.2-2.1-11-1.9-13.6.5-24.3 12.1-24.3 26.1 0 13.7 11.1 24.8 24.8 24.8 2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1c-9.1 0-16.6-7.4-16.6-16.6 0-9.5 7.4-17.7 16.5-18.1 4.2-.2 8.1 1.1 11.4 3.6 1.2.9 2.8 1.1 4.2.5 1.4-.6 2.3-2 2.4-3.6.7-17.6 15-31.5 32.7-31.5 18.1 0 32.8 14.7 32.8 32.8 0 .4 0 .8-.1 1.2v1.5c-.3 16.7-14 30.2-30.8 30.2-2.3 0-4.1 1.8-4.1 4.1s1.8 4.1 4.1 4.1c21.5 0 39-17.5 39-39v-.4c0-.6.1-1.1.1-1.7 0-22.5-18.4-40.8-41-40.8z" />
      <path d="M56.1 125.827c1.6 1.6 4.2 1.6 5.8 0l26.5-26.5c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8 0l-19.5 19.5v-30.3c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v30.3l-19.4-19.4c-.8-.8-1.8-1.2-2.9-1.2-1 0-2.1.4-2.9 1.2-1.6 1.6-1.6 4.2 0 5.8l26.4 26.4z" />
    </g>
  </svg>
);
