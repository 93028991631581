import { ReactSelectOption } from '@fcg-tech/regtech-components';
import { FilterSelectOption } from '@fcg-tech/regtech-filter';
import { FeedArticleDomain, Tag } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { useMemo } from 'react';
import { components, OptionProps } from 'react-select';
import { getFeedArticleDomain } from '../../utils/misc';
import { useTeamContext } from '../TeamContext/TeamContext';
import { TagOptionTagName, TagOptionTeamname } from './TagOption.styles';

export const TagOption = <M extends boolean>(
  props: OptionProps<FilterSelectOption<{ id: string | number }>, M>,
) => {
  const tag = (props.data as ReactSelectOption<Tag>)?.value;
  const teamContext = useTeamContext();
  const domain = useMemo(
    () => getFeedArticleDomain(teamContext?.companyId, teamContext?.teamId),
    [teamContext],
  );

  return (
    <components.Option {...props}>
      <TagOptionTagName className={classNames(!tag.active && 'inactive')}>
        {props.children}
      </TagOptionTagName>
      {tag?.teamName && domain !== FeedArticleDomain.Team ? (
        <TagOptionTeamname>({tag?.teamName})</TagOptionTeamname>
      ) : null}
    </components.Option>
  );
};
