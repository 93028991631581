import { useTranslation } from 'react-i18next';
import {
  Importance as ImportanceType,
  ImportanceIndicator,
} from '@fcg-tech/regtech-components';
import { Importance } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import React, { FunctionComponent, useCallback } from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import { ImportanceMessageKeys } from '../../utils/translationMaps';
import {
  ImportanceButton,
  ImportanceButtonExplanation,
  ImportanceSelectorWrapper,
} from './ImportanceSelector.styles';

interface ImportanceOptionProps {
  importance: Importance;
  messageKey: MessageKeys;
  selected: boolean;
  onClick?: (importance: Importance, isSelected: boolean) => void;
}

const ImportanceOption: FunctionComponent<ImportanceOptionProps> = ({
  importance,
  messageKey,
  selected,
  onClick,
}) => {
  const { t } = useTranslation();
  const handleClick = useCallback(
    () => onClick?.(importance, !selected),
    [onClick, importance, selected],
  );
  return (
    <>
      <ImportanceButton
        data-testid={`importance-selector-button-${importance}`}
        className={classNames(selected && 'selected', !onClick && 'disabled')}
        title={t(messageKey)}
        onClick={handleClick}
      >
        <ImportanceIndicator importance={importance as ImportanceType} />
      </ImportanceButton>
      <ImportanceButtonExplanation
        data-testid={`importance-selector-explanation-${importance}`}
      >
        {t(messageKey)}
      </ImportanceButtonExplanation>
    </>
  );
};

interface ImportanceSelectorProps {
  currentImportance?: Array<number>;
  allowed?: Array<Importance>;
  disabled?: boolean;
  allowMultiSelect?: boolean;
  withExplanation?: boolean;
  onChange?: (importances: Array<Importance>) => void;
}

export const ImportanceSelector: FunctionComponent<ImportanceSelectorProps> = ({
  currentImportance = [],
  allowed,
  disabled,
  allowMultiSelect,
  withExplanation,
  onChange,
}) => {
  const importances = ImportanceMessageKeys.filter(
    (imp) =>
      imp.importance !== Importance.Unspecified &&
      (!allowed || allowed.includes(imp.importance)),
  );

  const handleImportanceOptionChange = useCallback(
    (importance: Importance) => {
      if (allowMultiSelect) {
        const index = currentImportance?.indexOf(importance);
        const updated =
          index >= 0
            ? update(currentImportance, { $splice: [[index, 1]] })
            : update(currentImportance, { $push: [importance] }).sort();
        onChange?.(updated.filter(Boolean));
      } else {
        if (currentImportance?.[0] === importance) {
          onChange?.([]);
        } else {
          onChange?.([importance]);
        }
      }
    },
    [allowMultiSelect, currentImportance, onChange],
  );

  return (
    <ImportanceSelectorWrapper
      data-testid="importance-selector"
      className={classNames(
        currentImportance?.[0] > 0 && 'has-selected',
        withExplanation && 'with-explanation',
      )}
    >
      {importances.map(({ importance, key }) => (
        <ImportanceOption
          key={importance}
          importance={importance}
          selected={Boolean(currentImportance?.includes(importance))}
          messageKey={key}
          onClick={!disabled ? handleImportanceOptionChange : null}
        />
      ))}
    </ImportanceSelectorWrapper>
  );
};
