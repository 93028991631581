import { ReactSelectOption } from '@fcg-tech/regtech-components';
import {
  StoredFilter,
  Comment,
  TeamReadMetadata,
  NameOrder,
  Settings,
  User,
  Tag,
  Company,
} from '@fcg-tech/regtech-types/regeye';
import { differenceInSeconds } from 'date-fns';
import { isTag } from './guards';

export const userSorter =
  (settings?: Settings, currentUser?: User) => (a: User, b: User) => {
    if (a.username === currentUser?.username) {
      return -1;
    }
    if (b.username === currentUser?.username) {
      return 1;
    }
    if (settings?.userNamePreference === NameOrder.Alias) {
      return a.alias?.localeCompare(b.alias) ?? 0;
    }
    return a.username.localeCompare(b.username);
  };

export const commentDateSorter = (a: Comment, b: Comment) =>
  differenceInSeconds(a.created, b.created);

export const commentNotDeletedFilter = (a: Comment) => a && !a.deleted;

export const sortComments = (comments: Array<Comment>): Array<Comment> => {
  const replies: Record<string, Array<Comment>> = {};
  const rootComments = comments
    .filter((comment) => {
      if (comment.replyTo) {
        replies[comment.replyTo] = replies[comment.replyTo] ?? [];
        replies[comment.replyTo].push(comment);
        return false;
      }
      return true;
    })
    .sort(commentDateSorter);

  const finalComments: Array<Comment> = [];
  rootComments.forEach((comment) => {
    const commentReplies = (replies[comment.id] ?? []).filter(
      commentNotDeletedFilter,
    );
    if (!comment.deleted || commentReplies.length) {
      finalComments.push(comment);
      commentReplies
        .sort(commentDateSorter)
        .forEach((reply) => finalComments.push(reply));
    }
  });

  return finalComments;
};

export const getTeamMetadataSorter =
  (currentTeamId?: string) => (a: TeamReadMetadata, b: TeamReadMetadata) => {
    if (currentTeamId && currentTeamId === a.team.id) {
      return -1;
    }
    if (currentTeamId && currentTeamId === b.team.id) {
      return 1;
    }
    if (a.subscribed && !b.subscribed) {
      return -1;
    }
    if (b.subscribed && !a.subscribed) {
      return 1;
    }
    return a.team.name.localeCompare(b.team.name);
  };

export const getCompanyTeamMetadataSorter =
  (currentTeamCompanyId?: string, currentCompanyId?: string) =>
  (a: Company, b: Company) => {
    if (currentCompanyId && currentCompanyId === a.id) {
      return -1;
    }
    if (currentCompanyId && currentCompanyId === b.id) {
      return 1;
    }
    if (currentTeamCompanyId === a.id) {
      return -1;
    }
    if (currentTeamCompanyId === b.id) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  };

export const storedFilterSorter = (a: StoredFilter, b: StoredFilter) => {
  const r = a.filterType.localeCompare(b.filterType);
  if (r !== 0) {
    return r;
  }

  return a.name.localeCompare(b.name);
};

export const reactSelectOptionSorter = (
  a: ReactSelectOption<unknown>,
  b: ReactSelectOption<unknown>,
) => a.label.localeCompare(b.label);

export const activeTagSorter = (a: Tag, b: Tag) => {
  if (a.active && !b.active) {
    return -1;
  } else if (!a.active && b.active) {
    return 1;
  }
  return a.name.localeCompare(b.name);
};

export const tagSorter = (a: Tag | string, b: Tag | string) => {
  if (isTag(a)) {
    if (isTag(b)) {
      return a.name.localeCompare(b.name);
    }
    return a.name.localeCompare(b);
  }
  if (isTag(b)) {
    if (isTag(a)) {
      return a.localeCompare(b.name);
    }
    return a.localeCompare(b.name);
  }
};
