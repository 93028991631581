import {
  ConfirmDialog,
  InfoCircle,
  PrimaryButton,
  SecondaryButton,
  ToggleSwitch,
  useConfirmDialog,
  useFlash,
} from '@fcg-tech/regtech-components';
import { Team, TeamInvitation } from '@fcg-tech/regtech-types/regeye';
import update from 'immutability-helper';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';
import {
  useInvitationActions,
  useSettingsActions,
  useTeamMemberActions,
} from '../../api/hooks';
import { allTeamsKey } from '../../api/hooks/cacheKeys';
import { TeamActions } from '../../api/schema';
import { AccessControl } from '../../components/AccessControl';
import { TeamContextProvider } from '../../components/TeamContext/TeamContext';
import { MessageKeys } from '../../translations/translationTypes';
import { formatName } from '../../utils/formatters';
import { isTeamVisible } from '../../utils/misc';
import { MyProfileHideTeamInfo } from './MyProfilePage.styles';
import { MyProfileTeamTableRowWrapper } from './MyProfileTeamTableRow.styles';
export type RowType = 'team' | 'invitation';

export type TeamRowData = Pick<Team, 'id' | 'name'> & {
  hidden?: boolean;
  rowType: RowType;
};

export type InvitationRowData = Pick<
  TeamInvitation,
  'id' | 'team' | 'message' | 'createdBy'
> & {
  rowType: RowType;
};

enum InvitationStatus {
  Accepted = 1,
  Rejected = 2,
}

interface MyProfileTeamTableRowProps {
  team: Team;
  invitation?: TeamInvitation;
}

export const MyProfileTeamTableRow: FunctionComponent<
  MyProfileTeamTableRowProps
> = ({ team, invitation }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean | InvitationStatus>(false);
  const { leaveTeam } = useTeamMemberActions(team.id);
  const addFlash = useFlash();
  const [showConfirmLeaveTeamDialog, handleConfirmLeaveTeam, confirmLeaveTeam] =
    useConfirmDialog();

  const { mutate } = useSWRConfig();
  const { acceptInvitation, rejectInvitation } = useInvitationActions(
    invitation?.id,
  );

  const { settings, patchSettings } = useSettingsActions();

  const handleLeaveClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const confirmed = await confirmLeaveTeam();
      if (confirmed) {
        try {
          setIsLoading(true);
          await leaveTeam();
          addFlash({
            content: t(
              MessageKeys.AdministrationTeamMembersLeaveTeamSuccessMessage,
              {
                name: team.name,
              },
            ),
            level: 'success',
          });
        } catch (e) {
          addFlash({
            content: t(MessageKeys.LabelCouldNotSave),
            level: 'error',
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [addFlash, confirmLeaveTeam, leaveTeam, t, team.name],
  );

  const handleShowChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const hidden = !event.target.checked;
      const index = settings.teams.findIndex((t) => t.id === team.id);
      patchSettings({
        teams: update(
          settings.teams,
          index >= 0
            ? {
                [index]: { hidden: { $set: hidden } },
              }
            : { $push: [{ id: team.id, hidden }] },
        ),
      });
    },
    [patchSettings, team.id, settings.teams],
  );

  const handleAcceptClick = useCallback(async () => {
    try {
      setIsLoading(InvitationStatus.Accepted);
      await acceptInvitation();
      mutate(allTeamsKey());
      addFlash({
        level: 'success',
        content: t(MessageKeys.TeamInvitationAcceptedMessage, {
          name: team.name ?? 'N/A',
        }),
      });
    } catch (err) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.LabelCouldNotSave),
      });
      setIsLoading(false);
    }
  }, [acceptInvitation, mutate, addFlash, t, team.name]);

  const handleRejectClick = useCallback(async () => {
    try {
      setIsLoading(InvitationStatus.Rejected);
      await rejectInvitation();
      addFlash({
        level: 'success',
        content: t(MessageKeys.TeamInvitationRejectedMessage, {
          name: team.name ?? 'N/A',
        }),
      });
    } catch (err) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.LabelCouldNotSave),
      });
    }
    setIsLoading(false);
  }, [rejectInvitation, addFlash, t, team.name]);

  return (
    <TeamContextProvider teamId={team.id}>
      {showConfirmLeaveTeamDialog ? (
        <ConfirmDialog
          title={t(MessageKeys.AdministrationTeamMembersLeaveTeamButtonLabel)}
          body={t(MessageKeys.AdministrationTeamMembersLeaveTeamConfirmLabel, {
            name: team.name,
          })}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleConfirmLeaveTeam}
        />
      ) : null}
      <MyProfileTeamTableRowWrapper>
        {invitation ? (
          <>
            <div className="team-invitation">
              <div className="team-invitation_tag">
                {t(MessageKeys.MyProfileTabMyTeamsInvitation)}
              </div>
              {team.name}
              <div className="team-invitation_description">
                {t(MessageKeys.MyProfileTabMyTeamsInvitationByWithMessage, {
                  name: formatName(
                    invitation.createdBy,
                    settings.userNamePreference,
                  ),

                  message: invitation.message,
                })}
              </div>
            </div>
            <div className="action-wrapper">
              <SecondaryButton
                loading={isLoading === InvitationStatus.Rejected}
                disabled={isLoading && isLoading !== InvitationStatus.Rejected}
                onClick={handleRejectClick}
              >
                {t(MessageKeys.LabelReject)}
              </SecondaryButton>
              <PrimaryButton
                loading={isLoading === InvitationStatus.Accepted}
                disabled={isLoading && isLoading !== InvitationStatus.Accepted}
                onClick={handleAcceptClick}
              >
                {t(MessageKeys.LabelAccept)}
              </PrimaryButton>
            </div>
          </>
        ) : (
          <>
            <div className="team-name">{team.name}</div>
            <div className="toggle-wrapper">
              <ToggleSwitch
                checked={isTeamVisible(team, settings)}
                offLabel={t(MessageKeys.LabelHide)}
                onLabel={t(MessageKeys.LabelShow)}
                onChange={handleShowChange}
              />
              <MyProfileHideTeamInfo>
                <InfoCircle
                  placement="left"
                  info={t(MessageKeys.MyProfileTabMyTeamsHideTeamTooltip)}
                />
              </MyProfileHideTeamInfo>
            </div>
            <div className="action-wrapper">
              <AccessControl requiredPermissions={[TeamActions.TeamLeave]}>
                <PrimaryButton
                  className="narrow"
                  loading={Boolean(isLoading)}
                  onClick={handleLeaveClick}
                >
                  {t(MessageKeys.AdministrationTeamMembersLeaveTeamButtonLabel)}
                </PrimaryButton>
              </AccessControl>
            </div>
          </>
        )}
      </MyProfileTeamTableRowWrapper>
    </TeamContextProvider>
  );
};
