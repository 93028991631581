import { Building2, LayoutDashboard, User, Users } from 'lucide-react';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useMatch } from 'react-router-dom';
import { useAllTeams, useCompany } from '../../api/hooks';
import { FeedArticleSearchPageHeading } from '../../containers/FeedArticleSearchPage/FeedArticleSearchPageHeading';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { StyledHeading } from './Header.styles';

export const HeaderHeading: FunctionComponent = () => {
  const { t } = useTranslation();
  const teamMatch = useMatch(routes.teamBaseCatchAll);
  const companyMatch = useMatch(routes.companyBaseCatchAll);
  const teamId = teamMatch?.params?.teamId;
  const companyId = companyMatch?.params?.companyId;
  const { data: teams } = useAllTeams();
  const { data: company } = useCompany(companyId);
  const currentTeam = useMemo(() => {
    if (teamId) {
      return teams.find((t) => t.id === teamId);
    }
  }, [teamId, teams]);

  return (
    <StyledHeading>
      <Routes>
        <Route
          path={routes.teamBaseCatchAll}
          element={
            <>
              <Users />
              <span className="heading">{currentTeam?.name ?? 'N/A'}</span>
            </>
          }
        />
        <Route
          path={routes.companyBaseCatchAll}
          element={
            <>
              <Building2 />
              <span className="heading">{company?.name ?? 'N/A'}</span>
            </>
          }
        />
        <Route
          path={routes.superAdminCompanyCatchAll}
          element={
            <>
              <Building2 />
              <span className="heading">
                {t(MessageKeys.SideMenuAdministration)}
              </span>
            </>
          }
        />
        <Route
          path={routes.personalCatchAll}
          element={
            <>
              <User />
              <span className="heading">{t(MessageKeys.SideMenuPersonal)}</span>
            </>
          }
        />
        <Route
          path={routes.notifications}
          element={
            <span className="heading">
              {t(MessageKeys.NotificationsPageHeading)}
            </span>
          }
        />
        <Route
          path={routes.search}
          element={<FeedArticleSearchPageHeading />}
        />
        <Route
          path={routes.dashboard}
          element={
            <>
              <LayoutDashboard />
              <span className="heading">
                {t(MessageKeys.SideMenuDashboard)}
              </span>
            </>
          }
        />
      </Routes>
    </StyledHeading>
  );
};
