export const routeFragments = {
  companyBase: 'company/:companyId',
  companyActions: 'actions',
  companyActionsLog: 'activity-log',
  companyAdministration: 'administration',
  companyAdministrationDetails: 'details',
  companyAdministrationCatchAll: 'administration/*',
  teamBase: 'team/:teamId',
  teamSubscriptionArticles: 'articles',
  teamActions: 'actions',
  teamArchive: 'archive',
  teamActionsLog: 'action-log',
  teamSettings: 'settings',
  teamSettingsPage: 'settings/:settingKey',
  teamSettingsCatchAll: 'settings/*',
  superAdmin: 'administration',
  personal: 'personal',
  personalAllTeamSubscriptions: 'articles/all',
  personalBookmarked: 'articles/bookmarked',
  personalAssignedToMe: 'articles/my/assigned',
  personalCommentedByMe: 'articles/my/commented',
  personalEventLog: 'event-log',
  personalProfile: 'profile',
  personalProfileTeams: 'teams',
  personalProfileFilters: 'filters',
  personalProfileDisplaySettings: 'display-settings',
  personalProfileColumnSettings: 'table-columns',
  personalProfileNotifications: 'notifications',
  personalProfileAdvancedSettings: 'advanced',
};

export const routes = {
  dashboard: '/dashboard',
  trending: '/articles/trending',
  feedArticleById: '/articles/id/:articleId',
  companyBase: routeFragments.companyBase,
  companyBaseCatchAll: `/${routeFragments.companyBase}/*`,
  companyActions: `/${routeFragments.companyBase}/${routeFragments.companyActions}`,
  companyActionsLog: `/${routeFragments.companyBase}/${routeFragments.companyActionsLog}`,
  companyAdministrationCatchAll: `/${routeFragments.companyBase}/${routeFragments.companyAdministrationCatchAll}`,
  companyTeamAdministration: `/${routeFragments.companyBase}/${routeFragments.companyAdministration}/${routeFragments.teamBase}`,
  companyAdministrationDetails: `/${routeFragments.companyBase}/${routeFragments.companyAdministration}/${routeFragments.companyAdministrationDetails}`,
  teamBase: `/${routeFragments.teamBase}`,
  teamBaseCatchAll: `/${routeFragments.teamBase}/*`,
  teamSubscriptions: `/${routeFragments.teamBase}/${routeFragments.teamSubscriptionArticles}`,
  teamActions: `/${routeFragments.teamBase}/${routeFragments.teamActions}`,
  teamArchive: `/${routeFragments.teamBase}/${routeFragments.teamArchive}`,
  teamActionsLog: `/${routeFragments.teamBase}/${routeFragments.teamActionsLog}`,
  teamSettingsCatchAll: `/${routeFragments.teamBase}/${routeFragments.teamSettingsCatchAll}`,
  teamSettingsPage: `/${routeFragments.teamBase}/${routeFragments.teamSettingsPage}`,
  search: '/search',
  superAdmin: `/${routeFragments.superAdmin}`,
  superAdminCatchAll: `/${routeFragments.superAdmin}/*`,
  superAdminCompany: `/${routeFragments.superAdmin}/${routeFragments.companyBase}`,
  superAdminCompanyCatchAll: `/${routeFragments.superAdmin}/${routeFragments.companyBase}/*`,
  superAdminCompanyTeam: `/${routeFragments.superAdmin}/${routeFragments.companyBase}/${routeFragments.teamBase}`,
  superAdminCompanyDetails: `/${routeFragments.superAdmin}/${routeFragments.companyBase}/${routeFragments.companyAdministrationDetails}`,
  fallbackNoCompanies: '/fallback/no-companies',
  fallbackCompaniesNoTeams: '/fallback/no-teams',
  notifications: '/notifications',
  notificationsSettings: '/notifications/settings',
  login: '/login',
  personal: `/${routeFragments.personal}`,
  personalCatchAll: `/${routeFragments.personal}/*`,
  personalAllTeamSubscriptions: `/${routeFragments.personal}/${routeFragments.personalAllTeamSubscriptions}`,
  personalBookmarked: `/${routeFragments.personal}/${routeFragments.personalBookmarked}`,
  personalAssignedToMe: `/${routeFragments.personal}/${routeFragments.personalAssignedToMe}`,
  personalCommentedByMe: `/${routeFragments.personal}/${routeFragments.personalCommentedByMe}`,
  personalEventLog: `/${routeFragments.personal}/${routeFragments.personalEventLog}`,
  personalProfile: `/${routeFragments.personal}/${routeFragments.personalProfile}`,
  personalProfileSetting: `/${routeFragments.personal}/${routeFragments.personalProfile}/:settingKey`,
  personalProfileCatchAll: `/${routeFragments.personal}/${routeFragments.personalProfile}/*`,
};

export enum TeamSettingKey {
  Subscriptions = 'subscriptions',
  Members = 'members',
  Tags = 'tags',
  Filters = 'filters',
}
