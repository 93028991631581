import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useFeedArticle } from '../../api/hooks';
import { FeedArticleOverlayContentProps } from './FeedArticleOverlayContent';

interface FeedArticleOverlayLoaderProps {
  articleId: string;
  children: React.ReactElement<FeedArticleOverlayContentProps>;
}

export const FeedArticleOverlayLoader: FunctionComponent<PropsWithChildren<FeedArticleOverlayLoaderProps>> =
  ({ articleId, children }) => {
    const { data: feedArticle } = useFeedArticle(articleId);
    return React.cloneElement(children, {
      ...children.props,
      feedArticle,
    });
  };
