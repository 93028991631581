import { parseQueryDate } from '@fcg-tech/regtech-api-utils';
import {
  TooltipNext as Tooltip,
  useToggle,
} from '@fcg-tech/regtech-components';
import { FilterBarButton, FilterBarIconButton } from '@fcg-tech/regtech-filter';
import { classNames, single, useDevice } from '@fcg-tech/regtech-utils';
import { endOfMonth, startOfMonth } from 'date-fns';
import update from 'immutability-helper';
import { Archive, Calendar, Download, List, Mail } from 'lucide-react';
import { stringify } from 'query-string';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useCompany } from '../../api/hooks';
import { ArchivedOptions, InsightsSelector } from '../../api/schema';
import { ActionsArticleExportModal } from '../../containers/Insights/ActionsArticleExportModal';
import { team } from '../../state/teamState';
import { MessageKeys } from '../../translations/translationTypes';
import { FeedArticleDomain, TableSubType, TableType } from '../../types';
import { useFilter } from '../../utils/filterHooks';
import { parseQuery } from '../../utils/historyHelpers';
import { FilterBarColumnSelector, FilterBarSortSelector } from '../Filter';
import { useFeedArticleTableContext } from './FeedArticleTableContext';
import { useTableColumnOptions } from './feedArticleTableHelpers';
import {
  FeedArticleTableTopActionBarQuickFilterButton,
  FeedArticleTableTopActionBarQuickFilterWrapper,
  FeedArticleTableTopActionBarWrapper,
} from './FeedArticleTableTopActionBar.styles';

export const FeedArticleTableTopActionBar: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const { companyId, teamId } = useParams<{
    companyId: string;
    teamId: string;
  }>();

  const selectedTeam = useRecoilValue(team(teamId));
  const { data: company } = useCompany(companyId);

  const {
    tableType,
    tableSubType,
    domain,
    isFilterVisible,
    isFilterDisabled,
    setFilterVisible,
  } = useFeedArticleTableContext();

  const parsedQuery = parseQuery(location.search);
  const showCalendar = Boolean(parsedQuery.showCalendar);

  const [columns] = useTableColumnOptions(tableType, domain);

  const {
    filter,
    filterId,
    extendedFilter,
    storedFilters,
    sortBy,
    getFilterQuery,
    handleSortByChange,
    handleFilterValueChange,
    handleFilterValueClear,
  } = useFilter({
    companyId,
    teamId,
    tableType,
  });

  const storedFilter = useMemo(
    () => (filterId ? storedFilters.find((f) => f.id === filterId) : null),
    [filterId, storedFilters],
  );

  const handleToggleShowCalendar = useCallback(() => {
    let parsedQuery = parseQuery(location.search);
    const parsedHash = parseQuery(location.hash);
    if ('showCalendar' in parsedQuery) {
      delete parsedQuery.showCalendar;
      delete parsedQuery.showAgenda;

      // Remove dueDate and selector. If we have a loaded filter selected, these properties will be re-populated below
      delete parsedQuery['dueDate.to'];
      delete parsedQuery['dueDate.from'];
      delete parsedQuery['dueDate.interval'];
      delete parsedQuery.selector;

      if (filterId) {
        // We can't use handleFilterChange to update the filter here since we want to remove some non-filter values at the same time
        parsedQuery = {
          ...parsedQuery,
          ...getFilterQuery(
            update(filter, {
              dueDate: { $set: storedFilter?.filter?.dueDate },
              selector: { $set: storedFilter?.filter?.selector },
            }),
          ),
        } as never;
      }
    } else {
      const dueMonth = parsedHash.dueMonth
        ? parseQueryDate(single(parsedHash.dueMonth))
        : new Date();
      parsedQuery = {
        ...parseQuery,
        filterId,
        showCalendar: '1',
        ...getFilterQuery(
          update(filter, {
            dueDate: {
              $set: {
                from: startOfMonth(filter?.dueDate?.from ?? dueMonth),
                to: endOfMonth(filter?.dueDate?.from ?? dueMonth),
              },
            },
            selector: { $set: [InsightsSelector.WithDueDate] },
          }),
        ),
      };
    }

    navigate(
      {
        pathname: location.pathname,
        search: stringify(parsedQuery, { arrayFormat: 'comma' }),
      },
      { replace: true },
    );
  }, [
    filter,
    filterId,
    getFilterQuery,
    location.hash,
    location.pathname,
    location.search,
    navigate,
    storedFilter?.filter?.dueDate,
    storedFilter?.filter?.selector,
  ]);

  const [showExportModal, , toggleExportModal] = useToggle();

  const handleToggleFilterPane = useCallback(
    () => setFilterVisible((old) => !old),
    [setFilterVisible],
  );

  const handleToggleUnreadOnly = useCallback(() => {
    if (filter?.unreadOnly) {
      handleFilterValueClear('unreadOnly');
    } else {
      handleFilterValueChange('unreadOnly', true);
    }
  }, [filter?.unreadOnly, handleFilterValueChange, handleFilterValueClear]);

  const handleToggleArchived = useCallback(() => {
    if (filter?.archived) {
      handleFilterValueClear('archived');
    } else {
      handleFilterValueChange('archived', ArchivedOptions.All);
    }
  }, [filter?.archived, handleFilterValueChange, handleFilterValueClear]);

  const showArchiveToggleButton = useMemo(() => {
    if (selectedTeam && tableSubType === TableSubType.Archive) {
      return false;
    }

    return selectedTeam && tableType === TableType.Generic || tableType === TableType.Actions;
  }, [selectedTeam, tableSubType, tableType]);

  const showUnreadOnlyToggleButton = useMemo(() => {
    return tableType === TableType.Generic;
  }, [tableType]);

  return (
    <FeedArticleTableTopActionBarWrapper>
      {showExportModal ? (
        <ActionsArticleExportModal
          company={company}
          team={selectedTeam}
          domain={domain}
          filter={extendedFilter}
          onRequestClose={toggleExportModal}
        />
      ) : null}
      <FeedArticleTableTopActionBarQuickFilterWrapper>
        {showUnreadOnlyToggleButton ? (
          <Tooltip
            placement="top-end"
            content={t(
              filter?.unreadOnly
                ? MessageKeys.FeedArticleFilterToggleUnreadOnlyTooltip
                : MessageKeys.FeedArticleFilterToggleAllReadStatusTooltip,
            )}
          >
            <FeedArticleTableTopActionBarQuickFilterButton
              className={classNames(filter?.unreadOnly && 'enabled')}
              onClick={handleToggleUnreadOnly}
            >
              <Mail size="18" />
            </FeedArticleTableTopActionBarQuickFilterButton>
          </Tooltip>
        ) : null}
        {showArchiveToggleButton ? (
          <Tooltip
            placement="top-end"
            content={t(
              single(filter?.archived) === ArchivedOptions.All
                ? MessageKeys.FeedArticleFilterToggleAllArchivedStatusTooltip
                : MessageKeys.FeedArticleFilterToggleUnarchivedOnlyTooltip,
            )}
          >
            <FeedArticleTableTopActionBarQuickFilterButton
              className={classNames(
                single(filter?.archived) === ArchivedOptions.All && 'enabled',
              )}
              onClick={handleToggleArchived}
            >
              <Archive size="18" />
            </FeedArticleTableTopActionBarQuickFilterButton>
          </Tooltip>
        ) : null}
      </FeedArticleTableTopActionBarQuickFilterWrapper>
      {tableType === TableType.Actions ? (
        <>
          {domain !== FeedArticleDomain.Personal ? (
            <FilterBarButton id="export-button" onClick={toggleExportModal}>
              <Download size="18" />
            </FilterBarButton>
          ) : null}
          <FilterBarButton
            id="calendar-toggle-button"
            onClick={handleToggleShowCalendar}
          >
            {showCalendar ? <List size="18" /> : <Calendar size="18" />}
          </FilterBarButton>
          {isMobile ? (
            <FilterBarSortSelector
              columns={columns}
              onSortByChange={handleSortByChange}
              sortBy={sortBy}
            />
          ) : (
            <FilterBarColumnSelector disabled={showCalendar} />
          )}
        </>
      ) : null}
      {tableType === TableType.ActionLog || tableType === TableType.Generic ? (
        isMobile ? (
          <FilterBarSortSelector
            columns={columns}
            onSortByChange={handleSortByChange}
            sortBy={sortBy}
          />
        ) : (
          <FilterBarColumnSelector />
        )
      ) : null}

      <FilterBarIconButton
        disabled={isFilterDisabled}
        toggled={isFilterVisible}
        onClick={handleToggleFilterPane}
      />
    </FeedArticleTableTopActionBarWrapper>
  );
};
