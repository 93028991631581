import styled from 'styled-components';

export const TeamMultiInvitationWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 1;

  & > .text-area-wrapper {
    width: 100%;
  }
`;

export const TeamMultiInvitationTip = styled.div`
  margin: 1.2rem 0 0.6rem 0;
  display: flex;

  & > .lucide {
    margin-right: 1rem;
  }
`;
