import styled from 'styled-components';

export const ImportanceIndicatorWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 2.6rem;
  border-radius: 2px;
  isolation: isolate;
`;

export const ImportanceChevronWrapper = styled.div`
  position: relative;

  & .lucide {
    position: relative;
  }

  & > .lucide {
    z-index: 2;
  }

  & .lucide-chevron-down,
  .lucide-chevrons-up,
  .lucide-minus {
    top: 1px;
  }
`;

export const ImportanceChevronShadowWrapper = styled.div`
  position: absolute;
  top: 1px;
  z-index: 1;
`;
