import {
  CompanyIcon,
  CrossIcon,
  usePaneResize,
  UsersIcon,
} from '@fcg-tech/regtech-components';
import {
  FeedArticle,
  FeedArticleMetadataMutationCallbacks,
} from '@fcg-tech/regtech-types/regeye';
import { classNames, useHotkeys } from '@fcg-tech/regtech-utils';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAllTeams,
  useArticleMetadata,
  useCompanies,
  useSettings,
  useSettingsActions,
} from '../../api/hooks';
import { SLOW_FOCUS_REVALIDATE_INTERVAL } from '../../constants';
import { MessageKeys } from '../../translations/translationTypes';
import { getEnabledAndDisabled } from '../../utils/misc';
import {
  getCompanyTeamMetadataSorter,
  getTeamMetadataSorter,
} from '../../utils/sorters';
import { ArticleContext } from '../ArticleContext';
import { FeedArticleReader } from '../FeedArticleReader/FeedArticleReader';
import { FeedArticleTeamMetadata } from '../FeedArticleTeamMetadata';
import { FeedArticleTeamMetadataContentProps } from '../FeedArticleTeamMetadata/FeedArticleTeamMetadataContent';
import { NoContentMessage } from '../generic';
import { ChevronIcon } from '../icons';
import {
  TeamContextProvider,
  useTeamContext,
} from '../TeamContext/TeamContext';
import {
  FeedArticleContent,
  FeedArticleContentDragTrap,
  FeedArticleExpandedInfoCompanyHeader,
  FeedArticleExpandedInfoCompanyHeaderExpandIconWrapper,
  FeedArticleExpandedInfoCompanyHeaderName,
  FeedArticleExpandedInfoCompanyHeaderSeparator,
  FeedArticleExpandedInfoNoTeamsFoundMessage,
  FeedArticleExpandedInfoResizer,
  FeedArticleExpandedInfoWrapper,
  FeedArticleMinimizeButton,
  FeedArticleMinimizeButtonWrapper,
  FeedArticleTeamMetadataContainer,
  FeedArticleTeamMetadataPortalContainer,
  FeedArticleTeamReadMetadataHiddenItemsMessage,
} from './FeedArticleExpandedInfo.styles';

export interface FeedArticleExpandedInfoProps
  extends FeedArticleMetadataMutationCallbacks {
  feedArticle: FeedArticle;
  maxHeight?: number;
  maxWidth?: number;
  className?: string;
  onRequestClose?: (event?: React.MouseEvent) => void;
  onTagClick?: (tagId: string) => void;
  onTeamTagClick?: FeedArticleTeamMetadataContentProps['onTeamTagClick'];
  onArticleTypeClick?: (tagId: string) => void;
  onContentLoad?: () => void;
  onArticleMetadataMutated?: (articleId: string) => void;
}

export const FeedArticleExpandedInfo: FunctionComponent<
  FeedArticleExpandedInfoProps
> = ({
  feedArticle,
  maxHeight,
  maxWidth,
  className,
  onRequestClose,
  onBookmark,
  onMarkAsRead,
  onArchive,
  onTagClick,
  onTeamTagClick,
  onArticleTypeClick,
  onContentLoad,
  onArticleMetadataMutated,
}) => {
  const { t } = useTranslation();
  const metaDataReq = useArticleMetadata(feedArticle?.article?.id, {
    focusThrottleInterval: SLOW_FOCUS_REVALIDATE_INTERVAL,
  });

  const { data: settings } = useSettings();
  const { data: teams } = useAllTeams();
  const { data: companies } = useCompanies();
  const { updateSettings } = useSettingsActions();

  const teamContext = useTeamContext();

  const targetTeamId = feedArticle.team?.id ?? teamContext?.teamId;
  const targetTeam = teams?.find((team) => team.id === targetTeamId);
  const targetCompanyId =
    feedArticle.team?.company?.id ??
    targetTeam?.company?.id ??
    teamContext?.companyId;

  const paneRef = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();
  const resizeHandleRef = useRef<HTMLDivElement>();

  const markAsReadTimer = useRef<NodeJS.Timeout>();

  const handleAfterResize = useCallback(() => {
    setMetadataHidden(
      metadataContainerRef.current?.getBoundingClientRect().width < 300,
    );

    return undefined;
  }, []);

  const handleAfterResizeEnded = useCallback(
    (width: number) => {
      setMetadataHidden(
        metadataContainerRef.current?.getBoundingClientRect().width < 300,
      );

      updateSettings({
        ...settings,
        feedArticleReaderWidth: width,
      });

      return undefined;
    },
    [settings, updateSettings],
  );

  const { getWidth, isDragging } = usePaneResize({
    paneRef,
    wrapperRef,
    resizeHandleRef,
    initialWidth: settings?.feedArticleReaderWidth,
    onAfterResize: handleAfterResize,
    onAfterResizeEnded: handleAfterResizeEnded,
  });

  const [enabledCompanies, enabledTeams, disabledCompanies, disabledTeams] =
    useMemo(() => {
      const currentTeam = teams.find((t) => t.id === targetTeamId);
      const [enabledCompanies, enabledTeams, disabledCompanies, disabledTeams] =
        getEnabledAndDisabled(companies, teams, settings);

      return [
        enabledCompanies.sort(
          getCompanyTeamMetadataSorter(currentTeam?.company?.id, targetTeamId),
        ),
        enabledTeams,
        disabledCompanies,
        disabledTeams,
      ];
    }, [companies, targetTeamId, teams, settings]);

  const enabledTeamsReadMetaData = useMemo(
    () =>
      metaDataReq.data?.teamReadMetadata.filter((m) =>
        enabledTeams.find((t) => t.id === m.team?.id),
      ),
    [enabledTeams, metaDataReq.data?.teamReadMetadata],
  );

  const userHasTeams = enabledTeamsReadMetaData?.length > 0;

  const metadataContainerRef = useRef<HTMLDivElement>(null);
  const [metadataHidden, setMetadataHidden] = useState(false);

  useEffect(() => {
    if (settings?.markReadAfterMs >= 0 && !feedArticle.userMetadata.read) {
      markAsReadTimer.current = setTimeout(async () => {
        onMarkAsRead(true, feedArticle.article.id);
      }, settings?.markReadAfterMs);

      return () => clearTimeout(markAsReadTimer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(settings)]);

  const toggleMarkAsRead = useCallback(() => {
    clearTimeout(markAsReadTimer.current);
    onMarkAsRead(!feedArticle.userMetadata.read, feedArticle.article.id);
  }, [feedArticle.article.id, feedArticle.userMetadata.read, onMarkAsRead]);

  useHotkeys({ hotkeys: ['r'], callback: toggleMarkAsRead });

  const portalRef = useRef<HTMLDivElement>(null);

  const [expandedCompanies, setExpandedCompanies] = useState<string[]>(
    [targetCompanyId].filter(Boolean),
  );

  const toggleExpandedCompany = useCallback((id) => {
    setExpandedCompanies((old) => {
      const set = new Set(old);
      if (set.has(id)) {
        set.delete(id);
      } else {
        set.add(id);
      }

      return Array.from(set);
    });
  }, []);

  return (
    <ArticleContext.Provider value={{ articleId: feedArticle.article.id }}>
      <FeedArticleExpandedInfoWrapper className={className} ref={wrapperRef}>
        <FeedArticleContent
          ref={paneRef}
          maxHeight={maxHeight}
          hasForcedWidth={
            (getWidth() >= 0 || settings?.feedArticleReaderWidth > 0) &&
            userHasTeams
          }
        >
          <FeedArticleReader
            feedArticle={feedArticle}
            articleText={metaDataReq?.data?.articleText}
            onBookmark={onBookmark}
            onMarkAsRead={onMarkAsRead}
            onArticleTypeClick={onArticleTypeClick}
            onTagClick={onTagClick}
            onContentLoad={onContentLoad}
          />
          {onRequestClose ? (
            <FeedArticleMinimizeButtonWrapper>
              <FeedArticleMinimizeButton onClick={onRequestClose}>
                <CrossIcon size="15" />
              </FeedArticleMinimizeButton>
            </FeedArticleMinimizeButtonWrapper>
          ) : null}
          {isDragging ? <FeedArticleContentDragTrap /> : null}
        </FeedArticleContent>
        {userHasTeams ? (
          <>
            <FeedArticleTeamMetadataContainer
              ref={metadataContainerRef}
              maxHeight={maxHeight}
              style={{
                marginRight: `calc((${maxWidth}px - 100%) * -1)`,
                paddingRight: `calc(${maxWidth}px - 100% + 2rem)`,
              }}
            >
              <FeedArticleTeamMetadataPortalContainer ref={portalRef} />
              {enabledCompanies.map((company, index) => {
                if (enabledCompanies.length > 1) {
                  const teams =
                    enabledTeamsReadMetaData && !metadataHidden
                      ? enabledTeamsReadMetaData
                          ?.filter(
                            (teamReadMetadata) =>
                              teamReadMetadata.team.company?.id === company.id,
                          )
                          ?.sort(getTeamMetadataSorter(targetTeamId))
                      : [];

                  const expanded = expandedCompanies.includes(company.id);

                  return (
                    <React.Fragment key={company.id}>
                      <FeedArticleExpandedInfoCompanyHeader
                        key={company.id}
                        role="button"
                        className={classNames(index === 0 && 'first')}
                        onClick={() => toggleExpandedCompany(company.id)}
                      >
                        {teams?.length ? (
                          <FeedArticleExpandedInfoCompanyHeaderExpandIconWrapper>
                            <ChevronIcon down={expanded} />
                          </FeedArticleExpandedInfoCompanyHeaderExpandIconWrapper>
                        ) : null}
                        <FeedArticleExpandedInfoCompanyHeaderName>
                          <CompanyIcon size="20" />
                          {company.name}
                        </FeedArticleExpandedInfoCompanyHeaderName>
                        {teams?.length === 0 ? (
                          <FeedArticleExpandedInfoNoTeamsFoundMessage>
                            {t(MessageKeys.ArticleMetadataCompanyIsEmpty)}
                          </FeedArticleExpandedInfoNoTeamsFoundMessage>
                        ) : null}
                        <FeedArticleExpandedInfoCompanyHeaderSeparator />
                      </FeedArticleExpandedInfoCompanyHeader>

                      {expanded
                        ? teams.map((teamReadMetadata) => (
                            <TeamContextProvider
                              key={teamReadMetadata.team.id}
                              teamId={teamReadMetadata.team.id}
                              companyId={teamReadMetadata.team.company?.id}
                            >
                              <FeedArticleTeamMetadata
                                teamReadMetadata={teamReadMetadata}
                                articleId={feedArticle.article.id}
                                isArchived={teamReadMetadata.archived}
                                initiallyExpanded={
                                  targetTeamId === teamReadMetadata.team.id
                                }
                                portalContainer={portalRef.current}
                                onTeamTagClick={onTeamTagClick}
                                onArchive={onArchive}
                                onArticleMetadataMutated={
                                  onArticleMetadataMutated
                                }
                              />
                            </TeamContextProvider>
                          ))
                        : null}
                    </React.Fragment>
                  );
                }

                const teams =
                  enabledTeamsReadMetaData && !metadataHidden
                    ? enabledTeamsReadMetaData
                        ?.filter(
                          (teamReadMetadata) =>
                            teamReadMetadata.team.company?.id ===
                            enabledCompanies[0].id,
                        )
                        ?.sort(getTeamMetadataSorter(targetTeamId))
                    : [];
                return teams.length
                  ? teams.map((teamReadMetadata) => (
                      <TeamContextProvider
                        key={teamReadMetadata.team.id}
                        teamId={teamReadMetadata.team.id}
                        companyId={teamReadMetadata.team.company?.id}
                      >
                        <FeedArticleTeamMetadata
                          teamReadMetadata={teamReadMetadata}
                          articleId={feedArticle.article.id}
                          isArchived={teamReadMetadata.archived}
                          initiallyExpanded={
                            targetTeamId === teamReadMetadata.team.id
                          }
                          portalContainer={portalRef.current}
                          onTeamTagClick={onTeamTagClick}
                          onArchive={onArchive}
                          onArticleMetadataMutated={onArticleMetadataMutated}
                        />
                      </TeamContextProvider>
                    ))
                  : null;
              })}

              {enabledTeams.length === 0 ? (
                <NoContentMessage className="narrow">
                  {t(MessageKeys.LabelNoTeamsFound)}
                </NoContentMessage>
              ) : null}

              {disabledCompanies.length > 0 && !metadataHidden ? (
                <FeedArticleTeamReadMetadataHiddenItemsMessage>
                  <CompanyIcon size="20" />
                  {t(MessageKeys.NrOfHiddenCompaniesMessage, {
                    count: disabledCompanies.length,
                  })}
                </FeedArticleTeamReadMetadataHiddenItemsMessage>
              ) : null}
              {disabledTeams.length > 0 && !metadataHidden ? (
                <FeedArticleTeamReadMetadataHiddenItemsMessage>
                  <UsersIcon size="20" />
                  {t(MessageKeys.NrOfHiddenTeamsMessage, {
                    count: disabledTeams.length,
                  })}
                </FeedArticleTeamReadMetadataHiddenItemsMessage>
              ) : null}
            </FeedArticleTeamMetadataContainer>
            <FeedArticleExpandedInfoResizer ref={resizeHandleRef} />
          </>
        ) : null}
      </FeedArticleExpandedInfoWrapper>
    </ArticleContext.Provider>
  );
};
