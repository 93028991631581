import {
  AlertBox,
  Pagination,
  usePagination,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  areDateRangesEqual,
  DateRange,
  FilterCheckbox,
  FilterClearButton,
  FilterDateRangeInterval,
  FilterRow,
  FilterRowLabel,
  FilterSelect,
  FilterSelectOption,
  StoredFilterType,
  useFilterRow,
} from '@fcg-tech/regtech-filter';
import {
  FeedArticle,
  FeedArticleDomain,
  FilterValues,
  InsightsSelector,
  OrderOptions,
  StoredFilter,
  TableType,
  TagType,
} from '@fcg-tech/regtech-types/regeye';
import { array, arrayShallowEquals, useDevice } from '@fcg-tech/regtech-utils';
import { endOfMonth, startOfMonth } from 'date-fns';
import update from 'immutability-helper';
import { stringify } from 'query-string';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useActionsArticles, useCompany, useSettings } from '../../api/hooks';
import { ArchivedOptions } from '../../api/schema';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { FeedArticleTable } from '../../components/FeedArticleTable';
import {
  FeedArticleTableAndFilterWrapper,
  StyledFilterBar,
} from '../../components/FeedArticleTable/FeedArticleTable.styles';
import { useFeedArticleTableContext } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { useTableColumnOptions } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { Filter, FilterBorderWrapper } from '../../components/Filter';
import { FilterBarTeamFilterItem } from '../../components/Filter/FilterBarTeamFilterItem';
import { ImportanceSelector } from '../../components/ImportanceSelector';
import { TagOption } from '../../components/options';
import { useFilterSelectStyles } from '../../components/selectStyles';
import { TeamActionsShowcase } from '../../components/showcases/TeamActionsShowcase';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { team } from '../../state/teamState';
import { MessageKeys } from '../../translations/translationTypes';
import { updateArticleUserMetadata } from '../../utils/articleMutations';
import {
  dueDateIntervals,
  performedDateIntervals,
  publicationDateIntervals,
} from '../../utils/filterHelpers';
import { useFilter, useTagAndTypeClickHandlers } from '../../utils/filterHooks';
import { formatName } from '../../utils/formatters';
import { parseQuery, useNonEphemeralData } from '../../utils/historyHelpers';
import { ActionsArticleExportModal } from './ActionsArticleExportModal';
import { ActionsArticlesDueDateCalendar } from './ActionsArticlesDueDateCalendar';

interface ActionsArticlesTableProps {
  teamId?: string;
  companyId?: string;
}

export const ActionsArticlesTable: FunctionComponent<
  ActionsArticlesTableProps
> = ({ companyId, teamId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: settings } = useSettings();
  const [page, setPage] = usePagination(1);
  const selectedTeam = useRecoilValue(team(teamId));
  const { data: company } = useCompany(companyId);
  const parsedQuery = parseQuery(location.search);
  const showCalendar = Boolean(parsedQuery.showCalendar);
  const showAgenda = Boolean(parsedQuery.showAgenda);
  const archived = parsedQuery.archived as ArchivedOptions;
  const { isMobile } = useDevice();
  const wrapper = useRef<HTMLDivElement>();

  const { setFilterDisabled, domain, isFilterVisible, setFilterVisible } =
    useFeedArticleTableContext();

  const handleFilterRequestClose = useCallback(
    () => setFilterVisible(false),
    [setFilterVisible],
  );

  const {
    filter,
    filterHash,
    extendedFilter,
    filterId,
    storedFilters,
    pinnedFilters,
    hasInitialFilter,
    sortBy,
    getFilterQuery,
    handleFilterClear,
    handleFilterChange,
    handleFilterValueChange,
    handleFilterValueClear,
    handleFilterValueExclude,
    handleSortByChange,
    handleStoredFilterSelected,
    handleFilterPinned,
    handlePinnedFilterSortOrderChange,
    handleFilterSaved,
    handleFilterDeleted,
  } = useFilter({
    companyId,
    teamId,
    tableType: TableType.Actions,
  });

  const [columns, handleColumnOptionsChange] = useTableColumnOptions(
    TableType.Actions,
    domain,
  );

  const { data, mutate, isValidating, error } = useActionsArticles(
    companyId ?? teamId,
    domain,
    extendedFilter,
    page,
    DEFAULT_PAGE_SIZE,
    { suspense: false },
  );

  const [showExportModal, , toggleExportModal] = useToggle();

  const { onTeamTagClick } = useTagAndTypeClickHandlers(
    filter,
    getFilterQuery,
    false,
    handleFilterChange,
  );

  const [dueMonth, setDueMonth] = useState(new Date());
  const storedFilter = useMemo(
    () => (filterId ? storedFilters.find((f) => f.id === filterId) : null),
    [filterId, storedFilters],
  );

  const handleToggleShowAgenda = useCallback(() => {
    const parsedQuery = parseQuery(location.search);
    if ('showAgenda' in parsedQuery) {
      delete parsedQuery.showAgenda;
    } else {
      parsedQuery.showAgenda = '1';
    }

    navigate(
      {
        pathname: location.pathname,
        search: stringify(parsedQuery, { arrayFormat: 'comma' }),
      },
      { replace: true },
    );
  }, [navigate]);

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (data?.pagination) {
        const totalPages = Math.ceil(
          data.pagination?.totalResults / data.pagination.limit,
        );
        if (pageNumber <= totalPages && pageNumber >= 1) {
          setPage(pageNumber);
          return true;
        }
      }
      return false;
    },
    [data?.pagination, setPage],
  );

  const handlePageChangeByDelta = useCallback(
    (direction: 1 | -1) => {
      return handlePageChange(page + direction);
    },
    [handlePageChange, page],
  );

  const handleArticleMutated = useCallback(
    (articleId: string, data: Partial<FeedArticle['userMetadata']>) => {
      updateArticleUserMetadata(articleId, data, mutate);
    },
    [mutate],
  );

  const paginationData = useNonEphemeralData(data?.pagination);
  const filterData = useNonEphemeralData(data?.insightFilter);
  const isLoading = !data && isValidating;

  useEffect(() => {
    setFilterDisabled(!filterData);
  }, [filterData, setFilterDisabled]);

  useEffect(() => {
    if (
      showCalendar &&
      filter &&
      !(
        filter.selector?.length === 1 &&
        filter.selector?.[0] === InsightsSelector.WithDueDate
      )
    ) {
      const { orderBy } = filter;
      handleFilterChange(
        update(filter, {
          selector: { $set: [InsightsSelector.WithDueDate] },
          dueDate: {
            $set: { from: startOfMonth(dueMonth), to: endOfMonth(dueMonth) },
          },
          orderBy: { $set: orderBy ?? [OrderOptions.DueAsc] },
        }),
        filterId,
      );
    }
  }, [filter, showCalendar, handleFilterChange, filterId, dueMonth]);

  const handleCalendarMonthChange = useCallback(
    (month: Date) => {
      setDueMonth(month);
      handleFilterChange(
        update(filter, {
          dueDate: {
            $set: { from: startOfMonth(month), to: endOfMonth(month) },
          },
        }),
        filterId,
      );
    },
    [filter, filterId, handleFilterChange],
  );

  const handleArticleMetadataMutated = useCallback(() => {
    mutate();
  }, [mutate]);

  const [assignees, attachedBy, commentedBy, tags, teams] = useMemo<
    Array<Array<FilterSelectOption>>
  >(() => {
    return [
      filterData?.assignees?.map<FilterSelectOption>((a) => ({
        value: { id: a.username },
        label: formatName(a, settings.userNamePreference),
      })),
      filterData?.attachedBy?.map<FilterSelectOption>((a) => ({
        value: { id: a.username },
        label: formatName(a, settings.userNamePreference),
      })),
      filterData?.commentedBy?.map<FilterSelectOption>((a) => ({
        value: { id: a.username },
        label: formatName(a, settings.userNamePreference),
      })),
      filterData?.tags?.map<FilterSelectOption>((a) => ({
        value: a,
        label: a.name,
      })),
      filterData?.teams?.map<FilterSelectOption>((a) => ({
        value: a,
        label: a.name,
      })),
    ];
  }, [
    filterData?.assignees,
    filterData?.attachedBy,
    filterData?.commentedBy,
    filterData?.tags,
    filterData?.teams,
    settings.userNamePreference,
  ]);

  const activities = useMemo<Array<FilterSelectOption>>(() => {
    const activities: Array<FilterSelectOption> = [
      {
        value: { id: InsightsSelector.WithComments.toString() },
        label: t(MessageKeys.TeamActivityEventCommented),
      },
      {
        value: { id: InsightsSelector.WithAssignees.toString() },
        label: t(MessageKeys.TeamActivityEventAssigned),
      },
      {
        value: { id: InsightsSelector.WithAttachments.toString() },
        label: t(MessageKeys.TeamActivityEventAttachment),
      },
      {
        value: { id: InsightsSelector.WithDueDate.toString() },
        label: t(MessageKeys.TeamActivityEventDueDate),
      },
      {
        value: { id: InsightsSelector.WithImportance.toString() },
        label: t(MessageKeys.TeamActivityEventImportance),
      },
      {
        value: { id: InsightsSelector.WithTeamTags.toString() },
        label: t(MessageKeys.TeamActivityEventTagged),
      },
    ];

    return activities;
  }, [t]);

  const importanceHandlers = useFilterRow<FilterValues>(
    'importance',
    filterId,
    handleFilterValueChange,
    handleFilterValueClear,
  );

  const teamTagStyles = useFilterSelectStyles(TagType.TeamTag);

  const maxTodayRange = useMemo<DateRange>(
    () => ({
      to: new Date(),
    }),
    [],
  );

  const storedFilterOverwritten = useMemo(
    () =>
      ((showCalendar || showAgenda) &&
        storedFilter?.filter?.dueDate &&
        filter.dueDate &&
        !areDateRangesEqual(storedFilter.filter.dueDate, filter.dueDate)) ||
      (storedFilter?.filter?.selector &&
        filter.selector &&
        !arrayShallowEquals(storedFilter.filter.selector, filter.selector)),
    [
      filter.dueDate,
      filter.selector,
      showAgenda,
      showCalendar,
      storedFilter?.filter.dueDate,
      storedFilter?.filter.selector,
    ],
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const filterEl = isFilterVisible ? (
    <FilterBorderWrapper>
      <Filter
        filter={filter}
        filterId={filterId}
        storedFilters={storedFilters as Array<StoredFilter>}
        clearLabel={t(MessageKeys.LabelClear)}
        excludeLabel={t(MessageKeys.LabelExclude)}
        noItemsLabel={t(MessageKeys.FeedArticleFilterTagsNoTags)}
        allowTeamFilters={Boolean(
          domain !== FeedArticleDomain.Company && selectedTeam,
        )}
        showDraftRestoredNotification={hasInitialFilter}
        onFilterClear={handleFilterClear}
        onFilterChange={handleFilterChange}
        onFilterValueChange={handleFilterValueChange}
        onFilterValueClear={handleFilterValueClear}
        onFilterValueExclude={handleFilterValueExclude}
        onFilterLoad={handleStoredFilterSelected}
        onSaveFilter={handleFilterSaved}
        onDeleteFilter={handleFilterDeleted}
        onPinFilter={handleFilterPinned}
        onRequestClose={handleFilterRequestClose}
      >
        {storedFilterOverwritten ? (
          <FilterRow>
            <AlertBox slim>
              {t(MessageKeys.FeedArticleFilterSomePropertiesOverwrittenWarning)}
            </AlertBox>
          </FilterRow>
        ) : null}
        <FilterSelect
          disabled={showCalendar || showAgenda}
          filterPropKey="selector"
          label={t(MessageKeys.FeedArticleFilterTeamActivityEventFilter)}
          options={activities}
          allowMultipleSelect
        />
        {domain === FeedArticleDomain.Company ||
        domain === FeedArticleDomain.Personal ? (
          <FilterSelect
            filterPropKey="teams"
            label={t(MessageKeys.FeedArticleFilterTeamFilter)}
            options={teams}
            allowMultipleSelect
          />
        ) : null}
        <FilterSelect
          filterPropKey="teamTags"
          label={t(MessageKeys.FeedArticleFilterTeamTagsFilter)}
          options={tags}
          selectStyles={teamTagStyles}
          components={{
            Option: TagOption,
          }}
          allowMultipleSelect
        />
        <FilterSelect
          filterPropKey="assignees"
          label={t(MessageKeys.FeedArticleFilterAssigneeFilter)}
          noItemsLabel={t(MessageKeys.FeedArticleFilterAssigneeNoAssignees)}
          options={assignees}
          allowMultipleSelect
        />
        <FilterCheckbox
          filterPropKey="uncompletedAssignedOnly"
          inverted
          label={t(MessageKeys.FeedArticleFilterIncludeCompletedFilter)}
        />
        <FilterSelect
          filterPropKey="commentedBy"
          label={t(MessageKeys.FeedArticleFilterCommentedByFilter)}
          noItemsLabel={t(MessageKeys.FeedArticleFilterCommentedNoCommenters)}
          options={commentedBy}
          allowMultipleSelect
        />
        <FilterSelect
          filterPropKey="attachedBy"
          label={t(MessageKeys.FeedArticleFilterAttachedByFilter)}
          noItemsLabel={t(MessageKeys.FeedArticleFilterAttachedByNoAttachments)}
          options={attachedBy}
          allowMultipleSelect
        />
        <FilterRow>
          <FilterRowLabel>
            {t(MessageKeys.FeedArticleFilterImportanceFilter)}
            <FilterClearButton onClick={importanceHandlers.onClear}>
              {t(MessageKeys.LabelClear)}
            </FilterClearButton>
          </FilterRowLabel>
          <ImportanceSelector
            currentImportance={array(filter.importance)}
            allowed={filterData?.importance}
            allowMultiSelect
            onChange={importanceHandlers.onChange}
          />
        </FilterRow>
        <FilterDateRangeInterval
          disabled={showCalendar || showAgenda}
          filterPropKey="dueDate"
          label={t(MessageKeys.FeedArticleFilterDueDateFilter)}
          intervals={dueDateIntervals}
        />
        <FilterDateRangeInterval
          filterPropKey="performedDate"
          label={t(MessageKeys.FeedArticleFilterPerformedDateFilter)}
          intervals={performedDateIntervals}
          allowedRange={maxTodayRange}
        />
        <FilterDateRangeInterval
          filterPropKey="publicationDate"
          label={t(MessageKeys.FeedArticleFilterPublicationDateFilter)}
          intervals={publicationDateIntervals}
        />
      </Filter>
    </FilterBorderWrapper>
  ) : null;

  return (
    <>
      {showExportModal ? (
        <ActionsArticleExportModal
          company={company}
          team={selectedTeam}
          domain={domain}
          filter={extendedFilter}
          onRequestClose={toggleExportModal}
        />
      ) : null}
      <TeamActionsShowcase />

      <StyledFilterBar
        filters={pinnedFilters}
        currentFilterId={filterId}
        components={{
          [StoredFilterType.MultiUserFilter]: FilterBarTeamFilterItem,
        }}
        singleUserFilterToolTipMessageKey={
          MessageKeys.FeedArticleFilterPersonalFilterTooltipLabel
        }
        multiUserFilterToolTipMessageKey={
          MessageKeys.FeedArticleFilterTeamFilterTooltipLabel
        }
        onPinnedFilterClick={handleStoredFilterSelected}
        onPinnedFilterSortOrderChange={handlePinnedFilterSortOrderChange}
      >
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(
            paginationData?.totalResults / paginationData?.limit ?? 1,
          )}
          disabled={isLoading}
          narrow={isMobile}
          onPageClick={handlePageChange}
        />
      </StyledFilterBar>

      <FeedArticleTableAndFilterWrapper ref={wrapper}>
        {showCalendar ? (
          <ActionsArticlesDueDateCalendar
            feedArticles={data?.items}
            isLoading={isLoading}
            month={filter.dueDate?.from ?? new Date()}
            showAgenda={showAgenda}
            domain={domain}
            mutateArticle={handleArticleMutated}
            mutateFeedArticleList={mutate}
            onMonthChange={handleCalendarMonthChange}
            onToggleShowAgenda={handleToggleShowAgenda}
            onArticleMetadataMutated={handleArticleMetadataMutated}
          />
        ) : (
          <FeedArticleTable
            feedArticles={data?.items}
            filterHash={filterHash}
            columns={columns}
            loading={isLoading}
            allowArrowKeyNavigation
            removeArchived={archived !== ArchivedOptions.All}
            sortBy={sortBy}
            mutateArticle={handleArticleMutated}
            mutateFeedArticleList={mutate}
            parentElement={wrapper.current}
            onColumnsChange={handleColumnOptionsChange}
            onChangePageByDelta={handlePageChangeByDelta}
            onSortByChange={handleSortByChange}
            onArticleMetadataMutated={handleArticleMetadataMutated}
            onTeamTagClick={onTeamTagClick}
          />
        )}
        {filterEl}
      </FeedArticleTableAndFilterWrapper>
    </>
  );
};
