import {
  H2,
  InputCheckbox,
  PrimaryButton,
  TextField,
} from '@fcg-tech/regtech-components';
import { useFormik } from 'formik';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  UserEmailForm,
  UserEmailLabel,
  UserEmailPromptButtonRow,
  UserEmailPromptCheckboxField,
  UserEmailPromptChildrenWrapper,
  UserEmailPromptContent,
  UserEmailPromptWrapper,
} from './UserEmailPrompt.styles';

interface UserEmailPromptProps {
  initialEmail?: string;
  initialRememberMe?: boolean;
  loading?: boolean;
  onSubmitEmail: (email: string, rememberMe: boolean) => void;
}

export const UserEmailPrompt: FunctionComponent<PropsWithChildren<UserEmailPromptProps>> = ({
  initialEmail = '',
  initialRememberMe = false,
  loading,
  children,
  onSubmitEmail,
}) => {
  const formik = useFormik<{ email: string; rememberMe: boolean }>({
    initialValues: {
      email: initialEmail,
      rememberMe: initialRememberMe,
    },
    onSubmit: (values) => {
      onSubmitEmail(values.email, values.rememberMe);
    },
  });

  return (
    <UserEmailPromptWrapper data-kind="user-email-prompt-wrapper">
      <UserEmailPromptContent>
        <UserEmailPromptChildrenWrapper>
          {children}
        </UserEmailPromptChildrenWrapper>
        <UserEmailForm onSubmit={formik.handleSubmit}>
          <H2>Sign in</H2>
          <UserEmailLabel htmlFor="email">E-mail</UserEmailLabel>
          <TextField
            type="email"
            name="email"
            autoCorrect="off"
            autoCapitalize="off"
            disabled={loading}
            value={formik.values.email}
            placeholder="Please enter your e-mail address"
            onChange={formik.handleChange}
          />
          <UserEmailPromptCheckboxField>
            <InputCheckbox
              id="rememberMe"
              name="rememberMe"
              label="Remember my e-mail"
              disabled={loading}
              checked={formik.values.rememberMe}
              onChange={formik.handleChange}
            />
          </UserEmailPromptCheckboxField>

          <UserEmailPromptButtonRow>
            <PrimaryButton className="wide" type="submit" loading={loading}>
              Next
            </PrimaryButton>
          </UserEmailPromptButtonRow>
        </UserEmailForm>
      </UserEmailPromptContent>
    </UserEmailPromptWrapper>
  );
};
