import { Portal, useForkRef } from '@fcg-tech/regtech-components';
import { Maybe } from '@fcg-tech/regtech-types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createEditor, Descendant, Editor, Range } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import {
  RichTextAreaWrapper,
  RichTextMention,
  RichTextPopupWrapper,
} from './RichTextArea.styles';
import {
  RichTextAnnotation,
  RichTextMentionListComponent,
} from './RichTextArea.types';
import { RichTextElement } from './RichTextElement';
import {
  detectTagsOrMentions,
  insertMention,
  pad,
  serializePlainText,
  withMentions,
} from './richTextUtils';

export interface RichTextAreaProps<MentionItem = unknown> {
  /**
   * Initial content. Cannot be changed by sending a new value
   */
  initialValue?: Array<Descendant>;
  allowedMentions?: Array<string>;
  editorRef?: React.MutableRefObject<Editor>;
  className?: string;
  placeholder?: string;
  MentionList?: RichTextMentionListComponent<MentionItem>;
  Mention?: FunctionComponent<React.HTMLProps<HTMLElement>>;
  portalContainer?: HTMLElement | string;
  onChange?: (plainText: string, markup: Array<Descendant>) => void;
  onSearch?: (
    search: string,
    type: RichTextAnnotation,
  ) => Promise<Array<MentionItem>>;
  onLoad?: (editor: Editor) => void;
}

export const RichTextArea = React.forwardRef<HTMLDivElement, RichTextAreaProps>(
  function <MentionItem>(
    {
      initialValue = [{ type: 'paragraph', children: [{ text: '' }] }],
      className,
      placeholder,
      editorRef,
      MentionList,
      Mention = RichTextMention,
      portalContainer,
      onChange,
      onSearch,
      onLoad,
    }: RichTextAreaProps<MentionItem>,
    inRef: React.ForwardedRef<HTMLDivElement>,
  ) {
    const wrapperRef = useRef<HTMLDivElement>();
    const ref = useForkRef<HTMLDivElement>(inRef, wrapperRef as never);
    const [value, setValue] = useState<Array<Descendant>>(pad(initialValue));
    const [mentionResult, setMentionResult] = useState<
      Maybe<Array<MentionItem>>
    >([]);
    const [target, setTarget] = useState<Maybe<Range>>();
    const [searchPosition, setSearchPosition] =
      React.useState<{ top: number; left: number }>();

    const renderElement = useCallback(
      (props) => <RichTextElement {...props} Mention={Mention} />,
      [Mention],
    );
    const editor = useMemo(
      () => withMentions(withReact(withHistory(createEditor()))),
      [],
    );

    if (editorRef) {
      editorRef.current = editor;
    }

    const handleChange = useCallback(
      async (value: Array<Descendant>) => {
        setValue(value);
        onChange?.(serializePlainText(value), value);
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
          const [target, search, type] = detectTagsOrMentions(
            editor,
            selection,
          );
          if (search && type) {
            setTarget(target);
            const result = await onSearch?.(search, type);
            setMentionResult(result);
            const selection = document.getSelection();
            if (selection?.anchorNode) {
              const anchorElement =
                selection.anchorNode.nodeType === Node.TEXT_NODE
                  ? selection.anchorNode.parentElement
                  : (selection.anchorNode as HTMLElement);
              if (anchorElement) {
                let { top, left } = anchorElement.getBoundingClientRect?.();

                // Offset from portal container if needed. Otherwise assume we are rendering into <body>
                if (portalContainer) {
                  const portal =
                    typeof portalContainer === 'string'
                      ? document.querySelector(portalContainer)
                      : portalContainer;
                  if (portal) {
                    const { top: portalTop, left: portalLeft } =
                      portal.getBoundingClientRect();
                    top -= portalTop;
                    left -= portalLeft;
                  }
                }

                setSearchPosition({
                  top,
                  left,
                });
              }
            }
          } else {
            setTarget(null);
            setMentionResult([]);
          }
        }
      },
      [editor, onChange, onSearch, portalContainer],
    );

    const handleSelect = useCallback(
      (id: string, type: RichTextAnnotation) => {
        if (type === RichTextAnnotation.Mention && target) {
          insertMention(editor, target, id);
          document.body.focus();
          (
            wrapperRef.current?.querySelector(
              '.slate-editor',
            ) as HTMLDivElement | null
          )?.focus();
        }
      },
      [editor, target],
    );

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        setTarget(null);
        setMentionResult([]);
      }
    }, []);

    useEffect(() => onLoad?.(editor), [editor, onLoad]);

    return (
      <RichTextAreaWrapper ref={ref} className={className}>
        <Slate editor={editor} value={value} onChange={handleChange}>
          <Editable
            renderElement={renderElement}
            placeholder={placeholder}
            className="slate-editor"
            onKeyDown={handleKeyDown}
          />
        </Slate>
        {mentionResult?.length && MentionList ? (
          <Portal container={portalContainer}>
            <RichTextPopupWrapper style={searchPosition}>
              <MentionList items={mentionResult} onSelect={handleSelect} />
            </RichTextPopupWrapper>
          </Portal>
        ) : null}
      </RichTextAreaWrapper>
    );
  },
);
