import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { DevModeIndicatorWrapper } from './DevMode.styles';
import { DevPermissionSelector } from './DevPermissionSelector';

const devModeAllowed = process.env.NODE_ENV === 'development';

const DevModeContext = React.createContext<{ enabled: boolean }>({
  enabled: false,
});

export const DevMode: FunctionComponent<EmptyPropsWithChildren> = ({ children }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (
        devModeAllowed &&
        event.key === 'D' &&
        event.ctrlKey &&
        event.shiftKey
      ) {
        setEnabled((old) => !old);
      }
    };

    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  });

  return (
    <DevModeContext.Provider value={{ enabled }}>
      {devModeAllowed && enabled ? (
        <React.Fragment>
          <DevModeIndicatorWrapper>DEV MODE ENABLED</DevModeIndicatorWrapper>
          <DevPermissionSelector>{children}</DevPermissionSelector>
        </React.Fragment>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </DevModeContext.Provider>
  );
};

export const useDevMode = () => React.useContext(DevModeContext).enabled;
