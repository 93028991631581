import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import { getResponse } from '../../api/apiUtils';
import regeye500 from '../../assets/regeye-500.svg';
import { environment } from '../../environments/environment';
import { MessageKeys } from '../../translations/translationTypes';
import { Error403 } from './Error403';
import { Error404 } from './Error404';
import { Error408 } from './Error408';
import {
  ErrorContent,
  ErrorHeading,
  ErrorImage,
  ErrorInfo,
  ErrorPageWrapper,
  ErrorRequestId,
  ErrorStatusHeading,
} from './ErrorMessage.styles';
import { GridLayoutMainWrapper } from '@fcg-tech/regtech-components';

interface ErrorMessageProps {
  error: Error;
}

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  error,
}) => {
  const { t } = useTranslation();
  const response = getResponse(error);
  if (response) {
    if (response.status === 404) {
      return (
        <GridLayoutMainWrapper className="full-height">
          <Error404 response={response} />;
        </GridLayoutMainWrapper>
      );
    }
    if (response.status === 403) {
      return (
        <GridLayoutMainWrapper className="full-height">
          <Error403 response={response} />;
        </GridLayoutMainWrapper>
      );
    }
    if (response.status === 408) {
      return (
        <GridLayoutMainWrapper className="full-height">
          <Error408 response={response} />;
        </GridLayoutMainWrapper>
      );
    }
  }

  return (
    <GridLayoutMainWrapper className="full-height">
      <ErrorPageWrapper>
        <ErrorImage src={regeye500} />
        {response ? (
          <ErrorStatusHeading>Error {response.status}</ErrorStatusHeading>
        ) : null}
        <ErrorHeading>An error occurred</ErrorHeading>
        {environment.showErrorTraces && error?.stack ? (
          <ErrorContent>
            <pre>{error?.stack}</pre>
          </ErrorContent>
        ) : null}
        {response && response.headers.get('apigw-requestid') ? (
          <>
            <ErrorInfo>{t(MessageKeys.ErrorReportToSupportMessage)}</ErrorInfo>
            <ErrorInfo className="center">
              <ErrorRequestId>
                {response.headers.get('apigw-requestid')}
              </ErrorRequestId>
            </ErrorInfo>
          </>
        ) : null}
      </ErrorPageWrapper>
    </GridLayoutMainWrapper>
  );
};
