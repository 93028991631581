import {
  CrossIcon,
  FormGridButtonRow,
  FormGridControl,
  FormGridLabel,
  FormGridRemoveButton,
  FormGridRow,
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  OptionsButton,
  PrimaryButton,
  QuestionIcon,
  randId,
  SecondaryButton,
  TextField,
  useToggle,
} from '@fcg-tech/regtech-components';
import { GlobalSearchMode, SearchRow } from '@fcg-tech/regtech-types/regeye';
import { FormikHandlers, useFormik } from 'formik';
import update from 'immutability-helper';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalForm,
  ModalFormWrapper,
  SearchHelpModal,
} from '../../components/modals';
import { MessageKeys } from '../../translations/translationTypes';
import { getSearchString } from '../../utils/searchUtils';
import {
  AdvancedSearchFilterDialogGeneratedString,
  AdvancedSearchFilterDialogGeneratedStringFormRow,
  AdvancedSearchFilterDialogHelpButton,
  AdvancedSearchFilterDialogORLabel,
  AdvancedSearchFilterDialogRowLabel,
} from './AdvancedSearchFilterDialog.styles';

const globalSearchModeTexts = {
  [GlobalSearchMode.GlobalNoFilter]:
    MessageKeys.SearchBarSearchGloballyNoFilter,
  [GlobalSearchMode.GlobalWithFilter]:
    MessageKeys.SearchBarSearchGloballyWithFilter,
  [GlobalSearchMode.LocalNoFilter]: MessageKeys.SearchBarSearchLocallyNoFilter,
  [GlobalSearchMode.LocalWithFilter]:
    MessageKeys.SearchBarSearchLocallyWithFilter,
};

interface AdvancedSearchFilterDialogProps {
  rows?: Array<SearchRow>;
  globalSearchModes?: Array<GlobalSearchMode>;
  onRequestClose: () => void;
  onSubmit: (rows: Array<SearchRow>, mode?: GlobalSearchMode) => void;
}

export const AdvancedSearchFilterDialog: FunctionComponent<
  AdvancedSearchFilterDialogProps
> = ({ rows, globalSearchModes, onRequestClose, onSubmit }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>();
  const formik = useFormik<Array<SearchRow>>({
    initialValues: rows ?? [
      {
        id: randId(),
        text: '',
        operator: undefined,
      },
    ],
    onSubmit: () => null,
  });

  const handleRemoveRow = useCallback(
    (id: string) => {
      formik.setValues((old) => {
        const index = old.findIndex((r) => r.id === id);
        let updated = old;
        if (index === 0 && updated.length === 1) {
          return update(updated, {
            [0]: {
              text: { $set: '' },
            },
          });
        }
        const lastRemoved = index === old.length - 1;
        updated = update(updated, {
          $splice: [[index, 1]],
        });

        if (lastRemoved) {
          updated = update(updated, {
            [index - 1]: {
              operator: { $set: undefined },
            },
          });
        }

        return updated;
      });
    },
    [formik],
  );

  const handleAddClick = useCallback(() => {
    formik.setValues((old) => {
      const updated = update(old, {
        [old.length - 1]: {
          operator: {
            $set: 'OR',
          },
        },
      });
      requestAnimationFrame(
        () => (ref.current.scrollTop = ref.current.scrollHeight),
      );

      return update(updated, {
        $push: [
          {
            id: randId(),
            text: '',
          },
        ],
      });
    });
  }, [formik]);

  const [showSearchHelpModal, , toggleShowSearchHelpModal] = useToggle();

  const finalString = useMemo(
    () => getSearchString(formik.values),
    [formik.values],
  );

  const handleSubmit = useCallback(() => {
    onSubmit(formik.values);
  }, [formik.values, onSubmit]);

  const handleMultiSubmit = useCallback(
    (eventOrValue: React.MouseEvent<HTMLButtonElement> | GlobalSearchMode) => {
      const mode =
        typeof eventOrValue === 'string'
          ? (eventOrValue as GlobalSearchMode)
          : ((eventOrValue.target as HTMLElement).getAttribute(
              'data-mode',
            ) as GlobalSearchMode) ?? globalSearchModes[0];
      onSubmit(formik.values, mode);
    },
    [formik.values, globalSearchModes, onSubmit],
  );

  const handleMultiQuickSubmit = useCallback(() => {
    onSubmit(formik.values, globalSearchModes[0]);
  }, [formik.values, globalSearchModes, onSubmit]);

  const optionButtonItems = useMemo(
    () =>
      globalSearchModes?.map((mode) => ({
        label: t(globalSearchModeTexts[mode]),
        value: mode,
        callback: handleMultiSubmit,
      })),
    [globalSearchModes, handleMultiSubmit, t],
  );

  return (
    <Modal onHide={onRequestClose}>
      {showSearchHelpModal ? (
        <SearchHelpModal onHide={toggleShowSearchHelpModal} />
      ) : null}
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.FeedArticleFilterAdvancedKeywordDialogHeading)}
        </ModalTitle>
      </ModalHeader>
      <ModalFormWrapper>
        <ModalForm onSubmit={formik.handleSubmit}>
          <ModalBody ref={ref}>
            {formik.values.map((row, index) => (
              <React.Fragment key={row.id}>
                <AdvancedSearchFilterDialogRow
                  key={row.id}
                  row={row}
                  index={index}
                  onChange={formik.handleChange}
                  onRemove={handleRemoveRow}
                />
              </React.Fragment>
            ))}
            <FormGridButtonRow>
              <SecondaryButton className="narrow" onClick={handleAddClick}>
                {t(
                  MessageKeys.FeedArticleFilterAdvancedKeywordSearchAddAnotherClause,
                )}
              </SecondaryButton>
            </FormGridButtonRow>
            {finalString ? (
              <>
                <AdvancedSearchFilterDialogGeneratedStringFormRow>
                  <AdvancedSearchFilterDialogRowLabel>
                    {t(
                      MessageKeys.FeedArticleFilterAdvancedKeywordSearchFinalString,
                    )}
                  </AdvancedSearchFilterDialogRowLabel>
                </AdvancedSearchFilterDialogGeneratedStringFormRow>
                <FormRow>
                  <AdvancedSearchFilterDialogGeneratedString>
                    {finalString}
                  </AdvancedSearchFilterDialogGeneratedString>
                </FormRow>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <ModalButtonRow className="space-between">
              <AdvancedSearchFilterDialogHelpButton
                onClick={toggleShowSearchHelpModal}
              >
                <QuestionIcon />
              </AdvancedSearchFilterDialogHelpButton>
              {optionButtonItems?.length ? (
                <OptionsButton
                  items={optionButtonItems}
                  onClick={handleMultiQuickSubmit}
                >
                  {optionButtonItems[0].label}
                </OptionsButton>
              ) : (
                <PrimaryButton onClick={handleSubmit}>
                  {t(MessageKeys.LabelSubmit)}
                </PrimaryButton>
              )}
            </ModalButtonRow>
          </ModalFooter>
        </ModalForm>
      </ModalFormWrapper>
    </Modal>
  );
};

interface AdvancedSearchFilterDialogRowProps {
  row: SearchRow;
  index: number;
  onChange: FormikHandlers['handleChange'];
  onRemove?: (id: string) => void;
}

export const AdvancedSearchFilterDialogRow: FunctionComponent<
  AdvancedSearchFilterDialogRowProps
> = ({ row, index, onChange, onRemove }) => {
  const { t } = useTranslation();
  const handleRemoveClick = useCallback(
    () => onRemove?.(row.id),
    [onRemove, row.id],
  );

  return (
    <>
      <FormGridRow>
        <FormGridLabel>
          {t(MessageKeys.FeedArticleFilterAdvancedKeywordSearchMustInclude)}
        </FormGridLabel>
        <FormGridControl>
          <TextField
            value={row.text}
            name={`${index}.text`}
            onChange={onChange}
          />
        </FormGridControl>
        {onRemove ? (
          <FormGridRemoveButton onClick={handleRemoveClick}>
            <CrossIcon size="14" />
          </FormGridRemoveButton>
        ) : null}
      </FormGridRow>
      {row.operator === 'OR' ? (
        <AdvancedSearchFilterDialogORLabel>
          {t(MessageKeys.FeedArticleFilterAdvancedKeywordSearchOrLabel)}
        </AdvancedSearchFilterDialogORLabel>
      ) : null}
    </>
  );
};
