import { constructFullUrl } from '@fcg-tech/regtech-api-utils';
import {
  TooltipNext as Tooltip,
  useCopyToClipboard,
} from '@fcg-tech/regtech-components';
import { Archive, Bookmark, Link as LinkIcon, Mail } from 'lucide-react';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GlobalActions, TeamActions } from '../../api/schema';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { FeedArticle, FeedArticleMetadataMutationCallbacks } from '../../types';
import { AccessControl } from '../AccessControl';
import { ActionIconButton } from '../buttons';
import {
  DataTableActionBarShiftIcon,
  DataTableActionBarTeamIcon,
  DataTableActionBarWrapper,
} from '../datatable/DataTableActionBar.styles';
import { useTeamContext } from '../TeamContext/TeamContext';

interface FeedArticleTableRowActionBarProps
  extends FeedArticleMetadataMutationCallbacks {
  feedArticle: FeedArticle;
}

export const FeedArticleTableRowActionBar: FunctionComponent<
  FeedArticleTableRowActionBarProps
> = ({ feedArticle, onMarkAsRead, onBookmark, onArchive }) => {
  const { t } = useTranslation();

  const { teamId: contextTeamId, team } = useTeamContext();
  const teamId = feedArticle.team?.id ?? contextTeamId;
  const isArchived =
    feedArticle.teamArticleMetadata?.archived?.archived ?? feedArticle.archived;

  const teamName = feedArticle.team?.name ?? team?.name ?? 'N/A';

  const [isPermalinkCopied, handleCopyPermalink] = useCopyToClipboard(
    constructFullUrl(routes.feedArticleById, {
      articleId: feedArticle.article.id,
    }),
    {
      successDuration: 3000,
    },
  );

  const handleBookmarkClick = useCallback(
    () =>
      onBookmark(!feedArticle.userMetadata.bookmarked, feedArticle.article.id),
    [feedArticle.article.id, feedArticle.userMetadata.bookmarked, onBookmark],
  );
  const handleMarkAsReadClick = useCallback(
    () => onMarkAsRead(!feedArticle.userMetadata.read, feedArticle.article.id),
    [feedArticle.article.id, feedArticle.userMetadata.read, onMarkAsRead],
  );

  const handleArchiveClick = useCallback(() => {
    onArchive(!isArchived, feedArticle.article.id, teamId);
  }, [feedArticle.article.id, isArchived, onArchive, teamId]);

  const readChangeContent = useMemo(
    () => (
      <Trans
        i18nKey={
          (feedArticle.userMetadata.read
            ? MessageKeys.ArticleMarkAsReadButtonTooltipIsReadActionBar
            : MessageKeys.ArticleMarkAsReadButtonTooltipIsNotReadActionBar) as string
        }
        components={{ shift: <DataTableActionBarShiftIcon size="18" /> }}
      />
    ),
    [feedArticle.userMetadata.read],
  );

  const bookmarkChangeContent = useMemo(
    () => (
      <Trans
        i18nKey={
          (feedArticle.userMetadata.bookmarked
            ? MessageKeys.ArticleBookmarkButtonTooltipIsBookmarkedActionBar
            : MessageKeys.ArticleBookmarkButtonTooltipIsNotBookmarkedActionBar) as string
        }
        components={{ shift: <DataTableActionBarShiftIcon size="18" /> }}
      />
    ),
    [feedArticle.userMetadata.bookmarked],
  );

  const archiveChangeContent = useMemo(
    () => (
      <Trans
        i18nKey={
          (isArchived
            ? MessageKeys.ArticleArchivedForTeamButtonTooltipActionBar
            : MessageKeys.ArticleUnArchivedForTeamButtonTooltipActionBar) as string
        }
        components={{
          shift: <DataTableActionBarShiftIcon size="18" />,
          teamIcon: <DataTableActionBarTeamIcon size="18" />,
        }}
        values={{ name: teamName }}
      />
    ),
    [isArchived, teamName],
  );

  return (
    <DataTableActionBarWrapper>
      <Tooltip
        placement="top-start"
        content={t(MessageKeys.ArticlePermalinkTooltip)}
      >
        <ActionIconButton
          message={
            isPermalinkCopied
              ? t(MessageKeys.ArticlePermalinkCopiedMessage)
              : undefined
          }
          onClick={handleCopyPermalink}
        >
          <LinkIcon size="18" />
        </ActionIconButton>
      </Tooltip>
      <AccessControl requiredPermissions={GlobalActions.ReadChange}>
        <Tooltip placement="top-start" content={readChangeContent}>
          <ActionIconButton
            active={feedArticle.userMetadata.read}
            onClick={handleMarkAsReadClick}
          >
            <Mail size="18" />
          </ActionIconButton>
        </Tooltip>
      </AccessControl>

      <AccessControl requiredPermissions={GlobalActions.BookmarkChange}>
        <Tooltip placement="top-start" content={bookmarkChangeContent}>
          <ActionIconButton
            active={feedArticle.userMetadata.bookmarked}
            onClick={handleBookmarkClick}
          >
            <Bookmark size="18" />
          </ActionIconButton>
        </Tooltip>
      </AccessControl>

      {teamId ? (
        <AccessControl
          requiredPermissions={TeamActions.ArticleArchiveChange}
          teamId={teamId}
        >
          <Tooltip placement="top-start" content={archiveChangeContent}>
            <ActionIconButton active={isArchived} onClick={handleArchiveClick}>
              <Archive size="18" />
            </ActionIconButton>
          </Tooltip>
        </AccessControl>
      ) : null}
    </DataTableActionBarWrapper>
  );
};
