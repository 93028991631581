import { constructUrl } from '@fcg-tech/regtech-api-utils';
import React, { FunctionComponent, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routes, TeamSettingKey } from '../../routes';
import { TeamSettingsFiltersDetails } from './TeamSettingsFiltersDetails';
import { TeamSettingsMembersTab } from './TeamSettingsMembersTab';
import { TeamSettingsPageOutlet } from './TeamSettingsPageOutlet';
import { TeamSettingsSubscriptionsTab } from './TeamSettingsSubscriptionsTab';
import { TeamSettingsTagsTab } from './TeamSettingsTagsTab';

export const TeamSettingsPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const params =
    useParams<{ teamId: string; settingKey?: string; '*'?: string }>();

  const teamId = params.teamId;

  useEffect(() => {
    if (!params.settingKey && !params['*']) {
      navigate(
        constructUrl(routes.teamSettingsPage, {
          teamId,
          settingKey: TeamSettingKey.Subscriptions,
        }),
      );
    }
  }, [navigate, params, params.settingKey, teamId]);

  return (
    <Routes>
      <Route path="/" element={<TeamSettingsPageOutlet />}>
        <Route
          path={TeamSettingKey.Subscriptions}
          element={
            <ErrorBoundary>
              <SuspenseLoader>
                <TeamSettingsSubscriptionsTab teamId={teamId} />
              </SuspenseLoader>
            </ErrorBoundary>
          }
        />
        <Route
          path={TeamSettingKey.Members}
          element={
            <ErrorBoundary>
              <SuspenseLoader>
                <TeamSettingsMembersTab teamId={teamId} />
              </SuspenseLoader>
            </ErrorBoundary>
          }
        />
        <Route
          path={TeamSettingKey.Tags}
          element={
            <ErrorBoundary>
              <SuspenseLoader>
                <TeamSettingsTagsTab teamId={teamId} />
              </SuspenseLoader>
            </ErrorBoundary>
          }
        />
        <Route
          path={TeamSettingKey.Filters}
          element={
            <ErrorBoundary>
              <SuspenseLoader>
                <TeamSettingsFiltersDetails teamId={teamId} />
              </SuspenseLoader>
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  );
};
