import { useTranslation } from 'react-i18next';
import {
  ConfirmDialog,
  CrossIcon,
  useConfirmDialog,
  useFlash,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  Filter as GenericFilter,
  FilterProps as GenericFilterProps,
  isFilterEmpty,
} from '@fcg-tech/regtech-filter';
import { FilterValues, StoredFilter } from '@fcg-tech/regtech-types/regeye';
import update from 'immutability-helper';
import React, { FunctionComponent, useCallback, useMemo, useState, PropsWithChildren } from 'react';
import { useSettings, useSettingsActions } from '../../api/hooks';
import { MessageKeys } from '../../translations/translationTypes';
import {
  getNoResultsMessageKey,
  isStoredFilterChanged,
} from '../../utils/filterHelpers';
import { UseFilterInterface } from '../../utils/filterHooks';
import { FeedArticleFilterSaveDialog } from './FeedArticleFilterSaveDialog';
import { FilterCloseMobileButton, GenericFilterWrapper } from './Filter.styles';
import { FilterDraftRestoredNotification } from './FilterDraftRestoredNotification';

interface FilterProps
  extends Omit<
    GenericFilterProps<FilterValues>,
    | 'storedFilters'
    | 'onFilterSaveClick'
    | 'onFilterUpdateClick'
    | 'onFilterDeleteClick'
    | 'onFilterEditNameClick'
    | 'onFilterPinClick'
  > {
  storedFilters?: Array<StoredFilter>;
  allowTeamFilters?: boolean;
  showDraftRestoredNotification?: boolean;
  onSaveFilter?: UseFilterInterface['handleFilterSaved'];
  onDeleteFilter?: UseFilterInterface['handleFilterDeleted'];
  onPinFilter?: UseFilterInterface['handleFilterPinned'];
  onRequestClose?: () => void;
}

export const Filter: FunctionComponent<PropsWithChildren<FilterProps>> = ({
  filter,
  filterId,
  storedFilters,
  allowTeamFilters,
  showDraftRestoredNotification,
  onFilterChange,
  onFilterValueChange,
  onFilterValueClear,
  onFilterValueExclude,
  onFilterLoad,
  onSaveFilter,
  onDeleteFilter,
  onPinFilter,
  onRequestClose,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const addFlash = useFlash();
  const { data: settings, mutate } = useSettings();
  const { patchSettings } = useSettingsActions();
  const selectedStoredFilter = useMemo(
    () => storedFilters?.find(({ id }) => id === filterId),
    [filterId, storedFilters],
  );

  const [newStoredFilter, setNewStoredFilter] = useState<StoredFilter>();

  const storedFilterChanged = useMemo(
    () =>
      selectedStoredFilter &&
      isStoredFilterChanged(selectedStoredFilter, filter),
    [filter, selectedStoredFilter],
  );

  const filterIsEmpty = useMemo(() => isFilterEmpty(filter), [filter]);

  const [
    showSaveFilterDialog,
    setShowSaveFilterDialog,
    toggleShowSaveFilterDialog,
  ] = useToggle();

  const handleSaveFilter = useCallback(
    async (name: string, teamFilter: boolean) => {
      setNewStoredFilter(null);
      try {
        await onSaveFilter?.({ filterValues: filter, name, teamFilter });
        addFlash({
          content: t(MessageKeys.FeedArticleFilterFilterSaveSuccess),
          level: 'success',
        });
      } catch (e) {
        addFlash({
          content: t(MessageKeys.FeedArticleFilterFilterSaveFailure),
          level: 'error',
        });
      }
    },
    [addFlash, filter, onSaveFilter, t],
  );

  const handleUpdateFilter = useCallback(
    async (overrideName?: string) => {
      setNewStoredFilter(null);
      try {
        await onSaveFilter?.({
          filterValues: filter,
          name: overrideName ?? selectedStoredFilter.name,
          filterId: selectedStoredFilter.id,
          teamFilter: !selectedStoredFilter.personalFilter,
        });
        addFlash({
          content: t(MessageKeys.FeedArticleFilterFilterSaveSuccess),
          level: 'success',
        });
      } catch (e) {
        addFlash({
          content: t(MessageKeys.FeedArticleFilterFilterSaveFailure),
          level: 'error',
        });
      }
    },
    [
      onSaveFilter,
      filter,
      selectedStoredFilter?.name,
      selectedStoredFilter?.id,
      selectedStoredFilter?.personalFilter,
      addFlash,
      t,
    ],
  );

  const handleEditFilterName = useCallback(() => {
    setShowSaveFilterDialog(true);
  }, [setShowSaveFilterDialog]);

  const handleUpdateCurrentFilterClick = useCallback(() => {
    handleUpdateFilter();
  }, [handleUpdateFilter]);

  const handleSaveAsNewFilterClick = useCallback(() => {
    const newFilter = { ...selectedStoredFilter };
    delete newFilter.id;
    setNewStoredFilter(newFilter);
    toggleShowSaveFilterDialog();
  }, [selectedStoredFilter, toggleShowSaveFilterDialog]);

  const [
    showConfirmDeleteFilterDialog,
    handleConfirmDeleteFilter,
    confirmDeleteFilter,
  ] = useConfirmDialog();

  const handleDeleteFilter = useCallback(async () => {
    const confirmed = await confirmDeleteFilter();
    if (confirmed) {
      onDeleteFilter?.(filterId);
    }
  }, [confirmDeleteFilter, filterId, onDeleteFilter]);

  const isSelectedStoredFilterPinned = useMemo(
    () => settings.pinnedFilters?.includes(filterId),
    [filterId, settings.pinnedFilters],
  );

  const handlePinFilter = useCallback(() => {
    onPinFilter?.(filterId, !isSelectedStoredFilterPinned);
  }, [filterId, isSelectedStoredFilterPinned, onPinFilter]);

  const handleDoNotShowAgainClick = useCallback(() => {
    mutate(
      update(settings, {
        flags: { hideFilterDraftRestoredNotification: { $set: true } },
      }),
    );
    patchSettings({
      flags: {
        ...(settings.flags || {}),
        hideFilterDraftRestoredNotification: true,
      },
    });
  }, [mutate, patchSettings, settings]);

  const handleGetNotification = useCallback(
    () => (
      <FilterDraftRestoredNotification
        onDoNotShowAgainClick={handleDoNotShowAgainClick}
      />
    ),
    [handleDoNotShowAgainClick],
  );

  return (
    <GenericFilterWrapper>
      {onRequestClose ? (
        <FilterCloseMobileButton onClick={onRequestClose}>
          <CrossIcon size="18" />
        </FilterCloseMobileButton>
      ) : null}
      <GenericFilter
        {...props}
        filter={filter}
        filterId={filterId}
        storedFilters={storedFilters}
        excludePropertyKey="exclude"
        clearLabel="Clear"
        excludeLabel="Exclude"
        isSelectedStoredFilterPinned={isSelectedStoredFilterPinned}
        getNoResultsMessage={getNoResultsMessageKey}
        getNotification={
          showDraftRestoredNotification &&
          settings?.flags?.hideFilterDraftRestoredNotification !== true
            ? handleGetNotification
            : undefined
        }
        saveFilterDisabled={filterIsEmpty}
        onFilterChange={onFilterChange}
        onFilterValueChange={onFilterValueChange}
        onFilterValueClear={onFilterValueClear}
        onFilterValueExclude={onFilterValueExclude}
        onFilterLoad={onFilterLoad}
        onFilterSaveClick={onSaveFilter ? handleSaveAsNewFilterClick : null}
        onFilterUpdateClick={
          storedFilterChanged ? handleUpdateCurrentFilterClick : null
        }
        onFilterDeleteClick={handleDeleteFilter}
        onFilterEditNameClick={handleEditFilterName}
        onFilterPinClick={handlePinFilter}
      >
        {showConfirmDeleteFilterDialog ? (
          <ConfirmDialog
            title={t(MessageKeys.LabelDelete)}
            body={t(MessageKeys.LabelDeleteFilterConfirmation)}
            confirmText={t(MessageKeys.LabelConfirmLabel)}
            cancelText={t(MessageKeys.LabelCancel)}
            onChoice={handleConfirmDeleteFilter}
          />
        ) : null}
        {showSaveFilterDialog ? (
          <FeedArticleFilterSaveDialog
            storedFilter={newStoredFilter ?? selectedStoredFilter}
            allowTeamFilters={allowTeamFilters}
            onSubmit={
              (newStoredFilter ?? selectedStoredFilter)?.id
                ? handleUpdateFilter
                : handleSaveFilter
            }
            onRequestClose={toggleShowSaveFilterDialog}
          />
        ) : null}
        {children}
      </GenericFilter>
    </GenericFilterWrapper>
  );
};
