import { Team } from '@fcg-tech/regtech-types/regeye';
import { atom, selectorFamily } from 'recoil';

export const teamState = atom<Array<Team>>({
  key: 'Teams',
  default: [],
});

export const team = selectorFamily({
  key: 'teamById',
  get: (id: string) => ({ get }) => get(teamState).find((t) => t.id === id),
});
