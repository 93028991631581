import { SuspenseLoader } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { PageScrollingWrapper } from '../../components/Page';
import { DashboardAssignedOverdueTeamCards } from './cards/DashboardAssignedOverdueCard';
import { DashboardBookmarksCard } from './cards/DashboardBookmarksCard';
import { DashboardNotificationsCard } from './cards/DashboardNotificationsCard';
import {
  DashboardPageCardsGrid,
  DashboardPageWrapper,
  StyledMainLayoutPaddedContentWrapper,
} from './Dashboard.styles';

export const DashboardPage: FunctionComponent = () => {
  return (
    <PageScrollingWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <StyledMainLayoutPaddedContentWrapper>
            <DashboardPageWrapper>
              <DashboardPageCardsGrid>
                <DashboardNotificationsCard />
                <DashboardBookmarksCard />
                <DashboardAssignedOverdueTeamCards />
              </DashboardPageCardsGrid>
            </DashboardPageWrapper>
          </StyledMainLayoutPaddedContentWrapper>
        </SuspenseLoader>
      </ErrorBoundary>
    </PageScrollingWrapper>
  );
};
