import { MessageLevel } from '@fcg-tech/regtech-types';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  CheckCircleIcon,
  CircleExclamationIcon,
  TriangleExclamationIcon,
} from '../Icons';
import { AlertBoxIconWrapper, AlertBoxWrapper } from './AlertBox.styles';

const iconMap: Record<MessageLevel, typeof CheckCircleIcon> = {
  success: CheckCircleIcon,
  error: TriangleExclamationIcon,
  warning: TriangleExclamationIcon,
  info: CircleExclamationIcon,
};

interface AlertBoxProps {
  level?: MessageLevel;
  slim?: boolean;
  className?: string;
}

export const AlertBox: FunctionComponent<PropsWithChildren<AlertBoxProps>> = ({
  level = MessageLevel.Info,
  slim,
  className,
  children,
}) => {
  const Icon = iconMap[level];
  return (
    <AlertBoxWrapper className={classNames(level, slim && 'slim', className)}>
      <AlertBoxIconWrapper>
        <Icon size={slim ? '16' : '22'} />
      </AlertBoxIconWrapper>
      {children}
    </AlertBoxWrapper>
  );
};
