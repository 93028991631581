import styled, { DefaultTheme } from 'styled-components';

export const FeedArticleFrameWrapper = styled.div`
  margin-top: 1.6rem;
  max-width: 75rem;
  -webkit-overflow-scrolling: touch;
`;

export const FeedArticleFrameSearchWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const FeedArticleFrameInner = styled.iframe`
  width: 100%;
  flex: 1;
  border: 0;
  outline: 0;
  -webkit-overflow-scrolling: touch;
`;

export const feedArticleFrameCss = (theme: DefaultTheme) => `
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: ${theme.typography?.fontFamily};
    background-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    white-space: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  html {
    font-size: 62.5%;
    overflow-y: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  button {
    cursor: pointer;
  }

  a {
    color: ${theme.palette?.MainColor?.main ?? 'initial'};
    font-weight: 400;
  }

  a:visited {
    color: ${theme.palette?.MainColor?.light ?? 'initial'};
  }

  a:hover,
  a:focus,
  a:active {
    color: ${theme.palette?.MainColor?.dark ?? 'initial'};
  }

  h1 {
    line-height: 4rem;
  }

  h2 {
    line-height: 3.8rem;
  }

  h3 {
    line-height: 3.4rem;
  }

  h4 {
    line-height: 3rem;
  }

  table td, table th {
    vertical-align: top;
    text-align: left;
    padding-right: 5px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  img[src*="facebook"], img[src*="linkedin"], img[src*="whatsapp"], img[src*="instagram"], img[src*="twitter"] {
    display: none;
  }
`;
