import { foundation } from '@fcg-tech/regtech-design-system';
import { Mail } from 'lucide-react';
import styled from 'styled-components';

export const SelectArticleAssigneeOptionWrapper = styled.span`
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const SelectArticleAssigneeOptionIcon = styled(Mail)`
  margin-right: 1rem;
  color: ${foundation.palette.onyx200.hex};

  &.read {
    color: ${foundation.palette.topaz500.hex};
  }
`;

export const SelectArticleAssigneeOptionLabel = styled.span`
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 1.2rem;
`;
