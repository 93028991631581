import {
  FeedArticle,
  FeedArticleMetadataMutationCallbacks,
} from '@fcg-tech/regtech-types/regeye';
import { useDevice } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent } from 'react';
import { useArticleMetadata } from '../../api/hooks';
import { FeedArticleExpandedInfo } from '../FeedArticleTable/FeedArticleExpandedInfo';
import { FeedArticleOverlaySheetContent } from './FeedArticleOverlaySheetContent';

export interface FeedArticleOverlayContentProps
  extends FeedArticleMetadataMutationCallbacks {
  feedArticle?: FeedArticle;
  setSwipeDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  onRequestClose?: () => void;
  onArticleMetadataMutated?: (articleId: string) => void;
}

export const FeedArticleOverlayContent: FunctionComponent<
  FeedArticleOverlayContentProps
> = ({
  feedArticle,
  setSwipeDisabled,
  onRequestClose,
  onArchive,
  onBookmark,
  onMarkAsRead,
  onArticleMetadataMutated,
}) => {
  const { data } = useArticleMetadata(feedArticle.article.id);
  const { isMobile } = useDevice();
  return !isMobile ? (
    <FeedArticleExpandedInfo
      feedArticle={feedArticle}
      onRequestClose={onRequestClose}
      onArchive={onArchive}
      onBookmark={onBookmark}
      onMarkAsRead={onMarkAsRead}
      onArticleMetadataMutated={onArticleMetadataMutated}
    />
  ) : (
    <FeedArticleOverlaySheetContent
      feedArticle={feedArticle}
      setSwipeDisabled={setSwipeDisabled}
      metadata={data}
      onArchive={onArchive}
      onBookmark={onBookmark}
      onMarkAsRead={onMarkAsRead}
    />
  );
};
