import { excludeProps, IconButton } from '@fcg-tech/regtech-components';
import { foundation } from '@fcg-tech/regtech-design-system';
import styled, { css } from 'styled-components';

export const FeedArticleExpandedInfoWrapper = styled.div`
  position: relative;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
  border-top: 1px dotted rgba(0, 0, 0, 0.3);
  white-space: initial;
  padding: 0;
  display: flex;
  box-shadow: inset 0 6px 4px -4px rgba(0, 0, 0, 0.3),
    inset 0 -6px 4px -4px rgba(0, 0, 0, 0.3);
  background-color: white;
  max-height: 100%;
`;

export const FeedArticleContent = styled.div.withConfig<{
  maxHeight?: number;
  hasForcedWidth?: boolean;
}>(excludeProps('maxHeight', 'hasForcedWidth'))`
  position: relative;
  padding: 3rem 4rem 3rem 2rem;
  display: flex;

  ${({ maxHeight }) =>
    maxHeight > 0
      ? css`
          max-height: ${maxHeight}px;
        `
      : ''};
  overflow-y: auto;

  ${({ hasForcedWidth }) =>
    !hasForcedWidth
      ? css`
          max-width: auto;
          flex: 1;
        `
      : ''}
`;

export const FeedArticleContentDragTrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

export const FeedArticleMinimizeButtonWrapper = styled.div`
  height: 0;
  width: 0;
  position: sticky;
  top: 0;
  right: 0;
`;

export const FeedArticleMinimizeButton = styled(IconButton)`
  position: absolute;
  top: -1.8rem;
  right: -3rem;
  width: 3.2rem;
  height: 3.2rem;
`;

export const FeedArticleTeamMetadataContainer = styled.div.withConfig<{
  maxHeight?: number;
}>(excludeProps('maxHeight'))`
  position: relative;
  padding: 3rem 2rem 3rem 2.4rem;
  background-color: ${foundation.palette.onyx100.hex};
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  flex: 1;
  ${({ maxHeight }) =>
    maxHeight > 0
      ? css`
          max-height: ${maxHeight}px;
        `
      : ''};
  overflow-y: auto;
`;

export const FeedArticleTeamMetadataPortalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const FeedArticleTeamReadMetadataHiddenItemsMessage = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  opacity: 0.6;
  display: flex;
  align-items: center;

  padding-left: 2.5rem;
  margin-top: 4.6rem;
  position: relative;

  & + & {
    margin-top: 1rem;
  }

  & > .icon {
    margin-right: 0.9rem;
    position: relative;
  }
`;

export const FeedArticleExpandedInfoResizer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  z-index: 10;
  cursor: ew-resize;
  transform: translateX(-50%);
`;

export const FeedArticleExpandedInfoCompanyHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border-radius: 0.4rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &:not(.first) {
    margin-top: 1.8rem;
  }
`;

export const FeedArticleExpandedInfoCompanyHeaderSeparator = styled.span`
  position: relative;
  flex: 1;
  margin: 0 1.6rem;
  align-self: stretch;
  &:after {
    content: ' ';
    width: 100%;
    position: absolute;
    top: 60%;
    border-top: 2px solid rgba(0, 0, 0, 0.3);
  }
`;

export const FeedArticleExpandedInfoCompanyHeaderName = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 600;

  & > .icon {
    margin-right: 1.4rem;
    position: relative;
    top: 2px;
    left: 3px;
  }
`;

export const FeedArticleExpandedInfoCompanyHeaderExpandIconWrapper = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-45%);
`;

export const FeedArticleExpandedInfoNoTeamsFoundMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  position: relative;
  top: 0.4rem;
  font-size: 1.2rem;
`;
