import { useTranslation } from 'react-i18next';
import { Portal, UsersIcon } from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { ArticleExpandedMetadata } from '../../api/schema';
import { MessageKeys } from '../../translations/translationTypes';
import { getTeamMetadataSorter } from '../../utils/sorters';
import { FeedArticleFrame } from '../FeedArticleReader/FeedArticleFrame';
import { FeedArticleBody } from '../FeedArticleReader/FeedArticleReader.styles';
import { FeedArticleTeamMetadata } from '../FeedArticleTeamMetadata';
import { portalRoot } from '../Portal';
import { useTeamContext } from '../TeamContext/TeamContext';
import { ChevronLeftIcon } from './FeedArticleOverlay.styles';
import {
  FeedArticleOverlaySheetFrameWrapper,
  FeedArticleOverlaySheetMetadataClose,
  FeedArticleOverlaySheetMetadataWrapper,
  FeedArticleOverlaySheetTeamMetadataButton,
  FeedArticleOverlaySheetWrapper,
} from './FeedArticleOverlaySheet.styles';
import {
  FeedArticleOverlaySheetHeader,
  FeedArticleOverlaySheetHeaderProps,
} from './FeedArticleOverlaySheetHeader';

interface FeedArticleOverlaySheetContentProps
  extends FeedArticleOverlaySheetHeaderProps {
  setSwipeDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  metadata: ArticleExpandedMetadata;
}

export const FeedArticleOverlaySheetContent: FunctionComponent<
  FeedArticleOverlaySheetContentProps
> = ({
  feedArticle,
  metadata,
  setSwipeDisabled,
  onBookmark,
  onMarkAsRead,
  onArchive,
}) => {
  const { t } = useTranslation();
  const [displayMetadata, setDisplayMetadata] = useState(false);
  const handleToggleDisplayMetadata = useCallback(() => {
    setDisplayMetadata((old: boolean) => {
      setTimeout(() => setSwipeDisabled(!old), 0);
      return !old;
    });
  }, [setDisplayMetadata, setSwipeDisabled]);

  const { teamId } = useTeamContext();
  return (
    <FeedArticleOverlaySheetWrapper>
      <FeedArticleOverlaySheetHeader
        feedArticle={feedArticle}
        onBookmark={onBookmark}
        onMarkAsRead={onMarkAsRead}
      />
      {!displayMetadata ? (
        <Portal container={portalRoot}>
          <FeedArticleOverlaySheetTeamMetadataButton
            className="floating"
            onClick={handleToggleDisplayMetadata}
          >
            <UsersIcon size="18" />
          </FeedArticleOverlaySheetTeamMetadataButton>
        </Portal>
      ) : null}

      <FeedArticleOverlaySheetFrameWrapper>
        {metadata.articleText ? (
          <FeedArticleFrame
            article={feedArticle.article}
            articleText={metadata.articleText}
          />
        ) : (
          <FeedArticleBody>{feedArticle.article.description}</FeedArticleBody>
        )}
      </FeedArticleOverlaySheetFrameWrapper>
      <FeedArticleOverlaySheetMetadataWrapper
        className={classNames(displayMetadata && 'open')}
      >
        <FeedArticleOverlaySheetMetadataClose
          role="button"
          title={t(MessageKeys.LabelClose)}
          onClick={handleToggleDisplayMetadata}
        >
          <ChevronLeftIcon size="22" />
          {t(MessageKeys.FeedArticleOverlayBackToArticle)}
        </FeedArticleOverlaySheetMetadataClose>
        {displayMetadata
          ? metadata?.teamReadMetadata
              ?.sort(getTeamMetadataSorter(teamId))
              .map((teamReadMetadata) => (
                <FeedArticleTeamMetadata
                  key={teamReadMetadata.team.id}
                  initiallyExpanded={teamId === teamReadMetadata.team.id}
                  articleId={feedArticle.article.id}
                  teamReadMetadata={teamReadMetadata}
                  onArchive={onArchive}
                />
              ))
          : null}
      </FeedArticleOverlaySheetMetadataWrapper>
    </FeedArticleOverlaySheetWrapper>
  );
};
