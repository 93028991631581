import { useTranslation } from 'react-i18next';
import { constructFullUrl } from '@fcg-tech/regtech-api-utils';
import {
  ArchiveIcon,
  BookmarkIcon,
  ChainIcon,
  EyeIcon,
  NotArchiveIcon,
  useCopyToClipboard,
} from '@fcg-tech/regtech-components';
import {
  FeedArticle,
  FeedArticleMetadataMutationCallbacks,
} from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useCallback } from 'react';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import {
  FeedArticleAction,
  FeedArticleActionArchiveWrapper,
  FeedArticleActionWithMessageWrapper,
} from '../FeedArticleReader/FeedArticleReader.styles';
import { useTeamContext } from '../TeamContext/TeamContext';
import {
  FeedArticleOverlaySheetHeaderWrapper,
  FeedArticleOverlaySheetPermalinkMessage,
} from './FeedArticleOverlaySheet.styles';

export interface FeedArticleOverlaySheetHeaderProps
  extends FeedArticleMetadataMutationCallbacks {
  feedArticle: FeedArticle;
}

export const FeedArticleOverlaySheetHeader: FunctionComponent<
  FeedArticleOverlaySheetHeaderProps
> = ({ feedArticle, onBookmark, onMarkAsRead, onArchive }) => {
  const { t } = useTranslation();
  const { teamId } = useTeamContext();

  const isArchivedForCurrentTeam = teamId && feedArticle.archived;

  const handleArchiveClick = useCallback(
    () =>
      onArchive?.(!isArchivedForCurrentTeam, feedArticle.article.id, teamId),
    [feedArticle.article.id, isArchivedForCurrentTeam, onArchive, teamId],
  );

  const handleBookmarkClick = useCallback(
    () =>
      onBookmark?.(
        !feedArticle.userMetadata.bookmarked,
        feedArticle.article.id,
      ),
    [feedArticle.article.id, feedArticle.userMetadata.bookmarked, onBookmark],
  );

  const handleMarkAsReadClick = useCallback(
    () =>
      onMarkAsRead?.(!feedArticle.userMetadata.read, feedArticle.article.id),
    [feedArticle.article.id, feedArticle.userMetadata.read, onMarkAsRead],
  );

  const [isPermalinkCopied, handleCopyPermalink] = useCopyToClipboard(
    constructFullUrl(routes.feedArticleById, {
      articleId: feedArticle.article.id,
    }),
    {
      successDuration: 3000,
    },
  );

  return (
    <FeedArticleOverlaySheetHeaderWrapper>
      <FeedArticleAction onClick={handleBookmarkClick}>
        <BookmarkIcon filled={feedArticle.userMetadata.bookmarked} size="18" />
      </FeedArticleAction>
      <FeedArticleAction onClick={handleMarkAsReadClick}>
        <EyeIcon disabled={!feedArticle.userMetadata.read} size="18" />
      </FeedArticleAction>
      {onArchive && teamId ? (
        <FeedArticleActionArchiveWrapper>
          <FeedArticleAction disabled={!teamId} onClick={handleArchiveClick}>
            {isArchivedForCurrentTeam || !teamId ? (
              <ArchiveIcon size="18" />
            ) : (
              <NotArchiveIcon size="18" />
            )}
          </FeedArticleAction>
        </FeedArticleActionArchiveWrapper>
      ) : null}
      <FeedArticleActionWithMessageWrapper>
        <FeedArticleAction onClick={handleCopyPermalink}>
          <ChainIcon size="18" />
        </FeedArticleAction>
        {isPermalinkCopied ? (
          <FeedArticleOverlaySheetPermalinkMessage>
            {t(MessageKeys.ArticlePermalinkCopiedMessage)}
          </FeedArticleOverlaySheetPermalinkMessage>
        ) : null}
      </FeedArticleActionWithMessageWrapper>
    </FeedArticleOverlaySheetHeaderWrapper>
  );
};
