import { excludeProps } from '@fcg-tech/regtech-components';
import { foundation } from '@fcg-tech/regtech-design-system';
import { Mail } from 'lucide-react';
import styled from 'styled-components';

export const FeedArticleReadListWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const FeedArticleReadListItem = styled.li.withConfig(
  excludeProps('read'),
)<{ read?: boolean }>`
  & + & {
    margin-top: 0.6rem;
  }
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  opacity: ${({ read }) => (read ? 1 : 0.6)};
`;

export const FeedArticleReadListItemIcon = styled(Mail).attrs({
  size: '16',
})`
  margin-right: 1rem;
  color: ${foundation.palette.onyx200.hex};

  &.read {
    color: ${foundation.palette.topaz500.hex};
  }
`;
