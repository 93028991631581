import styled from 'styled-components';

export const CompanySideNavigationWrapper = styled.div``;

export const CompanySideNavigationEmptyMessage = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  padding: 1rem;
  align-items: center;

  & > p {
    margin: 0 0 0 1rem;
    padding: 0;
  }

  margin: 0.6rem 1.6rem 1.6rem 2rem;
`;
