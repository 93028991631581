import React from 'react';
import { IconProps } from '../../types/icons';

export const SortIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
    <path
      d="M21.1511 10.2163H11.1511"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1511 5.21631H5.15112"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1511 15.2163H17.1511"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.05615 9.94958L6.05615 20.4831"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="5.9231"
      y1="20.3876"
      x2="2.82663"
      y2="17.6892"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="1"
      y1="-1"
      x2="5.10724"
      y2="-1"
      transform="matrix(0.753904 -0.656984 -0.656984 -0.753904 4.76489 20.2906)"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
