import { checkResponse, fetchAuthenticated } from '@fcg-tech/regtech-api-utils';
import { getAccessToken } from '@fcg-tech/regtech-auth';
import { environment } from '../../environments/environment';

export const getDocumentationUrl = async (): Promise<string> => {
  const response = await fetchAuthenticated(
    environment.documentationApiUrl,
    undefined,
    getAccessToken(),
  );

  if (!checkResponse(response)) {
    throw response;
  }

  const data = await response.json();

  return data.url;
};
