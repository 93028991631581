import { useTranslation } from 'react-i18next';
import Select, { ActionMeta } from 'react-select';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { NotificationSetting } from '@fcg-tech/regtech-types/regeye';
import {
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  FormGridToggle,
  isSelectAllOptionData,
  multiSelectInLabelStyles,
  MultiSelectOption,
  ReactSelectOption,
  SelectAllOptionData,
  useFlash,
} from '@fcg-tech/regtech-components';
import {
  useNotificationsSettings,
  useNotificationsSettingsActions,
} from '@fcg-tech/notifications';
import { environment } from '../../environments/environment';
import { MessageKeys } from '../../translations/translationTypes';
import { NOTIFICATIONS_REGEYE } from '../../constants';
import { MyProfileForm, MyProfileSection } from './MyProfilePage.styles';
import {
  usePersonalNotificationSettings,
  usePersonalNotificationSettingsActions,
} from '../../api/hooks';
import { classNames, single } from '@fcg-tech/regtech-utils';
import { isHTMLElement } from '../../utils/domUtils';
import { portalRoot } from '../../components/Portal';

type NotificationSettingOptions = Array<ReactSelectOption<NotificationSetting>>;

export const MyProfileNotifcationSettingsTab: FunctionComponent = () => {
  const { t } = useTranslation();
  const addFlash = useFlash();
  const { data: settings } = useNotificationsSettings(
    environment.notificationApiBaseUrl,
    NOTIFICATIONS_REGEYE,
  );

  const { enableEmailNotification, disableEmailNotification } =
    useNotificationsSettingsActions(environment.notificationApiBaseUrl);

  const { data: personalSettings } = usePersonalNotificationSettings();
  const { updateNotificationsSettings } =
    usePersonalNotificationSettingsActions();

  const notificationSettingsOptions = useMemo<
    Array<ReactSelectOption<NotificationSetting | SelectAllOptionData>>
  >(
    () => [
      {
        label: t(MessageKeys.LabelSelectAll),
        value: {
          _selectAll_: true,
        },
      },
      ...Object.keys(NotificationSetting).map<
        ReactSelectOption<NotificationSetting>
      >((key) => ({
        label: t(MessageKeys[`NotificationType${key}`]),
        value: NotificationSetting[key],
      })),
    ],
    [t],
  );

  const [selectedNotificationTypes, setSelectedNotificationTypes] =
    useState<NotificationSettingOptions>(
      notificationSettingsOptions.filter((option) =>
        personalSettings.items.includes(option.value as NotificationSetting),
      ) as NotificationSettingOptions,
    );

  const handleEnabledChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      try {
        checked
          ? await enableEmailNotification(NOTIFICATIONS_REGEYE)
          : await disableEmailNotification(NOTIFICATIONS_REGEYE);
        addFlash({
          level: 'success',
          content: t(MessageKeys.NotificationsSettingsSavedSuccess),
        });
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.NotificationsSettingsSavedFailure),
        });
      }
    },
    [addFlash, t, enableEmailNotification, disableEmailNotification],
  );

  const doUpdateNotificationsSettings = useCallback(
    async (options: NotificationSettingOptions) => {
      try {
        await updateNotificationsSettings(
          options.map<NotificationSetting>((option) => option.value),
        );
        addFlash({
          level: 'success',
          content: t(MessageKeys.NotificationsSettingsSavedSuccess),
        });
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.NotificationsSettingsSavedFailure),
        });
      }
    },
    [addFlash, t, updateNotificationsSettings],
  );

  const handleSelectedNotificationTypesChange = useCallback(
    (
      selected: Array<
        ReactSelectOption<NotificationSetting | SelectAllOptionData>
      >,
      action: ActionMeta<ReactSelectOption<NotificationSetting>>,
    ) => {
      if (selected.find((opt) => isSelectAllOptionData(opt.value))) {
        const updated = notificationSettingsOptions.slice(
          1,
        ) as NotificationSettingOptions;
        setSelectedNotificationTypes(updated);
        requestAnimationFrame(() => {
          if (isHTMLElement(document.activeElement)) {
            document.activeElement.blur();
          }
        });
      } else {
        setSelectedNotificationTypes(selected as NotificationSettingOptions);
        if (selected.length === 0 && action.action === 'clear') {
          doUpdateNotificationsSettings([]);
        }
      }
    },
    [doUpdateNotificationsSettings, notificationSettingsOptions],
  );

  const handleSelectedNotificationTypesBlur = useCallback(async () => {
    const updated = selectedNotificationTypes.map<NotificationSetting>(
      (option) => option.value,
    );
    if (
      updated.length !== personalSettings.items.length ||
      !updated.every((setting) => personalSettings.items.includes(setting))
    ) {
      doUpdateNotificationsSettings(selectedNotificationTypes);
    }
  }, [
    doUpdateNotificationsSettings,
    personalSettings.items,
    selectedNotificationTypes,
  ]);

  return (
    <MyProfileSection className="side-padding">
      <MyProfileForm>
        <FormGridRow
          className={classNames('with-toggle', settings?.enabled && 'valid')}
        >
          <FormGridLabel>
            {t(MessageKeys.MyProfileTabMySettingsNotificationsEnableEmails)}
          </FormGridLabel>
          <FormGridControl>
            <FormGridToggle
              checked={settings?.enabled}
              onChange={handleEnabledChange}
            />
          </FormGridControl>
        </FormGridRow>
        <FormGridRow
          className={classNames(
            'with-select',
            settings?.enabled && selectedNotificationTypes.length && 'valid',
            !settings?.enabled && 'disabled',
          )}
        >
          <FormGridLabel>
            {t(MessageKeys.MyProfileTabMySettingsNotificationsSelectTypes)}
          </FormGridLabel>
          <FormGridControl>
            <Select
              options={notificationSettingsOptions}
              value={selectedNotificationTypes}
              isMulti
              styles={multiSelectInLabelStyles}
              closeMenuOnSelect={false}
              menuPortalTarget={portalRoot}
              components={{ Option: MultiSelectOption }}
              hideSelectedOptions={false}
              isDisabled={!settings?.enabled}
              onChange={handleSelectedNotificationTypesChange}
              onBlur={handleSelectedNotificationTypesBlur}
            />
          </FormGridControl>
        </FormGridRow>
      </MyProfileForm>
    </MyProfileSection>
  );
};
