import { RegeyeShowcase } from '@fcg-tech/regtech-types/regeye';
import { isBefore } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings, useSettingsActions } from '../../api/hooks';

export const useShowcase = (
  id: RegeyeShowcase,
  options: {
    requireShowcaseCompleted?: Array<RegeyeShowcase>;
    initialDelay?: number;
    dontShowAfter?: Date;
  } = {},
): [
  display: boolean,
  handleShowcaseExit: () => void,
  updateIsDeferred: (isDeferred: boolean) => void,
] => {
  const { data: settings } = useSettings();
  const { patchSettings } = useSettingsActions();

  const requiredShowcasesCompleted = useMemo(() => {
    return (options.requireShowcaseCompleted ?? []).every((requiredId) =>
      settings.deferredShowcases.includes(requiredId),
    );
  }, [settings.deferredShowcases, options.requireShowcaseCompleted]);

  const [display, setDisplay] = useState(undefined);

  useEffect(() => {
    if (
      display === undefined &&
      requiredShowcasesCompleted &&
      !settings.deferredShowcases.includes(id) &&
      (!options.dontShowAfter || isBefore(new Date(), options.dontShowAfter))
    ) {
      setTimeout(() => {
        setDisplay(true);
      }, options.initialDelay ?? 0);
    }
  }, [
    display,
    id,
    options.dontShowAfter,
    options.initialDelay,
    requiredShowcasesCompleted,
    settings.deferredShowcases,
  ]);

  const handleUpdate = useCallback(
    (isDeferred: boolean) => {
      const deferred = new Set(settings.deferredShowcases);
      if (isDeferred) {
        deferred.add(id);
      } else {
        deferred.delete(id);
      }
      patchSettings({
        deferredShowcases: Array.from(deferred),
      });
      setDisplay(!isDeferred);
    },
    [id, patchSettings, settings.deferredShowcases],
  );

  const handleExit = useCallback(() => {
    const deferred = new Set(settings.deferredShowcases);
    deferred.add(id);
    patchSettings({
      deferredShowcases: Array.from(deferred),
    });
    setDisplay(false);
  }, [id, patchSettings, settings.deferredShowcases]);

  return [display, handleExit, handleUpdate];
};
