import React, { FunctionComponent } from 'react';
import {
  ImportanceChevronShadowWrapper,
  ImportanceChevronWrapper,
  ImportanceIndicatorWrapper,
} from './ImportanceIndicator.styles';
import {
  ChevronDown,
  ChevronsDown,
  ChevronsUp,
  ChevronUp,
  Icon,
  Minus,
} from 'lucide-react';
import { classNames } from '@fcg-tech/regtech-utils';

export type Importance = 0 | 1 | 2 | 3 | 4 | 5;

interface ImportanceIndicatorProps {
  /**
   * The importance of the indicator.
   * 0 or undefined = no importance set, 5 = most important
   */
  importance?: Importance;
  title?: string;
  className?: string;
}

const colorMap: Record<ImportanceIndicatorProps['importance'], string> = {
  0: '#DEDEDE',
  1: '#34C0CB',
  2: '#34A2CB',
  3: '#5ACB34',
  4: '#CB8534',
  5: '#CB5B34',
};

const chevronMap: Record<ImportanceIndicatorProps['importance'], Icon> = {
  0: Minus,
  1: ChevronsDown,
  2: ChevronDown,
  3: Minus,
  4: ChevronUp,
  5: ChevronsUp,
};

const ICON_SIZE = '20px';

export const ImportanceIndicator: FunctionComponent<
  ImportanceIndicatorProps
> = ({ className, title, importance = 0 }) => {
  const clamped = Math.max(0, Math.min(importance, 5));
  const ImportanceIcon = chevronMap[clamped];
  return (
    <ImportanceIndicatorWrapper
      title={title}
      className={classNames('importance-indicator', className)}
      style={{
        backgroundColor: colorMap[clamped],
      }}
    >
      <ImportanceChevronWrapper className="importance-indicator-chevron-wrapper">
        <ImportanceIcon color="white" style={{ width: ICON_SIZE }} />
        <ImportanceChevronShadowWrapper>
          <ImportanceIcon color="black" style={{ width: ICON_SIZE }} />
        </ImportanceChevronShadowWrapper>
      </ImportanceChevronWrapper>
    </ImportanceIndicatorWrapper>
  );
};
