import {
  AuthError,
  checkResponse,
  formatApiDate,
} from '@fcg-tech/regtech-api-utils';
import { getAccessToken } from '@fcg-tech/regtech-auth';
import update from 'immutability-helper';
import {
  ExtendedApiFilter,
  FilterExclude,
  FilterValues,
} from '@fcg-tech/regtech-types/regeye';
import { format, parse } from 'date-fns';
import { useMemo } from 'react';
import { environment } from '../environments/environment';
import { NameClashError } from './apiErrors';
import {
  Configuration,
  InsightsSelector,
  JSONApiResponse,
  Middleware,
  ResponseContext,
} from './schema';
import * as api from './schema/apis';
import { GetCompanyActionsArticlesRequest } from './schema/apis';
import { isDateRangeInterval } from '@fcg-tech/regtech-filter';
import { getLogger, getResponsePayload } from './logger';

const refDate = new Date();

export const formatQueryDate = (date: Date): string =>
  date ? format(date, 'yyyy-MM-dd') : undefined;

export const parseQueryDate = (dateStr: string) =>
  dateStr ? parse(dateStr, 'yyyy-MM-dd', refDate) : undefined;

export const isQueryDate = (dateStr: string) =>
  Boolean(dateStr?.match(/^\d{4}-\d{2}-\d{2}$/));

const intervalFromToMap = {
  'publicationDate.from': 'from',
  'publicationDate.to': 'to',
  'dueDate.from': 'dueFrom',
  'dueDate.to': 'dueTo',
  'performedDate.from': 'performedFrom',
  'performedDate.to': 'performedTo',
  'effectiveDate.from': 'effectiveFrom',
  'effectiveDate.to': 'effectiveTo',
};

export const getApiQueryValues = <T>(filterValues: T): T => {
  return Object.keys(filterValues).reduce<FilterValues>((curr, key) => {
    const value = filterValues[key];
    if (value instanceof Date) {
      curr[key] = formatApiDate(value);
    } else if (isDateRangeInterval(value)) {
      if (value.from) {
        curr[intervalFromToMap[`${key}.from`]] = formatApiDate(value.from);
      }
      if (value.to) {
        curr[intervalFromToMap[`${key}.to`]] = formatApiDate(value.to);
      }
    } else {
      curr[key] = filterValues[key];
    }
    return curr;
  }, {}) as never;
};

export const removeRedundantFilterExcludes = (
  filter?: FilterValues,
): FilterValues => {
  let updated = filter;
  if (!updated) {
    return {};
  }
  Object.values(FilterExclude).forEach((exclude) => {
    if (updated.exclude?.includes(exclude) && !updated[exclude]?.length) {
      const index = updated.exclude.indexOf(exclude);
      updated = update(updated, {
        exclude: { $splice: [[index, 1]] },
      });
    }
  });

  if (updated.exclude?.length === 0) {
    delete updated.exclude;
  }

  return updated;
};

export const getFeedArticlesFetcherQuery = (
  page: number,
  pageSize: number,
  filter?: FilterValues,
): ExtendedApiFilter => {
  return {
    skip: (page - 1) * pageSize,
    limit: pageSize,
    ...getApiQueryValues(removeRedundantFilterExcludes(filter)),
    searchString: filter?.searchString?.toString(),
  };
};

export type ActionsArticlesFilterQuery = Omit<
  GetCompanyActionsArticlesRequest,
  'companyId'
>;

export const buildInsightsFilterQuery = (
  page: number,
  pageSize: number,
  filter: FilterValues,
): ActionsArticlesFilterQuery => {
  return {
    ...getApiQueryValues(filter),
    skip: (page - 1) * pageSize,
    limit: pageSize,

    selector:
      filter.selector?.length > 0
        ? filter.selector
        : [
            InsightsSelector.WithAssignees,
            InsightsSelector.WithAttachments,
            InsightsSelector.WithComments,
            InsightsSelector.WithDueDate,
            InsightsSelector.WithImportance,
            InsightsSelector.WithTeamTags,
          ],
  };
};

export const checkResponseMiddleware: Middleware = {
  post: async ({ response }: ResponseContext) => {
    if (checkResponse(response)) {
      return Promise.resolve(response);
    }
    if (!checkAuthError(response)) {
      getLogger().error('An error occured', getResponsePayload(response));
      throw response;
    }
  },
};

export const useConfiguration = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    throw new AuthError('Accesstoken not found');
  }
  const config = useMemo(() => {
    return new Configuration({
      basePath: environment.apiBaseUrl,
      accessToken,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      middleware: [checkResponseMiddleware],
    });
  }, [accessToken]);
  return config;
};

type Apis = keyof typeof api;

export const useApi = <T>(apiKey: Apis): T => {
  const configuration = useConfiguration();
  const apiInstance = useMemo(() => {
    return new api[apiKey](configuration);
  }, [apiKey, configuration]);
  return apiInstance as unknown as T;
};

export const checkConflict = (err: unknown, message?: string) => {
  const response = getResponse(err);
  if (response?.status === 409) {
    throw new NameClashError(message);
  }
  return false;
};

export const getResponse = (err: unknown): Response | null => {
  if (err instanceof Response) {
    return err;
  }
  if (err instanceof JSONApiResponse) {
    return err.raw;
  }
  return null;
};

export const checkAuthError = (err: unknown) => {
  let response: Response;
  if (err instanceof Response) {
    response = err;
  }
  if (err instanceof JSONApiResponse) {
    response = err.raw;
  }
  if (response.status === 401) {
    return true;
  }

  return false;
};

export const getPaginatedPayload = (page: number, pageSize: number) => ({
  skip: (page - 1) * pageSize,
  limit: pageSize,
});
