import { useTranslation } from 'react-i18next';
import {
  FormRow,
  H3,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  TextArea,
} from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import React, { FunctionComponent, useCallback } from 'react';
import { useCurrentUser } from '../../../api/hooks';
import { MessageKeys } from '../../../translations/translationTypes';
import { ModalFormLabel } from '../../modals';
import { TeamMultiInvitation } from '../../TeamMultiInvitation';
import {
  SetupWizardModalFormWrapper,
  SetupWizardModalPage,
} from '../SetupWizard.styles';
import { WizardData, WizardPage } from './wizardTypes';

interface AdminUserSetupWizardTeamMembersPageProps {
  currentPage: WizardPage;
  wizardData: Partial<WizardData>;
  onChange?: (wizardData: Partial<WizardData>) => void;
  onPrev: () => void;
  onNext: () => void;
}

export const AdminUserSetupWizardTeamMembersPage: FunctionComponent<
  AdminUserSetupWizardTeamMembersPageProps
> = ({ currentPage, wizardData, onChange, onPrev, onNext }) => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const handleMessageChange = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      const updated = update(wizardData, {
        message: { $set: event.currentTarget.value },
      });

      onChange(updated);
    },
    [onChange, wizardData],
  );

  const handleEmailsChange = useCallback(
    (emails: Array<string>) =>
      onChange(
        update(wizardData, {
          memberList: {
            $set: emails?.filter((email) => email !== user.username),
          },
        }),
      ),
    [onChange, user.username, wizardData],
  );

  return (
    <SetupWizardModalPage
      className={classNames(
        currentPage > WizardPage.InviteMembers && 'after',
        currentPage < WizardPage.InviteMembers && 'before',
      )}
    >
      <ModalBody>
        <H3>
          {t(MessageKeys.WizardInviteMembers)}
        </H3>
        <SetupWizardModalFormWrapper>
          <FormRow>
            <ModalFormLabel>
              {t(MessageKeys.LabelEmailMaybePlural)}
            </ModalFormLabel>
            <TeamMultiInvitation onChange={handleEmailsChange} />
          </FormRow>

          <FormRow>
            <ModalFormLabel>
              {t(MessageKeys.LabelMessage)}
            </ModalFormLabel>
            <TextArea
              name="message"
              value={wizardData.message}
              onChange={handleMessageChange}
            />
          </FormRow>
        </SetupWizardModalFormWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalButtonRow className="space-between">
          <SecondaryButton onClick={onPrev}>
            {t(MessageKeys.LabelPrevious)}
          </SecondaryButton>
          <PrimaryButton disabled={!wizardData.memberList} onClick={onNext}>
            {t(MessageKeys.LabelNext)}
          </PrimaryButton>
        </ModalButtonRow>
      </ModalFooter>
    </SetupWizardModalPage>
  );
};
