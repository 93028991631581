import styled from 'styled-components';

import { TooltipNext, TooltipProps } from '@fcg-tech/regtech-components';
import { Icon as IconType } from 'lucide-react';
import React, { FunctionComponent } from 'react';
import { portalRoot } from './Portal';

export const Tooltip: FunctionComponent<
  Omit<TooltipProps, 'portal'> & { portal?: TooltipProps['portal'] }
> = (props) => {
  return <TooltipNext {...(props as TooltipProps)} portal={portalRoot} />;
};

const TooltipTextIconWrapper = styled.span`
  display: inline-block;
  position: relative;
  top: 3px;
`;
interface TooltipTextIconProps {
  Icon: IconType;
  size?: string;
}

export const TooltipTextIcon = ({
  Icon,
  size = '18',
}: TooltipTextIconProps) => (
  <TooltipTextIconWrapper>
    <Icon size={size} />
  </TooltipTextIconWrapper>
);
