export const insertAt = (str: string, insert: string, startIndex: number) => {
  return `${str.substring(0, startIndex)}${insert}${str.substr(
    startIndex + insert.length,
  )}`;
};
export const stripHtml = (html: string) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};
