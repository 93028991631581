import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { getFoldingAnimationName } from '../../animations';
import {
  FoldingNotificationContent,
  FoldingNotificationWrapper,
} from './FoldingNotification.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CSSTrans = CSSTransition as any; // https://github.com/facebook/react/issues/24304

interface FoldingNotificationProps {
  prefix?: string;
  in?: boolean;
  className?: string;
  hideMS?: number;
  delayMS?: number;
}

export const FoldingNotification: FunctionComponent<
  PropsWithChildren<FoldingNotificationProps>
> = ({
  className,
  in: inProp,
  prefix = 'default',
  hideMS = 3000,
  delayMS = 0,
  children,
}) => {
  const [delayedIn, setDelayedIn] = useState(false);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const entryTimer = setTimeout(() => {
      setDelayedIn(Boolean(inProp));
    }, delayMS);

    const exitTimer = setTimeout(() => {
      setDelayedIn(false);
    }, hideMS);

    return () => {
      clearTimeout(exitTimer);
      clearTimeout(entryTimer);
    };
  }, [delayMS, hideMS, inProp]);

  return (
    <CSSTrans
      in={Boolean(delayedIn)}
      timeout={200}
      classNames={getFoldingAnimationName(prefix)}
      nodeRef={ref}
      appear={Boolean(delayedIn)}
    >
      <FoldingNotificationWrapper
        $prefix={prefix}
        ref={ref}
        className={className}
      >
        <FoldingNotificationContent>{children}</FoldingNotificationContent>
      </FoldingNotificationWrapper>
    </CSSTrans>
  );
};
