import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useAuthContext } from '@fcg-tech/regtech-auth';
import {
  CompanyIcon,
  DashboardIcon,
  SettingsIcon,
  SideNavigationItem,
  SideNavigationItemIcon,
  SideNavigationItemLinkLabel,
  SideNavigationMenu,
  UsersIcon,
  useSideBar,
} from '@fcg-tech/regtech-components';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useAllTeams, useCompanies, useSettings } from '../../api/hooks';
import { GlobalActions } from '../../api/schema';
import { routeFragments, routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { Team } from '../../types';
import { getEnabledAndDisabled } from '../../utils/misc';
import { AccessControl } from '../AccessControl';
import { useInitContext } from '../InitContext';
import { CompanySideNavigation } from './CompanySideNavigation';
import {
  MainSideNavigationAdminMenu,
  MainSideNavigationContent,
  MainSideNavigationHiddenCountMenu,
  MainSideNavigationLogout,
  MainSideNavigationMenuWrapper,
  StyledRegEyeText,
  StyledSideBar,
} from './MainSideNavigation.styles';
import { PersonalSideNavigation } from './PersonalSideNavigation';

const Logo = () => <StyledRegEyeText color="white" />;

export const MainSideNavigation: FunctionComponent = () => {
  const { t } = useTranslation();
  const { logout } = useAuthContext();
  const { data: companies } = useCompanies(false, { suspense: false });
  const { data: teams } = useAllTeams({ suspense: false });
  const { error: initDataError } = useInitContext();
  const { data: settings } = useSettings();

  const [enabledCompanies, enabledTeams, disabledCompanies, disabledTeams] =
    useMemo(
      () => getEnabledAndDisabled(companies, teams, settings),
      [companies, settings, teams],
    );

  const { toggleExpandedHref } = useSideBar();
  const initialExpansionRef = useRef<boolean>(false);

  useEffect(() => {
    if (
      !initialExpansionRef.current &&
      enabledCompanies?.length &&
      enabledCompanies.length <= 2
    ) {
      enabledCompanies.forEach((company) => {
        toggleExpandedHref(
          constructUrl(routes.companyBase, { companyId: company.id }),
          true,
        );
      });
      initialExpansionRef.current = true;
    }
  }, [enabledCompanies, toggleExpandedHref]);

  const handleLogout = useCallback(() => logout(), [logout]);

  const teamsByCompany = useMemo(() => {
    const teamsByCompany: { [companyId: string]: Team[] } = {};
    enabledTeams?.forEach((team) => {
      if (!teamsByCompany[team.company?.id]) {
        teamsByCompany[team.company?.id] = [];
      }
      teamsByCompany[team.company?.id].push(team);
    });
    return teamsByCompany;
  }, [enabledTeams]);

  return (
    <StyledSideBar collapsible logo={Logo}>
      {initDataError ? null : (
        <MainSideNavigationContent>
          <MainSideNavigationMenuWrapper>
            <SideNavigationMenu>
              <SideNavigationItem
                noMobile
                href={constructUrl(routes.dashboard)}
              >
                <SideNavigationItemIcon as={DashboardIcon} />
                <SideNavigationItemLinkLabel>
                  {t(MessageKeys.SideMenuDashboard)}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </SideNavigationMenu>
            <AccessControl
              requiredPermissions={[GlobalActions.ViewNavPersonal]}
            >
              <PersonalSideNavigation />
            </AccessControl>
            {enabledCompanies.map((company) => (
              <CompanySideNavigation
                key={company.id}
                company={company}
                teams={teamsByCompany[company.id] ?? []}
              />
            ))}

            {disabledCompanies.length ? (
              <MainSideNavigationHiddenCountMenu>
                <SideNavigationItem
                  neverActive
                  href={constructUrl(routes.personalProfileSetting, {
                    settingKey: routeFragments.personalProfileTeams,
                  })}
                >
                  <SideNavigationItemIcon as={CompanyIcon} />
                  <SideNavigationItemLinkLabel>
                    {t(MessageKeys.NrOfHiddenCompaniesMessage, {
                      count: disabledCompanies.length,
                    })}
                  </SideNavigationItemLinkLabel>
                </SideNavigationItem>
              </MainSideNavigationHiddenCountMenu>
            ) : null}
            {disabledTeams.length ? (
              <MainSideNavigationHiddenCountMenu>
                <SideNavigationItem
                  neverActive
                  href={constructUrl(routes.personalProfileSetting, {
                    settingKey: routeFragments.personalProfileTeams,
                  })}
                >
                  <SideNavigationItemIcon as={UsersIcon} />
                  <SideNavigationItemLinkLabel>
                    {t(MessageKeys.NrOfHiddenTeamsMessage, {
                      count: disabledTeams.length,
                    })}
                  </SideNavigationItemLinkLabel>
                </SideNavigationItem>
              </MainSideNavigationHiddenCountMenu>
            ) : null}
            <AccessControl
              requiredPermissions={[
                GlobalActions.ViewPageCompaniesAdministration,
              ]}
            >
              <MainSideNavigationAdminMenu>
                <SideNavigationItem
                  noMobile
                  end={false}
                  href={constructUrl(routes.superAdmin)}
                >
                  <SideNavigationItemIcon as={SettingsIcon} />
                  <SideNavigationItemLinkLabel>
                    {t(MessageKeys.SideMenuAdministration)}
                  </SideNavigationItemLinkLabel>
                </SideNavigationItem>
              </MainSideNavigationAdminMenu>
            </AccessControl>
            <MainSideNavigationLogout>
              <SideNavigationItem onClick={handleLogout}>
                {t(MessageKeys.LabelLogOut)}
              </SideNavigationItem>
            </MainSideNavigationLogout>
          </MainSideNavigationMenuWrapper>
        </MainSideNavigationContent>
      )}
    </StyledSideBar>
  );
};
