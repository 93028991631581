import { useTranslation } from 'react-i18next';
import {
  FormGridButtonRow,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  FormRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextArea,
  useInput,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  ModalFormLabel,
  ModalForm,
  ModalFormWrapper,
} from '../../components/modals/modalFormComponents';
import {
  getDefaultPlaceholder,
  MessageKeys,
} from '../../translations/translationTypes';
import { TeamMultiInvitation } from '../TeamMultiInvitation';
import { TeamInviteMemberModalButtons } from './TeamInviteMemberModal.styles';

interface TeamInviteMemberModalProps {
  onConfirm: (emails: Array<string>, message: string) => void;
  onCancel: () => void;
}

export const TeamInviteMemberModal: FunctionComponent<
  TeamInviteMemberModalProps
> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<Array<string>>([]);
  const [message, onMessageChange] = useInput<HTMLTextAreaElement>('');

  const handleSubmit = useCallback(() => {
    onConfirm?.(emails, message);
  }, [emails, message, onConfirm]);

  return (
    <Modal onHide={onCancel}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.TeamSettingsTabMembersInviteMember)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper>
          <ModalForm onSubmit={handleSubmit}>
            <TeamMultiInvitation
              onChange={setEmails}
              placeholder={getDefaultPlaceholder(
                MessageKeys.LabelEmailMaybePlural,
                t,
              )}
            />
            <FormGridRow>
              <FormGridLabel>
                {t(MessageKeys.AdministrationTeamMembersInvitationMessage)}
              </FormGridLabel>
              <FormGridControl>
                <TextArea
                  value={message}
                  placeholder={getDefaultPlaceholder(
                    MessageKeys.AdministrationTeamMembersInvitationMessage,
                    t,
                  )}
                  onChange={onMessageChange}
                />
              </FormGridControl>
            </FormGridRow>

            <FormGridButtonRow>
              <SecondaryButton onClick={onCancel} type="button">
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton type="submit" disabled={Boolean(!emails?.length)}>
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </FormGridButtonRow>
          </ModalForm>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
