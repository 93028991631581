import { IconButton } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const FilterBorderWrapper = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    overflow-y: auto;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      box-shadow: -3px 0 4px 0 rgba(0, 0, 0, 0.3);
      background-color: white;
    }
  `}
`;

export const GenericFilterWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 100%;
    min-height: 100%;
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      padding-top: 5rem;
    }
  `}
`;

export const FilterCloseMobileButton = styled(IconButton)`
  ${({ theme }) => css`
    display: none;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      position: absolute;
      display: flex;
      top: 1rem;
      right: 1rem;
    }
  `}
`;

export const FilterBarSortSelectorWrapper = styled.div`
  position: relative;
`;
