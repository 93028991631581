import styled, { css } from 'styled-components';
import { Backdrop, ModalContent } from '../Modal/Modal.styles';

export const SEGUE_LENGTH = 20;

export const ShowcaseWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const ShowcaseSVG = styled.svg`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
`;

export const ShowcaseModalBackdrop = styled(Backdrop)`
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ShowcaseModalContent = styled(ModalContent)`
  min-width: 40vw;
`;

export const ShowcaseHighlightWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1002;
`;

export const ShowcaseHighlightContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 20ch;
  min-width: 30ch;
  max-width: 50ch;
  align-items: stretch;
  overflow: hidden;

  border-radius: 1rem;
  z-index: 1002;

  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
  top: 0;

  &.positioned {
    visibility: visible;
  }
`;

export const ShowcaseHighlightCircleShadow = styled.div<{ $radius: number }>`
  ${({ $radius: radius }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${radius}px ${radius}px;
    background-color: transparent;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
  `};
`;

export const ShowcaseContent = styled.div`
  background-color: white;
  margin: 0;
  overflow-y: scroll;
`;

export const ShowcasePadddedContent = styled.div`
  padding: 2.6rem;

  & > p:first-child {
    margin-top: 0;
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`;

export const ShowcaseIllustration = styled.div`
  background-color: #ccc;
  color: black;
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #999;
`;

export const ShowcaseActions = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  background-color: white;
  border-top: 1px solid #ccc;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
`;

export const ShowcaseActionWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const ShowcaseAction = styled.button`
  ${({ theme }) => css`
    border: 0;
    background: none;
    outline: 0;
    height: 4.5rem;
    flex: 1;
    margin: 0;
    color: rgb(19, 112, 212);
    opacity: 0.8;
    font-size: 1.6rem;
    font-weight: 600;

    &:hover {
      opacity: 1;
    }

    ${ShowcaseActionWrapper}:first-child > & {
      padding-left: 2.6rem;
      text-align: left;
    }

    ${ShowcaseActionWrapper}:last-child > & {
      padding-right: 2.6rem;
      text-align: right;
    }
  `}
`;

export const ShowcaseActionEmpty = styled.div`
  flex: 1;
`;
