import React, { FunctionComponent, useCallback } from 'react';
import { PrimaryButton, SecondaryButton } from '../Button';
import { Modal, ModalBody, ModalHeader, ModalTitle } from '../Modal';
import { AlertDialogButtonRow, AlertModal } from './AlertDialog.styles';

export interface AlertDialogProps {
  title: string;
  body: string;
  okLabel?: string;
  onRequestClose: () => void;
}

export const AlertDialog: FunctionComponent<AlertDialogProps> = ({
  title,
  body,
  okLabel = 'Ok',
  onRequestClose,
}) => {
  return (
    <AlertModal>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {body}
        <AlertDialogButtonRow>
          <PrimaryButton onClick={onRequestClose}>{okLabel}</PrimaryButton>
        </AlertDialogButtonRow>
      </ModalBody>
    </AlertModal>
  );
};
