import React, { FunctionComponent } from 'react';

interface SwipeIconProps {
  size?: string;
}

export const SwipeIcon: FunctionComponent<SwipeIconProps> = ({
  size = '48 ',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 298.305 298.305"
    >
      <g>
        <path
          style={{ fill: '#222220' }}
          d="M218.731,131.913l-38.492-6.036V58.702c0-15.952-12.978-28.93-28.93-28.93h-0.433
		c-15.952,0-28.93,12.978-28.93,28.93v107.881l-39.794-17.055c-7.996-3.427-17.105-2.613-24.367,2.176
		c-7.262,4.788-11.597,12.841-11.597,21.539v0.363c0,7.361,3.155,14.389,8.656,19.28l67.103,59.669v28.989
		c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-32.356c0-2.142-0.916-4.182-2.516-5.604l-69.619-61.906
		c-2.303-2.048-3.624-4.989-3.624-8.071v-0.363c0-3.695,1.77-6.982,4.854-9.017c3.085-2.034,6.804-2.367,10.201-0.911l50.247,21.534
		c0.461,0.198,0.944,0.353,1.447,0.456c0.749,0.155,1.505,0.188,2.244,0.115c0.105-0.01,0.207-0.03,0.311-0.044
		c0.134-0.019,0.268-0.035,0.401-0.062c0.152-0.029,0.299-0.069,0.447-0.108c0.082-0.021,0.166-0.04,0.247-0.063
		c0.163-0.049,0.321-0.107,0.48-0.166c0.067-0.025,0.135-0.047,0.201-0.074c0.153-0.062,0.302-0.133,0.449-0.205
		c0.071-0.034,0.143-0.066,0.213-0.103c0.132-0.069,0.259-0.146,0.387-0.223c0.083-0.05,0.167-0.098,0.248-0.151
		c0.106-0.07,0.208-0.146,0.31-0.221c0.096-0.07,0.193-0.139,0.286-0.214c0.084-0.068,0.163-0.141,0.243-0.212
		c0.103-0.09,0.206-0.18,0.304-0.276c0.071-0.07,0.136-0.144,0.204-0.217c0.097-0.104,0.196-0.206,0.287-0.316
		c0.073-0.086,0.138-0.178,0.207-0.268c0.077-0.101,0.156-0.199,0.228-0.305c0.091-0.133,0.173-0.272,0.255-0.411
		c0.041-0.069,0.085-0.134,0.124-0.205c0.36-0.651,0.628-1.361,0.781-2.113c0.104-0.503,0.152-1.01,0.152-1.512V58.702
		c0-7.681,6.249-13.93,13.93-13.93h0.433c7.681,0,13.93,6.249,13.93,13.93v72.025c0,0.277,0.017,0.549,0.047,0.818
		c-0.396,3.934,2.339,7.537,6.292,8.156l44.88,7.037c0.035,0.006,0.071,0.011,0.106,0.017c27.148,3.857,47.621,27.444,47.621,54.866
		v0.766c0,12.691-3.097,25.35-8.956,36.606l-20.345,39.088c-1.913,3.675-0.484,8.203,3.19,10.115c1.106,0.576,2.29,0.85,3.457,0.85
		c2.708,0,5.323-1.472,6.659-4.039l20.345-39.088c6.968-13.387,10.65-28.44,10.65-43.532v-0.766
		c0-16.958-6.122-33.351-17.237-46.158C250.845,142.671,235.499,134.309,218.731,131.913z"
        />
        <path
          style={{ fill: '#222220' }}
          d="M57.678,98.843c1.149,0,2.303-0.264,3.368-0.799c2.533-1.273,4.131-3.866,4.131-6.701V61.55h32.633
		c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5H65.177V16.758c0-2.835-1.599-5.428-4.131-6.701
		c-2.533-1.273-5.567-1.009-7.842,0.682L3.026,48.03C1.122,49.445,0,51.678,0,54.05s1.122,4.604,3.026,6.02l50.177,37.293
		C54.522,98.342,56.095,98.843,57.678,98.843z M50.177,76.424L20.073,54.05l30.104-22.373V76.424z"
        />
        <path
          style={{ fill: '#222220' }}
          d="M295.278,48.03l-50.178-37.292c-2.274-1.689-5.309-1.953-7.842-0.682
		c-2.533,1.273-4.131,3.866-4.131,6.701V46.55h-32.633c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h32.633v29.793
		c0,2.835,1.599,5.428,4.131,6.701c1.065,0.535,2.219,0.799,3.368,0.799c1.583,0,3.156-0.501,4.475-1.48l50.178-37.293
		c1.904-1.415,3.026-3.647,3.026-6.02S297.183,49.445,295.278,48.03z M248.127,76.424V31.676l30.104,22.374L248.127,76.424z"
        />
      </g>
    </svg>
  );
};
