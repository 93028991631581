import { FunctionComponent, useMemo } from 'react';

interface ShowcaseParagraphProps {
  content: string;
  className?: string;
}

export const ShowcaseParagraph: FunctionComponent<ShowcaseParagraphProps> = ({
  content,
  className,
}) => {
  const paragraphs = useMemo(() => {
    const ulFix = content.replace(
      /(<ul(?:[^>]*)>[\s\S]*?<\/ul>)/g,
      (match, p1) => match.replace(/\n/g, ''),
    );
    return ulFix.split('\n');
  }, [content]);
  return (
    <>
      {paragraphs.map((paragraph, index) =>
        paragraph?.length ? (
          <p
            key={index}
            className={className}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        ) : null,
      )}
    </>
  );
};
