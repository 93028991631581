import React from 'react';
import { IconProps } from '../../types/icons';

export const BookmarkIcon = ({
  size = '24',
  color = 'currentColor',
  filled,
  ...props
}: IconProps & { filled?: boolean }) => (
  <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
    {filled ? (
      <polygon
        fill={color}
        stroke={color}
        points="5.069 4.252 5.364 19.509 12.092 16.322 17.902 19.467 19.284 18.184 18.575 3.456 5.812 3.121"
      ></polygon>
    ) : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V19.0568L11.4188 15.1863C11.7665 14.9379 12.2335 14.9379 12.5812 15.1863L18 19.0568V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7ZM4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21C20 21.3746 19.7907 21.7178 19.4576 21.8892C19.1245 22.0606 18.7236 22.0315 18.4188 21.8137L12 17.2289L5.58124 21.8137C5.27642 22.0315 4.87549 22.0606 4.54242 21.8892C4.20935 21.7178 4 21.3746 4 21V5C4 4.20435 4.31607 3.44129 4.87868 2.87868Z"
      fill={color}
    />
  </svg>
);
