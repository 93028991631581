import {
  DataTableCellProps,
  DataTableColumn,
  RowRenderer,
  SortingRule,
} from '@fcg-tech/regtech-datatable';
import { foundation } from '@fcg-tech/regtech-design-system';
import { Tag } from '@fcg-tech/regtech-types/regeye';
import { Check } from 'lucide-react';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { DataTableCellWithEmptyMessage } from '../datatable/DataTableCellWithEmptyMessage';
import { RegeyeDataTable } from '../datatable/RegeyeDataTable';
import { RowData, TagTableRow } from './TagTableRow';

interface TagTableProps {
  tags: Array<Tag>;
  onRemoveTag?: (tagId: string) => Promise<void>;
  onEditTag?: (tagId: string) => void;
}

export const TagTable: FunctionComponent<TagTableProps> = ({
  tags,
  onRemoveTag,
  onEditTag,
}) => {
  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState<SortingRule<RowData>>({
    desc: false,
    id: 'name',
  });

  const DescriptionCell = useMemo(
    () => (props: DataTableCellProps<RowData>) =>
      (
        <DataTableCellWithEmptyMessage
          {...props}
          emptyMessage={t(MessageKeys.TagTableItemNoDescription)}
        />
      ),
    [t],
  );

  const ActiveCell = useMemo(
    () => (props: DataTableCellProps<RowData, boolean>) =>
      (
        <Check
          color={
            props.cell.value
              ? foundation.palette.emerald300.hex
              : foundation.palette.opal100.hex
          }
          size="18"
        />
      ),
    [],
  );

  const columns = useMemo<Array<DataTableColumn<RowData>>>(() => {
    const columns: Array<DataTableColumn<RowData>> = [
      {
        id: 'name',
        width: 200,
        minWidth: 200,
        defaultCanSort: true,
        Header: t(MessageKeys.LabelTagName),
        accessor: (row: RowData) => row.name,
      },
      {
        id: 'description',
        width: 200,
        minWidth: 200,
        defaultCanSort: true,
        Header: t(MessageKeys.LabelTagDescription),
        Cell: DescriptionCell,
        accessor: (row: RowData) => row.description,
      },
      {
        id: 'active',
        width: 70,
        minWidth: 70,
        defaultCanSort: true,
        Header: t(MessageKeys.AdministrationTeamDetailsTagActiveLabel),
        Cell: ActiveCell,
        accessor: (row: RowData) => row.active,
      },
    ];
    return columns;
  }, [DescriptionCell, ActiveCell, t]);

  const dataRows = useMemo<Array<RowData>>(() => {
    return tags
      .map((tag) => ({
        id: tag.id,
        name: tag.name,
        description: tag.description,
        active: tag.active,
      }))
      .sort((a, b) => {
        if (sortOrder?.desc === undefined) {
          return 0;
        }
        switch (sortOrder?.id) {
          case 'name':
            return a.name.localeCompare(b.name) * (sortOrder?.desc ? -1 : 1);
          case 'description':
            if (!a.description && b.description) {
              return 1;
            }
            if (a.description && !b.description) {
              return -1;
            }
            return (
              a.description.localeCompare(b.description) *
              (sortOrder?.desc ? -1 : 1)
            );
        }
      });
  }, [tags, sortOrder]);

  const WrappedRow = useMemo<RowRenderer<RowData>>(
    () => (props) =>
      <TagTableRow {...props} onEdit={onEditTag} onRemove={onRemoveTag} />,
    [onEditTag, onRemoveTag],
  );

  return (
    <RegeyeDataTable
      columns={columns}
      data={dataRows}
      sortBy={sortOrder}
      Row={WrappedRow}
      onSortByChange={setSortOrder}
    />
  );
};
