/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InitData,
    InitDataFromJSON,
    InitDataToJSON,
} from '../models';

/**
 * InitApi - interface
 * 
 * @export
 * @interface InitApiInterface
 */
export interface InitApiInterface {
    /**
     * 
     * @summary Initialization data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitApiInterface
     */
    getInitDataRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<InitData>>;

    /**
     * Initialization data
     */
    getInitData(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<InitData>;

}

/**
 * 
 */
export class InitApi extends runtime.BaseAPI implements InitApiInterface {

    /**
     * Initialization data
     */
    async getInitDataRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<InitData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/init`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InitDataFromJSON(jsonValue));
    }

    /**
     * Initialization data
     */
    async getInitData(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<InitData> {
        const response = await this.getInitDataRaw(initOverrides);
        return await response.value();
    }

}
