import update from 'immutability-helper';
import { FunctionComponent, useCallback } from 'react';

import { FeedArticleListMutator } from '@fcg-tech/regtech-types/regeye';
import { useDevice } from '@fcg-tech/regtech-utils';
import { useFeedArticle } from '../../api/hooks';
import { FeedArticleOverlayContent } from '../../components/FeedArticleOverlay';
import { useFeedArticleActionHandlers } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { TeamContextProvider } from '../../components/TeamContext/TeamContext';
import { FeedArticlePageExpandedInfo } from './FeedArticlePage.styles';

interface FeedArticlePageLoaderProps {
  articleId: string;
  teamId?: string;
}

export const FeedArticlePageLoader: FunctionComponent<
  FeedArticlePageLoaderProps
> = ({ articleId, teamId }) => {
  const { data: feedArticle, mutate } = useFeedArticle(articleId);
  const { isMobile } = useDevice();

  const mutateArticle = useCallback(
    (articleId: string, metadata: { bookmarked?: boolean; read?: boolean }) => {
      mutate(
        update(feedArticle, {
          userMetadata: {
            $merge: metadata,
          },
        }),
      );
    },
    [feedArticle, mutate],
  );

  const mutateFeedArticle = useCallback(
    async (callback: FeedArticleListMutator) => {
      const result = await callback({
        items: [feedArticle],
        pagination: { totalResults: 1, limit: 1, skip: 0 },
      });

      mutate(result.items[0]);
    },
    [feedArticle, mutate],
  );

  const { handleArchiveChange, handleBookmarkChange, handleMarkAsReadChange } =
    useFeedArticleActionHandlers({
      mutateArticle,
      mutateFeedArticleList: mutateFeedArticle,
    });

  const page = !isMobile ? (
    <FeedArticlePageExpandedInfo
      feedArticle={feedArticle}
      onBookmark={handleBookmarkChange}
      onMarkAsRead={handleMarkAsReadChange}
      onArchive={handleArchiveChange}
    />
  ) : (
    <FeedArticleOverlayContent
      feedArticle={feedArticle}
      onArchive={handleArchiveChange}
      onBookmark={handleBookmarkChange}
      onMarkAsRead={handleMarkAsReadChange}
    />
  );

  return teamId ? (
    <TeamContextProvider teamId={teamId}>{page}</TeamContextProvider>
  ) : (
    page
  );
};
