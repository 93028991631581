/* eslint-disable @typescript-eslint/triple-slash-reference */
/* eslint-disable @typescript-eslint/ban-types */
/// <reference path="../@types/react-table/index.d.ts" />
/// <reference path="../@types/react/index.d.ts" />

import { classNames } from '@fcg-tech/regtech-utils';
import React, { useCallback, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@fcg-tech/regtech-components';
import {
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
} from '@fcg-tech/regtech-components';
import {
  DataTableSortPickerRow,
  DataTableSortPickerRowButton,
  DataTableSortPickerRowLabel,
} from './DataTableSortPicker.styles';
import { DataTableColumn, SortingRule } from './types';
import { IdType } from 'react-table';

export interface DataTableSortPickerProps<D extends object = {}> {
  columns: Array<DataTableColumn<D>>;
  getColumnLabel: (column: DataTableColumn<D>) => string;
  sortBy?: SortingRule<D>;
  ascendingLabel?: string;
  descendingLabel?: string;
  submitLabel?: string;
  cancelLabel?: string;
  heading?: string;
  onChange: (sortBy: SortingRule<D> | undefined) => void;
  onRequestClose: () => void;
}

const DataTableSortPickerInner = <D extends object = {}>(
  {
    columns,
    getColumnLabel,
    sortBy: initialSortBy,
    ascendingLabel,
    descendingLabel,
    heading,
    submitLabel,
    cancelLabel,
    onChange,
    onRequestClose,
  }: DataTableSortPickerProps<D>,
  ref: React.ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  const [sortBy, setSortBy] = useState(initialSortBy);

  const handleSortByClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const ascending =
        (event.target as HTMLElement).getAttribute('data-ascending') === 'true';
      const id = (event.target as HTMLElement).getAttribute(
        'data-key',
      ) as unknown as IdType<D>;

      setSortBy({
        desc: !ascending,
        id,
      });
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    onChange?.(sortBy);
  }, [onChange, sortBy]);

  return (
    <Modal onHide={onRequestClose}>
      {heading ? (
        <ModalHeader>
          <ModalTitle>{heading}</ModalTitle>
        </ModalHeader>
      ) : null}
      <ModalBody ref={ref}>
        {columns
          .filter((col) => col.defaultCanSort)
          .map((col) => (
            <DataTableSortPickerRow key={col.id?.toString()}>
              <DataTableSortPickerRowLabel>
                {getColumnLabel(col)}
              </DataTableSortPickerRowLabel>
              <DataTableSortPickerRowButton
                data-ascending="true"
                data-key={col.id}
                className={classNames(
                  col.id === sortBy?.id && !sortBy?.desc && 'active',
                )}
                onClick={handleSortByClick}
              >
                {ascendingLabel ?? 'Ascending'}
              </DataTableSortPickerRowButton>
              <DataTableSortPickerRowButton
                data-ascending="false"
                data-key={col.id}
                className={classNames(
                  col.id === sortBy?.id && !sortBy?.desc === false && 'active',
                )}
                onClick={handleSortByClick}
              >
                {descendingLabel ?? 'Descending'}
              </DataTableSortPickerRowButton>
            </DataTableSortPickerRow>
          ))}
        <ModalButtonRow>
          <SecondaryButton onClick={onRequestClose}>
            {cancelLabel ?? 'Ok'}
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmit}>
            {submitLabel ?? 'Ok'}
          </PrimaryButton>
        </ModalButtonRow>
      </ModalBody>
    </Modal>
  );
};

export const DataTableSortPicker = React.forwardRef(DataTableSortPickerInner);
