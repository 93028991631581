import type { Publisher } from '../../../api/schema';

export type WizardData = {
  companyId: string;
  teamName: string;
  addMe: boolean;
  publishers: Array<Publisher>;
  memberList?: Array<string>;
  message: string;
};

export enum WizardProgress {
  Unstarted,
  CreateTeam,
  InviteMembers,
  UpdateSubscriptions,
  Done,
}

export enum WizardPage {
  Start,
  CreateTeam,
  InviteMembers,
  SelectSources,
  Summary,
  Progress,
  Done,
}
