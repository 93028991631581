import {
  ConfirmDialog,
  DangerButton,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  TextField,
  useConfirmDialog,
  useFlashContext,
} from '@fcg-tech/regtech-components';
import { Company, CompanyInput } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import { useFormik } from 'formik';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NameClashError } from '../../api/apiErrors';

import { useCompanyActions } from '../../api/hooks';
import { CompanyUpdateInput } from '../../api/schema';
import { useIamContext } from '../../components/IamContext';
import {
  ModalForm,
  ModalFormWrapper,
} from '../../components/modals/modalFormComponents';
import { MessageKeys } from '../../translations/translationTypes';
import {
  canCompanyCreate,
  canCompanyDelete,
  canCompanyElevatedEdit,
} from '../../utils/iamHelpers';

interface CompanyAdministrationEditCompanyModalProps {
  company?: Company;
  onRequestClose: (deleted?: boolean, created?: Company) => void;
}

export const CompanyAdministrationEditCompanyModal: FunctionComponent<
  CompanyAdministrationEditCompanyModalProps
> = ({ company, onRequestClose }) => {
  const { t } = useTranslation();
  const { createCompany, deleteCompany, updateCompany, updateCompanyElevated } =
    useCompanyActions();

  const [
    showDeleteCompanyConfirm,
    handleDeleteCompanyConfirm,
    confirmDeleteCompany,
  ] = useConfirmDialog();
  const { addFlash, clearFlash } = useFlashContext();

  const companyId = company?.id;

  const iamContext = useIamContext();
  const hasCompanyCreate = canCompanyCreate(iamContext);
  const hasCompanyEditElevated =
    company && canCompanyElevatedEdit(companyId, iamContext);

  const handleDeleteCompany = useCallback(async () => {
    const confirmed = await confirmDeleteCompany();
    if (confirmed) {
      await deleteCompany(companyId);
      onRequestClose(true);
    }
  }, [companyId, confirmDeleteCompany, deleteCompany, onRequestClose]);

  const formik = useFormik<Partial<CompanyInput>>({
    initialValues: {
      name: company?.name ?? '',
      stringId: company?.stringId ?? '',
    },
    onSubmit: async (values) => {
      try {
        clearFlash('company-name-clash');
        if (hasCompanyEditElevated && company) {
          if (values.name && values.stringId) {
            await updateCompanyElevated(company.id, values as CompanyInput);
            onRequestClose(false);
          }
        } else if (company) {
          if (values.name) {
            await updateCompany(company.id, values as CompanyUpdateInput);
            onRequestClose(false);
          }
        } else if (hasCompanyCreate) {
          if (values.name && values.stringId) {
            const c = await createCompany(values as CompanyInput);
            onRequestClose(false, c);
          }
        }
      } catch (e) {
        if (e instanceof NameClashError) {
          addFlash({
            id: 'company-name-clash',
            level: 'error',
            timeoutMS: 8000,
            content: e.message,
          });
        } else {
          addFlash({
            id: 'company-name-clash',
            level: 'error',
            content: t(MessageKeys.LabelCouldNotSave),
          });
        }
      }
    },
  });

  const handleHide = useCallback(() => onRequestClose(false), [onRequestClose]);

  return (
    <>
      {showDeleteCompanyConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirmAction)}
          body={t(MessageKeys.AdministrationCompanyDeleteConfirm, {
            name: company.name,
          })}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleDeleteCompanyConfirm}
        />
      ) : null}
      <Modal onHide={handleHide}>
        <ModalHeader>
          <ModalTitle>
            {t(
              company
                ? MessageKeys.AdministrationCompanyEditHeading
                : MessageKeys.AdministrationCompanyCreateHeading,
              {
                name: company?.name,
              },
            )}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ModalFormWrapper>
            <ModalForm onSubmit={formik.handleSubmit}>
              <FormGridRow
                className={classNames(formik.values.name && 'valid')}
              >
                <FormGridLabel>{t(MessageKeys.LabelCompanyName)}</FormGridLabel>
                <FormGridControl>
                  <TextField
                    name="name"
                    value={formik.values.name}
                    autoFocus
                    onChange={formik.handleChange}
                  />
                </FormGridControl>
              </FormGridRow>

              {hasCompanyEditElevated || (!company && hasCompanyCreate) ? (
                <FormGridRow
                  className={classNames(formik.values.stringId && 'valid')}
                >
                  <FormGridLabel>
                    {t(MessageKeys.LabelCompanyStringId)}
                  </FormGridLabel>
                  <FormGridControl>
                    <TextField
                      name="stringId"
                      value={formik.values.stringId}
                      onChange={formik.handleChange}
                    />
                  </FormGridControl>
                </FormGridRow>
              ) : null}
              <ModalButtonRow>
                {company && canCompanyDelete(company.id, iamContext) ? (
                  <DangerButton onClick={handleDeleteCompany}>
                    {t(MessageKeys.AdministrationCompanyDeleteButtonLabel)}
                  </DangerButton>
                ) : null}
                <PrimaryButton
                  type="submit"
                  disabled={
                    !formik.values.name ||
                    ((hasCompanyEditElevated ||
                      (!company && hasCompanyCreate)) &&
                      !formik.values.stringId)
                  }
                >
                  {t(MessageKeys.LabelSubmit)}
                </PrimaryButton>
              </ModalButtonRow>
            </ModalForm>
          </ModalFormWrapper>
        </ModalBody>
      </Modal>
    </>
  );
};
