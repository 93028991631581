import { TrashIcon } from '@fcg-tech/regtech-components';
import { User } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useMemo, PropsWithChildren } from 'react';
import { useSettings } from '../../api/hooks';
import { formatName } from '../../utils/formatters';
import { Tooltip } from '../Tooltip';
import {
  TeamMemberListItemActionsWrapper,
  TeamMemberListItemIconWrapper,
  TeamMemberListItemInnerWrapper,
  TeamMemberListItemNameWrapper,
  TeamMemberListItemWrapper,
  TeamMemberListRemoveButton,
} from './TeamMemberList.styles';

export interface IconRenderer {
  (membmer: User): JSX.Element | Array<JSX.Element>;
}

export type GetTooltip = (
  username: string,
  action: 'custom' | 'remove',
) => string;

export interface TeamMemberListItemProps {
  member: User;
  icon?: IconRenderer;
  getTooltip?: GetTooltip;
  emph?: boolean;
  className?: string;
  onRemoveClick?: (userId: string) => void;
}

export const TeamMemberListItem: FunctionComponent<PropsWithChildren<TeamMemberListItemProps>> = ({
  member,
  icon,
  getTooltip,
  emph,
  className,
  children,
  onRemoveClick,
}) => {
  const { data: settings } = useSettings({ suspense: false });
  const handleRemoveClick = useCallback(
    () => onRemoveClick(member.username),
    [member.username, onRemoveClick],
  );

  const iconTooltipText = useMemo(
    () => getTooltip(member.username, 'custom'),
    [getTooltip, member.username],
  );
  const deleteTooltipText = useMemo(
    () => getTooltip(member.username, 'remove'),
    [getTooltip, member.username],
  );

  return (
    <TeamMemberListItemWrapper
      className={classNames(className, emph && 'emph')}
      data-testid={`team-member-list-item-${member.username}`}
    >
      <TeamMemberListItemInnerWrapper>
        {icon ? (
          <Tooltip content={iconTooltipText}>
            <TeamMemberListItemIconWrapper>
              {icon(member)}
            </TeamMemberListItemIconWrapper>
          </Tooltip>
        ) : null}
        <TeamMemberListItemNameWrapper
          title={formatName(member, settings?.userNamePreference)}
        >
          {formatName(member, settings?.userNamePreference)}
        </TeamMemberListItemNameWrapper>
      </TeamMemberListItemInnerWrapper>
      <TeamMemberListItemActionsWrapper>
        {children}
        {onRemoveClick ? (
          <Tooltip content={deleteTooltipText}>
            <TeamMemberListRemoveButton onClick={handleRemoveClick}>
              <TrashIcon size="16" />
            </TeamMemberListRemoveButton>
          </Tooltip>
        ) : null}
      </TeamMemberListItemActionsWrapper>
    </TeamMemberListItemWrapper>
  );
};
