import { prune } from '@fcg-tech/regtech-utils';
import { FilterValues, TeamAction } from '@fcg-tech/regtech-types/regeye';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { isQueryDate, parseQueryDate } from '../api/apiUtils';

export const parseQuery = (url: string) =>
  parse(url.replace(/^[?#]/, '') ?? '', {
    arrayFormat: 'comma',
  }) ?? {};

type QueryKeys = keyof FilterValues;

export const parseUrlFilterValues = <K extends keyof FilterValues>(
  url: string,
  filterProps: Array<K>,
): FilterValues => {
  const filter: FilterValues = {};
  const parsed = parseQuery(url) as Record<QueryKeys, string | Array<string>>;

  filterProps.forEach((filterProp) => {
    if (filterProp === 'searchString') {
      filter.searchString = Array.isArray(parsed.searchString)
        ? parsed.searchString.join(',')
        : parsed.searchString;
    } else if (filterProp.match(/Interval$/)) {
      // Due date interval, etc
      filter[filterProp] = Array.isArray(parsed[filterProp])
        ? (parsed[filterProp][0] as never)
        : (parsed[filterProp] as never);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const prop = parsed[filterProp];
      if (prop) {
        const stringArray = Array.isArray(prop)
          ? prop
          : (prop as string).split(',');

        if (filterProp === 'actions') {
          filter.actions = stringArray as Array<TeamAction>;
        } else if (filterProp === 'importance') {
          filter.importance = stringArray.map(Number);
        } else {
          if (isQueryDate(stringArray[0])) {
            filter[filterProp] = parseQueryDate(stringArray[0]) as never;
          } else {
            filter[filterProp] = stringArray as never;
          }
        }
      }
    }
  });

  return prune(filter);
};

/**
 * This function caches data from e.g. useSWR and resets it when swr returns new data. During a fetch, swr might
 * return null data, which will cause a flash of content. This stupid hook prevents that
 */
export const useNonEphemeralData = <T>(newData: T): T => {
  const [data, setData] = useState(newData);
  useEffect(() => {
    if (newData) {
      setData(newData);
    }
  }, [newData]);

  return data;
};
