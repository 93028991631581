import { FunctionComponent } from 'react';
import {
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
} from '@fcg-tech/regtech-components';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { SetupWizardModalWrapper } from './SetupWizard.styles';

interface UserWithNoTeamsSetupWizardModalProps {
  onRequestClose?: () => void;
}

export const UserWithNoTeamsSetupWizardModal: FunctionComponent<UserWithNoTeamsSetupWizardModalProps> =
  ({ onRequestClose }) => {
    const { t } = useTranslation();
    return (
      <SetupWizardModalWrapper>
        <ModalHeader>
          <ModalTitle>
            {t(MessageKeys.WizardWelcomeToRegeye)}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            {t(MessageKeys.WizardNoTeamsIntro)}
          </p>
        </ModalBody>
        <ModalFooter>
          <ModalButtonRow>
            <PrimaryButton onClick={onRequestClose}>
              {t(MessageKeys.LabelClose)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFooter>
      </SetupWizardModalWrapper>
    );
  };
