import { IconLink } from '@fcg-tech/regtech-components';
import styled from 'styled-components';
import { Tag } from '../Tag';

export const PublisherBooleanTreeRegionLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  position: relative;

  &.selected {
    font-weight: 600;
  }
`;

export const PublisherBooleanTreeItemLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  position: relative;

  &.selected {
    font-weight: 500;
  }
`;

export const PublisherBooleanTreeExternalLink = styled(IconLink)`
  margin-left: 1.2rem;
`;

export const PublisherBooleanTreeArticleTypeNotUpdatedWarning = styled.div`
  font-weight: 400;
  color: #666;
  margin-left: 1.2rem;
`;

export const PublisherBooleanTreeNrOfItemsAbsoluteWrapper = styled.div`
  position: absolute;
  left: 14rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
`;

export const PublisherBooleanTreeNrOfItems = styled.span`
  padding: 0.2rem 0.5rem;
  min-width: 2rem;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  margin-left: 2rem;

  & + & {
    margin-left: 1rem;
  }

  &.selected {
    background-color: ${({ theme }) => theme.palette?.MainColorAccent.main};
  }
`;

export const PublisherBooleanTreeMatchedOuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PublisherBooleanTreeMatchedWrapper = styled.div`
  margin-right: 4.6rem;
  margin-bottom: 2.6rem;

  & ${PublisherBooleanTreeNrOfItemsAbsoluteWrapper} {
    position: relative;
    left: initial;
    top: initial;
    transform: initial;
  }
`;

export const RegionTag = styled(Tag)`
  background-color: #92cde3;
  border-color: #3c788f;
  color: black;
  margin-right: 1rem;
`;
