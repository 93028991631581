import { StylesConfig } from 'react-select';
import styled from 'styled-components';
import { MainLayoutSideSubNavigationOutletWrapper } from '../../components/MainLayout/MainLayout.styles';

export const CompanyAdministrationPageHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1.6rem 0;
`;

export const CompanyAdministrationPageOutletWrapper = styled(
  MainLayoutSideSubNavigationOutletWrapper,
)`
  & .tab-link-list__tab-item.tab-link-list__tab-item--label.select-company {
    padding: 0;
  }
`;

export const CompanyAdministrationDetailsForm = styled.form`
  width: 40rem;
`;

export const companySelectStyles: StylesConfig<unknown, false> = {
  container: (p) => ({ ...p, width: '100%' }),
  control: (p) => ({
    ...p,
    border: 0,
    borderRadius: 0,
    boxShadow: 'none',
  }),
  menu: (p) => ({
    ...p,
    zIndex: 10,
    fontWeight: 400,
    right: 0,
    left: 'initial',
    width: 'initial',
    minWidth: '100%',
    marginTop: 4,
    whiteSpace: 'nowrap',
  }),
};
