import { mobile } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const TeamInvitationItemWrapper = styled.div`
  display: flex;
  font-size: 1.4rem;
  position: relative;

  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.3);
    margin-top: 1rem;
    padding-top: 1rem;
  }

  &.narrow {
    flex-direction: column;
  }

  &.narrow > div:first-child {
    margin-top: 0;
  }

  ${mobile(
    () => css`
      flex-direction: column;
    `,
  )}
`;

export const TeamInvitationItemLabel = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  min-width: 7rem;
  white-space: nowrap;
`;

const itemValueNarrowCss = css`
  padding-left: 1rem;
  text-align: right;
`;

export const TeamInvitationItemValue = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

  ${TeamInvitationItemWrapper}.narrow & {
    ${itemValueNarrowCss}
  }

  ${mobile(() => itemValueNarrowCss)}
`;

const infoNarrowCss = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 0;
`;

export const TeamInvitationItemInfo = styled.div`
  flex: 1;
  &:not(:first-child) {
    margin-left: 1.6rem;
  }

  ${TeamInvitationItemWrapper}.narrow & {
    ${infoNarrowCss}

    margin-top: 1rem;
    margin-left: 0;
  }

  ${mobile(
    () => css`
      ${infoNarrowCss}

      & + & {
        margin-top: 1rem;
        margin-left: 0;
      }
    `,
  )}
`;

export const TeamInvitationItemInfoMessage = styled(TeamInvitationItemInfo)`
  flex: 2;
`;

const actionsNarrowCss = css`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const TeamInvitationItemActions = styled.div`
  width: 30rem;

  & > button + button {
    margin-left: 1.6rem;
  }

  ${TeamInvitationItemWrapper}.narrow & {
    ${actionsNarrowCss}
  }
  ${mobile(() => actionsNarrowCss)}
`;

const statusNarrowCss = css`
  top: 0;
  right: 0;
  margin-right: -3rem;
  margin-top: -3rem;
`;

export const TeamInvitationStatus = styled.div`
  position: absolute;
  margin-top: 0;
  margin-right: 0;
  right: 0;

  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  ${TeamInvitationItemWrapper}.narrow & {
    ${statusNarrowCss}
  }
  ${mobile(() => statusNarrowCss)}
`;
