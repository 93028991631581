import { GridLayoutMainWrapper } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const TeamOutletWrapper = styled(GridLayoutMainWrapper)`
  height: 100%;
  min-width: 0;
  overflow: hidden;

  & > .tab-panel {
    height: 100%;
  }
`;
