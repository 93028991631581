import { FeedArticle } from '@fcg-tech/regtech-types/regeye';
import { format } from 'date-fns';
import React, { FunctionComponent, useCallback } from 'react';
import { MOBILE_ARTICLE_ROW_TITLE_MAX_LENGTH } from '../../constants';
import { ArticleTypeTag } from '../Tag';
import {
  FeedArticleCondensedTableRowMeta,
  FeedArticleCondensedTableRowTagList,
  FeedArticleCondensedTableRowTitle,
  FeedArticleCondensedTableRowWrapper,
} from './FeedArticleCondensedTable.styles';

interface FeedArticleCondensedTableRowProps {
  feedArticle: FeedArticle;
  onClick: (articleId: string) => void;
}

export const FeedArticleCondensedTableRow: FunctionComponent<
  FeedArticleCondensedTableRowProps
> = ({ feedArticle, onClick }) => {
  const handleClick = useCallback(
    () => onClick?.(feedArticle.internalId),
    [feedArticle.internalId, onClick],
  );

  return (
    <FeedArticleCondensedTableRowWrapper role="button" onClick={handleClick}>
      <FeedArticleCondensedTableRowMeta>
        <span>{feedArticle.article.publisher}</span>
        <span>{format(feedArticle.article.publicationDate, 'yyyy-MM-dd')}</span>
      </FeedArticleCondensedTableRowMeta>
      <FeedArticleCondensedTableRowTitle>
        {feedArticle.article.title.substr(
          0,
          MOBILE_ARTICLE_ROW_TITLE_MAX_LENGTH,
        )}
        {feedArticle.article.title.length > MOBILE_ARTICLE_ROW_TITLE_MAX_LENGTH
          ? '...'
          : ''}
      </FeedArticleCondensedTableRowTitle>
      <FeedArticleCondensedTableRowMeta>
        <FeedArticleCondensedTableRowTagList
          maxQuantity={1}
          condensed
          tags={feedArticle.article.articleTypes}
          TagComponent={ArticleTypeTag}
        />
        <FeedArticleCondensedTableRowTagList
          maxQuantity={1}
          condensed
          tags={feedArticle.article.articleTags}
          className="right"
        />
      </FeedArticleCondensedTableRowMeta>
    </FeedArticleCondensedTableRowWrapper>
  );
};
