import { useTranslation } from 'react-i18next';
import {
  ReactSelectOption,
  SpinningLoadingIcon,
  useCloseSelectOnScroll,
  useToggle,
} from '@fcg-tech/regtech-components';
import { TeamActions } from '@fcg-tech/regtech-types/regeye';
import { FunctionComponent, useCallback, useMemo } from 'react';
import Select from 'react-select';
import CreateSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';
import { useTeamArticleTagActions, useTeamTags } from '../../api/hooks';
import { Tag, TeamArticleMetadata, TeamReadMetadata } from '../../api/schema';
import { MEDIUM_FOCUS_REVALIDATE_INTERVAL } from '../../constants';
import { MessageKeys } from '../../translations/translationTypes';
import { reactSelectOptionSorter } from '../../utils/sorters';
import { useAccessControl } from '../AccessControl';
import { teamMetadataSelectStyles } from '../selectStyles';
import { ArticleTeamTag } from '../Tag';
import { TagList } from '../TagList';
import { Tooltip } from '../Tooltip';
import {
  FeedArticleTeamMetadataContentAddTagSelectWrapper,
  FeedArticleTeamMetadataContentSectionRow,
  FeedArticleTeamMetadataContentSectionRowLabel,
  FeedArticleTeamMetadataContentSectionRowWidget,
  FeedArticleTeamMetadataContentSelectWrapper,
  FeedArticleTeamMetadataContentTags,
  FeedArticleTeamMetadataTeamTagLongPlaceholder,
} from './FeedArticleTeamMetadata.styles';

interface FeedArticleTeamMetadataTagsProps {
  articleId: string;
  metadata: TeamArticleMetadata;
  teamReadMetadata: TeamReadMetadata;
  isTeamActionsView?: boolean;
  disabled?: boolean;
  contextTeamId?: string;
  portalRoot?: HTMLElement;
  onTeamTagClick?: (tagId: string, teamId: string) => void;
  onMutated?: () => void;
}

export const FeedArticleTeamMetadataTags: FunctionComponent<
  FeedArticleTeamMetadataTagsProps
> = ({
  articleId,
  metadata,
  teamReadMetadata,
  isTeamActionsView,
  disabled,
  contextTeamId,
  portalRoot,
  onTeamTagClick,
  onMutated,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isAddingTag, toggleIsAddingTag] = useToggle(false);

  const { createArticleTag, addArticleTag, removeArticleTag } =
    useTeamArticleTagActions(teamReadMetadata.team.id, articleId);

  const { data: tags } = useTeamTags(teamReadMetadata.team.id, {
    focusThrottleInterval: MEDIUM_FOCUS_REVALIDATE_INTERVAL,
  });

  const allTeamTags = useMemo<Array<ReactSelectOption<Tag>>>(
    () =>
      tags
        .filter(
          (tag) => tag.active && !metadata.tags.find(({ id }) => id === tag.id),
        )
        .map<ReactSelectOption<Tag>>((tag) => ({
          label: tag.name,
          value: tag,
        }))
        .sort(reactSelectOptionSorter),
    [metadata.tags, tags],
  );

  const handleTagSelected = useCallback(
    async (option: ReactSelectOption<Tag>) => {
      toggleIsAddingTag(true);
      await addArticleTag(option.value.id);
      toggleIsAddingTag(false);
      onMutated();
    },
    [addArticleTag, onMutated, toggleIsAddingTag],
  );

  const handleTagCreated = useCallback(
    async (tagName: string) => {
      await createArticleTag(tagName);
      onMutated();
    },
    [createArticleTag, onMutated],
  );

  const handleTagRemove = useCallback(
    async (tagId: string) => {
      await removeArticleTag(tagId);
      onMutated();
    },
    [onMutated, removeArticleTag],
  );

  const handleTeamTagClick = useCallback(
    (tagId: string) => onTeamTagClick?.(tagId, teamReadMetadata.team.id),
    [onTeamTagClick, teamReadMetadata.team.id],
  );

  const isValidNewTeamTagOption = useCallback(
    (value: string) => {
      const valueLower = value.toLocaleLowerCase().trim();

      return (
        value.length &&
        !tags.find((tag) => tag.name.toLocaleLowerCase().trim() === valueLower)
      );
    },
    [tags],
  );

  const getNoOptionMessage = useCallback(
    (obj: { inputValue: string }) => {
      if (obj.inputValue.length === 0 && tags.length === 0) {
        return (
          <FeedArticleTeamMetadataTeamTagLongPlaceholder>
            {t(MessageKeys.TagSelectorNoTagsFoundTypeToCreate)}
          </FeedArticleTeamMetadataTeamTagLongPlaceholder>
        );
      }
      return t(
        isValidNewTeamTagOption(obj.inputValue)
          ? MessageKeys.LabelNoTagsFound
          : MessageKeys.ArticleMetadataCreateTeamTagConflict,
      );
    },
    [isValidNewTeamTagOption, t, tags.length],
  );

  const createAllowed = useAccessControl(TeamActions.TagCreate);
  const changeAllowed = useAccessControl(TeamActions.ArticleTagChange);

  const [selectRef, containerRef] = useCloseSelectOnScroll<HTMLDivElement>();

  return (
    <FeedArticleTeamMetadataContentSectionRow ref={containerRef}>
      <FeedArticleTeamMetadataContentSectionRowLabel data-testid="team-metadata-tags-label">
        {t(MessageKeys.ArticleMetadataTeamTags)}
      </FeedArticleTeamMetadataContentSectionRowLabel>
      <FeedArticleTeamMetadataContentSectionRowWidget>
        {metadata.tags.length || isAddingTag ? (
          <Tooltip
            priority={0}
            content={t(
              teamReadMetadata.team.id === contextTeamId && isTeamActionsView
                ? MessageKeys.ArticleMetadataTeamTagsAddToFilter
                : MessageKeys.ArticleMetadataTeamTagsClickWillNavigate,
              { name: teamReadMetadata.team.name },
            )}
          >
            <FeedArticleTeamMetadataContentTags>
              <TagList
                tags={metadata.tags}
                onRemove={!disabled && changeAllowed ? handleTagRemove : null}
                onClick={onTeamTagClick ? handleTeamTagClick : null}
                removeTooltip={t(MessageKeys.LabelRemoveTeamTag)}
                TagComponent={ArticleTeamTag}
              />
              {isAddingTag ? <SpinningLoadingIcon size="20" /> : null}
            </FeedArticleTeamMetadataContentTags>
          </Tooltip>
        ) : null}
        <FeedArticleTeamMetadataContentSelectWrapper>
          <FeedArticleTeamMetadataContentAddTagSelectWrapper>
            {createAllowed && changeAllowed ? (
              <CreateSelect
                ref={selectRef}
                options={allTeamTags}
                menuPortalTarget={portalRoot}
                value={null}
                placeholder={t(
                  MessageKeys.TagSelectorTypeToSelectOrCreateTagPlaceholder,
                )}
                isDisabled={disabled}
                isValidNewOption={isValidNewTeamTagOption}
                onChange={handleTagSelected}
                onCreateOption={handleTagCreated}
                formatCreateLabel={(tag: string) =>
                  t(MessageKeys.TagSelectorCreateOptionText, {
                    tag,
                  })
                }
                styles={teamMetadataSelectStyles(theme)}
                noOptionsMessage={getNoOptionMessage}
                closeMenuOnScroll
              />
            ) : null}
            {!createAllowed || (createAllowed && !changeAllowed) ? (
              <Select
                options={allTeamTags}
                menuPortalTarget={portalRoot}
                value={null}
                placeholder={t(
                  changeAllowed
                    ? MessageKeys.TagSelectorTypeToSelectTagPlaceholder
                    : MessageKeys.AccessErrorAddTeamTag,
                )}
                isDisabled={disabled || !changeAllowed}
                onChange={handleTagSelected}
                styles={teamMetadataSelectStyles(theme)}
                noOptionsMessage={getNoOptionMessage}
              />
            ) : null}
          </FeedArticleTeamMetadataContentAddTagSelectWrapper>
        </FeedArticleTeamMetadataContentSelectWrapper>
      </FeedArticleTeamMetadataContentSectionRowWidget>
    </FeedArticleTeamMetadataContentSectionRow>
  );
};
