import { createGlobalStyle, css } from 'styled-components';

export const RegeyeGlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    * {
      letter-spacing: 0.005em;
    }

    body {
      font-family: 'Roboto', sans-serif;
    }

    svg {
      user-select: none;
    }

    p {
      line-height: 150%;
    }

    #root {
      background-color: #ddd;
    }

    .full-height {
      height: 100%;
    }

    .success-color {
      color: ${theme?.colors?.success?.main ?? 'limegreen'};
    }

    .error-color {
      color: ${theme?.colors?.error?.main ?? 'crimson'};
    }

    .warning-color {
      color: ${theme?.colors?.warning?.main ?? 'orange'};
    }

    .showcase-narrow-list {
      margin: 0;
      padding: 0 0 0 2ch;
    }

    .showcase-narrow-list > li {
      margin: 0.4rem;
    }

    .margin-bottom {
      margin-bottom: 1.6rem;
    }

    .row-item-margin-left {
      margin-left: 1rem;
    }
  `};
  `;
