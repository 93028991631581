import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routeFragments } from '../../routes';
import { ActivityLogArticlesPage } from '../ActivityLogArticles';
import { CompanyAdministrationPage } from '../CompanyAdministrationPage';
import { CompanyAdministrationDetails } from '../CompanyAdministrationPage/CompanyAdministrationDetails';
import { CompanyAdministrationPageOutlet } from '../CompanyAdministrationPage/CompanyAdministrationPageOutlet';
import { ActionsArticlesPage } from '../Insights/ActionsArticlesPage';
import { CompanyOutlet } from './CompanyOutlet';

export const CompanyBaseContainer: FunctionComponent = () => {
  return (
    <ErrorBoundary>
      <SuspenseLoader>
        <Routes>
          <Route element={<CompanyOutlet />} path="/">
            <Route
              path={routeFragments.companyActions}
              element={<ActionsArticlesPage />}
            />
            <Route
              path={routeFragments.companyActionsLog}
              element={<ActivityLogArticlesPage />}
            />
            <Route
              path={routeFragments.companyAdministrationCatchAll}
              element={<CompanyAdministrationPageOutlet />}
            >
              <Route
                path={routeFragments.companyAdministrationDetails}
                element={<CompanyAdministrationDetails />}
              />
              <Route
                path={routeFragments.teamBase}
                element={<CompanyAdministrationPage />}
              />
            </Route>
          </Route>
        </Routes>
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
