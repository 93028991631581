import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { routeFragments, routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import {
  FilterDraftRestoredNotificationLinkWrapper,
  FilterDraftRestoredNotificationWrapper,
} from './FilterDraftRestoredNotification.styles';

interface FilterDraftRestoredNotificationProps {
  onDoNotShowAgainClick: () => void;
}

export const FilterDraftRestoredNotification: FunctionComponent<
  FilterDraftRestoredNotificationProps
> = ({ onDoNotShowAgainClick }) => {
  const { t } = useTranslation();
  return (
    <FilterDraftRestoredNotificationWrapper>
      {t(MessageKeys.FeedArticleFilterDraftRestoredNotification)}
      <FilterDraftRestoredNotificationLinkWrapper>
        <NavLink
          to={constructUrl(routes.personalProfileSetting, {
            settingKey: routeFragments.personalProfileAdvancedSettings,
          })}
        >
          {t(MessageKeys.FeedArticleFilterDraftRestoredChangeThisBehaviour)}
        </NavLink>
      </FilterDraftRestoredNotificationLinkWrapper>
      <FilterDraftRestoredNotificationLinkWrapper
        role="button"
        onClick={onDoNotShowAgainClick}
      >
        {t(MessageKeys.LabelDoNotShowThisAgain)}
      </FilterDraftRestoredNotificationLinkWrapper>
    </FilterDraftRestoredNotificationWrapper>
  );
};
