import memoizee from 'memoizee';
import { CompanyActions, GlobalActions, TeamActions } from '../api/schema';
import { IamContextProps } from '../components/IamContext';

export const canViewPageCompaniesAdministration = memoizee(
  (iamContext: IamContextProps) => {
    return iamContext?.globalPermissions?.includes(
      GlobalActions.ViewPageCompaniesAdministration,
    );
  },
);

export const canViewCompanyAdministration = memoizee(
  (companyId: string, iamContext: IamContextProps) => {
    return iamContext?.companyPermissions[companyId]?.includes(
      CompanyActions.ViewPageCompanyAdministration,
    );
  },
);

export const canViewPageTeamsAdministration = memoizee(
  (teamId: string, iamContext: IamContextProps) => {
    return iamContext?.teamPermissions?.[teamId]?.includes(
      TeamActions.ViewPageTeamsAdministration,
    );
  },
);

export const canGetCompanyActionsArticles = memoizee(
  (companyId: string, iamContext: IamContextProps) => {
    return iamContext?.companyPermissions[companyId]?.includes(
      CompanyActions.GetCompanyActionsArticles,
    );
  },
);

export const canGetCompanyActivityLogArticles = memoizee(
  (companyId: string, iamContext: IamContextProps) => {
    return iamContext?.companyPermissions[companyId]?.includes(
      CompanyActions.GetCompanyActivityLogArticles,
    );
  },
);

export const canCompanyCreate = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.CompanyCreate);
});

export const canCompanyList = memoizee((iamContext: IamContextProps) => {
  return iamContext?.globalPermissions?.includes(GlobalActions.CompanyList);
});

export const canCompanyTeamList = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyTeamList,
    ),
);
export const canCompanyTeamEdit = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyTeamEdit,
    ),
);

export const canCompanyTeamDelete = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyTeamDelete,
    ),
);

export const canTeamMemberList = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(TeamActions.MemberList),
);

export const canTeamInvitationCreate = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(
      TeamActions.TeamInvitationCreate,
    ),
);

export const canCompanyTeamInvitationCreate = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyTeamInvitationCreate,
    ),
);

export const canTeamInvitationDelete = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(
      TeamActions.TeamInvitationDelete,
    ),
);

export const canCompanyTeamInvitationDelete = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyTeamInvitationDelete,
    ),
);

export const canTeamMemberDelete = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(TeamActions.MemberDelete),
);

export const canCompanyTeamMemberDelete = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyTeamMemberDelete,
    ),
);

export const canTeamSubscriptionUpdate = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(
      TeamActions.TeamSubscriptionUpdate,
    ),
);

export const canTeamSubscriptionList = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(
      TeamActions.TeamSubscriptionList,
    ),
);

export const canGetTeamFilters = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(TeamActions.GetTeamFilters),
);

export const canDeleteTeamFilter = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(
      TeamActions.DeleteTeamFilter,
    ),
);

export const canTeamTagList = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(TeamActions.TagList),
);

export const canTeamTagCreate = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(TeamActions.TagCreate),
);

export const canTeamTagEdit = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(TeamActions.TagEdit),
);

export const canTeamTagDelete = memoizee(
  (teamId: string, iamContext: IamContextProps) =>
    iamContext?.teamPermissions?.[teamId]?.includes(TeamActions.TagDelete),
);

export const canCompanyDelete = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyDelete,
    ),
);

export const canCompanyEdit = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyEdit,
    ),
);

export const canCompanyElevatedEdit = memoizee(
  (companyId: string, iamContext: IamContextProps) =>
    iamContext?.companyPermissions?.[companyId]?.includes(
      CompanyActions.CompanyElevatedEdit,
    ),
);
