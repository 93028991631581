import { getEmptyMarkup } from '@fcg-tech/regtech-richtext';
import { useLocalStorage } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  STORED_FILTER_DRAFTS_KEY,
  STORED_RICH_TEXT_DRAFTS_KEY,
} from '../constants';
import {
  BaseDraftIdent,
  BaseRichTextDraft,
  DraftStorage,
  FilterDraft,
  FilterDraftIdent,
} from '../types';

const compareIdent = <T extends Record<string, string> | string>(
  base: T,
  full: T,
) => {
  if (typeof base === 'string' && typeof full === 'string') {
    return base === full;
  }
  if (typeof base === 'object' && typeof full === 'object') {
    const keys = Object.keys(base);
    for (let i = 0; i < keys.length; i++) {
      if (base[keys[i]] !== full[keys[i]]) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const useRichTextDrafts = <
  T extends BaseDraftIdent,
  D extends BaseRichTextDraft<T>,
>(
  baseIdent: T,
): {
  drafts: Array<D>;
  updateDraft: (ident: T, props: Partial<D>) => void;
  deleteDraft: (ident: T) => void;
} => {
  const [draftStorage, updateDrafts] = useLocalStorage<DraftStorage<T, D>>(
    STORED_RICH_TEXT_DRAFTS_KEY,
  );

  const updateDraftsRef = useRef(updateDrafts);
  const draftStorageRef = useRef(draftStorage);

  const drafts = useMemo(
    () =>
      draftStorage?.drafts
        ?.filter((d) => compareIdent(baseIdent, d.id))
        .map<D>(
          (d) =>
            ({
              ...d,
              updated: d.updated ? new Date(d.updated) : null,
            } as D),
        ),
    [baseIdent, draftStorage?.drafts],
  );

  const updateDraft = useCallback((ident: T, props: D) => {
    updateDraftsRef.current?.((oldDraftStorage) => {
      const index = oldDraftStorage?.drafts?.findIndex((d) =>
        compareIdent(ident, d.id),
      );

      let draft = oldDraftStorage?.drafts?.[index] ?? {
        ...props,
        markup: getEmptyMarkup(),
        id: ident,
      };

      draft = update(draft as BaseRichTextDraft<T>, {
        updated: { $set: new Date() },
      }) as D;

      draft = update(draft as BaseRichTextDraft<T>, {
        $merge: props,
      }) as D;

      return update(oldDraftStorage ?? { drafts: [] }, {
        drafts: index >= 0 ? { [index]: { $set: draft } } : { $push: [draft] },
      });
    });
  }, []);

  const deleteDraft = useCallback((ident: T) => {
    updateDraftsRef.current?.((oldDraftStorage) => {
      const index = oldDraftStorage?.drafts?.findIndex((d) =>
        compareIdent(ident, d.id),
      );
      if (index >= 0) {
        return update(oldDraftStorage, { drafts: { $splice: [[index, 1]] } });
      }
      return oldDraftStorage;
    });
  }, []);

  const deleteDraftRef = useRef(deleteDraft);

  // Clean drafts
  useEffect(() => {
    () => {
      draftStorageRef.current?.drafts?.forEach((draft) => {
        if (draft.content === '') {
          deleteDraftRef.current(draft.id);
        }
      });
    };
  }, []);

  return { drafts, updateDraft, deleteDraft };
};

export const useFilterDrafts = (
  baseIdent: FilterDraftIdent,
): {
  drafts: Array<FilterDraft>;
  updateDraft: (ident: FilterDraftIdent, props: Partial<FilterDraft>) => void;
  deleteDraft: (ident: FilterDraftIdent) => void;
} => {
  const [draftStorage, updateDrafts] = useLocalStorage<
    DraftStorage<FilterDraftIdent, FilterDraft>
  >(STORED_FILTER_DRAFTS_KEY);

  const drafts = useMemo(
    () =>
      draftStorage?.drafts
        ?.filter((d) => compareIdent(baseIdent, d.id))
        .map((d) => ({
          ...d,
          updated: d.updated ? new Date(d.updated) : null,
        })),
    [baseIdent, draftStorage?.drafts],
  );

  const updateDraft = useCallback(
    (ident: FilterDraftIdent, props: Partial<FilterDraft>) => {
      updateDrafts((oldDraftStorage) => {
        const index = oldDraftStorage?.drafts?.findIndex((d) =>
          compareIdent(ident, d.id),
        );

        let draft =
          oldDraftStorage?.drafts?.[index] ??
          ({
            stringifiedFilter: '',
            ...props,
            id: ident,
          } as FilterDraft);

        draft = update(draft, {
          updated: { $set: new Date() },
        });

        draft = update(draft, {
          $merge: props,
        });

        return update(
          oldDraftStorage ??
            ({ drafts: [] } as DraftStorage<FilterDraftIdent, FilterDraft>),
          {
            drafts:
              index >= 0 ? { [index]: { $set: draft } } : { $push: [draft] },
          },
        );
      });
    },
    [updateDrafts],
  );

  const deleteDraft = useCallback(
    (ident: FilterDraftIdent) => {
      updateDrafts((oldDraftStorage) => {
        const index = oldDraftStorage?.drafts?.findIndex((d) =>
          compareIdent(ident, d.id),
        );
        if (index >= 0) {
          return update(oldDraftStorage, { drafts: { $splice: [[index, 1]] } });
        }
        return oldDraftStorage;
      });
    },
    [updateDrafts],
  );

  return {
    drafts: baseIdent.filterType === 'search' ? [] : drafts,
    updateDraft,
    deleteDraft,
  };
};
