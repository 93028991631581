import {
  SortIcon,
  useClickOutside,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  DataTableColumn,
  DataTableSortPicker,
  SortingRule,
} from '@fcg-tech/regtech-datatable';
import { FilterBarButton } from '@fcg-tech/regtech-filter';
import { useEscapeKey } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterBarSortSelectorWrapper } from '.';
import { MessageKeys } from '../../translations/translationTypes';
import { FeedArticle } from '../../types';
import { getColumnLabel } from '../FeedArticleTable/feedArticleTableHelpers';
import { FeedArticleTableProps } from '../FeedArticleTable';

type FilterBarSortSelectorProps = Pick<
  FeedArticleTableProps,
  'columns' | 'onSortByChange' | 'sortBy'
>;

export const FilterBarSortSelector: FunctionComponent<
  FilterBarSortSelectorProps
> = ({ columns, sortBy, onSortByChange }) => {
  const { t } = useTranslation();

  const [showSelector, setShowSelector, toggleShowSelector] = useToggle();

  const closeSelector = useCallback(
    () => setShowSelector(false),
    [setShowSelector],
  );

  const selectorRef = useRef<HTMLDivElement>();

  useClickOutside(selectorRef, closeSelector);
  useEscapeKey({ callback: closeSelector });

  const handleChange = useCallback(
    (sortBy: SortingRule<FeedArticle>) => {
      onSortByChange?.(sortBy);
      setShowSelector(false);
    },
    [onSortByChange, setShowSelector],
  );

  const wrappedGetColumnLabel = useCallback(
    (column: DataTableColumn<FeedArticle>) => getColumnLabel(column, t),
    [t],
  );

  return (
    <FilterBarSortSelectorWrapper>
      <FilterBarButton onClick={toggleShowSelector}>
        <SortIcon size="18" />
      </FilterBarButton>
      {showSelector ? (
        <DataTableSortPicker
          ref={selectorRef}
          columns={columns}
          getColumnLabel={wrappedGetColumnLabel}
          sortBy={sortBy}
          ascendingLabel={t(MessageKeys.LabelAscending)}
          descendingLabel={t(MessageKeys.LabelDescending)}
          submitLabel={t(MessageKeys.LabelSubmit)}
          cancelLabel={t(MessageKeys.LabelCancel)}
          heading={t(MessageKeys.FeedArticleFilterSortOrderSelectorHeading)}
          onChange={handleChange}
          onRequestClose={toggleShowSelector}
        />
      ) : null}
    </FilterBarSortSelectorWrapper>
  );
};
