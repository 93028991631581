import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent } from 'react';
import { CircleExclamationIcon, SpinningLoadingIcon } from '../Icons';
import { Checkmark } from '../Table/TableColumnPicker.styles';
import {
  ProgressItemIconWrapper,
  ProgressItemText,
  ProgressItemWrapper,
} from './ProgressList.styles';
import { ProgressStep } from './progressTypes';

interface ProgressItemProps {
  step: ProgressStep;
}

export const ProgressItem: FunctionComponent<ProgressItemProps> = ({
  step,
}) => {
  return (
    <ProgressItemWrapper
      className={classNames(
        step?.started && 'started',
        step?.finished && 'finished',
        step?.failed && 'failed',
      )}
    >
      <ProgressItemText>{step?.text}</ProgressItemText>
      <ProgressItemIconWrapper>
        {step?.started && !step?.finished && !step?.failed ? (
          <SpinningLoadingIcon />
        ) : null}
        {step?.started && step?.finished && !step?.failed ? (
          <Checkmark color="currentColor" />
        ) : null}
        {step?.failed ? <CircleExclamationIcon color="currentColor" /> : null}
      </ProgressItemIconWrapper>
    </ProgressItemWrapper>
  );
};
