import React from 'react';
import { IconProps } from '../../types/icons';

export const SearchPlusIcon = ({
  size = '24',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="search-plus-icon_svg__feather search--lus-icon_svg__feather-search-plus"
    {...props}
  >
    <path d="M13.0735 2.90331C9.49149 2.90331 6.5877 5.8071 6.5877 9.3891C6.5877 12.9711 9.49149 15.8749 13.0735 15.8749C16.6555 15.8749 19.5593 12.9711 19.5593 9.3891C19.5593 5.8071 16.6555 2.90331 13.0735 2.90331ZM4.73462 9.3891C4.73462 4.78367 8.46806 1.05023 13.0735 1.05023C17.6789 1.05023 21.4124 4.78367 21.4124 9.3891C21.4124 13.9945 17.6789 17.728 13.0735 17.728C8.46806 17.728 4.73462 13.9945 4.73462 9.3891Z" />
    <path d="M17.6533 13.9689C18.0151 13.6071 18.6018 13.6071 18.9636 13.9689L22.9941 17.9993C23.3559 18.3612 23.3559 18.9478 22.9941 19.3097C22.6322 19.6715 22.0456 19.6715 21.6837 19.3097L17.6533 15.2792C17.2914 14.9174 17.2914 14.3307 17.6533 13.9689Z" />
    <path d="M4.73462 14.9498C5.2869 14.9498 5.73462 15.3975 5.73462 15.9498V21.9498C5.73462 22.5021 5.2869 22.9498 4.73462 22.9498C4.18233 22.9498 3.73462 22.5021 3.73462 21.9498V15.9498C3.73462 15.3975 4.18233 14.9498 4.73462 14.9498Z" />
    <path d="M0.734619 18.9498C0.734619 18.3975 1.18233 17.9498 1.73462 17.9498H7.73462C8.2869 17.9498 8.73462 18.3975 8.73462 18.9498C8.73462 19.5021 8.2869 19.9498 7.73462 19.9498H1.73462C1.18233 19.9498 0.734619 19.5021 0.734619 18.9498Z" />
  </svg>
);
