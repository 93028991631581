import {
  CompanyIcon,
  FormRow,
  H2,
  Label,
  TextField,
} from '@fcg-tech/regtech-components';
import { Radio } from 'reakit';
import styled from 'styled-components';

export const MyProfileForm = styled.form`
  width: 40rem;
`;

export const MyProfileFormRowLabel = styled(Label)`
  width: 28rem !important;
  margin-right: 2rem;
  flex: initial;
  &.wide {
    width: 36rem !important;
  }
`;

export const MyProfileSection = styled.section`
  margin-top: 2rem;
  & + & {
    padding-top: 2rem;
    border-top: 1px solid #ccc;
  }

  &.side-padding {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  & ${MyProfileFormRowLabel} {
    width: 36rem !important;
  }

  & ${FormRow} .toggle-switch {
    position: relative;
    top: 0.5rem;
  }
`;

export const MyProfileTextField = styled(TextField)`
  max-width: 40rem;
`;

export const MyProfileFormRowInputWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 3.8rem;
  & + & {
    margin-left: 2.6rem;
  }

  > * + * {
    margin-left: 1.6rem;
  }
`;

export const MyProfileFormRowRadioInputs = styled.div`
  display: flex;
  align-items: center;
`;

export const MyProfileFormRowInputLabel = styled(Label)`
  margin-top: 0;
  margin-left: 1rem;
  align-items: center;
`;

export const MyProfileFormRowInputRadio = styled(Radio)`
  margin: 0;
  padding: 0;
`;

export const MyProfileTeamFiltersInfo = styled.p`
  font-size: 1.4rem;
  width: 100%;
  text-align: center;
  font-weight: normal;
`;

export const MyProfileTableColumnSelectorWrapper = styled.div`
  padding-top: 1rem;
`;

export const MyProfileHideTeamInfo = styled.div`
  margin: 0 1.6rem;
`;

export const MyProfileCompanyWrapper = styled.div`
  & + & {
    padding-top: 1.6rem;
    box-shadow: 0 -4px 6px 1px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ccc;
    margin-top: 4.6rem;
  }
`;

export const MyProfileCompanyNameRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem;
`;

export const MyProfileCompanyNameRowHeader = styled(H2)`
  margin: 0;
`;

export const MyProfileTeamsAndInvitationsHeading = styled.h4`
  margin: 1.6rem 0 1rem 0;
  padding-left: 1.6rem;
`;

export const MyProfileCompanyNameRowCompanyIcon = styled(CompanyIcon)`
  margin-right: 0.2rem;
  position: relative;
  top: 0.1rem;
`;
