import {
  Checkbox,
  FormButtonRow,
  FormRow,
  PrimaryButton,
  useFlash,
  useInput,
} from '@fcg-tech/regtech-components';
import update from 'immutability-helper';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { Prompt } from 'react-router-dom';
import { RadioGroup, useRadioState } from 'reakit';
import {
  useCurrentUser,
  useCurrentUserActions,
  useSettings,
  useSettingsActions,
} from '../../api/hooks';
import { MessageKeys } from '../../translations/translationTypes';
import { NameOrder } from '../../types';
import {
  MyProfileFormRowInputLabel,
  MyProfileFormRowInputRadio,
  MyProfileFormRowInputWrapper,
  MyProfileFormRowLabel,
  MyProfileFormRowRadioInputs,
  MyProfileSection,
  MyProfileTextField,
} from './MyProfilePage.styles';

export const MyProfileDisplaySettingsTab: FunctionComponent = () => {
  const { t } = useTranslation();
  const { updateSettings } = useSettingsActions();
  const { data: settings } = useSettings();
  const { data: user } = useCurrentUser();
  const { updateUserAlias } = useCurrentUserActions();

  const addFlash = useFlash();
  const isDirty = useRef(false);
  const [editSettings, setSettings] = useState(settings);
  const [saving, setSaving] = useState(false);

  const handleUserNamePreferenceChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as NameOrder;
      const checked = event.target.checked;

      if (checked) {
        isDirty.current = true;
        setSettings((old) =>
          update(old, { userNamePreference: { $set: value } }),
        );
      }
    },
    [],
  );

  const [alias, handleAliasChange] = useInput(user?.alias ?? '');
  const aliasRef = useRef<HTMLInputElement>();

  const handleAliasKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      isDirty.current = true;
      if (event.key === 'Enter') {
        aliasRef.current.blur();
      }
    },
    [],
  );

  const handleMarkAsReadToggle = useCallback((checked: boolean) => {
    isDirty.current = true;
    setSettings((old) => {
      return {
        ...old,
        markReadAfterMs: checked ? 5000 : -1,
      };
    });
  }, []);

  const [markAsRead, setMarkAsRead] = useState({
    error: false,
    value: editSettings.markReadAfterMs
      ? (editSettings.markReadAfterMs < 0
          ? 5
          : editSettings.markReadAfterMs / 1000
        ).toString()
      : '0',
  });

  const handleMarkAsReadAfterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value ?? '0');
      isDirty.current = true;
      if (Number.isNaN(value)) {
        setMarkAsRead({
          error: true,
          value: event.target.value,
        });
      } else {
        setMarkAsRead({
          error: false,
          value: value.toString(),
        });
        setSettings((old) => ({
          ...old,
          markReadAfterMs: value * 1000,
        }));
      }
    },
    [],
  );

  const handleSubmitDisplaySettings = useCallback(async () => {
    if (alias.trim().length === 0) return;
    setSaving(true);
    try {
      await Promise.all([updateSettings(editSettings), updateUserAlias(alias)]);
      isDirty.current = false;
      addFlash({
        level: 'success',
        content: t(MessageKeys.LabelChangesSaved),
      });
    } catch (e) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.LabelCouldNotSave),
      });
    }
    setSaving(false);
  }, [addFlash, alias, editSettings, t, updateSettings, updateUserAlias]);

  const userNamePreferenceState = useRadioState({
    state: editSettings?.userNamePreference,
  });

  return (
    <>
      {/* {<Prompt
      when={isDirty.current}
      message={t.translate(MessageKeys.LabelChangesMadeNavigationWarning)}
    />} */}
      <MyProfileSection className="side-padding">
        <div>
          <FormRow>
            <MyProfileFormRowLabel>
              {t(MessageKeys.MyProfileTabMySettingsAlias)}
            </MyProfileFormRowLabel>
            <MyProfileTextField
              value={alias}
              ref={aliasRef}
              onChange={handleAliasChange}
              onKeyDown={handleAliasKeyDown}
            />
          </FormRow>
          <FormRow>
            <MyProfileFormRowLabel>
              {t(MessageKeys.MyProfileTabMySettingsNamePreference)}
            </MyProfileFormRowLabel>
            <RadioGroup
              {...userNamePreferenceState}
              aria-label={t(MessageKeys.MyProfileTabMySettingsNamePreference)}
            >
              <MyProfileFormRowRadioInputs>
                <MyProfileFormRowInputWrapper>
                  <MyProfileFormRowInputRadio
                    {...userNamePreferenceState}
                    id={NameOrder.Alias}
                    value={NameOrder.Alias}
                    onChange={handleUserNamePreferenceChange}
                  />
                  <MyProfileFormRowInputLabel htmlFor={NameOrder.Alias}>
                    {t(MessageKeys.MyProfileTabMySettingsNamePreferenceAlias)}
                  </MyProfileFormRowInputLabel>
                </MyProfileFormRowInputWrapper>
                <MyProfileFormRowInputWrapper>
                  <MyProfileFormRowInputRadio
                    {...userNamePreferenceState}
                    id={NameOrder.Email}
                    value={NameOrder.Email}
                    onChange={handleUserNamePreferenceChange}
                  />
                  <MyProfileFormRowInputLabel htmlFor={NameOrder.Email}>
                    {t(MessageKeys.MyProfileTabMySettingsNamePreferenceEmail)}
                  </MyProfileFormRowInputLabel>
                </MyProfileFormRowInputWrapper>
              </MyProfileFormRowRadioInputs>
            </RadioGroup>
          </FormRow>
          <FormRow>
            <MyProfileFormRowLabel>
              {t(MessageKeys.MyProfileTabMySettingsMarkAsRead)}
            </MyProfileFormRowLabel>
            <MyProfileFormRowInputWrapper>
              <Checkbox
                checked={editSettings.markReadAfterMs >= 0}
                onChange={handleMarkAsReadToggle}
              />
              {editSettings.markReadAfterMs >= 0 ? (
                <>
                  <span>
                    {t(
                      MessageKeys.MyProfileTabMySettingsMarkAsReadAfterSeconds,
                    )}
                  </span>
                  <MyProfileTextField
                    value={markAsRead.value}
                    error={markAsRead.error}
                    ref={aliasRef}
                    disabled={editSettings.markReadAfterMs < 0}
                    onChange={handleMarkAsReadAfterChange}
                  />
                </>
              ) : null}
            </MyProfileFormRowInputWrapper>
          </FormRow>

          <FormButtonRow className="sticky--bottom">
            <PrimaryButton
              loading={saving}
              disabled={
                markAsRead?.error ||
                alias.trim().length === 0 ||
                !isDirty.current
              }
              onClick={handleSubmitDisplaySettings}
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </FormButtonRow>
        </div>
      </MyProfileSection>
    </>
  );
};
