import { BooleanTreeItem } from '@fcg-tech/regtech-components';
import { Publisher } from '@fcg-tech/regtech-types/regeye';
import { Spec } from 'immutability-helper';

export enum SubscriptionEnumType {
  Region,
  Publisher,
  ArticleType,
}

export type SubscriptionItemValue = {
  id?: string;
  type: SubscriptionEnumType;
};

export type SubscriptionItem = BooleanTreeItem<SubscriptionItemValue>;

export const getUpdateArticleTypeSelectionInstructions = (
  publisher: Publisher,
  subscribed: boolean,
): Spec<Publisher> => {
  const instructions: Spec<Publisher> = {
    subscribedToEntirePublisher: { $set: subscribed },
    articleTypes: {},
  };

  publisher.articleTypes.forEach((_, index) => {
    instructions.articleTypes[index] = { subscribed: { $set: subscribed } };
  });

  return instructions;
};

export const getUpdateRegionSelectionInstructions = (
  items: Array<Publisher>,
  regionId: string,
  selected: boolean,
): Spec<Array<Publisher>> => {
  const instructions: Spec<Array<Publisher>> = {};

  items.forEach((publisher, index) => {
    if (publisher.region.id === regionId) {
      instructions[index] = getUpdateArticleTypeSelectionInstructions(
        publisher,
        selected,
      );
    }
  });

  return instructions;
};

export type PublisherDiff = {
  id: string;
  name: string;
  addedArticleTypes: number;
  removedArticleTypes: number;
};

export const getPublishersDiff = (
  oldValues: Array<Publisher>,
  newValues: Array<Publisher>,
) => {
  const diffs: Array<PublisherDiff> = [];
  const newPubMap = newValues.reduce<Record<string, Publisher>>((c, pub) => {
    c[pub.id] = pub;
    return c;
  }, {});

  oldValues.forEach((oldPub) => {
    const newPub = newPubMap[oldPub.id];
    if (newPub) {
      const addedArticleTypes = oldPub.articleTypes.filter(
        ({ subscribed, id }) =>
          !subscribed &&
          newPub.articleTypes?.find((t) => t.id === id)?.subscribed,
      ).length;

      const removedArticleTypes = oldPub.articleTypes.filter(
        ({ subscribed, id }) =>
          subscribed &&
          newPub.articleTypes?.find((t) => t.id === id)?.subscribed === false,
      ).length;

      if (
        addedArticleTypes !== 0 ||
        removedArticleTypes !== 0 ||
        oldPub.subscribedToEntirePublisher !==
          newPub.subscribedToEntirePublisher
      ) {
        diffs.push({
          id: newPub.id,
          name:
            (newPub.name.length > 30 ? newPub.abbreviation : newPub.name) ??
            newPub.name,
          addedArticleTypes,
          removedArticleTypes,
        });
      }
    }
  });

  return diffs;
};

export const updateArticleTypesDisabledState = (
  items: Array<SubscriptionItem>,
  publisherId: string | number,
  disabled: boolean,
) => {
  return items.map((item) => {
    if (item.parentId === publisherId && item.value) {
      item.disabled = disabled;
    }
    return item;
  });
};
