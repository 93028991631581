import { useTranslation } from 'react-i18next';
import {
  AlertBox,
  Calendar,
  CalendarEventData,
  CalendarEventType,
  SpinningLoadingIcon,
  useToggle,
} from '@fcg-tech/regtech-components';
import { ArrowDirection } from '@fcg-tech/regtech-types';
import {
  FeedArticle,
  FeedArticleDomain,
  FeedArticleListMutator,
  Importance,
  PageDirection,
} from '@fcg-tech/regtech-types/regeye';
import { useArrowKeys } from '@fcg-tech/regtech-utils';
import { addMonths, format, isSameDay } from 'date-fns';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import { useSettings } from '../../api/hooks';
import { FeedArticleOverlay } from '../../components/FeedArticleOverlay';
import { useFeedArticleActionHandlers } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { TeamContextProvider } from '../../components/TeamContext/TeamContext';
import { IMPORTANCE_COLOR } from '../../constants';
import { MessageKeys } from '../../translations/translationTypes';
import { getDateFnsLocale } from '../../utils/dateUtils';
import {
  ActionsArticleDueDateCalendarEvent,
  ActionsArticleDueDateCalendarEventData,
} from './ActionsArticleDueDateCalendarEvent';
import {
  ActionsArticlesDueDateCalendarLoadingWrapper,
  ActionsArticlesDueDateCalendarNoArticlesMessage,
  ActionsArticlesDueDateCalendarPageWrapper,
  ActionsArticlesDueDateCalendarWrapper,
} from './ActionsArticlesDueDateCalendar.styles';

const eventTypes: Record<Importance, CalendarEventType> = {
  '0': {
    id: '0',
    backgroundColor: IMPORTANCE_COLOR[0],
    color: 'black',
    component: ActionsArticleDueDateCalendarEvent,
  },

  '1': {
    id: '1',
    backgroundColor: IMPORTANCE_COLOR[1],
    color: 'black',
    component: ActionsArticleDueDateCalendarEvent,
  },
  '2': {
    id: '2',
    backgroundColor: IMPORTANCE_COLOR[2],
    color: 'black',
    component: ActionsArticleDueDateCalendarEvent,
  },
  '3': {
    id: '3',
    backgroundColor: IMPORTANCE_COLOR[3],
    color: 'black',
    component: ActionsArticleDueDateCalendarEvent,
  },
  '4': {
    id: '4',
    backgroundColor: IMPORTANCE_COLOR[4],
    color: 'black',
    component: ActionsArticleDueDateCalendarEvent,
  },
  '5': {
    id: '5',
    backgroundColor: IMPORTANCE_COLOR[5],
    color: 'black',
    component: ActionsArticleDueDateCalendarEvent,
  },
};

const eventSorter = (
  a: CalendarEventData<ActionsArticleDueDateCalendarEventData>,
  b: CalendarEventData<ActionsArticleDueDateCalendarEventData>,
) => {
  if (isSameDay(a.dateTime, b.dateTime)) {
    const importanceDiff = Number(b.eventType) - Number(a.eventType);
    if (importanceDiff !== 0) {
      return importanceDiff;
    }

    if (a.data?.teamName && b.data?.teamName) {
      const teamNameDiff = a.data.teamName.localeCompare(b.data.teamName);
      if (teamNameDiff !== 0) {
        return teamNameDiff;
      }
    }

    return a.label.localeCompare(b.label);
  }
  return a.dateTime < b.dateTime ? -1 : 1;
};

interface ActionsArticlesDueDateCalendarProps {
  feedArticles: Array<FeedArticle>;
  isLoading?: boolean;
  month: Date;
  showAgenda: boolean;
  mutateArticle?: (
    articleId: string,
    { bookmarked, read }: { bookmarked?: boolean; read?: boolean },
  ) => void;
  mutateFeedArticleList?: (
    callback: FeedArticleListMutator,
    shouldRevalidate?: boolean,
  ) => void;
  domain: FeedArticleDomain;
  onMonthChange: (month: Date) => void;
  onToggleShowAgenda: () => void;
  onArticleMetadataMutated?: (articleId: string) => void;
}

export const ActionsArticlesDueDateCalendar: FunctionComponent<PropsWithChildren<ActionsArticlesDueDateCalendarProps>> = ({
  feedArticles,
  isLoading,
  month,
  showAgenda,
  domain,
  mutateArticle,
  mutateFeedArticleList,
  children,
  onMonthChange,
  onToggleShowAgenda,
  onArticleMetadataMutated,
}) => {
  const { t } = useTranslation();

  const { data: settings } = useSettings({ suspense: false });

  const { handleArchiveChange, handleBookmarkChange, handleMarkAsReadChange } =
    useFeedArticleActionHandlers({
      mutateArticle,
      mutateFeedArticleList,
    });

  const calendarEvents = useMemo(
    () =>
      feedArticles
        ?.filter((feedArticle) =>
          Boolean(feedArticle.teamArticleMetadata.dueDate.dueDate),
        )
        .map<CalendarEventData<ActionsArticleDueDateCalendarEventData>>(
          (feedArticle) => {
            return {
              id: feedArticle.article.id,
              key: feedArticle.internalId,
              label: feedArticle.article.title,
              dateTime: feedArticle.teamArticleMetadata.dueDate.dueDate,
              eventType: (
                feedArticle.teamArticleMetadata.importance?.importance ?? 0
              ).toString(),
              data:
                domain === FeedArticleDomain.Company
                  ? {
                      teamName: feedArticle.team.name,
                    }
                  : undefined,
            };
          },
        )
        .sort(eventSorter) ?? [],
    [domain, feedArticles],
  );

  const [expandedKey, setExpanded] = useState<string | null>();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showNoArticlesMessage, setShowNoArticlesMessage] = useToggle(false);

  const autoLoadArticle = useRef<'first' | 'last'>();

  const handleEventClick = useCallback(
    (articleId: string, internalId: string) => {
      setExpanded(internalId);
      setShowOverlay(true);
    },
    [],
  );

  const handleArticleModalClose = useCallback(() => {
    setExpanded(null);
    setShowOverlay(false);
    setShowNoArticlesMessage(false);
    autoLoadArticle.current = undefined;
  }, [setShowNoArticlesMessage]);

  useEffect(() => {
    if (calendarEvents && autoLoadArticle.current === 'first' && !isLoading) {
      const internalId = calendarEvents[0]?.key as string;
      setExpanded(internalId);
      autoLoadArticle.current = internalId
        ? undefined
        : autoLoadArticle.current;
      setShowNoArticlesMessage(!internalId);
    } else if (
      calendarEvents &&
      autoLoadArticle.current === 'last' &&
      !isLoading
    ) {
      const internalId = calendarEvents[calendarEvents.length - 1]
        ?.key as string;
      setExpanded(internalId);
      setShowNoArticlesMessage(!internalId);
      autoLoadArticle.current = internalId
        ? undefined
        : autoLoadArticle.current;
    } else if (isLoading) {
      setShowNoArticlesMessage(false);
    }
  }, [setShowNoArticlesMessage, isLoading, calendarEvents]);

  const [previousArticle, expandedFeedArticle, nextArticle] = useMemo<
    [FeedArticle | null, FeedArticle | null, FeedArticle | null]
  >(() => {
    const expanded = feedArticles?.find((fe) => fe.internalId === expandedKey);
    const index = calendarEvents?.findIndex((e) => e.key === expandedKey);
    const prevKey = index > 0 ? calendarEvents?.[index - 1]?.key : null;
    const nextKey = calendarEvents?.[index + 1]?.key ?? null;

    return [
      prevKey ? feedArticles?.find((fe) => fe.internalId === prevKey) : null,
      expanded ?? null,
      nextKey ? feedArticles?.find((fe) => fe.internalId === nextKey) : null,
    ];
  }, [calendarEvents, expandedKey, feedArticles]);

  const handleChangeArticle = useCallback(
    (articleIdOrPageDirection) => {
      if (articleIdOrPageDirection === PageDirection.Next) {
        autoLoadArticle.current = 'first';
        onMonthChange(addMonths(month, 1));
      } else if (articleIdOrPageDirection === PageDirection.Previous) {
        autoLoadArticle.current = 'last';
        onMonthChange(addMonths(month, -1));
      } else {
        setExpanded(articleIdOrPageDirection);
      }
    },
    [month, onMonthChange],
  );

  const handleArrowKeys = useCallback(
    (key: ArrowDirection) => {
      if (key === 'ArrowLeft') {
        handleChangeArticle(
          previousArticle?.internalId ?? PageDirection.Previous,
        );
      } else if (key === 'ArrowRight') {
        handleChangeArticle(nextArticle?.internalId ?? PageDirection.Next);
      }
    },
    [handleChangeArticle, nextArticle?.internalId, previousArticle?.internalId],
  );

  useArrowKeys({ callback: handleArrowKeys });

  const overlayChildren =
    isLoading || showNoArticlesMessage ? (
      <ActionsArticlesDueDateCalendarNoArticlesMessage>
        {isLoading ? <SpinningLoadingIcon size="80px" /> : null}
        {showNoArticlesMessage
          ? t(
              MessageKeys.TeamActivityCalendarFeedArticleModalNoContentMessage,
              {
                month: format(month, 'MMMM yyyy'),
              },
            )
          : null}
      </ActionsArticlesDueDateCalendarNoArticlesMessage>
    ) : null;

  return (
    <ActionsArticlesDueDateCalendarPageWrapper>
      {showOverlay ? (
        <TeamContextProvider
          key={expandedFeedArticle?.team?.id}
          teamId={expandedFeedArticle?.team?.id}
          companyId={expandedFeedArticle?.team?.company?.id}
        >
          <FeedArticleOverlay
            feedArticle={expandedFeedArticle}
            nextArticleId={nextArticle?.internalId ?? PageDirection.Next}
            previousArticleId={
              previousArticle?.internalId ?? PageDirection.Previous
            }
            nextTooltipMessage={t(
              nextArticle
                ? MessageKeys.TeamActivityCalendarNextArticleTooltip
                : MessageKeys.TeamActivityCalendarNextMonthTooltip,
            )}
            prevTooltipMessage={t(
              previousArticle
                ? MessageKeys.TeamActivityCalendarPrevArticleTooltip
                : MessageKeys.TeamActivityCalendarPrevMonthTooltip,
            )}
            onArchive={handleArchiveChange}
            onBookmark={handleBookmarkChange}
            onMarkAsRead={handleMarkAsReadChange}
            onChangeArticle={handleChangeArticle}
            onRequestClose={handleArticleModalClose}
            onArticleMetadataMutated={onArticleMetadataMutated}
          >
            {overlayChildren}
          </FeedArticleOverlay>
        </TeamContextProvider>
      ) : null}
      <ActionsArticlesDueDateCalendarWrapper>
        {isLoading && !showOverlay ? (
          <ActionsArticlesDueDateCalendarLoadingWrapper>
            <SpinningLoadingIcon size="80px" />
          </ActionsArticlesDueDateCalendarLoadingWrapper>
        ) : (
          <>
            <AlertBox slim>
              {t(MessageKeys.TeamActivityCalendarDueDateOnlyInfoMessage)}
            </AlertBox>
            <Calendar
              monthStartDate={month}
              dateFnsSettings={{
                locale: getDateFnsLocale(settings?.dateFnsLocale ?? 'sv'),
                weekStartsOn: settings?.startOfWeek ?? 1,
              }}
              todayLabel={t(MessageKeys.TeamActivityCalendarTodayLabel)}
              agendaLabel={t(MessageKeys.TeamActivityCalendarAgendaLabel)}
              calendarLabel={t(MessageKeys.TeamActivityCalendarCalendarLabel)}
              eventTypes={Object.values(eventTypes)}
              events={calendarEvents}
              showAgenda={showAgenda}
              onMonthChange={onMonthChange}
              onEventClick={handleEventClick}
              onToggleShowAgenda={onToggleShowAgenda}
            />
          </>
        )}
      </ActionsArticlesDueDateCalendarWrapper>
      {children}
    </ActionsArticlesDueDateCalendarPageWrapper>
  );
};
