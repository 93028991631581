export const getNewlineSeparatedEmails = (text: string): string => {
  return text
    .replace(/,+/gm, ' ')
    .split(/\s+/)
    .map((s) => s.trim())
    .join('\n');
};

export const getEmails = (text: string): Array<string> => {
  return text
    .split(/[\s\n\r]+/)
    ?.map((s) => s.trim())
    ?.filter((s) => s.length) ?? [];
};
