import styled, { css } from 'styled-components';
import { getFoldingAnimation } from '../../animations';

export const FoldingNotificationWrapper = styled.div<{ $prefix: string }>`
  ${({ $prefix }) => css`
    ${getFoldingAnimation($prefix)}
  `}
`;

export const FoldingNotificationContent = styled.div`
  background-color: white;
  z-index: 100;
  padding: 0.6rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
  display: flex;
  align-items: center;
  height: auto;
  max-height: initial;

  border-radius: 900px/1000px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 0 6px 1px rgba(0, 0, 0, 0.1);
`;
