import { useTranslation } from 'react-i18next';
import {
  ConfirmDialog,
  DocumentIcon,
  DownloadIcon,
  ExternalLinkIcon,
  TrashIcon,
  useConfirmDialog,
  useIsMountedRef,
} from '@fcg-tech/regtech-components';
import { TeamAttachment } from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import {
  AttachmentListItemButton,
  AttachmentListItemButtons,
  AttachmentListItemDownloadLink,
  AttachmentListItemLink,
  AttachmentListItemName,
  AttachmentListItemNameWrapper,
  AttachmentListItemWrapper,
} from './AttachmentListItem.styles';

interface AttachmentListItemProps {
  attachment: Partial<TeamAttachment>;
  onDownloadClick?: (ref: string) => Promise<string>;
  onRemoveClick?: (id: string) => Promise<boolean>;
}

export const AttachmentListItem: FunctionComponent<AttachmentListItemProps> = ({
  attachment,
  onDownloadClick,
  onRemoveClick,
}) => {
  const { t } = useTranslation();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const downloadRef = useRef<HTMLAnchorElement>();
  const isMounted = useIsMountedRef();

  const handleDownloadClick = useCallback(async () => {
    setLoadingDownload(true);
    const presignedUrl = await onDownloadClick(
      attachment.documentLocation.ref as string,
    );

    downloadRef.current.download = attachment.name;
    downloadRef.current.href = presignedUrl;
    downloadRef.current.click();
    setLoadingDownload(false);
  }, [attachment.documentLocation.ref, attachment.name, onDownloadClick]);

  const [
    showRemoveConfirm,
    handleRemoveConfirm,
    confirmRemove,
  ] = useConfirmDialog();

  const handleRemoveClick = useCallback(async () => {
    if (await confirmRemove()) {
      setLoadingRemove(true);
      await onRemoveClick?.(attachment.id as string);
      if (isMounted.current) {
        setLoadingRemove(false);
      }
    }
  }, [attachment.id, confirmRemove, isMounted, onRemoveClick]);

  return (
    <AttachmentListItemWrapper>
      {showRemoveConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirmAction)}
          body={t(MessageKeys.ArticleMetadataConfirmRemoveAttachment)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleRemoveConfirm}
        />
      ) : null}
      <AttachmentListItemNameWrapper>
        {attachment?.documentLocation?.customFile ? (
          <>
            <DocumentIcon size="16" className="icon" />
            <AttachmentListItemName>
              {attachment.name ?? attachment.documentLocation?.name}
            </AttachmentListItemName>
          </>
        ) : (
          <>
            <ExternalLinkIcon size="16" className="icon" />
            <AttachmentListItemLink
              href={attachment.documentLocation?.ref as string}
              target="_blank"
            >
              {attachment.name ??
                attachment.documentLocation?.name ??
                (attachment.documentLocation?.ref as string)}
            </AttachmentListItemLink>
          </>
        )}
      </AttachmentListItemNameWrapper>
      <AttachmentListItemButtons>
        <AttachmentListItemDownloadLink ref={downloadRef} />
        {attachment?.documentLocation.customFile && onDownloadClick ? (
          <AttachmentListItemButton
            loading={loadingDownload}
            disabled={loadingRemove}
            onClick={handleDownloadClick}
          >
            <DownloadIcon size="16" />
          </AttachmentListItemButton>
        ) : null}
        {onRemoveClick ? (
          <AttachmentListItemButton
            loading={loadingRemove}
            disabled={loadingDownload}
            onClick={handleRemoveClick}
          >
            <TrashIcon size="16" />
          </AttachmentListItemButton>
        ) : null}
      </AttachmentListItemButtons>
    </AttachmentListItemWrapper>
  );
};
