import { IconButton } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const AttachmentListItemWrapper = styled.div`
  padding: 0.5rem 0;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: #ddd;
  }
`;

export const AttachmentListItemNameWrapper = styled.div`
  display: flex;
  align-items: center;
  & > .icon {
    margin-right: 0.5rem;
  }
`;

export const AttachmentListItemName = styled.span`
  flex: 1;
`;

export const AttachmentListItemButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const AttachmentListItemButton = styled(IconButton)`
  width: 2.2rem;
  height: 2.2rem;
  & + & {
    margin-left: 0.6rem;
  }
`;

export const AttachmentListItemDownloadLink = styled.a`
  width: 0;
  height: 0;
  overflow: hidden;
`;

export const AttachmentListItemLink = styled.a`
  flex: 1;
`;

export const AttachmentListUrlError = styled.div`
  position: absolute;
  left: 0;
  bottom: 1rem;
  color: red;
  font-size: 1.2rem;
`;

export const AttachmentListNoItemsMessage = styled.div`
  margin-top: 1rem;
  opacity: 0.7;
  font-size: 1.2rem;
  position: relative;
  top: -0.1rem;
`;
