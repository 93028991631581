import {
  FormGridButtonRow,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  useFlash,
} from '@fcg-tech/regtech-components';
import { MessageLevel } from '@fcg-tech/regtech-types';
import {
  SubscriptionUpdateItemsInner,
  Team,
} from '@fcg-tech/regtech-types/regeye';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useApi } from '../../api/apiUtils';
import { useAllTeams, usePublisherTeamActions } from '../../api/hooks';
import { PublishersApi } from '../../api/schema';
import { ModalForm, ModalFormWrapper } from '../../components/modals';
import { portalRoot } from '../../components/Portal';
import { singleSelectInLabelStyles } from '../../components/selectStyles';
import { MessageKeys } from '../../translations/translationTypes';

interface TeamSettingsCopySubscriptionsModalProps {
  targetTeamId: string;
  onRequestClose: (settingsCopied: boolean) => void;
}

export const TeamSettingsCopySubscriptionsModal: FunctionComponent<
  TeamSettingsCopySubscriptionsModalProps
> = ({ targetTeamId, onRequestClose }) => {
  const { t } = useTranslation();
  const api = useApi<PublishersApi>('PublishersApi');
  const { data: teams } = useAllTeams();
  const { updateTeamPublisherSettings } = usePublisherTeamActions(targetTeamId);
  const [sourceTeam, setSourceTeam] = useState<ReactSelectOption<Team>>();
  const addFlash = useFlash();

  const [loading, setLoading] = useState(false);

  const teamsOptions: Array<ReactSelectOption<Team>> = useMemo(
    () =>
      teams
        .filter((team) => team.id !== targetTeamId)
        .map<ReactSelectOption<Team>>((team) => ({
          label: `${team.company.name} - ${team.name}`,
          value: team,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [targetTeamId, teams],
  );

  const handleSourceTeamChange = useCallback(
    (option: ReactSelectOption<Team>) => {
      setSourceTeam(option);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const sourceTeamId = sourceTeam?.value.id;
        const data = await api.getTeamSubscription({ teamId: sourceTeamId });
        const items = data.items
          .map<SubscriptionUpdateItemsInner>((pub) => {
            return {
              publisherId: pub.id,
              articleTypeIds: pub.articleTypes
                .filter((tag) => tag.subscribed)
                .map((tag) => tag.id),
              subscribeToEntirePublisher: pub.subscribedToEntirePublisher,
            };
          })
          .filter(
            (item) =>
              item.articleTypeIds.length > 0 || item.subscribeToEntirePublisher,
          );

        await updateTeamPublisherSettings(items);

        onRequestClose(true);

        addFlash({
          level: MessageLevel.Success,
          content: t(MessageKeys.LabelChangesSaved),
        });
      } catch (e) {
        setLoading(false);
        addFlash({
          level: MessageLevel.Error,
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    },
    [
      addFlash,
      api,
      onRequestClose,
      sourceTeam?.value.id,
      t,
      updateTeamPublisherSettings,
    ],
  );

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.TeamSettingsCopySubscriptionsLabel)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper>
          <ModalForm onSubmit={handleSubmit}>
            <FormGridRow className="with-select">
              <FormGridLabel>
                {t(MessageKeys.TeamSettingsCopyFromLabel)}
              </FormGridLabel>
              <FormGridControl>
                <Select
                  options={teamsOptions}
                  value={sourceTeam}
                  styles={singleSelectInLabelStyles}
                  menuPortalTarget={portalRoot}
                  onChange={handleSourceTeamChange}
                />
              </FormGridControl>
            </FormGridRow>
            <FormGridButtonRow>
              <SecondaryButton
                type="button"
                onClick={() => onRequestClose(false)}
                disabled={loading}
              >
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                disabled={!sourceTeam}
                loading={loading}
              >
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </FormGridButtonRow>
          </ModalForm>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
