import { TeamReadMetadata, User } from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useMemo } from 'react';
import { useSettings } from '../../api/hooks';
import { formatName } from '../../utils/formatters';
import { userSorter } from '../../utils/sorters';
import {
  FeedArticleReadListItem,
  FeedArticleReadListItemIcon,
  FeedArticleReadListWrapper,
} from './FeedArticleReadList.styles';

interface FeedArticleReadListProps {
  readMetaData: TeamReadMetadata;
}

export const FeedArticleReadList: FunctionComponent<
  FeedArticleReadListProps
> = ({ readMetaData }) => {
  const { data: settings } = useSettings({ suspense: false });
  const users: Array<{ user: User; read: boolean }> = useMemo(
    () =>
      readMetaData.team.members.sort(userSorter(settings)).map((user) => ({
        user,
        read: Boolean(
          readMetaData.readByUsers.find(
            ({ username }) => username === user.username,
          ),
        ),
      })),
    [readMetaData.readByUsers, readMetaData.team.members, settings],
  );
  return (
    <FeedArticleReadListWrapper>
      {users.map(({ user, read }) => (
        <FeedArticleReadListItem key={user.username} read={read}>
          <FeedArticleReadListItemIcon className={read && 'read'} />
          {formatName(user, settings?.userNamePreference)}
        </FeedArticleReadListItem>
      ))}
    </FeedArticleReadListWrapper>
  );
};
