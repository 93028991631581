import React, { FunctionComponent } from 'react';
import { SetupStatus } from '../../utils/setupStatus';
import { AdminUserSetupWizardModal } from './AdminUserSetupWizardModal';
import { InvitationsSetupWizardModal } from './InvitationsSetupWizardModal';
import { JoinTeamSetupWizardModal } from './JoinTeamSetupWizardModal';
import { UserWithNoTeamsSetupWizardModal } from './UserWithNoTeamsSetupWizardModal';

interface SetupWizardProps {
  setupStatus: SetupStatus;
  singleCompanyId?: string;
  onRequestClose?: () => void;
}

export const SetupWizard: FunctionComponent<SetupWizardProps> = ({
  setupStatus,
  singleCompanyId,
  onRequestClose,
}) => {
  if (setupStatus === SetupStatus.CreateTeamAndInviteUsers) {
    return <AdminUserSetupWizardModal onRequestClose={onRequestClose} />;
  }

  if (setupStatus === SetupStatus.HasNoTeams) {
    return <UserWithNoTeamsSetupWizardModal onRequestClose={onRequestClose} />;
  }

  if (setupStatus === SetupStatus.HasInvites) {
    return <InvitationsSetupWizardModal onRequestClose={onRequestClose} />;
  }

  if (setupStatus === SetupStatus.JoinTeam) {
    return (
      <JoinTeamSetupWizardModal
        companyId={singleCompanyId}
        onRequestClose={onRequestClose}
      />
    );
  }

  return null;
};
