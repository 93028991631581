import {
  ConfirmDialog,
  PrimaryButton,
  useConfirmDialog,
  useFlash,
  useToggle,
} from '@fcg-tech/regtech-components';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useStoredFilterActions,
  useTeamStoredFilters,
} from '../../api/hooks/filterApiHooks';
import {
  AccessControl,
  useAccessControl,
} from '../../components/AccessControl';
import { FeedArticleFilterSaveDialog } from '../../components/Filter/FeedArticleFilterSaveDialog';
import { FilterListTable } from '../../components/FilterListTable';
import { NoContentMessage } from '../../components/generic';
import { useIamContext } from '../../components/IamContext';
import { MessageKeys } from '../../translations/translationTypes';
import { TeamActions } from '../../types';
import { canGetTeamFilters } from '../../utils/iamHelpers';
import { TeamSettingsCopyTeamFilterModal } from './TeamSettingsCopyTeamFilterModal';
import {
  TeamSettingsPageTeamFiltersInfo,
  TeamSettingsTabButtonBar,
} from './TeamSettingsPage.styles';
import { TeamSettingsTabTopPaddedWrapper } from './TeamSettingsSubscriptionsTab.styles';

interface TeamSettingsFiltersDetailsProps {
  teamId: string;
}

export const TeamSettingsFiltersDetails: FunctionComponent<
  TeamSettingsFiltersDetailsProps
> = ({ teamId }) => {
  const { t } = useTranslation();
  const { deleteFilter, saveFilter } = useStoredFilterActions();
  const addFlash = useFlash();
  const iamContext = useIamContext();
  const canListFilters = canGetTeamFilters(teamId, iamContext);
  const { data } = useTeamStoredFilters(
    undefined,
    canListFilters ? teamId : null,
  );

  const [
    showConfirmDeleteFilterDialog,
    handleConfirmDeleteFilter,
    confirmDeleteFilter,
  ] = useConfirmDialog();

  const [showCopyTeamFilterModal, , onShowCopyTeamFilterModalClick] =
    useToggle(false);

  const [editFilterId, setEditFilterId] = useState<string>();

  const handleRemoveFilter = useCallback(
    async (filterId: string) => {
      const confirmed = await confirmDeleteFilter();
      if (confirmed) {
        const filter = data.find(({ id }) => id === filterId);
        if (filter) {
          try {
            await deleteFilter(filterId, filter.filterType, teamId);
            addFlash({
              level: 'success',
              content: t(
                MessageKeys.AdministrationFiltersDeleteFilterSuccessLabel,
              ),
            });
          } catch (e) {
            addFlash({
              level: 'error',
              content: t(
                MessageKeys.AdministrationFiltersDeleteFilterFailureLabel,
              ),
            });
          }
        }
      }
    },
    [addFlash, confirmDeleteFilter, data, deleteFilter, t, teamId],
  );

  const editFilter = useMemo(
    () => data?.find(({ id }) => id === editFilterId),
    [data, editFilterId],
  );

  const handleUpdateFilter = useCallback(
    async (name: string) => {
      try {
        await saveFilter(
          editFilter.filter,
          name,
          editFilter.filterType,
          teamId,
          editFilter.id,
        );
        addFlash({
          level: 'success',
          content: t(MessageKeys.FeedArticleFilterFilterSaveSuccess),
        });
        setEditFilterId(null);
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.FeedArticleFilterFilterSaveFailure),
        });
      }
    },
    [
      addFlash,
      editFilter?.filter,
      editFilter?.filterType,
      editFilter?.id,
      saveFilter,
      t,
      teamId,
    ],
  );

  const handleUpdateFilterCancel = useCallback(() => setEditFilterId(null), []);

  const canEdit = useAccessControl([TeamActions.UpdateTeamFilter]);
  const canRemove = useAccessControl([TeamActions.DeleteTeamFilter]);

  const copyTeamFilterContent = (
    <>
      {showCopyTeamFilterModal ? (
        <TeamSettingsCopyTeamFilterModal
          targetTeamId={teamId}
          onRequestClose={onShowCopyTeamFilterModalClick}
        />
      ) : null}
      <AccessControl requiredPermissions={[TeamActions.CreateTeamFilter]}>
        <TeamSettingsTabButtonBar>
          <PrimaryButton onClick={onShowCopyTeamFilterModalClick}>
            {t(MessageKeys.TeamSettingsCopyTeamFilterLabel)}
          </PrimaryButton>
        </TeamSettingsTabButtonBar>
      </AccessControl>
    </>
  );

  return data?.length === 0 ? (
    <TeamSettingsTabTopPaddedWrapper>
      {copyTeamFilterContent}
      <NoContentMessage>
        {t(MessageKeys.LabelNoFiltersFound)}
        <TeamSettingsPageTeamFiltersInfo>
          {t(MessageKeys.AdministrationTeamFiltersHowToCreateFilters)}
        </TeamSettingsPageTeamFiltersInfo>
      </NoContentMessage>
    </TeamSettingsTabTopPaddedWrapper>
  ) : (
    <TeamSettingsTabTopPaddedWrapper>
      {showConfirmDeleteFilterDialog ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelDelete)}
          body={t(MessageKeys.LabelDeleteFilterConfirmation)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleConfirmDeleteFilter}
        />
      ) : null}
      {editFilter ? (
        <FeedArticleFilterSaveDialog
          allowTeamFilters
          storedFilter={editFilter}
          onRequestClose={handleUpdateFilterCancel}
          onSubmit={handleUpdateFilter}
        />
      ) : null}

      {copyTeamFilterContent}

      <AccessControl
        requiredPermissions={[TeamActions.GetTeamFilters]}
        noAccessMessageKey={MessageKeys.AccessErrorTeamFilterList}
      >
        <FilterListTable
          filters={data}
          onRemoveFilter={canRemove ? handleRemoveFilter : undefined}
          onEditFilter={canEdit ? setEditFilterId : undefined}
        />
      </AccessControl>
    </TeamSettingsTabTopPaddedWrapper>
  );
};
