import {exists, mapValues} from '../runtime';



/* ActivityList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface ActivityList
 */
export interface ActivityList {
    /**
     * 
     * @type {Array<RecentTeamActivity>}
     * @memberof ActivityList
     */
    items: Array<RecentTeamActivity>;
}

export function ActivityListFromJSON(json: any): ActivityList {
    return ActivityListFromJSONTyped(json, false);
}

export function ActivityListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(RecentTeamActivityFromJSON)),
    };
}

export function ActivityListToJSON(value?: ActivityList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(RecentTeamActivityToJSON)),
    };
}



/* Archived.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface Archived
 */
export interface Archived {
    /**
     * 
     * @type {boolean}
     * @memberof Archived
     */
    archived: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Archived
     */
    lastModified?: Date;
    /**
     * 
     * @type {User}
     * @memberof Archived
     */
    setByUser?: User;
}

export function ArchivedFromJSON(json: any): Archived {
    return ArchivedFromJSONTyped(json, false);
}

export function ArchivedFromJSONTyped(json: any, ignoreDiscriminator: boolean): Archived {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archived': json['archived'],
        'lastModified': !exists(json, 'lastModified') ? undefined : (new Date(json['lastModified'])),
        'setByUser': !exists(json, 'setByUser') ? undefined : UserFromJSON(json['setByUser']),
    };
}

export function ArchivedToJSON(value?: Archived | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archived': value.archived,
        'lastModified': value.lastModified === undefined ? undefined : (value.lastModified.toISOString()),
        'setByUser': UserToJSON(value.setByUser),
    };
}



/* ArchivedOptions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ArchivedOptions = {
    Archived: 'archived',
    NotArchived: 'not-archived',
    All: 'all'
} as const;
export type ArchivedOptions = typeof ArchivedOptions[keyof typeof ArchivedOptions];


export function ArchivedOptionsFromJSON(json: any): ArchivedOptions {
    return ArchivedOptionsFromJSONTyped(json, false);
}

export function ArchivedOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchivedOptions {
    return json as ArchivedOptions;
}

export function ArchivedOptionsToJSON(value?: ArchivedOptions | null): any {
    return value as any;
}



/* Article.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    publisher: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    publisherAbbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    publisherLink?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    title: string;
    /**
     * 
     * @type {DocumentLocation}
     * @memberof Article
     */
    documentLocation?: DocumentLocation;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Article
     */
    publicationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    description?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Article
     */
    articleTypes: Array<Tag>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Article
     */
    articleTags: Array<Tag>;
    /**
     * 
     * @type {Date}
     * @memberof Article
     */
    created: Date;
}

export function ArticleFromJSON(json: any): Article {
    return ArticleFromJSONTyped(json, false);
}

export function ArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Article {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publisher': json['publisher'],
        'publisherAbbreviation': !exists(json, 'publisherAbbreviation') ? undefined : json['publisherAbbreviation'],
        'publisherLink': !exists(json, 'publisherLink') ? undefined : json['publisherLink'],
        'title': json['title'],
        'documentLocation': !exists(json, 'documentLocation') ? undefined : DocumentLocationFromJSON(json['documentLocation']),
        'id': json['id'],
        'publicationDate': (new Date(json['publicationDate'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'articleTypes': ((json['articleTypes'] as Array<any>).map(TagFromJSON)),
        'articleTags': ((json['articleTags'] as Array<any>).map(TagFromJSON)),
        'created': (new Date(json['created'])),
    };
}

export function ArticleToJSON(value?: Article | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publisher': value.publisher,
        'publisherAbbreviation': value.publisherAbbreviation,
        'publisherLink': value.publisherLink,
        'title': value.title,
        'documentLocation': DocumentLocationToJSON(value.documentLocation),
        'id': value.id,
        'publicationDate': (value.publicationDate.toISOString()),
        'description': value.description,
        'articleTypes': ((value.articleTypes as Array<any>).map(TagToJSON)),
        'articleTags': ((value.articleTags as Array<any>).map(TagToJSON)),
        'created': (value.created.toISOString()),
    };
}



/* ArticleExpandedMetadata.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface ArticleExpandedMetadata
 */
export interface ArticleExpandedMetadata {
    /**
     * 
     * @type {string}
     * @memberof ArticleExpandedMetadata
     */
    articleText: string;
    /**
     * 
     * @type {Array<TeamReadMetadata>}
     * @memberof ArticleExpandedMetadata
     */
    teamReadMetadata: Array<TeamReadMetadata>;
}

export function ArticleExpandedMetadataFromJSON(json: any): ArticleExpandedMetadata {
    return ArticleExpandedMetadataFromJSONTyped(json, false);
}

export function ArticleExpandedMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleExpandedMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleText': json['articleText'],
        'teamReadMetadata': ((json['teamReadMetadata'] as Array<any>).map(TeamReadMetadataFromJSON)),
    };
}

export function ArticleExpandedMetadataToJSON(value?: ArticleExpandedMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleText': value.articleText,
        'teamReadMetadata': ((value.teamReadMetadata as Array<any>).map(TeamReadMetadataToJSON)),
    };
}



/* ArticleNotify.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface ArticleNotify
 */
export interface ArticleNotify {
    /**
     * 
     * @type {boolean}
     * @memberof ArticleNotify
     */
    notified: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ArticleNotify
     */
    created?: Date;
    /**
     * 
     * @type {User}
     * @memberof ArticleNotify
     */
    createdBy?: User;
}

export function ArticleNotifyFromJSON(json: any): ArticleNotify {
    return ArticleNotifyFromJSONTyped(json, false);
}

export function ArticleNotifyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleNotify {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notified': json['notified'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : UserFromJSON(json['createdBy']),
    };
}

export function ArticleNotifyToJSON(value?: ArticleNotify | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notified': value.notified,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'createdBy': UserToJSON(value.createdBy),
    };
}



/* AssignedUser.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface AssignedUser
 */
export interface AssignedUser {
    /**
     * 
     * @type {User}
     * @memberof AssignedUser
     */
    user: User;
    /**
     * 
     * @type {boolean}
     * @memberof AssignedUser
     */
    completed: boolean;
}

export function AssignedUserFromJSON(json: any): AssignedUser {
    return AssignedUserFromJSONTyped(json, false);
}

export function AssignedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignedUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserFromJSON(json['user']),
        'completed': json['completed'],
    };
}

export function AssignedUserToJSON(value?: AssignedUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'completed': value.completed,
    };
}



/* AvailableEventLogFilters.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






/**
 * 
 * @export
 * @interface AvailableEventLogFilters
 */
export interface AvailableEventLogFilters {
    /**
     * 
     * @type {Array<TeamAction>}
     * @memberof AvailableEventLogFilters
     */
    actions: Array<TeamAction>;
    /**
     * 
     * @type {Array<User>}
     * @memberof AvailableEventLogFilters
     */
    performedBy: Array<User>;
    /**
     * 
     * @type {Array<Team>}
     * @memberof AvailableEventLogFilters
     */
    teams: Array<Team>;
}

export function AvailableEventLogFiltersFromJSON(json: any): AvailableEventLogFilters {
    return AvailableEventLogFiltersFromJSONTyped(json, false);
}

export function AvailableEventLogFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableEventLogFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actions': ((json['actions'] as Array<any>).map(TeamActionFromJSON)),
        'performedBy': ((json['performedBy'] as Array<any>).map(UserFromJSON)),
        'teams': ((json['teams'] as Array<any>).map(TeamFromJSON)),
    };
}

export function AvailableEventLogFiltersToJSON(value?: AvailableEventLogFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actions': ((value.actions as Array<any>).map(TeamActionToJSON)),
        'performedBy': ((value.performedBy as Array<any>).map(UserToJSON)),
        'teams': ((value.teams as Array<any>).map(TeamToJSON)),
    };
}



/* AvailableInsightFilters.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






/**
 * 
 * @export
 * @interface AvailableInsightFilters
 */
export interface AvailableInsightFilters {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof AvailableInsightFilters
     */
    tags: Array<Tag>;
    /**
     * 
     * @type {Array<User>}
     * @memberof AvailableInsightFilters
     */
    commentedBy: Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof AvailableInsightFilters
     */
    attachedBy: Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof AvailableInsightFilters
     */
    assignees: Array<User>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AvailableInsightFilters
     */
    importance: Array<number>;
    /**
     * 
     * @type {Array<Team>}
     * @memberof AvailableInsightFilters
     */
    teams: Array<Team>;
}

export function AvailableInsightFiltersFromJSON(json: any): AvailableInsightFilters {
    return AvailableInsightFiltersFromJSONTyped(json, false);
}

export function AvailableInsightFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableInsightFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
        'commentedBy': ((json['commentedBy'] as Array<any>).map(UserFromJSON)),
        'attachedBy': ((json['attachedBy'] as Array<any>).map(UserFromJSON)),
        'assignees': ((json['assignees'] as Array<any>).map(UserFromJSON)),
        'importance': json['importance'],
        'teams': ((json['teams'] as Array<any>).map(TeamFromJSON)),
    };
}

export function AvailableInsightFiltersToJSON(value?: AvailableInsightFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': ((value.tags as Array<any>).map(TagToJSON)),
        'commentedBy': ((value.commentedBy as Array<any>).map(UserToJSON)),
        'attachedBy': ((value.attachedBy as Array<any>).map(UserToJSON)),
        'assignees': ((value.assignees as Array<any>).map(UserToJSON)),
        'importance': value.importance,
        'teams': ((value.teams as Array<any>).map(TeamToJSON)),
    };
}



/* Comment.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    comment: string;
    /**
     * 
     * @type {object}
     * @memberof Comment
     */
    markup?: object;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    replyTo?: string;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    lastModified?: Date;
    /**
     * 
     * @type {User}
     * @memberof Comment
     */
    author: User;
    /**
     * 
     * @type {boolean}
     * @memberof Comment
     */
    deleted: boolean;
}

export function CommentFromJSON(json: any): Comment {
    return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Comment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'comment': json['comment'],
        'markup': !exists(json, 'markup') ? undefined : json['markup'],
        'replyTo': !exists(json, 'replyTo') ? undefined : json['replyTo'],
        'created': (new Date(json['created'])),
        'lastModified': !exists(json, 'lastModified') ? undefined : (new Date(json['lastModified'])),
        'author': UserFromJSON(json['author']),
        'deleted': json['deleted'],
    };
}

export function CommentToJSON(value?: Comment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'comment': value.comment,
        'markup': value.markup,
        'replyTo': value.replyTo,
        'created': (value.created.toISOString()),
        'lastModified': value.lastModified === undefined ? undefined : (value.lastModified.toISOString()),
        'author': UserToJSON(value.author),
        'deleted': value.deleted,
    };
}



/* CommentCreateInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface CommentCreateInput
 */
export interface CommentCreateInput {
    /**
     * 
     * @type {string}
     * @memberof CommentCreateInput
     */
    comment: string;
    /**
     * 
     * @type {object}
     * @memberof CommentCreateInput
     */
    markup?: object;
    /**
     * 
     * @type {string}
     * @memberof CommentCreateInput
     */
    replyTo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentCreateInput
     */
    mentions?: Array<string>;
}

export function CommentCreateInputFromJSON(json: any): CommentCreateInput {
    return CommentCreateInputFromJSONTyped(json, false);
}

export function CommentCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
        'markup': !exists(json, 'markup') ? undefined : json['markup'],
        'replyTo': !exists(json, 'replyTo') ? undefined : json['replyTo'],
        'mentions': !exists(json, 'mentions') ? undefined : json['mentions'],
    };
}

export function CommentCreateInputToJSON(value?: CommentCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'markup': value.markup,
        'replyTo': value.replyTo,
        'mentions': value.mentions,
    };
}



/* CommentResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface CommentResponse
 */
export interface CommentResponse {
    /**
     * 
     * @type {Comment}
     * @memberof CommentResponse
     */
    comment: Comment;
}

export function CommentResponseFromJSON(json: any): CommentResponse {
    return CommentResponseFromJSONTyped(json, false);
}

export function CommentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': CommentFromJSON(json['comment']),
    };
}

export function CommentResponseToJSON(value?: CommentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': CommentToJSON(value.comment),
    };
}



/* CommentUpdateInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface CommentUpdateInput
 */
export interface CommentUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof CommentUpdateInput
     */
    comment: string;
    /**
     * 
     * @type {object}
     * @memberof CommentUpdateInput
     */
    markup?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentUpdateInput
     */
    mentions?: Array<string>;
}

export function CommentUpdateInputFromJSON(json: any): CommentUpdateInput {
    return CommentUpdateInputFromJSONTyped(json, false);
}

export function CommentUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
        'markup': !exists(json, 'markup') ? undefined : json['markup'],
        'mentions': !exists(json, 'mentions') ? undefined : json['mentions'],
    };
}

export function CommentUpdateInputToJSON(value?: CommentUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'markup': value.markup,
        'mentions': value.mentions,
    };
}



/* Company.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    stringId: string;
    /**
     * 
     * @type {Array<CompanyActions>}
     * @memberof Company
     */
    companyActions?: Array<CompanyActions>;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'stringId': json['stringId'],
        'companyActions': !exists(json, 'companyActions') ? undefined : ((json['companyActions'] as Array<any>).map(CompanyActionsFromJSON)),
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'stringId': value.stringId,
        'companyActions': value.companyActions === undefined ? undefined : ((value.companyActions as Array<any>).map(CompanyActionsToJSON)),
    };
}



/* CompanyActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CompanyActions = {
    ViewPageCompanyAdministration: 'regeye:company:ViewPageCompanyAdministration',
    CompanyGet: 'regeye:company:CompanyGet',
    CompanyEdit: 'regeye:company:CompanyEdit',
    CompanyElevatedEdit: 'regeye:company:CompanyElevatedEdit',
    CompanyDelete: 'regeye:company:CompanyDelete',
    CompanyTeamCreate: 'regeye:company:CompanyTeamCreate',
    CompanyTeamList: 'regeye:company:CompanyTeamList',
    CompanyTeamDelete: 'regeye:company:CompanyTeamDelete',
    CompanyTeamGet: 'regeye:company:CompanyTeamGet',
    CompanyTeamEdit: 'regeye:company:CompanyTeamEdit',
    CompanyTeamMemberEdit: 'regeye:company:CompanyTeamMemberEdit',
    CompanyTeamMemberDelete: 'regeye:company:CompanyTeamMemberDelete',
    CompanyTeamAddUser: 'regeye:company:CompanyTeamAddUser',
    CompanyTeamInvitationCreate: 'regeye:company:CompanyTeamInvitationCreate',
    CompanyTeamInvitationGet: 'regeye:company:CompanyTeamInvitationGet',
    CompanyTeamInvitationList: 'regeye:company:CompanyTeamInvitationList',
    CompanyTeamInvitationDelete: 'regeye:company:CompanyTeamInvitationDelete',
    CompanyTeamSubscriptionUpdate: 'regeye:company:CompanyTeamSubscriptionUpdate',
    CompanyTeamSubscriptionList: 'regeye:company:CompanyTeamSubscriptionList',
    GetCompanyActivityLogArticles: 'regeye:company:GetCompanyActivityLogArticles',
    GetCompanyActionsArticles: 'regeye:company:GetCompanyActionsArticles',
    CreateCompanyExcelReport: 'regeye:company:CreateCompanyExcelReport'
} as const;
export type CompanyActions = typeof CompanyActions[keyof typeof CompanyActions];


export function CompanyActionsFromJSON(json: any): CompanyActions {
    return CompanyActionsFromJSONTyped(json, false);
}

export function CompanyActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyActions {
    return json as CompanyActions;
}

export function CompanyActionsToJSON(value?: CompanyActions | null): any {
    return value as any;
}



/* CompanyInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface CompanyInput
 */
export interface CompanyInput {
    /**
     * 
     * @type {string}
     * @memberof CompanyInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInput
     */
    stringId: string;
}

export function CompanyInputFromJSON(json: any): CompanyInput {
    return CompanyInputFromJSONTyped(json, false);
}

export function CompanyInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'stringId': json['stringId'],
    };
}

export function CompanyInputToJSON(value?: CompanyInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'stringId': value.stringId,
    };
}



/* CompanyList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface CompanyList
 */
export interface CompanyList {
    /**
     * 
     * @type {Array<Company>}
     * @memberof CompanyList
     */
    items: Array<Company>;
}

export function CompanyListFromJSON(json: any): CompanyList {
    return CompanyListFromJSONTyped(json, false);
}

export function CompanyListFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CompanyFromJSON)),
    };
}

export function CompanyListToJSON(value?: CompanyList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CompanyToJSON)),
    };
}



/* CompanyUpdateInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface CompanyUpdateInput
 */
export interface CompanyUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof CompanyUpdateInput
     */
    name: string;
}

export function CompanyUpdateInputFromJSON(json: any): CompanyUpdateInput {
    return CompanyUpdateInputFromJSONTyped(json, false);
}

export function CompanyUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function CompanyUpdateInputToJSON(value?: CompanyUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}



/* Count.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Count
 */
export interface Count {
    /**
     * 
     * @type {number}
     * @memberof Count
     */
    count: number;
}

export function CountFromJSON(json: any): Count {
    return CountFromJSONTyped(json, false);
}

export function CountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Count {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
    };
}

export function CountToJSON(value?: Count | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
    };
}



/* CreationMetadata.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface CreationMetadata
 */
export interface CreationMetadata {
    /**
     * 
     * @type {User}
     * @memberof CreationMetadata
     */
    createdBy?: User;
    /**
     * 
     * @type {User}
     * @memberof CreationMetadata
     */
    lastModifiedBy?: User;
    /**
     * 
     * @type {Date}
     * @memberof CreationMetadata
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreationMetadata
     */
    lastModified: Date;
}

export function CreationMetadataFromJSON(json: any): CreationMetadata {
    return CreationMetadataFromJSONTyped(json, false);
}

export function CreationMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreationMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdBy': !exists(json, 'createdBy') ? undefined : UserFromJSON(json['createdBy']),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : UserFromJSON(json['lastModifiedBy']),
        'created': (new Date(json['created'])),
        'lastModified': (new Date(json['lastModified'])),
    };
}

export function CreationMetadataToJSON(value?: CreationMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': UserToJSON(value.createdBy),
        'lastModifiedBy': UserToJSON(value.lastModifiedBy),
        'created': (value.created.toISOString()),
        'lastModified': (value.lastModified.toISOString()),
    };
}



/* DashboardArticle.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface DashboardArticle
 */
export interface DashboardArticle {
    /**
     * 
     * @type {string}
     * @memberof DashboardArticle
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardArticle
     */
    title: string;
}

export function DashboardArticleFromJSON(json: any): DashboardArticle {
    return DashboardArticleFromJSONTyped(json, false);
}

export function DashboardArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
    };
}

export function DashboardArticleToJSON(value?: DashboardArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
    };
}



/* DateFilter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface DateFilter
 */
export interface DateFilter {
    /**
     * 
     * @type {Date}
     * @memberof DateFilter
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateFilter
     */
    to?: Date;
    /**
     * 
     * @type {string}
     * @memberof DateFilter
     */
    interval?: string;
}

export function DateFilterFromJSON(json: any): DateFilter {
    return DateFilterFromJSONTyped(json, false);
}

export function DateFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
    };
}

export function DateFilterToJSON(value?: DateFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from.toISOString().substr(0,10)),
        'to': value.to === undefined ? undefined : (value.to.toISOString().substr(0,10)),
        'interval': value.interval,
    };
}



/* DateInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface DateInput
 */
export interface DateInput {
    /**
     * 
     * @type {Date}
     * @memberof DateInput
     */
    dueDate: Date;
}

export function DateInputFromJSON(json: any): DateInput {
    return DateInputFromJSONTyped(json, false);
}

export function DateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dueDate': (new Date(json['dueDate'])),
    };
}

export function DateInputToJSON(value?: DateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dueDate': (value.dueDate.toISOString().substr(0,10)),
    };
}



/* DocumentLocation.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface DocumentLocation
 */
export interface DocumentLocation {
    /**
     * 
     * @type {string}
     * @memberof DocumentLocation
     */
    ref: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentLocation
     */
    customFile: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentLocation
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLocation
     */
    fileLocation?: string;
}

export function DocumentLocationFromJSON(json: any): DocumentLocation {
    return DocumentLocationFromJSONTyped(json, false);
}

export function DocumentLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ref': json['ref'],
        'customFile': json['customFile'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileLocation': !exists(json, 'fileLocation') ? undefined : json['fileLocation'],
    };
}

export function DocumentLocationToJSON(value?: DocumentLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ref': value.ref,
        'customFile': value.customFile,
        'name': value.name,
        'fileLocation': value.fileLocation,
    };
}



/* DocumentLocationList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface DocumentLocationList
 */
export interface DocumentLocationList {
    /**
     * 
     * @type {Array<DocumentLocation>}
     * @memberof DocumentLocationList
     */
    items: Array<DocumentLocation>;
}

export function DocumentLocationListFromJSON(json: any): DocumentLocationList {
    return DocumentLocationListFromJSONTyped(json, false);
}

export function DocumentLocationListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentLocationList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(DocumentLocationFromJSON)),
    };
}

export function DocumentLocationListToJSON(value?: DocumentLocationList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(DocumentLocationToJSON)),
    };
}



/* DueDate.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface DueDate
 */
export interface DueDate {
    /**
     * 
     * @type {Date}
     * @memberof DueDate
     */
    dueDate: Date;
    /**
     * 
     * @type {User}
     * @memberof DueDate
     */
    setByUser?: User;
}

export function DueDateFromJSON(json: any): DueDate {
    return DueDateFromJSONTyped(json, false);
}

export function DueDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DueDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dueDate': (new Date(json['dueDate'])),
        'setByUser': !exists(json, 'setByUser') ? undefined : UserFromJSON(json['setByUser']),
    };
}

export function DueDateToJSON(value?: DueDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dueDate': (value.dueDate.toISOString().substr(0,10)),
        'setByUser': UserToJSON(value.setByUser),
    };
}



/* ErrorResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponse
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}

export function ErrorResponseFromJSON(json: any): ErrorResponse {
    return ErrorResponseFromJSONTyped(json, false);
}

export function ErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
    };
}

export function ErrorResponseToJSON(value?: ErrorResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'message': value.message,
    };
}



/* Excel.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Excel
 */
export interface Excel {
    /**
     * 
     * @type {string}
     * @memberof Excel
     */
    data: string;
    /**
     * 
     * @type {number}
     * @memberof Excel
     */
    totalRows: number;
    /**
     * 
     * @type {number}
     * @memberof Excel
     */
    maxRows: number;
}

export function ExcelFromJSON(json: any): Excel {
    return ExcelFromJSONTyped(json, false);
}

export function ExcelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Excel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
        'totalRows': json['totalRows'],
        'maxRows': json['maxRows'],
    };
}

export function ExcelToJSON(value?: Excel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'totalRows': value.totalRows,
        'maxRows': value.maxRows,
    };
}



/* Exclude.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Exclude = {
    Publishers: 'publishers',
    Regions: 'regions',
    Types: 'types',
    Tags: 'tags'
} as const;
export type Exclude = typeof Exclude[keyof typeof Exclude];


export function ExcludeFromJSON(json: any): Exclude {
    return ExcludeFromJSONTyped(json, false);
}

export function ExcludeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Exclude {
    return json as Exclude;
}

export function ExcludeToJSON(value?: Exclude | null): any {
    return value as any;
}



/* FeedArticle.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */








/**
 * 
 * @export
 * @interface FeedArticle
 */
export interface FeedArticle {
    /**
     * 
     * @type {Article}
     * @memberof FeedArticle
     */
    article: Article;
    /**
     * 
     * @type {UserMetadata}
     * @memberof FeedArticle
     */
    userMetadata: UserMetadata;
    /**
     * 
     * @type {Team}
     * @memberof FeedArticle
     */
    team?: Team;
    /**
     * 
     * @type {boolean}
     * @memberof FeedArticle
     */
    archived?: boolean;
    /**
     * 
     * @type {RecentTeamActivity}
     * @memberof FeedArticle
     */
    recentTeamActivity?: RecentTeamActivity;
    /**
     * 
     * @type {TeamArticleMetadata}
     * @memberof FeedArticle
     */
    teamArticleMetadata?: TeamArticleMetadata;
}

export function FeedArticleFromJSON(json: any): FeedArticle {
    return FeedArticleFromJSONTyped(json, false);
}

export function FeedArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'article': ArticleFromJSON(json['article']),
        'userMetadata': UserMetadataFromJSON(json['userMetadata']),
        'team': !exists(json, 'team') ? undefined : TeamFromJSON(json['team']),
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'recentTeamActivity': !exists(json, 'recentTeamActivity') ? undefined : RecentTeamActivityFromJSON(json['recentTeamActivity']),
        'teamArticleMetadata': !exists(json, 'teamArticleMetadata') ? undefined : TeamArticleMetadataFromJSON(json['teamArticleMetadata']),
    };
}

export function FeedArticleToJSON(value?: FeedArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'article': ArticleToJSON(value.article),
        'userMetadata': UserMetadataToJSON(value.userMetadata),
        'team': TeamToJSON(value.team),
        'archived': value.archived,
        'recentTeamActivity': RecentTeamActivityToJSON(value.recentTeamActivity),
        'teamArticleMetadata': TeamArticleMetadataToJSON(value.teamArticleMetadata),
    };
}



/* FeedArticleList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */







/**
 * 
 * @export
 * @interface FeedArticleList
 */
export interface FeedArticleList {
    /**
     * 
     * @type {Array<FeedArticle>}
     * @memberof FeedArticleList
     */
    items: Array<FeedArticle>;
    /**
     * 
     * @type {Pagination}
     * @memberof FeedArticleList
     */
    pagination: Pagination;
    /**
     * 
     * @type {AvailableEventLogFilters}
     * @memberof FeedArticleList
     */
    eventLogFilter?: AvailableEventLogFilters;
    /**
     * 
     * @type {AvailableInsightFilters}
     * @memberof FeedArticleList
     */
    insightFilter?: AvailableInsightFilters;
}

export function FeedArticleListFromJSON(json: any): FeedArticleList {
    return FeedArticleListFromJSONTyped(json, false);
}

export function FeedArticleListFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedArticleList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(FeedArticleFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'eventLogFilter': !exists(json, 'eventLogFilter') ? undefined : AvailableEventLogFiltersFromJSON(json['eventLogFilter']),
        'insightFilter': !exists(json, 'insightFilter') ? undefined : AvailableInsightFiltersFromJSON(json['insightFilter']),
    };
}

export function FeedArticleListToJSON(value?: FeedArticleList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(FeedArticleToJSON)),
        'pagination': PaginationToJSON(value.pagination),
        'eventLogFilter': AvailableEventLogFiltersToJSON(value.eventLogFilter),
        'insightFilter': AvailableInsightFiltersToJSON(value.insightFilter),
    };
}



/* FeedArticleResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface FeedArticleResponse
 */
export interface FeedArticleResponse {
    /**
     * 
     * @type {FeedArticle}
     * @memberof FeedArticleResponse
     */
    feedArticle: FeedArticle;
}

export function FeedArticleResponseFromJSON(json: any): FeedArticleResponse {
    return FeedArticleResponseFromJSONTyped(json, false);
}

export function FeedArticleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedArticleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedArticle': FeedArticleFromJSON(json['feedArticle']),
    };
}

export function FeedArticleResponseToJSON(value?: FeedArticleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedArticle': FeedArticleToJSON(value.feedArticle),
    };
}



/* FileInfo.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof FileInfo
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FileInfo
     */
    lastModified?: Date;
    /**
     * 
     * @type {User}
     * @memberof FileInfo
     */
    createdBy?: User;
    /**
     * 
     * @type {User}
     * @memberof FileInfo
     */
    lastModifiedBy?: User;
}

export function FileInfoFromJSON(json: any): FileInfo {
    return FileInfoFromJSONTyped(json, false);
}

export function FileInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'lastModified': !exists(json, 'lastModified') ? undefined : (new Date(json['lastModified'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : UserFromJSON(json['createdBy']),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : UserFromJSON(json['lastModifiedBy']),
    };
}

export function FileInfoToJSON(value?: FileInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'lastModified': value.lastModified === undefined ? undefined : (value.lastModified.toISOString()),
        'createdBy': UserToJSON(value.createdBy),
        'lastModifiedBy': UserToJSON(value.lastModifiedBy),
    };
}



/* FileInfoList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface FileInfoList
 */
export interface FileInfoList {
    /**
     * 
     * @type {Array<FileInfo>}
     * @memberof FileInfoList
     */
    items: Array<FileInfo>;
    /**
     * 
     * @type {Pagination}
     * @memberof FileInfoList
     */
    pagination: Pagination;
}

export function FileInfoListFromJSON(json: any): FileInfoList {
    return FileInfoListFromJSONTyped(json, false);
}

export function FileInfoListFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileInfoList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(FileInfoFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function FileInfoListToJSON(value?: FileInfoList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(FileInfoToJSON)),
        'pagination': PaginationToJSON(value.pagination),
    };
}



/* FileInfoWithS3Url.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface FileInfoWithS3Url
 */
export interface FileInfoWithS3Url {
    /**
     * 
     * @type {string}
     * @memberof FileInfoWithS3Url
     */
    url: string;
    /**
     * 
     * @type {FileInfo}
     * @memberof FileInfoWithS3Url
     */
    fileInfo: FileInfo;
    /**
     * 
     * @type {number}
     * @memberof FileInfoWithS3Url
     */
    timeToLive: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoWithS3Url
     */
    urlType: FileInfoWithS3UrlUrlTypeEnum;
}


/**
 * @export
 */
export const FileInfoWithS3UrlUrlTypeEnum = {
    Get: 'get',
    Put: 'put'
} as const;
export type FileInfoWithS3UrlUrlTypeEnum = typeof FileInfoWithS3UrlUrlTypeEnum[keyof typeof FileInfoWithS3UrlUrlTypeEnum];


export function FileInfoWithS3UrlFromJSON(json: any): FileInfoWithS3Url {
    return FileInfoWithS3UrlFromJSONTyped(json, false);
}

export function FileInfoWithS3UrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileInfoWithS3Url {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'fileInfo': FileInfoFromJSON(json['fileInfo']),
        'timeToLive': json['timeToLive'],
        'urlType': json['urlType'],
    };
}

export function FileInfoWithS3UrlToJSON(value?: FileInfoWithS3Url | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'fileInfo': FileInfoToJSON(value.fileInfo),
        'timeToLive': value.timeToLive,
        'urlType': value.urlType,
    };
}



/* FileInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface FileInput
 */
export interface FileInput {
    /**
     * 
     * @type {string}
     * @memberof FileInput
     */
    filename: string;
}

export function FileInputFromJSON(json: any): FileInput {
    return FileInputFromJSONTyped(json, false);
}

export function FileInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
    };
}

export function FileInputToJSON(value?: FileInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
    };
}



/* Filter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    regions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    publishers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    types?: Array<string>;
    /**
     * 
     * @type {DateFilter}
     * @memberof Filter
     */
    publicationDate?: DateFilter;
    /**
     * 
     * @type {DateFilter}
     * @memberof Filter
     */
    dueDate?: DateFilter;
    /**
     * 
     * @type {DateFilter}
     * @memberof Filter
     */
    performedDate?: DateFilter;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    searchString?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    commentedBy?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    assignees?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    attachedBy?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Filter
     */
    importance?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    teamTags?: Array<string>;
    /**
     * 
     * @type {Array<InsightsSelector>}
     * @memberof Filter
     */
    selector?: Array<InsightsSelector>;
    /**
     * 
     * @type {boolean}
     * @memberof Filter
     */
    uncompletedAssignedOnly?: boolean;
    /**
     * 
     * @type {Array<Exclude>}
     * @memberof Filter
     */
    exclude?: Array<Exclude>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    teams?: Array<string>;
}

export function FilterFromJSON(json: any): Filter {
    return FilterFromJSONTyped(json, false);
}

export function FilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Filter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regions': !exists(json, 'regions') ? undefined : json['regions'],
        'publishers': !exists(json, 'publishers') ? undefined : json['publishers'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'types': !exists(json, 'types') ? undefined : json['types'],
        'publicationDate': !exists(json, 'publicationDate') ? undefined : DateFilterFromJSON(json['publicationDate']),
        'dueDate': !exists(json, 'dueDate') ? undefined : DateFilterFromJSON(json['dueDate']),
        'performedDate': !exists(json, 'performedDate') ? undefined : DateFilterFromJSON(json['performedDate']),
        'searchString': !exists(json, 'searchString') ? undefined : json['searchString'],
        'commentedBy': !exists(json, 'commentedBy') ? undefined : json['commentedBy'],
        'assignees': !exists(json, 'assignees') ? undefined : json['assignees'],
        'attachedBy': !exists(json, 'attachedBy') ? undefined : json['attachedBy'],
        'importance': !exists(json, 'importance') ? undefined : json['importance'],
        'teamTags': !exists(json, 'teamTags') ? undefined : json['teamTags'],
        'selector': !exists(json, 'selector') ? undefined : ((json['selector'] as Array<any>).map(InsightsSelectorFromJSON)),
        'uncompletedAssignedOnly': !exists(json, 'uncompletedAssignedOnly') ? undefined : json['uncompletedAssignedOnly'],
        'exclude': !exists(json, 'exclude') ? undefined : ((json['exclude'] as Array<any>).map(ExcludeFromJSON)),
        'teams': !exists(json, 'teams') ? undefined : json['teams'],
    };
}

export function FilterToJSON(value?: Filter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regions': value.regions,
        'publishers': value.publishers,
        'tags': value.tags,
        'types': value.types,
        'publicationDate': DateFilterToJSON(value.publicationDate),
        'dueDate': DateFilterToJSON(value.dueDate),
        'performedDate': DateFilterToJSON(value.performedDate),
        'searchString': value.searchString,
        'commentedBy': value.commentedBy,
        'assignees': value.assignees,
        'attachedBy': value.attachedBy,
        'importance': value.importance,
        'teamTags': value.teamTags,
        'selector': value.selector === undefined ? undefined : ((value.selector as Array<any>).map(InsightsSelectorToJSON)),
        'uncompletedAssignedOnly': value.uncompletedAssignedOnly,
        'exclude': value.exclude === undefined ? undefined : ((value.exclude as Array<any>).map(ExcludeToJSON)),
        'teams': value.teams,
    };
}



/* FilterInputCreate.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface FilterInputCreate
 */
export interface FilterInputCreate {
    /**
     * 
     * @type {string}
     * @memberof FilterInputCreate
     */
    name: string;
    /**
     * 
     * @type {Filter}
     * @memberof FilterInputCreate
     */
    filter: Filter;
    /**
     * 
     * @type {FilterType}
     * @memberof FilterInputCreate
     */
    filterType: FilterType;
}

export function FilterInputCreateFromJSON(json: any): FilterInputCreate {
    return FilterInputCreateFromJSONTyped(json, false);
}

export function FilterInputCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterInputCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'filter': FilterFromJSON(json['filter']),
        'filterType': FilterTypeFromJSON(json['filterType']),
    };
}

export function FilterInputCreateToJSON(value?: FilterInputCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'filter': FilterToJSON(value.filter),
        'filterType': FilterTypeToJSON(value.filterType),
    };
}



/* FilterInputUpdate.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface FilterInputUpdate
 */
export interface FilterInputUpdate {
    /**
     * 
     * @type {string}
     * @memberof FilterInputUpdate
     */
    name: string;
    /**
     * 
     * @type {Filter}
     * @memberof FilterInputUpdate
     */
    filter?: Filter;
}

export function FilterInputUpdateFromJSON(json: any): FilterInputUpdate {
    return FilterInputUpdateFromJSONTyped(json, false);
}

export function FilterInputUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterInputUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'filter': !exists(json, 'filter') ? undefined : FilterFromJSON(json['filter']),
    };
}

export function FilterInputUpdateToJSON(value?: FilterInputUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'filter': FilterToJSON(value.filter),
    };
}



/* FilterListItem.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface FilterListItem
 */
export interface FilterListItem {
    /**
     * 
     * @type {string}
     * @memberof FilterListItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FilterListItem
     */
    name: string;
}

export function FilterListItemFromJSON(json: any): FilterListItem {
    return FilterListItemFromJSONTyped(json, false);
}

export function FilterListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function FilterListItemToJSON(value?: FilterListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}



/* FilterSearchField.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FilterSearchField = {
    Regions: 'regions',
    Publishers: 'publishers',
    Types: 'types',
    Tags: 'tags'
} as const;
export type FilterSearchField = typeof FilterSearchField[keyof typeof FilterSearchField];


export function FilterSearchFieldFromJSON(json: any): FilterSearchField {
    return FilterSearchFieldFromJSONTyped(json, false);
}

export function FilterSearchFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterSearchField {
    return json as FilterSearchField;
}

export function FilterSearchFieldToJSON(value?: FilterSearchField | null): any {
    return value as any;
}



/* FilterSearchResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface FilterSearchResult
 */
export interface FilterSearchResult {
    /**
     * 
     * @type {boolean}
     * @memberof FilterSearchResult
     */
    truncated: boolean;
    /**
     * 
     * @type {string}
     * @memberof FilterSearchResult
     */
    lastId?: string;
    /**
     * 
     * @type {Array<FilterListItem>}
     * @memberof FilterSearchResult
     */
    items: Array<FilterListItem>;
}

export function FilterSearchResultFromJSON(json: any): FilterSearchResult {
    return FilterSearchResultFromJSONTyped(json, false);
}

export function FilterSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'truncated': json['truncated'],
        'lastId': !exists(json, 'lastId') ? undefined : json['lastId'],
        'items': ((json['items'] as Array<any>).map(FilterListItemFromJSON)),
    };
}

export function FilterSearchResultToJSON(value?: FilterSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'truncated': value.truncated,
        'lastId': value.lastId,
        'items': ((value.items as Array<any>).map(FilterListItemToJSON)),
    };
}



/* FilterType.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FilterType = {
    ArticleFilter: 'articleFilter',
    InsightFilter: 'insightFilter'
} as const;
export type FilterType = typeof FilterType[keyof typeof FilterType];


export function FilterTypeFromJSON(json: any): FilterType {
    return FilterTypeFromJSONTyped(json, false);
}

export function FilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterType {
    return json as FilterType;
}

export function FilterTypeToJSON(value?: FilterType | null): any {
    return value as any;
}



/* GlobalActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GlobalActions = {
    ViewPageCompaniesAdministration: 'regeye:global:ViewPageCompaniesAdministration',
    ViewGlobalSearch: 'regeye:global:ViewGlobalSearch',
    ViewNavPersonal: 'regeye:global:ViewNavPersonal',
    MyTeamList: 'regeye:global:MyTeamList',
    GetAllTeamActions: 'regeye:global:GetAllTeamActions',
    GetUserActivityLogArticles: 'regeye:global:GetUserActivityLogArticles',
    ArticleList: 'regeye:global:ArticleList',
    PublisherList: 'regeye:global:PublisherList',
    GetPersonalFilters: 'regeye:global:GetPersonalFilters',
    GetPersonalFilter: 'regeye:global:GetPersonalFilter',
    CreatePersonalFilter: 'regeye:global:CreatePersonalFilter',
    UserInvitationList: 'regeye:global:UserInvitationList',
    ReadMetadataGet: 'regeye:global:ReadMetadataGet',
    ArticleGet: 'regeye:global:ArticleGet',
    BookmarkChange: 'regeye:global:BookmarkChange',
    ReadChange: 'regeye:global:ReadChange',
    InvitationAccept: 'regeye:global:InvitationAccept',
    InvitationReject: 'regeye:global:InvitationReject',
    DeletePersonalFilter: 'regeye:global:DeletePersonalFilter',
    UpdatePersonalFilter: 'regeye:global:UpdatePersonalFilter',
    CompanyList: 'regeye:global:CompanyList',
    CompanyCreate: 'regeye:global:CompanyCreate',
    CountAssignments: 'regeye:global:CountAssignments',
    TranslateFilter: 'regeye:global:TranslateFilter',
    TeamsDashboard: 'regeye:global:TeamsDashboard'
} as const;
export type GlobalActions = typeof GlobalActions[keyof typeof GlobalActions];


export function GlobalActionsFromJSON(json: any): GlobalActions {
    return GlobalActionsFromJSONTyped(json, false);
}

export function GlobalActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalActions {
    return json as GlobalActions;
}

export function GlobalActionsToJSON(value?: GlobalActions | null): any {
    return value as any;
}



/* Importance.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface Importance
 */
export interface Importance {
    /**
     * 
     * @type {number}
     * @memberof Importance
     */
    importance: number;
    /**
     * 
     * @type {User}
     * @memberof Importance
     */
    setByUser?: User;
}

export function ImportanceFromJSON(json: any): Importance {
    return ImportanceFromJSONTyped(json, false);
}

export function ImportanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Importance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importance': json['importance'],
        'setByUser': !exists(json, 'setByUser') ? undefined : UserFromJSON(json['setByUser']),
    };
}

export function ImportanceToJSON(value?: Importance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importance': value.importance,
        'setByUser': UserToJSON(value.setByUser),
    };
}



/* ImportanceInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface ImportanceInput
 */
export interface ImportanceInput {
    /**
     * 
     * @type {number}
     * @memberof ImportanceInput
     */
    importance: number;
}

export function ImportanceInputFromJSON(json: any): ImportanceInput {
    return ImportanceInputFromJSONTyped(json, false);
}

export function ImportanceInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportanceInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importance': json['importance'],
    };
}

export function ImportanceInputToJSON(value?: ImportanceInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importance': value.importance,
    };
}



/* InitData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface InitData
 */
export interface InitData {
    /**
     * 
     * @type {Array<GlobalActions>}
     * @memberof InitData
     */
    globalActions: Array<GlobalActions>;
    /**
     * 
     * @type {User}
     * @memberof InitData
     */
    user: User;
}

export function InitDataFromJSON(json: any): InitData {
    return InitDataFromJSONTyped(json, false);
}

export function InitDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalActions': ((json['globalActions'] as Array<any>).map(GlobalActionsFromJSON)),
        'user': UserFromJSON(json['user']),
    };
}

export function InitDataToJSON(value?: InitData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'globalActions': ((value.globalActions as Array<any>).map(GlobalActionsToJSON)),
        'user': UserToJSON(value.user),
    };
}



/* InsightsSelector.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InsightsSelector = {
    WithAssignees: 'withAssignees',
    WithComments: 'withComments',
    WithDueDate: 'withDueDate',
    WithImportance: 'withImportance',
    WithTeamTags: 'withTeamTags',
    WithAttachments: 'withAttachments'
} as const;
export type InsightsSelector = typeof InsightsSelector[keyof typeof InsightsSelector];


export function InsightsSelectorFromJSON(json: any): InsightsSelector {
    return InsightsSelectorFromJSONTyped(json, false);
}

export function InsightsSelectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsSelector {
    return json as InsightsSelector;
}

export function InsightsSelectorToJSON(value?: InsightsSelector | null): any {
    return value as any;
}



/* InvitationsCreate.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface InvitationsCreate
 */
export interface InvitationsCreate {
    /**
     * 
     * @type {string}
     * @memberof InvitationsCreate
     */
    message?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationsCreate
     */
    usernames: Array<string>;
}

export function InvitationsCreateFromJSON(json: any): InvitationsCreate {
    return InvitationsCreateFromJSONTyped(json, false);
}

export function InvitationsCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationsCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'usernames': json['usernames'],
    };
}

export function InvitationsCreateToJSON(value?: InvitationsCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'usernames': value.usernames,
    };
}



/* InvitationsCreateResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface InvitationsCreateResult
 */
export interface InvitationsCreateResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationsCreateResult
     */
    failed: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationsCreateResult
     */
    succeed: Array<string>;
}

export function InvitationsCreateResultFromJSON(json: any): InvitationsCreateResult {
    return InvitationsCreateResultFromJSONTyped(json, false);
}

export function InvitationsCreateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationsCreateResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failed': json['failed'],
        'succeed': json['succeed'],
    };
}

export function InvitationsCreateResultToJSON(value?: InvitationsCreateResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failed': value.failed,
        'succeed': value.succeed,
    };
}



/* NotificationSetting.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NotificationSetting = {
    Assigned: 'Assigned',
    CommentReply: 'CommentReply',
    CommentMention: 'CommentMention',
    TeamInvite: 'TeamInvite',
    TeamNewMember: 'TeamNewMember',
    TeamRemovedMember: 'TeamRemovedMember',
    TeamNotify: 'TeamNotify',
    Unassigned: 'Unassigned'
} as const;
export type NotificationSetting = typeof NotificationSetting[keyof typeof NotificationSetting];


export function NotificationSettingFromJSON(json: any): NotificationSetting {
    return NotificationSettingFromJSONTyped(json, false);
}

export function NotificationSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSetting {
    return json as NotificationSetting;
}

export function NotificationSettingToJSON(value?: NotificationSetting | null): any {
    return value as any;
}



/* NotificationSettingsList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface NotificationSettingsList
 */
export interface NotificationSettingsList {
    /**
     * 
     * @type {Array<NotificationSetting>}
     * @memberof NotificationSettingsList
     */
    items: Array<NotificationSetting>;
}

export function NotificationSettingsListFromJSON(json: any): NotificationSettingsList {
    return NotificationSettingsListFromJSONTyped(json, false);
}

export function NotificationSettingsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettingsList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(NotificationSettingFromJSON)),
    };
}

export function NotificationSettingsListToJSON(value?: NotificationSettingsList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(NotificationSettingToJSON)),
    };
}



/* OrderOptions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderOptions = {
    PublisherAsc: 'publisherAsc',
    PublisherDesc: 'publisherDesc',
    TitleAsc: 'titleAsc',
    TitleDesc: 'titleDesc',
    PubDateAsc: 'pubDateAsc',
    PubDateDesc: 'pubDateDesc',
    DescriptionAsc: 'descriptionAsc',
    DescriptionDesc: 'descriptionDesc',
    CreatedAsc: 'createdAsc',
    CreatedDesc: 'createdDesc',
    ActionAsc: 'actionAsc',
    ActionDesc: 'actionDesc',
    ImportanceAsc: 'importanceAsc',
    ImportanceDesc: 'importanceDesc',
    DueAsc: 'dueAsc',
    DueDesc: 'dueDesc',
    ArticleTagsAsc: 'articleTagsAsc',
    ArticleTagsDesc: 'articleTagsDesc',
    ArticleTypesAsc: 'articleTypesAsc',
    ArticleTypesDesc: 'articleTypesDesc',
    TeamTagsAsc: 'teamTagsAsc',
    TeamTagsDesc: 'teamTagsDesc',
    AssignedAsc: 'assignedAsc',
    AssignedDesc: 'assignedDesc',
    TeamAsc: 'teamAsc',
    TeamDesc: 'teamDesc'
} as const;
export type OrderOptions = typeof OrderOptions[keyof typeof OrderOptions];


export function OrderOptionsFromJSON(json: any): OrderOptions {
    return OrderOptionsFromJSONTyped(json, false);
}

export function OrderOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderOptions {
    return json as OrderOptions;
}

export function OrderOptionsToJSON(value?: OrderOptions | null): any {
    return value as any;
}



/* Pagination.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    totalResults: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    skip: number;
}

export function PaginationFromJSON(json: any): Pagination {
    return PaginationFromJSONTyped(json, false);
}

export function PaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalResults': json['totalResults'],
        'limit': json['limit'],
        'skip': json['skip'],
    };
}

export function PaginationToJSON(value?: Pagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalResults': value.totalResults,
        'limit': value.limit,
        'skip': value.skip,
    };
}



/* Publisher.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface Publisher
 */
export interface Publisher {
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    description?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Publisher
     */
    articleTypes: Array<Tag>;
    /**
     * 
     * @type {Region}
     * @memberof Publisher
     */
    region: Region;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    abbreviation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Publisher
     */
    subscribedToEntirePublisher?: boolean;
}

export function PublisherFromJSON(json: any): Publisher {
    return PublisherFromJSONTyped(json, false);
}

export function PublisherFromJSONTyped(json: any, ignoreDiscriminator: boolean): Publisher {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'articleTypes': ((json['articleTypes'] as Array<any>).map(TagFromJSON)),
        'region': RegionFromJSON(json['region']),
        'abbreviation': !exists(json, 'abbreviation') ? undefined : json['abbreviation'],
        'subscribedToEntirePublisher': !exists(json, 'subscribedToEntirePublisher') ? undefined : json['subscribedToEntirePublisher'],
    };
}

export function PublisherToJSON(value?: Publisher | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'link': value.link,
        'description': value.description,
        'articleTypes': ((value.articleTypes as Array<any>).map(TagToJSON)),
        'region': RegionToJSON(value.region),
        'abbreviation': value.abbreviation,
        'subscribedToEntirePublisher': value.subscribedToEntirePublisher,
    };
}



/* PublisherList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface PublisherList
 */
export interface PublisherList {
    /**
     * 
     * @type {Array<Publisher>}
     * @memberof PublisherList
     */
    items: Array<Publisher>;
}

export function PublisherListFromJSON(json: any): PublisherList {
    return PublisherListFromJSONTyped(json, false);
}

export function PublisherListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublisherList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(PublisherFromJSON)),
    };
}

export function PublisherListToJSON(value?: PublisherList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(PublisherToJSON)),
    };
}



/* RecentTeamActivity.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






/**
 * 
 * @export
 * @interface RecentTeamActivity
 */
export interface RecentTeamActivity {
    /**
     * 
     * @type {string}
     * @memberof RecentTeamActivity
     */
    logId: string;
    /**
     * 
     * @type {TeamAction}
     * @memberof RecentTeamActivity
     */
    action: TeamAction;
    /**
     * 
     * @type {User}
     * @memberof RecentTeamActivity
     */
    createdByUser?: User;
    /**
     * 
     * @type {User}
     * @memberof RecentTeamActivity
     */
    targetUser?: User;
    /**
     * 
     * @type {string}
     * @memberof RecentTeamActivity
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof RecentTeamActivity
     */
    targetValue?: string;
    /**
     * 
     * @type {Date}
     * @memberof RecentTeamActivity
     */
    created: Date;
    /**
     * 
     * @type {DocumentLocation}
     * @memberof RecentTeamActivity
     */
    documentLocation?: DocumentLocation;
}

export function RecentTeamActivityFromJSON(json: any): RecentTeamActivity {
    return RecentTeamActivityFromJSONTyped(json, false);
}

export function RecentTeamActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecentTeamActivity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logId': json['logId'],
        'action': TeamActionFromJSON(json['action']),
        'createdByUser': !exists(json, 'createdByUser') ? undefined : UserFromJSON(json['createdByUser']),
        'targetUser': !exists(json, 'targetUser') ? undefined : UserFromJSON(json['targetUser']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'targetValue': !exists(json, 'targetValue') ? undefined : json['targetValue'],
        'created': (new Date(json['created'])),
        'documentLocation': !exists(json, 'documentLocation') ? undefined : DocumentLocationFromJSON(json['documentLocation']),
    };
}

export function RecentTeamActivityToJSON(value?: RecentTeamActivity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logId': value.logId,
        'action': TeamActionToJSON(value.action),
        'createdByUser': UserToJSON(value.createdByUser),
        'targetUser': UserToJSON(value.targetUser),
        'value': value.value,
        'targetValue': value.targetValue,
        'created': (value.created.toISOString()),
        'documentLocation': DocumentLocationToJSON(value.documentLocation),
    };
}



/* Region.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    name: string;
}

export function RegionFromJSON(json: any): Region {
    return RegionFromJSONTyped(json, false);
}

export function RegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Region {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function RegionToJSON(value?: Region | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}



/* StoredFilter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface StoredFilter
 */
export interface StoredFilter {
    /**
     * 
     * @type {string}
     * @memberof StoredFilter
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StoredFilter
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoredFilter
     */
    personalFilter: boolean;
    /**
     * 
     * @type {FilterType}
     * @memberof StoredFilter
     */
    filterType: FilterType;
    /**
     * 
     * @type {Filter}
     * @memberof StoredFilter
     */
    filter: Filter;
    /**
     * 
     * @type {string}
     * @memberof StoredFilter
     */
    teamId?: string;
}

export function StoredFilterFromJSON(json: any): StoredFilter {
    return StoredFilterFromJSONTyped(json, false);
}

export function StoredFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoredFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'personalFilter': json['personalFilter'],
        'filterType': FilterTypeFromJSON(json['filterType']),
        'filter': FilterFromJSON(json['filter']),
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
    };
}

export function StoredFilterToJSON(value?: StoredFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'personalFilter': value.personalFilter,
        'filterType': FilterTypeToJSON(value.filterType),
        'filter': FilterToJSON(value.filter),
        'teamId': value.teamId,
    };
}



/* StoredFilterList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface StoredFilterList
 */
export interface StoredFilterList {
    /**
     * 
     * @type {Array<StoredFilter>}
     * @memberof StoredFilterList
     */
    items: Array<StoredFilter>;
}

export function StoredFilterListFromJSON(json: any): StoredFilterList {
    return StoredFilterListFromJSONTyped(json, false);
}

export function StoredFilterListFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoredFilterList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(StoredFilterFromJSON)),
    };
}

export function StoredFilterListToJSON(value?: StoredFilterList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(StoredFilterToJSON)),
    };
}



/* SubscriptionUpdate.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface SubscriptionUpdate
 */
export interface SubscriptionUpdate {
    /**
     * 
     * @type {Array<SubscriptionUpdateItemsInner>}
     * @memberof SubscriptionUpdate
     */
    items: Array<SubscriptionUpdateItemsInner>;
}

export function SubscriptionUpdateFromJSON(json: any): SubscriptionUpdate {
    return SubscriptionUpdateFromJSONTyped(json, false);
}

export function SubscriptionUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SubscriptionUpdateItemsInnerFromJSON)),
    };
}

export function SubscriptionUpdateToJSON(value?: SubscriptionUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(SubscriptionUpdateItemsInnerToJSON)),
    };
}



/* SubscriptionUpdateItemsInner.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface SubscriptionUpdateItemsInner
 */
export interface SubscriptionUpdateItemsInner {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionUpdateItemsInner
     */
    publisherId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionUpdateItemsInner
     */
    articleTypeIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionUpdateItemsInner
     */
    subscribeToEntirePublisher?: boolean;
}

export function SubscriptionUpdateItemsInnerFromJSON(json: any): SubscriptionUpdateItemsInner {
    return SubscriptionUpdateItemsInnerFromJSONTyped(json, false);
}

export function SubscriptionUpdateItemsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionUpdateItemsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publisherId': json['publisherId'],
        'articleTypeIds': !exists(json, 'articleTypeIds') ? undefined : json['articleTypeIds'],
        'subscribeToEntirePublisher': !exists(json, 'subscribeToEntirePublisher') ? undefined : json['subscribeToEntirePublisher'],
    };
}

export function SubscriptionUpdateItemsInnerToJSON(value?: SubscriptionUpdateItemsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publisherId': value.publisherId,
        'articleTypeIds': value.articleTypeIds,
        'subscribeToEntirePublisher': value.subscribeToEntirePublisher,
    };
}



/* Tag.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    subscribed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    teamName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Tag
     */
    lastUpdate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tag
     */
    firstUpdate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    active?: boolean;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'subscribed': !exists(json, 'subscribed') ? undefined : json['subscribed'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
        'lastUpdate': !exists(json, 'lastUpdate') ? undefined : (new Date(json['lastUpdate'])),
        'firstUpdate': !exists(json, 'firstUpdate') ? undefined : (new Date(json['firstUpdate'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'subscribed': value.subscribed,
        'teamName': value.teamName,
        'lastUpdate': value.lastUpdate === undefined ? undefined : (value.lastUpdate.toISOString().substr(0,10)),
        'firstUpdate': value.firstUpdate === undefined ? undefined : (value.firstUpdate.toISOString().substr(0,10)),
        'description': value.description,
        'active': value.active,
    };
}



/* TagInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface TagInput
 */
export interface TagInput {
    /**
     * 
     * @type {string}
     * @memberof TagInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TagInput
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TagInput
     */
    active?: boolean;
}

export function TagInputFromJSON(json: any): TagInput {
    return TagInputFromJSONTyped(json, false);
}

export function TagInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function TagInputToJSON(value?: TagInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'active': value.active,
    };
}



/* TagInputList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TagInputList
 */
export interface TagInputList {
    /**
     * 
     * @type {Array<TagInput>}
     * @memberof TagInputList
     */
    items: Array<TagInput>;
}

export function TagInputListFromJSON(json: any): TagInputList {
    return TagInputListFromJSONTyped(json, false);
}

export function TagInputListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagInputList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(TagInputFromJSON)),
    };
}

export function TagInputListToJSON(value?: TagInputList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(TagInputToJSON)),
    };
}



/* Team.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    name: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Team
     */
    members?: Array<User>;
    /**
     * 
     * @type {Company}
     * @memberof Team
     */
    company?: Company;
    /**
     * 
     * @type {Array<TeamActions>}
     * @memberof Team
     */
    teamActions?: Array<TeamActions>;
}

export function TeamFromJSON(json: any): Team {
    return TeamFromJSONTyped(json, false);
}

export function TeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): Team {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(UserFromJSON)),
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'teamActions': !exists(json, 'teamActions') ? undefined : ((json['teamActions'] as Array<any>).map(TeamActionsFromJSON)),
    };
}

export function TeamToJSON(value?: Team | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(UserToJSON)),
        'company': CompanyToJSON(value.company),
        'teamActions': value.teamActions === undefined ? undefined : ((value.teamActions as Array<any>).map(TeamActionsToJSON)),
    };
}



/* TeamAction.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TeamAction = {
    Commented: 'COMMENTED',
    EditedComment: 'EDITED COMMENT',
    RemovedComment: 'REMOVED COMMENT',
    Assigned: 'ASSIGNED',
    CompletedAssigned: 'COMPLETED ASSIGNED',
    UncompletedAssigned: 'UNCOMPLETED ASSIGNED',
    Unassigned: 'UNASSIGNED',
    SetImportance: 'SET IMPORTANCE',
    UpdatedImportance: 'UPDATED IMPORTANCE',
    RemovedImportance: 'REMOVED IMPORTANCE',
    SetDueDate: 'SET DUE DATE',
    UpdatedDueDate: 'UPDATED DUE DATE',
    RemovedDueDate: 'REMOVED DUE DATE',
    Tagged: 'TAGGED',
    Untagged: 'UNTAGGED',
    Attached: 'ATTACHED',
    Unattached: 'UNATTACHED',
    Archived: 'ARCHIVED',
    Unarchived: 'UNARCHIVED',
    Notified: 'NOTIFIED'
} as const;
export type TeamAction = typeof TeamAction[keyof typeof TeamAction];


export function TeamActionFromJSON(json: any): TeamAction {
    return TeamActionFromJSONTyped(json, false);
}

export function TeamActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamAction {
    return json as TeamAction;
}

export function TeamActionToJSON(value?: TeamAction | null): any {
    return value as any;
}



/* TeamActions.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TeamActions = {
    ViewPageTeamsAdministration: 'regeye:team:ViewPageTeamsAdministration',
    TeamGet: 'regeye:team:TeamGet',
    MemberList: 'regeye:team:MemberList',
    TeamLeave: 'regeye:team:TeamLeave',
    MemberDelete: 'regeye:team:MemberDelete',
    GetTeamActionsArticles: 'regeye:team:GetTeamActionsArticles',
    TagList: 'regeye:team:TagList',
    TagCreate: 'regeye:team:TagCreate',
    TagGet: 'regeye:team:TagGet',
    TagEdit: 'regeye:team:TagEdit',
    TagDelete: 'regeye:team:TagDelete',
    ArticleTeamSubscribedList: 'regeye:team:ArticleTeamSubscribedList',
    GetTeamActivityLogArticles: 'regeye:team:GetTeamActivityLogArticles',
    GetTeamActivityLogForArticle: 'regeye:team:GetTeamActivityLogForArticle',
    TeamFileUpload: 'regeye:team:TeamFileUpload',
    TeamFileList: 'regeye:team:TeamFileList',
    TeamFileDownload: 'regeye:team:TeamFileDownload',
    TeamFileUpdate: 'regeye:team:TeamFileUpdate',
    TeamFileDelete: 'regeye:team:TeamFileDelete',
    TeamMetadataGet: 'regeye:team:TeamMetadataGet',
    TeamSubscriptionList: 'regeye:team:TeamSubscriptionList',
    TeamSubscriptionUpdate: 'regeye:team:TeamSubscriptionUpdate',
    ArticleAttachmentChange: 'regeye:team:ArticleAttachmentChange',
    ArticleTagChange: 'regeye:team:ArticleTagChange',
    ArticleArchiveChange: 'regeye:team:ArticleArchiveChange',
    ArticleDueDateChange: 'regeye:team:ArticleDueDateChange',
    ArticleAssigneeChange: 'regeye:team:ArticleAssigneeChange',
    ArticleImportanceChange: 'regeye:team:ArticleImportanceChange',
    ArticleCommentCreate: 'regeye:team:ArticleCommentCreate',
    ArticleCommentGet: 'regeye:team:ArticleCommentGet',
    ArticleCommentUpdate: 'regeye:team:ArticleCommentUpdate',
    ArticleCommentDelete: 'regeye:team:ArticleCommentDelete',
    GetTeamFilters: 'regeye:team:GetTeamFilters',
    CreateTeamFilter: 'regeye:team:CreateTeamFilter',
    DeleteTeamFilter: 'regeye:team:DeleteTeamFilter',
    UpdateTeamFilter: 'regeye:team:UpdateTeamFilter',
    GetTeamFilter: 'regeye:team:GetTeamFilter',
    TeamInvitationList: 'regeye:team:TeamInvitationList',
    TeamInvitationCreate: 'regeye:team:TeamInvitationCreate',
    TeamInvitationGet: 'regeye:team:TeamInvitationGet',
    TeamInvitationDelete: 'regeye:team:TeamInvitationDelete',
    CountOverdueArticles: 'regeye:team:CountOverdueArticles',
    UpdateMemberRole: 'regeye:team:UpdateMemberRole',
    ExcelExport: 'regeye:team:ExcelExport',
    ArticleNotify: 'regeye:team:ArticleNotify'
} as const;
export type TeamActions = typeof TeamActions[keyof typeof TeamActions];


export function TeamActionsFromJSON(json: any): TeamActions {
    return TeamActionsFromJSONTyped(json, false);
}

export function TeamActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamActions {
    return json as TeamActions;
}

export function TeamActionsToJSON(value?: TeamActions | null): any {
    return value as any;
}



/* TeamArticleMetadata.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */











/**
 * 
 * @export
 * @interface TeamArticleMetadata
 */
export interface TeamArticleMetadata {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TeamArticleMetadata
     */
    tags: Array<Tag>;
    /**
     * 
     * @type {Array<AssignedUser>}
     * @memberof TeamArticleMetadata
     */
    assigned: Array<AssignedUser>;
    /**
     * 
     * @type {Importance}
     * @memberof TeamArticleMetadata
     */
    importance?: Importance;
    /**
     * 
     * @type {DueDate}
     * @memberof TeamArticleMetadata
     */
    dueDate?: DueDate;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof TeamArticleMetadata
     */
    comments: Array<Comment>;
    /**
     * 
     * @type {Array<TeamAttachment>}
     * @memberof TeamArticleMetadata
     */
    attachments: Array<TeamAttachment>;
    /**
     * 
     * @type {Archived}
     * @memberof TeamArticleMetadata
     */
    archived: Archived;
    /**
     * 
     * @type {ArticleNotify}
     * @memberof TeamArticleMetadata
     */
    notified: ArticleNotify;
}

export function TeamArticleMetadataFromJSON(json: any): TeamArticleMetadata {
    return TeamArticleMetadataFromJSONTyped(json, false);
}

export function TeamArticleMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamArticleMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
        'assigned': ((json['assigned'] as Array<any>).map(AssignedUserFromJSON)),
        'importance': !exists(json, 'importance') ? undefined : ImportanceFromJSON(json['importance']),
        'dueDate': !exists(json, 'dueDate') ? undefined : DueDateFromJSON(json['dueDate']),
        'comments': ((json['comments'] as Array<any>).map(CommentFromJSON)),
        'attachments': ((json['attachments'] as Array<any>).map(TeamAttachmentFromJSON)),
        'archived': ArchivedFromJSON(json['archived']),
        'notified': ArticleNotifyFromJSON(json['notified']),
    };
}

export function TeamArticleMetadataToJSON(value?: TeamArticleMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': ((value.tags as Array<any>).map(TagToJSON)),
        'assigned': ((value.assigned as Array<any>).map(AssignedUserToJSON)),
        'importance': ImportanceToJSON(value.importance),
        'dueDate': DueDateToJSON(value.dueDate),
        'comments': ((value.comments as Array<any>).map(CommentToJSON)),
        'attachments': ((value.attachments as Array<any>).map(TeamAttachmentToJSON)),
        'archived': ArchivedToJSON(value.archived),
        'notified': ArticleNotifyToJSON(value.notified),
    };
}



/* TeamAttachment.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface TeamAttachment
 */
export interface TeamAttachment {
    /**
     * 
     * @type {string}
     * @memberof TeamAttachment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeamAttachment
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof TeamAttachment
     */
    created: Date;
    /**
     * 
     * @type {User}
     * @memberof TeamAttachment
     */
    createdBy: User;
    /**
     * 
     * @type {DocumentLocation}
     * @memberof TeamAttachment
     */
    documentLocation?: DocumentLocation;
}

export function TeamAttachmentFromJSON(json: any): TeamAttachment {
    return TeamAttachmentFromJSONTyped(json, false);
}

export function TeamAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'created': (new Date(json['created'])),
        'createdBy': UserFromJSON(json['createdBy']),
        'documentLocation': !exists(json, 'documentLocation') ? undefined : DocumentLocationFromJSON(json['documentLocation']),
    };
}

export function TeamAttachmentToJSON(value?: TeamAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'created': (value.created.toISOString()),
        'createdBy': UserToJSON(value.createdBy),
        'documentLocation': DocumentLocationToJSON(value.documentLocation),
    };
}



/* TeamDashboard.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamDashboard
 */
export interface TeamDashboard {
    /**
     * 
     * @type {Array<TeamDashboardData>}
     * @memberof TeamDashboard
     */
    items: Array<TeamDashboardData>;
}

export function TeamDashboardFromJSON(json: any): TeamDashboard {
    return TeamDashboardFromJSONTyped(json, false);
}

export function TeamDashboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamDashboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(TeamDashboardDataFromJSON)),
    };
}

export function TeamDashboardToJSON(value?: TeamDashboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(TeamDashboardDataToJSON)),
    };
}



/* TeamDashboardData.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface TeamDashboardData
 */
export interface TeamDashboardData {
    /**
     * 
     * @type {Team}
     * @memberof TeamDashboardData
     */
    team: Team;
    /**
     * 
     * @type {Array<DashboardArticle>}
     * @memberof TeamDashboardData
     */
    overdueArticles?: Array<DashboardArticle>;
    /**
     * 
     * @type {Array<DashboardArticle>}
     * @memberof TeamDashboardData
     */
    assignedArticles?: Array<DashboardArticle>;
}

export function TeamDashboardDataFromJSON(json: any): TeamDashboardData {
    return TeamDashboardDataFromJSONTyped(json, false);
}

export function TeamDashboardDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamDashboardData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'team': TeamFromJSON(json['team']),
        'overdueArticles': !exists(json, 'overdueArticles') ? undefined : ((json['overdueArticles'] as Array<any>).map(DashboardArticleFromJSON)),
        'assignedArticles': !exists(json, 'assignedArticles') ? undefined : ((json['assignedArticles'] as Array<any>).map(DashboardArticleFromJSON)),
    };
}

export function TeamDashboardDataToJSON(value?: TeamDashboardData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'team': TeamToJSON(value.team),
        'overdueArticles': value.overdueArticles === undefined ? undefined : ((value.overdueArticles as Array<any>).map(DashboardArticleToJSON)),
        'assignedArticles': value.assignedArticles === undefined ? undefined : ((value.assignedArticles as Array<any>).map(DashboardArticleToJSON)),
    };
}



/* TeamInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface TeamInput
 */
export interface TeamInput {
    /**
     * 
     * @type {string}
     * @memberof TeamInput
     */
    teamName: string;
}

export function TeamInputFromJSON(json: any): TeamInput {
    return TeamInputFromJSONTyped(json, false);
}

export function TeamInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamName': json['teamName'],
    };
}

export function TeamInputToJSON(value?: TeamInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamName': value.teamName,
    };
}



/* TeamInvitation.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface TeamInvitation
 */
export interface TeamInvitation {
    /**
     * 
     * @type {string}
     * @memberof TeamInvitation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitation
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitation
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof TeamInvitation
     */
    created: Date;
    /**
     * 
     * @type {User}
     * @memberof TeamInvitation
     */
    createdBy?: User;
    /**
     * 
     * @type {Team}
     * @memberof TeamInvitation
     */
    team: Team;
}

export function TeamInvitationFromJSON(json: any): TeamInvitation {
    return TeamInvitationFromJSONTyped(json, false);
}

export function TeamInvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamInvitation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'message': json['message'],
        'created': (new Date(json['created'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : UserFromJSON(json['createdBy']),
        'team': TeamFromJSON(json['team']),
    };
}

export function TeamInvitationToJSON(value?: TeamInvitation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'message': value.message,
        'created': (value.created.toISOString()),
        'createdBy': UserToJSON(value.createdBy),
        'team': TeamToJSON(value.team),
    };
}



/* TeamInvitationList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamInvitationList
 */
export interface TeamInvitationList {
    /**
     * 
     * @type {Array<TeamInvitation>}
     * @memberof TeamInvitationList
     */
    items: Array<TeamInvitation>;
}

export function TeamInvitationListFromJSON(json: any): TeamInvitationList {
    return TeamInvitationListFromJSONTyped(json, false);
}

export function TeamInvitationListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamInvitationList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(TeamInvitationFromJSON)),
    };
}

export function TeamInvitationListToJSON(value?: TeamInvitationList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(TeamInvitationToJSON)),
    };
}



/* TeamList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamList
 */
export interface TeamList {
    /**
     * 
     * @type {Array<Team>}
     * @memberof TeamList
     */
    items: Array<Team>;
}

export function TeamListFromJSON(json: any): TeamList {
    return TeamListFromJSONTyped(json, false);
}

export function TeamListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(TeamFromJSON)),
    };
}

export function TeamListToJSON(value?: TeamList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(TeamToJSON)),
    };
}



/* TeamMetadataResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamMetadataResponse
 */
export interface TeamMetadataResponse {
    /**
     * 
     * @type {TeamArticleMetadata}
     * @memberof TeamMetadataResponse
     */
    metadata?: TeamArticleMetadata;
}

export function TeamMetadataResponseFromJSON(json: any): TeamMetadataResponse {
    return TeamMetadataResponseFromJSONTyped(json, false);
}

export function TeamMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': !exists(json, 'metadata') ? undefined : TeamArticleMetadataFromJSON(json['metadata']),
    };
}

export function TeamMetadataResponseToJSON(value?: TeamMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': TeamArticleMetadataToJSON(value.metadata),
    };
}



/* TeamReadMetadata.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */






/**
 * 
 * @export
 * @interface TeamReadMetadata
 */
export interface TeamReadMetadata {
    /**
     * 
     * @type {Team}
     * @memberof TeamReadMetadata
     */
    team: Team;
    /**
     * 
     * @type {Array<User>}
     * @memberof TeamReadMetadata
     */
    readByUsers: Array<User>;
    /**
     * 
     * @type {boolean}
     * @memberof TeamReadMetadata
     */
    subscribed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamReadMetadata
     */
    archived: boolean;
    /**
     * 
     * @type {ArticleNotify}
     * @memberof TeamReadMetadata
     */
    notified: ArticleNotify;
}

export function TeamReadMetadataFromJSON(json: any): TeamReadMetadata {
    return TeamReadMetadataFromJSONTyped(json, false);
}

export function TeamReadMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamReadMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'team': TeamFromJSON(json['team']),
        'readByUsers': ((json['readByUsers'] as Array<any>).map(UserFromJSON)),
        'subscribed': json['subscribed'],
        'archived': json['archived'],
        'notified': ArticleNotifyFromJSON(json['notified']),
    };
}

export function TeamReadMetadataToJSON(value?: TeamReadMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'team': TeamToJSON(value.team),
        'readByUsers': ((value.readByUsers as Array<any>).map(UserToJSON)),
        'subscribed': value.subscribed,
        'archived': value.archived,
        'notified': ArticleNotifyToJSON(value.notified),
    };
}



/* TeamReadMetadataList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */





/**
 * 
 * @export
 * @interface TeamReadMetadataList
 */
export interface TeamReadMetadataList {
    /**
     * 
     * @type {UserMetadata}
     * @memberof TeamReadMetadataList
     */
    userMetadata: UserMetadata;
    /**
     * 
     * @type {Array<TeamReadMetadata>}
     * @memberof TeamReadMetadataList
     */
    items: Array<TeamReadMetadata>;
}

export function TeamReadMetadataListFromJSON(json: any): TeamReadMetadataList {
    return TeamReadMetadataListFromJSONTyped(json, false);
}

export function TeamReadMetadataListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamReadMetadataList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userMetadata': UserMetadataFromJSON(json['userMetadata']),
        'items': ((json['items'] as Array<any>).map(TeamReadMetadataFromJSON)),
    };
}

export function TeamReadMetadataListToJSON(value?: TeamReadMetadataList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userMetadata': UserMetadataToJSON(value.userMetadata),
        'items': ((value.items as Array<any>).map(TeamReadMetadataToJSON)),
    };
}



/* TeamReadMetadataResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamReadMetadataResponse
 */
export interface TeamReadMetadataResponse {
    /**
     * 
     * @type {TeamReadMetadataList}
     * @memberof TeamReadMetadataResponse
     */
    metadata: TeamReadMetadataList;
}

export function TeamReadMetadataResponseFromJSON(json: any): TeamReadMetadataResponse {
    return TeamReadMetadataResponseFromJSONTyped(json, false);
}

export function TeamReadMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamReadMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': TeamReadMetadataListFromJSON(json['metadata']),
    };
}

export function TeamReadMetadataResponseToJSON(value?: TeamReadMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': TeamReadMetadataListToJSON(value.metadata),
    };
}



/* TeamResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamResponse
 */
export interface TeamResponse {
    /**
     * 
     * @type {Team}
     * @memberof TeamResponse
     */
    team: Team;
}

export function TeamResponseFromJSON(json: any): TeamResponse {
    return TeamResponseFromJSONTyped(json, false);
}

export function TeamResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'team': TeamFromJSON(json['team']),
    };
}

export function TeamResponseToJSON(value?: TeamResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'team': TeamToJSON(value.team),
    };
}



/* TeamRole.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TeamRole = {
    TeamAdmin: 'TeamAdmin',
    TeamReader: 'TeamReader',
    TeamUser: 'TeamUser'
} as const;
export type TeamRole = typeof TeamRole[keyof typeof TeamRole];


export function TeamRoleFromJSON(json: any): TeamRole {
    return TeamRoleFromJSONTyped(json, false);
}

export function TeamRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamRole {
    return json as TeamRole;
}

export function TeamRoleToJSON(value?: TeamRole | null): any {
    return value as any;
}



/* TeamRoleInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamRoleInput
 */
export interface TeamRoleInput {
    /**
     * 
     * @type {string}
     * @memberof TeamRoleInput
     */
    username: string;
    /**
     * 
     * @type {TeamRole}
     * @memberof TeamRoleInput
     */
    role: TeamRole;
}

export function TeamRoleInputFromJSON(json: any): TeamRoleInput {
    return TeamRoleInputFromJSONTyped(json, false);
}

export function TeamRoleInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamRoleInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'role': TeamRoleFromJSON(json['role']),
    };
}

export function TeamRoleInputToJSON(value?: TeamRoleInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'role': TeamRoleToJSON(value.role),
    };
}



/* TeamTagList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamTagList
 */
export interface TeamTagList {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TeamTagList
     */
    items: Array<Tag>;
}

export function TeamTagListFromJSON(json: any): TeamTagList {
    return TeamTagListFromJSONTyped(json, false);
}

export function TeamTagListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamTagList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(TagFromJSON)),
    };
}

export function TeamTagListToJSON(value?: TeamTagList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(TagToJSON)),
    };
}



/* TeamTagResponse.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamTagResponse
 */
export interface TeamTagResponse {
    /**
     * 
     * @type {Tag}
     * @memberof TeamTagResponse
     */
    teamTag: Tag;
}

export function TeamTagResponseFromJSON(json: any): TeamTagResponse {
    return TeamTagResponseFromJSONTyped(json, false);
}

export function TeamTagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamTagResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamTag': TagFromJSON(json['teamTag']),
    };
}

export function TeamTagResponseToJSON(value?: TeamTagResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamTag': TagToJSON(value.teamTag),
    };
}



/* TeamTagsCreateResult.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TeamTagsCreateResult
 */
export interface TeamTagsCreateResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamTagsCreateResult
     */
    failed: Array<string>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TeamTagsCreateResult
     */
    succeed: Array<Tag>;
}

export function TeamTagsCreateResultFromJSON(json: any): TeamTagsCreateResult {
    return TeamTagsCreateResultFromJSONTyped(json, false);
}

export function TeamTagsCreateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamTagsCreateResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failed': json['failed'],
        'succeed': ((json['succeed'] as Array<any>).map(TagFromJSON)),
    };
}

export function TeamTagsCreateResultToJSON(value?: TeamTagsCreateResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failed': value.failed,
        'succeed': ((value.succeed as Array<any>).map(TagToJSON)),
    };
}



/* TranslatedFilter.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface TranslatedFilter
 */
export interface TranslatedFilter {
    /**
     * 
     * @type {Array<FilterListItem>}
     * @memberof TranslatedFilter
     */
    regions?: Array<FilterListItem>;
    /**
     * 
     * @type {Array<FilterListItem>}
     * @memberof TranslatedFilter
     */
    publishers?: Array<FilterListItem>;
    /**
     * 
     * @type {Array<FilterListItem>}
     * @memberof TranslatedFilter
     */
    tags?: Array<FilterListItem>;
    /**
     * 
     * @type {Array<FilterListItem>}
     * @memberof TranslatedFilter
     */
    types?: Array<FilterListItem>;
}

export function TranslatedFilterFromJSON(json: any): TranslatedFilter {
    return TranslatedFilterFromJSONTyped(json, false);
}

export function TranslatedFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranslatedFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regions': !exists(json, 'regions') ? undefined : ((json['regions'] as Array<any>).map(FilterListItemFromJSON)),
        'publishers': !exists(json, 'publishers') ? undefined : ((json['publishers'] as Array<any>).map(FilterListItemFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(FilterListItemFromJSON)),
        'types': !exists(json, 'types') ? undefined : ((json['types'] as Array<any>).map(FilterListItemFromJSON)),
    };
}

export function TranslatedFilterToJSON(value?: TranslatedFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regions': value.regions === undefined ? undefined : ((value.regions as Array<any>).map(FilterListItemToJSON)),
        'publishers': value.publishers === undefined ? undefined : ((value.publishers as Array<any>).map(FilterListItemToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(FilterListItemToJSON)),
        'types': value.types === undefined ? undefined : ((value.types as Array<any>).map(FilterListItemToJSON)),
    };
}



/* User.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    alias: string;
    /**
     * 
     * @type {TeamRole}
     * @memberof User
     */
    role?: TeamRole;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'alias': json['alias'],
        'role': !exists(json, 'role') ? undefined : TeamRoleFromJSON(json['role']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'alias': value.alias,
        'role': TeamRoleToJSON(value.role),
    };
}



/* UserInput.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface UserInput
 */
export interface UserInput {
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    alias: string;
}

export function UserInputFromJSON(json: any): UserInput {
    return UserInputFromJSONTyped(json, false);
}

export function UserInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alias': json['alias'],
    };
}

export function UserInputToJSON(value?: UserInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alias': value.alias,
    };
}



/* UserList.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */




/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserList
     */
    items: Array<User>;
}

export function UserListFromJSON(json: any): UserList {
    return UserListFromJSONTyped(json, false);
}

export function UserListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(UserFromJSON)),
    };
}

export function UserListToJSON(value?: UserList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(UserToJSON)),
    };
}



/* UserMetadata.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface UserMetadata
 */
export interface UserMetadata {
    /**
     * 
     * @type {boolean}
     * @memberof UserMetadata
     */
    read: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMetadata
     */
    bookmarked: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserMetadata
     */
    readCreated?: Date;
}

export function UserMetadataFromJSON(json: any): UserMetadata {
    return UserMetadataFromJSONTyped(json, false);
}

export function UserMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'read': json['read'],
        'bookmarked': json['bookmarked'],
        'readCreated': !exists(json, 'readCreated') ? undefined : (new Date(json['readCreated'])),
    };
}

export function UserMetadataToJSON(value?: UserMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'read': value.read,
        'bookmarked': value.bookmarked,
        'readCreated': value.readCreated === undefined ? undefined : (value.readCreated.toISOString()),
    };
}



/* UserReference.ts */
/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface UserReference
 */
export interface UserReference {
    /**
     * 
     * @type {string}
     * @memberof UserReference
     */
    username: string;
}

export function UserReferenceFromJSON(json: any): UserReference {
    return UserReferenceFromJSONTyped(json, false);
}

export function UserReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
    };
}

export function UserReferenceToJSON(value?: UserReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
    };
}

