import {
  CalendarAgendaEvent,
  CalendarAgendaEventContent,
  CalendarAgendEventPip,
  CalendarEvent,
  CalendarEventComponent,
  CalendarEventLabel,
  UsersIcon,
} from '@fcg-tech/regtech-components';
import {
  ActionsArticleDueDateAgendaEventTeamName,
  ActionsArticleDueDateAgendaEventWrapper,
  ActionsArticleDueDateCalendarEventTeamName,
  ActionsArticleDueDateCalendarEventWrapper,
} from './ActionsArticleDueDateCalendarEvent.styles';

export type ActionsArticleDueDateCalendarEventData = {
  teamName: string;
};

export const ActionsArticleDueDateCalendarEvent: CalendarEventComponent<
  ActionsArticleDueDateCalendarEventData
> = ({ context, ...rest }) => {
  if (context === 'calendar') {
    return rest.event.data ? (
      <CalendarEvent {...rest}>
        <ActionsArticleDueDateCalendarEventWrapper>
          <ActionsArticleDueDateCalendarEventTeamName>
            <UsersIcon size="14" />
            {rest.event.data?.teamName}
          </ActionsArticleDueDateCalendarEventTeamName>
          <CalendarEventLabel>{rest.event.label}</CalendarEventLabel>
        </ActionsArticleDueDateCalendarEventWrapper>
      </CalendarEvent>
    ) : (
      <CalendarEvent {...rest} />
    );
  }

  // Agenda event
  return rest.event.data ? (
    <CalendarAgendaEvent {...rest}>
      <ActionsArticleDueDateAgendaEventWrapper>
        <CalendarAgendEventPip
          backgroundColor={rest.eventType?.backgroundColor}
          color={rest.eventType?.color}
        />

        <ActionsArticleDueDateAgendaEventTeamName
          title={rest.event.data?.teamName}
        >
          <UsersIcon size="14" />
          {rest.event.data?.teamName}
        </ActionsArticleDueDateAgendaEventTeamName>
        <CalendarAgendaEventContent title={rest.event.label}>
          {rest.event.label}
        </CalendarAgendaEventContent>
      </ActionsArticleDueDateAgendaEventWrapper>
    </CalendarAgendaEvent>
  ) : (
    <CalendarAgendaEvent {...rest} />
  );
};
