import styled, { css } from 'styled-components';

export const ProgressListWrapper = styled.ul`
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
`;

export const ProgressItemWrapper = styled.li`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 3.6rem;
    font-size: 1.4rem;

    transition: all linear 0.2s;
    transform: rotateX(90deg);
    opacity: 0;

    &.started {
      transform: rotateX(0deg);
      opacity: 1;
    }

    &.finished {
      color: ${theme?.colors?.success?.main ?? 'limegreen'};
    }

    &.failed {
      text-decoration: line-through;
      color: ${theme?.colors?.error?.main ?? 'crimson'};
    }
  `}
`;

export const ProgressItemText = styled.span`
  display: inline-block;
  margin-right: 3rem;
`;

export const ProgressItemIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%) translateX(50%);
`;
