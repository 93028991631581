import {
  GRID_AREA_HEADER,
  SearchTextField,
  SecondaryButton,
  SideBarHamburgerButton,
  SideBarLogoWrapper,
} from '@fcg-tech/regtech-components';
import { foundation } from '@fcg-tech/regtech-design-system';
import styled, { css } from 'styled-components';
import { SIDE_NAVIGATION_WIDTH_REM } from '../MainSideNavigation/MainSideNavigation.styles';

export const HeaderWrapper = styled.header`
  background-color: ${foundation.palette.onyx100.hex};
  display: flex;
  z-index: 1;
  display: flex;
  align-items: center;
  z-index: 5;
  border-bottom: 1px solid rgba(31, 75, 66, 0.25);

  grid-area: ${GRID_AREA_HEADER};
  min-width: 0;
  overflow: hidden;

  @media print {
    display: none;
  }
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  min-width: 0;
`;

export const HeaderRightContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 1.5rem;
    flex: 1;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      display: none;
    }
  `}
`;

export const HeaderSearchTextField = styled(SearchTextField)`
  width: 20vw;
  max-width: 100%;
  min-width: 30rem;
`;

export const HeaderSearchSubmitButton = styled(SecondaryButton)`
  margin-left: 1.6rem;
`;

export const HeaderSideBarWrapper = styled.div`
  position: relative;
  width: calc(${SIDE_NAVIGATION_WIDTH_REM}rem - 1px);

  & > ${SideBarLogoWrapper} {
    padding: 0 1.7rem 0 1.5rem;
  }
`;

export const HeaderMobileHamburgerButton = styled(SideBarHamburgerButton)`
  ${({ theme }) => css`
    display: none;
    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      margin-left: 1.6rem;
      display: block;
    }
  `}
`;

export const HeaderActionButtonWrapper = styled.div`
  margin-right: 2.2rem;
`;

export const StyledHeading = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > .lucide {
    margin-right: 1rem;
  }

  & .heading {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
