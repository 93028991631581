import { validateEmail } from '@fcg-tech/regtech-components';
import { MessageKeys } from '../translations/translationTypes';
import { Translator } from '../types';

export const getEmailValidator = (t: Translator) => (email: string) => {
  if (!validateEmail(email)) {
    return t(MessageKeys.ErrorInvalidEmail);
  }
  return null;
};
