import { CheckSquare, Square } from 'lucide-react';
import { FunctionComponent } from 'react';
import { components } from 'react-select';
import { MultiSelectOptionWrapper } from './MultiSelectOptions.styles';

type OptionsProps = React.ComponentPropsWithRef<typeof components.Option>;

export const MultiSelectOption: FunctionComponent<OptionsProps> = (props) => {
  return (
    <components.Option {...props}>
      <MultiSelectOptionWrapper>
        {props.isSelected ? <CheckSquare size="20" /> : <Square size="20" />}
        {props.label}
      </MultiSelectOptionWrapper>
    </components.Option>
  );
};
