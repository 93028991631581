import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  Badge,
  TabBarNavigation,
  TabBarNavigationItem,
  ToolbarItemWrapper,
  ToolbarRow,
  TooltipNext as Tooltip,
} from '@fcg-tech/regtech-components';
import { TabState } from 'ariakit/ts/tab';
import { Archive, Clipboard, Inbox, Layers, Settings } from 'lucide-react';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTeamOverdueArticlesCount } from '../../api/hooks';
import { routes } from '../../routes';
import { team } from '../../state/teamState';
import { MessageKeys } from '../../translations/translationTypes';
import { canViewPageTeamsAdministration } from '../../utils/iamHelpers';
import { ErrorBoundary } from '../ErrorBoundary';
import { getTableTypeByPath } from '../FeedArticleTable/feedArticleTableHelpers';
import { FeedArticleTableTopActionBar } from '../FeedArticleTable/FeedArticleTableTopActionBar';
import { useIamContext } from '../IamContext';
import { SuspenseLoader } from '../SuspensLoader';
import { TeamToolbarWrapper } from './TeamToolbar.styles';

interface TeamToolbarProps {
  tabState: TabState;
}

export const TeamToolbar: FunctionComponent<TeamToolbarProps> = ({
  tabState,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams<{ teamId: string }>();
  const currentTeam = useRecoilValue(team(params.teamId));
  const iamContext = useIamContext();
  const overdueCount = useTeamOverdueArticlesCount(params.teamId);
  const tableType = getTableTypeByPath(location.pathname);
  const items = useMemo<Array<TabBarNavigationItem>>(
    () => [
      {
        to: constructUrl(routes.teamSubscriptions, { teamId: params.teamId }),
        label: () => (
          <ToolbarItemWrapper>
            <Inbox size="18" />
            {t(MessageKeys.SubscribedArticles)}
          </ToolbarItemWrapper>
        ),
      },
      {
        to: constructUrl(routes.teamActions, {
          teamId: params.teamId,
        }),
        label: () => (
          <ToolbarItemWrapper>
            <Clipboard size="18" />
            {t(MessageKeys.SideMenuTeamActivityInsights)}
            {overdueCount > 0 ? (
              <Tooltip
                placement="right"
                content={t(MessageKeys.SideMenuOverdueBadgeTooltip, {
                  count: overdueCount,
                })}
              >
                <Badge className="row-item-margin-left">{overdueCount}</Badge>
              </Tooltip>
            ) : null}
          </ToolbarItemWrapper>
        ),
      },
      {
        to: constructUrl(routes.teamArchive, { teamId: params.teamId }),
        label: () => (
          <ToolbarItemWrapper>
            <Archive size="18" />
            {t(MessageKeys.SideMenuTeamArticlesArchive)}
          </ToolbarItemWrapper>
        ),
      },
      {
        to: constructUrl(routes.teamActionsLog, { teamId: params.teamId }),
        label: () => (
          <ToolbarItemWrapper>
            <Layers size="18" />
            {t(MessageKeys.SideMenuTeamArticlesEventLog)}
          </ToolbarItemWrapper>
        ),
      },
      {
        to: constructUrl(routes.teamSettingsCatchAll, {
          teamId: params.teamId,
        }),
        selectedForActiveSubPaths: true,
        label: () => (
          <ToolbarItemWrapper>
            <Settings size="18" />
            {t(
              canViewPageTeamsAdministration(params.teamId, iamContext)
                ? MessageKeys.SideMenuTeamAdministration
                : MessageKeys.SideMenuTeamAbout,
            )}
          </ToolbarItemWrapper>
        ),
      },
    ],
    [iamContext, overdueCount, params.teamId, t],
  );
  return currentTeam ? (
    <TeamToolbarWrapper>
      <ToolbarRow>
        <TabBarNavigation
          tabLinkState={tabState}
          tabBarAriaLabel="Team Toolbar"
          items={items}
        />
        {tableType ? (
          <ErrorBoundary silentAuthError>
            <SuspenseLoader silent>
              <FeedArticleTableTopActionBar />
            </SuspenseLoader>
          </ErrorBoundary>
        ) : null}
      </ToolbarRow>
    </TeamToolbarWrapper>
  ) : null;
};
