import {
  ErrorBoundary,
  Showcase,
  ShowcaseReel,
} from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeShowcase, Translator } from '../../types';
import { useShowcase } from './useShowcase';

export const GenericFeedArticleTableShowcase: FunctionComponent = () => {
  const { t } = useTranslation();
  const [display, handleExit] = useShowcase(
    RegeyeShowcase.GenericFeedArticleTable,
    {
      requireShowcaseCompleted: [RegeyeShowcase.NewUser],
      initialDelay: 500,
    },
  );
  return display ? (
    <ErrorBoundary silent>
      <Showcase
        reel={GenericFeedArticleTableShowcaseReel(t)}
        onExit={handleExit}
      />
    </ErrorBoundary>
  ) : null;
};

const GenericFeedArticleTableShowcaseReel = (t: Translator): ShowcaseReel => ({
  id: RegeyeShowcase.GenericFeedArticleTable,
  skippable: true,
  waitForQuerySelector: '#filter-toggle-button',
  entries: [
    {
      content: t(MessageKeys.ShowCaseNewUserWelcomeToRegeyeFilterBody),
      querySelector: '#filter-toggle-button',
      padding: 5,
      // illustrationStyle: {
      //   backgroundSize: 'auto 90%',
      // },
      // illustrationBg: 'rgba(69, 123, 157, 1)',
      // illustrationSrc:
      //   'assets/showcase/newuser/filter-button-illustration-2.png',
    },
    {
      content: t(MessageKeys.ShowCaseNewUserWelcomeToRegeyeColumnPickerBody),
      querySelector: '#column-picker-button-wrapper',
      // illustrationStyle: {
      //   backgroundSize: 'contain',
      // },
      // illustrationBg: 'rgba(69, 123, 157, 1)',
      // illustrationSrc:
      //   'assets/showcase/newuser/filter-button-illustration-2.png',
    },
  ],
});
