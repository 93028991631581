import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useCallback } from 'react';
import {
  useAttachmentActions,
  useTeamActivitiesForArticleActions,
} from '../../api/hooks';
import { TeamActions, TeamArticleMetadata } from '../../api/schema';
import { MessageKeys } from '../../translations/translationTypes';
import { ApiDocumentLocation } from '../../types';
import { useAccessControl } from '../AccessControl';
import { AttachmentList } from '../AttachmentList';
import {
  FeedArticleTeamMetadataContentAttachmentsWrapper,
  FeedArticleTeamMetadataContentSectionRow,
  FeedArticleTeamMetadataContentSectionRowLabel,
  FeedArticleTeamMetadataContentWidgetDisabledWrapper,
} from './FeedArticleTeamMetadata.styles';

interface FeedArticleTeamMetadataAttachmentsProps {
  articleId: string;
  metadata: TeamArticleMetadata;
  teamId: string;
  disabled?: boolean;
  onMutated?: () => void;
}

export const FeedArticleTeamMetadataAttachments: FunctionComponent<
  FeedArticleTeamMetadataAttachmentsProps
> = ({ articleId, metadata, teamId, disabled: hardDisabled, onMutated }) => {
  const { t } = useTranslation();
  const { getFileDownloadUrl } = useAttachmentActions(teamId);

  const { addAttachment, deleteAttachment } =
    useTeamActivitiesForArticleActions(teamId, articleId);

  const handleAttachmentUpload = useCallback(
    async (acceptedFiles: Array<File>) => {
      await addAttachment(acceptedFiles);
      onMutated?.();
      return true;
    },
    [addAttachment, onMutated],
  );

  const handleDownloadAttachment = useCallback(
    async (ref: string): Promise<string> => {
      const { url } = await getFileDownloadUrl(ref);
      return url;
    },
    [getFileDownloadUrl],
  );

  const handleUrlAttachmentAdded = useCallback(
    async (attachmentUrls: Array<string>) => {
      await addAttachment(
        attachmentUrls.map<ApiDocumentLocation>((url) => ({
          customFile: false,
          ref: url,
        })),
      );
      onMutated?.();
      return true;
    },
    [addAttachment, onMutated],
  );

  const handleRemoveAttachment = useCallback(
    async (id: string): Promise<boolean> => {
      const attachment = metadata.attachments.find((a) => a.id === id);
      if (attachment) {
        try {
          await deleteAttachment(attachment.id, attachment.documentLocation);
          onMutated?.();
          return true;
        } catch (e) {
          return false;
        }
      }
      return false;
    },
    [deleteAttachment, metadata.attachments, onMutated],
  );

  const changeAllowed = useAccessControl(TeamActions.ArticleAttachmentChange);
  const disabled = !changeAllowed || hardDisabled;

  return (
    <FeedArticleTeamMetadataContentSectionRow className="expand">
      <FeedArticleTeamMetadataContentSectionRowLabel>
        {t(MessageKeys.ArticleMetadataAttachments)}
      </FeedArticleTeamMetadataContentSectionRowLabel>
      {!changeAllowed && !metadata.attachments?.length ? (
        <FeedArticleTeamMetadataContentWidgetDisabledWrapper>
          {t(MessageKeys.AccessErrorChangeAttachments)}
        </FeedArticleTeamMetadataContentWidgetDisabledWrapper>
      ) : (
        <FeedArticleTeamMetadataContentAttachmentsWrapper>
          <AttachmentList
            attachments={metadata.attachments ?? []}
            allowMultiple
            allowDownload
            onFileUpload={!disabled ? handleAttachmentUpload : null}
            onUrlSelected={!disabled ? handleUrlAttachmentAdded : null}
            onDownloadAttachment={handleDownloadAttachment}
            onRemoveAttachment={!disabled ? handleRemoveAttachment : null}
          />
        </FeedArticleTeamMetadataContentAttachmentsWrapper>
      )}
    </FeedArticleTeamMetadataContentSectionRow>
  );
};
