import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { SortingRule } from '@fcg-tech/regtech-datatable';
import {
  FilterSelectOption,
  FilterSelectProps,
  HandleClear,
  HandleFilterChange,
  HandleFilterClear,
  HandleFilterValueChange,
  isFilterEmpty,
  parseQuery,
  parseUrlFilterValues,
  StoredFilterType,
  useFilter as useGenericFilter,
  UseFilterInterface as UseGenericFilterInterface,
} from '@fcg-tech/regtech-filter';
import {
  FeedArticle,
  FilterConfig,
  FilterExclude,
  FilterSearchField,
  FilterValues,
  StoredFilter,
  TableSubType,
  TableType,
} from '@fcg-tech/regtech-types/regeye';
import { array, compareArrays, single } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import { parse, Stringifiable, stringify } from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  useAvilableArticleFiltersActions,
  usePersonalStoredFilters,
  useSettings,
  useSettingsActions,
  useStoredFilterActions,
  useTeamStoredFilters,
} from '../api/hooks';
import { FilterType, TranslatedFilter } from '../api/schema';
import { FeedArticleTableProps } from '../components/FeedArticleTable';
import { useTeamContext } from '../components/TeamContext/TeamContext';
import { routes } from '../routes';
import { useFilterDrafts } from './draftHelpers';
import {
  getFilterTypeFromTableType,
  getOrderBy,
  getSortBy,
} from './filterHelpers';
import { isFilterType } from './guards';
import { mergeItems, without } from './immutabilityHelpers';

export const handleSortByChange = (
  sortBy: SortingRule<FeedArticle>,
  navigate: NavigateFunction,
) => {
  const search: Record<
    string,
    string | number | boolean | Array<Stringifiable> | null | undefined
  > = parse(location.search) ?? {};
  search.orderBy = getOrderBy(sortBy);
  navigate(
    {
      pathname: location.pathname,
      search: stringify(search),
    },
    { replace: true },
  );
};

export interface UseFilterInterface
  extends UseGenericFilterInterface<FilterValues> {
  extendedFilter: FilterValues;
  translatedFilter: TranslatedFilter;
  pinnedFilters: Array<StoredFilter>;
  sortBy?: SortingRule<FeedArticle>;
  isSearching: boolean;
  hasInitialFilter: boolean;
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>;
  handleFilterPinned: (filterId: string, pinned: boolean) => void;
  handlePinnedFilterSortOrderChange: (
    targetFilterId: string,
    insertBeforeId: string | null,
  ) => void;
  handleFilterSaved: (opts: {
    filterValues: FilterValues;
    name: string;
    filterId?: string;
    teamFilter?: boolean;
  }) => Promise<void>;
  handleFilterDeleted: (filterId: string) => Promise<unknown>;
  handleSearch?: FilterSelectProps<FilterValues, boolean>['onSearch'];
  handleSortByChange: (sortBy?: SortingRule<FeedArticle>) => void;
}

export const useFilter = (filterConfig?: FilterConfig): UseFilterInterface => {
  const { teamId, companyId, tableType } = filterConfig ?? {};
  const filterDraftIdent = useMemo(
    () => ({ companyId, teamId, tableType }),
    [companyId, tableType, teamId],
  );
  const freezeAt = useRef<Date>(new Date());
  const navigate = useNavigate();

  const [isSearching, setIsSearching] = useState(false);
  const { data: settings } = useSettings({ suspense: false });
  const { updateSettings } = useSettingsActions();
  const { saveFilter, deleteFilter } = useStoredFilterActions();

  const { drafts, updateDraft, deleteDraft } =
    useFilterDrafts(filterDraftIdent);

  const [
    overrideHideDraftRestoredNotification,
    setOverrideHideDraftRestoredNotification,
  ] = useState(false);

  const filterType = getFilterTypeFromTableType(tableType);

  const modifyParsedFilter = useCallback(
    (filterValues: FilterValues): FilterValues => {
      let modified = filterValues;

      if (tableType === TableType.Actions) {
        if (modified.importance) {
          modified.importance = array(modified.importance);
        }
      }
      const searchString = modified.searchString;
      if (searchString && Array.isArray(searchString)) {
        modified = update(modified, {
          searchString: { $set: searchString.join(',') },
        });
      }
      return modified;
    },
    [tableType],
  );

  const filterPropKeys = useMemo<Array<keyof FilterValues>>(() => {
    switch (filterConfig?.tableType) {
      case TableType.Generic:
        if (filterConfig?.tableSubType === TableSubType.Search) {
          return [
            'orderBy',
            'publicationDate',
            'publishers',
            'regions',
            'searchString',
            'tags',
            'types',
          ];
        }
        return [
          'archived',
          'exclude',
          'orderBy',
          'publicationDate',
          'publishers',
          'publishers',
          'regions',
          'searchString',
          'tags',
          'types',
          'unreadOnly',
        ];

      case TableType.Actions:
        return [
          'archived',
          'assignees',
          'attachedBy',
          'commentedBy',
          'dueDate',
          'importance',
          'orderBy',
          'performedDate',
          'publicationDate',
          'selector',
          'teams',
          'teamTags',
          'uncompletedAssignedOnly',
          'unreadOnly',
        ];

      case TableType.ActionLog:
        return ['performedDate', 'performedBy', 'actions', 'orderBy', 'teams'];

      default:
        return [];
    }
  }, [filterConfig?.tableSubType, filterConfig?.tableType]);

  const {
    data: storedTeamFilters,
    isValidating: isValidatingStoredTeamFilters,
  } = useTeamStoredFilters(
    isFilterType(filterType) ? filterType : null,
    teamId,
    {
      suspense: true,
    },
  );

  const {
    data: storedPersonalFilters,
    isValidating: isValidatingStoredPersonalFilters,
  } = usePersonalStoredFilters(isFilterType(filterType) ? filterType : null, {
    suspense: true,
  });

  const storedFiltersRef = useRef<Array<StoredFilter>>([
    {
      id: 'reset',
      name: 'reset',
      filter: {},
      filterType,
      personalFilter: false,
      type: StoredFilterType.SingleUserFilter,
    },
  ]);
  useEffect(() => {
    if (!isValidatingStoredPersonalFilters && !isValidatingStoredTeamFilters) {
      storedFiltersRef.current = [
        ...(storedPersonalFilters ?? []),
        ...(storedTeamFilters ?? []),
      ].map((filter) => {
        return update(filter, {
          type: {
            $set: filter.personalFilter
              ? StoredFilterType.SingleUserFilter
              : StoredFilterType.MultiUserFilter,
          },
        });
      });
    }
  }, [
    isValidatingStoredPersonalFilters,
    isValidatingStoredTeamFilters,
    storedPersonalFilters,
    storedTeamFilters,
  ]);

  const storedFilters = storedFiltersRef.current;

  const stringifiedDraftFilter = drafts?.[0]?.stringifiedFilter;
  const [initialFilter, initialFilterId] = useMemo<
    [FilterValues | undefined, string | undefined]
  >(() => {
    if (
      stringifiedDraftFilter &&
      settings?.flags?.automaticallyRestoreFilterDrafts
    ) {
      return [
        parseUrlFilterValues(stringifiedDraftFilter, filterPropKeys),
        single(parseQuery(stringifiedDraftFilter).filterId),
      ];
    }
    return [undefined, undefined];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterProps = useGenericFilter<FilterValues>({
    storedFilters,
    initialFilter: {
      filter: initialFilter,
      filterId: initialFilterId,
    },
    filterProps: filterPropKeys,
    excludePropertyKey: 'exclude',
    modifyParsedFilter,
  });

  const {
    handleFilterChange: baseHandleFilterChange,
    handleFilterValueChange: baseHandleFilterValueChange,
    handleFilterValueClear: baseHandleFilterValueClear,
  } = filterProps;

  const extendedFilter = useMemo<FilterValues>(() => {
    let updated = filterProps.filter ?? {};
    if (updated.importance) {
      updated = update(updated, {
        importance: { $set: array(filterProps.filter.importance) },
      });
    }
    return updated;
  }, [filterProps.filter]);

  const [pinnedFilters, setPinnedFilters] = useState<Array<StoredFilter>>([]);

  useEffect(() => {
    setPinnedFilters((old) => {
      const pinned = settings?.pinnedFilters
        ?.map((id) => storedFilters.find((filter) => filter.id === id))
        .filter(Boolean);

      if (!pinned) {
        return old;
      }

      const oldSorted = [...old].sort();
      const sortedPinned = [...pinned].sort();
      if (!compareArrays(oldSorted, sortedPinned)) {
        return pinned;
      }
      return old;
    });
  }, [settings?.pinnedFilters, storedFilters]);

  const { searchFilterOptions, translateFilter } =
    useAvilableArticleFiltersActions(teamId);

  const [translatedFilter, setTranslatedFilter] = useState<TranslatedFilter>(
    {},
  );

  useEffect(() => {
    const run = async () => {
      if (
        (filterProps?.filter,
        filterType === FilterType.ArticleFilter ||
          filterType === FilterType.InsightFilter)
      ) {
        const translatedFilters = await translateFilter(filterProps.filter);
        setTranslatedFilter(translatedFilters);
      }
    };
    run();
  }, [filterType, filterProps.filter, translateFilter]);

  const handleFilterPinned = useCallback(
    (filterId: string, pinned: boolean) => {
      const s = new Set(settings.pinnedFilters);
      if (!s.has(filterId) && !pinned) {
        // User unpinned a filter that was not pinned. Should not happen, and not much we can do at this point
        return;
      }
      if (pinned) {
        s.add(filterId);
      } else {
        s.delete(filterId);
      }
      const updated = update(settings, {
        pinnedFilters: { $set: Array.from(s) },
      });

      updateSettings(updated);
    },
    [settings, updateSettings],
  );

  const handlePinnedFilterSortOrderChange = useCallback(
    (targetFilterId: string, insertBeforeId: string | null) => {
      const oldIndex = settings?.pinnedFilters?.indexOf(targetFilterId);
      const targetIndex = insertBeforeId
        ? settings?.pinnedFilters?.indexOf(insertBeforeId)
        : null;
      if (targetFilterId && oldIndex >= 0) {
        const pinnedFilters = update(settings?.pinnedFilters, {
          $splice:
            targetIndex !== null
              ? [
                  [oldIndex, 1],
                  [targetIndex, 0, targetFilterId],
                ]
              : [[oldIndex, 1]],
          $push: targetIndex === null ? [targetFilterId] : [],
        });

        updateSettings(
          update(settings, {
            pinnedFilters: { $set: pinnedFilters },
          }),
        );

        setPinnedFilters((old) => {
          const pinned = pinnedFilters
            ?.map((id) => storedFilters.find((filter) => filter.id === id))
            .filter(Boolean);

          if (!pinned) {
            return old;
          }

          const oldSorted = [...old].sort();
          const sortedPinned = [...pinned].sort();
          if (!compareArrays(oldSorted, sortedPinned)) {
            return pinned;
          }
          return old;
        });
      }
    },
    [settings, storedFilters, updateSettings],
  );

  const handleFilterSaved = useCallback<
    UseFilterInterface['handleFilterSaved']
  >(
    async ({ filterValues, name, filterId, teamFilter }) => {
      const filter = await saveFilter(
        filterValues,
        name,
        filterType,
        teamFilter ? teamId : null,
        filterId,
      );
      if (filter) {
        filterProps.handleFilterChange(filterValues, filter.id);
      }
      setOverrideHideDraftRestoredNotification(true);
    },
    [filterType, filterProps, saveFilter, teamId],
  );

  const handleFilterDeleted = useCallback(
    async (filterId: string) => {
      const storedFilter = storedFilters.find(({ id }) => id === filterId);
      setOverrideHideDraftRestoredNotification(true);
      if (storedFilter) {
        await deleteFilter(
          storedFilter.id,
          storedFilter.filterType,
          storedFilter.personalFilter ? null : teamId,
        );
        filterProps.handleFilterChange({}, null);
      }
    },
    [deleteFilter, teamId, filterProps, storedFilters],
  );

  const handleFilterValueChange = useCallback<
    HandleFilterValueChange<FilterValues>
  >(
    (...args) => {
      freezeAt.current = new Date();
      const updatedFilter = baseHandleFilterValueChange(...args);
      if (isFilterEmpty(updatedFilter)) {
        deleteDraft(filterDraftIdent);
      }
      setOverrideHideDraftRestoredNotification(true);
      return updatedFilter;
    },
    [deleteDraft, filterDraftIdent, baseHandleFilterValueChange],
  );

  const handleFilterClear = useCallback<HandleFilterClear>(() => {
    const updatedFilter = filterProps.handleFilterClear();
    if (isFilterEmpty(updatedFilter)) {
      deleteDraft(filterDraftIdent);
    }
    setOverrideHideDraftRestoredNotification(true);
    return updatedFilter;
  }, [deleteDraft, filterDraftIdent, filterProps]);

  const handleFilterValueClear = useCallback<HandleClear<FilterValues>>(
    (...args) => {
      const updatedFilter = baseHandleFilterValueClear(...args);
      if (isFilterEmpty(updatedFilter)) {
        deleteDraft(filterDraftIdent);
      }
      setOverrideHideDraftRestoredNotification(true);
      return updatedFilter;
    },
    [deleteDraft, filterDraftIdent, baseHandleFilterValueClear],
  );

  const handleFilterChange = useCallback<HandleFilterChange>(
    (filterValues: FilterValues, filterId?: string) => {
      freezeAt.current = new Date();
      if (isFilterEmpty(filterValues)) {
        deleteDraft(filterDraftIdent);
      }
      setOverrideHideDraftRestoredNotification(true);
      return baseHandleFilterChange(filterValues, filterId);
    },
    [deleteDraft, filterDraftIdent, baseHandleFilterChange],
  );

  const handleStoredFilterSelected = useCallback<
    UseFilterInterface['handleStoredFilterSelected']
  >(
    (...args) => {
      setOverrideHideDraftRestoredNotification(true);
      freezeAt.current = new Date();
      return filterProps.handleStoredFilterSelected(...args);
    },
    [filterProps],
  );

  const handleSortByChangeLocal = useCallback(
    (sortBy: SortingRule<FeedArticle>) => {
      return handleSortByChange(sortBy, navigate);
    },
    [navigate],
  );

  const handleSearch = useCallback<UseFilterInterface['handleSearch']>(
    async (value, searchField, options) => {
      setIsSearching(true);

      const result = await searchFilterOptions(
        searchField as FilterSearchField,
        value,
        extendedFilter,
        {
          offset: options?.offset,
          limit: 100,
        },
      );
      setIsSearching(false);

      setTranslatedFilter((oldFilters) => {
        return update(oldFilters, {
          [searchField]: {
            $set: mergeItems(result.items ?? [], oldFilters[searchField] ?? []),
          },
        });
      });

      return [
        result.items?.map<FilterSelectOption>((item) => ({
          label: item.name,
          value: item,
        })) ?? [],
        result.truncated,
        result.lastId,
      ];
    },
    [extendedFilter, searchFilterOptions, setIsSearching],
  );

  const { filter, filterId, getFilterQuery } = filterProps;

  useEffect(() => {
    if (!isFilterEmpty(filter)) {
      updateDraft(filterDraftIdent, {
        stringifiedFilter: stringify(getFilterQuery(filter, filterId)),
      });
    }
  }, [
    deleteDraft,
    filter,
    filterDraftIdent,
    filterId,
    getFilterQuery,
    updateDraft,
  ]);

  const sortBy = useMemo<SortingRule<FeedArticle>>(
    () =>
      extendedFilter.orderBy ? getSortBy(extendedFilter.orderBy) : undefined,
    [extendedFilter.orderBy],
  );

  return {
    ...filterProps,
    extendedFilter,
    translatedFilter,
    isSearching,
    setIsSearching,
    storedFilters,
    pinnedFilters,
    sortBy,
    hasInitialFilter:
      filterProps.usingInitialFilter && !overrideHideDraftRestoredNotification,
    handleFilterChange,
    handleFilterClear,
    handleFilterDeleted,
    handleFilterPinned,
    handleFilterSaved,
    handleFilterValueChange,
    handleFilterValueClear,
    handlePinnedFilterSortOrderChange,
    handleSearch,
    handleSortByChange: handleSortByChangeLocal,
    handleStoredFilterSelected,
  };
};

export const useTagAndTypeClickHandlers = (
  filter: FilterValues,
  getFilterQuery: UseFilterInterface['getFilterQuery'],
  alwaysRedirectOnTeamTag: boolean,
  onFilterChange: (filterValues: FilterValues) => void,
): Pick<
  FeedArticleTableProps,
  'onTagClick' | 'onTeamTagClick' | 'onArticleTypeClick'
> => {
  const { teamId } = useTeamContext();
  const navigate = useNavigate();

  const onTagClick = useCallback(
    (tagId: string) => {
      onFilterChange?.({
        ...filter,
        tags: [tagId],
        exclude: without(filter.exclude, FilterExclude.Tags),
      });
    },
    [filter, onFilterChange],
  );

  const onTeamTagClick = useCallback(
    (tagId: string, teamTagTeamId: string) => {
      if (teamId === teamTagTeamId && !alwaysRedirectOnTeamTag) {
        onFilterChange({
          ...filter,
          teamTags: [tagId],
        });
      } else {
        navigate(
          constructUrl(
            routes.teamActions,
            {
              teamId: teamTagTeamId,
            },
            {
              ...getFilterQuery({
                teamTags: [tagId],
                unarchivedOnly: true,
              }),
            },
          ),
        );
      }
    },
    [
      alwaysRedirectOnTeamTag,
      filter,
      getFilterQuery,
      navigate,
      onFilterChange,
      teamId,
    ],
  );

  const onArticleTypeClick = useCallback(
    (tagId: string) => {
      onFilterChange?.({
        ...filter,
        types: [tagId],
        exclude: without(filter.exclude, FilterExclude.Types),
      });
    },
    [filter, onFilterChange],
  );

  return useMemo(
    () => ({
      onTagClick,
      onTeamTagClick,
      onArticleTypeClick,
    }),
    [onTagClick, onTeamTagClick, onArticleTypeClick],
  );
};
