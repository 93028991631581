import { RichTextAreaProps } from '@fcg-tech/regtech-richtext';
import {
  Comment,
  User,
  CommentDraftIdent,
} from '@fcg-tech/regtech-types/regeye';
import { differenceInSeconds } from 'date-fns';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Descendant } from 'slate';
import { useRichTextDrafts } from '../../utils/draftHelpers';
import { sortComments } from '../../utils/sorters';
import { useArticleContext } from '../ArticleContext';
import { useTeamContext } from '../TeamContext/TeamContext';
import { CommentField } from './CommentField';
import { CommentList } from './CommentList';
import { CommentListWrapper } from './CommentList.styles';
import {
  CommentListContainer,
  CommentListOuterContainer,
  CommentsWrapper,
} from './Comments.styles';

interface CommentsProps extends Pick<RichTextAreaProps, 'portalContainer'> {
  comments?: Array<Comment>;
  teamMembers?: Array<User>;
  onSubmitComment?: (
    plainText: string,
    markup: Array<Descendant>,
    replyToCommentId?: string,
  ) => Promise<unknown>;
  onEditComment?: (
    commentId: string,
    plainText: string,
    markup: Array<Descendant>,
  ) => Promise<unknown>;
  onDeleteComment?: (commentId: string) => void;
}

export const Comments: FunctionComponent<CommentsProps> = ({
  comments = [],
  teamMembers,
  portalContainer,
  onSubmitComment,
  onDeleteComment,
  onEditComment,
}) => {
  const articleContext = useArticleContext();
  const teamContext = useTeamContext();
  const commentFieldInputRef = useRef<HTMLDivElement>();
  const [replyToCommentId, setReplyToCommentId] = useState<string>();
  const [editCommentId, setEditCommentId] = useState<string>();
  const listRef = useRef<HTMLDivElement>();

  const baseIdent = useMemo<CommentDraftIdent>(
    () => ({
      articleId: articleContext?.articleId,
      teamId: teamContext?.teamId,
    }),
    [articleContext?.articleId, teamContext?.teamId],
  );

  const [key, setKey] = useState(1);

  const { drafts, updateDraft, deleteDraft } = useRichTextDrafts(baseIdent);

  const draft = useMemo(() => {
    if (replyToCommentId) {
      return drafts?.find((d) => d.id.replyToId === replyToCommentId);
    } else if (editCommentId) {
      return drafts?.find((d) => d.id.editId === editCommentId);
    }
    return drafts?.sort((a, b) =>
      differenceInSeconds(b.updated, a.updated),
    )?.[0];
  }, [drafts, editCommentId, replyToCommentId]);

  useEffect(() => {
    if (draft && draft.id.editId && draft.id.editId !== editCommentId) {
      setEditCommentId(draft.id.editId);
    } else if (
      draft &&
      draft.id.replyToId &&
      draft.id.replyToId !== replyToCommentId
    ) {
      setReplyToCommentId(draft.id.replyToId);
    }
    // We only want to do this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReply = useCallback(
    (commentId: string) => {
      setReplyToCommentId(commentId);
      setEditCommentId(undefined);
      const newId: CommentDraftIdent = { ...baseIdent, replyToId: commentId };
      updateDraft(newId, {
        id: newId,
      });
    },
    [baseIdent, updateDraft],
  );

  const handleCancel = useCallback(() => {
    deleteDraft(draft?.id);
    setReplyToCommentId(undefined);
    setEditCommentId(undefined);
    setKey(new Date().getTime());
  }, [deleteDraft, draft?.id]);

  const handleEdit = useCallback(
    (commentId: string) => {
      const newId = { ...baseIdent, editId: commentId };
      const comment = comments.find(({ id }) => id === commentId);
      const draft = drafts?.find((d) => d.id.editId === commentId);
      if (!draft) {
        updateDraft(newId, {
          id: newId,
          markup: comment.markup,
          content: comment.comment,
        });
      }
      setEditCommentId(commentId);
      setReplyToCommentId(undefined);
    },
    [baseIdent, comments, drafts, updateDraft],
  );

  const handleSubmitComment = useCallback(
    async (plainText: string, markup: Array<Descendant>) => {
      if (editCommentId) {
        await onEditComment(editCommentId, plainText, markup);
        setEditCommentId(undefined);
      } else {
        await onSubmitComment(plainText, markup, replyToCommentId);
        setReplyToCommentId(undefined);
      }
      deleteDraft(draft?.id);
      setKey(new Date().getTime());
      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    },
    [
      deleteDraft,
      draft?.id,
      editCommentId,
      onEditComment,
      onSubmitComment,
      replyToCommentId,
    ],
  );

  const sortedComments = useMemo(() => sortComments(comments), [comments]);

  const replyingTo = useMemo(
    () => comments.find(({ id }) => id === replyToCommentId),
    [comments, replyToCommentId],
  );

  const editComment = useMemo(
    () => comments.find(({ id }) => id === editCommentId),
    [comments, editCommentId],
  );

  useEffect(() => {
    if (editCommentId) {
      setKey(new Date().getTime());
    }
  }, [editCommentId]);

  useEffect(() => {
    if (replyToCommentId) {
      setKey(new Date().getTime());
    }
  }, [replyToCommentId]);

  return (
    <CommentsWrapper>
      <CommentListOuterContainer>
        {sortedComments?.length ? (
          <CommentListContainer ref={listRef}>
            <CommentList
              comments={sortedComments}
              disableActions={Boolean(replyToCommentId || editCommentId)}
              teamMembers={teamMembers}
              onDelete={onDeleteComment}
              onReply={onSubmitComment ? handleReply : null}
              onEdit={onEditComment ? handleEdit : null}
            />
          </CommentListContainer>
        ) : null}
      </CommentListOuterContainer>
      {onSubmitComment ? (
        <CommentField
          key={key}
          draft={draft}
          draftIdent={draft?.id ?? baseIdent}
          updateDraft={updateDraft}
          deleteDraft={deleteDraft}
          replyTo={replyingTo}
          edit={editComment}
          inputRef={commentFieldInputRef}
          teamMembers={teamMembers}
          portalContainer={portalContainer}
          onSubmit={handleSubmitComment}
          onCancel={handleCancel}
        />
      ) : null}
    </CommentsWrapper>
  );
};
