import { Label, TextField } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const ModalFormWrapper = styled.div`
  padding: 0.3rem 0 0 0;
  width: 50rem;
  max-width: 50rem;
  overflow-y: auto;
  &.narrow {
    width: 36rem;
    max-width: 36rem;
  }
`;

export const ModalForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    flex: 1;
    overflow-y: auto;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      min-width: initial;
      width: 100%;
    }
  `}
`;

export const ModalFormLabel = styled(Label)`
  position: relative;
`;

export const ModalTextField = styled(TextField)`
  width: 40rem;
`;
