import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 0.3rem 0 0 0;
  width: 50rem;
  max-width: 50rem;

  &.narrow {
    width: 36rem;
    max-width: 36rem;
  }
`;
