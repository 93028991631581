import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  FormButtonRow,
  FormRow,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useFlash,
  useInput,
} from '@fcg-tech/regtech-components';
import {
  FilterValues,
  Team,
  Company,
  FeedArticleDomain,
} from '@fcg-tech/regtech-types/regeye';
import { downloadDataUrl, useEscapeKeyOld } from '@fcg-tech/regtech-utils';
import { format } from 'date-fns';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import slugify from 'slugify';
import { useExportActionsArticles } from '../../api/hooks';
import { ModalFormLabel } from '../../components/modals';
import { MessageKeys } from '../../translations/translationTypes';
import {
  ActionsArticlesExportModalFilenameExtension,
  ActionsArticlesExportModalFilenameRow,
} from './ActionsArticleExportModal.styles';

interface ActionsArticleExportModalProps {
  company?: Company;
  team?: Team;
  domain: FeedArticleDomain;
  filter: FilterValues;
  onRequestClose: () => void;
}

export const ActionsArticleExportModal: FunctionComponent<ActionsArticleExportModalProps> =
  ({ company, team, domain, filter, onRequestClose }) => {
    const { name, id } = useMemo(() => {
      if (domain === FeedArticleDomain.Company && company) {
        return company;
      }
      return team ?? { name: undefined, id: undefined };
    }, [company, domain, team]);

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [filename, handleFilenameChange] = useInput(
      `${slugify(name, {
        replacement: '_',
      })}-actions-${format(new Date(), 'yyMMdd-HHmmss')}`,
    );
    const [limitExceeded, setLimitExceeded] = useState(0);
    const addFlash = useFlash();

    const { export: createExport } = useExportActionsArticles(id, domain);

    const handleExport = useCallback(async () => {
      try {
        setLoading(true);
        const response = await createExport(filter);
        const filenameWithExtension = `${filename.replace(
          /\.xlsx?$/,
          '',
        )}.xlsx`;
        downloadDataUrl(
          filenameWithExtension,
          response.data,
          response.contentType,
        );
        if (response.limitExceeded) {
          setLimitExceeded(response.maxRows);
        } else {
          onRequestClose();
        }
      } catch (err) {
        console.error(err);
        onRequestClose();
        addFlash({
          level: 'error',
          content: t(MessageKeys.ErrorServerError),
        });
      }
    }, [addFlash, createExport, filename, filter, onRequestClose, t]);

    useEscapeKeyOld(onRequestClose);

    return limitExceeded > 0 ? (
      <AlertDialog
        title={t(MessageKeys.LabelInfo)}
        body={t(MessageKeys.ExportLimitExceeded, {
          limit: limitExceeded,
        })}
        onRequestClose={onRequestClose}
      />
    ) : (
      <Modal>
        <ModalHeader>
          <ModalTitle>
            {t(MessageKeys.LabelExport)}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <FormRow>
            <ModalFormLabel>
              {t(MessageKeys.LabelFilename)}
            </ModalFormLabel>
            <ActionsArticlesExportModalFilenameRow>
              <TextField value={filename} onChange={handleFilenameChange} />
              <ActionsArticlesExportModalFilenameExtension>
                .xlsx
              </ActionsArticlesExportModalFilenameExtension>
            </ActionsArticlesExportModalFilenameRow>
          </FormRow>
          <FormButtonRow>
            <SecondaryButton onClick={onRequestClose}>
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton onClick={handleExport} loading={loading}>
              {t(MessageKeys.LabelExport)}
            </PrimaryButton>
          </FormButtonRow>
        </ModalBody>
      </Modal>
    );
  };
