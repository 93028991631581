import { GridLayoutMainWrapper } from '@fcg-tech/regtech-components';
import { parse } from 'query-string';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { NotificationsContainer } from './NotificationsContainer';

type Query = {
  filter?: 'all' | 'unread';
};

export const NotificationsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = useMemo<Query>(() => parse(location.search), [location.search]);

  const filter = query.filter ?? 'unread';

  return (
    <GridLayoutMainWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <NotificationsContainer key={filter} filter={filter} />
        </SuspenseLoader>
      </ErrorBoundary>
    </GridLayoutMainWrapper>
  );
};
