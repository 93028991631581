import { Comment } from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useRef } from 'react';
import { CommentEntry, CommentEntryProps } from './CommentEntry';
import { CommentListWrapper } from './CommentList.styles';

interface CommentListProps
  extends Pick<
    CommentEntryProps,
    'teamMembers' | 'onReply' | 'onEdit' | 'onDelete'
  > {
  comments: Array<Comment>;
  disableActions?: boolean;
}

export const CommentList = React.forwardRef<HTMLDivElement, CommentListProps>(
  (
    {
      comments,
      disableActions,
      teamMembers,
      onReply,
      onEdit,
      onDelete,
    }: CommentListProps,
    ref,
  ) => {
    return (
      <CommentListWrapper ref={ref}>
        {comments?.map((comment) => (
          <CommentEntry
            key={comment.id}
            comment={comment}
            teamMembers={teamMembers}
            disableActions={disableActions}
            onReply={onReply}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}
      </CommentListWrapper>
    );
  },
);
