import { useTranslation } from 'react-i18next';
import {
  CheckCircleIcon,
  CircleExclamationIcon,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ProgressStep,
  TriangleExclamationIcon,
} from '@fcg-tech/regtech-components';
import { classNames, sleep } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import {
  useCompanyActions,
  usePublisherTeamActions,
  useTeamInvitationActions,
} from '../../../api/hooks';
import { Publisher, SubscriptionUpdate } from '../../../api/schema';
import { MessageKeys } from '../../../translations/translationTypes';
import { SuspenseLoader } from '../../SuspensLoader';
import {
  AdminUserSetupWizardProgress,
  AdminUserSetupWizardResultIconWrapper,
  SetupWizardModalPage,
  SetupWizardModalWrapper,
} from '../SetupWizard.styles';
import { AdminUserSetupSummaryPage } from './AdminUserSetupSummaryPage';
import { AdminUserSetupWizardSubscriptionPage } from './AdminUserSetupWizardSubscriptionsPage';
import { AdminUserSetupWizardTeamMembersPage } from './AdminUserSetupWizardTeamMembersPage';
import { AdminUserSetupWizardTeamPage } from './AdminUserSetupWizardTeamPage';
import { WizardData, WizardPage, WizardProgress } from './wizardTypes';

interface AdminUserSetupWizardModalProps {
  onRequestClose?: () => void;
}

export const AdminUserSetupWizardModal: FunctionComponent<
  AdminUserSetupWizardModalProps
> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<WizardPage>(WizardPage.Start);
  const [progress, setProgress] = useState<WizardProgress>(
    WizardProgress.Unstarted,
  );
  const [setupFailures, setSetupFailures] = useState<Array<WizardProgress>>([]);
  const { createTeam, addSelfToTeam } = useCompanyActions();
  const { companyCreateTeamInvitation } = useTeamInvitationActions(null);
  const { companyUpdateTeamPublisherSettings } = usePublisherTeamActions(null);

  const onNext = useCallback(() => setCurrentPage((page) => page + 1), []);
  const onPrev = useCallback(() => setCurrentPage((page) => page - 1), []);

  const [data, setData] = useState<Partial<WizardData>>({
    memberList: [],
  });
  const handlePublishersChange = useCallback(
    (publishers: Array<Publisher>) =>
      setData((old) => ({ ...old, publishers })),
    [],
  );

  const handleStartSetup = useCallback(async () => {
    setCurrentPage(WizardPage.Progress);
    setProgress(WizardProgress.CreateTeam);

    let teamId: string;

    try {
      const [team] = await Promise.all([
        createTeam(data.companyId, data.teamName),
        sleep(1000),
      ]);
      teamId = team.id;

      if (data.addMe) {
        await addSelfToTeam(data.companyId, teamId);
      }
    } catch (e) {
      // Fatal
      if (process.env.NODE_ENV !== 'production') {
        console.error(e);
      }
      setSetupFailures([WizardProgress.CreateTeam]);
      setProgress(WizardProgress.Done);
      return;
    }

    setProgress(WizardProgress.InviteMembers);

    try {
      await Promise.all([
        ...data.memberList.map((email) =>
          companyCreateTeamInvitation(
            data.companyId,
            email,
            data.message,
            teamId,
          ),
        ),
        sleep(2000),
      ]);
    } catch (e) {
      setSetupFailures([WizardProgress.InviteMembers]);
      setProgress(WizardProgress.Done);
    }

    setProgress(WizardProgress.UpdateSubscriptions);

    try {
      const updateData: SubscriptionUpdate['items'] = data.publishers
        .map((item) => {
          const publisher = {
            publisherId: item.id,
            articleTypeIds: item.articleTypes
              .filter(({ subscribed }) => subscribed)
              .map(({ id }) => id),
            subscribeToEntirePublisher: item.subscribedToEntirePublisher,
          };

          return publisher.articleTypeIds.length ? publisher : null;
        })
        .filter((publisher) => Boolean(publisher));
      if (updateData.length > 0) {
        await Promise.all([
          companyUpdateTeamPublisherSettings(
            updateData,
            data.companyId,
            teamId,
          ),
          sleep(1000),
        ]);
      }
    } catch (e) {
      setSetupFailures((old) => [...old, WizardProgress.UpdateSubscriptions]);
      setProgress(WizardProgress.Done);
    }

    setProgress(WizardProgress.Done);

    await sleep(2000);
    setCurrentPage(WizardPage.Done);
  }, [
    createTeam,
    data.companyId,
    data.teamName,
    data.addMe,
    data.memberList,
    data.message,
    data.publishers,
    addSelfToTeam,
    companyCreateTeamInvitation,
    companyUpdateTeamPublisherSettings,
  ]);

  const progressSteps = useMemo<Array<ProgressStep>>(() => {
    if (progress === WizardProgress.Unstarted) {
      return [];
    }

    const steps: Array<ProgressStep> = [
      {
        id: WizardProgress.CreateTeam,
        text: t(MessageKeys.WizardProgressCreatingTeam),
        started: progress > WizardProgress.Unstarted,
        failed: setupFailures.includes(WizardProgress.CreateTeam),
        finished: progress > WizardProgress.CreateTeam,
      },
      {
        id: WizardProgress.InviteMembers,
        text: t(MessageKeys.WizardProgressInvitingMembers),
        started: progress > WizardProgress.CreateTeam,
        failed: setupFailures.includes(WizardProgress.InviteMembers),
        finished: progress > WizardProgress.InviteMembers,
      },
      {
        id: WizardProgress.UpdateSubscriptions,
        text: t(MessageKeys.WizardProgressConfiguringSubscriptions),
        started: progress > WizardProgress.InviteMembers,
        failed: setupFailures.includes(WizardProgress.UpdateSubscriptions),
        finished: progress > WizardProgress.UpdateSubscriptions,
      },
    ];

    return steps;
  }, [t, progress, setupFailures]);

  return (
    <SetupWizardModalWrapper>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.WizardWelcomeToRegeye)}
        </ModalTitle>
      </ModalHeader>
      <SuspenseLoader>
        <SetupWizardModalPage
          className={classNames(currentPage > WizardPage.Start && 'after')}
        >
          <ModalBody>
            <p>
              {t(MessageKeys.WizardAdminIntro)}
            </p>
            <p>
              {t(MessageKeys.WizardLetsGo)}
            </p>
          </ModalBody>
          <ModalFooter>
            <ModalButtonRow>
              <PrimaryButton onClick={onNext}>
                {t(MessageKeys.LabelNext)}
              </PrimaryButton>
            </ModalButtonRow>
          </ModalFooter>
        </SetupWizardModalPage>
        <AdminUserSetupWizardTeamPage
          currentPage={currentPage}
          wizardData={data}
          onChange={setData}
          onNext={onNext}
        />
        <AdminUserSetupWizardTeamMembersPage
          currentPage={currentPage}
          wizardData={data}
          onChange={setData}
          onNext={onNext}
          onPrev={onPrev}
        />
        <AdminUserSetupWizardSubscriptionPage
          currentPage={currentPage}
          onNext={onNext}
          onPrev={onPrev}
          onChange={handlePublishersChange}
        />
        {currentPage === WizardPage.Summary ? (
          <AdminUserSetupSummaryPage
            data={data}
            currentPage={currentPage}
            onNext={handleStartSetup}
            onPrev={onPrev}
          />
        ) : null}
        {currentPage === WizardPage.Progress ? (
          <SetupWizardModalPage>
            <ModalBody>
              <AdminUserSetupWizardProgress steps={progressSteps} />
            </ModalBody>
          </SetupWizardModalPage>
        ) : null}
        {currentPage === WizardPage.Done ? (
          <SetupWizardModalPage>
            <ModalBody className="center">
              {setupFailures.length === 0 ? (
                <>
                  <AdminUserSetupWizardResultIconWrapper className="success-color">
                    <CheckCircleIcon color="currentColor" size="40" />
                  </AdminUserSetupWizardResultIconWrapper>
                  <p>
                    {t(MessageKeys.WizardDoneMessage)}
                  </p>
                </>
              ) : null}
              {setupFailures.includes(WizardProgress.CreateTeam) ? (
                <>
                  <AdminUserSetupWizardResultIconWrapper className="error-color">
                    <CircleExclamationIcon color="currentColor" size="40" />
                  </AdminUserSetupWizardResultIconWrapper>
                  <p>
                    {t(MessageKeys.WizardFailedToCreateTeamMessage)}
                  </p>
                </>
              ) : setupFailures.length ? (
                <>
                  <AdminUserSetupWizardResultIconWrapper className="warning-color">
                    <TriangleExclamationIcon color="currentColor" size="40" />
                  </AdminUserSetupWizardResultIconWrapper>
                  <p>
                    {t(MessageKeys.WizardDoneWithErrorsMessage)}
                  </p>
                </>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <ModalButtonRow>
                <PrimaryButton onClick={onRequestClose}>
                  {t(MessageKeys.LabelClose)}
                </PrimaryButton>
              </ModalButtonRow>
            </ModalFooter>
          </SetupWizardModalPage>
        ) : null}
      </SuspenseLoader>
    </SetupWizardModalWrapper>
  );
};
