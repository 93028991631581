import { useTranslation } from 'react-i18next';
import {
  BooleanTree,
  BooleanTreeItemMeta,
  ExternalLinkIcon,
  H3,
  isItemPartiallySelected,
} from '@fcg-tech/regtech-components';
import {
  SubscriptionEnumType,
  SubscriptionItem,
  SubscriptionItemValue,
} from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import { format, formatDistanceToNow } from 'date-fns';
import Fuse from 'fuse.js';
import { Globe2 } from 'lucide-react';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Publisher } from '../../api/schema';
import { MessageKeys } from '../../translations/translationTypes';
import { formatPublisherName } from '../../utils/formatters';
import {
  PublisherBooleanTreeArticleTypeNotUpdatedWarning,
  PublisherBooleanTreeExternalLink,
  PublisherBooleanTreeItemLabel,
  PublisherBooleanTreeMatchedOuterWrapper,
  PublisherBooleanTreeMatchedWrapper,
  PublisherBooleanTreeNrOfItems,
  PublisherBooleanTreeNrOfItemsAbsoluteWrapper,
  PublisherBooleanTreeRegionLabel,
  RegionTag,
} from './PublisherBooleanTree.styles';
import { getFilteredSearchResults } from './publisherBooleanTreeUtils';

const fuseConfig = {
  threshold: 0.2,
  includeMatches: true,
  includeScore: true,
  shouldSort: false,
  ignoreLocation: true,
  minMatchCharLength: 1,
  useExtendedSearch: true,
};

interface PublisherBooleanTreeProps {
  publishers: Array<Publisher>;
  searchTerm?: string;
  onChange?: React.ComponentPropsWithoutRef<typeof BooleanTree>['onChange'];
}

export const PublisherBooleanTree: FunctionComponent<
  PublisherBooleanTreeProps
> = ({ publishers, searchTerm, onChange }) => {
  const { t } = useTranslation();

  const getArticleTypeContents = useCallback(
    (item: SubscriptionItemValue) => {
      if (item.id && item.publisherId) {
        const publisher = publishers.find(({ id }) => id === item.publisherId);
        if (publisher) {
          const articleType = publisher.articleTypes.find(
            ({ id }) => id === item.id,
          );
          if (articleType) {
            return (
              <PublisherBooleanTreeItemLabel>
                {articleType.name}

                <PublisherBooleanTreeArticleTypeNotUpdatedWarning>
                  (
                  {t(
                    MessageKeys.AdministrationTeamSubscriptionsInactiveArticleTypeWarning,
                    {
                      addedDate: format(articleType.firstUpdate, 'yyyy-MM-dd'),
                      updatedDate: format(articleType.lastUpdate, 'yyyy-MM-dd'),
                      since: formatDistanceToNow(articleType.lastUpdate, {
                        addSuffix: true,
                      }),
                    },
                  )}
                  )
                </PublisherBooleanTreeArticleTypeNotUpdatedWarning>
              </PublisherBooleanTreeItemLabel>
            );
          }
        }
      }
    },
    [publishers, t],
  );

  const getPublisherContents = useCallback(
    (item: SubscriptionItemValue, meta: BooleanTreeItemMeta) => {
      const publisher = publishers.find(({ id }) => id === item.id);
      const label = formatPublisherName(publisher);
      if (publisher) {
        return (
          <PublisherBooleanTreeItemLabel
            title={publisher.name}
            className={classNames('publisher', meta.selected && 'selected')}
          >
            {item.regionName ? (
              <RegionTag tag={item.regionName} Icon={Globe2} />
            ) : null}
            {label}
            {publisher.link ? (
              <PublisherBooleanTreeExternalLink
                href={publisher.link}
                target="_blank"
              >
                <ExternalLinkIcon size="18" />
              </PublisherBooleanTreeExternalLink>
            ) : null}
            {item.nrOfSubscribedArticleTypes > 0 ? (
              <PublisherBooleanTreeNrOfItems
                className="selected"
                title={t(
                  MessageKeys.TeamSettingsTabSubscriptionsNrOfSubscribedArticleTypes,
                )}
              >
                {item.nrOfSubscribedArticleTypes}
              </PublisherBooleanTreeNrOfItems>
            ) : null}
            {item.nrOfSubscribedArticleTypes !== item.nrOfArticleTypes ? (
              <PublisherBooleanTreeNrOfItems
                title={t(
                  MessageKeys.TeamSettingsTabSubscriptionsNrOfArticleTypes,
                )}
              >
                {item.nrOfArticleTypes}
              </PublisherBooleanTreeNrOfItems>
            ) : null}
          </PublisherBooleanTreeItemLabel>
        );
      }

      return null;
    },
    [publishers, t],
  );

  const getRegionContents = useCallback(
    (item: SubscriptionItemValue, meta: BooleanTreeItemMeta) => {
      const publisher = publishers.find(({ region }) => region.id === item.id);
      return (
        <PublisherBooleanTreeRegionLabel
          className={classNames(meta.selected && 'selected')}
        >
          {publisher.region.name}
          <PublisherBooleanTreeNrOfItemsAbsoluteWrapper>
            {item.nrOfSubscribedArticleTypes > 0 ? (
              <PublisherBooleanTreeNrOfItems
                className="selected"
                title={t(
                  MessageKeys.TeamSettingsTabSubscriptionsNrOfSubscribedArticleTypes,
                )}
              >
                {item.nrOfSubscribedArticleTypes}
              </PublisherBooleanTreeNrOfItems>
            ) : null}
            {item.nrOfSubscribedArticleTypes !== item.nrOfArticleTypes ? (
              <PublisherBooleanTreeNrOfItems
                title={t(
                  MessageKeys.TeamSettingsTabSubscriptionsNrOfArticleTypes,
                )}
              >
                {item.nrOfArticleTypes}
              </PublisherBooleanTreeNrOfItems>
            ) : null}
          </PublisherBooleanTreeNrOfItemsAbsoluteWrapper>
        </PublisherBooleanTreeRegionLabel>
      );
    },
    [publishers, t],
  );

  const [regionTreeItems, publisherFuse] = useMemo<
    [Array<SubscriptionItem>, Fuse<SubscriptionItem>]
  >(() => {
    const publisherFuse = new Fuse<SubscriptionItem>([], {
      ...fuseConfig,
      keys: ['value.name', 'value.abbreviation'],
    });

    const regionMap: Record<string, SubscriptionItem> = {};
    publishers.forEach((publisher) => {
      if (!regionMap[publisher.region.id]) {
        regionMap[publisher.region.id] = {
          id: publisher.region.id,
          label: publisher.region.name,
          getLabel: getRegionContents,
          dataProperty: 'region',
          value: {
            id: publisher.region.id,
            name: publisher.region.name,
            type: SubscriptionEnumType.Region,
            nrOfArticleTypes: 0,
            nrOfSubscribedArticleTypes: 0,
          },
          subItems: [],
        };
      }
    });

    const treeItems = Object.values(regionMap);

    publishers.forEach((publisher) => {
      // Partially subscribed if subscribed to at least 1 but not all article types
      const subscribedArticleTypes = publisher.articleTypes.filter(
        ({ subscribed }) => subscribed,
      );

      const regionItem = regionMap[publisher.region.id];
      if (!regionItem) {
        console.error('Could not find region for', publisher.id);
        return;
      }

      const publisherItem: SubscriptionItem = {
        id: publisher.id,
        value: {
          id: publisher.id,
          type: SubscriptionEnumType.Publisher,
          name: publisher.name,
          abbreviation: publisher.abbreviation,
          nrOfArticleTypes: publisher.articleTypes.length,
          nrOfSubscribedArticleTypes: subscribedArticleTypes.length,
        },
        dataProperty: 'publisher',
        label:
          (publisher.name.length > 70
            ? publisher.abbreviation
            : `${publisher.name} ${
                publisher.abbreviation ? `(${publisher.abbreviation})` : ''
              }`) ?? publisher.name,
        getLabel: getPublisherContents,
        parentId: publisher.region.id,
        selected: subscribedArticleTypes.length > 0,
        subItems: [],
      };

      publisherFuse.add(publisherItem);

      regionItem.subItems.push(publisherItem);

      regionItem.value.nrOfArticleTypes += publisherItem.value.nrOfArticleTypes;
      regionItem.value.nrOfSubscribedArticleTypes +=
        publisherItem.value.nrOfSubscribedArticleTypes;

      publisherItem.subItems.push({
        id: `${publisher.id}-all-article-types`,
        value: { type: SubscriptionEnumType.ArticleType },
        label: t(MessageKeys.TeamSettingsTabSubscriptionsAllArticleTypes),
        parentId: publisher.id,
        selected: publisher.subscribedToEntirePublisher,
      });

      publisher.articleTypes.forEach((articleType) => {
        const articleTypeItem: SubscriptionItem = {
          id: `${publisher.id}-${articleType.id}`,
          value: {
            id: articleType.id,
            name: articleType.name,
            publisherId: publisher.id,
            type: SubscriptionEnumType.ArticleType,
          },
          getLabel: getArticleTypeContents,
          parentId: publisher.id,
          selected:
            articleType.subscribed || publisher.subscribedToEntirePublisher,
          disabled: publisher.subscribedToEntirePublisher,
        };
        publisherItem.subItems.push(articleTypeItem);
      });
    });

    Object.values(treeItems).forEach((regionItem) => {
      const partiallySelected = isItemPartiallySelected(regionItem);
      regionItem.selected = Boolean(
        regionItem.subItems.find(({ selected }) => selected),
      );
      regionItem.expanded = partiallySelected;
    });

    return [treeItems, publisherFuse];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishers]);

  const searchResult = useMemo(
    () =>
      getFilteredSearchResults(
        searchTerm?.trim() ?? '',
        regionTreeItems,
        publisherFuse,
      ),
    [searchTerm, regionTreeItems, publisherFuse],
  );

  if (searchResult) {
    return (
      <PublisherBooleanTreeMatchedOuterWrapper>
        <PublisherBooleanTreeMatchedWrapper>
          <H3>
            {t(MessageKeys.TeamSettingsTabSubscriptionsMatchedPublishers)}
          </H3>
          {searchResult.length ? (
            <BooleanTree items={searchResult} onChange={onChange} />
          ) : (
            t(MessageKeys.LabelNothingFound)
          )}
        </PublisherBooleanTreeMatchedWrapper>
      </PublisherBooleanTreeMatchedOuterWrapper>
    );
  }

  return <BooleanTree items={regionTreeItems} onChange={onChange} />;
};
