import { Team, TeamInvitation } from '@fcg-tech/regtech-types/regeye';
import { FunctionComponent } from 'react';
import { MyProfileTeamTableRow } from './MyProfileTeamTableRow';

interface MyProfileTeamTableProps {
  teams: Array<Team>;
  invitations: Array<TeamInvitation>;
}

export const MyProfileTeamTable: FunctionComponent<MyProfileTeamTableProps> = ({
  teams,
  invitations,
}) => {
  return (
    <>
      {invitations.map((invitation) => (
        <MyProfileTeamTableRow
          key={invitation.id}
          invitation={invitation}
          team={invitation.team}
        />
      ))}
      {teams.map((team) => (
        <MyProfileTeamTableRow key={team.id} team={team} />
      ))}
    </>
  );
};
