import {
  BooleanToggleCallback,
  FeedArticle,
  PaginatedItems,
} from '@fcg-tech/regtech-types/regeye';
import update, { Spec } from 'immutability-helper';
import { useCallback } from 'react';

type MutatorParamType = PaginatedItems<FeedArticle>;

export interface ArticleListMutator {
  (value: MutatorParamType): MutatorParamType | Promise<MutatorParamType>;
}

export const updateArticleUserMetadata = (
  articleId: string,
  metadata: Partial<FeedArticle['userMetadata']>,
  mutate: (
    mutateCallback: ArticleListMutator,
    shouldRevalidate?: boolean,
  ) => void,
) => {
  mutate((value) => {
    const instructions: Spec<Array<FeedArticle>, never> = {};
    value.items.forEach((feedArticle, i) => {
      if (feedArticle.article?.id === articleId) {
        instructions[i] = {
          userMetadata: {
            $merge: metadata,
          },
        };
      }
    });
    return update(value, {
      items: instructions,
    });
  }, false);
};

export const useBooleanMutatorHandler = <G, T extends Array<G>>(
  value: boolean,
  callback: BooleanToggleCallback,
  ...args: T
) => {
  const eventHandler = useCallback(() => {
    callback(!value, ...args);
  }, [args, callback, value]);

  return eventHandler;
};
