import { classNames } from '@fcg-tech/regtech-utils';
import { Search } from 'lucide-react';
import { parse } from 'query-string';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MessageKeys } from '../../translations/translationTypes';
import {
  FeedArticleSearchPageHeadingSearchString,
  FeedArticleSearchPageHeadingSearchStringPre,
  FeedArticleSearchPageHeadingWrapper,
} from './FeedArticleSearchPage.styles';

export const FeedArticleSearchPageHeading: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  let { searchString } = parse(location.search) ?? {};
  searchString = Array.isArray(searchString)
    ? searchString.join(' ')
    : searchString;

  return (
    <FeedArticleSearchPageHeadingWrapper>
      <Search />
      <div className="heading">
        {t(
          searchString?.length
            ? MessageKeys.FeedArticleSearchResultsHeading
            : MessageKeys.FeedArticleSearchResultsNoSearchTermHeading,
        )}
        {searchString?.length ? (
          <FeedArticleSearchPageHeadingSearchString title={searchString}>
            <FeedArticleSearchPageHeadingSearchStringPre
              className={classNames(searchString.length > 20 && 'small')}
            >
              {searchString}
            </FeedArticleSearchPageHeadingSearchStringPre>
          </FeedArticleSearchPageHeadingSearchString>
        ) : null}
      </div>
    </FeedArticleSearchPageHeadingWrapper>
  );
};
