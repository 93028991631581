import { DataTableColumn } from '@fcg-tech/regtech-datatable';
import { Translator } from '@fcg-tech/regtech-types';
import {
  BooleanToggleCallback,
  FeedArticle,
  FeedArticleDomain,
  FeedArticleListMutator,
  FeedArticleTableColumns,
  StoredFeedArticleTableColumnOptions,
  TableSubType,
  TableType,
} from '@fcg-tech/regtech-types/regeye';
import update from 'immutability-helper';
import { AlertCircle } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import {
  useCurrentUser,
  useFeedArticleActions,
  useTeamActivitiesForArticleActions,
} from '../../api/hooks';
import { articleMetaDataKey } from '../../api/hooks/cacheKeys';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { FeedArticleTableCell } from './FeedArticleTableCell';
import { useFeedArticleTableContext } from './FeedArticleTableContext';

type UseFeedArticleActionHandlersProps = {
  mutateArticle?: (
    articleId: string,
    { bookmarked, read }: { bookmarked?: boolean; read?: boolean },
  ) => void;
  mutateFeedArticleList?: (
    callback: FeedArticleListMutator,
    shouldRevalidate?: boolean,
  ) => void;
};

export const useFeedArticleActionHandlers = ({
  mutateArticle,
  mutateFeedArticleList,
}: UseFeedArticleActionHandlersProps): {
  handleArchiveChange: BooleanToggleCallback;
  handleBookmarkChange: BooleanToggleCallback;
  handleMarkAsReadChange: BooleanToggleCallback;
} => {
  const { data: user } = useCurrentUser();
  const { mutate } = useSWRConfig();
  const { archiveArticle, unarchiveArticle } =
    useTeamActivitiesForArticleActions();

  const { bookmark, markAsRead } = useFeedArticleActions();

  const handleBookmarkChange = useCallback(
    async (isBookmarked: boolean, articleId: string) => {
      await bookmark(articleId, !isBookmarked);
      mutateArticle?.(articleId, { bookmarked: isBookmarked });
    },
    [bookmark, mutateArticle],
  );

  const handleMarkAsReadChange = useCallback(
    async (isRead: boolean, articleId: string) => {
      await markAsRead(articleId, !isRead);
      mutateArticle?.(articleId, { read: isRead });
    },
    [markAsRead, mutateArticle],
  );

  const handleArchiveChange = useCallback(
    async (
      isArchived: boolean,
      articleId: string,
      teamId: string,
      remove = false,
    ) => {
      mutateFeedArticleList?.((current) => {
        let updated = current;
        for (let i = 0; i < current.items.length; i++) {
          const item = current.items[i];
          if (item.article.id === articleId) {
            // No need to do anything if the article is not marked as archived in the team
            if (isArchived === updated.items[i].archived) {
              continue;
            }

            updated = update(updated, {
              items: {
                [i]: {
                  archived: { $set: isArchived },
                },
              },
            });

            if (
              item.team?.id === teamId &&
              updated.items[0].teamArticleMetadata
            ) {
              // Temporary fix - will be overwritten when articles are reloaded
              updated = update(updated, {
                items: {
                  [i]: {
                    teamArticleMetadata: {
                      archived: {
                        $set: {
                          archived: isArchived,
                          lastModified: new Date(),
                          setByUser: user,
                        },
                      },
                    },
                  },
                },
              });
            }

            if (remove) {
              updated = update(updated, {
                items: {
                  $splice: [[i, 1]],
                },
              });
            }
          }
        }

        return updated;
      }, false);

      if (isArchived) {
        await archiveArticle(teamId, articleId);
      } else {
        await unarchiveArticle(teamId, articleId);
      }
      mutate(articleMetaDataKey(articleId));
    },
    [archiveArticle, mutateFeedArticleList, unarchiveArticle, mutate, user],
  );

  return useMemo(
    () => ({
      handleArchiveChange,
      handleBookmarkChange,
      handleMarkAsReadChange,
    }),
    [handleArchiveChange, handleBookmarkChange, handleMarkAsReadChange],
  );
};

const genericTablePaths = [
  routes.teamSubscriptions,
  routes.teamArchive,
  routes.personalAllTeamSubscriptions,
  routes.personalBookmarked,
];
const activityArticlesPath = [
  routes.teamActions,
  routes.companyActions,
  routes.personalAssignedToMe,
  routes.personalCommentedByMe,
];
const actionLogPaths = [
  routes.teamActionsLog,
  routes.companyActionsLog,
  routes.personalEventLog,
];

export const getTableTypeByPath = (
  path: string,
): { tableType?: TableType; tableSubType?: TableSubType } | null => {
  if (activityArticlesPath.some((pattern) => matchPath(pattern, path))) {
    return { tableType: TableType.Actions };
  }
  if (actionLogPaths.some((pattern) => matchPath(pattern, path))) {
    return { tableType: TableType.ActionLog };
  }
  if (genericTablePaths.some((pattern) => matchPath(pattern, path))) {
    if (matchPath(routes.teamArchive, path)) {
      return {
        tableType: TableType.Generic,
        tableSubType: TableSubType.Archive,
      };
    }
    return { tableType: TableType.Generic };
  }

  return null;
};

export const getTableTypeColumns = (
  tableType: TableType,
  feedArticleDomain: FeedArticleDomain | null,
  t: Translator,
): Array<DataTableColumn<FeedArticle>> => {
  const columns: Record<
    FeedArticleTableColumns,
    DataTableColumn<FeedArticle>
  > = {
    [FeedArticleTableColumns.Publisher]: {
      id: FeedArticleTableColumns.Publisher,
      defaultCanSort: true,
      sortDescFirst: false,
      width: 150,
      minWidth: 100,
      Header: t(MessageKeys.ArticleFeedTablePublisher),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) => feedArticle.article.publisher,
    },

    [FeedArticleTableColumns.Title]: {
      id: FeedArticleTableColumns.Title,
      defaultCanSort: true,
      width: 200,
      minWidth: 150,
      Header: t(MessageKeys.ArticleFeedTableTitle),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) => feedArticle.article.title,
    },

    [FeedArticleTableColumns.Content]: {
      id: FeedArticleTableColumns.Content,
      defaultCanSort: true,
      width: 300,
      minWidth: 100,
      Header: t(MessageKeys.ArticleFeedTableDescription),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) => feedArticle.article.description,
    },

    [FeedArticleTableColumns.Type]: {
      id: FeedArticleTableColumns.Type,
      defaultCanSort: true,
      width: 100,
      minWidth: 80,
      Header: t(MessageKeys.ArticleFeedTableArticleType),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) => feedArticle.article.articleTypes,
    },

    [FeedArticleTableColumns.Tags]: {
      id: FeedArticleTableColumns.Tags,
      defaultCanSort: true,
      width: 100,
      minWidth: 80,
      Header: t(MessageKeys.ArticleFeedTableTags),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) => feedArticle.article.articleTags,
    },

    [FeedArticleTableColumns.Date]: {
      id: FeedArticleTableColumns.Date,
      defaultCanSort: true,
      width: 100,
      minWidth: 110,
      maxWidth: 110,
      Header: t(MessageKeys.ArticleFeedTableDate),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) =>
        feedArticle.article.publicationDate,
    },
    [FeedArticleTableColumns.Importance]: {
      id: FeedArticleTableColumns.Importance,
      Header: () => <AlertCircle size="18" />,
      defaultCanSort: true,
      Cell: FeedArticleTableCell,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      accessor: (feedArticle: FeedArticle) =>
        feedArticle.teamArticleMetadata?.importance,
    },

    [FeedArticleTableColumns.TeamTags]: {
      id: FeedArticleTableColumns.TeamTags,
      defaultCanSort: true,
      width: 100,
      minWidth: 80,
      Header: t(MessageKeys.ArticleFeedTableTeamTags),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) =>
        feedArticle.teamArticleMetadata.tags,
    },
    [FeedArticleTableColumns.DueDate]: {
      id: FeedArticleTableColumns.DueDate,
      defaultCanSort: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      Header: t(MessageKeys.ArticleFeedTableDueDate),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) =>
        feedArticle.teamArticleMetadata?.dueDate,
    },
    [FeedArticleTableColumns.Assigned]: {
      id: FeedArticleTableColumns.Assigned,
      defaultCanSort: true,
      width: 100,
      Header: t(MessageKeys.ArticleFeedTableAssignedTo),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) =>
        feedArticle.teamArticleMetadata?.assigned,
    },
    [FeedArticleTableColumns.Teams]: {
      id: FeedArticleTableColumns.Teams,
      defaultCanSort: true,
      width: 100,
      Header: t(MessageKeys.ArticleFeedTableTeam),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) => feedArticle.team?.name,
    },
    [FeedArticleTableColumns.Action]: {
      id: FeedArticleTableColumns.Action,
      defaultCanSort: true,
      width: 100,
      Header: t(MessageKeys.TeamActivityArticlesTableAction),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) => feedArticle.recentTeamActivity,
    },
    [FeedArticleTableColumns.ActionDate]: {
      id: FeedArticleTableColumns.ActionDate,
      defaultCanSort: true,
      width: 100,
      Header: t(MessageKeys.TeamActivityArticlesTableDate),
      Cell: FeedArticleTableCell,
      accessor: (feedArticle: FeedArticle) =>
        feedArticle.recentTeamActivity?.created,
    },
  };

  switch (tableType) {
    case TableType.Generic:
      return [
        columns[FeedArticleTableColumns.Publisher],
        columns[FeedArticleTableColumns.Title],
        columns[FeedArticleTableColumns.Content],
        columns[FeedArticleTableColumns.Type],
        columns[FeedArticleTableColumns.Tags],
        columns[FeedArticleTableColumns.Date],
      ];

    case TableType.Actions:
      return [
        columns[FeedArticleTableColumns.Importance],
        !feedArticleDomain ||
        feedArticleDomain === FeedArticleDomain.Company ||
        feedArticleDomain === FeedArticleDomain.Personal
          ? columns[FeedArticleTableColumns.Teams]
          : null,
        columns[FeedArticleTableColumns.Publisher],
        columns[FeedArticleTableColumns.Title],
        columns[FeedArticleTableColumns.Content],
        columns[FeedArticleTableColumns.Type],
        columns[FeedArticleTableColumns.Tags],
        columns[FeedArticleTableColumns.TeamTags],
        columns[FeedArticleTableColumns.DueDate],
        columns[FeedArticleTableColumns.Assigned],
        columns[FeedArticleTableColumns.Date],
      ].filter(Boolean);

    case TableType.ActionLog:
      return [
        !feedArticleDomain ||
        feedArticleDomain === FeedArticleDomain.Company ||
        feedArticleDomain === FeedArticleDomain.Personal
          ? columns[FeedArticleTableColumns.Teams]
          : null,
        columns[FeedArticleTableColumns.Publisher],
        columns[FeedArticleTableColumns.Title],
        columns[FeedArticleTableColumns.Content],
        columns[FeedArticleTableColumns.Type],
        columns[FeedArticleTableColumns.Tags],
        columns[FeedArticleTableColumns.Action],
        columns[FeedArticleTableColumns.ActionDate],
        columns[FeedArticleTableColumns.Date],
      ].filter(Boolean);

    default:
      return [];
  }
};

export const getInnerScrollHeight = (element: HTMLElement) => {
  if (element) {
    const box = element.getBoundingClientRect();
    const newHeight = window.innerHeight - box.y - 60;
    return newHeight;
  }
  return 0;
};

const columnOptionsKey = (key: TableType) => `${key}-options-v2`;

export const saveTableColumnOptions = (
  key: TableType,
  options: Record<string, Partial<StoredFeedArticleTableColumnOptions>>,
) => {
  localStorage.setItem(columnOptionsKey(key), JSON.stringify(options));
};

export const clearTableColumnOptions = (key?: TableType) => {
  if (key) {
    localStorage.removeItem(columnOptionsKey(key));
  } else {
    Object.values(TableType).forEach((key) =>
      localStorage.removeItem(columnOptionsKey(key)),
    );
  }
};

export const loadTableColumnOptions = (key: TableType) => {
  let options = (JSON.parse(localStorage.getItem(columnOptionsKey(key))) ??
    {}) as Record<string, Partial<StoredFeedArticleTableColumnOptions>>;

  if (Array.isArray(options)) {
    // Reset options to an object. Arrays are old format
    options = {};
  }

  return options;
};

export const mergeTableColumnsAndOptions = (
  columnOptions: Record<string, Partial<StoredFeedArticleTableColumnOptions>>,
  mergeInto: Array<DataTableColumn<FeedArticle>>,
): Array<DataTableColumn<FeedArticle>> => {
  return mergeInto
    .map<DataTableColumn<FeedArticle>>((m) => ({
      ...m,
      width: columnOptions?.[m.id]?.width ?? m.width,
    }))
    .filter((m) => columnOptions?.[m.id]?.visible ?? true)
    .sort((a, b) => {
      const aOrder = columnOptions?.[a.id]?.order ?? 100000;
      const bOrder = columnOptions?.[b.id]?.order ?? 100000;
      return aOrder - bOrder;
    });
};

export const useTableColumnOptions = (
  tableType: TableType,
  feedArticleDomain: FeedArticleDomain,
): [
  columns: Array<DataTableColumn<FeedArticle>>,
  handleColumnOptionsChange: (
    columns: Array<DataTableColumn<FeedArticle>>,
  ) => void,
  setColumns: React.Dispatch<
    React.SetStateAction<Array<DataTableColumn<FeedArticle>>>
  >,
] => {
  const { t } = useTranslation();

  const { columnOptions, updateColumnOptions } = useFeedArticleTableContext();
  const allColumns = useMemo(
    () => getTableTypeColumns(tableType, feedArticleDomain, t),
    [feedArticleDomain, t, tableType],
  );

  const [columns, setColumns] = useState(
    useMemo(
      () => mergeTableColumnsAndOptions(columnOptions, allColumns),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  );

  useEffect(() => {
    setColumns(mergeTableColumnsAndOptions(columnOptions, allColumns));
  }, [allColumns, columnOptions]);

  const handleColumnOptionsChange = useCallback(
    (updatedColumns: Array<DataTableColumn<FeedArticle>>) => {
      updateColumnOptions((previousColumnOptions) => {
        const updatedOptions = Object.fromEntries(
          updatedColumns.map<
            [string, Partial<StoredFeedArticleTableColumnOptions>]
          >((col, i) => [
            col.id,
            {
              width: col.width,
              visible: previousColumnOptions?.[col.id]?.visible ?? true,
              order: i,
            },
          ]),
        );

        return {
          ...previousColumnOptions,
          ...updatedOptions,
        };
      });
    },
    [updateColumnOptions],
  );

  return [columns, handleColumnOptionsChange, setColumns];
};

export const getColumnLabel = (
  column: DataTableColumn<FeedArticle>,
  t: Translator,
): string => {
  if (typeof column.Header === 'string') {
    return column.Header;
  }

  switch (column.id) {
    case FeedArticleTableColumns.Importance:
      return t(MessageKeys.ArticleFeedTableImportance);
  }

  return null;
};
