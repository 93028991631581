import { useTranslation } from 'react-i18next';
import {
  Deemph,
  FormRow,
  FormRowReadOnlyValue,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  SimpleList,
} from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent } from 'react';
import { MessageKeys } from '../../../translations/translationTypes';
import { formatPublisherName } from '../../../utils/formatters';
import { getSubscribedPublishers } from '../../../utils/selectors';
import { ModalFormLabel } from '../../modals';
import {
  NrOfSubscribedArticleTypes,
  SetupWizardModalPage,
} from '../SetupWizard.styles';
import { WizardData, WizardPage } from './wizardTypes';

interface AdminUserSetupSummaryPageProps {
  data: Partial<WizardData>;
  currentPage: WizardPage;
  onPrev: () => void;
  onNext: () => void;
}

export const AdminUserSetupSummaryPage: FunctionComponent<
  AdminUserSetupSummaryPageProps
> = ({ data, currentPage, onPrev, onNext }) => {
  const { t } = useTranslation();
  const subscribed = getSubscribedPublishers(data.publishers);

  return (
    <SetupWizardModalPage
      className={classNames(
        currentPage > WizardPage.Summary && 'after',
        currentPage < WizardPage.Summary && 'before',
      )}
    >
      <ModalBody>
        <FormRow>
          <ModalFormLabel>
            {t(MessageKeys.WizardLabelTeamName)}
          </ModalFormLabel>
          <FormRowReadOnlyValue>
            <div>{data.teamName}</div>
            {data.addMe ? (
              <Deemph as="div" className="with-margin">
                {t(MessageKeys.WizardLabelYouWillBeAddedToThisTeam)}
              </Deemph>
            ) : null}
          </FormRowReadOnlyValue>
        </FormRow>
        <FormRow>
          <ModalFormLabel>
            {t(MessageKeys.WizardLabelTeamMembers)}
          </ModalFormLabel>
          <FormRowReadOnlyValue>
            <SimpleList>
              {data.memberList?.map((email) => (
                <li key={email}>{email}</li>
              ))}
            </SimpleList>
            {data.memberList?.length === 0 ? '-' : ''}
          </FormRowReadOnlyValue>
        </FormRow>
        <FormRow>
          <ModalFormLabel>
            {t(MessageKeys.LabelMessage)}
          </ModalFormLabel>
          <FormRowReadOnlyValue>{data.message ?? '-'}</FormRowReadOnlyValue>
        </FormRow>
        <FormRow>
          <ModalFormLabel>
            {t(MessageKeys.WizardLabelSubscriptions)}
          </ModalFormLabel>
          <FormRowReadOnlyValue>
            <SimpleList>
              {subscribed.map((publisher) => (
                <li key={publisher.id} title={publisher.name}>
                  {formatPublisherName(publisher, 30)} -{' '}
                  <NrOfSubscribedArticleTypes>
                    {publisher.subscribedToEntirePublisher ? (
                      t(MessageKeys.WizardLabelAllArticleType)
                    ) : (
                      t(MessageKeys.WizardLabelCountArticleType, {
                        count: publisher.articleTypes.filter(
                          (type) => type.subscribed,
                        ).length
                      })
                    )}
                  </NrOfSubscribedArticleTypes>
                </li>
              ))}
            </SimpleList>
            {subscribed.length === 0 ? '-' : ''}
          </FormRowReadOnlyValue>
        </FormRow>
      </ModalBody>
      <ModalFooter>
        <ModalButtonRow className="space-between">
          <SecondaryButton onClick={onPrev}>
            {t(MessageKeys.LabelPrevious)}
          </SecondaryButton>
          <PrimaryButton onClick={onNext}>
            {t(MessageKeys.WizardDoneButtonLabel)}
          </PrimaryButton>
        </ModalButtonRow>
      </ModalFooter>
    </SetupWizardModalPage>
  );
};
