import { RecentTeamActivity } from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent } from 'react';
import { useTeamActivityLogForArticle } from '../../api/hooks';
import { useSettings } from '../../api/hooks';
import { formatDateTime, formatName } from '../../utils/formatters';
import { TeamActivityEventDescription } from '../TeamArticlesEventDescription';
import {
  ActivitiesEntryAvatar,
  ActivitiesEntryBody,
  ActivitiesEntryContent,
  ActivitiesEntryMetadata,
  ActivitiesEntryMetadataName,
  ActivitiesEntryWrapper,
  ActivitiesListContainer,
  ActivitiesListOuterContainer,
  ActivitiesListWrapper,
  ActivitiesWrapper,
} from './Activities.styles';

interface RecentTeamActivityProps {
  teamId: string;
  articleId: string;
}

export const Activities: FunctionComponent<RecentTeamActivityProps> = ({
  teamId,
  articleId,
}) => {
  const { data: settings } = useSettings({ suspense: false });

  const { data: teamActivities } = useTeamActivityLogForArticle(
    teamId,
    articleId,
  );

  return (
    <ActivitiesWrapper>
      <ActivitiesListOuterContainer>
        <ActivitiesListContainer>
          <ActivitiesListWrapper>
            {teamActivities?.map((activity) => (
              <ActivitiesEntryWrapper
                key={`${activity.created.getTime()} - ${activity.action} - ${
                  activity.createdByUser.username
                }`}
              >
                <ActivitiesEntryAvatar>{`${(activity.createdByUser.alias ??
                  activity.createdByUser
                    .username)[0].toUpperCase()}`}</ActivitiesEntryAvatar>
                <ActivitiesEntryContent>
                  <ActivitiesEntryMetadata>
                    {formatDateTime(activity.created, settings)}
                  </ActivitiesEntryMetadata>
                  <ActivitiesEntryBody>
                    <TeamActivityEventDescription
                      recentTeamActivity={activity}
                    />
                  </ActivitiesEntryBody>
                </ActivitiesEntryContent>
              </ActivitiesEntryWrapper>
            ))}
          </ActivitiesListWrapper>
        </ActivitiesListContainer>
      </ActivitiesListOuterContainer>
    </ActivitiesWrapper>
  );
};
