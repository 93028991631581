import styled from 'styled-components';

export const CommentsWrapper = styled.div`
  width: 100%;
`;

export const CommentListOuterContainer = styled.div`
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.2rem;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    pointer-events: none;
  }
`;

export const CommentListContainer = styled.div`
  overflow-y: scroll;
  max-height: 30rem;
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;
  scroll-behavior: smooth;
`;
