import styled from 'styled-components';

export const FeedArticleSearchPageHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;

  & > .lucide {
    margin-right: 1rem;
  }
`;

export const FeedArticleSearchPageHeadingSearchString = styled.div`
  display: inline-flex;
  border: 1px solid #ddd;
  background-color: #eee;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  margin: 0 0 0 1rem;
  min-width: 0;
`;
export const FeedArticleSearchPageHeadingSearchStringPre = styled.pre`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;

  &.small {
    font-size: 1.6rem;
  }
`;
