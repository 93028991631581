import styled, { css } from 'styled-components';

export const MyProfileTeamTableRowWrapper = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid #ccc;
    display: flex;
    height: 5.6rem;
    align-items: center;
    padding: 0 1.6rem;

    &:last-child {
      border-bottom: 1px solid #ccc;
    }

    .team-name {
      flex: 1;
    }

    .team-invitation {
      flex: 1;
      display: flex;
      align-items: center;

      .team-invitation_tag {
        background: ${theme.palette.MainColorAccent.main};
        color: ${theme.palette.MainColorAccent.mainContrast};
        font-weight: 600;
        border-radius: 0.3rem;
        padding: 0.3rem 0.6rem;
        font-size: 1.1rem;
        margin-right: 0.6rem;
      }

      .team-invitation_description {
        color: #666;
        margin-left: 1rem;
        font-weight: 400;
      }
    }

    .toggle-wrapper {
      width: 30rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .action-wrapper {
      width: 20rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button + .button {
        margin-left: 1.6rem;
      }
    }
  `}
`;
