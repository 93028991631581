import { css } from 'styled-components';

export const getFoldingAnimation = (prefix: string) => css`
  & {
    opacity: 0;
    transform: translateY(25%) rotateX(45deg);
  }

  &.${getFoldingAnimationName(prefix)}-enter {
    opacity: 0;
    transform: translateY(25%) rotateX(45deg);
  }

  &.${getFoldingAnimationName(prefix)}-enter-active {
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    opacity: 1;
    transform: translateY(0) rotateX(0);
  }

  &.${getFoldingAnimationName(prefix)}-enter-done {
    opacity: 1;
    transform: translateY(0) rotateX(0);
  }

  &.${getFoldingAnimationName(prefix)}-exit {
    opacity: 1;
    transform: translateY(0) rotateX(0);
  }

  &.${getFoldingAnimationName(prefix)}-exit-active {
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    opacity: 0;
    transform: translateY(-25%) rotateX(-45deg);
  }
`;

export const getFoldingAnimationName = (prefix: string) => `${prefix}-folding`;
