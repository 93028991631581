/* eslint-disable @typescript-eslint/ban-types */
import { DataTableCellProps } from '@fcg-tech/regtech-datatable';

interface DataTableCellWithEmptyMessageProps<D extends object = {}>
  extends DataTableCellProps<D> {
  emptyMessage: string;
}

export const DataTableCellWithEmptyMessage = <D extends object = {}>({
  emptyMessage,
  ...props
}: DataTableCellWithEmptyMessageProps<D>) => {
  return (
    <>{props.value?.toString()?.length ? props.value : <i>{emptyMessage}</i>}</>
  );
};
