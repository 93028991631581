import { CrossIcon } from '../Icons';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useEffect, useState, PropsWithChildren } from 'react';
import FocusLock from 'react-focus-lock';
import { SheetCloseButton, SheetWrapper } from './Sheet.styles';

interface SheetProps {
  startOpen?: boolean;
  className?: string;
  onRequestClose?: () => void;
}

export const Sheet: FunctionComponent<PropsWithChildren<SheetProps>> = ({
  startOpen,
  className,
  onRequestClose,
  children,
}) => {
  const [isOpen, setOpen] = useState(startOpen);
  useEffect(() => {
    setTimeout(() => setOpen(true), 1);
  }, []);

  return (
    <FocusLock>
      <SheetWrapper className={classNames(className, isOpen && 'open')}>
        {onRequestClose ? (
          <SheetCloseButton onClick={onRequestClose}>
            <CrossIcon size="16" />
          </SheetCloseButton>
        ) : null}
        {children}
      </SheetWrapper>
    </FocusLock>
  );
};
