import { baseFetch, constructUrl } from '@fcg-tech/regtech-api-utils';
import { LoginInfoResponse } from '../types';

export const useAuthApi = (apiBaseUrl: string) => {
  return {
    async selectCognito(email: string) {
      const result = await baseFetch(
        constructUrl(
          `${apiBaseUrl}/LoginInfo`,
          undefined,
          { email },
        ),
      );

      const data =
        (await result.json()) as LoginInfoResponse;

      return data;
    },
  };
};
