import {
  GlobalActions,
  TeamInvitationList,
} from '@fcg-tech/regtech-types/regeye';
import { useMemo } from 'react';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import { ScopedMutator } from 'swr/dist/types';
import { useAccessControl } from '../../components/AccessControl';
import { useInitContext } from '../../components/InitContext';
import { useApi } from '../apiUtils';
import { InvitationsApi } from '../schema';
import {
  allTeamsKey,
  assignedToMeCount,
  companiesKey,
  invitationsForUserKey,
  teamInvitationsKey,
} from './cacheKeys';

export const useTeamInvitations = (
  teamId: string,
  config: SWRConfiguration = {},
) => {
  const api = useApi<InvitationsApi>('InvitationsApi');
  const { error: initDataError } = useInitContext();
  return useSWR<TeamInvitationList['items']>(
    initDataError ? null : teamInvitationsKey(teamId),
    async () => (await api.listTeamInvitations({ teamId }))?.items,
    {
      suspense: true,
      ...config,
    },
  );
};

export const useCompanyTeamInvitations = (
  companyId: string,
  teamId: string,
  config: SWRConfiguration = {},
) => {
  const api = useApi<InvitationsApi>('InvitationsApi');
  const { error: initDataError } = useInitContext();
  return useSWR<TeamInvitationList['items']>(
    initDataError ? null : teamInvitationsKey(teamId),
    async () =>
      (await api.companyListTeamInvitations({ companyId, teamId }))?.items,
    {
      suspense: true,
      ...config,
    },
  );
};

export const reloadCompanyTeamInvitations = (
  teamId: string,
  mutate: ScopedMutator<unknown>,
) => {
  mutate(teamInvitationsKey(teamId));
};

export const useTeamInvitationActions = (teamId: string | null) => {
  const api = useApi<InvitationsApi>('InvitationsApi');
  const { mutate } = useSWRConfig();
  return useMemo(
    () => ({
      createTeamInvitation: async (
        emails: string | Array<string>,
        message: string,
        overrideTeamId?: string,
      ) => {
        const usernames = Array.isArray(emails) ? emails : [emails];
        try {
          const result = await api.createTeamInvitation({
            teamId: overrideTeamId ?? teamId,
            invitationsCreate: {
              message,
              usernames,
            },
          });

          mutate(teamInvitationsKey(overrideTeamId ?? teamId));
          return result;
        } catch (err) {
          // TODO: Erorr logging
          throw new Error(
            `Could not invite users to team ${overrideTeamId ?? teamId}`,
          );
        }
      },

      companyCreateTeamInvitation: async (
        companyId: string,
        emails: string | Array<string>,
        message: string,
        overrideTeamId?: string,
      ) => {
        const usernames = Array.isArray(emails) ? emails : [emails];
        try {
          const result = await api.companyCreateTeamInvitation({
            teamId: overrideTeamId ?? teamId,
            companyId,
            invitationsCreate: { message, usernames },
          });

          mutate(teamInvitationsKey(overrideTeamId ?? teamId));
          return result;
        } catch (err) {
          // TODO: Error logging
          throw new Error(
            `Could not invite users to team ${overrideTeamId ?? teamId}`,
          );
        }
      },

      removeTeamInvitation: async (
        invitationId: string,
        overrideTeamId?: string,
      ) => {
        const result = await api.removeInvitationToTeam({
          invitationId,
          teamId: overrideTeamId ?? teamId,
        });
        mutate(
          teamInvitationsKey(overrideTeamId ?? teamId),
          result.items,
          false,
        );
      },
      companyRemoveTeamInvitation: async (
        companyId: string,
        invitationId: string,
        overrideTeamId?: string,
      ) => {
        const result = await api.companyRemoveInvitationToTeam({
          companyId,
          invitationId,
          teamId: overrideTeamId ?? teamId,
        });
        mutate(
          teamInvitationsKey(overrideTeamId ?? teamId),
          result.items,
          false,
        );
      },
    }),
    [api, mutate, teamId],
  );
};

export const useInvitations = (config: SWRConfiguration = {}) => {
  const api = useApi<InvitationsApi>('InvitationsApi');
  const { error: initDataError } = useInitContext();
  const allowed = useAccessControl(GlobalActions.UserInvitationList);
  return useSWR<TeamInvitationList['items']>(
    !allowed || initDataError ? null : invitationsForUserKey(),
    async () => (await api.getInvitationsForUser())?.items,
    {
      suspense: true,
      ...config,
    },
  );
};

export const reloadInvitations = (mutate: ScopedMutator<unknown>) =>
  mutate(invitationsForUserKey());

export const useInvitationActions = (invitationId: string) => {
  const api = useApi<InvitationsApi>('InvitationsApi');
  const { mutate } = useSWRConfig();
  return useMemo(
    () => ({
      acceptInvitation: async () => {
        const result = await api.acceptInvitation({ invitationId });

        mutate(invitationsForUserKey(), result.items, false);
        mutate(allTeamsKey());
        mutate(companiesKey());
        mutate(assignedToMeCount);
      },

      rejectInvitation: async () => {
        const result = await api.rejectInvitation({ invitationId });

        mutate(invitationsForUserKey(), result.items, false);

        return;
      },
    }),
    [api, invitationId, mutate],
  );
};
