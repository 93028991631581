import { BellIconButton } from '@fcg-tech/notifications';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { useAuthContext } from '@fcg-tech/regtech-auth';
import {
  BookIcon,
  IconButton,
  PrimaryButton,
  TooltipNext as Tooltip,
  useFlash,
  useSideBar,
} from '@fcg-tech/regtech-components';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDocumentationUrl, useNotifications } from '../../api/hooks';
import { GlobalActions } from '../../api/schema';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { AccessControl } from '../AccessControl';
import { ErrorBoundary } from '../ErrorBoundary';
import { useInitContext } from '../InitContext';
import { SearchBar } from '../SearchBar';
import { SuspenseLoader } from '../SuspensLoader';
import {
  HeaderActionButtonWrapper,
  HeaderLeftContent,
  HeaderMobileHamburgerButton,
  HeaderRightContent,
  HeaderWrapper,
} from './Header.styles';
import { HeaderHeading } from './HeaderHeading';

export const Header: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useAuthContext();
  const { error: initDataError } = useInitContext();
  const { toggleMobileMenu } = useSideBar();
  const [loading, setLoading] = useState(false);
  const addFlash = useFlash();

  const handleLogout = useCallback(() => logout(), [logout]);

  const { data } = useNotifications(
    {
      unreadOnly: true,
    },
    {
      suspense: false,
    },
  );

  const countStr = useMemo(() => {
    if (data) {
      if (
        data.items.length > 99 ||
        (data.items.length === 99 && data.truncated)
      ) {
        return '99+';
      }
      return data.items.length;
    }

    return 0;
  }, [data]);

  const handleDocsClick = useCallback(async () => {
    try {
      setLoading(true);
      const url = await getDocumentationUrl();
      window.open(url, '_blank');
    } catch (e) {
      addFlash({
        content: t(MessageKeys.ErrorGeneric),
        level: 'error',
      });
    }
    setLoading(false);
  }, [addFlash, t]);

  return (
    <HeaderWrapper>
      {initDataError ? null : (
        <>
          <HeaderMobileHamburgerButton onClick={toggleMobileMenu} />
          <HeaderLeftContent>
            <ErrorBoundary>
              <SuspenseLoader silent>
                <HeaderHeading />
              </SuspenseLoader>
            </ErrorBoundary>
          </HeaderLeftContent>
        </>
      )}
      <HeaderRightContent>
        <AccessControl requiredPermissions={[GlobalActions.ViewGlobalSearch]}>
          <ErrorBoundary>
            <SearchBar />
          </ErrorBoundary>
        </AccessControl>
        <HeaderActionButtonWrapper>
          <BellIconButton
            id="notifications-button-wrapper"
            count={countStr}
            href={constructUrl(routes.notifications)}
            tooltip={t(MessageKeys.NotificationsUnreadCountTooltip, {
              count: data?.items?.length ?? 0,
            })}
          />
        </HeaderActionButtonWrapper>
        <HeaderActionButtonWrapper>
          <Tooltip
            content={t(MessageKeys.DocumentationLinkTooltip)}
            showDelay={1000}
            placement="bottom-end"
          >
            <IconButton
              id="documentation-button-wrapper"
              loading={loading}
              onClick={handleDocsClick}
            >
              <BookIcon />
            </IconButton>
          </Tooltip>
        </HeaderActionButtonWrapper>
        {isAuthenticated === true ? (
          <PrimaryButton data-kind="logout-button" onClick={handleLogout}>
            Logout
          </PrimaryButton>
        ) : null}
      </HeaderRightContent>
    </HeaderWrapper>
  );
};
