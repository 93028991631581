import { SpinningLoadingIcon } from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { PropsWithChildren } from 'react';
import { ActionIconButtonWrapper } from './ActionIconButton.styles';

interface ActionIconButtonProps
  extends Partial<React.PropsWithoutRef<typeof HTMLDivElement>> {
  message?: string;
  className?: string;
  active?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const ActionIconButton = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<ActionIconButtonProps>
>(
  (
    {
      children,
      message,
      active,
      loading,
      className,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => {
    return (
      <ActionIconButtonWrapper
        ref={ref}
        role="button"
        className={classNames(
          'action-icon-button',
          className,
          active && 'active',
          active === false && 'inactive',
          disabled && 'disabled',
          loading && loading,
        )}
        onClick={!disabled ? onClick : null}
        {...props}
      >
        <div className={classNames('content', loading && 'hidden')}>
          <div className="child-wrapper">{children}</div>
          {message ? <div className="action-message">{message}</div> : null}
        </div>
        {loading ? (
          <div className="lodaing-wrapper">
            <SpinningLoadingIcon size="20" />
          </div>
        ) : null}
      </ActionIconButtonWrapper>
    );
  },
);
