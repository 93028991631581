import {
  H3,
  SecondaryButton,
  TabBar,
  TextField,
} from '@fcg-tech/regtech-components';
import { TabPanel } from 'reakit';
import styled from 'styled-components';

export const TeamAdministrationWrapper = styled.div`
  min-height: 0;
`;

export const TeamAdministrationTeamTabBar = styled(TabBar)`
  position: sticky;
  top: 0px;
  z-index: 1;
  box-shadow: none;
  border: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

export const TeamAdministrationTeamTabContentWrapper = styled.div`
  flex: 1;
  padding-top: 1.6rem;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

export const TeamAdministrationTeamTabPanel = styled(TabPanel)`
  outline: 0;
`;

export const TeamAdministrationTeamTabContentInnerWrapper = styled.div`
  & + & {
    padding-bottom: 4.6rem;
  }
  padding-right: 1.6rem;
  padding-left: 2.6rem;
`;

export const TeamAdministrationTeamTabButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.6rem;

  & > button + button {
    margin-left: 0.8rem;
  }
`;

export const TeamAdministrationTeamDetailsForm = styled.form`
  width: 40rem;
`;

export const TeamAdministrationTeamDetailsDangerZone = styled.div`
  border-top: 2px solid red;
  padding-top: 1.6rem;
  padding-left: 1.6rem;
  margin-top: 5.6rem;
`;

export const TeamAdministrationDeleteTeamButton = styled(SecondaryButton)`
  background-color: red;
  border: red;
  font-weight: 600;
  color: white;

  &:active {
    filter: brightness(90%);
    background-color: red;
    border: red;
    box-shadow: 0 2px 4px 0 rgba(255, 0, 0, 0.3);
  }
`;

export const TeamAdministrationPageTeaMemberDetailsInvitationsHeading = styled(
  H3,
)`
  margin-top: 3.6rem;
`;

export const TeamAdministrationDetailsTeamNameInput = styled(TextField)`
  width: 40rem;
  flex: initial;
`;
