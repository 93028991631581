import { Showcase, ShowcaseReel } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeShowcase, Translator } from '../../types';
import { ShowCaseContent } from './ShowCaseContent';
import { useShowcase } from './useShowcase';

export const NewUserShowcase: FunctionComponent = () => {
  const { t } = useTranslation();
  const [display, handleExit] = useShowcase(RegeyeShowcase.NewUser, {
    initialDelay: 1500,
  });
  return display ? (
    <Showcase reel={newUserShowcaseReel(t)} onExit={handleExit} />
  ) : null;
};

const newUserShowcaseReel = (t: Translator): ShowcaseReel => ({
  id: RegeyeShowcase.NewUser,
  skippable: true,
  waitForQuerySelector: '#notifications-button-wrapper',
  entries: [
    {
      content: (entry) => (
        <ShowCaseContent
          entry={entry}
          heading={t(MessageKeys.ShowCaseNewUserWelcomeToRegeyeHeading)}
          content={t(MessageKeys.ShowCaseNewUserWelcomeToRegeyeBody)}
        />
      ),
      paddedContentStyle: {
        maxWidth: '60rem',
      },
      // illustrationBg: 'rgba(69, 123, 157, 1)',
      // illustrationSrc: 'assets/showcase/newuser/filter-button-illustration.png',
    },
    {
      content: t(MessageKeys.ShowCaseNewUserWelcomeToRegeyeNotificationsBody),
      querySelector: '#notifications-button-wrapper',
      padding: 5,
      // illustrationStyle: {
      //   backgroundSize: 'contain',
      // },
      // illustrationBg: 'rgba(69, 123, 157, 1)',
      // illustrationSrc:
      //   'assets/showcase/newuser/filter-button-illustration-2.png',
    },
    {
      content: t(MessageKeys.ShowCaseNewUserWelcomeToRegeyeDocumentationBody),
      querySelector: '#documentation-button-wrapper',
      padding: -5,
      // illustrationStyle: {
      //   backgroundSize: 'contain',
      // },
      // illustrationBg: 'rgba(69, 123, 157, 1)',
      // illustrationSrc:
      //   'assets/showcase/newuser/filter-button-illustration-2.png',
    },
  ],
});
