import { Company, TableSubType, Team } from '@fcg-tech/regtech-types/regeye';
import React, { useCallback, useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { pageContext } from '../../state/pageContext';
import {
  FeedArticleDomain,
  PersonalDomain,
  StoredFeedArticleTableColumnOptions,
  TableType,
} from '../../types';
import {
  loadTableColumnOptions,
  saveTableColumnOptions,
} from './feedArticleTableHelpers';

type UpdateColumnOptions = React.Dispatch<
  React.SetStateAction<
    Record<string, Partial<StoredFeedArticleTableColumnOptions>>
  >
>;

type FeedArticleTableContextValue = {
  columnOptions: Record<string, Partial<StoredFeedArticleTableColumnOptions>>;
  updateColumnOptions: UpdateColumnOptions;
  team?: Team;
  teamId?: string;
  company?: Company;
  companyId?: string;
  tableType: TableType;
  tableSubType?: TableSubType;
  domain: FeedArticleDomain | null;
  personalDomain?: PersonalDomain;
  isFilterVisible: boolean;
  setFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterDisabled: boolean;
  setFilterDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  handleFilterToggle: () => void;
};

export const FeedArticleTableContext =
  React.createContext<FeedArticleTableContextValue>(undefined);

export type FeedArticleTableContextProviderProps = Pick<
  FeedArticleTableContextValue,
  | 'tableType'
  | 'tableSubType'
  | 'domain'
  | 'personalDomain'
  | 'teamId'
  | 'companyId'
  | 'team'
  | 'company'
>;

export const FeedArticleTableContextProvider = ({
  children,
  tableType,
  tableSubType,
  domain,
  personalDomain,
  teamId,
  companyId,
  team,
  company,
}: React.PropsWithChildren<FeedArticleTableContextProviderProps>) => {
  const [isFilterVisible, setFilterVisible] = React.useState(false);
  const [isFilterDisabled, setFilterDisabled] = React.useState(false);
  const [columnOptions, setColumnOptions] = React.useState(
    loadTableColumnOptions(tableType),
  );

  const updateColumnOptions = React.useCallback<UpdateColumnOptions>(
    (columnsOrUpdater) => {
      setColumnOptions((prevColumnOptions) => {
        let newData: Record<
          string,
          Partial<StoredFeedArticleTableColumnOptions>
        >;
        if (typeof columnsOrUpdater === 'function') {
          newData = columnsOrUpdater(prevColumnOptions);
        } else {
          newData = columnsOrUpdater;
        }

        saveTableColumnOptions(tableType, newData);

        return newData;
      });
    },
    [tableType],
  );

  const handleFilterToggle = useCallback(
    () => setFilterVisible((old) => !old),
    [],
  );

  const [, setPageContext] = useRecoilState(pageContext);

  useEffect(() => {
    setTimeout(() => setPageContext({ tableType, tableSubType }), 0);
  }, [tableType, tableSubType, setPageContext]);

  const value = React.useMemo<FeedArticleTableContextValue>(
    () => ({
      columnOptions,
      updateColumnOptions,
      tableType,
      tableSubType,
      domain,
      teamId,
      companyId,
      team,
      company,
      personalDomain,
      isFilterVisible,
      handleFilterToggle,
      setFilterVisible,
      isFilterDisabled,
      setFilterDisabled,
    }),
    [
      columnOptions,
      updateColumnOptions,
      tableType,
      tableSubType,
      domain,
      teamId,
      companyId,
      team,
      company,
      personalDomain,
      isFilterVisible,
      handleFilterToggle,
      isFilterDisabled,
    ],
  );

  return (
    <FeedArticleTableContext.Provider value={value}>
      {children}
    </FeedArticleTableContext.Provider>
  );
};

export const useFeedArticleTableContext = () =>
  useContext(FeedArticleTableContext);
