import { ToggleSwitch } from '@fcg-tech/regtech-components';
import { Company, Team, TeamInvitation } from '@fcg-tech/regtech-types/regeye';
import update from 'immutability-helper';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';
import { useSettingsActions } from '../../api/hooks';
import { companiesKey, companyKey } from '../../api/hooks/cacheKeys';
import { NoContentMessage } from '../../components/generic';
import { MessageKeys } from '../../translations/translationTypes';
import {
  MyProfileCompanyNameRow,
  MyProfileCompanyNameRowCompanyIcon,
  MyProfileCompanyNameRowHeader,
  MyProfileCompanyWrapper,
  MyProfileTeamsAndInvitationsHeading,
} from './MyProfilePage.styles';
import { MyProfileTeamTable } from './MyProfileTeamTable';

interface MyProfileCompanyProps {
  company: Company;
  teams: Array<Team>;
  invitations: Array<TeamInvitation>;
}

export const MyProfileCompany: FunctionComponent<MyProfileCompanyProps> = ({
  company,
  teams,
  invitations,
}) => {
  const { t } = useTranslation();
  const { settings, patchSettings } = useSettingsActions();
  const { mutate } = useSWRConfig();

  const handleShowChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const hidden = !event.target.checked;
      const index = settings.companies.findIndex((t) => t.id === company.id);
      await patchSettings({
        companies: update(
          settings.companies,
          index >= 0
            ? {
                [index]: { hidden: { $set: hidden } },
              }
            : { $push: [{ id: company.id, hidden }] },
        ),
      });
      mutate(companiesKey());
      mutate(companyKey(company.id));
    },
    [settings.companies, mutate, company.id, patchSettings],
  );

  const hidden = useMemo(
    () => settings.companies.find((c) => c.id === company.id)?.hidden,
    [company.id, settings.companies],
  );

  return (
    <MyProfileCompanyWrapper>
      <MyProfileCompanyNameRow>
        <MyProfileCompanyNameRowHeader>
          <MyProfileCompanyNameRowCompanyIcon /> {company.name}
        </MyProfileCompanyNameRowHeader>
        <ToggleSwitch
          checked={!hidden}
          offLabel={t(MessageKeys.LabelHide)}
          onLabel={t(MessageKeys.LabelShow)}
          onChange={handleShowChange}
        />
      </MyProfileCompanyNameRow>
      {teams.length || invitations.length ? (
        <>
          <MyProfileTeamsAndInvitationsHeading>
            {t(MessageKeys.MyProfileTabMyTeamsCompanyTeamsAndInvitations)}
          </MyProfileTeamsAndInvitationsHeading>
          <MyProfileTeamTable teams={teams} invitations={invitations} />
        </>
      ) : (
        <NoContentMessage>{t(MessageKeys.LabelNoTeamsFound)}</NoContentMessage>
      )}
    </MyProfileCompanyWrapper>
  );
};
