import { FormRow } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedArticleTableContextProvider } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { MessageKeys } from '../../translations/translationTypes';
import { TableType } from '../../types';
import {
  MyProfileFormRowLabel,
  MyProfileSection,
} from './MyProfilePage.styles';
import { MyProfileTableColumnSelector } from './MyProfileTableColumnSelector';

export const MyProfileColumnSettings: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <MyProfileSection className="side-padding">
        <div>
          <FormRow>
            <MyProfileFormRowLabel>
              {t(MessageKeys.MyProfileTabMySettingsFeedArticleColumns)}
            </MyProfileFormRowLabel>
            <FeedArticleTableContextProvider
              domain={null}
              tableType={TableType.Generic}
            >
              <MyProfileTableColumnSelector />
            </FeedArticleTableContextProvider>
          </FormRow>
          <FormRow>
            <MyProfileFormRowLabel>
              {t(MessageKeys.MyProfileTabMySettingsActionsColumns)}
            </MyProfileFormRowLabel>
            <FeedArticleTableContextProvider
              domain={null}
              tableType={TableType.Actions}
            >
              <MyProfileTableColumnSelector />
            </FeedArticleTableContextProvider>
          </FormRow>
          <FormRow>
            <MyProfileFormRowLabel>
              {t(MessageKeys.MyProfileTabMySettingsActionsLogColumns)}
            </MyProfileFormRowLabel>
            <FeedArticleTableContextProvider
              domain={null}
              tableType={TableType.ActionLog}
            >
              <MyProfileTableColumnSelector />
            </FeedArticleTableContextProvider>
          </FormRow>
        </div>
      </MyProfileSection>
    </>
  );
};
