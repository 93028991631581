import { Environment } from './types';

export const environment: Environment = {
  production: true,
  customerFacing: false,
  showErrorTraces: false,
  authCallbackUrl: 'https://regeye-sigma.fcgapps.com/auth/callback',
  oauthScopes: 'openid+email',
  selectCognitoApiBaseUrl:
    'https://dlpieihbj6.execute-api.eu-north-1.amazonaws.com',
  hostedLoginBaseUrl: 'https://auth.fcgapps.com',
  apiBaseUrl: 'https://3m3h0vezgd.execute-api.eu-north-1.amazonaws.com/Prod',
  notificationApiBaseUrl:
    'https://376f3li6m1.execute-api.eu-north-1.amazonaws.com',
  documentationApiUrl:
    'https://hrs1aiiini.execute-api.eu-north-1.amazonaws.com/documentation/regeye',
};
