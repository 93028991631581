import { atom } from 'recoil';
import { TableSubType, TableType } from '../types';

export type PageContext = {
  tableType: TableType;
  tableSubType?: TableSubType;
};

export const pageContext = atom<PageContext | null>({
  key: 'PageContext',
  default: null,
});
