import { randId } from '@fcg-tech/regtech-components';

import { SearchRow } from '../types';

const andRe = /\s+and\s+/gi;
const orRe = /\s+or\s+/i;
const varRe = /\$\d+/g;

export const getSearchRows = (searchString = ''): Array<SearchRow> => {
  const sentenceRe = /"([^"]+)"/g;
  const saved: Record<string, string> = {};
  let index = 0;

  // Replace all sentences with variables
  let replacedSearchString = searchString.replace(
    sentenceRe,
    (matched, content) => {
      index += 1;
      saved[`$${index}`] = content;
      return `$${index}`;
    },
  );

  // Replace all occurencies of "and" with a single whitespace
  replacedSearchString = replacedSearchString.replace(andRe, ' ');

  // Split on OR
  const replacedSearchStringArr = replacedSearchString.split(orRe);

  // Re-insert sentances and convert to searchrows
  return replacedSearchStringArr.map<SearchRow>((str, i) => {
    const text = str.replace(varRe, (matched) => `"${saved[matched]}"`);
    return {
      id: randId(),
      text,
      operator: i < replacedSearchStringArr.length - 1 ? 'OR' : undefined,
    };
  });
};

export const getSearchString = (arr: Array<SearchRow>) => {
  if (!arr?.length) {
    return '';
  }
  const result = arr.filter((row) => row.text?.length);

  return result
    .map((row, i) => {
      return `${row.text}${
        row.operator && i < result.length - 1 ? ` ${row.operator}` : ''
      }`;
    })
    .join(' ');
};
