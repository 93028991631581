import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@fcg-tech/regtech-auth';
import { PrimaryButton } from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { MessageKeys } from '../../translations/translationTypes';
import {
  Error403LogoutContaienr,
  ErrorInfo,
  ErrorPageWrapper,
  ErrorStatusHeading,
  StyledRegEyeText,
} from './ErrorMessage.styles';

interface Error404Props {
  response?: Response;
}

export const Error403: FunctionComponent<Error404Props> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { logout } = useAuthContext();
  const handleLogout = useCallback(() => logout(), [logout]);

  return (
    <ErrorPageWrapper>
      <Error403LogoutContaienr>
        <PrimaryButton onClick={handleLogout}>Logout</PrimaryButton>
      </Error403LogoutContaienr>
      <StyledRegEyeText color={theme?.palette?.MainColor?.main ?? 'black'} />
      <ErrorStatusHeading>
        {t(MessageKeys.ErrorAuthorizationRequired)}
      </ErrorStatusHeading>
      <ErrorInfo>
        {t(MessageKeys.FallbackNoCompaniesMessage)}
      </ErrorInfo>
    </ErrorPageWrapper>
  );
};
