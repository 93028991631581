import { FunctionComponent, PropsWithChildren } from 'react';
import { Element } from 'slate';
import { RenderElementProps } from 'slate-react';
import { RichTextMentionElement } from './RichTextArea.types';

interface RichTextElementProps {
  attributes: RenderElementProps['attributes'];
  element: Element;
  Mention: FunctionComponent<
    PropsWithChildren<
      RenderElementProps['attributes'] & {
        className?: string;
        contentEditable?: boolean;
      }
    >
  >;
}

export const RichTextElement: FunctionComponent<
  PropsWithChildren<RichTextElementProps>
> = ({ Mention, ...props }) => {
  const { attributes, element, children } = props;
  if (Element.isElementType(element, 'mention')) {
    return (
      <Mention
        contentEditable={false}
        {...attributes}
        className="rich-text--mention"
      >
        @{(element as RichTextMentionElement).id}
        {children}
      </Mention>
    );
  }
  return (
    <p {...attributes} className="rich-text--paragraph">
      {children}
    </p>
  );
};
