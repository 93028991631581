import styled, { css } from 'styled-components';
import Color from 'color';

export const AlertBoxIconWrapper = styled.div`
  padding: 0 2rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlertBoxWrapper = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    border-radius: 0.8rem;
    border: 1px solid
      ${Color(theme?.colors?.info?.main ?? 'blue')
        .darken(1.4)
        .string()};
    padding: 1.6rem 2rem;
    background-color: ${theme?.colors?.info?.main ?? 'blue'};
    color: ${theme?.colors?.info?.contrast ?? 'white'};

    &.success {
      border-color: ${Color(theme?.colors?.success?.main ?? 'green')
        .darken(1.4)
        .string()};
      background-color: ${theme?.colors?.success?.main ?? 'green'};
      color: ${theme?.colors?.success?.contrast ?? 'white'};
    }

    &.error {
      border-color: ${Color(theme?.colors?.error?.main ?? 'red')
        .darken(1.4)
        .string()};
      background-color: ${theme?.colors?.error?.main ?? 'red'};
      color: ${theme?.colors?.error?.contrast ?? 'white'};
    }

    &.warning {
      border-color: ${Color(theme?.colors?.warning?.main ?? 'orange')
        .darken(1.4)
        .string()};
      background-color: ${theme?.colors?.warning?.main ?? 'orange'};
      color: ${theme?.colors?.warning?.contrast ?? 'black'};
    }

    &.slim {
      font-size: 1.2rem;
      padding: 0.4rem 0.6rem;

      & > ${AlertBoxIconWrapper} {
        padding: 0 1rem 0 0;
      }
    }
  `}
`;
