import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  line-height: 1rem;
  font-size: 1.4rem;
  font-weight: 800;
`;
