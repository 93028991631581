import { CheckCircleIcon, excludeProps } from '@fcg-tech/regtech-components';
import { DataTable, DataTableComponent } from '@fcg-tech/regtech-datatable';
import { foundation } from '@fcg-tech/regtech-design-system';
import { FilterBar } from '@fcg-tech/regtech-filter';
import styled, { css } from 'styled-components';
import { FeedArticle } from '../../types';
import { SuspenseLoader } from '../SuspensLoader';

export const FeedArticleTableWrapper = styled.div`
  max-height: 100%;
  flex: 1;
  overflow: hidden;
  position: relative;
`;

export const FeedArticleTableNoContent = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;
  font-size: 2.2rem;
  font-weight: 600;
`;

export const FeedArticleLoadingWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;

  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 1;
`;

export const TableRowSuspenseLoader = styled(SuspenseLoader).withConfig<{
  height?: number;
}>(excludeProps('height'))`
  ${({ height }) =>
    height > 0
      ? css`
          height: ${height}px;
        `
      : ''};
`;

export const FeedArticleTextContent = styled.div`
  display: -webkit-box;
  padding-top: 0.5rem;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  line-height: 1.5;
  text-overflow: ellipsis;
  color: #333;

  td.sorted & {
    font-weight: 500;
    color: black;
  }

  &.warning {
    color: #ec7357;
  }

  &.alert {
    color: red;
  }
`;

export const FeedArticleAssignee = styled.div`
  display: flex;
  align-items: center;
`;

export const FeedArticleAssigneeCompletedIcon = styled(CheckCircleIcon).attrs({
  size: '14',
})`
  margin-right: 0.4ch;
  color: rgba(0, 0, 0, 0.4);

  &.completed {
    color: ${({ theme }) => theme.colors?.success?.main};
  }
`;

export const FeedArticleTableDataTable = styled<
  DataTableComponent<FeedArticle>
>(DataTable)`
  flex: 1;
  border-top: 1px solid #ccc;

  .header .th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }

  .header .tr {
    background-color: white;
  }

  .rowgroup:not(.resizing-columns) > .tr {
    cursor: pointer;
    &:hover .td {
      background-color: #edf5f7;
    }
  }

  .tr .td {
    font-size: 1.2rem;
    overflow: hidden;
    background-color: #f5f5f5;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .td:first-child,
  .th:first-child {
    padding-left: 1.6rem;
  }

  .td:first-child[data-columnid='importance'] {
    padding-left: 0.6rem;
  }

  .th:first-child[data-columnid='importance'] {
    padding-left: 1.2rem;
  }

  .tr.read > .td {
    background-color: #fff;
    & > ${FeedArticleTextContent}:not(.alert):not(.warning) {
      color: ${foundation.palette.onyx450.hex};
    }

    & .tag:not(.team-tag) {
      opacity: 0.6;
    }
  }

  .tr:not(.read) > .td > ${FeedArticleTextContent}.title {
    font-weight: 600;
  }

  .tr.flash-enter .td {
    background-color: #ffd400;
  }

  .tr.flash-enter-active .td {
    transition: background-color 0.5s ease-out;
    background-color: #f5f5f5;
  }

  .tr.flash-enter-active.read .td {
    background-color: white;
  }

  .tr-expanded {
    position: relative;

    .tr-expanded-info {
      position: relative;
      z-index: 1;
    }

    .tr-expanded-bg {
      position: absolute;
      height: 100%;
      background-color: #ddd;
    }
  }

  & .tr > .tr-hover-container {
    position: sticky;
    right: 0;
    height: inherit;
  }

  & .tr > .tr-hover-container {
    transform: translateX(300%);
    opacity: 0;
    transition: opacity 0.2s ease-out;
    will-change: opacity;

    &.visible {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.force-hover .tr > .tr-hover-container {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const FeedArticleTableAndFilterWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  min-height: 100%;
  max-height: 100%;
`;

export const StyledFilterBar = styled(FilterBar)`
  padding-left: 1rem;
  padding-right: 1rem;

  & .filter-toggle-button {
    margin-left: 1rem;
  }
`;
