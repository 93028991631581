import styled from 'styled-components';

export const SessionExpiredModalUsernameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const SessionExpiredModalUsername = styled.pre`
  background-color: #f4f4f4;
  font-size: 1.4rem;
  margin-right: 1.6rem;
  padding: 0.4rem 1rem;
  font-weight: 600;
  white-space: normal;
  max-width: 100%;
  overflow-wrap: break-word;
`;
