import { ConfirmDialog, useConfirmDialog } from '@fcg-tech/regtech-components';
import { DataTableRow, DataTableRowProps } from '@fcg-tech/regtech-datatable';
import { useIsMountedRef, useMouseHover } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { DataTableActionBar } from '../datatable/DataTableActionBar';

export type RowData = { id: string; username: string };
export type ColumnKeys = keyof RowData | 'delete';

interface TeamMemberInvitationsTableRowProps
  extends DataTableRowProps<RowData> {
  onRemove?: (invitationId: string) => Promise<void>;
}

export const TeamMemberInvitationsTableRow: FunctionComponent<
  TeamMemberInvitationsTableRowProps
> = ({ onRemove, ...props }) => {
  const { t } = useTranslation();
  const [isRemoving, setIsRemoving] = useState(false);
  const isMounted = useIsMountedRef();
  const [showConfirmRemoveDialog, handleConfirmRemove, confirmRemove] =
    useConfirmDialog();

  const row = props.row.original;

  const handleRemoveClick = useCallback(async () => {
    const confirmed = await confirmRemove();
    if (confirmed) {
      setIsRemoving(true);
      try {
        await onRemove(row.id);
      } finally {
        if (isMounted.current) {
          setIsRemoving(false);
        }
      }
    }
  }, [confirmRemove, onRemove, row.id, isMounted]);

  const { isMouseOver, ...hoverProps } = useMouseHover();

  return (
    <>
      {showConfirmRemoveDialog ? (
        <ConfirmDialog
          title={t(MessageKeys.RemoveInvitation)}
          body={t(MessageKeys.RemoveInvitationConfirm, {
            user: row.username,
          })}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleConfirmRemove}
        />
      ) : null}

      <DataTableRow {...props} {...hoverProps}>
        {isMouseOver ? (
          <div className="tr-hover-container" data-kind="hover">
            <DataTableActionBar
              isRemoving={isRemoving}
              onRemove={handleRemoveClick}
            />
          </div>
        ) : null}
      </DataTableRow>
    </>
  );
};
