import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  ConfirmDialog,
  DangerBorder,
  DangerButton,
  FormGridButtonRow,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  H2,
  PrimaryButton,
  TextField,
  useApplicationStateAnyActivity,
  useApplicationStateMutationKey,
  useConfirmDialog,
  useFlashContext,
} from '@fcg-tech/regtech-components';
import {
  CompanyInput,
  CompanyUpdateInput,
} from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import { useFormik } from 'formik';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { NameClashError } from '../../api/apiErrors';
import { useCompany, useCompanyActions } from '../../api/hooks';
import { useIamContext } from '../../components/IamContext';
import { MainLayoutPaddedContentWrapper } from '../../components/MainLayout/MainLayout.styles';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import {
  canCompanyDelete,
  canCompanyEdit,
  canCompanyElevatedEdit,
} from '../../utils/iamHelpers';
import { CompanyAdministrationDetailsForm } from './CompanyAdministrationPage.styles';

interface CompanyAdministrationDetailsProps {
  superAdmin?: boolean;
}

export const CompanyAdministrationDetails: FunctionComponent<
  CompanyAdministrationDetailsProps
> = ({ superAdmin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{
    companyId: string;
  }>();
  const { data: company } = useCompany(params.companyId);
  const { updateCompanyElevated, updateCompany, deleteCompany } =
    useCompanyActions();
  const { addFlash, clearFlash } = useFlashContext();
  const [
    showDeleteCompanyConfirm,
    handleDeleteCompanyConfirm,
    confirmDeleteCompany,
  ] = useConfirmDialog();
  const iamContext = useIamContext();
  const canElevatedEdit = canCompanyElevatedEdit(company.id, iamContext);
  const canEdit = canCompanyEdit(company.id, iamContext);
  const canDelete = canCompanyDelete(company.id, iamContext);

  const { isMutating: savingDetails, setIsMutating: setIsSavingDetails } =
    useApplicationStateMutationKey('update-company-details');

  const { isMutating: deletingCompany, setIsMutating: setIsDeletingCompany } =
    useApplicationStateMutationKey('delete-company');

  const anyNetworkActivity = useApplicationStateAnyActivity();

  const formik = useFormik<Partial<CompanyInput>>({
    initialValues: {
      name: company?.name ?? '',
      stringId: company?.stringId ?? '',
    },
    onSubmit: async (values) => {
      try {
        clearFlash('company-name-clash');
        if (canElevatedEdit && company) {
          if (values.name && values.stringId) {
            setIsSavingDetails(true);
            await updateCompanyElevated(company.id, values as CompanyInput);
          }
        } else if (company) {
          setIsSavingDetails(true);
          if (values.name) {
            await updateCompany(company.id, values as CompanyUpdateInput);
          }
        }
      } catch (e) {
        if (e instanceof NameClashError) {
          addFlash({
            id: 'company-name-clash',
            level: 'error',
            timeoutMS: 8000,
            content: e.message,
          });
        } else {
          addFlash({
            id: 'company-name-clash',
            level: 'error',
            content: t(MessageKeys.LabelCouldNotSave),
          });
        }
      }
      setIsSavingDetails(false);
    },
  });

  const handleDeleteClick = useCallback(async () => {
    if (await confirmDeleteCompany()) {
      setIsDeletingCompany(true);
      try {
        await deleteCompany(company.id);
        addFlash({
          content: t(MessageKeys.AdministrationCompanyDeleteSuccess),
          level: 'success',
        });
        navigate(
          constructUrl(superAdmin ? routes.superAdmin : routes.dashboard),
        );
      } catch (e) {
        addFlash({
          content: t(MessageKeys.AdministrationCompanyDeleteFailure),
          level: 'error',
        });
      }
      setIsDeletingCompany(false);
    }
  }, [
    addFlash,
    company.id,
    confirmDeleteCompany,
    deleteCompany,
    navigate,
    setIsDeletingCompany,
    superAdmin,
    t,
  ]);

  const submitDisabled =
    anyNetworkActivity &&
    formik.values.name.trim().length > 1 &&
    formik.values.stringId.trim().length > 0;

  return (
    <>
      <MainLayoutPaddedContentWrapper>
        {showDeleteCompanyConfirm ? (
          <ConfirmDialog
            title={t(MessageKeys.LabelConfirmAction)}
            body={t(MessageKeys.AdministrationCompanyDeleteConfirm, {
              name: company.name,
            })}
            confirmText={t(MessageKeys.LabelConfirmLabel)}
            cancelText={t(MessageKeys.LabelCancel)}
            onChoice={handleDeleteCompanyConfirm}
          />
        ) : null}
        <CompanyAdministrationDetailsForm onSubmit={formik.handleSubmit}>
          <H2>{t(MessageKeys.AdministrationTabBarTeamDetailsLabel)}</H2>
          <FormGridRow
            className={classNames(
              formik.values.name?.length > 0 && 'valid',
              !formik.values.name?.length && 'invalid',
            )}
          >
            <FormGridLabel>{t(MessageKeys.LabelCompanyName)}</FormGridLabel>
            <FormGridControl>
              <TextField
                name="name"
                value={formik.values.name}
                isEditEnabled={canEdit}
                valid={formik.values.name?.length > 0}
                error={!formik.values.name?.length}
                onChange={formik.handleChange}
              />
            </FormGridControl>
          </FormGridRow>
          {canElevatedEdit ? (
            <FormGridRow
              className={classNames(
                formik.values.stringId?.length > 0 && 'valid',
                !formik.values.stringId?.length && 'invalid',
              )}
            >
              <FormGridLabel>
                {t(MessageKeys.LabelCompanyStringId)}
              </FormGridLabel>
              <FormGridControl>
                <TextField
                  name="stringId"
                  value={formik.values.stringId}
                  isEditEnabled={canEdit}
                  valid={formik.values.stringId?.length > 0}
                  error={!formik.values.stringId?.length}
                  onChange={formik.handleChange}
                />
              </FormGridControl>
            </FormGridRow>
          ) : null}
          {canEdit ? (
            <FormGridButtonRow>
              <PrimaryButton
                type="submit"
                disabled={submitDisabled}
                loading={savingDetails}
              >
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </FormGridButtonRow>
          ) : null}
        </CompanyAdministrationDetailsForm>
      </MainLayoutPaddedContentWrapper>
      {canDelete ? (
        <>
          <DangerBorder />
          <MainLayoutPaddedContentWrapper>
            <DangerButton
              loading={deletingCompany}
              disabled={anyNetworkActivity}
              onClick={handleDeleteClick}
            >
              {t(MessageKeys.AdministrationCompanyDeleteButtonLabel, {
                name: company?.name,
              })}
            </DangerButton>
          </MainLayoutPaddedContentWrapper>
        </>
      ) : null}
    </>
  );
};
