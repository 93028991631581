import { FunctionComponent } from 'react';

interface RenderWrapperProps {
  render: JSX.Element;
}

export const RenderWrapper: FunctionComponent<RenderWrapperProps> = ({
  render,
}) => {
  return render;
};
