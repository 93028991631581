import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { CompanyTeamAdministration } from './CompanyTeamAdministration';

export const CompanyAdministrationPage: FunctionComponent = () => {
  const params = useParams<{
    teamId: string;
    companyId: string;
  }>();

  return (
    <ErrorBoundary>
      <SuspenseLoader>
        <CompanyTeamAdministration
          teamId={params.teamId}
          companyId={params.companyId}
        />
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
