import { IconButton } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const TeamMemberListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: 0;
  width: 100%;
`;

export const TeamMemberListItemWrapper = styled.li`
  padding: 0.4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.emph {
    font-weight: 500;
  }
`;

export const TeamMemberListItemIconWrapper = styled.span`
  margin-right: 1rem;
`;

export const TeamMemberListItemInnerWrapper = styled.span`
  display: flex;
  align-items: center;
  max-width: 25rem;
  white-space: initial;
`;

export const TeamMemberListRemoveButton = styled(IconButton)`
  width: 2.2rem;
  height: 2.2rem;
  margin-left: 1rem;
`;

export const TeamMemberListItemNameWrapper = styled.span`
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TeamMemberListItemAssignedIconWrapper = styled.div`
  margin-left: 0.5ch;
  position: relative;
  z-index: 2;
  & > .icon {
    position: relative;
    z-index: 2;
  }
`;

export const TeamMemberListItemMarkAsCompleteButton = styled.div.attrs({
  role: 'button',
})`
  display: flex;
  text-decoration: underline;

  cursor: pointer;

  & ${TeamMemberListItemAssignedIconWrapper}:before {
    position: absolute;
    content: ' ';
    top: 2px;
    left: 1px;
    background-color: transparent;
    width: 16px;
    height: 16px;

    border-radius: 50%;
    box-shadow: 0 2px 0 1px rgba(0, 0, 0, 0.2);
    z-index: 0;
    opacity: 0;
    transition: opacity 0.14s ease-in-out;
  }
  &:hover {
    & ${TeamMemberListItemAssignedIconWrapper}:before {
      opacity: 1;
    }
  }
`;

export const TeamMemberListItemActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
