import {
  excludeProps,
  IconButton,
  Menu,
  MenuItem,
  SearchTextField,
  SecondaryButton,
} from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

const SEARCH_BAR_DESKTOP_WIDTH_REM = 32;

export const SearchBarWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      width: calc(100vw - 60px);
      padding-right: 1rem;
    }
  `}
`;

export const SearchBarSubmitButton = styled(SecondaryButton)`
  margin-left: 1.6rem;
`;

export const SearchBarSuggestionsWrapper = styled.div`
  ${({ theme }) => css`
    width: min(${SEARCH_BAR_DESKTOP_WIDTH_REM}rem, 100vw / 5);
    z-index: 10;
    display: flex;
    background-color: white;
    border: 1px solid ${theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
    border-top: 1px solid #aaa;
  `}
`;

export const SearchBarSuggestion = styled.div`
  border: 0px solid #aaa;
  background-color: #eee;
  color: #000;
  font-size: 1.1rem;
  padding: 0.3rem;
  border-radius: 3px;
  margin-left: 1rem;
`;

export const SearchBarMenu = styled(Menu)`
  width: 100%;
  border-radius: 0 0 6px 6px;
  max-height: initial;
`;

export const SearchBarMenuItemText = styled.span`
  flex: 1 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchBarMenuItem = styled(MenuItem).withConfig<{
  active?: boolean;
  role: 'button';
}>(excludeProps('active'))`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    border-top: 1px solid #ccc;
  }

  &:hover:after {
    display: none;
  }

  &:hover {
    background-color: #f3f3f3;
    color: black;
  }

  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme?.colors?.info?.main ?? '#f3f3f3'};
          color: ${theme?.colors?.info?.contrast ?? '#000'};
        `
      : ''}
`;

export const SearchBarHelpButton = styled(IconButton)`
  ${({ theme }) => css`
    width: 3.6rem;
    height: 3.6rem;
    margin-left: 1.2rem;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      margin-left: 0.8rem;
      width: 2.4rem;
      height: 2.4rem;
    }
  `}
`;

export const SearchBarTextFieldWrapper = styled.div`
  ${({ theme }) => css`
    width: min(${SEARCH_BAR_DESKTOP_WIDTH_REM}rem, 100vw / 5);

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      width: 100%;
    }
  `}
`;

export const SearchBarTextField = styled(SearchTextField).withConfig<{
  open: boolean;
}>(excludeProps('open'))`
  ${({ open }) => css`
    ${open
      ? css`
          border-radius: 5px 5px 0 0;
        `
      : null}
  `}
`;
