import React, { FunctionComponent } from 'react';
import { ProgressItem } from './ProgressItem';
import { ProgressListWrapper } from './ProgressList.styles';
import { ProgressStep } from './progressTypes';

interface ProgressListProps {
  steps: Array<ProgressStep>;
  className?: string;
}

export const ProgressList: FunctionComponent<ProgressListProps> = ({
  steps,
  className,
}) => {
  return (
    <ProgressListWrapper className={className}>
      {steps.map((step) => (
        <ProgressItem key={step.id} step={step} />
      ))}
    </ProgressListWrapper>
  );
};
