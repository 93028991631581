import {
  FormGridButtonRow,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  FormGridToggle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextArea,
  TextField,
} from '@fcg-tech/regtech-components';
import { Tag } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import { useFormik } from 'formik';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getDefaultPlaceholder,
  MessageKeys,
} from '../../translations/translationTypes';
import { ModalForm } from './modalFormComponents';
import { UpsertTagModalFields } from './UpsertTagModal.styles';

interface UpsertTagModalProps {
  tag?: Tag;
  onConfirm: (name: string, description: string, active: boolean) => void;
  onCancel: () => void;
}

export const UpsertTagModal: FunctionComponent<UpsertTagModalProps> = ({
  tag,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<Partial<Tag>>({
    initialValues: {
      name: tag?.name ?? '',
      active: tag?.active ?? true,
      description: tag?.description ?? '',
    },
    onSubmit: (values) => {
      onConfirm?.(values.name, values.description, values.active);
    },
  });

  return (
    <Modal kind="upsert-tag-modal" onHide={onCancel}>
      <ModalHeader>
        <ModalTitle>
          {t(
            tag
              ? MessageKeys.AdministrationTeamDetailsTagEditLabel
              : MessageKeys.AdministrationTeamDetailsTagCreateLabel,
          )}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalForm
          onSubmit={formik.handleSubmit}
          data-tag-id={tag?.id ?? 'new'}
        >
          <UpsertTagModalFields>
            <FormGridRow
              className={classNames(formik.values.name?.length && 'valid')}
            >
              <FormGridLabel>{t(MessageKeys.LabelTagName)}</FormGridLabel>
              <FormGridControl>
                <TextField
                  value={formik.values.name}
                  name="name"
                  placeholder={getDefaultPlaceholder(
                    MessageKeys.LabelTagName,
                    t,
                  )}
                  onChange={formik.handleChange}
                />
              </FormGridControl>
            </FormGridRow>
            <FormGridRow
              className={classNames(
                formik.values.description?.length && 'valid',
              )}
            >
              <FormGridLabel>
                {t(MessageKeys.LabelTagDescription)}
              </FormGridLabel>
              <FormGridControl>
                <TextArea
                  value={formik.values.description}
                  placeholder={getDefaultPlaceholder(
                    MessageKeys.LabelTagDescription,
                    t,
                  )}
                  name="description"
                  onChange={formik.handleChange}
                />
              </FormGridControl>
            </FormGridRow>
            <FormGridRow
              className={classNames(
                'with-toggle',
                formik.values.active && 'valid',
              )}
            >
              <FormGridLabel>
                {t(MessageKeys.AdministrationTeamDetailsTagActiveLabel)}
              </FormGridLabel>
              <FormGridControl>
                <FormGridToggle
                  checked={formik.values.active}
                  name="active"
                  onChange={formik.handleChange}
                />
              </FormGridControl>
            </FormGridRow>
          </UpsertTagModalFields>
          <FormGridButtonRow>
            <SecondaryButton
              onClick={onCancel}
              data-kind="upsert-tag-modal-cancel-button"
            >
              {t(MessageKeys.LabelCancel)}
            </SecondaryButton>
            <PrimaryButton
              disabled={formik.values.name.trim().length === 0}
              type="submit"
              data-kind="upsert-tag-modal-submit-button"
            >
              {t(MessageKeys.LabelSubmit)}
            </PrimaryButton>
          </FormGridButtonRow>
        </ModalForm>
      </ModalBody>
    </Modal>
  );
};
