import { Showcase, ShowcaseReel } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeShowcase, Translator } from '../../types';
import { ShowCaseContent } from './ShowCaseContent';
import { useShowcase } from './useShowcase';

export const NewDataTableShowcase: FunctionComponent = () => {
  const { t } = useTranslation();
  const [display, handleExit] = useShowcase(RegeyeShowcase.NewDataTable, {
    requireShowcaseCompleted: [
      RegeyeShowcase.GenericFeedArticleTable,
      RegeyeShowcase.NewUser,
    ],
  });
  return display ? (
    <Showcase reel={NewDataTableShowcaseReel(t)} onExit={handleExit} />
  ) : null;
};

const contentStyle = { width: 940 };

const NewDataTableShowcaseReel = (t: Translator): ShowcaseReel => ({
  id: RegeyeShowcase.NewDataTable,
  skippable: true,
  waitForQuerySelector: '#filter-toggle-button',
  entries: [
    {
      content: (entry) => (
        <ShowCaseContent
          entry={entry}
          heading={t(MessageKeys.ShowCaseNewDataTableWelcomeHeading)}
          content={t(MessageKeys.ShowCaseNewDataTablePage1WelcomeBody)}
        />
      ),
      contentStyle,
      illustrationSrc: 'assets/showcase/new-table/showcase-new_table_1.png',
    },
    {
      content: t(MessageKeys.ShowCaseNewDataTablePage2WelcomeBody),
      contentStyle,
      illustrationStyle: {
        backgroundSize: 'contain',
      },
      illustrationSrc: 'assets/showcase/new-table/showcase-new_table_2.png',
    },
  ],
});
