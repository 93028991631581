import styled from 'styled-components';

export const ActionsArticleDueDateCalendarEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 0;
`;

export const ActionsArticleDueDateCalendarEventTeamName = styled.h3`
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 0 0.3rem 0;
  padding-bottom: 0.3rem;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  & > .icon {
    margin-right: 0.5rem;
    position: relative;
    top: 0.1rem;
  }
`;

export const ActionsArticleDueDateAgendaEventWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const ActionsArticleDueDateAgendaEventTeamName = styled.h3`
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0 1rem 0 0;
  width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

  & > .icon {
    margin-right: 0.5rem;
    position: relative;
    top: 0.1rem;
  }
`;
