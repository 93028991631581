import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  AwardIcon,
  BellIcon,
  CheckIcon,
  DashboardCardTitleIconWrapper,
  DashboardHeaderNavIconButton,
  SuspenseLoader,
} from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  useNotifications,
  useNotificationsReadActions,
} from '../../../api/hooks';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { routes } from '../../../routes';
import { MessageKeys } from '../../../translations/translationTypes';
import {
  fixPath,
  markAsRead,
} from '../../NotificationsPage/notificationPageUtils';
import {
  DashboardCardBodyInner,
  DashboardCardCountWrapper,
  DashboardCardListCheckbutton,
  DashboardCardListItem,
  DashboardCardListItemIconWrapper,
  DashboardCardListItemText,
  DashboardCardListWrapper,
  DashboardCardNoContent,
  DashboardCardNoContentIconWrapper,
  RegeyeDashboardCard as DashboardCard,
} from '../Dashboard.styles';
import { MAX_ITEMS } from '../dashboardConstants';

export const DashboardNotificationsCard: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <DashboardCard
      title={
        <>
          <DashboardCardTitleIconWrapper>
            <BellIcon size="14" />
          </DashboardCardTitleIconWrapper>{' '}
          Notifications
        </>
      }
      headerButton={
        <DashboardHeaderNavIconButton to={constructUrl(routes.notifications)}>
          {t(MessageKeys.DashboardViewAllNotifications)}
        </DashboardHeaderNavIconButton>
      }
    >
      <ErrorBoundary>
        <SuspenseLoader>
          <DashboardNotificationsCardBody />
        </SuspenseLoader>
      </ErrorBoundary>
    </DashboardCard>
  );
};

interface DashboardNotificationsCardBodyProps {
  maxItems?: number;
}

export const DashboardNotificationsCardBody: FunctionComponent<
  DashboardNotificationsCardBodyProps
> = ({ maxItems = MAX_ITEMS }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data, mutate } = useNotifications({ unreadOnly: true });
  const { markNotificationsAsRead } = useNotificationsReadActions();

  const handleCheckClick = useCallback(
    (event: React.PointerEvent<HTMLButtonElement>) => {
      const id = event.currentTarget.getAttribute('data-id');

      if (id) {
        mutate((old) => ({
          ...old,
          items: markAsRead(old.items, [id]),
        }));

        markNotificationsAsRead([id]);
      }
    },
    [markNotificationsAsRead, mutate],
  );

  return (
    <DashboardCardBodyInner>
      {data.items?.length ? (
        <>
          <DashboardCardCountWrapper>
            <Trans
              i18nKey={MessageKeys.DashboardNotificationCount as string}
              values={{
                countStr:
                  (data?.truncated ? '99+' : data?.items?.length) ?? '0',
              }}
              components={{
                count: <span className="count" />,
              }}
            />
          </DashboardCardCountWrapper>
          <DashboardCardListWrapper>
            {data.items.slice(0, maxItems).map((item) => (
              <DashboardCardListItem key={item.id}>
                <DashboardCardListItemIconWrapper>
                  <BellIcon size="14" />
                </DashboardCardListItemIconWrapper>
                <DashboardCardListItemText
                  to={fixPath(item)?.link?.path}
                  className={classNames(item.read && 'read')}
                >
                  {item.title}
                </DashboardCardListItemText>
                <DashboardCardListCheckbutton
                  data-id={item.id}
                  onClick={handleCheckClick}
                >
                  <CheckIcon
                    size="14"
                    color={
                      item.read ? theme.colors.success.main : 'currentColor'
                    }
                  />
                </DashboardCardListCheckbutton>
              </DashboardCardListItem>
            ))}
            {data.items.length > maxItems ? (
              <DashboardCardListItem>
                {t(MessageKeys.DashboardAndCountMoreNotifications, {
                  count: data.items.length - maxItems,
                })}
              </DashboardCardListItem>
            ) : null}
          </DashboardCardListWrapper>
        </>
      ) : (
        <DashboardCardNoContent>
          <DashboardCardNoContentIconWrapper>
            <AwardIcon size="24" />
          </DashboardCardNoContentIconWrapper>
          {t(MessageKeys.DashboardAllCaughtUp)}
        </DashboardCardNoContent>
      )}
    </DashboardCardBodyInner>
  );
};
