import {
  ExternalLinkIcon,
  mobile,
  UnstyledButton,
} from '@fcg-tech/regtech-components';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const FeedArticleReaderWrapper = styled.div`
  flex: 1;
`;

export const FeedArticleHeading = styled.a.attrs({
  ariaLevel: 2,
  role: 'heading',
  target: '_blank',
})`
  display: block;
  color: black;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 700;
  white-space: normal;
  line-height: 3rem;
  margin-bottom: 1.6rem;

  &:hover {
    color: ${({ theme }) => theme?.palette?.MainColor?.dark};
  }
`;

export const FeedArticlePublisherLink = styled.a.attrs({
  ariaLevel: 3,
  role: 'heading',
  target: '_blank',
})`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;

  &:hover {
    color: ${({ theme }) => theme?.palette?.MainColor?.dark};
  }
`;

export const FeedArticleExternalLinkIcon = styled(ExternalLinkIcon)`
  margin-left: 0.4rem;
`;

export const FeedArticleMetaDataWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem 0;
`;

export const FeedArticleMetaData = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 1rem;
  }
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
`;

export const FeedArticleTagsWrapper = styled(FeedArticleMetaData)`
  align-items: flex-start;

  & > .tag-list-wrapper + .tag-list-wrapper {
    margin-left: 1rem;
  }
`;

export const FeedArticleActionsWrapper = styled.div`
  display: flex;
  margin: 1rem 0 2rem 0;

  .data-table-icon-button + .data-table-icon-button {
    margin-left: 1.6rem;
  }
`;

export const FeedArticleActionArchiveWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 0 2rem;

  &:after {
    content: '';
    position: absolute;
    top: -0.8rem;
    left: -0.8rem;
    bottom: -0.8rem;
    right: -0.8rem;
    border: 1px solid #777;
    pointer-events: none;
    border-radius: 900px/1000px;
  }

  &:not(.has-dialog-button):after {
    display: none;
  }

  &:not(.has-dialog-button) {
  }
`;

export const FeedArticleAction = styled(UnstyledButton)`
  ${({ theme }) => css`
    & + & {
      margin-left: 1.6rem;
    }
    position: relative;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s linear;

    & > svg {
      z-index: 1;
    }

    &:disabled {
      opacity: 0.3;
    }

    &:hover,
    &:active {
      color: ${theme?.palette?.MainColor?.lightContrast ?? 'rgba(0, 0, 0, 1)'};

      &:before {
        content: ' ';
        position: absolute;
        top: -0.8rem;
        bottom: -0.8rem;
        left: -0.8rem;
        right: -0.8rem;
        z-index: 0;
        border-radius: 50%;
        background-color: ${theme?.palette?.MainColor?.light ??
        theme?.palette?.MainColorAccent?.light ??
        theme?.palette?.MainColorAccent.main ??
        'rgba(255,255,255,0.5)'};
      }
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      &:focus:before {
        display: none;
      }
    }
  `}
`;

export const FeedArticleActionWithMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & > ${FeedArticleAction} {
    margin-right: 1.6rem;
  }

  ${FeedArticleAction} + & {
    margin-left: 1.6rem;
  }

  ${mobile(
    () => css`
      & > ${FeedArticleAction} {
        margin-right: 0;
      }

      ${FeedArticleAction} + & {
        margin-left: 0;
      }
    `,
  )}
`;

export const FeedArticleNavLink = styled(NavLink)`
  margin-left: 1.6rem;
`;

export const FeedArticleBody = styled.p`
  font-family: 'Roboto Slab';
  white-space: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;

  & h1 {
    line-height: 4rem;
  }

  & h2 {
    line-height: 3.8rem;
  }

  & h3 {
    line-height: 3.4rem;
  }

  & h4 {
    line-height: 3rem;
  }

  & table td {
    vertical-align: top;
  }

  & img {
    max-width: 100%;
  }
`;

export const FeedArticleTypeTag = styled.span`
  ${({ theme }) => css`
    padding: 0.4rem 0.6rem;
    border: 1px solid ${theme?.palette?.MainColor.mainContrast ?? '#000'};
    border-radius: 6px;
    font-size: 1.4rem;
    font-weight: 500;
    background-color: ${theme?.palette?.MainColor.main ?? '#ddd'};
    color: ${theme?.palette?.MainColor.mainContrast ?? '#000'};
    margin: 0 0.4rem 0.4rem 0;
    display: flex;
    align-items: center;
    overflow: hidden;
  `}
`;
