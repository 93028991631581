import { Importance, ImportanceIndicator } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { TeamArticlesEventDescriptionImportanceWrapper } from './TeamArticlesEventDescripion.styles';

interface TeamArticlesEventDescriptionImportanceProps {
  importance: Importance;
}

export const TeamArticlesEventDescriptionImportance: FunctionComponent<
  TeamArticlesEventDescriptionImportanceProps
> = ({ importance }) => {
  return (
    <TeamArticlesEventDescriptionImportanceWrapper>
      <ImportanceIndicator importance={importance} />
    </TeamArticlesEventDescriptionImportanceWrapper>
  );
};
