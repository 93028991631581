import { MutableRefObject, RefObject, useEffect, useRef } from 'react';
import { getScrollParent } from '../utils';

export const useCloseSelectOnScroll = <T extends HTMLElement>(): [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectRef: MutableRefObject<any>,
  containerRef: RefObject<T | undefined>,
] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>();
  const containerRef = useRef<T>();

  useEffect(() => {
    const container = containerRef.current
      ? getScrollParent(containerRef.current)
      : null;
    const select = selectRef.current;
    if (container && select) {
      const handleScroll = () => {
        if (select) {
          select.blur();
        }
      };

      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, selectRef.current]);

  return [selectRef, containerRef];
};
