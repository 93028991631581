import {
  H1,
  ShowcaseEntry,
  ShowcasePadddedContent,
  ShowcaseParagraph,
} from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';

interface ShowCaseContentProps {
  entry: ShowcaseEntry;
  heading: string;
  content: string;
}

export const ShowCaseContent: FunctionComponent<ShowCaseContentProps> = ({
  entry,
  heading,
  content,
}) => {
  return (
    <ShowcasePadddedContent style={entry.paddedContentStyle}>
      <H1>{heading}</H1>
      <ShowcaseParagraph content={content} />
    </ShowcasePadddedContent>
  );
};
