import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useArchivedArticles, useSubscriptionArticles } from '../../api/hooks';
import { TeamContextProvider } from '../../components/TeamContext/TeamContext';
import { routeFragments } from '../../routes';
import { ActivityLogArticlesPage } from '../ActivityLogArticles';
import { GenericPaginatedFeedArticlePage } from '../GenericPaginatedFeedArticlePage/GenericPaginatedFeedArticlePage';
import { ActionsArticlesPage } from '../Insights/ActionsArticlesPage';
import { TeamSettingsPage } from '../TeamSettings/TeamSettingsPage';
import { TeamOutlet } from './TeamOutlet';

export const TeamBaseContainer: FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<TeamOutlet />} path="/">
        <Route
          path={routeFragments.teamSubscriptionArticles}
          element={
            <TeamContextProvider>
              <GenericPaginatedFeedArticlePage
                useFeedArticles={useSubscriptionArticles}
              />
            </TeamContextProvider>
          }
        />
        <Route
          path={routeFragments.teamArchive}
          element={
            <TeamContextProvider>
              <GenericPaginatedFeedArticlePage
                useFeedArticles={useArchivedArticles}
              />
            </TeamContextProvider>
          }
        />
        <Route
          path={routeFragments.teamActions}
          element={
            <TeamContextProvider>
              <ActionsArticlesPage />
            </TeamContextProvider>
          }
        />
        <Route
          path={routeFragments.teamActionsLog}
          element={
            <TeamContextProvider>
              <ActivityLogArticlesPage />
            </TeamContextProvider>
          }
        />
        <Route
          path={routeFragments.teamSettingsCatchAll}
          element={
            <TeamContextProvider>
              <TeamSettingsPage />
            </TeamContextProvider>
          }
        />
      </Route>
    </Routes>
  );
};
