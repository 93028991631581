import { UnstyledButton } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const FeedArticleTableTopActionBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FeedArticleTableTopActionBarQuickFilterWrapper = styled.div`
  margin-right: 1.6rem;
  display: flex;
`;

export const FeedArticleTableTopActionBarQuickFilterButton = styled(
  UnstyledButton,
)`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  &.enabled:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;
    border-top: 2px solid black;
  }
`;
