/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyToJSON,
    CompanyInput,
    CompanyInputFromJSON,
    CompanyInputToJSON,
    CompanyList,
    CompanyListFromJSON,
    CompanyListToJSON,
    CompanyUpdateInput,
    CompanyUpdateInputFromJSON,
    CompanyUpdateInputToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    TeamInput,
    TeamInputFromJSON,
    TeamInputToJSON,
    TeamList,
    TeamListFromJSON,
    TeamListToJSON,
    TeamResponse,
    TeamResponseFromJSON,
    TeamResponseToJSON,
    TeamRoleInput,
    TeamRoleInputFromJSON,
    TeamRoleInputToJSON,
    UserReference,
    UserReferenceFromJSON,
    UserReferenceToJSON,
} from '../models';

export interface CompanyDeleteRequest {
    companyId: string;
}

export interface CompanyTeamAddUserRequest {
    companyId: string;
    teamId: string;
}

export interface CompanyTeamCreateRequest {
    companyId: string;
    teamInput: TeamInput;
}

export interface CompanyTeamDeleteRequest {
    companyId: string;
    teamId: string;
}

export interface CompanyTeamGetRequest {
    companyId: string;
    teamId: string;
}

export interface CompanyTeamListRequest {
    companyId: string;
}

export interface CompanyTeamMemberDeleteRequest {
    companyId: string;
    teamId: string;
    userReference: UserReference;
}

export interface CompanyTeamMemberEditRequest {
    companyId: string;
    teamId: string;
    teamRoleInput: TeamRoleInput;
}

export interface CompanyTeamUpdateRequest {
    companyId: string;
    teamId: string;
    teamInput: TeamInput;
}

export interface CreateCompanyRequest {
    companyInput: CompanyInput;
}

export interface GetCompanyRequest {
    companyId: string;
}

export interface UpdateCompanyRequest {
    companyId: string;
    companyUpdateInput: CompanyUpdateInput;
}

export interface UpdateCompanyElevatedRequest {
    companyId: string;
    companyInput: CompanyInput;
}

/**
 * CompaniesApi - interface
 * 
 * @export
 * @interface CompaniesApiInterface
 */
export interface CompaniesApiInterface {
    /**
     * 
     * @summary Delete company
     * @param {string} companyId the company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyDeleteRaw(requestParameters: CompanyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete company
     */
    companyDelete(requestParameters: CompanyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Add the current user to the team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyTeamAddUserRaw(requestParameters: CompanyTeamAddUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Add the current user to the team
     */
    companyTeamAddUser(requestParameters: CompanyTeamAddUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Create a team
     * @param {string} companyId the company id
     * @param {TeamInput} teamInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyTeamCreateRaw(requestParameters: CompanyTeamCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamResponse>>;

    /**
     * Create a team
     */
    companyTeamCreate(requestParameters: CompanyTeamCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamResponse>;

    /**
     * 
     * @summary Delete team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyTeamDeleteRaw(requestParameters: CompanyTeamDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete team
     */
    companyTeamDelete(requestParameters: CompanyTeamDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Get team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyTeamGetRaw(requestParameters: CompanyTeamGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamResponse>>;

    /**
     * Get team
     */
    companyTeamGet(requestParameters: CompanyTeamGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamResponse>;

    /**
     * 
     * @summary List all teams of company
     * @param {string} companyId the company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyTeamListRaw(requestParameters: CompanyTeamListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamList>>;

    /**
     * List all teams of company
     */
    companyTeamList(requestParameters: CompanyTeamListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamList>;

    /**
     * 
     * @summary Remove member from team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {UserReference} userReference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyTeamMemberDeleteRaw(requestParameters: CompanyTeamMemberDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Remove member from team
     */
    companyTeamMemberDelete(requestParameters: CompanyTeamMemberDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Update the permissiosn role of user
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {TeamRoleInput} teamRoleInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyTeamMemberEditRaw(requestParameters: CompanyTeamMemberEditRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update the permissiosn role of user
     */
    companyTeamMemberEdit(requestParameters: CompanyTeamMemberEditRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Update team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {TeamInput} teamInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    companyTeamUpdateRaw(requestParameters: CompanyTeamUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update team
     */
    companyTeamUpdate(requestParameters: CompanyTeamUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Create a company
     * @param {CompanyInput} companyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Company>>;

    /**
     * Create a company
     */
    createCompany(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Company>;

    /**
     * 
     * @summary Get company
     * @param {string} companyId the company id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Company>>;

    /**
     * Get company
     */
    getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Company>;

    /**
     * 
     * @summary List companies which user can access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    listCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyList>>;

    /**
     * List companies which user can access
     */
    listCompanies(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyList>;

    /**
     * 
     * @summary Update company
     * @param {string} companyId the company id
     * @param {CompanyUpdateInput} companyUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update company
     */
    updateCompany(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Update company
     * @param {string} companyId the company id
     * @param {CompanyInput} companyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    updateCompanyElevatedRaw(requestParameters: UpdateCompanyElevatedRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update company
     */
    updateCompanyElevated(requestParameters: UpdateCompanyElevatedRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI implements CompaniesApiInterface {

    /**
     * Delete company
     */
    async companyDeleteRaw(requestParameters: CompanyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete company
     */
    async companyDelete(requestParameters: CompanyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.companyDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Add the current user to the team
     */
    async companyTeamAddUserRaw(requestParameters: CompanyTeamAddUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyTeamAddUser.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyTeamAddUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/members`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add the current user to the team
     */
    async companyTeamAddUser(requestParameters: CompanyTeamAddUserRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.companyTeamAddUserRaw(requestParameters, initOverrides);
    }

    /**
     * Create a team
     */
    async companyTeamCreateRaw(requestParameters: CompanyTeamCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamResponse>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyTeamCreate.');
        }

        if (requestParameters.teamInput === null || requestParameters.teamInput === undefined) {
            throw new runtime.RequiredError('teamInput','Required parameter requestParameters.teamInput was null or undefined when calling companyTeamCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies/{companyId}/teams`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamInputToJSON(requestParameters.teamInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamResponseFromJSON(jsonValue));
    }

    /**
     * Create a team
     */
    async companyTeamCreate(requestParameters: CompanyTeamCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamResponse> {
        const response = await this.companyTeamCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete team
     */
    async companyTeamDeleteRaw(requestParameters: CompanyTeamDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyTeamDelete.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyTeamDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete team
     */
    async companyTeamDelete(requestParameters: CompanyTeamDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.companyTeamDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get team
     */
    async companyTeamGetRaw(requestParameters: CompanyTeamGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamResponse>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyTeamGet.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyTeamGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamResponseFromJSON(jsonValue));
    }

    /**
     * Get team
     */
    async companyTeamGet(requestParameters: CompanyTeamGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamResponse> {
        const response = await this.companyTeamGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all teams of company
     */
    async companyTeamListRaw(requestParameters: CompanyTeamListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamList>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyTeamList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}/teams`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamListFromJSON(jsonValue));
    }

    /**
     * List all teams of company
     */
    async companyTeamList(requestParameters: CompanyTeamListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamList> {
        const response = await this.companyTeamListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove member from team
     */
    async companyTeamMemberDeleteRaw(requestParameters: CompanyTeamMemberDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyTeamMemberDelete.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyTeamMemberDelete.');
        }

        if (requestParameters.userReference === null || requestParameters.userReference === undefined) {
            throw new runtime.RequiredError('userReference','Required parameter requestParameters.userReference was null or undefined when calling companyTeamMemberDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/members`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserReferenceToJSON(requestParameters.userReference),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove member from team
     */
    async companyTeamMemberDelete(requestParameters: CompanyTeamMemberDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.companyTeamMemberDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Update the permissiosn role of user
     */
    async companyTeamMemberEditRaw(requestParameters: CompanyTeamMemberEditRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyTeamMemberEdit.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyTeamMemberEdit.');
        }

        if (requestParameters.teamRoleInput === null || requestParameters.teamRoleInput === undefined) {
            throw new runtime.RequiredError('teamRoleInput','Required parameter requestParameters.teamRoleInput was null or undefined when calling companyTeamMemberEdit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/permissions`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamRoleInputToJSON(requestParameters.teamRoleInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the permissiosn role of user
     */
    async companyTeamMemberEdit(requestParameters: CompanyTeamMemberEditRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.companyTeamMemberEditRaw(requestParameters, initOverrides);
    }

    /**
     * Update team
     */
    async companyTeamUpdateRaw(requestParameters: CompanyTeamUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyTeamUpdate.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyTeamUpdate.');
        }

        if (requestParameters.teamInput === null || requestParameters.teamInput === undefined) {
            throw new runtime.RequiredError('teamInput','Required parameter requestParameters.teamInput was null or undefined when calling companyTeamUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamInputToJSON(requestParameters.teamInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update team
     */
    async companyTeamUpdate(requestParameters: CompanyTeamUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.companyTeamUpdateRaw(requestParameters, initOverrides);
    }

    /**
     * Create a company
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.companyInput === null || requestParameters.companyInput === undefined) {
            throw new runtime.RequiredError('companyInput','Required parameter requestParameters.companyInput was null or undefined when calling createCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyInputToJSON(requestParameters.companyInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     * Create a company
     */
    async createCompany(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Company> {
        const response = await this.createCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get company
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     * Get company
     */
    async getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Company> {
        const response = await this.getCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List companies which user can access
     */
    async listCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CompanyList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyListFromJSON(jsonValue));
    }

    /**
     * List companies which user can access
     */
    async listCompanies(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CompanyList> {
        const response = await this.listCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update company
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling updateCompany.');
        }

        if (requestParameters.companyUpdateInput === null || requestParameters.companyUpdateInput === undefined) {
            throw new runtime.RequiredError('companyUpdateInput','Required parameter requestParameters.companyUpdateInput was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUpdateInputToJSON(requestParameters.companyUpdateInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update company
     */
    async updateCompany(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.updateCompanyRaw(requestParameters, initOverrides);
    }

    /**
     * Update company
     */
    async updateCompanyElevatedRaw(requestParameters: UpdateCompanyElevatedRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling updateCompanyElevated.');
        }

        if (requestParameters.companyInput === null || requestParameters.companyInput === undefined) {
            throw new runtime.RequiredError('companyInput','Required parameter requestParameters.companyInput was null or undefined when calling updateCompanyElevated.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies/{companyId}/elevated`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyInputToJSON(requestParameters.companyInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update company
     */
    async updateCompanyElevated(requestParameters: UpdateCompanyElevatedRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.updateCompanyElevatedRaw(requestParameters, initOverrides);
    }

}
