import { GridLayoutMainWrapper } from '@fcg-tech/regtech-components';
import { single } from '@fcg-tech/regtech-utils';
import { parse } from 'query-string';
import { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { FeedArticlePageWrapper } from './FeedArticlePage.styles';
import { FeedArticlePageLoader } from './FeedArticlePageLoader';

type Params = {
  articleId: string;
};

export const FeedArticlePage: FunctionComponent = () => {
  const params = useParams<Params>();
  const location = useLocation();
  const query = parse(location.search);

  return (
    <GridLayoutMainWrapper>
      <ErrorBoundary>
        <SuspenseLoader>
          <FeedArticlePageWrapper className="full-height">
            <FeedArticlePageLoader
              key={params.articleId}
              articleId={params.articleId}
              teamId={single(query?.teamId)}
            />
          </FeedArticlePageWrapper>
        </SuspenseLoader>
      </ErrorBoundary>
    </GridLayoutMainWrapper>
  );
};
