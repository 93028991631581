import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  ArrowDirectionOld,
  HelpCircleIcon,
  Portal,
  SearchPlusIcon,
  useInput,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  GlobalSearchMode,
  SearchRow,
  TableSubType,
  TableType,
} from '@fcg-tech/regtech-types/regeye';
import { single, useArrowKeysOld, useEscapeKey } from '@fcg-tech/regtech-utils';
import { autoUpdate, useFloating } from '@floating-ui/react-dom';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { routes } from '../../routes';
import { pageContext } from '../../state/pageContext';
import { MessageKeys } from '../../translations/translationTypes';
import { useFilter } from '../../utils/filterHooks';
import { getSearchRows, getSearchString } from '../../utils/searchUtils';
import { AdvancedSearchFilterDialog } from '../Filter/AdvancedSearchFilterDialog';
import { SearchHelpModal } from '../modals';
import { Tooltip } from '../Tooltip';
import {
  SearchBarHelpButton,
  SearchBarMenu,
  SearchBarMenuItem,
  SearchBarMenuItemText,
  SearchBarSuggestion,
  SearchBarSuggestionsWrapper,
  SearchBarTextField,
  SearchBarTextFieldWrapper,
  SearchBarWrapper,
} from './SearchBar.styles';

export const SearchBar: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const page = useRecoilValue(pageContext);
  const [searchString, handleSearchInputChange, setSearchString] = useInput();
  const handleClearSearch = useCallback(
    () => setSearchString(''),
    [setSearchString],
  );

  const { extendedFilter, filterId, handleFilterChange, getFilterQuery } =
    useFilter({
      tableType: TableType.Generic,
      tableSubType: TableSubType.Search,
    });

  const hasFilter = Object.keys(extendedFilter).length > 0;

  const { reference, strategy, floating, x, y } = useFloating({
    placement: 'bottom',
    whileElementsMounted: (reference, floating, update) =>
      autoUpdate(reference, floating, update, {
        animationFrame: true,
      }),
  });

  useEscapeKey({
    callback: useCallback(() => {
      setSearchString('');
      if (searchString?.length) {
        return true;
      }
    }, [searchString?.length, setSearchString]),
    priority: searchString.length > 0 ? 5 : 1,
  });

  const handleGlobalSearchNoFilter = useCallback(() => {
    if (searchString.length) {
      navigate(constructUrl(routes.search, null, { searchString }));
      setSearchString('');
    }
  }, [navigate, searchString, setSearchString]);

  const handleGlobalSearchWithFilter = useCallback(() => {
    if (searchString.length) {
      navigate(
        constructUrl(
          routes.search,
          null,
          getFilterQuery({ ...extendedFilter, searchString, filterId }),
        ),
      );
      setSearchString('');
    }
  }, [
    extendedFilter,
    filterId,
    getFilterQuery,
    navigate,
    searchString,
    setSearchString,
  ]);

  const handleLocalSearchNoFilter = useCallback(() => {
    if (searchString.length) {
      handleFilterChange({ searchString });
      setSearchString('');
    }
  }, [handleFilterChange, searchString, setSearchString]);

  const handleLocalSearchWithFilter = useCallback(() => {
    if (searchString.length) {
      handleFilterChange({ ...extendedFilter, searchString }, filterId);
      setSearchString('');
    }
  }, [
    extendedFilter,
    filterId,
    handleFilterChange,
    searchString,
    setSearchString,
  ]);

  const pageWithFilter = useRef<never>();
  const pageNoFilter = useRef<never>();
  const globalWithFilter = useRef<never>();
  const globalNoFilter = useRef<never>();

  const items = useMemo(
    () =>
      [
        page?.tableType && hasFilter
          ? {
              ref: pageWithFilter,
              callback: handleLocalSearchWithFilter,
              messageKey: MessageKeys.SearchBarSearchLocallyWithFilter,
              mode: GlobalSearchMode.LocalWithFilter,
            }
          : null,
        page?.tableType
          ? {
              ref: pageNoFilter,
              callback: handleLocalSearchNoFilter,
              messageKey: MessageKeys.SearchBarSearchLocallyNoFilter,
              mode: GlobalSearchMode.LocalNoFilter,
            }
          : null,
        hasFilter
          ? {
              ref: globalWithFilter,
              callback: handleGlobalSearchWithFilter,
              messageKey: MessageKeys.SearchBarSearchGloballyWithFilter,
              mode: GlobalSearchMode.GlobalWithFilter,
            }
          : null,
        {
          ref: globalNoFilter,
          callback: handleGlobalSearchNoFilter,
          messageKey: MessageKeys.SearchBarSearchGloballyNoFilter,
          mode: GlobalSearchMode.GlobalNoFilter,
        },
      ].filter(Boolean),
    [
      handleGlobalSearchNoFilter,
      handleGlobalSearchWithFilter,
      handleLocalSearchNoFilter,
      handleLocalSearchWithFilter,
      hasFilter,
      page?.tableType,
    ],
  );

  const enabledModes = useMemo(() => items.map(({ mode }) => mode), [items]);

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleArrowKeys = useCallback(
    (direction: ArrowDirectionOld) => {
      if (direction === 'down') {
        setActiveIndex(((activeIndex ?? -1) + 1) % items.length);
      } else if (direction === 'up') {
        setActiveIndex((activeIndex + items.length * 2 - 1) % items.length);
      }
    },
    [activeIndex, items.length],
  );

  useArrowKeysOld(searchString.length ? handleArrowKeys : null, true);

  useEscapeKey({
    callback: useCallback(() => setSearchString(''), [setSearchString]),
    allowInputEvents: true,
  });

  const handleSearchInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key.toLowerCase() === 'enter') {
        const callback = items[activeIndex >= 0 ? activeIndex : 0].callback;
        callback();
      }
    },
    [activeIndex, items],
  );

  const [showHelpModal, , toggleHelpModal] = useToggle(false);

  const [advancedSearchRows, setAdvancedSearchRows] =
    useState<Array<SearchRow>>();

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const handleShowAdvancedSearchClick = useCallback(() => {
    setShowAdvancedSearch(true);
    setAdvancedSearchRows(
      getSearchRows(searchString || single(extendedFilter.searchString)),
    );
  }, [extendedFilter.searchString, searchString]);

  const handleRequestCloseAdvancedSearch = useCallback(
    () => setShowAdvancedSearch(false),
    [],
  );

  const handleSubmitAdvancedSearch = useCallback(
    (rows: Array<SearchRow>, mode?: GlobalSearchMode) => {
      const searchString = getSearchString(rows);
      setShowAdvancedSearch(false);
      setSearchString('');
      switch (mode) {
        case GlobalSearchMode.GlobalNoFilter:
          navigate(constructUrl(routes.search, null, { searchString }));
          break;
        case GlobalSearchMode.GlobalWithFilter:
          navigate(
            constructUrl(
              routes.search,
              null,
              getFilterQuery({ ...extendedFilter, searchString, filterId }),
            ),
          );
          break;
        case GlobalSearchMode.LocalNoFilter:
          handleFilterChange({ searchString });
          break;
        case GlobalSearchMode.LocalWithFilter:
          handleFilterChange({ ...extendedFilter, searchString }, filterId);
          break;
      }
    },
    [
      extendedFilter,
      filterId,
      getFilterQuery,
      handleFilterChange,
      navigate,
      setSearchString,
    ],
  );

  return (
    <SearchBarWrapper>
      {showHelpModal ? <SearchHelpModal onHide={toggleHelpModal} /> : null}
      {showAdvancedSearch ? (
        <AdvancedSearchFilterDialog
          rows={advancedSearchRows}
          globalSearchModes={enabledModes}
          onRequestClose={handleRequestCloseAdvancedSearch}
          onSubmit={handleSubmitAdvancedSearch}
        />
      ) : null}
      <SearchBarTextFieldWrapper ref={reference}>
        <SearchBarTextField
          value={searchString}
          open={searchString.length > 0}
          placeholder={t(MessageKeys.SearchBarTextFieldPlaceholder)}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchInputKeyDown}
          onClear={handleClearSearch}
        />
      </SearchBarTextFieldWrapper>
      <SearchBarHelpButton onClick={toggleHelpModal}>
        <HelpCircleIcon size="20" />
      </SearchBarHelpButton>
      <Tooltip content={t(MessageKeys.SearchBarAdvancedSearchButtonTooltip)}>
        <SearchBarHelpButton onClick={handleShowAdvancedSearchClick}>
          <SearchPlusIcon size="22" />
        </SearchBarHelpButton>
      </Tooltip>
      {searchString.length ? (
        <Portal>
          <SearchBarSuggestionsWrapper
            ref={floating}
            style={{ position: strategy, left: x, top: y }}
          >
            <SearchBarMenu>
              {items.map(({ callback, messageKey }, index) => (
                <SearchBarMenuItem
                  key={messageKey}
                  onClick={callback}
                  tabIndex={-1}
                  active={activeIndex === index}
                >
                  <SearchBarMenuItemText>{searchString}</SearchBarMenuItemText>
                  <SearchBarSuggestion>{t(messageKey)}</SearchBarSuggestion>
                </SearchBarMenuItem>
              ))}
            </SearchBarMenu>
          </SearchBarSuggestionsWrapper>
        </Portal>
      ) : null}
    </SearchBarWrapper>
  );
};
