import {
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  FormGridToggle,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useFocus,
  useInput,
} from '@fcg-tech/regtech-components';
import { StoredFilter } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalForm,
  ModalFormWrapper,
} from '../../components/modals/modalFormComponents';
import { MessageKeys } from '../../translations/translationTypes';

interface FeedArticleFilterSaveDialogProps {
  storedFilter?: StoredFilter;
  allowTeamFilters?: boolean;
  onSubmit: (name: string, teamFilter: boolean) => Promise<void>;
  onRequestClose: () => void;
}

export const FeedArticleFilterSaveDialog: FunctionComponent<
  FeedArticleFilterSaveDialogProps
> = ({ storedFilter, allowTeamFilters, onSubmit, onRequestClose }) => {
  const { t } = useTranslation();
  const [name, handleNameChange] = useInput(storedFilter?.name);
  const [createAsTeamFilter, setCreateAsTeamFilter] = useState(
    storedFilter?.personalFilter === false,
  );
  const [loading, setLoading] = useState(false);

  const handleCreateAsTeamFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCreateAsTeamFilter(event.target.checked);
    },
    [setCreateAsTeamFilter],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      await onSubmit(name, createAsTeamFilter);
      onRequestClose();
    },
    [onSubmit, name, createAsTeamFilter, onRequestClose],
  );

  const ref = useFocus<HTMLInputElement>();

  return (
    <Modal onHide={onRequestClose}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.FeedArticleFilterSaveFilterButtonLabel)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalForm onSubmit={handleSubmit}>
          <ModalFormWrapper className="narrow">
            <FormGridRow className={classNames(name?.length && 'valid')}>
              <FormGridLabel>
                {t(MessageKeys.FeedArticleFilterSaveFilterFilterNameLabel)}
              </FormGridLabel>
              <FormGridControl>
                <TextField
                  name="name"
                  ref={ref}
                  value={name}
                  valid={name?.length > 0}
                  onChange={handleNameChange}
                />
              </FormGridControl>
            </FormGridRow>
            {allowTeamFilters && !storedFilter?.id ? (
              <FormGridRow
                className={classNames(
                  'with-toggle',
                  createAsTeamFilter && 'valid',
                )}
              >
                <FormGridLabel>
                  {t(MessageKeys.FeedArticleFilterSaveFilterTeamFilterLabel)}
                </FormGridLabel>
                <FormGridControl>
                  <FormGridToggle
                    checked={createAsTeamFilter}
                    onChange={handleCreateAsTeamFilterChange}
                  />
                </FormGridControl>
              </FormGridRow>
            ) : null}
            <ModalButtonRow>
              <SecondaryButton
                type="button"
                onClick={onRequestClose}
                disabled={loading}
              >
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton type="submit" disabled={!name} loading={loading}>
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </ModalButtonRow>
          </ModalFormWrapper>
        </ModalForm>
      </ModalBody>
    </Modal>
  );
};
