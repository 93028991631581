import { Showcase, ShowcaseReel } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeShowcase, Translator } from '../../types';
import { ShowCaseContent } from './ShowCaseContent';
import { useShowcase } from './useShowcase';

export const MarkAsReadShowcase: FunctionComponent = () => {
  const { t } = useTranslation();
  const [display, handleExit] = useShowcase(RegeyeShowcase.MarkAsRead, {
    requireShowcaseCompleted: [RegeyeShowcase.GenericFeedArticleTable],
  });
  return display ? (
    <Showcase reel={MarkAsReadShowcaseReel(t)} onExit={handleExit} />
  ) : null;
};

const contentStyle = { width: 940 };

const MarkAsReadShowcaseReel = (t: Translator): ShowcaseReel => ({
  id: RegeyeShowcase.MarkAsRead,
  skippable: true,
  waitForQuerySelector: '[role="tablist"]',
  entries: [
    {
      content: (entry) => (
        <ShowCaseContent
          entry={entry}
          heading={t(MessageKeys.ShowCaseMarkAsReadWelcomeHeading)}
          content={t(MessageKeys.ShowCaseMarkAsReadWelcomeBody)}
        />
      ),
      contentStyle,
      illustrationStyle: { backgroundSize: 'contain', paddingBottom: '37.2%' },
      illustrationSrc:
        'assets/showcase/mark-as-read/mark-as-read-showcase-01.jpg',
    },
  ],
});
