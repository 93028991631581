import { Company, Team, TeamInvitation } from '@fcg-tech/regtech-types/regeye';
import { IamContextProps } from '../components/IamContext';
import {
  canViewCompanyAdministration,
  canViewPageCompaniesAdministration,
} from './iamHelpers';

export enum SetupStatus {
  None,
  CreateTeamAndInviteUsers,
  HasInvites,
  HasNoTeams,
  JoinTeam,
}

export const getSetupStatus = (
  companies: Array<Company>,
  teams: Array<Team>,
  singleCompanyTeams: Array<Team>,
  invitations: Array<TeamInvitation>,
  iamContext: IamContextProps,
): SetupStatus => {
  if (canViewPageCompaniesAdministration(iamContext) || teams.length > 0) {
    return SetupStatus.None;
  }

  if (invitations?.length) {
    return SetupStatus.HasInvites;
  }

  if (companies.length > 1) {
    return SetupStatus.None;
  }

  if (!canViewCompanyAdministration(companies[0].id, iamContext)) {
    return SetupStatus.HasNoTeams;
  }

  if (singleCompanyTeams.length === 0) {
    return SetupStatus.CreateTeamAndInviteUsers;
  } else {
    return SetupStatus.JoinTeam;
  }
};
