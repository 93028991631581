import styled from 'styled-components';
import { SecondaryButton } from './Button';

export const DangerButton = styled(SecondaryButton)`
  background-color: red;
  border: red;
  font-weight: 600;
  color: white;

  &:active {
    filter: brightness(90%);
    background-color: red;
    border: red;
    box-shadow: 0 2px 4px 0 rgba(255, 0, 0, 0.3);
  }
`;
