import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, { FunctionComponent, useMemo } from 'react';

import { useInitData } from '../../api/hooks/initApiHooks';
import { InitData } from '../../api/schema';
import { ErrorBoundary } from '../ErrorBoundary';

export type InitContextProps = {
  initData: InitData | null;
  error?: boolean;
};

export const InitContext = React.createContext<InitContextProps>({
  initData: null,
});

export const useInitContext = () => React.useContext(InitContext);

export const InitContextProvider: FunctionComponent<EmptyPropsWithChildren> = ({
  children,
}) => {
  return (
    <ErrorBoundary>
      <InitContextProviderLoader>{children}</InitContextProviderLoader>
    </ErrorBoundary>
  );
};

export const InitContextProviderLoader: FunctionComponent<
  EmptyPropsWithChildren
> = ({ children }) => {
  const { data: initData, error } = useInitData({
    suspense: true,
  });

  const contextValue = useMemo<InitContextProps>(
    () => ({
      initData,
      error: Boolean(error),
    }),
    [error, initData],
  );

  return (
    <InitContext.Provider value={contextValue}>{children}</InitContext.Provider>
  );
};
