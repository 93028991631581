import { useCallback } from 'react';
import { Settings } from '@fcg-tech/regtech-types/regeye';
import { useShowcase } from '../../components/showcases/useShowcase';
import { RegeyeShowcase } from '../../types';
import { useSettingsActions } from '../../api/hooks';

export const useProfilePageShowcaseSettings = (
  id: RegeyeShowcase,
): [
  isDeferred: boolean,
  onToggleEvent: (event: React.ChangeEvent<HTMLInputElement>) => void,
] => {
  const [display, , update] = useShowcase(id);

  const onToggleEvent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      update(event.target.checked);
    },
    [update],
  );

  return [!display, onToggleEvent];
};

export const useSettingsFlag = <K extends keyof Settings['flags']>(key: K) => {
  const { patchSettings } = useSettingsActions();

  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      patchSettings({
        flags: {
          [key]: event.target.checked,
        },
      });
    },
    [key, patchSettings],
  );
};
