import {
  ID,
  Publisher,
  SubscriptionUpdateItemsInner,
} from '@fcg-tech/regtech-types/regeye';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';

import { useApi } from '../apiUtils';
import { PublishersApi } from '../schema';
import { publishersForTeamKey, publishersKey } from './cacheKeys';

export const usePublishersForTeam = (
  teamId: ID | null,
  config: SWRConfiguration = {},
) => {
  const api = useApi<PublishersApi>('PublishersApi');
  return useSWR<Array<Publisher>>(
    teamId ? publishersForTeamKey(teamId) : null,
    async () => (await api.getTeamSubscription({ teamId })).items,
    {
      suspense: true,
      ...config,
    },
  );
};

export const usePublisherTeamActions = (teamId: string | null) => {
  const api = useApi<PublishersApi>('PublishersApi');
  const { mutate } = useSWRConfig();

  return {
    updateTeamPublisherSettings: async (
      items: Array<SubscriptionUpdateItemsInner>,
      overrideTeamId?: string,
    ) => {
      await api.updateTeamSubscription({
        teamId: overrideTeamId ?? teamId,
        subscriptionUpdate: {
          items,
        },
      });

      mutate(publishersForTeamKey(teamId));
    },

    companyUpdateTeamPublisherSettings: async (
      items: Array<SubscriptionUpdateItemsInner>,
      companyId: string,
      overrideTeamId?: string,
    ) => {
      await api.companyUpdateTeamSubscription({
        teamId: overrideTeamId ?? teamId,
        companyId,
        subscriptionUpdate: {
          items,
        },
      });
    },
  };
};

export const useAllPublishers = (config: SWRConfiguration = {}) => {
  const api = useApi<PublishersApi>('PublishersApi');
  return useSWR<Array<Publisher>>(
    publishersKey(),
    async () => (await api.getPublishers()).items,
    {
      suspense: true,
      ...config,
    },
  );
};
