import { FlashNotificationManager, H1 } from '@fcg-tech/regtech-components';
import { HEADER_HEIGHT_REM } from '@fcg-tech/regtech-design-system/regeye';
import styled from 'styled-components';

export const MainLayoutWrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  display: flex;
`;

export const MainLayoutContent = styled.div`
  background-color: ${({ theme }) => theme.colors?.backdrop ?? '#fff'};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: stretch;
  max-height: 100vh;
  min-width: 0;
  position: relative;
`;

export const MainLayoutPageWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - ${HEADER_HEIGHT_REM}rem);
  position: relative;
`;

export const MainLayoutContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

export const MainLayoutPaddedContentWrapper = styled.div`
  padding: 1.5rem;

  & > ${H1}:first-child {
    margin: 1.6rem 0 0.6rem 0;
  }

  &.sticky {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #eee;
  }

  & + & {
    margin-top: 2rem;
  }
`;

export const MainLayoutFlashNotificationWrapper = styled(
  FlashNotificationManager,
)`
  position: absolute;
`;

export const MainLayoutSideSubNavigationOutletWrapper = styled.div`
  margin-top: 0.8rem;
  display: flex;
  min-height: 0;
  height: calc(100% - 0.8rem);
  overflow: hidden;

  & > .menu {
    width: 20rem;
  }

  & > .content {
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    background-color: white;
  }
`;
