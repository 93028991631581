import { GRID_AREA_MAIN } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const CompanyOutletWrapper = styled.div`
  grid-area: ${GRID_AREA_MAIN};
  height: 100%;
  min-width: 0;
  overflow: hidden;

  & > .tab-panel {
    height: 100%;
  }
`;
