import { useTranslation } from 'react-i18next';
import {
  ConfirmDialog,
  H2,
  PrimaryButton,
  useConfirmDialog,
  useFlash,
  useToggle,
} from '@fcg-tech/regtech-components';
import { Team } from '@fcg-tech/regtech-types/regeye';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  useCompanyTeamInvitations,
  useCurrentUser,
  useSettings,
} from '../../api/hooks';
import { InvitationsCreateResult } from '../../api/schema';
import { NoContentMessage } from '../../components/generic';
import { useIamContext } from '../../components/IamContext';
import {
  TeamInviteMemberModal,
  TeamMemberInvitationsTable,
  TeamMemberTable,
} from '../../components/TeamMemberAdministrationList';
import { MessageKeys } from '../../translations/translationTypes';
import {
  canCompanyTeamInvitationCreate,
  canCompanyTeamInvitationDelete,
  canCompanyTeamMemberDelete,
} from '../../utils/iamHelpers';
import { userSorter } from '../../utils/sorters';
import {
  TeamAdministrationPageTeaMemberDetailsInvitationsHeading,
  TeamAdministrationTeamTabButtonBar,
  TeamAdministrationTeamTabContentInnerWrapper,
} from './TeamAdministration.styles';
import { MainLayoutPaddedContentWrapper } from '../MainLayout/MainLayout.styles';

export interface TeamAdministrationTeamMemberDetailsPropsCallbacks {
  onCreateTeamInvitation: (
    companyId: string,
    emails: Array<string>,
    message: string,
    overrideTeamId?: string,
  ) => Promise<InvitationsCreateResult>;
  onRemoveTeamInvitation: (
    companyId: string,
    teamInvitationId: string,
    overrideTeamId?: string,
  ) => Promise<void>;
  onRemoveTeamMember: (
    companyId: string,
    teamId: string,
    username?: string,
  ) => Promise<void>;
  onAddSelfToTeam: (companyId: string, teamId: string) => Promise<void>;
  onLeaveTeam: (teamId: string) => Promise<void>;
}

interface TeamAdministrationTeamMemberDetailsProps
  extends TeamAdministrationTeamMemberDetailsPropsCallbacks {
  team: Team;
}

export const TeamAdministrationTeamMemberDetails: FunctionComponent<
  TeamAdministrationTeamMemberDetailsProps
> = ({
  team,
  onCreateTeamInvitation,
  onLeaveTeam,
  onRemoveTeamInvitation,
  onAddSelfToTeam,
  onRemoveTeamMember,
}) => {
  const companyId = team.company?.id;
  const teamId = team.id;
  const { data: user } = useCurrentUser();
  const { data: invitations } = useCompanyTeamInvitations(companyId, teamId);

  const { t } = useTranslation();
  const addFlash = useFlash();

  const { data: settings } = useSettings({ suspense: false });

  const sortedMembers = useMemo(
    () => team?.members?.slice().sort(userSorter(settings)) ?? [],
    [team?.members, settings],
  );

  const iamContext = useIamContext();

  const [isInviting, setIsInviting] = useState(false);

  const [showRemoveConfirm, handleRemoveConfirm, confirmRemoveMember] =
    useConfirmDialog();
  const [showConfirmLeaveTeamDialog, handleConfirmLeaveTeam, confirmLeaveTeam] =
    useConfirmDialog();

  const isMember = team.members.find(
    (member) => member.username === user.username,
  );

  const handleRemoveMember = useCallback(
    async (userId: string) => {
      try {
        if (await confirmRemoveMember()) {
          await onRemoveTeamMember(companyId, teamId, userId);
          addFlash({
            level: 'success',
            content: t(
              MessageKeys.TeamSettingsTabMembersRemoveMemberSuccessful,
              { name: team.name },
            ),
            timeoutMS: 5000,
          });
        }
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    },
    [
      confirmRemoveMember,
      onRemoveTeamMember,
      companyId,
      teamId,
      addFlash,
      t,
      team.name,
    ],
  );

  const handleJoinTeamClick = useCallback(async () => {
    try {
      setIsInviting(true);
      await onAddSelfToTeam(companyId, teamId);

      addFlash({
        level: 'success',
        content: t(
          MessageKeys.AdministrationTeamMembersJoinTeamSuccessMessage,
          { name: team.name },
        ),
        timeoutMS: 5000,
      });
    } catch (e) {
      addFlash({
        level: 'error',
        content: t(MessageKeys.LabelCouldNotSave),
      });
    } finally {
      setIsInviting(false);
    }
  }, [addFlash, companyId, onAddSelfToTeam, t, team.name, teamId]);

  const handleLeaveTeamClick = useCallback(async () => {
    const confirmed = await confirmLeaveTeam();
    if (confirmed) {
      try {
        setIsInviting(true);
        await onLeaveTeam(teamId);
        addFlash({
          level: 'success',
          content: t(
            MessageKeys.AdministrationTeamMembersLeaveTeamSuccessMessage,
            { name: team.name },
          ),
          timeoutMS: 5000,
        });
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.LabelCouldNotSave),
        });
      } finally {
        setIsInviting(false);
      }
    }
  }, [addFlash, confirmLeaveTeam, onLeaveTeam, t, team.name, teamId]);

  const canInvite = canCompanyTeamInvitationCreate(companyId, iamContext);
  const canUnInvite = canCompanyTeamInvitationDelete(companyId, iamContext);
  const canRemove = canCompanyTeamMemberDelete(companyId, iamContext);

  const handleRemoveInvitation = useCallback(
    async (invitationId: string) => {
      try {
        await onRemoveTeamInvitation(companyId, invitationId);
        addFlash({
          level: 'success',
          content: t(
            MessageKeys.TeamSettingsTabMembersRemoveInvitationSuccessful,
          ),
          timeoutMS: 5000,
        });
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    },
    [addFlash, onRemoveTeamInvitation, t, companyId],
  );

  const [showInviteModal, setShowInviteModal, handleToggleInviteModal] =
    useToggle(false);

  const handleInvitationEmailsChange = useCallback(
    async (emails: Array<string>, message: string) => {
      setShowInviteModal(false);
      setIsInviting(true);
      try {
        const result = await onCreateTeamInvitation(companyId, emails, message);
        addFlash({
          level: 'info',
          timeoutMS: 7500,
          content: t(
            !result.failed?.length
              ? MessageKeys.TeamSettingsTabMembersInviteMemberSuccessful
              : result.failed?.length && result.succeed?.length
              ? MessageKeys.TeamSettingsTabMembersInviteMemberPartiallySuccessful
              : MessageKeys.TeamSettingsTabMembersInviteMemberFailure,
            { name: result.failed?.join(', ') ?? '(N/A)' },
          ),
        });
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.LabelCouldNotSave),
        });
      } finally {
        setIsInviting(false);
      }
    },
    [setShowInviteModal, onCreateTeamInvitation, companyId, addFlash, t],
  );

  return (
    <>
      <MainLayoutPaddedContentWrapper>
        {showInviteModal ? (
          <TeamInviteMemberModal
            onCancel={handleToggleInviteModal}
            onConfirm={handleInvitationEmailsChange}
          />
        ) : null}
        {showRemoveConfirm ? (
          <ConfirmDialog
            title={t(MessageKeys.LabelConfirmAction)}
            body={t(MessageKeys.TeamSettingsTabMembersRemoveMemberConfirmLabel)}
            confirmText={t(MessageKeys.LabelConfirmLabel)}
            cancelText={t(MessageKeys.LabelCancel)}
            onChoice={handleRemoveConfirm}
          />
        ) : null}
        {showConfirmLeaveTeamDialog ? (
          <ConfirmDialog
            title={t(MessageKeys.AdministrationTeamMembersLeaveTeamButtonLabel)}
            body={t(
              MessageKeys.AdministrationTeamMembersLeaveTeamConfirmLabel,
              {
                name: team.name,
              },
            )}
            confirmText={t(MessageKeys.LabelConfirmLabel)}
            cancelText={t(MessageKeys.LabelCancel)}
            onChoice={handleConfirmLeaveTeam}
          />
        ) : null}
        <div className="flex space-between">
          <H2>{t(MessageKeys.AdministrationTeamDetailsMembers)}</H2>
          {canInvite || isMember ? (
            <TeamAdministrationTeamTabButtonBar>
              {isMember ? (
                <PrimaryButton
                  onClick={handleLeaveTeamClick}
                  loading={isInviting}
                >
                  {t(MessageKeys.AdministrationTeamMembersLeaveTeamButtonLabel)}
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={handleJoinTeamClick}
                  loading={isInviting}
                >
                  {t(MessageKeys.AdministrationTeamMembersJoinTeamButtonLabel)}
                </PrimaryButton>
              )}
              {canInvite ? (
                <PrimaryButton
                  onClick={handleToggleInviteModal}
                  loading={isInviting}
                >
                  {t(MessageKeys.TeamSettingsTabMembersInviteMember)}
                </PrimaryButton>
              ) : null}
            </TeamAdministrationTeamTabButtonBar>
          ) : null}
        </div>
      </MainLayoutPaddedContentWrapper>
      <TeamMemberTable
        members={sortedMembers ?? []}
        onRemoveMember={canRemove ? handleRemoveMember : null}
      />
      <TeamAdministrationTeamTabContentInnerWrapper>
        {sortedMembers.length === 0 ? (
          <NoContentMessage>
            {t(MessageKeys.AdministrationTeamMembersEmptyMessage)}
          </NoContentMessage>
        ) : null}
      </TeamAdministrationTeamTabContentInnerWrapper>
      {invitations?.length ? (
        <>
          <TeamAdministrationTeamTabContentInnerWrapper>
            <TeamAdministrationPageTeaMemberDetailsInvitationsHeading>
              {t(MessageKeys.AdministrationTeamMembersInvitationsHeading)}
            </TeamAdministrationPageTeaMemberDetailsInvitationsHeading>
          </TeamAdministrationTeamTabContentInnerWrapper>
          <TeamMemberInvitationsTable
            invitations={invitations}
            onRemoveInvitation={canUnInvite ? handleRemoveInvitation : null}
          />
        </>
      ) : null}
    </>
  );
};
