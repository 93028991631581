import { constructFullUrl } from '@fcg-tech/regtech-api-utils';
import { useCopyToClipboard } from '@fcg-tech/regtech-components';
import {
  FeedArticle,
  FeedArticleMetadataMutationCallbacks,
  GlobalActions,
} from '@fcg-tech/regtech-types/regeye';
import { format } from 'date-fns';
import { Bookmark, Link, Mail } from 'lucide-react';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TABLE_DATE_FORMAT } from '../../constants';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { AccessControl } from '../AccessControl';
import { ActionIconButton } from '../buttons';
import { Debug } from '../Debug';
import { ArticleTag, ArticleTypeTag } from '../Tag';
import { TagList } from '../TagList';
import { Tooltip } from '../Tooltip';
import { FeedArticleFrame } from './FeedArticleFrame';
import {
  FeedArticleActionsWrapper,
  FeedArticleBody,
  FeedArticleExternalLinkIcon,
  FeedArticleHeading,
  FeedArticleMetaData,
  FeedArticleMetaDataWrapper,
  FeedArticlePublisherLink,
  FeedArticleReaderWrapper,
  FeedArticleTagsWrapper,
} from './FeedArticleReader.styles';

interface FeedArticleReaderProps
  extends Omit<FeedArticleMetadataMutationCallbacks, 'onArchive'> {
  feedArticle: FeedArticle;
  articleText?: string;
  onTagClick?: (tagId: string) => void;
  onArticleTypeClick?: (tagId: string) => void;
  onContentLoad?: () => void;
}

export const FeedArticleReader: FunctionComponent<FeedArticleReaderProps> = ({
  feedArticle,
  articleText,
  onTagClick,
  onArticleTypeClick,
  onContentLoad,
  onBookmark,
  onMarkAsRead,
}) => {
  const { t } = useTranslation();
  const articleId = feedArticle.article.id;

  const handleBookmarkClick = useCallback(
    () =>
      onBookmark(!feedArticle.userMetadata.bookmarked, feedArticle.article.id),
    [feedArticle.article.id, feedArticle.userMetadata.bookmarked, onBookmark],
  );
  const handleMarkAsReadClick = useCallback(
    () => onMarkAsRead(!feedArticle.userMetadata.read, feedArticle.article.id),
    [feedArticle.article.id, feedArticle.userMetadata.read, onMarkAsRead],
  );

  const [isPermalinkCopied, handleCopyPermalink] = useCopyToClipboard(
    constructFullUrl(routes.feedArticleById, { articleId }),
    {
      successDuration: 3000,
    },
  );

  return (
    <FeedArticleReaderWrapper>
      {feedArticle.article.documentLocation?.ref ? (
        <FeedArticleHeading href={feedArticle.article.documentLocation?.ref}>
          {feedArticle.article?.title} <FeedArticleExternalLinkIcon size="16" />
        </FeedArticleHeading>
      ) : (
        <FeedArticleHeading as="span">
          {feedArticle.article?.title}
        </FeedArticleHeading>
      )}
      <Debug>{feedArticle.article?.id}</Debug>
      <FeedArticleMetaDataWrapper>
        <FeedArticleMetaData>
          {format(feedArticle.article.publicationDate, TABLE_DATE_FORMAT)}
        </FeedArticleMetaData>
        <FeedArticleMetaData>
          <FeedArticlePublisherLink href={feedArticle.article.publisherLink}>
            {t(MessageKeys.ArticleViaPublisher, {
              publisher: feedArticle.article?.publisher,
            })}
            <FeedArticleExternalLinkIcon size="14" />
          </FeedArticlePublisherLink>
        </FeedArticleMetaData>
      </FeedArticleMetaDataWrapper>
      <FeedArticleActionsWrapper>
        <AccessControl requiredPermissions={GlobalActions.ReadChange}>
          <Tooltip
            placement="top-start"
            html
            content={t(
              feedArticle.userMetadata.read
                ? MessageKeys.ArticleMarkAsReadButtonTooltipIsRead
                : MessageKeys.ArticleMarkAsReadButtonTooltipIsNotRead,
            )}
          >
            <ActionIconButton
              onClick={handleMarkAsReadClick}
              active={feedArticle.userMetadata.read}
            >
              <Mail size="18" />
            </ActionIconButton>
          </Tooltip>
        </AccessControl>

        <AccessControl requiredPermissions={GlobalActions.BookmarkChange}>
          <Tooltip
            placement="top-start"
            content={t(
              feedArticle.userMetadata.bookmarked
                ? MessageKeys.ArticleBookmarkButtonTooltipIsBookmarked
                : MessageKeys.ArticleBookmarkButtonTooltipIsNotBookmarked,
            )}
          >
            <ActionIconButton
              onClick={handleBookmarkClick}
              active={feedArticle.userMetadata.bookmarked}
            >
              <Bookmark size="18" />
            </ActionIconButton>
          </Tooltip>
        </AccessControl>

        <Tooltip
          placement="top-start"
          content={t(MessageKeys.ArticlePermalinkTooltip)}
        >
          <ActionIconButton
            onClick={handleCopyPermalink}
            message={
              isPermalinkCopied
                ? t(MessageKeys.ArticlePermalinkCopiedMessage)
                : undefined
            }
          >
            <Link size="18" />
          </ActionIconButton>
        </Tooltip>
      </FeedArticleActionsWrapper>
      <FeedArticleTagsWrapper>
        <TagList
          tags={feedArticle.article.articleTypes}
          onClick={onArticleTypeClick}
          TagComponent={ArticleTypeTag}
        />
        <TagList
          onClick={onTagClick}
          tags={feedArticle.article.articleTags}
          maxQuantity={10}
          allowShowAll
          TagComponent={ArticleTag}
        />
      </FeedArticleTagsWrapper>
      {articleText ? (
        <FeedArticleFrame
          articleText={articleText}
          article={feedArticle.article}
          onContentLoad={onContentLoad}
        />
      ) : (
        <FeedArticleBody>{feedArticle.article.description}</FeedArticleBody>
      )}
    </FeedArticleReaderWrapper>
  );
};
