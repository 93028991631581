import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';

import { NoContentMessage } from '../../components/generic';
import { MessageKeys } from '../../translations/translationTypes';
import { FallbackWrapper } from './fallback.styles';

export const FallbackNoCompaniesPage: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <FallbackWrapper>
      {t(MessageKeys.FallbackNoCompaniesMessage)}
    </FallbackWrapper>
  );
};
