import { RichTextMentionElement } from '@fcg-tech/regtech-richtext';
import type { Descendant, Element as SlateElement } from 'slate';
import { embeddedAtRefRe } from './atRefUtils';

export const convertLegacyCommentToDescendants = (
  comment: string,
): Descendant[] => {
  return comment.split('\n').map<Descendant>((line) => {
    const re = new RegExp(embeddedAtRefRe, 'g');
    const d: Descendant = {
      type: 'paragraph',
      children: [],
    };

    let arr: RegExpExecArray | null;
    let lastIndex = 0;

    while ((arr = re.exec(line)) !== null) {
      const pre = line.substring(lastIndex, arr.index);

      //Mentions are only mentions if the are preceeded by a space, or is the first thing on the line
      if (arr[1].length !== arr[0].length || lastIndex === 0) {
        d.children.push({
          text: `${pre} `,
        });
        d.children.push({
          type: 'mention',
          id: arr[1].replace(/^@/, ''),
          children: [
            {
              text: arr[1],
            },
          ],
        });

        lastIndex = re.lastIndex;
      }
    }

    if (lastIndex !== line.length || line.length === 0) {
      d.children.push({
        type: 'text',
        text: line.substring(lastIndex),
      });
    }

    return d;
  });
};

export const findMentions = (markup: Array<Descendant>): string[] => {
  let mentions = [];

  markup?.forEach((d) => {
    if ((d as SlateElement).type === 'mention') {
      mentions.push((d as RichTextMentionElement).id);
    } else if ((d as SlateElement).children) {
      mentions = [...mentions, ...findMentions((d as SlateElement).children)];
    }
  });

  return Array.from(new Set(mentions));
};
