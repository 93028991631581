import {
  DataTableColumn,
  RowRenderer,
  SortingRule,
} from '@fcg-tech/regtech-datatable';
import { User } from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeDataTable } from '../datatable/RegeyeDataTable';
import { RowData, TeamMemberTableRow } from './TeamMemberTableRow';

interface TeamMemberTableProps {
  members: Array<User>;
  onRemoveMember?: (userId: string) => Promise<void>;
}

export const TeamMemberTable: FunctionComponent<TeamMemberTableProps> = ({
  members,
  onRemoveMember,
}) => {
  const { t } = useTranslation();
  const [sortOrder, setSortOrder] = useState<SortingRule<RowData>>({
    desc: true,
    id: 'alias',
  });
  const columns = useMemo<Array<DataTableColumn<RowData>>>(() => {
    const columns: Array<DataTableColumn<RowData>> = [
      {
        id: 'alias',
        width: 200,
        minWidth: 200,
        defaultCanSort: true,
        Header: t(MessageKeys.LabelAlias),
        accessor: (row: RowData) => row.alias,
      },
      {
        id: 'username',
        width: 200,
        minWidth: 200,
        defaultCanSort: true,
        Header: t(MessageKeys.LabelUsername),
        accessor: (row: RowData) => row.username,
      },
    ];
    return columns;
  }, [t]);

  const dataRows = useMemo<Array<RowData>>(() => {
    return members
      .map((user) => ({
        alias: user.alias,
        username: user.username,
      }))
      .sort((a, b) => {
        if (sortOrder?.desc === undefined) {
          return 0;
        }
        switch (sortOrder?.id) {
          case 'alias':
            return a.alias.localeCompare(b.alias) * (sortOrder?.desc ? -1 : 1);
          case 'username':
            return (
              a.username.localeCompare(b.username) * (sortOrder?.desc ? -1 : 1)
            );
        }
      });
  }, [members, sortOrder]);

  const WrappedRow = useMemo<RowRenderer<RowData>>(
    () => (props) =>
      <TeamMemberTableRow {...props} onRemove={onRemoveMember} />,
    [onRemoveMember],
  );

  return (
    <RegeyeDataTable
      columns={columns}
      data={dataRows}
      sortBy={sortOrder}
      Row={WrappedRow}
      onSortByChange={setSortOrder}
    />
  );
};
