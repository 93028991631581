import { FilterType, Translator } from '@fcg-tech/regtech-types/regeye';

export enum MessageKeys {
  AccessErrorAddAssignee = 'access-error-add-assignee',
  AccessErrorAddTeamTag = 'access-error-add-team-tag',
  AccessErrorChangeAttachments = 'access-error-change-attachments',
  AccessErrorPersonalFilterList = 'access-error-personal-filter-list',
  AccessErrorPersonalTeamList = 'access-error-personal-team-list',
  AccessErrorSetDueDate = 'access-error-set-due-date',
  AccessErrorTeamFilterList = 'access-error-team-filter-list',
  AccessErrorTeamMemberList = 'access-error-team-members-list',
  AccessErrorTeamSubscriptionList = 'access-error-team-subscription-list',
  AccessErrorTeamTagList = 'access-error-team-tag-list',

  ActionsArticlesCompanyActionsHeading = 'actions-articles-company-actions-heading',

  ActivityLogArticlesCompanyHeading = 'activity-log-articles-company-heading',

  AdministrationCompanyCreateHeading = 'administration-company-create-heading',
  AdministrationCompanyDeleteButtonLabel = 'administration-company-delete-button-label',
  AdministrationCompanyDeleteConfirm = 'administration-company-delete-confirm',
  AdministrationCompanyDeleteFailure = 'administration-company-delete-failure',
  AdministrationCompanyDeleteSuccess = 'administration-company-delete-success',
  AdministrationCompanyEditHeading = 'administration-company-edit-heading',
  AdministrationCompanyNameConflictErrorMessage = 'administration-company-name-conflict-error-message',
  AdministrationCompanyStringIdConflictErrorMessage = 'administration-company-string-id-conflict-error-message',
  AdministrationCompanyHeading = 'administration-company-heading',
  AdministrationFiltersDeleteFilterFailureLabel = 'administration-filters-delete-filter-failure-label',
  AdministrationFiltersDeleteFilterSuccessLabel = 'administration-filters-delete-filter-success-label',
  AdministrationTabBarTeamDetailsLabel = 'administration-tab-team-details-label',
  AdministrationTabBarTeamFiltersLabel = 'administration-tab-team-filters-label',
  AdministrationTabBarTeamMembersLabel = 'administration-tab-team-members-label',
  AdministrationTabBarTeamsLabel = 'administration-tab-teams-label',
  AdministrationTabBarTeamSubscriptionsLabel = 'administration-tab-team-subscriptions-label',
  AdministrationTabBarTeamTagsLabel = 'administration-tab-team-tags-label',
  AdministrationTeamCreateFailure = 'administration-team-create-failure',
  AdministrationTeamCreateSuccess = 'administration-team-create-success',
  AdministrationTeamDetails = 'administration-team-details',
  AdministrationTeamDetailsMembers = 'administration-team-details-members',
  AdministrationTeamDetailsAddMeToThisTeam = 'administration-team-details-add-me-to-this-team',
  AdministrationTeamDetailsCreateTagSuccessLabel = 'administration-team-details-create-tag-success-label',
  AdministrationTeamDetailsDangerZoneLabel = 'administration-team-details-danger-zone-label',
  AdministrationTeamDetailsDeleteTeamConfirmLabel = 'administration-team-details-delete-team-confirm-label',
  AdministrationTeamDetailsDeleteTeamFailureLabel = 'administration-team-details-delete-team-failure-label',
  AdministrationTeamDetailsDeleteTeamLabel = 'administration-team-details-delete-team-label',
  AdministrationTeamDetailsDeleteTeamSuccessLabel = 'administration-team-details-delete-team-success-label',
  AdministrationTeamDetailsRemoveTagSuccessLabel = 'administration-team-details-remove-tag-success-label',
  AdministrationTeamDetailsTagActiveLabel = 'administration-team-details-tag-active-label',
  AdministrationTeamDetailsTagConfirmLabel = 'administration-team-details-tag-confirm-label',
  AdministrationTeamDetailsTagCreateLabel = 'administration-team-details-tag-create-label',
  AdministrationTeamDetailsTagEditLabel = 'administration-team-details-tag-edit-label',
  AdministrationTeamDetailsTeamName = 'administration-team-details-team-name',
  AdministrationTeamDetailsUpdateTagSuccessLabel = 'administration-team-details-update-tag-success-label',
  AdministrationTeamEditFailure = 'administration-team-edit-failure',
  AdministrationTeamEditSuccess = 'administration-team-edit-success',
  AdministrationTeamDeleteFailure = 'administration-team-delete-failure',
  AdministrationTeamDeleteSuccess = 'administration-team-delete-success',
  AdministrationTeamFiltersHowToCreateFilters = 'administration-team-filters-how-to-create-filters',
  AdministrationTeamHeading = 'administration-team-heading',
  AdministrationTeamMembersEmptyMessage = 'administration-team-members-empty-message',
  AdministrationTeamMembersHeading = 'administration-team-members-heading',
  AdministrationTeamMembersInvitationMessage = 'administration-team-members-invitation-message',
  AdministrationTeamMembersInvitationsHeading = 'administration-team-members-invitations-heading',
  AdministrationTeamMembersJoinTeamButtonLabel = 'administration-team-members-join-team-button-label',
  AdministrationTeamMembersJoinTeamSuccessMessage = 'administration-team-members-join-team-success-message',
  AdministrationTeamMembersLeaveTeamButtonLabel = 'administration-team-members-leave-team-button-label',
  AdministrationTeamMembersLeaveTeamConfirmLabel = 'administration-team-members-leave-team-confirm-label',
  AdministrationTeamMembersLeaveTeamSuccessMessage = 'administration-team-members-leave-team-success-message',
  AdministrationTeamSubscriptionsHeading = 'administration-team-subscriptions-heading',
  AdministrationTeamSubscriptionsInactiveArticleTypeWarning = 'administration-team-subscriptions-inactive-article-type-warning',
  AdministrationTeamTabCreateNewTeamLabel = 'administration-team-tab-create-new-team-label',
  AdministrationTeamTabHeading = 'administration-team-tab-heading',
  AdministrationTeamTabNoTeamsMessage = 'administration-team-no-teams-message',

  AllArticleTypes = 'all-article-types',

  ArticleArchivedForTeamButtonTooltip = 'article-archive-for-team-button-tooltip',
  ArticleArchivedForTeamButtonTooltipActionBar = 'article-archive-for-team-button-tooltip-action-bar',
  ArticleUnArchivedForTeamButtonTooltip = 'article-unarchive-for-team-button-tooltip',
  ArticleUnArchivedForTeamButtonTooltipActionBar = 'article-unarchive-for-team-button-tooltip-action-bar',
  ArticleArchivedForTeamButtonNotAllowedTooltip = 'article-archive-for-team-button-not-allowed-tooltip',
  ArticleArchiveForMultipleTeamsButtonTooltip = 'article-archive-for-multiple-teams-button-tooltip',
  ArticleArchiveSettingsModalPreamble = 'article-archive-settings-modal-preamble',
  ArticleArchiveSettingsModalTitle = 'article-archive-settings-modal-title',
  ArticleAssignedToMeFeed = 'article-bookmarked-assigned-to-me-feed',
  ArticleBookmarkButtonTooltipIsBookmarked = 'article-bookmark-button-tooltip-is-bookmarked',
  ArticleBookmarkButtonTooltipIsBookmarkedActionBar = 'article-bookmark-button-tooltip-is-bookmarked-action-bar',
  ArticleBookmarkButtonTooltipIsNotBookmarked = 'article-bookmark-button-tooltip-is-not-bookmarked',
  ArticleBookmarkButtonTooltipIsNotBookmarkedActionBar = 'article-bookmark-button-tooltip-is-not-bookmarked-action-bar',
  ArticleBookmarkedFeed = 'article-bookmarked-feed',
  ArticleCommentedByMeFeed = 'article-bookmarked-commented-by-me-feed',
  ArticleFeed = 'article-feed',
  ArticleFeedNoItems = 'article-feed-no-items',
  ArticleFeedTableArchive = 'article-feed-table-archive',
  ArticleFeedTableAssignedTo = 'article-feed-table-assigned-to',
  ArticleFeedTableArticleType = 'article-feed-table-article-type',
  ArticleFeedTableBookmark = 'article-feed-table-bookmark',
  ArticleFeedTableDate = 'article-feed-table-date',
  ArticleFeedTableDescription = 'article-feed-table-description',
  ArticleFeedTableDueDate = 'article-feed-table-due-date',
  ArticleFeedTableImportance = 'article-feed-table-importance',
  ArticleFeedTableNoContent = 'article-feed-table-no-content',
  ArticleFeedTablePublisher = 'article-feed-table-publisher',
  ArticleFeedTableTeam = 'article-feed-table-teams',
  ArticleFeedTableRead = 'article-feed-table-read',
  ArticleFeedTableTags = 'article-feed-table-tags',
  ArticleFeedTableTeamTags = 'article-feed-table-team-tags',
  ArticleFeedTableTitle = 'article-feed-table-title',
  ArticleImportanceImportant = 'article-importance-important',
  ArticleImportanceModerately = 'article-importance-moderately',
  ArticleImportanceSlightly = 'article-importance-slightly',
  ArticleImportanceUnimportant = 'article-importance-unimportant',
  ArticleImportanceUnspecified = 'article-importance-unspecified',
  ArticleImportanceVeryImportant = 'article-importance-very-important',
  ArticleMarkAsReadButtonTooltipIsNotRead = 'article-mark-as-read-button-tooltip-is-not-read',
  ArticleMarkAsReadButtonTooltipIsNotReadActionBar = 'article-mark-as-read-button-tooltip-is-not-read-action-bar',
  ArticleMarkAsReadButtonTooltipIsRead = 'article-mark-as-read-button-tooltip-is-read',
  ArticleMarkAsReadButtonTooltipIsReadActionBar = 'article-mark-as-read-button-tooltip-is-read-action-bar',
  ArticleMetadataActivitiesLabel = 'article-metadata-activities-label',
  ArticleMetadataAssigned = 'article-metadata-assigned',
  ArticleMetadataAssignReadByUser = 'article-metadata-read-by-user',
  ArticleMetadataAssignSelectPlaceholder = 'article-metadata-assign-select-placeholder',
  ArticleMetadataAssignUnreadByUser = 'article-metadata-unread-by-user',
  ArticleMetadataAttachments = 'article-metadata-attachments',
  ArticleMetadataAttachmentsEmpty = 'article-metadata-attachments-empty',
  ArticleMetadataConfirmRemoveAttachment = 'article-metadata-confirm-remove-attachment',
  ArticleMetadataCreateTeamTagConflict = 'article-metadata-create-team-tag-conflict',
  ArticleMetadataDueDate = 'article-metadata-due-date',
  ArticleMetadataImportance = 'article-metadata-importance',
  ArticleMetadataNrOfActivities = 'article-metadata-nr-of-activities',
  ArticleMetadataNrOfComments = 'article-metadata-nr-of-comments',
  ArticleMetadataRemoveAssignee = 'article-metadata-remove-assignee',
  ArticleMetadataStatus = 'article-metadata-status',
  ArticleMetadataStatusClosed = 'article-metadata-status-closed',
  ArticleMetadataStatusOpen = 'article-metadata-status-open',
  ArticleMetadataTeamTags = 'article-metadata-team-tags',
  ArticleMetadataTeamTagsAddToFilter = 'article-metadata-team-tags-add-to-filter',
  ArticleMetadataTeamTagsClickWillNavigate = 'article-metadata-team-tags-not-current-team-warning',
  ArticleMetadataNotifyButtonTooltip = 'article-metadata-notify-button-tooltip',
  ArticleMetadataAlreadyNotififedButtonTooltip = 'article-metadata-already-notififed-button-tooltip',
  ArticleMetadataNotificationTimeout = 'article-metadata-notification-timeout',
  ArticleMetadataNotifySuccessMessage = 'article-metadata-notify-success-message',
  ArticleMetadataNotifyConfirmTitle = 'article-metadata-notify-confirm-title',
  ArticleMetadataNotifyConfirmBody = 'article-metadata-notify-confirm-body',
  ArticleMetadataNotifyConfirmButtonLabel = 'article-metadata-notify-confirm-button-label',
  ArticleMetadataCompanyIsEmpty = 'article-metadata-company-is-empty',
  ArticlePermalinkCopiedMessage = 'article-permalink-copied-message',
  ArticlePermalinkTooltip = 'article-permalink-tooltip',
  ArticlesFromMyTeams = 'articles-from-my-teams',
  ArticlesFromMyTeamsShort = 'articles-from-my-teams-short',
  ArticleTrendingFeed = 'article-trending-feed',
  ArticleTypes = 'article-types',
  ArticleViaPublisher = 'article-via-publisher',

  AttachmentUploadActiveMessage = 'attachment-upload-active-message',
  AttachmentUploadAddUrlButtonLabel = 'attachment-upload-add-url-button-label',
  AttachmentUploadAddUrlTextAreaLabel = 'attachment-upload-add-url-text-area-label',
  AttachmentUploadDragFilesHintLabel = 'attachment-upload-drag-files-hint-label',
  AttachmentUploadHoverMessage = 'attachment-upload-hover-message',
  AttachmentUploadSelectFilesButtonLabel = 'attachment-upload-select-files-button-label',
  AttachmentUploadUploadErrorMessage = 'attachment-upload-error-message',
  AttachmentUploadUploadInProgressMessage = 'attachment-upload-in-progress-message',

  CommentEditingYourCommentFrom = 'comment-editing-your-comment-from',
  CommentFieldCancelReplyLabel = 'comment-field-cancel-reply-label',
  CommentFieldCancelEditLabel = 'comment-field-cancel-edit-label',
  CommentFieldSubmitLabel = 'comment-field-submit-label',
  CommentFieldPlaceholderLabel = 'comment-field-placeholder-label',
  CommentFieldSubmitReplyLabel = 'comment-field-submit-reply-label',
  CommentHintMentions = 'comment-hint-mentions',
  CommentWasDeleted = 'comment-was-deleted',
  CommentFieldNavigationWarning = 'comment-field-navigation-warning',
  CommentFieldDraftRestored = 'comment-field-draft-restored',
  CommentFieldDraftFromDateRestored = 'comment-field-draft-from-date-restored',
  CommentFieldDeleteDraft = 'comment-field-draft-delete-draft',

  CustomFeedEditPostFormArticleTags = 'custom-feed-edit-post-form-article-tags',
  CustomFeedEditPostFormArticleType = 'custom-feed-edit-post-form-article-type',
  CustomFeedEditPostFormAttachments = 'custom-feed-edit-post-form-attachments',
  CustomFeedEditPostFormModalHeading = 'custom-feed-edit-post-form-modal-heading',
  CustomFeedEditPostFormReference = 'custom-feed-edit-post-form-reference',
  CustomFeedEditPostFormSelectOrCreateTypeOptionText = 'custom-feed-edit-post-form-select-or-create-type',
  CustomFeedEditPostFormSelectOrCreateTypePlaceholder = 'custom-feed-edit-post-form-select-or-create-type',
  CustomFeedEditPostFormTypeToSelectOrCreateTypePlaceholder = 'custom-feed-edit-post-form-select-or-create-type',
  CustomFeedEditPostFormUploadFile = 'custom-feed-edit-post-form-upload-file',
  CustomFeedEditPostFormUploadLink = 'custom-feed-edit-post-form-upload-link',
  CustomFeedEditPostFormUploadLinkPlaceholder = 'custom-feed-edit-post-form-upload-link-placeholder',
  CustomFeedEditPostFormUploadSelectFile = 'custom-feed-edit-post-form-select-file',
  CustomFeedNewPostFormModalHeading = 'custom-feed-new-post-form-modal-heading',
  CustomFeedPageRemoveFilesProgressLabel = 'custom-feed-page-remove-files-progress-label',
  CustomFeedPageSaveCompletedLabel = 'custom-feed-page-save-completed-label',
  CustomFeedPageUploadFilesProgressLabel = 'custom-feed-page-upload-files-progress-label',
  CustomFeedsEditFormDescriptionLabel = 'custom-feeds-edit-form-description-label',
  CustomFeedsEditFormFeedPrivacyLabel = 'custom-feeds-edit-form-feed-privacy-label',
  CustomFeedsEditFormModalCreateHeading = 'custom-feeds-edit-form-modal-create-heading',
  CustomFeedsEditFormModalEditHeading = 'custom-feeds-edit-form-modal-edit-heading',
  CustomFeedsEditFormTitleLabel = 'custom-feeds-edit-form-title-label',
  CustomFeedsListCreateNew = 'custom-feeds-list-create-new',
  CustomFeedsListFeedCreateFailure = 'custom-feeds-list-feed-create-failure',
  CustomFeedsListFeedCreateNameConflict = 'custom-feeds-list-create-name-conflict',
  CustomFeedsListFeedCreateSuccess = 'custom-feeds-list-feed-create-success',
  CustomFeedsListHeading = 'custom-feeds-list-heading',

  CustomPostsAddNewPost = 'custom-posts-add-new-post',
  CustomPostsTableCreatedByLabel = 'custom-posts-table-created-by-label',
  CustomPostsTableCreatedLabel = 'custom-posts-table-created-label',
  CustomPostsTableLastModifiedByLabel = 'custom-posts-table-lastmodified-by-label',
  CustomPostsTableLastModifiedLabel = 'custom-posts-table-lastmodified-label',
  CustomPostsTableNoPostsFound = 'custom-posts-table-no-posts-found',
  CustomPostsTableTitleLabel = 'custom-posts-table-title-label',

  DefaultPlaceholder = 'default-placeholder',

  DashboardNotificationCount = 'dashboard-notification-count',
  DashboardBookmarkCount = 'dashboard-bookmark-count',
  DashboardOverdueCount = 'dashboard-overdue-count',
  DashboardAssignedCount = 'dashboard-assigned-count',
  DashboardAndCountMoreArticles = 'dashboard-and-count-more-articles',
  DashboardAndCountMoreNotifications = 'dashboard-and-count-more-notifications',
  DashboardViewAllNotifications = 'dashboard-view-all-notifications',
  DashboardViewAllBookmarks = 'dashboard-view-all-bookmarks',
  DashboardViewAllOverdue = 'dashboard-view-all-overdue',
  DashboardViewAllAssigned = 'dashboard-view-all-assigned',
  DashboardViewAllArticles = 'dashboard-view-all-articles',
  DashboardAllCaughtUp = 'dashboard-all-caught-up',
  DashboardNoOverdue = 'dashboard-no-overdue',
  DashboardNoAssigned = 'dashboard-no-assigned',
  DashboardNoBookmarks = 'dashboard-no-bookmarks',

  DocumentationLinkTooltip = 'documentation-link-tooltip',

  ErrorAuthorizationRequired = 'error-authorization-required',
  ErrorInvalidEmail = 'error-invalid-email',
  ErrorInvalidUrl = 'error-invalid-url',
  ErrorNotFoundHeading = 'error-not-found-heading',
  ErrorNotFoundInfo = 'error-not-found-info',
  ErrorTimeoutHeading = 'error-timeout-heading',
  ErrorTimeoutInfo = 'error-timeout-info',
  ErrorReportToSupportMessage = 'error-report-to-support-message',
  ErrorServerError = 'error-server-error',
  ErrorGeneric = 'error-generic',

  ExportLimitExceeded = 'export-limit-exceeded',

  FallbackNoCompaniesHasTeamsMessage = 'fallback-no-companies-has-teams-message',
  FallbackNoCompaniesHasTeamsMessageForAdmin = 'fallback-no-companies-has-teams-message-for-admin',
  FallbackNoCompaniesMessage = 'fallback-no-companies-message',
  FallbackNoTeamsMessage = 'fallback-no-teams-message',

  FeedArticleFilterAdvancedKeywordDialogHeading = 'feed-article-filter-advanced-search-dialog-heading',
  FeedArticleFilterAdvancedKeywordSearchAddAnotherClause = 'feed-article-filter-advanced-search-add-another-clause',
  FeedArticleFilterAdvancedKeywordSearchFinalString = 'feed-article-filter-advanced-search-add-final-string',
  FeedArticleFilterAdvancedKeywordSearchMustInclude = 'feed-article-filter-advanced-search-must-include',
  FeedArticleFilterAdvancedKeywordSearchOrLabel = 'feed-article-filter-advanced-search-or-label',
  FeedArticleFilterAdvancedKeywordSearchTip = 'feed-article-filter-advanced-search-tip',
  FeedArticleFilterAdvancedKeywordSearchWhitespaceSeparation = 'feed-article-filter-advanced-search-whitespace-separation',
  FeedArticleFilterAllUsers = 'feed-article-filter-all-users',
  FeedArticleFilterArticleTagsFilter = 'feed-article-filter-article-tags-filter',
  FeedArticleFilterArticleTypeFilter = 'feed-article-filter-article-type-filter',
  FeedArticleFilterArticleTypeNoItems = 'feed-article-filter-article-type-no-items',
  FeedArticleFilterArchiveStatusFilter = 'feed-article-filter-archive-status-filter',
  FeedArticleFilterAssigneeFilter = 'feed-article-filter-assignee-filter',
  FeedArticleFilterAssigneeNoAssignees = 'feed-article-filter-assignee-no-assignes',
  FeedArticleFilterAttachedByFilter = 'feed-article-filter-attached-by-filter',
  FeedArticleFilterAttachedByNoAttachments = 'feed-article-filter-attached-by-no-attachments',
  FeedArticleFilterCommentedByFilter = 'feed-article-filter-commented-by-filter',
  FeedArticleFilterCommentedNoCommenters = 'feed-article-filter-commented-no-commenters',
  FeedArticleFilterDateIntervalFrom = 'feed-article-filter-date-interval-from',
  FeedArticleFilterDateIntervalTo = 'feed-article-filter-date-interval-to',
  FeedArticleFilterDueDateFilter = 'feed-article-filter-duedate-filter',
  FeedArticleFilterFilterSaveFailure = 'feed-article-filter-save-failure',
  FeedArticleFilterFilterSaveSuccess = 'feed-article-filter-save-success',
  FeedArticleFilterHeading = 'feed-article-filter-heading',
  FeedArticleFilterImportanceFilter = 'feed-article-filter-importance-filter',
  FeedArticleFilterIncludeClosedFilter = 'feed-article-filter-include-closed-filter',
  FeedArticleFilterIncludeCompletedFilter = 'feed-article-filter-include-completed-filter',
  FeedArticleFilterLoadFilterLabel = 'feed-article-load-filter-label',
  FeedArticleFilterNoSearchResultsMessage = 'feed-article-filter-no-search-results-message',
  FeedArticleFilterNoUsers = 'feed-article-filter-no-users',
  FeedArticleFilterPerformedDateFilter = 'feed-article-filter-performed-filter',
  FeedArticleFilterPersonalFilterTooltipLabel = 'feed-article-filter-personal-filter-tooltip-label',
  FeedArticleFilterPinFilterButtonLabel = 'feed-article-filter-pin-filter-button-label',
  FeedArticleFilterPublicationDateFilter = 'feed-article-filter-publication-date-filter',
  FeedArticleFilterPublisherFilter = 'feed-article-filter-publisher-filter',
  FeedArticleFilterPublisherNoItems = 'feed-article-filter-publisher-no-items',
  FeedArticleFilterRegionFilter = 'feed-article-filter-region-filter',
  FeedArticleFilterRegionNoItems = 'feed-article-filter-region-no-items',
  FeedArticleFilterSavedUpdatedFilterButtonLabel = 'feed-article-save-updated-filter-button-label',
  FeedArticleFilterSaveFilterButtonLabel = 'feed-article-save-filter-button-label',
  FeedArticleFilterSaveFilterFilterNameLabel = 'feed-article-save-filter-filter-name-label',
  FeedArticleFilterSaveFilterTeamFilterLabel = 'feed-article-save-filter-team-filter-label',
  FeedArticleFilterSearchInProgressMessage = 'feed-article-filter-search-in-progress-message',
  FeedArticleFilterShowAdvancedKeywordSearchButtonLabel = 'feed-article-filter-show-advanced-search-button-label',
  FeedArticleFilterSomePropertiesOverwrittenWarning = 'feed-article-filter-some-properties-overwritten-warning',
  FeedArticleFilterSortOrderSelectorHeading = 'feed-article-filter-sort-order-selector-hiding',
  FeedArticleFilterSubmitLabel = 'feed-article-filter-submit-label',
  FeedArticleFilterSummaryLabel = 'feed-article-filter-summary-label',
  FeedArticleFilterTagsNoTags = 'feed-article-filter-tags-no-tags',
  FeedArticleFilterTeamActivityDateFilter = 'feed-article-filter-team-activity-date-filter',
  FeedArticleFilterTeamActivityEventFilter = 'feed-article-filter-team-activity-event-filter',
  FeedArticleFilterTeamActivityPerformedByFilter = 'feed-article-filter-team-activity-performed-by-filter',
  FeedArticleFilterTeamFilterTooltipLabel = 'feed-article-filter-team-filter-tooltip-label',
  FeedArticleFilterTeamTagsFilter = 'feed-article-filter-team-tags-filter',
  FeedArticleFilterTeamFilter = 'feed-article-filter-team-filter',
  FeedArticleFilterTeamTagsIncludeInactive = 'feed-article-filter-team-include-inactive',
  FeedArticleFilterTooManySearchResultsMessage = 'feed-article-filter-too-many-search-results-message',
  FeedArticleFilterTypeMoreToSearchMessage = 'feed-article-filter-type-more-to-search-message',
  FeedArticleFilterUnpinFilterButtonLabel = 'feed-article-filter-unpin-filter-button-label',
  FeedArticleFilterUnreadOnlyFilter = 'feed-article-filter-unread-only-filter',
  FeedArticleFilterDraftRestoredNotification = 'feed-article-filter-draft-restored-notification',
  FeedArticleFilterDraftRestoredChangeThisBehaviour = 'feed-article-filter-draft-restored-change-this-behaviour',
  FeedArticleFilterToggleUnreadOnlyTooltip = 'feed-article-filter-toggle-unread-only-tooltip',
  FeedArticleFilterToggleAllReadStatusTooltip = 'feed-article-filter-toggle-all-read-status-tooltip',
  FeedArticleFilterToggleUnarchivedOnlyTooltip = 'feed-article-filter-toggle-unarchived-only-tooltip',
  FeedArticleFilterToggleAllArchivedStatusTooltip = 'feed-article-filter-toggle-all-archived-status-tooltip',
  FeedArticleInfoArticleTypes = 'feed-article-info-article-types',
  FeedArticleInfoSourceTags = 'feed-article-info-source-tags',
  FeedArticleModalNoArticleMessage = 'feed-articles-modal-no-article-message',
  FeedArticleOverlayBackToArticle = 'feed-article-overlay-back-to-article',
  FeedArticleSearchResultsHeading = 'feed-article-search-results-heading',
  FeedArticleSearchResultsNoSearchTermHeading = 'feed-article-search-results-no-search-term-heading',
  FeedArticleSearchResultSummary = 'feed-article-search-result-summary',
  FeedArticleTeamMetadataNotSubscribed = 'feed-article-team-meta-data-not-subscribed',

  FilterListTableNoContent = 'filter-list-table-no-content',
  FilterTypeActionsFilter = 'filter-type-actions-filter',
  FilterTypeArticleFilter = 'filter-type-article-filter',

  LabelAccept = 'label-accept',
  LabelAlias = 'label-alias',
  LabelAllTeams = 'label-all-teams',
  LabelArchived = 'label-archived',
  LabelNotArvchived = 'label-not-archived',
  LabelAll = 'label-all',
  LabelAscending = 'label-ascending',
  LabelCancel = 'label-cancel',
  LabelChangesMadeNavigationWarning = 'label-changes-made-navigation-warning',
  LabelChangesSaved = 'label-changes-saved',
  LabelClear = 'label-clear',
  LabelClose = 'label-closing',
  LabelCompany = 'label-company',
  LabelCompanyName = 'label-company-name',
  LabelCompanyStringId = 'label-company-string-id',
  LabelConfirmAction = 'label-confirm-action',
  LabelConfirmLabel = 'label-confirm-label',
  LabelCouldNotSave = 'label-could-not-save',
  LabelDelete = 'label-delete',
  LabelDangerZone = 'label-danger-zone',
  LabelDeleteFilterConfirmation = 'label-delete-filter-confirmation',
  LabelDescending = 'label-descending',
  LabelDescription = 'label-description',
  LabelDone = 'label-done',
  LabelDoNotShowThisAgain = 'label-do-not-show-this-again',
  LabelEdit = 'label-edit',
  LabelEmail = 'label-email',
  LabelEmailMaybePlural = 'label-email-maybe-plural',
  LabelExclude = 'label-exclude',
  LabelExport = 'label-export',
  LabelFilename = 'label-filename',
  LabelFilterName = 'label-filter-name',
  LabelFilterType = 'label-filter-type',
  LabelHide = 'label-hide',
  LabelInfo = 'label-info',
  LabelLogicalAnd = 'label-logical-and',
  LabelLogicalOr = 'label-logical-or',
  LabelLogIn = 'label-login',
  LabelLogOut = 'label-logout',
  LabelMarkAsDone = 'label-mark-as-done',
  LabelMarkAsComplete = 'label-mark-as-complete',
  LabelMarkedAsComplete = 'label-marked-as-complete',
  LabelMarkAsNotCompleted = 'label-mark-as-not-completed',
  LabelNotCompleted = 'label-not-completed',
  LabelMessage = 'label-message',
  LabelNext = 'label-next',
  LabelNoFiltersFound = 'label-no-filters-found',
  LabelNoTagsFound = 'label-no-tags-found',
  LabelNoTeamsFound = 'label-no-teams-found',
  LabelNothingFound = 'label-nothing-found',
  LabelPrevious = 'label-previous',
  LabelReject = 'label-reject',
  LabelRemoveTeamTag = 'label-remove-team-tag',
  LabelReply = 'label-reply',
  LabelReset = 'label-reset',
  LabelReplyingTo = 'label-replying-to',
  LabelSaveChanges = 'label-save-changes',
  LabelSearch = 'label-search',
  LabelShow = 'label-show',
  LabelSubmit = 'label-submit',
  LabelTagDescription = 'label-tag-description',
  LabelTagName = 'label-tag-name',
  LabelTeam = 'label-team',
  LabelTeamName = 'label-team-name',
  LabelTitle = 'label-title',
  LabelSelectAll = 'label-select-all',
  LabelUsername = 'label-username',
  LabelWarning = 'label-warning',

  MyProfileTabMySettingsTableColumnsReset = 'my-profile-tab-my-settings-table-columns-reset',
  MyProfileTabMySettingsDraftsReset = 'my-profile-tab-my-settings-drafts-reset',
  MyProfileTabMySettingsActionsColumns = 'my-profile-tab-my-settings-actions-columns',
  MyProfileTabMySettingsActionsLogColumns = 'my-profile-tab-my-settings-actions-log-columns',
  MyProfileTabMySettingsAdvancedSettings = 'my-profile-tab-my-settings-advanced-settings',
  MyProfileTabMySettingsAlias = 'my-profile-tab-my-settings-alias',
  MyProfileTabMySettingsDeviceSettings = 'my-profile-tab-my-settings-device-settings',
  MyProfileTabMySettingsFilterSettings = 'my-profile-tab-my-settings-filter-settings',
  MyProfileTabMySettingsUserInterfaceNotificationSettings = 'my-profile-tab-my-settings-user-interface-notification-settings',
  MyProfileTabMySettingsDisplaySettings = 'my-profile-tab-my-settings-display-settings',
  MyProfileTabMySettingsColumnSettings = 'my-profile-tab-my-settings-column-settings',
  MyProfileTabMySettingsFeedArticleColumns = 'my-profile-tab-my-settings-feed-article-columns',
  MyProfileTabMySettingsFilters = 'my-profile-tab-my-settings-filter',
  MyProfileTabMySettingsInvitationsHeading = 'my-profile-tab-my-settings-invitations-heading',
  MyProfileTabMySettingsLabel = 'my-profile-tab-my-settings-label',
  MyProfileTabMySettingsMarkAsRead = 'my-profile-tab-my-settings-mark-as-read',
  MyProfileTabMySettingsMarkAsReadAfterSeconds = 'my-profile-tab-my-settings-mark-as-read-input-placeholder',
  MyProfileTabMySettingsNamePreference = 'my-profile-tab-my-settings-name-order',
  MyProfileTabMySettingsNamePreferenceAlias = 'my-profile-tab-my-settings-name-preference-alias',
  MyProfileTabMySettingsNamePreferenceEmail = 'my-profile-tab-my-settings-name-preference-email',
  MyProfileTabMySettingsNotifications = 'my-profile-tab-notifications-settings-label',
  MyProfileTabMySettingsNotificationsEnableEmails = 'my-profile-tab-my-settings-notifications-enable-emails',
  MyProfileTabMySettingsNotificationsSelectTypes = 'my-profile-tab-my-settings-notifications-select-types',
  MyProfileTabMySettingsTableColumnsResetButtonLabel = 'my-profile-tab-my-settings-table-columns-reset-button-label',
  MyProfileTabMySettingsDraftsResetButtonLabel = 'my-profile-tab-my-settings-drafts-reset-button-label',
  MyProfileTabMySettingsCompaniesAndTeamsHeading = 'my-profile-tab-my-settings-companies-and-teams-heading',
  MyProfileTabMySettingsWidthsResetMessage = 'my-profile-tab-my-settings-widths-reset-message',
  MyProfileTabMySettingsDraftsResetMessage = 'my-profile-tab-my-settings-drafts-reset-message',
  MyProfileTabMySettingsRestoreFilterDraftsMessage = 'my-profile-tab-my-settings-restore-filter-drafts-message',
  MyProfileTabMySettingsHideRestoreFilterDraftsMessage = 'my-profile-tab-my-settings-hide-restore-filter-drafts-message',
  MyProfileTabMySettingsDoNotShowConfirmTeamArticleNotificationsMessage = 'my-profile-tab-my-settings-do-not-show-confirm-team-article-notifications-message',
  MyProfileTabMySettingsShowcaseNewUser = 'my-profile-tab-my-settings-showcase-new-user',
  MyProfileTabMySettingsShowcaseGenericFeedArticleTable = 'my-profile-tab-my-settings-showcase-generic-feed-article-table',
  MyProfileTabMySettingsShowcaseTeamActions = 'my-profile-tab-my-settings-showcase-team-actions',
  MyProfileTabMySettingsShowcaseNewDataTable = 'my-profile-tab-my-settings-showcase-new-data-table',
  MyProfileTabMySettingsShowcaseNewTabs = 'my-profile-tab-my-settings-showcase-new-tabs',
  MyProfileTabMySettingsShowcaseMarkAsRead = 'my-profile-tab-my-settings-showcase-mark-as-read',
  MyProfileTabMySettingsShowcaseWhatsNewAugust2022 = 'my-profile-tab-my-settings-showcase-whats-new-august-2022',
  MyProfileTabMySettingsResetAll = 'my-profile-tab-my-settings-reset-all',
  MyProfileTabMySettingsResetAllConfirmMessage = 'my-profile-tab-my-settings-reset-all-confirm-message',
  MyProfileTabMyTeamsCompanyTeamsAndInvitations = 'my-profile-tab-my-teams-company-teams-and-invitations',
  MyProfileTabMyTeamsHideTeamTooltip = 'my-profile-tab-my-teams-hide-team-tooltip',
  MyProfileTabMyTeamsInvitation = 'my-profile-tab-my-teams-invitation',
  MyProfileTabMyTeamsInvitationByWithMessage = 'my-profile-tab-my-teams-invitation-by-with-message',

  NotificationsEmptyMessage = 'notifications-empty-message',
  NotificationsLabel = 'notifications-label',
  NotificationsPageHeading = 'notifications-page-heading',
  NotificationsSettingsLabel = 'notifications-settings-label',
  NotificationsSettingsSavedFailure = 'notifications-settings-saved-failure',
  NotificationsSettingsSavedSuccess = 'notifications-settings-saved-success',
  NotificationsSettingsText = 'notifications-settings-text',
  NotificationsSettingsToggleTextOff = 'notifications-settings-toggle-text-off',
  NotificationsSettingsToggleTextOn = 'notifications-settings-toggle-text-on',
  NotificationsUnreadCountTooltip = 'notifications-unread-count-tooltip',

  NotificationTypeAssigned = 'notification-type-assigned',
  NotificationTypeCommentReply = 'notification-type-comment-reply',
  NotificationTypeCommentMention = 'notification-type-comment-mention',
  NotificationTypeTeamInvite = 'notification-type-team-invite',
  NotificationTypeTeamNewMember = 'notification-type-team-new-member',
  NotificationTypeTeamRemovedMember = 'notification-type-team-removed-member',
  NotificationTypeTeamNotify = 'notification-type-team-notify',
  NotificationTypeUnassigned = 'notification-type-unassigned',

  NrOfHiddenTeamsMessage = 'nr-of-hidden-teams-message',
  NrOfHiddenCompaniesMessage = 'nr-of-hidden-companies-message',
  AllTeamsHiddenMessage = 'all-teams-hidden-message',

  RelativeDateIntervalCustom = 'relative-date-interval-custom',
  RelativeDateIntervalLastMonth = 'relative-date-interval-lastmonth',
  RelativeDateIntervalLastQuarter = 'relative-date-interval-lastquarter',
  RelativeDateIntervalLastWeek = 'relative-date-interval-lastweek',
  RelativeDateIntervalLastYear = 'relative-date-interval-lastyear',
  RelativeDateIntervalNextMonth = 'relative-date-interval-nextmonth',
  RelativeDateIntervalNextWeek = 'relative-date-interval-nextweek',
  RelativeDateIntervalOverdue = 'relative-date-interval-overdue',
  RelativeDateIntervalThisMonth = 'relative-date-intervalthismonth',
  RelativeDateIntervalThisQuarter = 'relative-date-interval-thisquarter',
  RelativeDateIntervalThisWeek = 'relative-date-intervalthisweek',
  RelativeDateIntervalThisYear = 'relative-date-interval-thisyear',
  RelativeDateIntervalToday = 'relative-date-interval-today',

  RemoveInvitation = 'remove-invitation',
  RemoveInvitationConfirm = 'remove-invitation-confirm',

  SearchBarAdvancedSearchButtonTooltip = 'search-bar-advanced-search-button-tooltip',
  SearchBarHelpModalAdvancedSearchDescription = 'search-bar-help-modal-advanced-search-description',
  SearchBarHelpModalExample1Description = 'search-bar-help-modal-example-1-description',
  SearchBarHelpModalExample1Label = 'search-bar-help-modal-example-1-label',
  SearchBarHelpModalExampleTextLabel = 'search-bar-help-modal-example-text-label',
  SearchBarHelpModalFieldsDescription = 'search-bar-help-modal-fields-description',
  SearchBarHelpModalOrTextDescription = 'search-bar-help-modal-or-text-description',
  SearchBarHelpModalOrTextLabel = 'search-bar-help-modal-or-text-label',
  SearchBarHelpModalQuotedTextDescription = 'search-bar-help-modal-quoted-text-description',
  SearchBarHelpModalQuotedTextLabel = 'search-bar-help-modal-quoted-text-label',
  SearchBarHelpModalTitle = 'search-bar-help-modal-title',
  SearchBarHelpModalUnquotedTextDescription = 'search-bar-help-modal-unquoted-text-description',
  SearchBarHelpModalUnquotedTextLabel = 'search-bar-help-modal-unquoted-text-label',
  SearchBarSearchGloballyNoFilter = 'search-bar-search-globally-no-filter',
  SearchBarSearchGloballyWithFilter = 'search-bar-search-globally-with-filter',
  SearchBarSearchLocallyNoFilter = 'search-bar-search-locally-no-filter',
  SearchBarSearchLocallyWithFilter = 'search-bar-search-locally-with-filter',
  SearchBarTextFieldPlaceholder = 'search-bar-text-field-placeholder',

  SideMenuAdministration = 'side-menu-administration',
  SideMenuDashboard = 'side-menu-dashboard',
  SideMenuAssignedCountBadgeTooltip = 'side-menu-assigned-count-badge-tooltip',
  SideMenuInvitationsBadgeTooltip = 'side-menu-invitations-badge-tooltip',
  SideMenuMyArticlesEventLog = 'side-menu-my-articles-event-log',
  SideMenuOverdueBadgeTooltip = 'side-menu-overdue-badge-tooltip',
  SideMenuPersonal = 'side-menu-personal',
  SideMenuSourceOrigin = 'side-menu-source-origin',
  SideMenuTeamAbout = 'side-menu-team-about',
  SideMenuTeamActivitiesAssigned = 'side-menu-team-activities-assigned',
  SideMenuTeamActivitiesAttachment = 'side-menu-team-activities-attachment',
  SideMenuTeamActivitiesCommented = 'side-menu-team-activities-commented',
  SideMenuTeamActivitiesDueDate = 'side-menu-team-activities-duedates',
  SideMenuTeamActivitiesImportance = 'side-menu-team-activities-importance',
  SideMenuTeamActivitiesTags = 'side-menu-team-activities-tags',
  SideMenuTeamActivityInsights = 'side-menu-team-activity-insights',
  SideMenuTeamAdministration = 'side-menu-team-administration',
  SideMenuTeamArticlesEventLog = 'side-menu-team-articles-event-log',
  SideMenuTeamArticlesArchive = 'side-menu-team-articles-archive',
  SideMenuCompanyAdministration = 'side-menu-company-administration',
  SideMenuEmptyCompanyMessage = 'side-menu-empty-company-message',
  SideMenuEmptyCompanyMessageTooltip = 'side-menu-empty-company-message-tooltip',

  SubscribedArticlesByTeam = 'subscribed-articles-by-team',
  SubscribedArticles = 'subscribed-articles',

  TagListOverflowMessage = 'tag-list-overflow-message',
  TagListShowLessLabel = 'tag-list-show-less-label',
  TagListShowMoreLabel = 'tag-list-show-more-label',
  TagSelectorCreateOptionText = 'team-tag-typeahead-create-option-text',
  TagSelectorSelectOrCreateTagPlaceholder = 'team-tag-typeahead-select-or-create-tag-placeholder',
  TagSelectorSelectTagPlaceholder = 'team-tag-typeahead-select-tag-placeholder',
  TagSelectorTypeToSelectOrCreateTagPlaceholder = 'team-tag-typeahead-type-to-select-or-create-tag-placeholder',
  TagSelectorTypeToSelectTagPlaceholder = 'team-tag-typeahead-type-to-select-tag-placeholder',
  TagSelectorNoTagsFoundTypeToCreate = 'team-tag-typeahead-no-tags-found-type-to-create',
  TagTableItemNoDescription = 'tag-table-item-no-description',

  TeamActivityActionsHeading = `team-activity-actions-heading`,
  TeamActivityArticlesTableAction = 'team-activity-articles-table-action',
  TeamActivityArticlesTableDate = 'team-activity-articles-table-date',
  TeamActivityCalendarAgendaLabel = 'team-activity-calendar-agenda-label',
  TeamActivityCalendarCalendarLabel = 'team-activity-calendar-calendar-label',
  TeamActivityCalendarDueDateOnlyInfoMessage = 'team-activity-due-date-only-info-message',
  TeamActivityCalendarFeedArticleModalNoContentMessage = 'team-activity-calendar-feed-article-modal-no-content-message',
  TeamActivityCalendarNextMonthTooltip = 'team-activity-calendar-next-month-tooltip',
  TeamActivityCalendarPrevMonthTooltip = 'team-activity-calendar-prev-month-tooltip',
  TeamActivityCalendarNextArticleTooltip = 'team-activity-calendar-next-article-tooltip',
  TeamActivityCalendarPrevArticleTooltip = 'team-activity-calendar-prev-article-tooltip',
  TeamActivityCalendarTodayLabel = 'team-activity-calendar-today-label',
  TeamActivityDescriptionArchived = 'team-activity-description-archived',
  TeamActivityDescriptionAssigned = 'team-activity-description-assigned',
  TeamActivityDescriptionAttachmentAdded = 'team-activity-description-attachment-added',
  TeamActivityDescriptionAttachmentRemoved = 'team-activity-description-attachment-removed',
  TeamActivityDescriptionCommented = 'team-activity-description-commented',
  TeamActivityDescriptionRemovedComment = 'team-activity-description-removed-comment',
  TeamActivityDescriptionRemovedDueDate = 'team-activity-description-removed-due-date',
  TeamActivityDescriptionRemovedImportance = 'team-activity-description-removed-importance',
  TeamActivityDescriptionSetDueDate = 'team-activity-description-set-due-date',
  TeamActivityDescriptionSetImportance = 'team-activity-description-set-importance',
  TeamActivityDescriptionTagged = 'team-activity-description-tagged',
  TeamActivityDescriptionUnarchived = 'team-activity-description-unarchived',
  TeamActivityDescriptionUnAssigned = 'team-activity-description-un-assigned',
  TeamActivityDescriptionUntagged = 'team-activity-description-untagged',
  TeamActivityDescriptionUpdatedComment = 'team-activity-description-updated-comment',
  TeamActivityDescriptionUpdatedImportance = 'team-activity-description-updated-importance',
  TeamActivityDescriptionUpdateDueDate = 'team-activity-description-update-due-date',
  TeamActivityDescriptionCompletedAssigned = 'team-activity-description-completed-assigned',
  TeamActivityDescriptionUncompletedAssigned = 'team-activity-description-uncompleted-assigned',
  TeamActivityDescriptionNotified = 'team-activity-description-notified',
  TeamActivityEventArchived = 'team-activity-event-archived',
  TeamActivityEventNotified = 'team-activity-event-notified',
  TeamActivityEventCompleted = 'team-activity-event-completed-assigned',
  TeamActivityEventUncompleted = 'team-activity-event-uncompleted-assigned',
  TeamActivityEventAssigned = 'team-activity-event-assigned',
  TeamActivityEventAttached = 'team-activity-event-attached',
  TeamActivityEventAttachment = 'team-activity-event-attachment',
  TeamActivityEventCommented = 'team-activity-event-commented',
  TeamActivityEventDueDate = 'team-activity-event-due-date',
  TeamActivityEventEditComment = 'team-activity-event-edited-comment',
  TeamActivityEventImportance = 'team-activity-event-importance',
  TeamActivityEventRemoveComment = 'team-activity-event-removed-comment',
  TeamActivityEventRemovedDueDate = 'team-activity-event-removed-due-date',
  TeamActivityEventRemovedImportance = 'team-activity-event-removed-importance',
  TeamActivityEventSetDueDate = 'team-activity-event-set-due-date',
  TeamActivityEventSetImportance = 'team-activity-event-set-importance',
  TeamActivityEventTagged = 'team-activity-event-tagged',
  TeamActivityEventUnArchived = 'team-activity-event-unarchived',
  TeamActivityEventUnassigned = 'team-activity-event-unassigned',
  TeamActivityEventUnAttached = 'team-activity-event-unattached',
  TeamActivityEventUntagged = 'team-activity-event-untagged',
  TeamActivityEventUpdatedDueDate = 'team-activity-event-updated-due-date',
  TeamActivityEventUpdatedImportance = 'team-activity-event-updated-importance',
  TeamArticlesEventLogHeading = 'team-articles-event-log-heading',
  TeamInvitationAcceptedMessage = 'team-invitations-accepted-message',
  TeamInvitationRejectedMessage = 'team-invitations-rejected-message',
  TeamInvitationsSender = 'team-invitations-sender',
  TeamSettingsMembersHeading = 'team-settings-tab-members-heading',
  TeamSettingsSubscriptionsHeading = 'team-settings-subscriptions-heading',

  TeamSettingsTabFiltersLabel = 'team-settings-tab-filters-label',
  TeamSettingsCopyTeamFiltersLabel = 'team-settings-copy-team-filters-label',

  TeamSettingsTabMembersInviteMember = 'team-settings-tab-members-invite-member',
  TeamSettingsTabMembersInviteMemberSuccessful = 'team-settings-tab-members-invite-member-successful',
  TeamSettingsTabMembersInviteMemberPartiallySuccessful = 'team-settings-tab-members-invite-member-partial-successful',
  TeamSettingsTabMembersInviteMemberFailure = 'team-settings-tab-members-invite-member-failure',
  TeamSettingsTabMembersLabel = 'team-settings-tab-members-label',
  TeamSettingsTabMembersRemoveInvitationSuccessful = 'team-settings-tab-members-remove-invitation-successful',
  TeamSettingsTabMembersRemoveMemberConfirmLabel = 'team-settings-tab-members-remove-member-confirm-label',
  TeamSettingsTabMembersRemoveMemberSuccessful = 'team-settings-tab-members-remove-member-successful',
  TeamSettingsTabMembersTooltip = 'feed-article-filter-members-tooltip',
  TeamSettingsTabSubscriptionsAllArticleTypes = 'team-settings-tab-subscriptions-all-article-types',
  TeamSettingsTabSubscriptionsLabel = 'team-settings-tab-subscriptions-label',
  TeamSettingsTabSubscriptionsTooltip = 'feed-article-filter-subscriptions-tooltip',
  TeamSettingsTabSubscriptionsHeading = 'team-settings-tab-subscriptions-heading',
  TeamSettingsTabSubscriptionsDescription = 'team-settings-tab-subscriptions-description',
  TeamSettingsTabSubscriptionsCopyFromTeam = 'team-settings-tab-subscriptions-copy-from-team',
  TeamSettingsTabSubscriptionsNrOfArticleTypes = 'team-settings-tab-subscriptions-nr-of-article-types',
  TeamSettingsTabSubscriptionsNrOfSubscribedArticleTypes = 'team-settings-tab-subscriptions-nr-of-subscribed-article-types',
  TeamSettingsTabSubscriptionsMatchedRegions = 'team-settings-tab-subscriptions-matched-regions',
  TeamSettingsTabSubscriptionsMatchedPublishers = 'team-settings-tab-subscriptions-matched-publishers',
  TeamSettingsTabSubscriptionsMatchedArticleTypes = 'team-settings-tab-subscriptions-matched-article-types',
  TeamSettingsTabSubscriptionsSearchPublishersPlaceholder = 'team-settings-tab-subscriptions-search-publishers-placeholder',

  TeamSettingsTabTagsLabel = 'team-settings-tab-tags-label',
  TeamSettingsTabTagsNameClashErrorMesssage = 'team-settings-tab-tags-name-clash-error-message',
  TeamSettingsTabTagsTooltip = 'feed-article-filter-tags-tooltip',
  TeamSettingsCopySubscriptionsLabel = 'team-settings-copy-subscriptions-label',
  TeamSettingsCopyTeamTagsLabel = 'team-settings-copy-team-tags-label',
  TeamSettingsCopyTeamFilterLabel = 'team-settings-copy-team-filter-label',
  TeamSettingsCopyTeamFilterSubmitLabel = 'team-settings-copy-team-filter-submit-label',
  TeamSettingsCopyFromLabel = 'team-settings-copy-from-label',
  TeamSettingsSelectTeamTags = 'team-settings-select-team-tags',
  TeamSettingsSelectTeamFilters = 'team-settings-select-team-filters',
  TeamSettingsIncludeDescription = 'team-settings-include-description',
  TeamSettingsCopyTagsSuccessful = 'team-settings-copy-tags-successful',
  TeamSettingsCopyFiltersSuccessful = 'team-settings-copy-filters-successful',
  TeamSettingsCopyTagsPartiallySuccessful = 'team-settings-copy-tags-partial-successful',
  TeamSettingsCopyFiltersPartiallySuccessful = 'team-settings-copy-filters-partial-successful',
  TeamSettingsCopyTagsFailed = 'team-settings-copy-tags-failed',
  TeamSettingsCopyFiltersFailed = 'team-settings-copy-filters-failed',

  TeamMultiInvitationEnableMultiTip = 'team-multi-invitation-enable-multi-tip',
  TeamMultiInvitationSeparateWithNewlineTip = 'team-multi-invitation-separate-with-newline-tip',

  WizardAdminIntro = 'wizard-admin-intro',
  WizardCreateYourFirstTeam = 'wizard-create-your-first-team',
  WizardDoneButtonLabel = 'wizard-done',
  WizardDoneMessage = 'wizard-done-message',
  WizardDoneWithErrorsMessage = 'wizard-done-with-errors-message',
  WizardEmailValidationErrors = 'wizard-email-validation-errors',
  WizardFailedToCreateTeamMessage = 'wizard-failed-to-create-team-message',
  WizardInvitationsIntro = 'wizard-invitations-intro',
  WizardInviteEmailPlaceholder = 'wizard-invite-email-placeholder',
  WizardInviteMembers = 'wizard-invite-members',
  WizardLabelAllArticleType = 'wizard-label-all-article-types',
  WizardLabelCountArticleType = 'wizard-label-count-article-types',
  WizardLabelSubscriptions = 'wizard-label-subscriptions',
  WizardLabelTeamMembers = 'wizard-label-team-members',
  WizardLabelTeamName = 'wizard-label-team-name',
  WizardLabelYouWillBeAddedToThisTeam = 'wizard-label-you-will-be-added-to-this-team',
  WizardLetsGo = 'wizard-lets-go',
  WizardNoTeamsHasInvitesIntro = 'wizard-no-teams-has-invites',
  WizardNoTeamsIntro = 'wizard-no-teams',
  WizardProgressConfiguringSubscriptions = 'wizard-progress-configuring-subscriptions',
  WizardProgressCreatingTeam = 'wizard-progress-creating-team',
  WizardProgressInvitingMembers = 'wizard-progress-inviting-members',
  WizardSelectSubscriptions = 'wizard-select-subscriptions',
  WizardWelcomeToRegeye = 'wizard-welcome-to-regeye',
  WizardJoinExistingTeam = 'wizard-join-existing-team',
  WizardJoinExistingTeamInstead = 'wizard-join-existing-team-instead',
  WizardCreateNewTeam = 'wizard-create-new-team',
  WizardCreateNewTeamInstead = 'wizard-create-new-team-instead',
  WizardJoinTeamsButtonLabel = 'wizard-join-teams-button-label',
  WizardCreateTeamButtonLabel = 'wizard-create-team-button-label',
  WizardJoinTeamSuccessMessage = 'wizard-join-team-success-message',

  ShowCaseNewUserWelcomeToRegeyeHeading = 'show-case-new-user-welcome-to-regeye-heading',
  ShowCaseNewUserWelcomeToRegeyeBody = 'show-case-new-user-welcome-to-regeye-body',
  ShowCaseNewUserWelcomeToRegeyeFilterBody = 'show-case-new-user-welcome-to-regeye-filter-body',
  ShowCaseNewUserWelcomeToRegeyeColumnPickerBody = 'show-case-new-user-welcome-to-regeye-column-picker-body',
  ShowCaseNewUserWelcomeToRegeyeNotificationsBody = 'show-case-new-user-welcome-to-regeye-notifications-body',
  ShowCaseNewUserWelcomeToRegeyeDocumentationBody = 'show-case-new-user-welcome-to-regeye-documentation-body',

  ShowCaseTeamActionsWelcomeHeading = 'show-case-team-actions-welcome-heading',
  ShowCaseTeamActionsWelcomeBody = 'show-case-team-actions-welcome-body',
  ShowCaseTeamActionsCalendarBody = 'show-case-team-actions-calendar-body',
  ShowCaseTeamActionsExportBody = 'show-case-team-actions-export-body',

  ShowCaseNewDataTableWelcomeHeading = 'show-case-new-data-table-welcome-heading',
  ShowCaseNewDataTablePage1WelcomeBody = 'show-case-new-data-table-page1-welcome-body',
  ShowCaseNewDataTablePage2WelcomeBody = 'show-case-new-data-table-page2-welcome-body',

  ShowCaseNewTabsWelcomeHeading = 'show-case-new-tabs-welcome-heading',
  ShowCaseNewTabsWelcomeBody = 'show-case-new-tabs-welcome-body',

  ShowCaseMarkAsReadWelcomeHeading = 'show-case-mark-as-read-welcome-heading',
  ShowCaseMarkAsReadWelcomeBody = 'show-case-mark-as-read-welcome-body',

  ShowCaseWhatsNewMonthHeading = 'show-case-whats-new-month-heading',
  ShowCaseWhatsNewAugustBody = 'show-case-whats-new-august-body',
}

export const RelativeDateIntervalPrefix = 'RelativeDateInterval';

export type TranslationLocale = {
  language: string;
  messages: {
    [P in MessageKeys]: string;
  };
};

export const getFilterTypeMessageKey = (
  filterType: FilterType,
): MessageKeys => {
  switch (filterType) {
    case 'articleFilter':
      return MessageKeys.FilterTypeArticleFilter;
    case 'insightFilter':
      return MessageKeys.FilterTypeActionsFilter;
  }
};

export const getDefaultPlaceholder = (key: MessageKeys, t: Translator) =>
  t(MessageKeys.DefaultPlaceholder, { subject: t(key) });
