import styled from 'styled-components';

export const PageScrollingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow-x: hidden;

  .overflow-hidden {
    overflow: hidden;
  }
`;
