import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
} from 'react';

type LoadingKey = string | number | symbol;

export type ApplicationState = {
  loadingKeys: Array<LoadingKey>;
  mutationKeys: Array<LoadingKey>;

  setLoadingKeys?: (loadingKey: LoadingKey, isLoading: boolean) => void;
  setMutationKeys?: (mutationKey: LoadingKey, isMutating: boolean) => void;
};

export const ApplicationStateContext = React.createContext<ApplicationState>({
  loadingKeys: [],
  mutationKeys: [],
});

export const ApplicationStateContextProvider: FunctionComponent<EmptyPropsWithChildren> = ({
  children,
}) => {
  const [loadingKeys, setLoadingKeys] = React.useState<Array<LoadingKey>>([]);
  const [mutationKeys, setMutationKeys] = React.useState<Array<LoadingKey>>([]);

  const handleSetLoadingKeys = useCallback(
    (loadingKey: LoadingKey, isLoading: boolean) => {
      setLoadingKeys((old) => {
        if (isLoading) {
          return [...(old || []), loadingKey];
        } else {
          return old?.filter((key) => key !== loadingKey);
        }
      });
    },
    [],
  );

  const handleSetMutationKeys = useCallback(
    (mutationKey: LoadingKey, isMutating: boolean) => {
      setMutationKeys((old) => {
        if (isMutating) {
          return [...(old || []), mutationKey];
        } else {
          return old?.filter((key) => key !== mutationKey);
        }
      });
    },
    [],
  );

  const value = useMemo<ApplicationState>(
    () => ({
      loadingKeys,
      mutationKeys,
      setLoadingKeys: handleSetLoadingKeys,
      setMutationKeys: handleSetMutationKeys,
    }),
    [handleSetLoadingKeys, handleSetMutationKeys, loadingKeys, mutationKeys],
  );

  return (
    <ApplicationStateContext.Provider value={value}>
      {children}
    </ApplicationStateContext.Provider>
  );
};

export const useApplicationState = () => useContext(ApplicationStateContext);
export const useApplicationStateLoadingKey = (loadingKey: LoadingKey) => {
  const { loadingKeys, setLoadingKeys } = useApplicationState();
  const callback = useCallback(
    (isLoading: boolean) => {
      setLoadingKeys(loadingKey, isLoading);
    },
    [loadingKey, setLoadingKeys],
  );

  return {
    isLoading: loadingKeys.includes(loadingKey),
    setIsLoading: callback,
  };
};

export const useApplicationStateMutationKey = (mutationKey: LoadingKey) => {
  const { mutationKeys, setMutationKeys } = useApplicationState();
  const callback = useCallback(
    (isMutating: boolean) => {
      setMutationKeys(mutationKey, isMutating);
    },
    [mutationKey, setMutationKeys],
  );

  return {
    isMutating: mutationKeys.includes(mutationKey),
    setIsMutating: callback,
  };
};

export const useApplicationStateAnyActivity = () => {
  const { loadingKeys, mutationKeys } = useApplicationState();
  return loadingKeys.length > 0 || mutationKeys.length > 0;
};
