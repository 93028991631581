import { FormRow, IconButton, Modal } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';
import { ModalFormLabel } from '../modals';

export const AdvancedSearchFilterDialogModal = styled(Modal)``;

export const AdvancedSearchFilterDialogRowLabel = styled(ModalFormLabel)`
  display: flex;
  align-items: center;
  width: 16rem;

  & > .icon {
    margin-right: 0.4rem;
  }
`;

export const AdvancedSearchFilterDialogHelpButton = styled(IconButton)``;

export const AdvancedSearchFilterDialogTipText = styled.div`
  max-width: 35rem;
  font-size: 1.1rem;
`;

export const AdvancedSearchFilterDialogInputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      width: 100%;
    }
  `}
`;

export const AdvancedSearchFilterDialogInputTipText = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 0.2rem;
  font-size: 1.1rem;
  opacity: 0.6;
`;

export const AdvancedSearchFilterDialogRowOrLabel = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  flex: 1;
`;

export const AdvancedSearchFilterDialogGeneratedStringFormRow = styled(FormRow)`
  margin-top: 6rem !important;
`;

export const AdvancedSearchFilterDialogGeneratedString = styled.pre`
  margin: 0;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.3rem;
  position: relative;
  top: -0.2rem;
  white-space: normal;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AdvancedSearchFilterDialogORLabel = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.6rem 0;
  font-size: 1.6rem;
  font-weight: 600;
`;
