import {
  RegeyeShowcase,
  SettingsV1Diff,
  User,
} from '@fcg-tech/regtech-types/regeye';
import update from 'immutability-helper';
import { useCallback, useMemo } from 'react';
import useSWR, { SWRConfiguration, SWRResponse, useSWRConfig } from 'swr';
import { whatsNewShowCases } from '../../components/showcases';
import { NameOrder, Settings } from '../../types';
import { useApi } from '../apiUtils';
import {
  ArticleExpandedMetadata,
  NotificationSetting,
  NotificationSettingsList,
  PersonalApi,
  UpdateNotificationsSettingsRequest,
} from '../schema';
import {
  allTeamsKey,
  articleMetaDataKey,
  currentUserKey,
  myNotificationsSettingsKey,
  mySettingsKey,
} from './cacheKeys';

const defaultSettings: Settings = {
  version: 2,
  dateFormat: 'yyyy-MM-dd',
  dateTimeformat: 'yyyy-MM-dd HH:mm',
  userNamePreference: NameOrder.Alias,
  dateFnsLocale: 'sv',
  startOfWeek: 1,
  markReadAfterMs: 0,
  pinnedFilters: [],
  feedArticleReaderWidth: 0.5,
  companies: [],
  teams: [],
  deferredShowcases: [],
  flags: {},
};

const getSettings = async (api: PersonalApi): Promise<Settings> => {
  const data = (await api.getPersonalSettings()) as Settings;

  if (!data?.deferredShowcases?.includes(RegeyeShowcase.NewUser)) {
    // New user. Should not see all the "whats new" showcases
    data.deferredShowcases = data.deferredShowcases ?? [];
    data.deferredShowcases.push(...whatsNewShowCases);

    await api.updatePersonalSettings({ body: data });
  }

  if (data.version === 1) {
    data.version = 2;
    delete (data as SettingsV1Diff).columns;
    await api.updatePersonalSettings({ body: data });
  }

  /* Keep this in place for a few months, but remove it in the future. Bug has been fixed, waiting for all users data to be normalized */
  if (data.teams) {
    const set = new Set<string>();
    const filtered: typeof data.teams = [];
    [...data.teams].reverse().forEach((team) => {
      if (!set.has(team.id)) {
        set.add(team.id);
        filtered.push(team);
      }
    });

    const oldLength = data.teams.length;
    data.teams = filtered;
    if (oldLength !== filtered.length) {
      await api.updatePersonalSettings({ body: data });
    }
  }

  /* Keep this in place for a few months, but remove it in the future. Bug has been fixed, waiting for all users data to be normalized */
  if (data.companies) {
    const set = new Set<string>();
    const filtered: typeof data.companies = [];
    [...data.companies].reverse().forEach((company) => {
      if (!set.has(company.id)) {
        set.add(company.id);
        filtered.push(company);
      }
    });

    const oldLength = data.companies.length;
    data.companies = filtered;
    if (oldLength !== filtered.length) {
      await api.updatePersonalSettings({ body: data });
    }
  }

  return {
    ...defaultSettings,
    ...(data ?? {}),
  };
};

export const useSettings = (
  config: SWRConfiguration = {},
): SWRResponse<Settings> => {
  const api = useApi<PersonalApi>('PersonalApi');
  return useSWR<Settings>(mySettingsKey(), () => getSettings(api), {
    suspense: true,
    refreshInterval: 60000,
    dedupingInterval: 30000,
    ...config,
  });
};

const mergeSettings = (
  settings: Settings,
  patch: Partial<Settings>,
): Settings => {
  let newSettings = { ...settings };
  Object.entries(patch).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      newSettings = update(newSettings, {
        [key]: { $set: value },
      });
    } else if (typeof value === 'object') {
      newSettings = update(newSettings, {
        [key]: { $merge: value },
      });
    } else {
      newSettings = update(newSettings, {
        [key]: { $set: value },
      });
    }
  });

  return newSettings;
};

export const useSettingsActions = () => {
  const api = useApi<PersonalApi>('PersonalApi');
  const { data: settings } = useSettings();
  const { mutate } = useSWRConfig();

  const updateSettings = useCallback(
    async (newSettings: Settings) => {
      await api.updatePersonalSettings({ body: newSettings });
      mutate(mySettingsKey(), newSettings, true);
    },
    [api, mutate],
  );

  return useMemo(
    () => ({
      settings,
      updateSettings,
      patchSettings: async (patch: Partial<Settings>) => {
        await updateSettings(mergeSettings(settings, patch));
        if (patch.teams) {
          mutate(allTeamsKey());
        }
      },
      resetSettings: async () => {
        await updateSettings(defaultSettings);
      },
    }),
    [mutate, settings, updateSettings],
  );
};

export const useCurrentUser = (config: SWRConfiguration = {}) => {
  const api = useApi<PersonalApi>('PersonalApi');
  return useSWR<User>(currentUserKey(), () => api.getCurrentUser(), {
    suspense: true,
    ...config,
  });
};

export const useCurrentUserActions = () => {
  const api = useApi<PersonalApi>('PersonalApi');
  const { mutate } = useSWRConfig();
  return useMemo(
    () => ({
      updateUserAlias: async (alias: string) => {
        const result = await api.updateCurrentUser({ userInput: { alias } });

        mutate(currentUserKey(), result, false);

        return result;
      },
    }),
    [api, mutate],
  );
};

export const useFeedArticleActions = () => {
  const personalApi = useApi<PersonalApi>('PersonalApi');
  const { mutate } = useSWRConfig();

  return useMemo(
    () => ({
      bookmark: async (articleId: string, removeBookmark?: boolean) => {
        if (removeBookmark) {
          return personalApi.unbookmarkArticle({ articleId });
        }
        return personalApi.bookmarkArticle({ articleId });
      },

      markAsRead: async (articleId: string, removeMarkAsRead?: boolean) => {
        let promise: ReturnType<typeof personalApi.markArticleAsUnread>;
        if (removeMarkAsRead) {
          promise = personalApi.markArticleAsUnread({ articleId });
        } else {
          promise = personalApi.markArticleAsRead({ articleId });
        }

        const result = await promise;

        mutate(
          articleMetaDataKey(articleId),
          (old: ArticleExpandedMetadata): ArticleExpandedMetadata => {
            return {
              ...old,
              teamReadMetadata: result.metadata.items,
            };
          },
          false,
        );
      },
    }),
    [mutate, personalApi],
  );
};

export const usePersonalNotificationSettings = (
  config: SWRConfiguration = {},
) => {
  const api = useApi<PersonalApi>('PersonalApi');
  return useSWR<NotificationSettingsList>(
    myNotificationsSettingsKey(),
    () => api.getNotificationsSettings(),
    {
      suspense: true,
      ...config,
    },
  );
};

export const usePersonalNotificationSettingsActions = () => {
  const api = useApi<PersonalApi>('PersonalApi');
  const { mutate } = useSWRConfig();

  return useMemo(
    () => ({
      updateNotificationsSettings: async (
        settings: Array<NotificationSetting>,
      ) => {
        await api.updateNotificationsSettings({
          notificationSettingsList: { items: settings },
        });
        mutate(myNotificationsSettingsKey());
      },
    }),
    [api, mutate],
  );
};
