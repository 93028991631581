import React, { FunctionComponent } from 'react';
import { PublisherDiff } from './teamSettingsUtils';
import {
  TeamSettingsSubscriptionsSummaryDiffRow,
  TeamSettingsSubscriptionsSummaryDiffRowCount,
  TeamSettingsSubscriptionsSummaryDiffRowHeading,
  TeamSettingsSubscriptionsSummaryWrapper,
} from './TeamSettingsSubscriptionsSummary.styles';

interface TeamSettingsSubscriptionsSummaryProps {
  diffs: Array<PublisherDiff>;
}

export const TeamSettingsSubscriptionsSummary: FunctionComponent<TeamSettingsSubscriptionsSummaryProps> = ({
  diffs,
}) => {
  return (
    <TeamSettingsSubscriptionsSummaryWrapper>
      {diffs.map((diff) => (
        <TeamSettingsSubscriptionsSummaryDiffRow key={diff.id}>
          <TeamSettingsSubscriptionsSummaryDiffRowHeading>
            {diff.name}
          </TeamSettingsSubscriptionsSummaryDiffRowHeading>
          <TeamSettingsSubscriptionsSummaryDiffRowCount>
            Added article types: {diff.addedArticleTypes}
          </TeamSettingsSubscriptionsSummaryDiffRowCount>
          <TeamSettingsSubscriptionsSummaryDiffRowCount>
            Removed article types: {diff.removedArticleTypes}
          </TeamSettingsSubscriptionsSummaryDiffRowCount>
        </TeamSettingsSubscriptionsSummaryDiffRow>
      ))}
    </TeamSettingsSubscriptionsSummaryWrapper>
  );
};
