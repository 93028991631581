import { SpinningLoadingIcon } from '@fcg-tech/regtech-components';
import {
  DataTableColumn,
  DataTableProps,
  Row,
  RowRenderer,
  SortingRule,
} from '@fcg-tech/regtech-datatable';
import { ArrowDirection } from '@fcg-tech/regtech-types';
import type {
  FeedArticle,
  FeedArticleListMutator,
} from '@fcg-tech/regtech-types/regeye';
import {
  classNames,
  debounce,
  isInputActive,
  useArrowKeys,
  useDevice,
} from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import anime from 'animejs';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { FeedArticleTeamMetadataContentProps } from '../FeedArticleTeamMetadata/FeedArticleTeamMetadataContent';
import { useTeamContext } from '../TeamContext/TeamContext';
import { FeedArticleCondensedTable } from './FeedArticleCondensedTable';
import {
  FeedArticleLoadingWrapper,
  FeedArticleTableDataTable,
  FeedArticleTableNoContent,
  FeedArticleTableWrapper,
} from './FeedArticleTable.styles';
import {
  getInnerScrollHeight,
  useFeedArticleActionHandlers,
} from './feedArticleTableHelpers';
import { FeedArticleTableRow } from './FeedArticleTableRow';

export interface FeedArticleTableProps
  extends Pick<
    DataTableProps,
    'sortBy' | 'onSortByChange' | 'onColumnsChange'
  > {
  feedArticles?: Array<FeedArticle>;
  filterHash?: string;
  columns: Array<DataTableColumn<FeedArticle>>;
  loading?: boolean;
  allowArrowKeyNavigation?: boolean;
  removeArchived?: boolean;
  removeUnbookmarked?: boolean;
  removeRead?: boolean;
  sortBy?: SortingRule<FeedArticle>;
  mutateArticle?: (
    articleId: string,
    { bookmarked, read }: { bookmarked?: boolean; read?: boolean },
  ) => void;
  mutateFeedArticleList?: (
    callback: FeedArticleListMutator,
    shouldRevalidate?: boolean,
  ) => void;
  parentElement: HTMLElement;
  onSortByChange?: (sortBy: SortingRule<FeedArticle>) => void;
  onChangePageByDelta?: (direction: 1 | -1) => boolean;
  onTagClick?: (tagId: string) => void;
  onTeamTagClick?: FeedArticleTeamMetadataContentProps['onTeamTagClick'];
  onArticleTypeClick?: (tagId: string) => void;
  onArticleMetadataMutated?: (articleId: string) => void;
}

const getRowId = (originalRow: FeedArticle) => originalRow.internalId;

export const FeedArticleTable: FunctionComponent<FeedArticleTableProps> = ({
  feedArticles,
  filterHash,
  columns,
  loading,
  allowArrowKeyNavigation,
  sortBy,
  removeArchived,
  removeUnbookmarked,
  removeRead,
  parentElement,
  mutateArticle,
  mutateFeedArticleList,
  onSortByChange,
  onChangePageByDelta,
  onTeamTagClick,
  onTagClick,
  onColumnsChange,
  onArticleTypeClick,
  onArticleMetadataMutated,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const wrapperRef = useRef<HTMLDivElement>();
  const scrollRef = useRef<HTMLDivElement>();

  const [maxHeight, setMaxHeight] = useState(undefined);
  const [maxWidth, setMaxWidth] = useState(undefined);
  const [expanded, setExpanded] = useState<Array<string>>([]);
  const archiveStatusUpdatedWhileExpanded = useRef<Array<string>>([]);
  const readStatusUpdatedWhileExpanded = useRef<Array<string>>([]);

  const { teamId: contextTeamId } = useTeamContext();

  const feedArticlesRef = useRef<Array<FeedArticle>>(feedArticles);

  useEffect(() => {
    feedArticlesRef.current = feedArticles;
  }, [feedArticles]);

  const {
    handleArchiveChange: baseHandleArchiveChange,
    handleBookmarkChange: baseHandleBookmarkChange,
    handleMarkAsReadChange: baseHandleMarkAsReadChange,
  } = useFeedArticleActionHandlers({
    mutateArticle,
    mutateFeedArticleList,
  });

  const collapseArticleRow = useCallback((articleId: string) => {
    const row = wrapperRef.current.querySelector(
      `.tr[data-articleid="${articleId}"]`,
    );
    if (row) {
      anime({
        targets: row,
        height: 0,
        duration: 160,
        easing: 'linear',
      });
    }
  }, []);

  const removeAndCollapseArticleRow = useCallback(
    (articleId: string, feedArticleInternalId: string) => {
      setTimeout(() => {
        collapseArticleRow(articleId);
        setTimeout(() => {
          mutateFeedArticleList((old) => {
            const index = old.items.findIndex(
              (f) => f.internalId === feedArticleInternalId,
            );
            if (index >= 0) {
              return update(old, {
                items: {
                  $splice: [[index, 1]],
                },
              });
            }
          });
        }, 600);
      }, 110);
    },
    [collapseArticleRow, mutateFeedArticleList],
  );

  const flashArticleRow = useCallback((articleId: string) => {
    const row = wrapperRef.current.querySelector(
      `.tr[data-articleid="${articleId}"]`,
    );
    if (row) {
      row.classList.toggle('flash-enter', true);
      setTimeout(() => {
        row.classList.toggle('flash-enter-active', true);
        setTimeout(() => {
          row.classList.toggle('flash-enter-active', false);
          row.classList.toggle('flash-enter', false);
        }, 500);
      }, 1);
    }
  }, []);

  const handleArchiveChange = useCallback(
    (isArchived: boolean, articleId: string, archivedForTeamId: string) => {
      const feedArticle = feedArticlesRef.current.find(
        (feedArticle) => feedArticle.article.id === articleId,
      );
      if (feedArticle) {
        if (
          removeArchived &&
          (feedArticle.team?.id ?? contextTeamId) === archivedForTeamId
        ) {
          if (expanded.includes(feedArticle.internalId)) {
            if (archiveStatusUpdatedWhileExpanded.current.includes(articleId)) {
              archiveStatusUpdatedWhileExpanded.current.splice(
                archiveStatusUpdatedWhileExpanded.current.indexOf(articleId),
                1,
              );
            } else {
              archiveStatusUpdatedWhileExpanded.current.push(articleId);
            }
          } else {
            collapseArticleRow(articleId);
          }
          setTimeout(
            () =>
              baseHandleArchiveChange(
                isArchived,
                articleId,
                archivedForTeamId,
                !expanded.includes(feedArticle.internalId),
              ),
            250,
          );
        } else {
          baseHandleArchiveChange(isArchived, articleId, archivedForTeamId);
        }
      }
    },
    [
      baseHandleArchiveChange,
      collapseArticleRow,
      contextTeamId,
      expanded,
      removeArchived,
    ],
  );

  useEffect(() => setExpanded([]), [filterHash]);

  const resize = useCallback(() => {
    if (parentElement) {
      setMaxHeight(getInnerScrollHeight(parentElement.parentElement) + 10);
      setMaxWidth(scrollRef.current?.scrollWidth);
    }
  }, [parentElement]);

  useEffect(() => {
    if (!loading) {
      resize();
    }
  }, [loading, parentElement, resize]);

  useEffect(() => {
    const onResize = debounce(() => {
      if (!loading) {
        resize();
      }
    }, 1000);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [loading, resize]);

  const handleContentLoad = useCallback((container: HTMLDivElement) => {
    setTimeout(() => {
      if (container) {
        const targetTop = Math.max(
          0,
          container.getBoundingClientRect().y -
            scrollRef.current.getBoundingClientRect().y +
            scrollRef.current.scrollTop -
            10,
        );

        scrollRef.current.scrollTo({
          top: targetTop,
          behavior: 'smooth',
        });
      }
    }, 100);
  }, []);

  const handleArrowKeyDown = useCallback(
    (direction: ArrowDirection) => {
      const feedArticles = feedArticlesRef.current;
      if (feedArticles.length === 0 || !allowArrowKeyNavigation) {
        return;
      }
      switch (direction) {
        case 'ArrowDown':
          if (expanded.length === 0) {
            setExpanded([feedArticles[0].internalId]);
          } else {
            const lastId = expanded[expanded.length - 1];
            const nextId =
              feedArticles[
                feedArticles.findIndex((f) => f.internalId === lastId) + 1
              ]?.internalId;

            if (nextId) {
              setExpanded([nextId]);
            } else if (onChangePageByDelta) {
              // Go to next page
              setExpanded([]);
              if (onChangePageByDelta(1)) {
                localStorage.setItem('expandArticle', 'first');
              }
            }
          }
          break;
        case 'ArrowUp':
          if (expanded.length > 0) {
            const firstId = expanded[0];
            const prevId =
              feedArticles[
                feedArticles.findIndex((f) => f.internalId === firstId) - 1
              ]?.internalId;

            if (prevId) {
              setExpanded([prevId]);
            } else if (onChangePageByDelta) {
              // Go to previous page
              setExpanded([]);
              if (onChangePageByDelta(-1)) {
                localStorage.setItem('expandArticle', 'last');
              }
            }
          }
          break;
      }
    },
    [allowArrowKeyNavigation, expanded, onChangePageByDelta],
  );

  useArrowKeys({ callback: handleArrowKeyDown });

  const handleRowClick = useCallback((row: Row<FeedArticle>) => {
    setExpanded((old) => [...old, row.original.internalId]);
  }, []);

  const handleRowRequestClose = useCallback(
    (feedArticleInternalId: string) => {
      const feedArticle = feedArticlesRef.current.find(
        (f) => f.internalId === feedArticleInternalId,
      );
      if (feedArticle) {
        setExpanded((old) => {
          const index = old.indexOf(feedArticleInternalId);
          if (index >= 0) {
            setTimeout(() => flashArticleRow(feedArticle.article.id), 10);
            if (
              archiveStatusUpdatedWhileExpanded.current.includes(
                feedArticle.article.id,
              )
            ) {
              archiveStatusUpdatedWhileExpanded.current.splice(
                archiveStatusUpdatedWhileExpanded.current.indexOf(
                  feedArticle.article.id,
                ),
                1,
              );
              if (removeArchived) {
                removeAndCollapseArticleRow(
                  feedArticle.article.id,
                  feedArticleInternalId,
                );
              }
            }

            if (
              readStatusUpdatedWhileExpanded.current.includes(
                feedArticle.article.id,
              )
            ) {
              readStatusUpdatedWhileExpanded.current.splice(
                readStatusUpdatedWhileExpanded.current.indexOf(
                  feedArticle.article.id,
                ),
                1,
              );
              if (removeRead) {
                removeAndCollapseArticleRow(
                  feedArticle.article.id,
                  feedArticleInternalId,
                );
              }
            }

            return update(old, { $splice: [[index, 1]] });
          }
          return old;
        });
      }
    },
    [flashArticleRow, removeAndCollapseArticleRow, removeArchived, removeRead],
  );

  const handleColumnsChange = useCallback(
    (columns: Array<DataTableColumn<FeedArticle>>) => {
      onColumnsChange?.(columns);
      setMaxWidth(scrollRef.current.scrollWidth);
    },
    [onColumnsChange],
  );

  const handleBookmarkChange = useCallback<typeof baseHandleBookmarkChange>(
    (isBookmarked: boolean, articleId: string) => {
      const feedArticle = feedArticlesRef.current.find(
        (f) => f.article.id === articleId,
      );
      if (feedArticle) {
        baseHandleBookmarkChange(isBookmarked, articleId);
        if (!isBookmarked && removeUnbookmarked) {
          removeAndCollapseArticleRow(articleId, feedArticle.internalId);
        }
      }
    },
    [baseHandleBookmarkChange, removeAndCollapseArticleRow, removeUnbookmarked],
  );

  const handleMarkAsReadChange = useCallback<typeof baseHandleMarkAsReadChange>(
    (isRead: boolean, articleId: string) => {
      const feedArticle = feedArticlesRef.current.find(
        (f) => f.article.id === articleId,
      );
      if (feedArticle) {
        baseHandleMarkAsReadChange(isRead, articleId);
        if (isRead && removeRead) {
          if (expanded.includes(feedArticle.internalId)) {
            if (readStatusUpdatedWhileExpanded.current.includes(articleId)) {
              readStatusUpdatedWhileExpanded.current.splice(
                readStatusUpdatedWhileExpanded.current.indexOf(articleId),
                1,
              );
            } else {
              readStatusUpdatedWhileExpanded.current.push(articleId);
            }
          } else {
            removeAndCollapseArticleRow(articleId, feedArticle.internalId);
          }
        }
      }
    },
    [baseHandleMarkAsReadChange, removeAndCollapseArticleRow, expanded, removeRead],
  );

  const [forceHover, setForceHover] = useState(false);

  useEffect(() => {
    const handleShift = (event: KeyboardEvent) => {
      if (!isInputActive(event) && event.key === 'Shift') {
        setForceHover(event.type === 'keydown');
      }
    };
    document.addEventListener('keydown', handleShift);
    document.addEventListener('keyup', handleShift);

    return () => {
      document.removeEventListener('keydown', handleShift);
      document.removeEventListener('keyup', handleShift);
    };
  }, []);

  const WrappedRow = useMemo<RowRenderer<FeedArticle>>(() => {
    return (props) => (
      <FeedArticleTableRow
        {...props}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        onContentLoad={handleContentLoad}
        onRequestClose={handleRowRequestClose}
        onTagClick={onTagClick}
        onTeamTagClick={onTeamTagClick}
        onArticleTypeClick={onArticleTypeClick}
        onArticleMetadataMutated={onArticleMetadataMutated}
        onArchive={handleArchiveChange}
        onMarkAsRead={handleMarkAsReadChange}
        onBookmark={handleBookmarkChange}
      />
    );
  }, [
    maxHeight,
    maxWidth,
    handleContentLoad,
    handleRowRequestClose,
    onTagClick,
    onTeamTagClick,
    onArticleTypeClick,
    onArticleMetadataMutated,
    handleArchiveChange,
    handleMarkAsReadChange,
    handleBookmarkChange,
  ]);

  return (
    <FeedArticleTableWrapper ref={wrapperRef}>
      {!isMobile ? (
        <FeedArticleTableDataTable
          columns={columns}
          data={feedArticles}
          Row={WrappedRow}
          height={`${maxHeight}px`}
          expanded={expanded}
          getRowId={getRowId}
          scrollRef={scrollRef}
          sortBy={sortBy}
          className={classNames(forceHover && 'force-hover')}
          onRowClick={handleRowClick}
          onSortByChange={onSortByChange}
          onColumnsChange={handleColumnsChange}
        />
      ) : (
        <FeedArticleCondensedTable
          feedArticles={feedArticles}
          mutateArticle={mutateArticle}
          mutateFeedArticle={mutateFeedArticleList}
          loading={loading}
          onChangePageByDelta={onChangePageByDelta}
        />
      )}
      {feedArticles?.length === 0 ? (
        <FeedArticleTableNoContent>
          {t(MessageKeys.ArticleFeedTableNoContent)}
        </FeedArticleTableNoContent>
      ) : null}
      {loading ? (
        <FeedArticleLoadingWrapper>
          <SpinningLoadingIcon size="80px" />
        </FeedArticleLoadingWrapper>
      ) : null}
    </FeedArticleTableWrapper>
  );
};
