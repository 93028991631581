import { mobile, UnstyledButton } from '@fcg-tech/regtech-components';
import { foundation } from '@fcg-tech/regtech-design-system';
import styled, { css, DefaultTheme } from 'styled-components';
import { activityBlipp } from '../../styleBlocks';
import { AssignedTeamMemberList } from '../TeamMemberList/AssignedTeamMemberList';

export const FeedArticleTeamMetadataWrapper = styled.div`
  ${({ theme }) => css`
    background: transparent;
    border: 1px solid transparent;
    padding: 0.6rem 0.5rem 1.5rem 0.5rem;
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    &.expanded {
      background: ${theme?.palette?.MainWhite ?? '#fff'};
      border-color: #333;
    }

    &.expanded + & {
      margin-top: 1.6rem;
    }

    ${mobile(
      () => css`
        border: 0;

        & + & {
          border-top: 1px solid #777;
          border-radius: 0;
        }
      `,
    )}
  `}
`;

export const FeedArticleTeamMetadataHeader = styled.div.attrs({
  role: 'button',
})`
  position: relative;
  padding: 0.5rem 2rem 0.5rem 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const FeedArticleTeamMetadataExpandIconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-45%);
`;

export const FeedArticleTeamMetadataHeaderWrapper = styled.div`
  margin-right: 1rem;
  position: relative;
  top: 1px;
  width: 1.8rem;

  &.activity {
    &:before {
      ${activityBlipp}
    }
  }
`;

export const FeedArticleTeamMetadataContentOuterWrapper = styled.div`
  padding: 1.5rem 2rem 0.5rem 2rem;
`;

export const FeedArticleTeamMetadataItemLabel = styled.div.attrs({
  'data-testid': 'feed-article-team-metadata-item-label',
})`
  flex: 1;
  color: black;
  display: flex;
  align-items: center;

  ${FeedArticleTeamMetadataWrapper}:not(.subscribed):not(.expanded), ${FeedArticleTeamMetadataWrapper}.archived & {
    opacity: 0.4;
  }
`;

export const FeedArticleTeamMetadataItemLabelNotSubscribed = styled.span`
  margin-left: 2rem;
  font-size: 1.1rem;
`;

export const FeedArticleTeamMetadataArchived = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-left: 1rem;
`;

export const FeedArticleTeamMetadataArchivedLabel = styled.span`
  margin-right: 1rem;
`;

export const FeedArticleTeamMetadataItemReadCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${foundation.palette.onyx350.hex};

  width: 7rem;

  & > .lucide {
    margin-right: 0.5rem;
  }

  &.active > .lucide {
    color: ${foundation.palette.opal350.hex};
  }
`;

export const FeedArticleTeamMetadataContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeedArticleTeamMetadataContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  max-width: 100%;
`;

export const FeedArticleTeamMetadataContentSection = styled.div`
  display: flex;

  & + & {
    margin-top: 1.6rem;
  }
`;

export const FeedArticleTeamMetadataContentSectionRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 100%;

  & + & {
    margin-top: 1.6rem;
  }

  &.expand {
    width: 100%;
  }

  ${mobile(
    () => css`
      width: 100%;
      flex-direction: column;
      flex-wrap: initial;
    `,
  )}
`;

export const FeedArticleTeamMetadataContentSectionRowLabel = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  width: 10rem;
  padding-top: 0.3rem;
  margin: 0 0 0.7rem 0;
`;

export const FeedArticleMetadataContentActivitiesButton = styled.div.attrs({
  role: 'button',
})`
  padding: 0.5rem 0 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const FeedArticleMetadataContentActivitiesLabel = styled(
  FeedArticleTeamMetadataContentSectionRowLabel,
)`
  width: auto;
  margin-left: 0.8rem;
`;

export const FeedArticleTeamMetadataContentSectionRowWidget = styled.div`
  flex: 1;
  width: 100%;
  max-width: 100%;
`;

export const FeedArticleTeamMetadataContentImportanceWrapper = styled(
  FeedArticleTeamMetadataContentSectionRowWidget,
)`
  width: 20rem;
  flex: initial;
`;

export const FeedArticleTeamMetadataContentAttachmentsWrapper = styled(
  FeedArticleTeamMetadataContentSectionRowWidget,
)`
  position: relative;
  top: -0.3rem;
`;

export const FeedArticleTeamMetadataContentDueDateWrapper = styled(
  FeedArticleTeamMetadataContentSectionRowWidget,
)`
  width: 16rem;
  flex: initial;
  position: relative;
  top: -0.3rem;
`;

export const FeedArticleTeamMetadataContentWidgetDisabledWrapper = styled(
  FeedArticleTeamMetadataContentSectionRowWidget,
)`
  position: relative;
  top: 0.5rem;
`;

export const FeedArticleTeamMetadataContentTags = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const FeedArticleTeamMetadataContentAddTagButton = styled(
  UnstyledButton,
)`
  width: 2.4rem;
`;

export const FeedArticleTeamMetadataContentSelectWrapper = styled.div`
  flex: 1;
  position: relative;
  top: -0.7rem;
  ${mobile(
    () => css`
      top: 0;
    `,
  )}
`;

export const FeedArticleTeamMetadataContentTeamList = styled(
  AssignedTeamMemberList,
)`
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
`;

export const FeedArticleTeamMetadataContentAddTagSelectWrapper = styled.div`
  ${FeedArticleTeamMetadataContentSelectWrapper}:not(:first-child) > & {
    margin-top: 1rem;
  }
`;

export const FeedArticleTeamMetadataContentMailIconWrapper = styled.div`
  position: relative;
  top: 1px;

  color: ${foundation.palette.onyx200.hex};

  &.read {
    color: ${foundation.palette.topaz500.hex};
  }
`;

export const compactTheme: Partial<DefaultTheme> = {
  elements: {
    button: {
      primary: css`
        height: 3rem;
        font-size: 1.2rem;
        height: 3rem;
      `,
      secondary: css`
        height: 3rem;
        font-size: 1.2rem;
        height: 3rem;
      `,
    },

    textField: {
      input: css`
        font-size: 1.3rem;
        padding: 0.8rem;
        height: auto;
      `,

      wrapper: css`
        padding: 0;
        height: auto;
      `,
    },

    textArea: {
      input: css`
        min-height: 4rem;
      `,
    },
  },
};

export const FeedArticleTeamMetadataTeamTagLongPlaceholder = styled.span`
  font-size: 1.2rem;
  color: #666;
`;
