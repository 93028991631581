import { Company, FeedArticleDomain, Settings, Team } from '../types';

export const getFeedArticleDomain = (
  companyId: string | undefined | null,
  teamId: string | undefined | null,
): FeedArticleDomain => {
  if (teamId) {
    return FeedArticleDomain.Team;
  }

  if (companyId) {
    return FeedArticleDomain.Company;
  }

  return FeedArticleDomain.Personal;
};

export const isCompanyVisible = (
  company: Company | undefined | null,
  settings: Settings | undefined | null,
): boolean => {
  if (!company) {
    return false;
  }
  return Boolean(
    settings?.companies?.find((c) => c.id === company.id)?.hidden !== true,
  );
};

export const isTeamVisible = (
  team: Team | undefined | null,
  settings: Settings | undefined | null,
): boolean => {
  if (!team) {
    return false;
  }
  return Boolean(
    settings?.teams?.find((t) => t.id === team.id)?.hidden !== true,
  );
};

export const getEnabledAndDisabled = (
  companies: Array<Company>,
  teams: Array<Team>,
  settings: Settings,
): [
  enabledCompanies: Array<Company>,
  enabledTeams: Array<Team>,
  disabledCompanies: Array<Company>,
  disabledTeams: Array<Team>,
] => {
  const enabledCompanies: Array<Company> = [];
  const enabledTeams: Array<Team> = [];
  const disabledCompanies: Array<Company> = [];
  const disabledTeams: Array<Team> = [];

  companies.forEach((company) => {
    if (!isCompanyVisible(company, settings)) {
      disabledCompanies.push(company);
      teams.forEach((team) => {
        if (team.company?.id === company.id) {
          disabledTeams.push(team);
        }
      });
    } else {
      enabledCompanies.push(company);
      teams.forEach((team) => {
        if (team.company?.id === company.id) {
          if (!isTeamVisible(team, settings)) {
            disabledTeams.push(team);
          } else {
            enabledTeams.push(team);
          }
        }
      });
    }
  });

  return [enabledCompanies, enabledTeams, disabledCompanies, disabledTeams];
};
