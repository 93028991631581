import { mobile } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const ActivitiesWrapper = styled.div`
  width: 100%;
`;

export const ActivitiesListOuterContainer = styled.div`
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.2rem;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    pointer-events: none;
  }
`;

export const ActivitiesListContainer = styled.div`
  overflow-y: scroll;
  max-height: 30rem;
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;
  scroll-behavior: smooth;
`;

export const ActivitiesListWrapper = styled.div`
  padding: 1rem 0;
`;

export const ActivitiesEntryWrapper = styled.div`
  padding-left: 0;
  display: flex;
  & + & {
    margin-top: 1rem;
  }
  position: relative;
`;

export const ActivitiesEntryAvatar = styled.div`
  ${({ theme }) => css`
    width: 3rem;
    height: 3rem;
    background-color: ${theme?.palette?.MainColor?.main ?? '#aaa'};
    color: ${theme?.palette?.MainColor?.mainContrast ?? '#111'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1rem;
    font-size: 1.4rem;
    font-weight: 800;

    ${mobile(
      () => css`
        display: none;
      `,
    )}
  `}
`;

export const ActivitiesEntryContent = styled.div`
  flex: 1;
  margin: 0;
  padding: 0 0 0 1rem;
`;

export const ActivitiesEntryMetadata = styled.div`
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 0.7rem;
  display: flex;
  justify-content: space-between;
`;

export const ActivitiesEntryMetadataName = styled.span`
  font-weight: 600;
`;

export const ActivitiesEntryBody = styled.div`
  margin: 0;
  font-size: 1.4rem;
  padding: 0;
  line-height: 2rem;

  ${mobile(
    () => css`
      overflow-wrap: anywhere;
    `,
  )}
`;
