import { useTranslation } from 'react-i18next';
import { useToggle } from '@fcg-tech/regtech-components';
import React, { FunctionComponent } from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import { Activities } from '../Activities/Activities';
import { ErrorBoundary } from '../ErrorBoundary';
import { ChevronIcon } from '../icons';
import { SuspenseLoader } from '../SuspensLoader';
import {
  FeedArticleMetadataContentActivitiesButton,
  FeedArticleMetadataContentActivitiesLabel,
} from './FeedArticleTeamMetadata.styles';

interface FeedArticleTeamMetadataActivitiesProps {
  articleId: string;
  teamId: string;
  initiallyExpanded?: boolean;
}

export const FeedArticleTeamMetadataActivities: FunctionComponent<FeedArticleTeamMetadataActivitiesProps> =
  ({ articleId, teamId, initiallyExpanded }) => {
    const { t } = useTranslation();
    const [expanded, , handleActivitiesListHeaderClick] = useToggle(
      initiallyExpanded ?? false,
    );

    return (
      <React.Fragment>
        <FeedArticleMetadataContentActivitiesButton
          onClick={handleActivitiesListHeaderClick}
        >
          <ChevronIcon down={expanded} />
          <FeedArticleMetadataContentActivitiesLabel>
            {t(MessageKeys.ArticleMetadataActivitiesLabel)}
          </FeedArticleMetadataContentActivitiesLabel>
        </FeedArticleMetadataContentActivitiesButton>
        {expanded ? (
          <ErrorBoundary>
            <SuspenseLoader>
              <Activities teamId={teamId} articleId={articleId} />
            </SuspenseLoader>
          </ErrorBoundary>
        ) : null}
      </React.Fragment>
    );
  };
