import {} from '@fcg-tech/regtech-components';
import { DataTableRow, DataTableRowProps } from '@fcg-tech/regtech-datatable';
import { useIsMountedRef, useMouseHover } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { DataTableActionBar } from '../datatable/DataTableActionBar';

export type RowData = { alias: string; username: string };
export type ColumnKeys = keyof RowData | 'delete';

interface TeamMemberTableRowProps extends DataTableRowProps<RowData> {
  onRemove?: (userId: string) => Promise<void>;
}

export const TeamMemberTableRow: FunctionComponent<TeamMemberTableRowProps> = ({
  onRemove,
  ...props
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const isMounted = useIsMountedRef();

  const row = props.row.original;

  const username = row.username;

  const handleRemoveClick = useCallback(async () => {
    setIsRemoving(true);
    try {
      await onRemove(username);
    } finally {
      if (isMounted.current) {
        setIsRemoving(false);
      }
    }
  }, [isMounted, onRemove, username]);

  const { isMouseOver, ...hoverProps } = useMouseHover();

  return (
    <DataTableRow {...props} {...hoverProps}>
      {isMouseOver ? (
        <div className="tr-hover-container" data-kind="hover">
          <DataTableActionBar
            isRemoving={isRemoving}
            onRemove={handleRemoveClick}
          />
        </div>
      ) : null}
    </DataTableRow>
  );
};
