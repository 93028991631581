import styled from 'styled-components';
import { TagList } from '../TagList';

export const FeedArticleCondensedTableWrapper = styled.div`
  border-top: 1px solid #ccc;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const FeedArticleCondensedTableRowWrapper = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 0.7rem;
`;

export const FeedArticleCondensedTableRowMeta = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: #777;
  font-weight: 600;
`;

export const FeedArticleCondensedTableRowTitle = styled.h2`
  margin: 1rem 0;
  font-size: 1.2rem;
`;

export const FeedArticleCondensedTableRowTagList = styled(TagList)`
  flex: 1;
  min-width: 0;
  overflow: hidden;

  &.right {
    justify-content: flex-end;
  }

  & > .taglist-tag {
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
  }

  & > .taglist-overflow {
    width: 3ch;
    white-space: nowrap;
    margin: 0;
  }
`;

export const FeedArticleCondensedExpandedInfoSwiper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(230, 230, 230, 0.8);

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(4px);
  }
`;
