export const buildQueryKey = (query: Record<string, unknown>) => {
  return Object.entries(query)
    .map(([key, value]) => {
      if (value instanceof Date) {
        return `${key} = ${value.toISOString()}`;
      }
      if (Array.isArray(value)) {
        return `${key} = ${value.sort().join(',')}`;
      }
      return `${key} = ${value}`;
    })
    .filter(Boolean)
    .join(',');
};
