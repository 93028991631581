import styled from 'styled-components';
import { FeedArticleExpandedInfo } from '../../components/FeedArticleTable/FeedArticleExpandedInfo';

export const FeedArticlePageWrapper = styled.div`
  max-height: 100%;
  overflow: hidden;

  &.full-height {
    height: 100%;
  }
`;

export const FeedArticlePageExpandedInfo = styled(FeedArticleExpandedInfo)`
  max-height: 100%;
  height: 100%;
  border: 0;
  box-shadow: none;
`;
