import { foundation } from '@fcg-tech/regtech-design-system';
import styled from 'styled-components';

export const ActionIconButtonWrapper = styled.div`
  position: relative;
  transition: color 0.2s linear;
  will-change: color;
  display: flex;
  align-items: center;

  color: ${foundation.palette.onyx300.hex};
  cursor: pointer;

  & + & {
    margin-left: 1.2rem;
  }

  & * {
    cursor: pointer;
  }

  &.active,
  &.loading {
    color: ${foundation.palette.opal350.hex};
  }

  .child-wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    transition: color 0.2s linear;
    will-change: color;
  }

  &:hover > .content > .child-wrapper {
    &:before {
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      content: '';
      background-color: #c4c4c4;
      border-radius: 50%;
    }
  }

  &:hover:not(.active) > .content > .child-wrapper {
    color: ${foundation.palette.onyx350.hex};
  }

  &.disabled:not(.active) {
    color: ${foundation.palette.onyx300.hex};
  }

  &.disabled > .content > .child-wrapper:before {
    display: none;
  }

  .content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    & > .child-wrapper > svg {
      z-index: 1;
    }

    & > .action-message {
      margin-left: 0.8rem;
      color: #333;
    }

    &.hidden {
      visibility: hidden;
    }
  }

  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
`;
