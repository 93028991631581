import {
  ConfirmDialog,
  TooltipContextProvider,
  TooltipNext as Tooltip,
  useConfirmDialog,
  useFlash,
  UsersIcon,
} from '@fcg-tech/regtech-components';
import { RichTextAreaProps } from '@fcg-tech/regtech-richtext';
import { MessageLevel } from '@fcg-tech/regtech-types';
import { TeamActions, TeamReadMetadata } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import { Archive, BellRing, Mail, Users } from 'lucide-react';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  useSettings,
  useSettingsActions,
  useTeamActivitiesForArticleActions,
} from '../../api/hooks';
import { MessageKeys } from '../../translations/translationTypes';
import { formatDateTime, formatName } from '../../utils/formatters';
import { AccessControl, useAccessControl } from '../AccessControl';
import { ActionIconButton } from '../buttons';
import { Debug } from '../Debug';
import { ErrorBoundary } from '../ErrorBoundary';
import { FeedArticleReadList } from '../FeedArticleReader/FeedArticleReadList';
import { ChevronIcon } from '../icons';
import { SuspenseLoader } from '../SuspensLoader';
import { TooltipTextIcon } from '../Tooltip';
import {
  FeedArticleTeamMetadataArchived,
  FeedArticleTeamMetadataArchivedLabel,
  FeedArticleTeamMetadataContentOuterWrapper,
  FeedArticleTeamMetadataExpandIconWrapper,
  FeedArticleTeamMetadataHeader,
  FeedArticleTeamMetadataHeaderWrapper,
  FeedArticleTeamMetadataItemLabel,
  FeedArticleTeamMetadataItemLabelNotSubscribed,
  FeedArticleTeamMetadataItemReadCount,
  FeedArticleTeamMetadataWrapper,
} from './FeedArticleTeamMetadata.styles';
import {
  FeedArticleTeamMetadataContent,
  FeedArticleTeamMetadataContentProps,
} from './FeedArticleTeamMetadataContent';

const NOTIFICATION_TIMEOUT = 60 * 60 * 1000; // 1 hour

interface FeedArticleTeamMetadataProps
  extends Pick<RichTextAreaProps, 'portalContainer'> {
  articleId: string;
  teamReadMetadata: TeamReadMetadata;
  isArchived?: boolean;
  hasActivity?: boolean;
  initiallyExpanded?: boolean;
  onTeamTagClick?: FeedArticleTeamMetadataContentProps['onTeamTagClick'];
  onArticleMetadataMutated?: (articleId: string) => void;
  onArchive?: (archived: boolean, articleId: string, teamId: string) => void;
}

export const FeedArticleTeamMetadata: FunctionComponent<
  FeedArticleTeamMetadataProps
> = ({
  articleId,
  teamReadMetadata,
  isArchived,
  hasActivity,
  initiallyExpanded,
  portalContainer,
  onTeamTagClick,
  onArticleMetadataMutated,
  onArchive,
}) => {
  const { t } = useTranslation();
  const addFlash = useFlash();
  const { data: settings } = useSettings();
  const { patchSettings } = useSettingsActions();

  const metadataAllowed = useAccessControl(TeamActions.TeamMetadataGet);
  const archiveAllowed =
    useAccessControl(TeamActions.ArticleArchiveChange) && Boolean(onArchive);

  const [expanded, setExpanded] = useState(
    (metadataAllowed && initiallyExpanded) ?? false,
  );

  const handleHeaderClick = useCallback(
    () => (metadataAllowed ? setExpanded((expanded) => !expanded) : null),
    [metadataAllowed],
  );

  const getFeedArticleReadList = useCallback(
    () => <FeedArticleReadList readMetaData={teamReadMetadata} />,
    [teamReadMetadata],
  );

  const handleArchiveClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onArchive?.(!isArchived, articleId, teamReadMetadata.team.id);
    },
    [articleId, isArchived, onArchive, teamReadMetadata.team.id],
  );

  const [showConfirmNotify, handleConfirmNotifyChoice, confirmNotify] =
    useConfirmDialog();

  const { notifyTeam } = useTeamActivitiesForArticleActions(
    teamReadMetadata.team.id,
    articleId,
  );

  const [notificationSent, setNotificationSent] = useState(
    teamReadMetadata.notified?.notified === true,
  );

  const notifyDisabled =
    Date.now() - NOTIFICATION_TIMEOUT <
    (teamReadMetadata.notified?.created?.getTime() ?? 0);

  const sendNotification = useCallback(async () => {
    try {
      await notifyTeam();
      addFlash({
        content: t(MessageKeys.ArticleMetadataNotifySuccessMessage),
        level: MessageLevel.Success,
      });
      setNotificationSent(true);
      return true;
    } catch (error) {
      addFlash({
        error,
        content: t(MessageKeys.LabelCouldNotSave),
        level: MessageLevel.Error,
      });
      return false;
    }
  }, [addFlash, notifyTeam, t]);

  const handleNotifyClick = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation();
      if (!notifyDisabled) {
        if (settings?.flags?.doNotConfirmSendArticleTeamNotifications) {
          sendNotification();
        } else {
          const result = await confirmNotify();
          if (result !== false) {
            const [, doNotShowAgain] = result;
            if (doNotShowAgain) {
              await patchSettings({
                flags: {
                  doNotConfirmSendArticleTeamNotifications: true,
                },
              });
            }
            sendNotification();
          }
        }
      }
    },
    [
      confirmNotify,
      notifyDisabled,
      patchSettings,
      sendNotification,
      settings?.flags?.doNotConfirmSendArticleTeamNotifications,
    ],
  );

  const archiveChangeContent = useMemo(
    () => (
      <Trans
        i18nKey={
          (isArchived
            ? MessageKeys.ArticleArchivedForTeamButtonTooltip
            : MessageKeys.ArticleUnArchivedForTeamButtonTooltip) as string
        }
        components={{
          teamIcon: <TooltipTextIcon Icon={Users} />,
        }}
        values={{ name: teamReadMetadata.team.name }}
      />
    ),
    [isArchived, teamReadMetadata.team.name],
  );
  const notifyContent = useMemo(() => {
    if (notificationSent && !teamReadMetadata.notified?.createdBy) {
      return null;
    }
    const timeout = Math.ceil(
      (NOTIFICATION_TIMEOUT +
        (teamReadMetadata.notified.created?.getTime() ?? 0) -
        Date.now()) /
        60000,
    );
    return (
      <>
        <Trans
          i18nKey={
            (notificationSent
              ? MessageKeys.ArticleMetadataAlreadyNotififedButtonTooltip
              : MessageKeys.ArticleMetadataNotifyButtonTooltip) as string
          }
          components={{
            teamIcon: <TooltipTextIcon Icon={Users} />,
          }}
          values={{
            name: teamReadMetadata.team.name,
            date: teamReadMetadata.notified?.created
              ? formatDateTime(teamReadMetadata.notified.created, settings)
              : '',
            username: teamReadMetadata.notified?.createdBy
              ? formatName(teamReadMetadata.notified.createdBy)
              : '',
          }}
        />
        <br />
        {timeout > 0
          ? t(MessageKeys.ArticleMetadataNotificationTimeout, {
              minutes: timeout,
            })
          : null}
      </>
    );
  }, [
    notificationSent,
    settings,
    t,
    teamReadMetadata.notified.created,
    teamReadMetadata.notified.createdBy,
    teamReadMetadata.team.name,
  ]);

  return (
    <>
      {showConfirmNotify ? (
        <ConfirmDialog
          title={t(MessageKeys.ArticleMetadataNotifyConfirmTitle)}
          body={t(MessageKeys.ArticleMetadataNotifyConfirmBody, {
            team: teamReadMetadata.team.name,
          })}
          confirmText={t(MessageKeys.ArticleMetadataNotifyConfirmButtonLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          allowDoNotShowAgain
          doNotShowAgainLabel={t(MessageKeys.LabelDoNotShowThisAgain)}
          onChoice={handleConfirmNotifyChoice}
        />
      ) : null}
      <FeedArticleTeamMetadataWrapper
        data-testid={`feed-article-team-metadata-wrapper-${articleId}`}
        className={classNames(
          expanded && 'expanded',
          teamReadMetadata.subscribed && 'subscribed',
          isArchived && 'archived',
        )}
      >
        <FeedArticleTeamMetadataHeader onClick={handleHeaderClick}>
          <FeedArticleTeamMetadataItemLabel>
            <AccessControl requiredPermissions={TeamActions.TeamMetadataGet}>
              <FeedArticleTeamMetadataExpandIconWrapper>
                <ChevronIcon down={expanded} />
              </FeedArticleTeamMetadataExpandIconWrapper>
            </AccessControl>
            <FeedArticleTeamMetadataHeaderWrapper
              className={hasActivity && 'activity'}
            >
              <UsersIcon size="18" />
            </FeedArticleTeamMetadataHeaderWrapper>
            {t(MessageKeys.TeamActivityActionsHeading, {
              team: teamReadMetadata.team.name,
            })}
            {!teamReadMetadata.subscribed ? (
              <FeedArticleTeamMetadataItemLabelNotSubscribed>
                {t(MessageKeys.FeedArticleTeamMetadataNotSubscribed)}
              </FeedArticleTeamMetadataItemLabelNotSubscribed>
            ) : null}
            <Debug withMargin>{teamReadMetadata.team.id}</Debug>
          </FeedArticleTeamMetadataItemLabel>
          {isArchived ? (
            <FeedArticleTeamMetadataArchived>
              <FeedArticleTeamMetadataArchivedLabel>
                {t(MessageKeys.LabelArchived)}
              </FeedArticleTeamMetadataArchivedLabel>
              {archiveAllowed ? (
                <Tooltip placement="top-start" content={archiveChangeContent}>
                  <ActionIconButton
                    onClick={handleArchiveClick}
                    active={isArchived}
                  >
                    <Archive size="20" />
                  </ActionIconButton>
                </Tooltip>
              ) : (
                <Archive size="20" />
              )}
            </FeedArticleTeamMetadataArchived>
          ) : (
            <>
              <Tooltip
                key={
                  teamReadMetadata.notified?.notified ? 'notified' : 'notify'
                }
                placement={
                  teamReadMetadata.notified?.notified ? 'left' : 'top-start'
                }
                content={notifyContent}
              >
                <ActionIconButton
                  active={notificationSent}
                  disabled={notifyDisabled}
                  onClick={handleNotifyClick}
                >
                  <BellRing size={18} />
                </ActionIconButton>
              </Tooltip>
              {archiveAllowed ? (
                <Tooltip placement="top-start" content={archiveChangeContent}>
                  <ActionIconButton
                    onClick={handleArchiveClick}
                    active={isArchived}
                  >
                    <Archive size="20" />
                  </ActionIconButton>
                </Tooltip>
              ) : null}
            </>
          )}
          <Tooltip content={getFeedArticleReadList} placement="left-end">
            <FeedArticleTeamMetadataItemReadCount
              className={classNames(
                teamReadMetadata.readByUsers.length ===
                  teamReadMetadata.team.members?.length && 'active',
              )}
            >
              <Mail size="18" />
              {teamReadMetadata.readByUsers.length}/
              {teamReadMetadata.team.members?.length ?? '0'}
            </FeedArticleTeamMetadataItemReadCount>
          </Tooltip>
        </FeedArticleTeamMetadataHeader>
        <AccessControl requiredPermissions={TeamActions.TeamMetadataGet}>
          {expanded ? (
            <ErrorBoundary>
              <SuspenseLoader>
                <FeedArticleTeamMetadataContentOuterWrapper>
                  <TooltipContextProvider>
                    <FeedArticleTeamMetadataContent
                      articleId={articleId}
                      teamReadMetadata={teamReadMetadata}
                      disabled={isArchived}
                      portalContainer={portalContainer}
                      onTeamTagClick={onTeamTagClick}
                      onArticleMetadataMutated={onArticleMetadataMutated}
                    />
                  </TooltipContextProvider>
                </FeedArticleTeamMetadataContentOuterWrapper>
              </SuspenseLoader>
            </ErrorBoundary>
          ) : null}
        </AccessControl>
      </FeedArticleTeamMetadataWrapper>
    </>
  );
};
