import { useTranslation } from 'react-i18next';
import {
  H1,
  Showcase,
  ShowcaseEntry,
  ShowcasePadddedContent,
  ShowcaseParagraph,
  ShowcaseReel,
} from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeShowcase, Translator } from '../../types';
import { useShowcase } from './useShowcase';
import { ShowCaseContent } from './ShowCaseContent';

export const TeamActionsShowcase: FunctionComponent = () => {
  const { t } = useTranslation();
  const [display, handleExit] = useShowcase(RegeyeShowcase.TeamActions);
  return display ? (
    <Showcase reel={teamActionsShowcaseReel(t)} onExit={handleExit} />
  ) : null;
};

const teamActionsShowcaseReel = (t: Translator): ShowcaseReel => ({
  id: RegeyeShowcase.TeamActions,
  skippable: true,
  waitForQuerySelector: '#export-button',
  entries: [
    {
      content: (entry) => (
        <ShowCaseContent
          entry={entry}
          heading={t(MessageKeys.ShowCaseTeamActionsWelcomeHeading)}
          content={t(MessageKeys.ShowCaseTeamActionsWelcomeBody)}
        />
      ),
      paddedContentStyle: {
        maxWidth: '60rem',
      },
      // illustrationBg: 'rgba(69, 123, 157, 1)',
      // illustrationSrc: 'assets/showcase/newuser/filter-button-illustration.png',
    },
    {
      content: t(MessageKeys.ShowCaseTeamActionsCalendarBody),
      querySelector: '#calendar-toggle-button',
      // illustrationStyle: {
      //   backgroundSize: 'auto 90%',
      // },
      // illustrationBg: 'rgba(69, 123, 157, 1)',
      // illustrationSrc:
      //   'assets/showcase/newuser/filter-button-illustration-2.png',
    },
    {
      content: t(MessageKeys.ShowCaseTeamActionsExportBody),
      querySelector: '#export-button',
      // illustrationStyle: {
      //   backgroundSize: 'contain',
      // },
      // illustrationBg: 'rgba(69, 123, 157, 1)',
      // illustrationSrc:
      //   'assets/showcase/newuser/filter-button-illustration-2.png',
    },
  ],
});
