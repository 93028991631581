import { DataTableRow, DataTableRowProps } from '@fcg-tech/regtech-datatable';
import { Tag } from '@fcg-tech/regtech-types/regeye';
import { useMouseHover } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { DataTableActionBar } from '../datatable/DataTableActionBar';

export type RowData = Pick<Tag, 'id' | 'name' | 'description' | 'active'>;
export type ColumnKeys = 'name' | 'description' | 'edit' | 'delete';

interface TagTableRowProps extends DataTableRowProps<RowData> {
  onRemove?: (tagId: string) => Promise<void>;
  onEdit?: (tagId: string) => void;
}

export const TagTableRow: FunctionComponent<TagTableRowProps> = ({
  onRemove,
  onEdit,
  ...props
}) => {
  const tag = props.row.original;
  const [isRemoving, setIsRemoving] = useState(false);
  const handleRemoveClick = useCallback(async () => {
    setIsRemoving(true);
    try {
      await onRemove?.(tag.id);
    } catch (e) {
      console.error(e);
    }
    setIsRemoving(false);
  }, [tag.id, onRemove]);

  const handleEditClick = useCallback(() => onEdit?.(tag.id), [onEdit, tag.id]);

  const { isMouseOver, ...hoverProps } = useMouseHover();

  return (
    <DataTableRow {...props} {...hoverProps}>
      {isMouseOver ? (
        <div className="tr-hover-container" data-kind="hover">
          <DataTableActionBar
            isRemoving={isRemoving}
            onRemove={handleRemoveClick}
            onEdit={handleEditClick}
          />
        </div>
      ) : null}
    </DataTableRow>
  );
};
