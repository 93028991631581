import { useTranslation } from 'react-i18next';
import {
  FormRow,
  FormRowReadOnlyValue,
  H3,
  InputCheckbox,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  PrimaryButton,
  TextField,
} from '@fcg-tech/regtech-components';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback } from 'react';
import { useCompanies } from '../../../api/hooks';
import { MessageKeys } from '../../../translations/translationTypes';
import { ModalFormLabel } from '../../modals';
import {
  SetupWizardModalFormWrapper,
  SetupWizardModalPage,
} from '../SetupWizard.styles';
import { WizardData, WizardPage } from './wizardTypes';

interface AdminUserSetupWizardTeamPageProps {
  currentPage: WizardPage;
  wizardData: Partial<WizardData>;
  onChange?: (wizardData: Partial<WizardData>) => void;
  onNext: () => void;
}

export const AdminUserSetupWizardTeamPage: FunctionComponent<AdminUserSetupWizardTeamPageProps> =
  ({ wizardData, currentPage, onChange, onNext }) => {
    const { t } = useTranslation();
    const { data: companies } = useCompanies();

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) =>
        onChange({
          ...wizardData,
          companyId: companies[0].id,
          [event.target.attributes.getNamedItem('name').value]:
            event.target.attributes.getNamedItem('type')?.value === 'checkbox'
              ? event.target.checked
              : event.target.value,
        }),
      [companies, onChange, wizardData],
    );

    return (
      <SetupWizardModalPage
        className={classNames(
          currentPage > WizardPage.CreateTeam && 'after',
          currentPage < WizardPage.CreateTeam && 'before',
        )}
      >
        <ModalBody>
          <H3>
            {t(MessageKeys.WizardCreateYourFirstTeam)}
          </H3>
          <SetupWizardModalFormWrapper>
            <FormRow>
              <ModalFormLabel>
                {t(MessageKeys.WizardLabelTeamName)}
              </ModalFormLabel>
              <TextField
                name="teamName"
                value={wizardData.teamName}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <ModalFormLabel>
                {t(MessageKeys.LabelCompany)}
              </ModalFormLabel>
              <FormRowReadOnlyValue>{companies[0]?.name}</FormRowReadOnlyValue>
            </FormRow>
            <FormRow>
              <ModalFormLabel>
                {t(MessageKeys.AdministrationTeamDetailsAddMeToThisTeam)}
              </ModalFormLabel>
              <InputCheckbox
                checked={Boolean(wizardData.addMe)}
                name="addMe"
                onChange={handleChange}
              />
            </FormRow>
          </SetupWizardModalFormWrapper>
        </ModalBody>
        <ModalFooter>
          <ModalButtonRow>
            <PrimaryButton disabled={!wizardData.teamName} onClick={onNext}>
              {t(MessageKeys.LabelNext)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalFooter>
      </SetupWizardModalPage>
    );
  };
