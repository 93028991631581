import styled from 'styled-components';

export const ActionsArticlesExportModalFilenameRow = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2rem;
  min-width: 30rem;
`;

export const ActionsArticlesExportModalFilenameExtension = styled.span`
  margin-left: 0.5rem;
  font-weight: 600;
`;
