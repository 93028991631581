import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@fcg-tech/regtech-components';
import { RichTextAreaProps } from '@fcg-tech/regtech-richtext';
import {
  Comment,
  CommentDraft,
  CommentDraftIdent,
  User,
} from '@fcg-tech/regtech-types/regeye';
import { format } from 'date-fns';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Descendant, Editor } from 'slate';
import { useSettings } from '../../api/hooks';
import { TABLE_DATE_FORMAT } from '../../constants';
import { MessageKeys } from '../../translations/translationTypes';
import { formatDateTime, formatName } from '../../utils/formatters';
import { useArticleContext } from '../ArticleContext/ArticleContext';
import { useTeamContext } from '../TeamContext/TeamContext';
import {
  CommentFieldActionRow,
  CommentFieldCancelButton,
  CommentFieldEditDescription,
  CommentFieldEditDescriptionHeading,
  CommentFieldFoldingNotification,
  CommentFieldSubmitButton,
  CommentFieldWrapper,
  CommentHint,
} from './CommentField.styles';

const CommentTextField = React.lazy(() => import('./CommentTextField'));

interface CommentFieldProps extends Pick<RichTextAreaProps, 'portalContainer'> {
  draft?: CommentDraft;
  draftIdent?: CommentDraftIdent;
  updateDraft: (
    draftIdent: CommentDraftIdent,
    data: Partial<CommentDraft>,
  ) => void;
  deleteDraft: (draftIdent: CommentDraftIdent) => void;
  disabled?: boolean;
  replyTo?: Comment;
  edit?: Comment;
  inputRef?: React.MutableRefObject<HTMLDivElement>;
  teamMembers?: Array<User>;
  onSubmit: (plainText: string, markup: Array<Descendant>) => Promise<unknown>;
  onCancel?: () => void;
}

export const CommentField: FunctionComponent<CommentFieldProps> = ({
  draft,
  draftIdent,
  updateDraft,
  deleteDraft,
  disabled,
  replyTo,
  edit,
  inputRef,
  teamMembers,
  portalContainer,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const articleContext = useArticleContext();
  const teamContext = useTeamContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const draftRestored = useMemo(() => Boolean(draft?.content), []);

  const { data: settings } = useSettings({ suspense: false });

  const [commentValue, setCommentValue] = useState<{
    content: string;
    markup: Array<Descendant> | undefined;
  }>(
    draft?.content && draft?.markup
      ? {
          content: draft.content,
          markup: draft.markup,
        }
      : undefined,
  );
  const commentValueRef = useRef<typeof commentValue>(commentValue);
  commentValueRef.current = commentValue;

  const [submitting, setSubmitting] = useState(false);

  const editor = useRef<Editor>();

  const handleSubmit = useCallback(async () => {
    const { content, markup } = commentValue ?? {};
    if (!content?.length) {
      return;
    }
    setSubmitting(true);
    try {
      await onSubmit?.(content, markup);

      deleteDraft(draftIdent);
    } catch (err) {
      setSubmitting(false);
    }
  }, [commentValue, deleteDraft, draftIdent, onSubmit]);

  const handleDiscard = useCallback(
    (setOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
      if (draft?.id) {
        setOpen(false);
        setCommentValue(undefined);
        onCancel?.();
      }
    },
    [draft?.id, onCancel],
  );

  const handleChange = useCallback(
    (content: string, markup: Array<Descendant>) => {
      setCommentValue({ content, markup });
      updateDraft(draftIdent, { content, markup, active: true });
    },
    [draftIdent, updateDraft],
  );

  const handleLoad = useCallback((e: Editor) => {
    editor.current = e;
  }, []);

  return (
    <CommentFieldWrapper>
      <CommentFieldFoldingNotification
        in={draftRestored}
        hideMS={5000}
        delayMS={
          500 /* Suspense messes with transitions. Wait a bit before starting */
        }
      >
        {t(draft?.updated
          ? MessageKeys.CommentFieldDraftFromDateRestored
          : MessageKeys.CommentFieldDraftRestored, {
          date: draft?.updated ? format(draft.updated, TABLE_DATE_FORMAT) : 'N/A'
        })}
      </CommentFieldFoldingNotification>
      <CommentTextField
        initialValue={draft?.content}
        initialMarkup={draft?.markup}
        inputRef={inputRef}
        teamMembers={teamMembers}
        disabled={disabled || submitting}
        portalContainer={portalContainer}
        onChange={handleChange}
        onLoad={handleLoad}
      />
      <CommentFieldActionRow>
        <CommentHint>
          {t(MessageKeys.CommentHintMentions)}
        </CommentHint>
        {onCancel && (replyTo || edit) ? (
          <CommentFieldCancelButton
            onClick={onCancel}
            disabled={disabled || submitting}
          >
            {t(replyTo
              ? MessageKeys.CommentFieldCancelReplyLabel
              : MessageKeys.CommentFieldCancelEditLabel)}
          </CommentFieldCancelButton>
        ) : null}
        <CommentFieldSubmitButton
          onClick={handleSubmit}
          title={t(
            replyTo
              ? MessageKeys.CommentFieldSubmitReplyLabel
              : MessageKeys.CommentFieldSubmitLabel,
          )}
          disabled={disabled || submitting || !commentValue?.content?.length}
        >
          {(setOpen) => (
            <Menu narrow alignRight>
              <MenuItem onClick={() => handleDiscard(setOpen)}>
                {t(MessageKeys.CommentFieldDeleteDraft)}
              </MenuItem>
            </Menu>
          )}
        </CommentFieldSubmitButton>
      </CommentFieldActionRow>
      {replyTo ? (
        <CommentFieldEditDescription>
          <CommentFieldEditDescriptionHeading>
            {t(MessageKeys.LabelReplyingTo, {
              name: formatName(replyTo.author, settings?.userNamePreference)
            })}
          </CommentFieldEditDescriptionHeading>
          {replyTo.comment}
        </CommentFieldEditDescription>
      ) : null}
      {edit ? (
        <CommentFieldEditDescription>
          <CommentFieldEditDescriptionHeading>
            {t(MessageKeys.CommentEditingYourCommentFrom, {
              date: formatDateTime(edit.created, settings)
            })}
          </CommentFieldEditDescriptionHeading>
        </CommentFieldEditDescription>
      ) : null}
    </CommentFieldWrapper>
  );
};
