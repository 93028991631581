import React, { FunctionComponent, useCallback, useRef, PropsWithChildren } from 'react';

import { useToggle } from '../../miscHooks';
import { useClickOutside } from '../ClickOutsideListener';
import { AbsoluteMenu, MenuButton } from '../Menu';
import { ButtonProps, PrimaryButton, SecondaryButton } from './Button';
import { OptionsButtonArrow, OptionsButtonWrapper } from './Button.styles';

type OptionsButtonItem = {
  label: string;
  value?: unknown;
  callback: (value: unknown) => void;
};
export interface OptionsButtonProps extends Omit<ButtonProps, 'type'> {
  secondary?: boolean;
  items: Array<OptionsButtonItem>;
}

export const OptionsButton: FunctionComponent<PropsWithChildren<OptionsButtonProps>> = ({
  children,
  className,
  secondary,
  items,
  onClick,
  ...props
}) => {
  const [isOpen, , toggleIsOpen] = useToggle(false);
  const ref = useRef<HTMLDivElement>();

  useClickOutside(ref, isOpen ? toggleIsOpen : null);

  const Button = secondary ? SecondaryButton : PrimaryButton;

  return (
    <OptionsButtonWrapper ref={ref}>
      <Button {...props} className="button" onClick={onClick}>
        {children}
      </Button>
      <Button className="arrow-button" onClick={toggleIsOpen}>
        <OptionsButtonArrow />
      </Button>
      {isOpen ? (
        <AbsoluteMenu className="top right">
          {items.map((props) => (
            <OptionsButtonMenuButton key={props.label} {...props} />
          ))}
        </AbsoluteMenu>
      ) : null}
    </OptionsButtonWrapper>
  );
};

export const OptionsButtonMenuButton = ({
  label,
  value,
  callback,
}: OptionsButtonItem): JSX.Element => {
  const handleClick = useCallback(() => callback?.(value), [callback, value]);
  return <MenuButton onClick={handleClick}>{label}</MenuButton>;
};
