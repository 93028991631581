import { classNames } from '@fcg-tech/regtech-utils';
import { Check, CheckSquare, Square } from 'lucide-react';
import React from 'react';
import { FormGridToggleWrapper } from './FormGridToggle.styles';

const size = '18';

export const FormGridToggle = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>(({ className, ref, as, label, ...props }, inputRef) => {
  return (
    <FormGridToggleWrapper
      className={classNames(className, props.disabled && 'disabled')}
    >
      <input {...props} ref={inputRef} type="checkbox" />

      <div className="inner-wrapper">
        {props.checked ? <Check size="12" /> : null}
      </div>
    </FormGridToggleWrapper>
  );
});
