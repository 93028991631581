import { FeedArticleTableColumns, TableType } from './types';

export const TABLE_DATE_FORMAT = 'yyyy-MM-dd';

export const SLOW_FOCUS_REVALIDATE_INTERVAL = 1000 * 60 * 5;
export const MEDIUM_FOCUS_REVALIDATE_INTERVAL = 1000 * 60 * 2;

export const DEFAULT_PAGE_SIZE = 50;

export const IMPORTANCE_COLOR = [
  '#ddd',
  'rgba(118, 249, 255, 1)',
  'rgba(78, 219, 255, 1)',
  'rgba(0, 209, 0, 1)',
  'rgba(255, 155, 33, 1)',
  'rgba(255, 85, 23, 1)',
];

export const NOTIFICATIONS_REGEYE = 'regeye';

export const PORTAL_ROOT_SELECTOR = '#portal-root';

export const MOBILE_ARTICLE_ROW_TITLE_MAX_LENGTH = 200;

export const STORED_RICH_TEXT_DRAFTS_KEY = 'regeye-stored-drafs_v2';
export const STORED_FILTER_DRAFTS_KEY = 'regeye-stored-filter-drafts_v1';

export const customTagConversion: Record<string, string | true> = {
  'UN Affairs': true,
  EEAS: true,
  ITRE: true,
  'LEX ALERTS': 'LEX Alerts',
};

export const ALL_COLUMNS = {
  [TableType.Generic]: [
    FeedArticleTableColumns.Publisher,
    FeedArticleTableColumns.Type,
    FeedArticleTableColumns.Tags,
    FeedArticleTableColumns.Title,
    FeedArticleTableColumns.Content,
    FeedArticleTableColumns.Date,
  ],
  [TableType.Actions]: [
    FeedArticleTableColumns.Teams,
    FeedArticleTableColumns.Publisher,
    FeedArticleTableColumns.Type,
    FeedArticleTableColumns.Tags,
    FeedArticleTableColumns.TeamTags,
    FeedArticleTableColumns.Title,
    FeedArticleTableColumns.Content,
    FeedArticleTableColumns.Importance,
    FeedArticleTableColumns.DueDate,
    FeedArticleTableColumns.Date,
  ],
  [TableType.ActionLog]: [
    FeedArticleTableColumns.Teams,
    FeedArticleTableColumns.Publisher,
    FeedArticleTableColumns.Type,
    FeedArticleTableColumns.Tags,
    FeedArticleTableColumns.Title,
    FeedArticleTableColumns.Content,
    FeedArticleTableColumns.Action,
    FeedArticleTableColumns.ActionDate,
    FeedArticleTableColumns.Date,
  ],
};
