import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import { useAuthContext } from '@fcg-tech/regtech-auth';
import { FunctionComponent, useCallback } from 'react';
import { SWRConfig } from 'swr';

const cache = new Map();

const provider = () => {
  return cache;
};

export const SwrConfigWrapper: FunctionComponent<EmptyPropsWithChildren> = ({
  children,
}) => {
  const { reAuthenticate, isAuthenticated } = useAuthContext();

  const getIsOnline = useCallback(() => isAuthenticated, [isAuthenticated]);
  const handleSWRError = useCallback(
    (error: Error | Response) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(error);
      }
      if ('status' in error && error.status === 401) {
        reAuthenticate?.();
      }
    },
    [reAuthenticate],
  );

  return (
    <SWRConfig
      key="swr-config"
      value={{
        focusThrottleInterval: 30000,
        onError: handleSWRError,
        isOnline: getIsOnline,
        isVisible: getIsOnline,
        provider,
      }}
    >
      {children}
    </SWRConfig>
  );
};
