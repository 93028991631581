import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { SideBarContextProvider } from '@fcg-tech/regtech-components';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAllTeams,
  useCompanies,
  useCompanyTeams,
  useInvitations,
} from '../../api/hooks';
import { GlobalActions } from '../../api/schema';
import { DevMode } from '../../components/dev';
import { RegeyeGlobalStyle } from '../../components/globalStyle';
import { useIamContext } from '../../components/IamContext';
import { useInitContext } from '../../components/InitContext';
import { MainLayout } from '../../components/MainLayout';
import { SetupWizard } from '../../components/SetupWizard';
import { MarkAsReadShowcase } from '../../components/showcases/MarkAsReadShowcase';
import { NewTabsShowcase } from '../../components/showcases/NewTabsShowcase';
import { NewUserShowcase } from '../../components/showcases/NewUserShowcase';
import { WhatsNewAugustShowcase } from '../../components/showcases/WhatsNewAugustShowcase';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routeFragments, routes } from '../../routes';
import { canCompanyTeamList } from '../../utils/iamHelpers';
import { getSetupStatus, SetupStatus } from '../../utils/setupStatus';
import { FallbackNoCompaniesPage } from '../fallback';
import { AppRoutes } from './AppRoutes';

export const AuthenticatedApp: FunctionComponent = () => {
  const navigate = useNavigate();

  const { error: initDataError } = useInitContext();
  const iamContext = useIamContext();
  const { data: companies } = useCompanies(
    !iamContext.globalPermissions.includes(GlobalActions.CompanyList),
  );
  const { data: invitations } = useInvitations();
  const { data: teams } = useAllTeams();
  // Only load teams for user company if user is member of exactly one company. Mutli-company users are assumed to know how the system works
  // and do not need to see the setup wizard
  const { data: singleCompanyTeams } = useCompanyTeams(
    companies?.length === 1 && canCompanyTeamList(companies[0].id, iamContext)
      ? companies[0].id
      : null,
  );

  const setupStatus = useMemo(
    () =>
      getSetupStatus(
        companies,
        teams,
        singleCompanyTeams,
        invitations,
        iamContext,
      ),
    // We only want to check this once. Due to suspense, we will not reach here until all required data is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [setupWizardClosed, setSetupWizardClosed] = useState(false);

  const onRequestSetupWizardClose = useCallback(() => {
    setSetupWizardClosed(true);
    navigate(routes.dashboard);
  }, [navigate]);

  useEffect(() => {
    if (
      !setupWizardClosed &&
      (setupStatus === SetupStatus.HasNoTeams ||
        setupStatus === SetupStatus.HasInvites)
    ) {
      navigate(
        constructUrl(routes.personalProfileSetting, {
          settingKey: routeFragments.personalProfileTeams,
        }),
      );
    }
  }, [navigate, setupStatus, setupWizardClosed]);

  const main = (
    <MainLayout>
      <NewUserShowcase />
      <NewTabsShowcase />
      <MarkAsReadShowcase />
      <WhatsNewAugustShowcase />
      {initDataError ? (
        <FallbackNoCompaniesPage />
      ) : setupStatus === SetupStatus.None || setupWizardClosed ? (
        <AppRoutes />
      ) : (
        <SetupWizard
          setupStatus={setupStatus}
          singleCompanyId={companies?.[0]?.id}
          onRequestClose={onRequestSetupWizardClose}
        />
      )}
    </MainLayout>
  );

  return (
    <SuspenseLoader className="maximized">
      <SideBarContextProvider>
        <RegeyeGlobalStyle />
        <SuspenseLoader className="maximized">
          {process.env.NODE_ENV === 'development' ? (
            <DevMode>{main}</DevMode>
          ) : (
            main
          )}
        </SuspenseLoader>
      </SideBarContextProvider>
    </SuspenseLoader>
  );
};
