import { ChevronRightIcon, Portal } from '@fcg-tech/regtech-components';
import {
  FeedArticle,
  FeedArticleMetadataMutationCallbacks,
  PageDirection,
} from '@fcg-tech/regtech-types/regeye';
import { classNames, useDevice } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState, PropsWithChildren } from 'react';
import { useSwipeable } from 'react-swipeable';
import { SwipeIcon } from '../icons';
import { Tooltip } from '../Tooltip';
import {
  ChevronLeftIcon,
  FeedArticleOverlayModal,
  FeedArticleOverlayModalSwiper,
  FeedArticleOverlayNextButton,
  FeedArticleOverlayPrevButton,
  FeedArticleOverlaySuspenseLoader,
} from './FeedArticleOverlay.styles';
import { FeedArticleOverlayContent } from './FeedArticleOverlayContent';
import { FeedArticleOverlayLoader } from './FeedArticleOverlayLoader';
import {
  FeedArticleOverlaySheetBackground,
  FeedArticleOverlaySheetSheet,
} from './FeedArticleOverlaySheet.styles';

interface FeedArticleOverlayBaseProps
  extends FeedArticleMetadataMutationCallbacks {
  previousArticleId?: string | PageDirection;
  nextArticleId?: string | PageDirection;

  nextTooltipMessage?: string;
  prevTooltipMessage?: string;

  onChangeArticle?: (articleId: string | PageDirection) => void;
  onRequestClose?: () => void;
  onArticleMetadataMutated?: (articleId: string) => void;
}

type FeedArticleOverlayProps =
  | (FeedArticleOverlayBaseProps & {
      articleId: string;
      feedArticle?: never;
    })
  | (FeedArticleOverlayBaseProps & {
      articleId?: never;
      feedArticle: FeedArticle;
    });

export const FeedArticleOverlay: FunctionComponent<PropsWithChildren<FeedArticleOverlayProps>> = ({
  articleId,
  feedArticle,
  previousArticleId,
  nextArticleId,
  nextTooltipMessage,
  prevTooltipMessage,
  children,
  onChangeArticle,
  onRequestClose,
  onArchive,
  onBookmark,
  onMarkAsRead,
  onArticleMetadataMutated,
}) => {
  const { isMobile } = useDevice();

  const { ref: swipeRef } = useSwipeable({
    preventDefaultTouchmoveEvent: true,
    onSwiped: (eventData) => {
      const target = eventData.event.target;
      // Weird bug in react-swipeable that causes clicks outside the swipable area to count as swipes. Occurs only after at least one normal swipe. After
      // Also, at the current version swipeRef is an old-school callback, so cannot use it to match against event.target
      if (
        (target as HTMLElement)
          ?.getAttribute('class')
          ?.split(' ')
          ?.includes('swipe')
      ) {
        if (eventData?.dir === 'Down') {
          onChangeArticle(null);
        } else if (eventData.dir === 'Left') {
          if (nextArticleId) {
            onChangeArticle(nextArticleId);
          }
        } else if (eventData.dir === 'Right') {
          if (previousArticleId) {
            onChangeArticle(previousArticleId);
          }
        }
      }
    },
    trackMouse: process.env.NODE_ENV !== 'production',
  });

  const [swipeDisabled, setSwipeDisabled] = useState(false);

  const handlePrevArticle = useCallback(
    () => onChangeArticle(previousArticleId),
    [onChangeArticle, previousArticleId],
  );

  const handleNextArticle = useCallback(
    () => onChangeArticle(nextArticleId),
    [onChangeArticle, nextArticleId],
  );

  return !isMobile ? (
    <FeedArticleOverlayModal
      onHide={onRequestClose}
      floatingCloseButton
      className={classNames()}
    >
      <Tooltip placement="right" content={prevTooltipMessage}>
        <FeedArticleOverlayPrevButton onClick={handlePrevArticle}>
          <ChevronLeftIcon />
        </FeedArticleOverlayPrevButton>
      </Tooltip>
      <Tooltip placement="right" content={nextTooltipMessage}>
        <FeedArticleOverlayNextButton onClick={handleNextArticle}>
          <ChevronRightIcon />
        </FeedArticleOverlayNextButton>
      </Tooltip>
      {children ? (
        children
      ) : (
        <FeedArticleOverlaySuspenseLoader>
          {articleId && !feedArticle ? (
            <FeedArticleOverlayLoader articleId={articleId}>
              <FeedArticleOverlayContent
                onArchive={onArchive}
                onBookmark={onBookmark}
                onMarkAsRead={onMarkAsRead}
                onArticleMetadataMutated={onArticleMetadataMutated}
              />
            </FeedArticleOverlayLoader>
          ) : null}
          {feedArticle ? (
            <FeedArticleOverlayContent
              feedArticle={feedArticle}
              onArchive={onArchive}
              onBookmark={onBookmark}
              onMarkAsRead={onMarkAsRead}
              onArticleMetadataMutated={onArticleMetadataMutated}
            />
          ) : null}
        </FeedArticleOverlaySuspenseLoader>
      )}
    </FeedArticleOverlayModal>
  ) : (
    <Portal container="portal-root">
      <FeedArticleOverlaySheetBackground>
        {previousArticleId ? (
          <FeedArticleOverlaySheetSheet
            key={previousArticleId}
            className="offscreen-previous"
          ></FeedArticleOverlaySheetSheet>
        ) : null}
        <FeedArticleOverlaySheetSheet
          key={feedArticle?.internalId ?? articleId}
          onRequestClose={swipeDisabled ? null : onRequestClose}
        >
          {children ? (
            children
          ) : (
            <FeedArticleOverlaySuspenseLoader>
              {articleId && !feedArticle ? (
                <FeedArticleOverlayLoader articleId={articleId}>
                  <FeedArticleOverlayContent
                    setSwipeDisabled={setSwipeDisabled}
                    onArchive={onArchive}
                    onBookmark={onBookmark}
                    onMarkAsRead={onMarkAsRead}
                    onArticleMetadataMutated={onArticleMetadataMutated}
                  />
                </FeedArticleOverlayLoader>
              ) : null}
              {feedArticle ? (
                <FeedArticleOverlayContent
                  feedArticle={feedArticle}
                  setSwipeDisabled={setSwipeDisabled}
                  onArchive={onArchive}
                  onBookmark={onBookmark}
                  onMarkAsRead={onMarkAsRead}
                  onArticleMetadataMutated={onArticleMetadataMutated}
                />
              ) : null}
            </FeedArticleOverlaySuspenseLoader>
          )}
        </FeedArticleOverlaySheetSheet>
        {nextArticleId ? (
          <FeedArticleOverlaySheetSheet
            key={nextArticleId}
            className="offscreen-next"
          ></FeedArticleOverlaySheetSheet>
        ) : null}
      </FeedArticleOverlaySheetBackground>
      {!swipeDisabled ? (
        <FeedArticleOverlayModalSwiper ref={swipeRef} className="swipe">
          <SwipeIcon />
        </FeedArticleOverlayModalSwiper>
      ) : null}
    </Portal>
  );
};
