import {
  FormGridButtonRow,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  MultiSelectOption,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  useFlash,
} from '@fcg-tech/regtech-components';
import { MessageLevel } from '@fcg-tech/regtech-types';
import {
  FiltersApi,
  FilterType,
  StoredFilter,
  Team,
} from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import { PromisePool } from '@supercharge/promise-pool';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useRecoilValue } from 'recoil';
import { useApi } from '../../api/apiUtils';
import {
  getStoredFilters,
  useAllTeams,
  useStoredFilterActions,
  useTeamStoredFilters,
} from '../../api/hooks';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { ModalForm, ModalFormWrapper } from '../../components/modals';
import { portalRoot } from '../../components/Portal';
import {
  isSelectAllOptionData,
  multiSelectInLabelStyles,
  SelectAllOptionData,
  singleSelectInLabelStyles,
} from '../../components/selectStyles';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { team } from '../../state/teamState';
import { MessageKeys } from '../../translations/translationTypes';
import { isHTMLElement } from '../../utils/domUtils';

interface TeamSettingsCopyTeamFilterModalProps {
  targetTeamId: string;
  onRequestClose: (settingsCopied: boolean) => void;
}
export const TeamSettingsCopyTeamFilterModal: FunctionComponent<
  TeamSettingsCopyTeamFilterModalProps
> = ({ onRequestClose, ...args }) => {
  const { t } = useTranslation();
  const handleHide = useCallback(() => onRequestClose(false), [onRequestClose]);
  const selectedTeam = useRecoilValue(team(args.targetTeamId));

  return (
    <Modal onHide={handleHide}>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.TeamSettingsCopyTeamFiltersLabel, {
            team: selectedTeam.name,
          })}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper>
          <SuspenseLoader>
            <ErrorBoundary>
              <TeamSettingsCopyTeamFilterModalContents
                {...args}
                onRequestClose={onRequestClose}
              />
            </ErrorBoundary>
          </SuspenseLoader>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
export const TeamSettingsCopyTeamFilterModalContents: FunctionComponent<
  TeamSettingsCopyTeamFilterModalProps
> = ({ targetTeamId, onRequestClose }) => {
  const { t } = useTranslation();
  const filtersApi = useApi<FiltersApi>('FiltersApi');
  const { data: teams } = useAllTeams();

  const filterTypeOptions = useMemo<Array<ReactSelectOption<FilterType>>>(
    () => [
      {
        value: FilterType.ArticleFilter,
        label: t(MessageKeys.FilterTypeArticleFilter),
      },

      {
        value: FilterType.InsightFilter,
        label: t(MessageKeys.FilterTypeActionsFilter),
      },
    ],
    [t],
  );

  const [filterType, setFilterType] = useState<ReactSelectOption<FilterType>>(
    filterTypeOptions[0],
  );
  const { data: originalStoredFilters } = useTeamStoredFilters(
    filterType.value,
    targetTeamId,
  );
  const { saveFilter } = useStoredFilterActions({ debounceMutate: 200 });
  const [sourceTeam, setSourceTeam] = useState<ReactSelectOption<Team>>();
  const addFlash = useFlash();

  const [loading, setLoading] = useState(false);

  const teamsOptions: Array<ReactSelectOption<Team>> = useMemo(
    () =>
      teams
        .filter((team) => team.id !== targetTeamId)
        .map<ReactSelectOption<Team>>((team) => ({
          label: `${team.company.name} - ${team.name}`,
          value: team,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [targetTeamId, teams],
  );

  const [teamFiltersOptions, setTeamFiltersOptions] = useState<
    Array<ReactSelectOption<StoredFilter | SelectAllOptionData>>
  >([]);

  const [selectedTeamFilters, setSelectedTeamFilters] = useState<
    Array<ReactSelectOption<StoredFilter>>
  >([]);

  const updateTeamFiltersOptions = useCallback(
    async (filterType: FilterType, sourceTeamId: string) => {
      if (filterType && sourceTeamId) {
        setLoading(true);
        try {
          const storedFilters = await getStoredFilters(
            filterType,
            sourceTeamId,
            filtersApi,
          );
          const updatedTeamFiltersOptions = storedFilters.map<
            ReactSelectOption<StoredFilter | SelectAllOptionData>
          >((storedFilter) => ({
            label: storedFilter.name,
            value: storedFilter,
          }));
          updatedTeamFiltersOptions.splice(0, 0, {
            label: t(MessageKeys.LabelSelectAll),
            value: { _selectAll_: true },
          });

          setTeamFiltersOptions(updatedTeamFiltersOptions);
        } catch (err) {
          addFlash({
            level: MessageLevel.Error,
            content: t(MessageKeys.ErrorGeneric),
          });
        } finally {
          setLoading(false);
        }
      }
    },
    [addFlash, filtersApi, t],
  );

  const handleSourceTeamChange = useCallback(
    async (option: ReactSelectOption<Team>) => {
      setSourceTeam(option);
      updateTeamFiltersOptions(filterType.value, option.value.id);
    },
    [filterType, updateTeamFiltersOptions],
  );

  const handleFilterTypeChange = useCallback(
    (option: ReactSelectOption<FilterType>) => {
      setFilterType(option);
      updateTeamFiltersOptions(option.value, sourceTeam?.value?.id);
    },
    [sourceTeam?.value?.id, updateTeamFiltersOptions],
  );

  const handleSelectedTeamFilterChange = useCallback(
    (
      options: Array<
        ReactSelectOption<StoredFilter | SelectAllOptionData>
      > | null,
    ) => {
      if (options?.find(({ value }) => isSelectAllOptionData(value))) {
        setSelectedTeamFilters(
          teamFiltersOptions.filter(
            (option) => !isSelectAllOptionData(option.value),
          ) as Array<ReactSelectOption<StoredFilter>>,
        );
        if (isHTMLElement(document.activeElement)) {
          document.activeElement.blur();
        }
      } else {
        setSelectedTeamFilters(
          options as Array<ReactSelectOption<StoredFilter>>,
        );
      }
    },
    [teamFiltersOptions],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const { results, errors } = await PromisePool.withConcurrency(2)
          .for(selectedTeamFilters)
          .process((storedFilter) => {
            const existingFilterWithSameName = originalStoredFilters.find(
              (existingFilter) =>
                existingFilter.name === storedFilter.value.name,
            );
            return saveFilter(
              storedFilter.value.filter,
              existingFilterWithSameName
                ? `${storedFilter.value.name}-copy`
                : storedFilter.value.name,
              filterType.value,
              targetTeamId,
            );
          });

        if (errors?.length) {
          addFlash({
            level:
              results.length === 0 ? MessageLevel.Error : MessageLevel.Warning,
            content: t(
              results.length === 0
                ? MessageKeys.TeamSettingsCopyFiltersFailed
                : MessageKeys.TeamSettingsCopyFiltersPartiallySuccessful,
              {
                filters: errors
                  .map((option) => option.item.value.name)
                  .join(', '),
              },
            ),
          });
        } else if (results.length > 0) {
          addFlash({
            level: MessageLevel.Success,
            content: t(MessageKeys.TeamSettingsCopyFiltersSuccessful),
          });
        }
        onRequestClose(true);
      } catch (e) {
        setLoading(false);
        addFlash({
          level: MessageLevel.Error,
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    },
    [
      addFlash,
      filterType,
      onRequestClose,
      originalStoredFilters,
      saveFilter,
      selectedTeamFilters,
      t,
      targetTeamId,
    ],
  );

  return (
    <ModalForm onSubmit={handleSubmit}>
      <FormGridRow className={classNames('with-select', filterType && 'valid')}>
        <FormGridLabel>{t(MessageKeys.LabelFilterType)}</FormGridLabel>
        <FormGridControl>
          <Select
            options={filterTypeOptions}
            value={filterType}
            styles={singleSelectInLabelStyles}
            menuPortalTarget={portalRoot}
            isDisabled={loading}
            openMenuOnFocus
            onChange={handleFilterTypeChange}
          />
        </FormGridControl>
      </FormGridRow>
      <FormGridRow className={classNames('with-select', sourceTeam && 'valid')}>
        <FormGridLabel>
          {t(MessageKeys.TeamSettingsCopyFromLabel)}
        </FormGridLabel>
        <FormGridControl>
          <Select
            options={teamsOptions}
            value={sourceTeam}
            styles={singleSelectInLabelStyles}
            menuPortalTarget={portalRoot}
            isDisabled={loading}
            openMenuOnFocus
            onChange={handleSourceTeamChange}
          />
        </FormGridControl>
      </FormGridRow>
      {sourceTeam ? (
        <FormGridRow
          className={classNames(
            'with-select',
            selectedTeamFilters?.length && 'valid',
          )}
        >
          <FormGridLabel>
            {t(MessageKeys.TeamSettingsSelectTeamFilters)}
          </FormGridLabel>
          <FormGridControl>
            <Select
              options={teamFiltersOptions}
              value={selectedTeamFilters}
              isMulti
              closeMenuOnSelect={false}
              menuPortalTarget={portalRoot}
              styles={multiSelectInLabelStyles}
              isDisabled={loading}
              components={{ Option: MultiSelectOption }}
              hideSelectedOptions={false}
              openMenuOnFocus
              onChange={handleSelectedTeamFilterChange}
            />
          </FormGridControl>
        </FormGridRow>
      ) : null}
      <FormGridButtonRow>
        <SecondaryButton
          type="button"
          onClick={() => onRequestClose(false)}
          disabled={loading}
        >
          {t(MessageKeys.LabelCancel)}
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={!sourceTeam || loading || selectedTeamFilters?.length === 0}
          loading={loading}
        >
          {t(MessageKeys.TeamSettingsCopyTeamFilterSubmitLabel)}
        </PrimaryButton>
      </FormGridButtonRow>
    </ModalForm>
  );
};
