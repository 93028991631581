import { useTranslation } from 'react-i18next';
import { Tag as TagType } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import { isTag } from '../../utils/guards';
import { tagSorter } from '../../utils/sorters';
import { Tag, TagProps } from '../Tag';
import {
  TagListOverflowMessage,
  TagListShowAllLessButton,
  TagListWrapper,
} from './TagList.styles';

interface TagListProps {
  tags: Array<TagType | string>;
  TagComponent?: FunctionComponent<TagProps>;
  maxQuantity?: number;
  sortAscending?: boolean;
  allowShowAll?: boolean;
  condensed?: boolean;
  removeTooltip?: string;
  className?: string;
  tagClassName?: string;
  onClick?: (tagId: string) => void;
  onRemove?: (tagId: string) => void;
}

export const TagList: FunctionComponent<TagListProps> = ({
  tags,
  TagComponent,
  maxQuantity,
  allowShowAll,
  sortAscending,
  condensed,
  removeTooltip,
  className,
  tagClassName,
  onClick,
  onRemove,
}) => {
  const { t } = useTranslation();
  const MyTag = TagComponent ?? Tag;
  const [showAll, setShowAll] = useState(false);
  const visibleTags = useMemo(() => {
    const list =
      (maxQuantity && !showAll ? tags?.slice(0, maxQuantity) : tags) ?? [];
    if (typeof sortAscending === 'boolean') {
      const sorted = list.sort(tagSorter);
      if (!sortAscending) {
        return sorted.reverse();
      }
      return sorted;
    }
    return list;
  }, [maxQuantity, showAll, sortAscending, tags]);

  const handleShowAllLessClick = useCallback(
    () => setShowAll((old) => !old),
    [],
  );

  return visibleTags?.length ? (
    <TagListWrapper className={classNames('tag-list-wrapper', className)}>
      {visibleTags.map((tag) => (
        <MyTag
          key={typeof tag === 'string' ? tag : tag.id}
          tag={tag}
          className={classNames(
            'taglist-tag',
            isTag(tag) && tag.active === false ? 'inactive' : 'active',
            tagClassName,
          )}
          removeTooltip={removeTooltip}
          onClick={onClick}
          onRemoveClick={onRemove}
        />
      ))}
      {visibleTags.length < (tags?.length ?? 0) ? (
        <TagListOverflowMessage className="taglist-overflow">
          {condensed
            ? `+ ${tags.length - visibleTags.length}`
            : t(MessageKeys.TagListOverflowMessage, {
                count: tags.length - visibleTags.length,
              })}
        </TagListOverflowMessage>
      ) : null}
      {maxQuantity &&
      allowShowAll &&
      (visibleTags.length < tags?.length || showAll) ? (
        <TagListShowAllLessButton onClick={handleShowAllLessClick}>
          {t(
            showAll
              ? MessageKeys.TagListShowLessLabel
              : MessageKeys.TagListShowMoreLabel,
          )}
        </TagListShowAllLessButton>
      ) : null}
    </TagListWrapper>
  ) : null;
};
