import React, { FunctionComponent, PropsWithChildren } from 'react';
import { SpinningLoadingIcon } from '@fcg-tech/regtech-components';
import { SuspenseLoaderWrapper } from './SuspenseLoader.styles';

interface SuspenseLoaderProps {
  silent?: boolean;
  className?: string;
}

export const SuspenseLoader: FunctionComponent<PropsWithChildren<SuspenseLoaderProps>> = ({
  silent,
  className,
  children,
}) => {
  return (
    <React.Suspense
      fallback={
        <SuspenseLoaderWrapper
          data-testid="suspense-loader"
          className={className}
        >
          {!silent ? <SpinningLoadingIcon size="80px" /> : null}
        </SuspenseLoaderWrapper>
      }
    >
      {children}
    </React.Suspense>
  );
};
