import { useTranslation } from 'react-i18next';
import {
  Label,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  UploadZone,
  useInput,
  useToggle,
} from '@fcg-tech/regtech-components';
import { TeamAttachment } from '@fcg-tech/regtech-types/regeye';
import { useDevice } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import {
  AttachmentListAddUrlTextArea,
  AttachmentListAddUrlTextAreaWrapper,
  AttachmentListAddUrlWrapper,
  AttachmentListList,
  AttachmentListWrapper,
} from './AttachmentList.styles';
import { AttachmentListItem } from './AttachmentListItem';
import {
  AttachmentListNoItemsMessage,
  AttachmentListUrlError,
} from './AttachmentListItem.styles';

const urlRe =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

interface AttachmentListProps {
  attachments: Array<Partial<TeamAttachment>>;
  allowMultiple?: boolean;
  allowDownload?: boolean;
  className?: string;
  onFileUpload?: React.ComponentPropsWithoutRef<typeof UploadZone>['onDrop'];
  onUrlSelected?: (urls: Array<string>) => Promise<boolean>;
  onDownloadAttachment?: (ref: string) => Promise<string>;
  onRemoveAttachment?: (ref: string) => Promise<boolean>;
}

export const AttachmentList: FunctionComponent<AttachmentListProps> = ({
  attachments,
  allowMultiple = false,
  allowDownload = true,
  className,
  onFileUpload,
  onUrlSelected,
  onDownloadAttachment,
  onRemoveAttachment,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const [showAddUrlDialog, setShowAddUrlDialog, toggleShowAddUrlDialogClick] =
    useToggle(false);

  const validateUrl = useCallback(
    (value: string) => {
      if (
        value.length > 0 &&
        value
          .split(/[\n\r]+/)
          .some((v) => v.trim().length > 0 && !urlRe.test(v.trim()))
      ) {
        return t(MessageKeys.ErrorInvalidUrl);
      }
    },
    [t],
  );

  const [
    attachmentUrls,
    handleAttachmentUrlsChange,
    setAttachmentUrls,
    urlError,
  ] = useInput<HTMLTextAreaElement>('', validateUrl);

  const handleSubmitAttachmentUrls = useCallback(() => {
    const urls = Array.from(
      new Set(
        attachmentUrls
          .split(/[\n\r]+/)
          .map((s) => s.trim())
          .filter(Boolean)
          .filter((s) => s.length),
      ),
    );

    onUrlSelected?.(urls);
    setShowAddUrlDialog(false);
    setAttachmentUrls('');
  }, [attachmentUrls, onUrlSelected, setAttachmentUrls, setShowAddUrlDialog]);

  const sortedAttachment = useMemo(
    () =>
      attachments.sort((a, b) => {
        if (a.documentLocation?.customFile && !b.documentLocation?.customFile) {
          return -1;
        } else if (
          b.documentLocation?.customFile &&
          !a.documentLocation?.customFile
        ) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      }),
    [attachments],
  );

  return (
    <AttachmentListWrapper className={className}>
      {showAddUrlDialog ? (
        <Modal>
          <ModalHeader>
            <ModalTitle>
              {t(MessageKeys.AttachmentUploadAddUrlButtonLabel)}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Label column htmlFor="attachment-url">
              {t(MessageKeys.AttachmentUploadAddUrlTextAreaLabel)}
            </Label>
            <AttachmentListAddUrlTextAreaWrapper>
              <AttachmentListAddUrlTextArea
                id="attachment-url"
                value={attachmentUrls}
                onChange={handleAttachmentUrlsChange}
              />
              {urlError ? (
                <AttachmentListUrlError>{urlError}</AttachmentListUrlError>
              ) : null}
            </AttachmentListAddUrlTextAreaWrapper>
            <ModalButtonRow>
              <SecondaryButton onClick={toggleShowAddUrlDialogClick}>
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton
                disabled={Boolean(urlError)}
                onClick={handleSubmitAttachmentUrls}
              >
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </ModalButtonRow>
          </ModalBody>
        </Modal>
      ) : null}
      {onFileUpload && !isMobile ? (
        <>
          <UploadZone
            allowMultiple={allowMultiple}
            activeMessage={t(
              MessageKeys.AttachmentUploadActiveMessage,
            )}
            hoverMessage={t(MessageKeys.AttachmentUploadHoverMessage)}
            dragFilesHintLabel={t(
              MessageKeys.AttachmentUploadDragFilesHintLabel,
            )}
            selectFilesButtonLabel={t(
              MessageKeys.AttachmentUploadSelectFilesButtonLabel,
            )}
            uploadErrorMessage={t(
              MessageKeys.AttachmentUploadUploadErrorMessage,
            )}
            uploadInProgressMessage={t(
              MessageKeys.AttachmentUploadUploadInProgressMessage,
            )}
            onDrop={onFileUpload}
          />
          <AttachmentListAddUrlWrapper>
            <SecondaryButton onClick={toggleShowAddUrlDialogClick}>
              {t(MessageKeys.AttachmentUploadAddUrlButtonLabel)}
            </SecondaryButton>
          </AttachmentListAddUrlWrapper>
        </>
      ) : null}
      {(!onFileUpload || isMobile) && !attachments?.length ? (
        <AttachmentListNoItemsMessage>
          {t(MessageKeys.ArticleMetadataAttachmentsEmpty)}
        </AttachmentListNoItemsMessage>
      ) : null}
      {allowDownload ? (
        <AttachmentListList>
          {sortedAttachment.map((attachment) => (
            <AttachmentListItem
              key={attachment.id}
              attachment={attachment}
              onDownloadClick={onDownloadAttachment}
              onRemoveClick={onRemoveAttachment}
            />
          ))}
        </AttachmentListList>
      ) : null}
    </AttachmentListWrapper>
  );
};
