import {
  BooleanTreeRowChevronIcon,
  CircleExclamationIcon,
  Heading4,
  PrimaryButton,
  RenderWrapper,
  SearchTextField,
  SecondaryButton,
  useFlash,
  useInput,
  useToggle,
} from '@fcg-tech/regtech-components';
import { MessageLevel } from '@fcg-tech/regtech-types';
import {
  Publisher,
  SubscriptionEnumType,
  SubscriptionItemValue,
  SubscriptionUpdate,
} from '@fcg-tech/regtech-types/regeye';
import update, { Spec } from 'immutability-helper';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
// import { Prompt } from 'react-router-dom';
import { usePublishersForTeam, usePublisherTeamActions } from '../../api/hooks';
import { TeamActions } from '../../api/schema';
import { AccessControl } from '../../components/AccessControl';
import { FlexContainer } from '../../components/generic';
import { useIamContext } from '../../components/IamContext';
import { PublisherBooleanTree } from '../../components/PublisherBooleanTree';
import { PublisherBooleanTreeNrOfItems } from '../../components/PublisherBooleanTree/PublisherBooleanTree.styles';
import { MessageKeys } from '../../translations/translationTypes';
import {
  canTeamSubscriptionList,
  canTeamSubscriptionUpdate,
} from '../../utils/iamHelpers';
import { TeamSettingsCopySubscriptionsModal } from './TeamSettingsCopySubscriptionsModal';
import { TeamSettingsSubscriptionsSummary } from './TeamSettingsSubscriptionsSummary';
import {
  TeamSettingsSubscriptionsActionsButtons,
  TeamSettingsSubscriptionsCopySubscriptionsButton,
  TeamSettingsSubscriptionsDetailsSummaryWrapper,
  TeamSettingsSubscriptionsParagraph,
  TeamSettingsSubscriptionsTabWrapper,
  TeamSettingsTabSubscriptionsDescriptionChevron,
  TeamSettingsTabSubscriptionSearchWrapper,
  TeamSettingsTabSubscriptionsLegend,
  TeamSettingsTabSubscriptionsLegendItem,
} from './TeamSettingsSubscriptionsTab.styles';
import {
  getPublishersDiff,
  getUpdateArticleTypeSelectionInstructions,
  getUpdateRegionSelectionInstructions,
} from './teamSettingsUtils';

interface TeamSettingsSubscriptionsTabProps {
  teamId: string;
}

export const TeamSettingsSubscriptionsTab: FunctionComponent<
  TeamSettingsSubscriptionsTabProps
> = ({ teamId }) => {
  const { t } = useTranslation();
  const { updateTeamPublisherSettings } = usePublisherTeamActions(teamId);
  const [loading, setLoading] = useState(false);
  const addFlash = useFlash();
  const iamContext = useIamContext();
  const canList = canTeamSubscriptionList(teamId, iamContext);
  const canUpdate = canTeamSubscriptionUpdate(teamId, iamContext);
  const [showCopyModal, , toggleShowCopyModal] = useToggle();
  const [hideDiff, setHideDiff] = useState(false);
  const [searchTerm, onSearchChange, setSearch] = useInput('');

  const { data, mutate } = usePublishersForTeam(canList ? teamId : null, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  const [items, setItems] = useState(data ?? []);

  useEffect(() => {
    setItems((old) => {
      if (data && old !== data) {
        return data;
      }
      return old;
    });
  }, [data]);

  const handleChange = useCallback(
    (
      changedItem: {
        id: string | number;
        parentId?: string | number;
        value?: SubscriptionItemValue;
      },
      checked: boolean,
    ) => {
      setHideDiff(false);
      setItems((old) => {
        const { value, parentId } = changedItem;
        if (value) {
          if (value.type === SubscriptionEnumType.ArticleType) {
            const index = old.findIndex(({ id }) => id === parentId);
            if (!value.id) {
              // All current and future article types checkbox selected or deselected
              const instructions: Spec<Array<Publisher>> = {};
              if (checked) {
                instructions[index] = getUpdateArticleTypeSelectionInstructions(
                  old[index],
                  true,
                );
              } else {
                instructions[index] = {
                  subscribedToEntirePublisher: { $set: false },
                };
              }
              return update(old, instructions);
            } else {
              // Actual article type
              const articleTypeIndex = old[index].articleTypes.findIndex(
                ({ id }) => id === value.id,
              );
              return update(old, {
                [index]: {
                  articleTypes: {
                    [articleTypeIndex]: { subscribed: { $set: checked } },
                  },
                  subscribedToEntirePublisher: {
                    $set: false,
                  },
                },
              });
            }
          } else if (value.type === SubscriptionEnumType.Region) {
            return update(
              old,
              getUpdateRegionSelectionInstructions(old, value.id, checked),
            );
          } else {
            const index = old.findIndex(({ id }) => id === value.id);
            return update(old, {
              [index]: getUpdateArticleTypeSelectionInstructions(
                old[index],
                checked,
              ),
            });
          }
        }
        return old;
      });
    },
    [],
  );

  const diffs = useMemo(
    () => getPublishersDiff(data ?? [], items),
    [data, items],
  );

  const handleSubmit = useCallback(async () => {
    const updateData: SubscriptionUpdate['items'] = items.map((item) => {
      return {
        publisherId: item.id,
        articleTypeIds: item.articleTypes
          .filter(({ subscribed }) => subscribed)
          .map(({ id }) => id),
        subscribeToEntirePublisher: item.subscribedToEntirePublisher,
      };
    });

    try {
      setLoading(true);
      await updateTeamPublisherSettings(updateData);
      mutate();
      addFlash({
        level: MessageLevel.Success,
        content: t(MessageKeys.LabelChangesSaved),
      });
    } catch (err) {
      addFlash({
        level: MessageLevel.Error,
        content: t(MessageKeys.LabelCouldNotSave),
      });
    } finally {
      setLoading(false);
    }
  }, [addFlash, items, mutate, t, updateTeamPublisherSettings]);

  const handleCancel = useCallback(() => {
    setItems(data);
  }, [data]);

  const handleCopyModalClosed = useCallback(
    (updated: boolean) => {
      if (updated) {
        setHideDiff(true);
      }
      toggleShowCopyModal();
    },
    [toggleShowCopyModal],
  );

  const [totalArticleTypes, subscribedArticleTypes] = useMemo(() => {
    let total = 0;
    let subscribed = 0;

    if (items) {
      items.forEach((item) => {
        total += item.articleTypes.length;
        subscribed += item.articleTypes.filter(
          ({ subscribed }) => subscribed,
        ).length;
      });
    }

    return [total, subscribed];
  }, [items]);

  return (
    <>
      {/* <Prompt
        when={diffs.length > 0 && isActive}
        message={t.translate(MessageKeys.LabelChangesMadeNavigationWarning)}
     />*/}
      <TeamSettingsSubscriptionsTabWrapper>
        <AccessControl
          requiredPermissions={[TeamActions.TeamSubscriptionList]}
          noAccessMessageKey={MessageKeys.AccessErrorTeamSubscriptionList}
        >
          <Heading4>
            {t(MessageKeys.TeamSettingsTabSubscriptionsHeading)}
          </Heading4>

          <TeamSettingsSubscriptionsParagraph>
            <CircleExclamationIcon size="34" />
            <Trans
              i18nKey={
                MessageKeys.TeamSettingsTabSubscriptionsDescription as string
              }
              components={{
                chevron: (
                  <RenderWrapper
                    render={
                      <TeamSettingsTabSubscriptionsDescriptionChevron>
                        <BooleanTreeRowChevronIcon />
                      </TeamSettingsTabSubscriptionsDescriptionChevron>
                    }
                  />
                ),
              }}
            />
          </TeamSettingsSubscriptionsParagraph>
          <FlexContainer className="center margin-bottom">
            <TeamSettingsTabSubscriptionSearchWrapper>
              <SearchTextField
                value={searchTerm}
                placeholder={t(
                  MessageKeys.TeamSettingsTabSubscriptionsSearchPublishersPlaceholder,
                )}
                onChange={onSearchChange}
                onClear={() => setSearch('')}
              />
            </TeamSettingsTabSubscriptionSearchWrapper>
            <TeamSettingsTabSubscriptionsLegend>
              <TeamSettingsTabSubscriptionsLegendItem>
                <PublisherBooleanTreeNrOfItems className="selected">
                  {subscribedArticleTypes}
                </PublisherBooleanTreeNrOfItems>
                {t(
                  MessageKeys.TeamSettingsTabSubscriptionsNrOfSubscribedArticleTypes,
                )}
              </TeamSettingsTabSubscriptionsLegendItem>
              <TeamSettingsTabSubscriptionsLegendItem>
                <PublisherBooleanTreeNrOfItems>
                  {totalArticleTypes}
                </PublisherBooleanTreeNrOfItems>
                {t(MessageKeys.TeamSettingsTabSubscriptionsNrOfArticleTypes)}
              </TeamSettingsTabSubscriptionsLegendItem>
            </TeamSettingsTabSubscriptionsLegend>
          </FlexContainer>
          <PublisherBooleanTree
            publishers={items}
            searchTerm={searchTerm.trim()}
            onChange={canUpdate ? handleChange : null}
          />
        </AccessControl>

        {!hideDiff && diffs.length ? (
          <TeamSettingsSubscriptionsDetailsSummaryWrapper>
            <TeamSettingsSubscriptionsSummary diffs={diffs} />
            <TeamSettingsSubscriptionsActionsButtons>
              <SecondaryButton
                id="cancel-updated-subscriptions"
                disabled={loading}
                onClick={handleCancel}
              >
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton
                id="save-updated-subscriptions"
                loading={loading}
                onClick={handleSubmit}
              >
                {t(MessageKeys.LabelSaveChanges)}
              </PrimaryButton>
            </TeamSettingsSubscriptionsActionsButtons>
          </TeamSettingsSubscriptionsDetailsSummaryWrapper>
        ) : null}

        {showCopyModal ? (
          <TeamSettingsCopySubscriptionsModal
            targetTeamId={teamId}
            onRequestClose={handleCopyModalClosed}
          />
        ) : null}

        {!(!hideDiff && diffs.length) ? (
          <AccessControl
            requiredPermissions={[TeamActions.ViewPageTeamsAdministration]}
          >
            <TeamSettingsSubscriptionsCopySubscriptionsButton
              onClick={toggleShowCopyModal}
            >
              {t(MessageKeys.TeamSettingsTabSubscriptionsCopyFromTeam)}
            </TeamSettingsSubscriptionsCopySubscriptionsButton>
          </AccessControl>
        ) : null}
      </TeamSettingsSubscriptionsTabWrapper>
    </>
  );
};
