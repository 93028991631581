import { Publisher, Region } from '@fcg-tech/regtech-types/regeye';

export const getRegions = (publishers: Array<Publisher>) => {
  const regions: Record<string, Region> = {};

  publishers.forEach((sub) => {
    if (!regions[sub.region.id]) {
      regions[sub.region.id] = sub.region;
    }
  });

  return Object.values(regions);
};

export const getFileName = (file: unknown) => {
  if (file instanceof File) {
    return file.name;
  }

  return '';
};

export const getSubscribedPublishers = (publishers: Array<Publisher>) =>
  publishers.filter(
    (publisher) =>
      publisher.subscribedToEntirePublisher ||
      publisher.articleTypes.find((type) => type.subscribed),
  );
