import {
  FilterSearchResult,
  FilterType,
  FilterValues,
  StoredFilter,
  StoredFilterList,
} from '@fcg-tech/regtech-types/regeye';
import { debounce } from '@fcg-tech/regtech-utils';
import { useMemo } from 'react';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import { useInitContext } from '../../components/InitContext';

import {
  convertFilterForApi,
  convertStoredFilter,
} from '../../converters/filterConverters';
import { convertTag } from '../../converters/tagConverter';
import { getApiQueryValues, useApi } from '../apiUtils';
import {
  ApiResponse,
  ArticlesApi,
  FilterInputCreate,
  FiltersApi,
  SearchAvailableArticleFiltersRequest,
  TranslatedFilter,
} from '../schema';
import { myFiltersKey, teamFiltersKey } from './cacheKeys';

export const getStoredFilters = async (
  filterType: FilterType | undefined,
  teamId: string | null,
  api: FiltersApi,
): Promise<Array<StoredFilter>> => {
  const result = await (teamId
    ? api.getTeamFilters({ teamId, filterType })
    : api.getPersonalFilters({ filterType }));

  return result.items
    .filter((filter) => !filterType || filter.filterType === filterType)
    .map(convertStoredFilter);
};

export const useTeamStoredFilters = (
  filterType?: FilterType | null,
  teamId?: string,
  config: SWRConfiguration = {},
) => {
  const api = useApi<FiltersApi>('FiltersApi');
  return useSWR<Array<StoredFilter>>(
    !teamId || filterType === null ? null : teamFiltersKey(teamId, filterType),
    () => getStoredFilters(filterType, teamId, api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const usePersonalStoredFilters = (
  filterType?: FilterType | null,
  config: SWRConfiguration = {},
) => {
  const api = useApi<FiltersApi>('FiltersApi');
  const { error: initDataError } = useInitContext();
  return useSWR<Array<StoredFilter>>(
    initDataError || filterType === null ? null : myFiltersKey(filterType),
    () => getStoredFilters(filterType, null, api),
    {
      suspense: true,
      ...config,
    },
  );
};

type UseStoredFilterActionProps = {
  debounceMutate?: number;
};

export const useStoredFilterActions = ({
  debounceMutate,
}: UseStoredFilterActionProps = {}) => {
  const api = useApi<FiltersApi>('FiltersApi');
  const { mutate } = useSWRConfig();

  const doMutate =
    debounceMutate > 0 ? debounce(mutate, debounceMutate) : mutate;

  return useMemo(
    () => ({
      saveFilter: async (
        filterValues: FilterValues,
        name: string,
        filterType: FilterType,
        teamId?: string,
        filterId?: string,
      ): Promise<StoredFilter> => {
        if (!name) {
          throw new Error('Filter must have a name');
        }

        const filterInput: FilterInputCreate = {
          name,
          filterType,
          filter: convertFilterForApi(filterValues),
        };

        let storedFiltersResponse: ApiResponse<StoredFilterList>;

        if (teamId) {
          if (filterId) {
            storedFiltersResponse = await api.updateTeamFilterRaw({
              filterId,
              teamId,
              filterInputUpdate: filterInput,
            });
          } else {
            storedFiltersResponse = await api.createTeamFilterRaw({
              teamId,
              filterInputCreate: filterInput,
            });
          }
        } else {
          if (filterId) {
            storedFiltersResponse = await api.updatePersonalFilterRaw({
              filterId,
              filterInputUpdate: filterInput,
            });
          } else {
            storedFiltersResponse = await api.createPersonalFilterRaw({
              filterInputCreate: filterInput,
            });
          }
        }

        const storedFilters = (await storedFiltersResponse.value()).items.map(
          convertStoredFilter,
        );

        doMutate(
          teamId
            ? teamFiltersKey(teamId, filterType)
            : myFiltersKey(filterType),
          storedFilters,
          true,
        );

        doMutate(teamId ? teamFiltersKey(teamId) : myFiltersKey());

        const location = (
          storedFiltersResponse.raw.headers.get('location') as string
        )?.split('/');

        const savedFilterId = location?.length
          ? location[location.length - 1]
          : null;

        const filter = storedFilters.find(({ id }) => id === savedFilterId);
        return filter;
      },

      deleteFilter: async (
        filterId: string,
        filterType: FilterType,
        teamId?: string,
      ) => {
        let deletePromise: Promise<StoredFilterList>;
        if (teamId) {
          deletePromise = api.deleteTeamFilter({ filterId, teamId });
        } else {
          deletePromise = api.deletePersonalFilter({ filterId });
        }

        const storedFilters = (await deletePromise).items.map(
          convertStoredFilter,
        );

        doMutate(
          teamId ? teamFiltersKey(teamId, undefined) : myFiltersKey(undefined),
        );
        doMutate(
          teamId
            ? teamFiltersKey(teamId, filterType)
            : myFiltersKey(filterType),
          storedFilters,
          false,
        );
      },
    }),
    [api, doMutate],
  );
};

export const useAvilableArticleFiltersActions = (teamId?: string) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const filterApi = useApi<FiltersApi>('FiltersApi');
  return useMemo(
    () => ({
      translateFilter: async (
        filterValues: FilterValues,
      ): Promise<TranslatedFilter> => {
        const filter: FilterValues = {
          ...filterValues,
        };

        const result = await filterApi.translateFilter({
          filter: convertFilterForApi(filter),
        });

        return {
          ...result,
          tags: result.tags?.map((tag) => convertTag(tag)),
          types: result.types?.map((tag) => convertTag(tag)),
        };
      },
      searchFilterOptions: async (
        filterSearchField: SearchAvailableArticleFiltersRequest['filterSearchField'],
        filterSearchString: string,
        filterValues: FilterValues,
        { offset, limit }: { offset?: string; limit?: number } = {},
      ): Promise<FilterSearchResult> => {
        const query: SearchAvailableArticleFiltersRequest = {
          ...getApiQueryValues(filterValues),
          teamId,
          filterSearchString,
          filterSearchField,
          offset,
          limit,
        };

        const result = await api.searchAvailableArticleFilters(query);

        const { items, ...rest } = result;
        if (filterSearchField === 'tags' || filterSearchField === 'types') {
          return {
            ...rest,
            items: items?.map((tag) => convertTag(tag)),
          };
        }
        return {
          ...rest,
          items,
        };
      },
    }),
    [api, filterApi, teamId],
  );
};
