import styled from 'styled-components';

export const TagOptionTagName = styled.span`
  &.inactive {
    text-decoration: line-through;
    opacity: 0.5;
  }
`;

export const TagOptionTeamname = styled.span`
  color: #888;
  font-size: 80%;
  margin-left: 1.6rem;
  text-decoration: none !important;
`;
