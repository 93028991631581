import { CrossIcon, IconProps } from '@fcg-tech/regtech-components';
import { Tag as TagType } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback } from 'react';
import { DefaultTheme } from 'styled-components';
import { isTag } from '../../utils/guards';
import { useDevMode } from '../dev';
import { Tooltip } from '../Tooltip';
import {
  StyledTagIcon,
  TagLabel,
  TagRemoveButton,
  TagWrapper,
} from './Tag.styles';

export interface TagProps {
  tag: TagType | string;
  className?: string;
  theme?: DefaultTheme;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  Icon?: FunctionComponent<IconProps>;
  removeTooltip?: string;
  onClick?: (tagId: string) => void;
  onRemoveClick?: (tagId: string) => void;
}

export const Tag: FunctionComponent<TagProps> = ({
  tag,
  className,
  theme,
  color,
  backgroundColor,
  disabled,
  Icon,
  removeTooltip,
  onClick,
  onRemoveClick,
}) => {
  const handleRemoveClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onRemoveClick?.(typeof tag === 'string' ? tag : tag.id);
    },
    [tag, onRemoveClick],
  );

  const handleClick = useCallback(
    () => onClick?.(typeof tag === 'string' ? tag : tag.id),
    [onClick, tag],
  );

  const devMode = useDevMode();

  return (
    <TagWrapper
      className={classNames(className, 'tag')}
      theme={theme}
      color={color}
      backgroundColor={backgroundColor}
      disabled={disabled}
      role={onClick ? 'link' : 'none'}
      onClick={handleClick}
      title={
        devMode && isTag(tag)
          ? `Original name: ${tag.originalName}`
          : typeof tag === 'string'
          ? tag
          : tag.name
      }
      data-kind="tag"
    >
      {Icon ? <StyledTagIcon as={Icon} /> : null}
      <TagLabel>{typeof tag === 'string' ? tag : tag.name}</TagLabel>
      {onRemoveClick ? (
        <Tooltip content={removeTooltip}>
          <TagRemoveButton color={color} onClick={handleRemoveClick}>
            <CrossIcon size="12" />
          </TagRemoveButton>
        </Tooltip>
      ) : null}
    </TagWrapper>
  );
};
