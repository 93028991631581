import { Showcase, ShowcaseReel } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeShowcase, Translator } from '../../types';
import { ShowCaseContent } from './ShowCaseContent';
import { useShowcase } from './useShowcase';

export const NewTabsShowcase: FunctionComponent = () => {
  const { t } = useTranslation();
  const [display, handleExit] = useShowcase(RegeyeShowcase.NewTabs, {
    requireShowcaseCompleted: [
      RegeyeShowcase.GenericFeedArticleTable,
      RegeyeShowcase.NewUser,
    ],
  });
  return display ? (
    <Showcase reel={NewTabsShowcaseReel(t)} onExit={handleExit} />
  ) : null;
};

const contentStyle = { width: 940 };

const NewTabsShowcaseReel = (t: Translator): ShowcaseReel => ({
  id: RegeyeShowcase.NewTabs,
  skippable: true,
  waitForQuerySelector: '[role="tablist"]',
  entries: [
    {
      content: (entry) => (
        <ShowCaseContent
          entry={entry}
          heading={t(MessageKeys.ShowCaseNewTabsWelcomeHeading)}
          content={t(MessageKeys.ShowCaseNewTabsWelcomeBody)}
        />
      ),
      contentStyle,
      illustrationStyle: { backgroundSize: 'contain', paddingBottom: '46.5%' },
      illustrationSrc: 'assets/showcase/new-tabs/regeye-new-tabs.jpg',
    },
  ],
});
