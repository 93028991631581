import { H3, LineButton } from '@fcg-tech/regtech-components';
import styled from 'styled-components';

export const TeamSettingsHelpIconWrapper = styled.div`
  margin-left: 1.6rem;
  display: flex;
  align-items: center;
`;

export const TeamSettingsTabButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.6rem;
  padding: 0 1.6rem;

  & > button + button {
    margin-left: 0.8rem;
  }
`;

export const TeamSettingsMemberDetailsInvitationsHeading = styled(H3)`
  margin-top: 3.6rem;
  padding-left: 1.6rem;
`;

export const TeamSettingsPageTeamFiltersInfo = styled.p`
  font-size: 1.4rem;
  width: 100%;
  text-align: center;
  font-weight: normal;
`;

export const TeamSettingsCopyTeamItemsSelectAllButton = styled(LineButton)`
  margin-top: 1rem;
`;
