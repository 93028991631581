import { Tag } from '@fcg-tech/regtech-types/regeye';
import { FilterType } from '../api/schema';

export const isTag = (tag: unknown): tag is Tag =>
  typeof tag === 'object' &&
  Reflect.has(tag, 'name') &&
  Reflect.has(tag, 'description');

export const isFilterType = (e: unknown): e is FilterType =>
  Object.values(FilterType).includes(e as never);

export const isCheckboxChangeEvent = (
  e: React.ChangeEvent<Element>,
): e is React.ChangeEvent<HTMLInputElement> =>
  e.target.attributes.getNamedItem('type').value === 'checkbox';
