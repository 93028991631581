import {
  excludeProps,
  TagIcon,
  UnstyledButton,
} from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const TagWrapper = styled.div.withConfig(
  excludeProps('backgroundColor', 'color', 'disabled'),
)<{ backgroundColor?: string; color?: string; disabled?: boolean }>`
  ${({ theme, backgroundColor, color, disabled }) => css`
    padding: 0.4rem 0.6rem;
    border: 1px solid
      ${color ?? theme?.palette?.MainColorAccent.mainContrast ?? '#000'};
    border-radius: 4px;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: ${backgroundColor ??
    theme?.palette?.MainColorAccent.main ??
    '#ddd'};
    color: ${color ?? theme?.palette?.MainColorAccent.mainContrast ?? '#000'};
    display: inline-flex;
    align-items: center;
    opacity: ${disabled ? 0.7 : 1};
    white-space: nowrap;
    max-width: 20rem;

    &[role='link'] {
      cursor: pointer;
    }

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      font-size: 1rem;
      padding: 0.2rem 0.4rem;
    }
  `}
`;

export const TagRemoveButton = styled(UnstyledButton)`
  margin-left: 0.5rem;
  color: inherit;

  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;

export const TagLabel = styled.span`
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTagIcon = styled(TagIcon).attrs({ size: '12' })`
  margin-right: 0.4rem;
`;
