import {
  multiSelectStyles,
  ReactSelectOption,
} from '@fcg-tech/regtech-components';
import { Maybe } from '@fcg-tech/regtech-types';
import { useMemo } from 'react';
import { StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components';
import { Tag } from '../api/schema';
import { TagType } from '../types';
import {
  ARTICLE_TAG_BG,
  ARTICLE_TAG_FG,
  ARTICLE_TYPE_TAG_BG,
  ARTICLE_TYPE_TAG_FG,
  TEAM_TAG_BG,
  TEAM_TAG_FG,
} from './Tag';

export {
  inLabelStyles,
  isSelectAllOptionData,
  multiSelectInLabelStyles,
  multiSelectStyles,
  singleSelectInLabelStyles,
  singleSelectStyles,
} from '@fcg-tech/regtech-components';
export type { SelectAllOptionData } from '@fcg-tech/regtech-components';

export const teamMetadataSelectStyles = (
  theme: DefaultTheme,
): StylesConfig<unknown, false> => ({
  container: (p) => ({
    ...p,
    fontSize: '1.3rem',
    width: '28rem',

    [`@media (max-width: ${theme?.breakpoints?.mobile ?? 730}px)`]: {
      width: '100%',
    },
  }),
  menu: (p) => ({
    ...p,
    zIndex: 10,
  }),
  option: (p, m) => {
    if ((m.data as { __isNew__?: boolean }).__isNew__) {
      return {
        ...p,
        fontWeight: 600,
      };
    }
    return p;
  },
});

export const useFilterSelectStyles = (
  tagType: Maybe<TagType>,
  isExcluding?: boolean,
): StylesConfig<ReactSelectOption<Tag>, boolean> => {
  return useMemo(() => {
    const isExcludingStyle = isExcluding
      ? {
          textDecoration: 'line-through',
        }
      : {};
    if (tagType === TagType.Tag) {
      return {
        ...(multiSelectStyles as StylesConfig<ReactSelectOption<Tag>, true>),
        multiValue: (p) => ({
          ...p,
          backgroundColor: ARTICLE_TAG_BG,
          border: `1px solid ${ARTICLE_TAG_FG}`,
          borderRadius: '3px',
        }),
        multiValueLabel: (p) => ({
          ...p,
          color: 'black',
          fontWeight: 500,
          ...isExcludingStyle,
        }),
        multiValueRemove: (p) => ({
          ...p,
          color: 'black',
        }),
        menu: (p) => ({
          ...p,
          zIndex: 10,
        }),
      } as StylesConfig<unknown, true>;
    }
    if (tagType === TagType.ArticleTypeTag) {
      return {
        ...(multiSelectStyles as StylesConfig<ReactSelectOption<Tag>, true>),
        multiValue: (p) => ({
          ...p,
          backgroundColor: ARTICLE_TYPE_TAG_BG,
          border: `1px solid ${ARTICLE_TYPE_TAG_FG}`,
          borderRadius: '3px',
        }),
        multiValueLabel: (p) => ({
          ...p,
          color: 'black',
          fontWeight: 500,
          ...isExcludingStyle,
        }),
        multiValueRemove: (p) => ({
          ...p,
          color: 'black',
        }),
        menu: (p) => ({
          ...p,
          zIndex: 10,
        }),
      } as StylesConfig<unknown, true>;
    }
    if (tagType === TagType.TeamTag) {
      return {
        ...(multiSelectStyles as StylesConfig<ReactSelectOption<Tag>, true>),
        multiValue: (p) => ({
          ...p,
          color: 'black',
          backgroundColor: TEAM_TAG_BG,
          border: `1px solid ${TEAM_TAG_FG}`,
          borderRadius: '3px',
        }),
        multiValueLabel: (p) => ({
          ...p,
          color: 'black',
          fontWeight: 500,
          ...isExcludingStyle,
        }),
        multiValueRemove: (p) => ({
          ...p,
          color: 'black',
        }),
        menu: (p) => ({
          ...p,
          zIndex: 10,
        }),
      } as StylesConfig<unknown, true>;
    }
    return {
      ...(multiSelectStyles as StylesConfig<ReactSelectOption<Tag>, true>),
      multiValueLabel: (p) => ({
        ...p,
        ...isExcludingStyle,
      }),
      menu: (p) => ({
        ...p,
        zIndex: 10,
      }),
    } as StylesConfig<unknown, true>;
  }, [isExcluding, tagType]);
};
