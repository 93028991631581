import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  CircleExclamationIcon,
  CompanyIcon,
  SideBarSeparator,
  SideNavigationExpandButton,
  SideNavigationExpandButtonChevronIcon,
  SideNavigationItem,
  SideNavigationItemIcon,
  SideNavigationItemLinkLabel,
  SideNavigationMenu,
  TooltipNext as Tooltip,
  useSideBar,
} from '@fcg-tech/regtech-components';
import { Company, Team } from '@fcg-tech/regtech-types/regeye';
import { Users } from 'lucide-react';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { useSettings } from '../../api/hooks';
import { CompanyActions } from '../../api/schema';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { isTeamVisible } from '../../utils/misc';
import { useIamContext } from '../IamContext';
import { TeamContextProvider } from '../TeamContext/TeamContext';
import {
  CompanySideNavigationEmptyMessage,
  CompanySideNavigationWrapper,
} from './CompanySideNavigation.styles';

interface CompanySideNavigationProps {
  company: Company;
  teams: Array<Team>;
}

export const CompanySideNavigation: FunctionComponent<
  CompanySideNavigationProps
> = ({ company, teams }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { data: settings } = useSettings();
  const { expandedHrefs, toggleExpandedHref } = useSideBar();
  const baseUrl = constructUrl(routes.companyBase, {
    companyId: company.id,
  });
  const baseUrls = useMemo(() => {
    const baseUrls = teams.map(
      (team) =>
        `${constructUrl(routes.teamBaseCatchAll, { teamId: team.id })}/*`,
    );
    baseUrls.push(
      constructUrl(routes.companyActions, {
        companyId: company.id,
      }),
    );
    baseUrls.push(
      constructUrl(routes.companyActionsLog, {
        companyId: company.id,
      }),
    );
    baseUrls.push(
      constructUrl(routes.companyAdministrationCatchAll, {
        companyId: company.id,
      }),
    );
    baseUrls.push(
      constructUrl(routes.companyTeamAdministration, {
        companyId: company.id,
        teamId: ':teamId',
      }),
    );
    return baseUrls;
  }, [company.id, teams]);

  useEffect(() => {
    if (baseUrls.some((baseUrl) => matchPath(baseUrl, location.pathname))) {
      toggleExpandedHref(baseUrl, true);
    }
    // Only needed after page load. No automatic expanding after that, please
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expanded = expandedHrefs.includes(baseUrl);

  const handleExpandClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      toggleExpandedHref(baseUrl, !expanded);
      if (expanded) {
        baseUrls.map((baseUrl) => toggleExpandedHref(baseUrl, !expanded));
      }
    },
    [toggleExpandedHref, baseUrl, expanded, baseUrls],
  );

  const iamContext = useIamContext();

  const noCompanyRights = useMemo(
    () =>
      !iamContext.companyPermissions[company.id]?.includes(
        CompanyActions.ViewPageCompanyAdministration,
      ) &&
      !iamContext.companyPermissions[company.id]?.includes(
        CompanyActions.GetCompanyActionsArticles,
      ) &&
      !iamContext.companyPermissions[company.id]?.includes(
        CompanyActions.GetCompanyActivityLogArticles,
      ),
    [iamContext.companyPermissions, company.id],
  );

  return (
    <TeamContextProvider companyId={company.id}>
      <CompanySideNavigationWrapper>
        {company ? (
          <>
            <SideBarSeparator />
            <SideNavigationMenu>
              <SideNavigationItem
                href={constructUrl(routes.companyBase, {
                  companyId: company.id,
                })}
                end={false}
                onClick={noCompanyRights ? handleExpandClick : undefined}
              >
                <SideNavigationExpandButton
                  onClick={handleExpandClick}
                  title="show/hide"
                >
                  <SideNavigationExpandButtonChevronIcon down={expanded} />
                </SideNavigationExpandButton>
                <SideNavigationItemIcon as={CompanyIcon} />
                <SideNavigationItemLinkLabel>
                  {company.name}
                </SideNavigationItemLinkLabel>
              </SideNavigationItem>
            </SideNavigationMenu>
            {expanded ? (
              <SideNavigationMenu>
                {teams
                  ?.filter((team) => isTeamVisible(team, settings))
                  .map((team) => (
                    <TeamContextProvider
                      teamId={team.id}
                      companyId={team.company?.id}
                      key={team.id}
                    >
                      <SideNavigationItem
                        href={constructUrl(routes.teamBase, {
                          teamId: team.id,
                        })}
                        end={false}
                      >
                        <SideNavigationItemIcon as={Users} />
                        <SideNavigationItemLinkLabel>
                          {team.name}
                        </SideNavigationItemLinkLabel>
                      </SideNavigationItem>
                    </TeamContextProvider>
                  ))}
              </SideNavigationMenu>
            ) : null}

            {expanded && teams?.length === 0 && noCompanyRights ? (
              <Tooltip
                showDelay={750}
                content={t(MessageKeys.SideMenuEmptyCompanyMessageTooltip)}
                placement="right"
              >
                <CompanySideNavigationEmptyMessage>
                  <CircleExclamationIcon size="18px" />
                  <p>{t(MessageKeys.SideMenuEmptyCompanyMessage)}</p>
                </CompanySideNavigationEmptyMessage>
              </Tooltip>
            ) : null}
          </>
        ) : null}
      </CompanySideNavigationWrapper>
    </TeamContextProvider>
  );
};
