import {
  ChevronRightIcon,
  IconButton,
  IconButtonDefaultIconWrapper,
  Modal,
  SuspenseLoader,
} from '@fcg-tech/regtech-components';
import styled from 'styled-components';
import { FeedArticleExpandedInfoWrapper } from '../FeedArticleTable/FeedArticleExpandedInfo.styles';

export const FeedArticleOverlayModal = styled(Modal)`
  width: 95vw;
  height: 95vh;
  top: 0;
  max-height: initial;
  overflow: visible;
  background: transparent;

  & ${FeedArticleExpandedInfoWrapper} {
    border-top: 0;
    border-bottom: 0;
    border-radius: 0.6rem;
    min-height: 100%;
    box-shadow: none;
  }
`;

export const FeedArticleOverlayModalSwiper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(230, 230, 230, 0.8);

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(4px);
  }
`;

export const FeedArticleOverlayPrevButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
  z-index: 10;

  & > ${IconButtonDefaultIconWrapper} {
    position: relative;
    left: -0.1rem;
  }
`;

export const FeedArticleOverlayNextButton = styled(
  FeedArticleOverlayPrevButton,
)`
  right: 0;
  left: initial;
  transform: translate(50%, -50%);

  & > ${IconButtonDefaultIconWrapper} {
    position: relative;
    left: 0.1rem;
  }
`;

export const ChevronLeftIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;

export const FeedArticleOverlaySuspenseLoader = styled(SuspenseLoader)`
  background: white;
  border-radius: 0.6rem;
`;
