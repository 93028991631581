import {
  FormGridButtonRow,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  FormGridToggle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  TextField,
  useFlash,
  useFocus,
  useInput,
} from '@fcg-tech/regtech-components';
import { Company } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {
  ModalForm,
  ModalFormWrapper,
} from '../../components/modals/modalFormComponents';
import { portalRoot } from '../../components/Portal';
import { singleSelectInLabelStyles } from '../../components/selectStyles';
import { MessageKeys } from '../../translations/translationTypes';

interface TeamAdministrationCreateTeamModalProps {
  companies: Array<Company>;
  initialCompanyId?: string;
  onSubmit: (
    companyId: string,
    teamName: string,
    addUserToTeam: boolean,
  ) => Promise<void>;
  onCancel: () => void;
  onRequestClose: () => void;
}

export const TeamAdministrationCreateTeamModal: FunctionComponent<
  TeamAdministrationCreateTeamModalProps
> = ({ companies, initialCompanyId, onSubmit, onCancel, onRequestClose }) => {
  const addFlash = useFlash();
  const { t } = useTranslation();
  const [teamNameValue, handleTeamNameChange] = useInput('');
  const [addMeToThisTeam, setAddMeToThisTeam] = useState(false);
  const companyOptions: Array<ReactSelectOption<Company>> = useMemo(
    () =>
      companies.map<ReactSelectOption<Company>>((company) => ({
        label: company.name,
        value: company,
      })),
    [companies],
  );

  const [company, setCompany] = useState<ReactSelectOption<Company>>(
    initialCompanyId
      ? companyOptions.find((o) => o.value.id === initialCompanyId)
      : null,
  );
  const [loading, setLoading] = useState(false);

  const companyId = company?.value.id;
  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (teamNameValue.trim().length === 0) {
        return;
      }
      setLoading(true);
      try {
        await onSubmit(companyId, teamNameValue, addMeToThisTeam);
        onRequestClose();
        addFlash({
          level: 'success',
          content: t(MessageKeys.AdministrationTeamCreateSuccess),
        });
      } catch (e) {
        addFlash({
          level: 'error',
          content: t(MessageKeys.AdministrationTeamCreateFailure),
        });
        return null;
      }
    },
    [
      teamNameValue,
      onSubmit,
      companyId,
      addMeToThisTeam,
      onRequestClose,
      addFlash,
      t,
    ],
  );
  const ref = useFocus<HTMLInputElement>();

  const handleCompanyChange = useCallback(
    (option: ReactSelectOption<Company>) => {
      setCompany(option);
    },
    [],
  );

  const handleAddMeToThisTeamChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddMeToThisTeam(event.target.checked);
    },
    [],
  );

  const bodyRef = useRef<HTMLDivElement>();

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.AdministrationTeamTabCreateNewTeamLabel)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody className="no-scroll" ref={bodyRef}>
        <ModalFormWrapper className="narrow">
          <ModalForm onSubmit={handleSubmit}>
            <FormGridRow>
              <FormGridLabel>
                {t(MessageKeys.AdministrationTeamDetailsTeamName)}
              </FormGridLabel>
              <FormGridControl>
                <TextField
                  name="team-name"
                  ref={ref}
                  value={teamNameValue}
                  autoFocus
                  onChange={handleTeamNameChange}
                />
              </FormGridControl>
            </FormGridRow>
            {!companies || companies.length === 1 ? null : (
              <FormGridRow>
                <FormGridLabel>{t(MessageKeys.LabelCompany)}</FormGridLabel>
                <FormGridControl>
                  <Select
                    options={companyOptions}
                    value={company}
                    isMulti={false}
                    styles={singleSelectInLabelStyles}
                    menuPortalTarget={portalRoot}
                    onChange={handleCompanyChange}
                  />
                </FormGridControl>
              </FormGridRow>
            )}
            <FormGridRow
              className={classNames('with-toggle', addMeToThisTeam && 'valid')}
            >
              <FormGridLabel>
                {t(MessageKeys.AdministrationTeamDetailsAddMeToThisTeam)}
              </FormGridLabel>
              <FormGridControl>
                <FormGridToggle
                  checked={addMeToThisTeam}
                  onChange={handleAddMeToThisTeamChange}
                />
              </FormGridControl>
            </FormGridRow>
            <FormGridButtonRow>
              <SecondaryButton
                type="button"
                onClick={onCancel}
                disabled={loading}
              >
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                disabled={!teamNameValue.trim().length || !company}
                loading={loading}
              >
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </FormGridButtonRow>
          </ModalForm>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
