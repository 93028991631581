import { mobile } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const TeamSettingsSubscriptionsSummaryWrapper = styled.div`
  font-size: 1.4rem;
  min-width: 30rem;
  padding: 1.6rem;

  ${mobile(
    () => css`
      display: none;
    `,
  )}
`;

export const TeamSettingsSubscriptionsSummaryDiffRow = styled.div`
  & + & {
    margin-top: 1.7rem;
  }
`;

export const TeamSettingsSubscriptionsSummaryDiffRowHeading = styled.h4`
  margin: 0 0 0.5rem 0;
`;

export const TeamSettingsSubscriptionsSummaryDiffRowCount = styled.div`
  padding-left: 1rem;
  margin-top: 0.5rem;
`;
