import { useTranslation } from 'react-i18next';
import { ModalHeader, ModalTitle } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { MessageKeys } from '../../../translations/translationTypes';
import { SuspenseLoader } from '../../SuspensLoader';
import { SetupWizardModalWrapper } from '../SetupWizard.styles';
import { JoinTeamSetupWizardTeamList } from './JoinTeamSetupWizardTeamList';

interface JoinTeamSetupWizardModalProps {
  companyId: string;
  onRequestClose?: () => void;
}

export const JoinTeamSetupWizardModal: FunctionComponent<
  JoinTeamSetupWizardModalProps
> = ({ companyId, onRequestClose }) => {
  const { t } = useTranslation();
  return (
    <SetupWizardModalWrapper>
      <ModalHeader>
        <ModalTitle>
          {t(MessageKeys.WizardWelcomeToRegeye)}
        </ModalTitle>
      </ModalHeader>
      <SuspenseLoader>
        <JoinTeamSetupWizardTeamList
          companyId={companyId}
          onRequestClose={onRequestClose}
        />
      </SuspenseLoader>
    </SetupWizardModalWrapper>
  );
};
