import styled from 'styled-components';

export const MultiSelectOptionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & > .lucide {
    margin-right: 1.3rem;
  }
`;
