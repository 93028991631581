/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivityList,
    ActivityListFromJSON,
    ActivityListToJSON,
    ArchivedOptions,
    ArchivedOptionsFromJSON,
    ArchivedOptionsToJSON,
    Count,
    CountFromJSON,
    CountToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Exclude,
    ExcludeFromJSON,
    ExcludeToJSON,
    FeedArticleList,
    FeedArticleListFromJSON,
    FeedArticleListToJSON,
    FeedArticleResponse,
    FeedArticleResponseFromJSON,
    FeedArticleResponseToJSON,
    FilterSearchField,
    FilterSearchFieldFromJSON,
    FilterSearchFieldToJSON,
    FilterSearchResult,
    FilterSearchResultFromJSON,
    FilterSearchResultToJSON,
    InsightsSelector,
    InsightsSelectorFromJSON,
    InsightsSelectorToJSON,
    OrderOptions,
    OrderOptionsFromJSON,
    OrderOptionsToJSON,
    TeamAction,
    TeamActionFromJSON,
    TeamActionToJSON,
} from '../models';

export interface CountOverdueArticlesRequest {
    teamId: string;
}

export interface GetAllArticlesRequest {
    skip?: number;
    limit?: number;
    unreadOnly?: boolean;
    publishers?: Array<string>;
    regions?: Array<string>;
    types?: Array<string>;
    tags?: Array<string>;
    from?: Date;
    to?: Date;
    orderBy?: Array<OrderOptions>;
    searchString?: string;
    exclude?: Array<Exclude>;
    bookmarked?: boolean;
}

export interface GetAllTeamActionsRequest {
    skip?: number;
    limit?: number;
    selector?: Array<InsightsSelector>;
    importance?: Array<number>;
    teamTags?: Array<string>;
    commentedBy?: Array<string>;
    assignees?: Array<string>;
    attachedBy?: Array<string>;
    dueFrom?: Date;
    dueTo?: Date;
    performedFrom?: Date;
    performedTo?: Date;
    from?: Date;
    to?: Date;
    orderBy?: Array<OrderOptions>;
    archived?: ArchivedOptions;
    uncompletedAssignedOnly?: boolean;
    teams?: Array<string>;
}

export interface GetArticleRequest {
    articleId: string;
}

export interface GetArticlesForTeamRequest {
    teamId: string;
    skip?: number;
    limit?: number;
    unreadOnly?: boolean;
    publishers?: Array<string>;
    regions?: Array<string>;
    types?: Array<string>;
    tags?: Array<string>;
    from?: Date;
    to?: Date;
    orderBy?: Array<OrderOptions>;
    searchString?: string;
    exclude?: Array<Exclude>;
    archived?: ArchivedOptions;
    bookmarked?: boolean;
}

export interface GetCompanyActionsArticlesRequest {
    companyId: string;
    skip?: number;
    limit?: number;
    selector?: Array<InsightsSelector>;
    importance?: Array<number>;
    teamTags?: Array<string>;
    commentedBy?: Array<string>;
    assignees?: Array<string>;
    attachedBy?: Array<string>;
    dueFrom?: Date;
    dueTo?: Date;
    performedFrom?: Date;
    performedTo?: Date;
    from?: Date;
    to?: Date;
    orderBy?: Array<OrderOptions>;
    archived?: ArchivedOptions;
    uncompletedAssignedOnly?: boolean;
    teams?: Array<string>;
}

export interface GetCompanyActivityLogArticlesRequest {
    companyId: string;
    skip?: number;
    limit?: number;
    actions?: Array<TeamAction>;
    performedFrom?: Date;
    performedTo?: Date;
    performedBy?: Array<string>;
    orderBy?: Array<OrderOptions>;
    teams?: Array<string>;
}

export interface GetTeamActionsArticlesRequest {
    teamId: string;
    skip?: number;
    limit?: number;
    selector?: Array<InsightsSelector>;
    importance?: Array<number>;
    teamTags?: Array<string>;
    commentedBy?: Array<string>;
    assignees?: Array<string>;
    attachedBy?: Array<string>;
    dueFrom?: Date;
    dueTo?: Date;
    performedFrom?: Date;
    performedTo?: Date;
    from?: Date;
    to?: Date;
    orderBy?: Array<OrderOptions>;
    archived?: ArchivedOptions;
    uncompletedAssignedOnly?: boolean;
}

export interface GetTeamActivityLogArticlesRequest {
    teamId: string;
    skip?: number;
    limit?: number;
    actions?: Array<TeamAction>;
    performedFrom?: Date;
    performedTo?: Date;
    performedBy?: Array<string>;
    orderBy?: Array<OrderOptions>;
}

export interface GetTeamActivityLogForArticleRequest {
    teamId: string;
    articleId: string;
}

export interface GetUserActivityLogArticlesRequest {
    skip?: number;
    limit?: number;
    actions?: Array<TeamAction>;
    performedFrom?: Date;
    performedTo?: Date;
    orderBy?: Array<OrderOptions>;
    teams?: Array<string>;
}

export interface SearchAvailableArticleFiltersRequest {
    filterSearchField: FilterSearchField;
    unreadOnly?: boolean;
    publishers?: Array<string>;
    regions?: Array<string>;
    types?: Array<string>;
    tags?: Array<string>;
    from?: Date;
    to?: Date;
    searchString?: string;
    teamId?: string;
    offset?: string;
    filterSearchString?: string;
    exclude?: Array<Exclude>;
    archived?: ArchivedOptions;
    limit?: number;
    bookmarked?: boolean;
}

/**
 * ArticlesApi - interface
 * 
 * @export
 * @interface ArticlesApiInterface
 */
export interface ArticlesApiInterface {
    /**
     * 
     * @summary Count number number of assignments to user (unarchived teams)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    countAssignmentsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Count>>;

    /**
     * Count number number of assignments to user (unarchived teams)
     */
    countAssignments(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Count>;

    /**
     * 
     * @summary Count number of unarchvied articles which is overdue
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    countOverdueArticlesRaw(requestParameters: CountOverdueArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Count>>;

    /**
     * Count number of unarchvied articles which is overdue
     */
    countOverdueArticles(requestParameters: CountOverdueArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Count>;

    /**
     * 
     * @summary List all articles
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {boolean} [unreadOnly] return unread articles only
     * @param {Array<string>} [publishers] select articles from specified publisher
     * @param {Array<string>} [regions] select articles from specified region
     * @param {Array<string>} [types] select articles with specified types
     * @param {Array<string>} [tags] select articles with specified tags
     * @param {Date} [from] filter articles published after date
     * @param {Date} [to] filter articles published before date
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {string} [searchString] Support common search functionality:  unquoted text: text not inside quote marks will be converted to terms separated by &amp; operators.  \&quot;quoted text\&quot;: text inside quote marks will be converted to terms separated by &lt;-&gt; operators, as if processed by phraseto_tsquery.  OR: the word “or” will be converted to the | operator.  -: a dash will be converted to the ! operator. 
     * @param {Array<Exclude>} [exclude] Invert filter for the specified field.
     * @param {boolean} [bookmarked] return bookmarked articles only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getAllArticlesRaw(requestParameters: GetAllArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>>;

    /**
     * List all articles
     */
    getAllArticles(requestParameters: GetAllArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList>;

    /**
     * 
     * @summary List actions from all teams where user is member
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {Array<InsightsSelector>} [selector] select which actions should be selected
     * @param {Array<number>} [importance] filter activities with specific importance
     * @param {Array<string>} [teamTags] filter activities with specific tags
     * @param {Array<string>} [commentedBy] filter activities commented by specific users
     * @param {Array<string>} [assignees] filter activities assigned to specific users
     * @param {Array<string>} [attachedBy] filter by attachments made by users
     * @param {Date} [dueFrom] filter activities with due date after date
     * @param {Date} [dueTo] filter activities with due date before date
     * @param {Date} [performedFrom] filter activities performed after date
     * @param {Date} [performedTo] filter activities performed before date
     * @param {Date} [from] filter articles published after date
     * @param {Date} [to] filter articles published before date
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {ArchivedOptions} [archived] Select archive status. Default is not-archived
     * @param {boolean} [uncompletedAssignedOnly] returns only not completed assigned articles
     * @param {Array<string>} [teams] select articles from specified teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getAllTeamActionsRaw(requestParameters: GetAllTeamActionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>>;

    /**
     * List actions from all teams where user is member
     */
    getAllTeamActions(requestParameters: GetAllTeamActionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList>;

    /**
     * 
     * @summary get article
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getArticleRaw(requestParameters: GetArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleResponse>>;

    /**
     * get article
     */
    getArticle(requestParameters: GetArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleResponse>;

    /**
     * 
     * @summary List all articles in team
     * @param {string} teamId 
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {boolean} [unreadOnly] return unread articles only
     * @param {Array<string>} [publishers] select articles from specified publisher
     * @param {Array<string>} [regions] select articles from specified region
     * @param {Array<string>} [types] select articles with specified types
     * @param {Array<string>} [tags] select articles with specified tags
     * @param {Date} [from] filter articles published after date
     * @param {Date} [to] filter articles published before date
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {string} [searchString] Support common search functionality:  unquoted text: text not inside quote marks will be converted to terms separated by &amp; operators.  \&quot;quoted text\&quot;: text inside quote marks will be converted to terms separated by &lt;-&gt; operators, as if processed by phraseto_tsquery.  OR: the word “or” will be converted to the | operator.  -: a dash will be converted to the ! operator. 
     * @param {Array<Exclude>} [exclude] Invert filter for the specified field.
     * @param {ArchivedOptions} [archived] Select archive status. Default is not-archived
     * @param {boolean} [bookmarked] return bookmarked articles only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getArticlesForTeamRaw(requestParameters: GetArticlesForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>>;

    /**
     * List all articles in team
     */
    getArticlesForTeam(requestParameters: GetArticlesForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList>;

    /**
     * 
     * @summary List all articles with recent activities
     * @param {string} companyId 
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {Array<InsightsSelector>} [selector] select which actions should be selected
     * @param {Array<number>} [importance] filter activities with specific importance
     * @param {Array<string>} [teamTags] filter activities with specific tags
     * @param {Array<string>} [commentedBy] filter activities commented by specific users
     * @param {Array<string>} [assignees] filter activities assigned to specific users
     * @param {Array<string>} [attachedBy] filter by attachments made by users
     * @param {Date} [dueFrom] filter activities with due date after date
     * @param {Date} [dueTo] filter activities with due date before date
     * @param {Date} [performedFrom] filter activities performed after date
     * @param {Date} [performedTo] filter activities performed before date
     * @param {Date} [from] filter articles published after date
     * @param {Date} [to] filter articles published before date
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {ArchivedOptions} [archived] Select archive status. Default is not-archived
     * @param {boolean} [uncompletedAssignedOnly] returns only not completed assigned articles
     * @param {Array<string>} [teams] select articles from specified teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getCompanyActionsArticlesRaw(requestParameters: GetCompanyActionsArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>>;

    /**
     * List all articles with recent activities
     */
    getCompanyActionsArticles(requestParameters: GetCompanyActionsArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList>;

    /**
     * 
     * @summary List all articles with recent activities
     * @param {string} companyId 
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {Array<TeamAction>} [actions] query action types.
     * @param {Date} [performedFrom] filter activities performed after date
     * @param {Date} [performedTo] filter activities performed before date
     * @param {Array<string>} [performedBy] query actions performed by users
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {Array<string>} [teams] select articles from specified teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getCompanyActivityLogArticlesRaw(requestParameters: GetCompanyActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>>;

    /**
     * List all articles with recent activities
     */
    getCompanyActivityLogArticles(requestParameters: GetCompanyActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList>;

    /**
     * 
     * @summary List all articles with recent activities
     * @param {string} teamId 
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {Array<InsightsSelector>} [selector] select which actions should be selected
     * @param {Array<number>} [importance] filter activities with specific importance
     * @param {Array<string>} [teamTags] filter activities with specific tags
     * @param {Array<string>} [commentedBy] filter activities commented by specific users
     * @param {Array<string>} [assignees] filter activities assigned to specific users
     * @param {Array<string>} [attachedBy] filter by attachments made by users
     * @param {Date} [dueFrom] filter activities with due date after date
     * @param {Date} [dueTo] filter activities with due date before date
     * @param {Date} [performedFrom] filter activities performed after date
     * @param {Date} [performedTo] filter activities performed before date
     * @param {Date} [from] filter articles published after date
     * @param {Date} [to] filter articles published before date
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {ArchivedOptions} [archived] Select archive status. Default is not-archived
     * @param {boolean} [uncompletedAssignedOnly] returns only not completed assigned articles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getTeamActionsArticlesRaw(requestParameters: GetTeamActionsArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>>;

    /**
     * List all articles with recent activities
     */
    getTeamActionsArticles(requestParameters: GetTeamActionsArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList>;

    /**
     * 
     * @summary List all articles with recent activities
     * @param {string} teamId 
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {Array<TeamAction>} [actions] query action types.
     * @param {Date} [performedFrom] filter activities performed after date
     * @param {Date} [performedTo] filter activities performed before date
     * @param {Array<string>} [performedBy] query actions performed by users
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getTeamActivityLogArticlesRaw(requestParameters: GetTeamActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>>;

    /**
     * List all articles with recent activities
     */
    getTeamActivityLogArticles(requestParameters: GetTeamActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList>;

    /**
     * 
     * @summary List all all activities of article for team
     * @param {string} teamId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getTeamActivityLogForArticleRaw(requestParameters: GetTeamActivityLogForArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ActivityList>>;

    /**
     * List all all activities of article for team
     */
    getTeamActivityLogForArticle(requestParameters: GetTeamActivityLogForArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ActivityList>;

    /**
     * 
     * @summary List all articles with activity performed by user
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {Array<TeamAction>} [actions] query action types.
     * @param {Date} [performedFrom] filter activities performed after date
     * @param {Date} [performedTo] filter activities performed before date
     * @param {Array<OrderOptions>} [orderBy] Order result. Order determined by appeareance of items.
     * @param {Array<string>} [teams] select articles from specified teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    getUserActivityLogArticlesRaw(requestParameters: GetUserActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>>;

    /**
     * List all articles with activity performed by user
     */
    getUserActivityLogArticles(requestParameters: GetUserActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList>;

    /**
     * 
     * @summary List all available filters
     * @param {FilterSearchField} filterSearchField select filter to get
     * @param {boolean} [unreadOnly] return unread articles only
     * @param {Array<string>} [publishers] select articles from specified publisher
     * @param {Array<string>} [regions] select articles from specified region
     * @param {Array<string>} [types] select articles with specified types
     * @param {Array<string>} [tags] select articles with specified tags
     * @param {Date} [from] filter articles published after date
     * @param {Date} [to] filter articles published before date
     * @param {string} [searchString] Support common search functionality:  unquoted text: text not inside quote marks will be converted to terms separated by &amp; operators.  \&quot;quoted text\&quot;: text inside quote marks will be converted to terms separated by &lt;-&gt; operators, as if processed by phraseto_tsquery.  OR: the word “or” will be converted to the | operator.  -: a dash will be converted to the ! operator. 
     * @param {string} [teamId] require that articles are subscribed in a specified team
     * @param {string} [offset] The id of the last item in the previous pagination
     * @param {string} [filterSearchString] use this string to search specified column
     * @param {Array<Exclude>} [exclude] Invert filter for the specified field.
     * @param {ArchivedOptions} [archived] Select archive status. Default is not-archived
     * @param {number} [limit] maximum number of records to return
     * @param {boolean} [bookmarked] return bookmarked articles only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApiInterface
     */
    searchAvailableArticleFiltersRaw(requestParameters: SearchAvailableArticleFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FilterSearchResult>>;

    /**
     * List all available filters
     */
    searchAvailableArticleFilters(requestParameters: SearchAvailableArticleFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FilterSearchResult>;

}

/**
 * 
 */
export class ArticlesApi extends runtime.BaseAPI implements ArticlesApiInterface {

    /**
     * Count number number of assignments to user (unarchived teams)
     */
    async countAssignmentsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Count>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/assigned-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountFromJSON(jsonValue));
    }

    /**
     * Count number number of assignments to user (unarchived teams)
     */
    async countAssignments(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Count> {
        const response = await this.countAssignmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Count number of unarchvied articles which is overdue
     */
    async countOverdueArticlesRaw(requestParameters: CountOverdueArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Count>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling countOverdueArticles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/actions/teams/{teamId}/overdue`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountFromJSON(jsonValue));
    }

    /**
     * Count number of unarchvied articles which is overdue
     */
    async countOverdueArticles(requestParameters: CountOverdueArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Count> {
        const response = await this.countOverdueArticlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all articles
     */
    async getAllArticlesRaw(requestParameters: GetAllArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>> {
        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.unreadOnly !== undefined) {
            queryParameters['unreadOnly'] = requestParameters.unreadOnly;
        }

        if (requestParameters.publishers) {
            queryParameters['publishers'] = requestParameters.publishers.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.regions) {
            queryParameters['regions'] = requestParameters.regions.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.searchString !== undefined) {
            queryParameters['searchString'] = requestParameters.searchString;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.bookmarked !== undefined) {
            queryParameters['bookmarked'] = requestParameters.bookmarked;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/articles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleListFromJSON(jsonValue));
    }

    /**
     * List all articles
     */
    async getAllArticles(requestParameters: GetAllArticlesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList> {
        const response = await this.getAllArticlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List actions from all teams where user is member
     */
    async getAllTeamActionsRaw(requestParameters: GetAllTeamActionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>> {
        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.selector) {
            queryParameters['selector'] = requestParameters.selector.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.importance) {
            queryParameters['importance'] = requestParameters.importance.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.teamTags) {
            queryParameters['teamTags'] = requestParameters.teamTags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.commentedBy) {
            queryParameters['commentedBy'] = requestParameters.commentedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.assignees) {
            queryParameters['assignees'] = requestParameters.assignees.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.attachedBy) {
            queryParameters['attachedBy'] = requestParameters.attachedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.dueFrom !== undefined) {
            queryParameters['dueFrom'] = (requestParameters.dueFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.dueTo !== undefined) {
            queryParameters['dueTo'] = (requestParameters.dueTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedFrom !== undefined) {
            queryParameters['performedFrom'] = (requestParameters.performedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedTo !== undefined) {
            queryParameters['performedTo'] = (requestParameters.performedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.archived !== undefined) {
            queryParameters['archived'] = requestParameters.archived;
        }

        if (requestParameters.uncompletedAssignedOnly !== undefined) {
            queryParameters['uncompletedAssignedOnly'] = requestParameters.uncompletedAssignedOnly;
        }

        if (requestParameters.teams) {
            queryParameters['teams'] = requestParameters.teams.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/actions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleListFromJSON(jsonValue));
    }

    /**
     * List actions from all teams where user is member
     */
    async getAllTeamActions(requestParameters: GetAllTeamActionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList> {
        const response = await this.getAllTeamActionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get article
     */
    async getArticleRaw(requestParameters: GetArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleResponse>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getArticle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/article/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleResponseFromJSON(jsonValue));
    }

    /**
     * get article
     */
    async getArticle(requestParameters: GetArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleResponse> {
        const response = await this.getArticleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all articles in team
     */
    async getArticlesForTeamRaw(requestParameters: GetArticlesForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getArticlesForTeam.');
        }

        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.unreadOnly !== undefined) {
            queryParameters['unreadOnly'] = requestParameters.unreadOnly;
        }

        if (requestParameters.publishers) {
            queryParameters['publishers'] = requestParameters.publishers.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.regions) {
            queryParameters['regions'] = requestParameters.regions.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.searchString !== undefined) {
            queryParameters['searchString'] = requestParameters.searchString;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.archived !== undefined) {
            queryParameters['archived'] = requestParameters.archived;
        }

        if (requestParameters.bookmarked !== undefined) {
            queryParameters['bookmarked'] = requestParameters.bookmarked;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/articles/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleListFromJSON(jsonValue));
    }

    /**
     * List all articles in team
     */
    async getArticlesForTeam(requestParameters: GetArticlesForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList> {
        const response = await this.getArticlesForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all articles with recent activities
     */
    async getCompanyActionsArticlesRaw(requestParameters: GetCompanyActionsArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompanyActionsArticles.');
        }

        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.selector) {
            queryParameters['selector'] = requestParameters.selector.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.importance) {
            queryParameters['importance'] = requestParameters.importance.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.teamTags) {
            queryParameters['teamTags'] = requestParameters.teamTags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.commentedBy) {
            queryParameters['commentedBy'] = requestParameters.commentedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.assignees) {
            queryParameters['assignees'] = requestParameters.assignees.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.attachedBy) {
            queryParameters['attachedBy'] = requestParameters.attachedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.dueFrom !== undefined) {
            queryParameters['dueFrom'] = (requestParameters.dueFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.dueTo !== undefined) {
            queryParameters['dueTo'] = (requestParameters.dueTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedFrom !== undefined) {
            queryParameters['performedFrom'] = (requestParameters.performedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedTo !== undefined) {
            queryParameters['performedTo'] = (requestParameters.performedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.archived !== undefined) {
            queryParameters['archived'] = requestParameters.archived;
        }

        if (requestParameters.uncompletedAssignedOnly !== undefined) {
            queryParameters['uncompletedAssignedOnly'] = requestParameters.uncompletedAssignedOnly;
        }

        if (requestParameters.teams) {
            queryParameters['teams'] = requestParameters.teams.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/actions/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleListFromJSON(jsonValue));
    }

    /**
     * List all articles with recent activities
     */
    async getCompanyActionsArticles(requestParameters: GetCompanyActionsArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList> {
        const response = await this.getCompanyActionsArticlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all articles with recent activities
     */
    async getCompanyActivityLogArticlesRaw(requestParameters: GetCompanyActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompanyActivityLogArticles.');
        }

        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.actions) {
            queryParameters['actions'] = requestParameters.actions.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.performedFrom !== undefined) {
            queryParameters['performedFrom'] = (requestParameters.performedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedTo !== undefined) {
            queryParameters['performedTo'] = (requestParameters.performedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedBy) {
            queryParameters['performedBy'] = requestParameters.performedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.teams) {
            queryParameters['teams'] = requestParameters.teams.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/activity-log/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleListFromJSON(jsonValue));
    }

    /**
     * List all articles with recent activities
     */
    async getCompanyActivityLogArticles(requestParameters: GetCompanyActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList> {
        const response = await this.getCompanyActivityLogArticlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all articles with recent activities
     */
    async getTeamActionsArticlesRaw(requestParameters: GetTeamActionsArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamActionsArticles.');
        }

        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.selector) {
            queryParameters['selector'] = requestParameters.selector.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.importance) {
            queryParameters['importance'] = requestParameters.importance.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.teamTags) {
            queryParameters['teamTags'] = requestParameters.teamTags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.commentedBy) {
            queryParameters['commentedBy'] = requestParameters.commentedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.assignees) {
            queryParameters['assignees'] = requestParameters.assignees.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.attachedBy) {
            queryParameters['attachedBy'] = requestParameters.attachedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.dueFrom !== undefined) {
            queryParameters['dueFrom'] = (requestParameters.dueFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.dueTo !== undefined) {
            queryParameters['dueTo'] = (requestParameters.dueTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedFrom !== undefined) {
            queryParameters['performedFrom'] = (requestParameters.performedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedTo !== undefined) {
            queryParameters['performedTo'] = (requestParameters.performedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.archived !== undefined) {
            queryParameters['archived'] = requestParameters.archived;
        }

        if (requestParameters.uncompletedAssignedOnly !== undefined) {
            queryParameters['uncompletedAssignedOnly'] = requestParameters.uncompletedAssignedOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/actions/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleListFromJSON(jsonValue));
    }

    /**
     * List all articles with recent activities
     */
    async getTeamActionsArticles(requestParameters: GetTeamActionsArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList> {
        const response = await this.getTeamActionsArticlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all articles with recent activities
     */
    async getTeamActivityLogArticlesRaw(requestParameters: GetTeamActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamActivityLogArticles.');
        }

        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.actions) {
            queryParameters['actions'] = requestParameters.actions.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.performedFrom !== undefined) {
            queryParameters['performedFrom'] = (requestParameters.performedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedTo !== undefined) {
            queryParameters['performedTo'] = (requestParameters.performedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedBy) {
            queryParameters['performedBy'] = requestParameters.performedBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/activity-log/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleListFromJSON(jsonValue));
    }

    /**
     * List all articles with recent activities
     */
    async getTeamActivityLogArticles(requestParameters: GetTeamActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList> {
        const response = await this.getTeamActivityLogArticlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all all activities of article for team
     */
    async getTeamActivityLogForArticleRaw(requestParameters: GetTeamActivityLogForArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ActivityList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamActivityLogForArticle.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getTeamActivityLogForArticle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/activity-log/teams/{teamId}/articles/{articleId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityListFromJSON(jsonValue));
    }

    /**
     * List all all activities of article for team
     */
    async getTeamActivityLogForArticle(requestParameters: GetTeamActivityLogForArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ActivityList> {
        const response = await this.getTeamActivityLogForArticleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all articles with activity performed by user
     */
    async getUserActivityLogArticlesRaw(requestParameters: GetUserActivityLogArticlesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FeedArticleList>> {
        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.actions) {
            queryParameters['actions'] = requestParameters.actions.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.performedFrom !== undefined) {
            queryParameters['performedFrom'] = (requestParameters.performedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.performedTo !== undefined) {
            queryParameters['performedTo'] = (requestParameters.performedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.teams) {
            queryParameters['teams'] = requestParameters.teams.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/activity-log`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleListFromJSON(jsonValue));
    }

    /**
     * List all articles with activity performed by user
     */
    async getUserActivityLogArticles(requestParameters: GetUserActivityLogArticlesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FeedArticleList> {
        const response = await this.getUserActivityLogArticlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all available filters
     */
    async searchAvailableArticleFiltersRaw(requestParameters: SearchAvailableArticleFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FilterSearchResult>> {
        if (requestParameters.filterSearchField === null || requestParameters.filterSearchField === undefined) {
            throw new runtime.RequiredError('filterSearchField','Required parameter requestParameters.filterSearchField was null or undefined when calling searchAvailableArticleFilters.');
        }

        const queryParameters: any = {};

        if (requestParameters.unreadOnly !== undefined) {
            queryParameters['unreadOnly'] = requestParameters.unreadOnly;
        }

        if (requestParameters.publishers) {
            queryParameters['publishers'] = requestParameters.publishers.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.regions) {
            queryParameters['regions'] = requestParameters.regions.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.searchString !== undefined) {
            queryParameters['searchString'] = requestParameters.searchString;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['teamId'] = requestParameters.teamId;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filterSearchString !== undefined) {
            queryParameters['filterSearchString'] = requestParameters.filterSearchString;
        }

        if (requestParameters.filterSearchField !== undefined) {
            queryParameters['filterSearchField'] = requestParameters.filterSearchField;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.archived !== undefined) {
            queryParameters['archived'] = requestParameters.archived;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.bookmarked !== undefined) {
            queryParameters['bookmarked'] = requestParameters.bookmarked;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/search-article-filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterSearchResultFromJSON(jsonValue));
    }

    /**
     * List all available filters
     */
    async searchAvailableArticleFilters(requestParameters: SearchAvailableArticleFiltersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FilterSearchResult> {
        const response = await this.searchAvailableArticleFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
