import {
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  FormGridToggle,
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  ReactSelectOption,
  SecondaryButton,
  useFlash,
  useToggle,
} from '@fcg-tech/regtech-components';
import { MessageLevel } from '@fcg-tech/regtech-types';
import { Tag, TagType, Team } from '@fcg-tech/regtech-types/regeye';
import { classNames } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useApi } from '../../api/apiUtils';
import {
  getTagsForTeam,
  useAllTeams,
  useTeamTagActions,
  useTeamTags,
} from '../../api/hooks';
import { TeamsApi } from '../../api/schema';
import { ModalForm, ModalFormWrapper } from '../../components/modals';
import { portalRoot } from '../../components/Portal';
import {
  isSelectAllOptionData,
  multiSelectInLabelStyles,
  SelectAllOptionData,
  singleSelectInLabelStyles,
  useFilterSelectStyles,
} from '../../components/selectStyles';
import { MessageKeys } from '../../translations/translationTypes';
import { isHTMLElement } from '../../utils/domUtils';

interface TeamSettingsCopyTeamTagsModalProps {
  targetTeamId: string;
  onRequestClose: (settingsCopied: boolean) => void;
}

export const TeamSettingsCopyTeamTagsModal: FunctionComponent<
  TeamSettingsCopyTeamTagsModalProps
> = ({ targetTeamId, onRequestClose }) => {
  const { t } = useTranslation();
  const teamsApi = useApi<TeamsApi>('TeamsApi');
  const { data: teams } = useAllTeams();
  const { data: originalTags } = useTeamTags(targetTeamId);
  const { createTags } = useTeamTagActions(targetTeamId);
  const [sourceTeam, setSourceTeam] = useState<ReactSelectOption<Team>>();
  const addFlash = useFlash();

  const [copyDescription, , handleCopyDescriptionChange] = useToggle(false);
  const [loading, setLoading] = useState(false);

  const teamsOptions: Array<ReactSelectOption<Team>> = useMemo(
    () =>
      teams
        .filter((team) => team.id !== targetTeamId)
        .map<ReactSelectOption<Team>>((team) => ({
          label: `${team.company.name} - ${team.name}`,
          value: team,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [targetTeamId, teams],
  );

  const [teamTagsOptions, setTeamTagsOptions] = useState<
    Array<ReactSelectOption<Tag | SelectAllOptionData>>
  >([]);
  const [selectedTeamTags, setSelectedTeamTags] = useState<
    Array<ReactSelectOption<Tag>>
  >([]);

  const handleSourceTeamChange = useCallback(
    async (option: ReactSelectOption<Team>) => {
      setSourceTeam(option);

      const tags = await getTagsForTeam(option.value.id, teamsApi);
      const updatedTags = tags
        .filter((tag) => tag.active)
        .filter((tag) => {
          const re = new RegExp(`^${tag.name}$`, 'i');
          return !originalTags.some((originalTag) => re.test(originalTag.name));
        })
        .map<ReactSelectOption<Tag | SelectAllOptionData>>((tag) => ({
          label: tag.name,
          value: tag,
        }));

      updatedTags.splice(0, 0, {
        label: t(MessageKeys.LabelSelectAll),
        value: { _selectAll_: true },
      });
      setTeamTagsOptions(updatedTags);
    },
    [originalTags, t, teamsApi],
  );

  const handleSelectedTeamTagsChange = useCallback(
    (options: Array<ReactSelectOption<Tag | SelectAllOptionData>>) => {
      if (options?.find(({ value }) => isSelectAllOptionData(value))) {
        setSelectedTeamTags(
          teamTagsOptions.filter(
            (option) => !isSelectAllOptionData(option.value),
          ) as Array<ReactSelectOption<Tag>>,
        );

        if (isHTMLElement(document.activeElement)) {
          document.activeElement.blur();
        }
      } else {
        setSelectedTeamTags(options as Array<ReactSelectOption<Tag>>);
      }
    },
    [teamTagsOptions],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const [created, failed] = await createTags(
          selectedTeamTags.map((tag) => ({
            name: tag.value.name,
            description: copyDescription ? tag.value.description : '',
            active: true,
          })),
        );

        if (failed.length > 0) {
          addFlash({
            level:
              created.length === 0 ? MessageLevel.Error : MessageLevel.Warning,
            content: t(
              created.length === 0
                ? MessageKeys.TeamSettingsCopyTagsFailed
                : MessageKeys.TeamSettingsCopyTagsPartiallySuccessful,
              { tags: failed.join(', ') },
            ),
          });
        } else if (created.length > 0) {
          addFlash({
            level: MessageLevel.Success,
            content: t(MessageKeys.TeamSettingsCopyTagsSuccessful),
          });
        }
        onRequestClose(true);
      } catch (e) {
        setLoading(false);
        addFlash({
          level: MessageLevel.Error,
          content: t(MessageKeys.LabelCouldNotSave),
        });
      }
    },
    [
      addFlash,
      copyDescription,
      createTags,
      onRequestClose,
      selectedTeamTags,
      t,
    ],
  );

  const handleHide = useCallback(() => onRequestClose(false), [onRequestClose]);

  return (
    <Modal onHide={handleHide}>
      <ModalHeader>
        <ModalTitle>{t(MessageKeys.TeamSettingsCopyTeamTagsLabel)}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalFormWrapper>
          <ModalForm onSubmit={handleSubmit}>
            <FormGridRow
              className={classNames('with-select', sourceTeam && 'valid')}
            >
              <FormGridLabel>
                {t(MessageKeys.TeamSettingsCopyFromLabel)}
              </FormGridLabel>
              <FormGridControl>
                <Select
                  options={teamsOptions}
                  value={sourceTeam}
                  styles={singleSelectInLabelStyles}
                  menuPortalTarget={portalRoot}
                  onChange={handleSourceTeamChange}
                />
              </FormGridControl>
            </FormGridRow>
            {sourceTeam ? (
              <>
                <FormGridRow
                  className={classNames(
                    'with-select',
                    selectedTeamTags?.length && 'valid',
                  )}
                >
                  <FormGridLabel>
                    {t(MessageKeys.TeamSettingsSelectTeamTags)}
                  </FormGridLabel>
                  <FormGridControl>
                    <Select
                      options={teamTagsOptions}
                      value={selectedTeamTags}
                      isMulti
                      closeMenuOnSelect={false}
                      styles={multiSelectInLabelStyles}
                      menuPortalTarget={portalRoot}
                      onChange={handleSelectedTeamTagsChange}
                    />
                  </FormGridControl>
                </FormGridRow>
                <FormGridRow
                  className={classNames(
                    'with-toggle',
                    copyDescription && 'valid',
                  )}
                >
                  <FormGridLabel>
                    {t(MessageKeys.TeamSettingsIncludeDescription)}
                  </FormGridLabel>
                  <FormGridControl>
                    <FormGridToggle
                      checked={copyDescription}
                      onChange={handleCopyDescriptionChange}
                    />
                  </FormGridControl>
                </FormGridRow>
              </>
            ) : null}
            <ModalButtonRow>
              <SecondaryButton
                type="button"
                onClick={() => onRequestClose(false)}
                disabled={loading}
              >
                {t(MessageKeys.LabelCancel)}
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                disabled={!sourceTeam}
                loading={loading}
              >
                {t(MessageKeys.LabelSubmit)}
              </PrimaryButton>
            </ModalButtonRow>
          </ModalForm>
        </ModalFormWrapper>
      </ModalBody>
    </Modal>
  );
};
