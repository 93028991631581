import { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

interface AssignedTeamMamberStatusIconProps {
  color?: string;
}

export const AssignedTeamMamberStatusIcon: FunctionComponent<
  AssignedTeamMamberStatusIconProps
> = ({ color = '#ccc' }) => {
  return (
    <svg
      className="icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <circle cx="9" cy="9" r="9" className="fill-circle" fill={color} />
      <circle cx="9" cy="9" r="8.5" stroke={color} />
      <path
        d="M13 6L6.8125 12.1875L4 9.375"
        stroke="#EDF5F7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
