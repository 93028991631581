import {
  ConfirmDialog,
  SuspenseLoader,
  useApplicationStateAnyActivity,
  useApplicationStateMutationKey,
  useConfirmDialog,
  useFlash,
} from '@fcg-tech/regtech-components';
import { Team } from '@fcg-tech/regtech-types/regeye';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../api/schema';
import { MessageKeys } from '../../translations/translationTypes';
import { canCompanyTeamDelete } from '../../utils/iamHelpers';
import { ErrorBoundary } from '../ErrorBoundary';
import { useIamContext } from '../IamContext';
import {
  TeamAdministrationDeleteTeamButton,
  TeamAdministrationTeamDetailsDangerZone,
  TeamAdministrationWrapper,
} from './TeamAdministration.styles';
import {
  TeamAdministrationTeamDetails,
  TeamAdministrationTeamDetailsPropsCallbacks,
} from './TeamAdministrationTeamDetails';
import {
  TeamAdministrationTeamMemberDetails,
  TeamAdministrationTeamMemberDetailsPropsCallbacks,
} from './TeamAdministrationTeamMemberDetails';

interface TeamAdministrationProps
  extends TeamAdministrationTeamMemberDetailsPropsCallbacks,
    TeamAdministrationTeamDetailsPropsCallbacks {
  company: Company;
  team: Team;
  onTeamDelete: (companyId: string, teamId: string) => Promise<void>;
}

export const TeamAdministration: FunctionComponent<TeamAdministrationProps> = ({
  company,
  team,
  onTeamDelete,
  onTeamUpdate,
  onAddSelfToTeam,
  onCreateTeamInvitation,
  onLeaveTeam,
  onRemoveTeamInvitation,
  onRemoveTeamMember,
}) => {
  const { t } = useTranslation();
  const iamContext = useIamContext();
  const companyId = company.id;

  const [showDeleteTeamConfirm, handleDeleteTeamConfirm, confirmDeleteTeam] =
    useConfirmDialog();

  const canDelete = canCompanyTeamDelete(companyId, iamContext);
  const { isMutating, setIsMutating } =
    useApplicationStateMutationKey('deleteTeam');

  const networkActivity = useApplicationStateAnyActivity();

  const addFlash = useFlash();

  const handleDeleteClick = useCallback(async () => {
    if (await confirmDeleteTeam()) {
      setIsMutating(true);
      try {
        await onTeamDelete(company.id, team.id);
        addFlash({
          level: 'success',
          content: t(
            MessageKeys.AdministrationTeamDetailsDeleteTeamSuccessLabel,
          ),
        });
      } catch (error) {
        console.error(error);
      }
      setIsMutating(false);
    }
  }, [
    addFlash,
    company.id,
    confirmDeleteTeam,
    onTeamDelete,
    setIsMutating,
    t,
    team.id,
  ]);

  return (
    <TeamAdministrationWrapper>
      {showDeleteTeamConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.LabelConfirmAction)}
          body={t(MessageKeys.AdministrationTeamDetailsDeleteTeamConfirmLabel, {
            name: team.name,
          })}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleDeleteTeamConfirm}
        />
      ) : null}

      <ErrorBoundary>
        <SuspenseLoader>
          <TeamAdministrationTeamDetails
            team={team}
            onTeamUpdate={onTeamUpdate}
          />
          <TeamAdministrationTeamMemberDetails
            team={team}
            onAddSelfToTeam={onAddSelfToTeam}
            onCreateTeamInvitation={onCreateTeamInvitation}
            onLeaveTeam={onLeaveTeam}
            onRemoveTeamInvitation={onRemoveTeamInvitation}
            onRemoveTeamMember={onRemoveTeamMember}
          />
          {canDelete ? (
            <TeamAdministrationTeamDetailsDangerZone>
              <TeamAdministrationDeleteTeamButton
                loading={isMutating}
                disabled={networkActivity}
                onClick={handleDeleteClick}
              >
                {t(MessageKeys.AdministrationTeamDetailsDeleteTeamLabel, {
                  name: team?.name,
                })}
              </TeamAdministrationDeleteTeamButton>
            </TeamAdministrationTeamDetailsDangerZone>
          ) : null}
        </SuspenseLoader>
      </ErrorBoundary>
    </TeamAdministrationWrapper>
  );
};
