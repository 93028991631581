import styled, { css } from 'styled-components';

export const RichTextAreaWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
    border: 1px solid ${theme?.colors?.border ?? 'rgba(0,0,0,0.3)'};
    border-radius: 4px;
    font-size: 1.4rem;
    padding: 1rem;
    font-family: ${theme?.typography?.fontFamily ?? 'initial'};
    background: inherit;
    overflow-y: auto;

    &:focus {
      outline: none;
      border-color: ${theme?.palette?.MainColor?.main ?? 'rgba(0,0,0,0.7)'};
    }

    &.error,
    &.error:focus {
      border-color: ${theme?.colors?.error?.main ?? 'crimson'};
    }

    & .slate-editor {
      min-height: 100% !important;
    }

    & .rich-text--paragraph {
      margin: 0;
    }

    ${theme?.elements?.textArea?.input}
  `};
`;

export const RichTextPopupWrapper = styled.div`
  position: absolute;
  z-index: 20;
  transform: translateY(-102%);
  min-width: 30rem;
  max-width: 90vw;
`;

export const RichTextMention = styled.span`
  ${({ theme }) => css`
    font-weight: 600;
    color: ${theme.colors?.info?.dark ?? 'darkblue'};
    &.current-user {
      color: ${theme.palette?.MainColorAccent?.dark ?? 'darkblue'};
    }
  `}
`;
