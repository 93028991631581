import { useTranslation } from 'react-i18next';
import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  FormRow,
  H3,
  InputCheckbox,
  ModalBody,
  ModalButtonRow,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useFlash,
  useInput,
} from '@fcg-tech/regtech-components';
import { MessageLevel } from '@fcg-tech/regtech-types';
import { useIsMountedRef } from '@fcg-tech/regtech-utils';
import { FunctionComponent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCompanyActions, useCompanyTeams } from '../../../api/hooks';
import { routes } from '../../../routes';
import { MessageKeys } from '../../../translations/translationTypes';
import { ModalFormLabel } from '../../modals';

interface JoinTeamSetupWizardTeamListProps {
  companyId: string;
  onRequestClose: () => void;
}

export const JoinTeamSetupWizardTeamList: FunctionComponent<
  JoinTeamSetupWizardTeamListProps
> = ({ companyId, onRequestClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setupState, setSetupState] = useState<'create' | 'join'>('join');
  const { data: teams } = useCompanyTeams(companyId);
  const [loading, setLoading] = useState(false);
  const { addSelfToTeam, createTeam } = useCompanyActions();
  const [teamName, handleTeamNameChange] = useInput('');
  const [joinedTeams, setJoinedTeams] = useState<string[]>([]);

  const addFlash = useFlash();
  const isMountedRef = useIsMountedRef();

  const handleJoinTeamChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setJoinedTeams((old) => {
        const { checked, value } = event.target;
        const set = new Set(old);
        if (checked) {
          set.add(value);
        } else {
          set.delete(value);
        }
        return [...set];
      });
    },
    [],
  );

  const handleJoinTeamCommit = useCallback(async () => {
    try {
      setLoading(true);
      await Promise.all(
        joinedTeams.map(async (teamId) => {
          await addSelfToTeam(companyId, teamId);
        }),
      );
      addFlash({
        level: MessageLevel.Success,
        content: t(MessageKeys.WizardJoinTeamSuccessMessage),
      });
      onRequestClose();
    } catch (error) {
      if (isMountedRef.current) {
        setLoading(false);
      }
      addFlash({
        level: MessageLevel.Error,
        error,
        content: t(MessageKeys.LabelCouldNotSave),
      });
    }
  }, [
    addFlash,
    addSelfToTeam,
    companyId,
    isMountedRef,
    joinedTeams,
    onRequestClose,
    t,
  ]);

  const handleCreateTeamCommit = useCallback(async () => {
    try {
      setLoading(true);
      const team = await createTeam(companyId, teamName);
      await addSelfToTeam(companyId, team.id);
      addFlash({
        level: MessageLevel.Success,
        content: t(MessageKeys.WizardJoinTeamSuccessMessage),
      });
      onRequestClose();
      navigate(
        constructUrl(routes.teamSettingsPage, {
          teamId: team.id,
          settingKey: 'subscriptions',
        }),
      );
    } catch (error) {
      if (isMountedRef.current) {
        setLoading(false);
      }
      addFlash({
        level: MessageLevel.Error,
        error,
        content: t(MessageKeys.LabelCouldNotSave),
      });
    }
  }, [
    addFlash,
    addSelfToTeam,
    companyId,
    createTeam,
    isMountedRef,
    navigate,
    onRequestClose,
    t,
    teamName,
  ]);

  const handleToggleMode = useCallback(
    () => setSetupState((old) => (old === 'create' ? 'join' : 'create')),
    [],
  );

  return <>
    <ModalBody>
      <H3>
        {setupState === 'create' ? (
          t(MessageKeys.WizardCreateNewTeam)
        ) : (
          t(MessageKeys.WizardJoinExistingTeam)
        )}
      </H3>
      {setupState === 'join'
        ? teams.map((team) => (
            <FormRow key={team.id} className="slim">
              <ModalFormLabel>{team.name}</ModalFormLabel>
              <InputCheckbox
                value={team.id}
                checked={joinedTeams.includes(team.id)}
                disabled={loading}
                onChange={handleJoinTeamChange}
              />
            </FormRow>
          ))
        : null}
      {setupState === 'create' ? (
        <FormRow>
          <ModalFormLabel>
            {t(MessageKeys.WizardLabelTeamName)}
          </ModalFormLabel>
          <TextField
            value={teamName}
            disabled={loading}
            onChange={handleTeamNameChange}
          />
        </FormRow>
      ) : null}
    </ModalBody>
    <ModalFooter>
      <ModalButtonRow className="space-between">
        <SecondaryButton disabled={loading} onClick={handleToggleMode}>
          {setupState === 'create' ? (
            t(MessageKeys.WizardJoinExistingTeamInstead)
          ) : (
            t(MessageKeys.WizardCreateNewTeamInstead)
          )}
        </SecondaryButton>
        <PrimaryButton
          loading={loading}
          disabled={
            (setupState === 'create' && !teamName.length) ||
            (setupState === 'join' && !joinedTeams.length)
          }
          onClick={
            setupState === 'create'
              ? handleCreateTeamCommit
              : handleJoinTeamCommit
          }
        >
          {t(setupState === 'create'
            ? MessageKeys.WizardCreateTeamButtonLabel
            : MessageKeys.WizardJoinTeamsButtonLabel)}
        </PrimaryButton>
      </ModalButtonRow>
    </ModalFooter>
  </>;
};
