import { DataTableRow, DataTableRowProps } from '@fcg-tech/regtech-datatable';
import { StoredFilter } from '@fcg-tech/regtech-types/regeye';
import { useIsMountedRef, useMouseHover } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { GlobalActions, TeamActions } from '../../api/schema';
import { useAccessControl } from '../AccessControl';
import { DataTableActionBar } from '../datatable/DataTableActionBar';

export type RowData = Pick<StoredFilter, 'id' | 'name' | 'filterType'>;
export type ColumnKeys = 'name' | 'filterType' | 'edit' | 'delete';
interface FilterListTableRowProps extends DataTableRowProps<RowData> {
  onRemove?: (filterId: string) => Promise<unknown>;
  onEdit?: (filterId: string) => void;
}

export const FilterListTableRow: FunctionComponent<FilterListTableRowProps> = ({
  onRemove,
  onEdit,
  ...props
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const isMounted = useIsMountedRef();
  const filter = props.row.original;

  const handleRemoveClick = useCallback(async () => {
    setIsRemoving(true);
    try {
      await onRemove(filter.id);
    } finally {
      if (isMounted.current) {
        setIsRemoving(false);
      }
    }
  }, [filter.id, isMounted, onRemove]);

  const handleEditClick = useCallback(() => {
    onEdit(filter.id);
  }, [onEdit, filter.id]);

  const { isMouseOver, ...hoverProps } = useMouseHover();

  return (
    <DataTableRow {...props} {...hoverProps}>
      {isMouseOver ? (
        <div className="tr-hover-container" data-kind="hover">
          <DataTableActionBar
            isRemoving={isRemoving}
            onRemove={onRemove ? handleRemoveClick : undefined}
            onEdit={onEdit ? handleEditClick : undefined}
          />
        </div>
      ) : null}
    </DataTableRow>
  );
};
