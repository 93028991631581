import {
  FeedArticle,
  FeedArticleListMutator,
  PageDirection,
} from '@fcg-tech/regtech-types/regeye';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSwipeable } from 'react-swipeable';
import { FeedArticleOverlay } from '../FeedArticleOverlay';
import { FeedArticleCondensedTableWrapper } from './FeedArticleCondensedTable.styles';
import { FeedArticleCondensedTableRow } from './FeedArticleCondensedTableRow';
import { useFeedArticleActionHandlers } from './feedArticleTableHelpers';

const EXPAND_ARTICLE = 'expandArticle';

interface FeedArticleCondensedTableProps {
  feedArticles?: Array<FeedArticle>;
  loading?: boolean;
  mutateArticle?: (
    articleId: string,
    { bookmarked, read }: { bookmarked?: boolean; read?: boolean },
  ) => void;
  mutateFeedArticle?: (
    callback: FeedArticleListMutator,
    shouldRevalidate?: boolean,
  ) => void;
  onChangePageByDelta?: (direction: 1 | -1) => boolean;
}

export const FeedArticleCondensedTable: FunctionComponent<
  FeedArticleCondensedTableProps
> = ({
  feedArticles,
  mutateArticle,
  mutateFeedArticle,
  onChangePageByDelta,
}) => {
  const [expandedId, setExpanded] = useState<string | undefined | null>();

  const [previousArticle, expandedFeedArticle, nextArticle] = useMemo<
    [FeedArticle | null, FeedArticle | null, FeedArticle | null]
  >(() => {
    const expanded = feedArticles?.find((fe) => fe.internalId === expandedId);
    const index = feedArticles?.indexOf(expanded);
    return [
      index > 0 ? feedArticles?.[index - 1] : null,
      expanded ?? null,
      feedArticles?.[index + 1] ?? null,
    ];
  }, [expandedId, feedArticles]);

  const { handleArchiveChange, handleBookmarkChange, handleMarkAsReadChange } =
    useFeedArticleActionHandlers({
      mutateArticle,
      mutateFeedArticleList: mutateFeedArticle,
    });

  const handleSheetRequestClose = useCallback(() => setExpanded(null), []);

  useEffect(() => {
    const autoOpen = localStorage.getItem(EXPAND_ARTICLE);
    if (autoOpen && feedArticles) {
      localStorage.removeItem(EXPAND_ARTICLE);

      if (autoOpen === 'first') {
        setExpanded(feedArticles[0]?.internalId);
      } else {
        setExpanded(feedArticles[feedArticles.length - 1]?.internalId);
      }
    }
  }, [feedArticles]);

  const handleChangeArticle = useCallback(
    (articleIdOrPageDirection: string | PageDirection) => {
      if (articleIdOrPageDirection === PageDirection.Next) {
        localStorage.setItem(EXPAND_ARTICLE, 'first');
        setExpanded(null);
        onChangePageByDelta(1);
      } else if (articleIdOrPageDirection === PageDirection.Previous) {
        localStorage.setItem(EXPAND_ARTICLE, 'last');
        setExpanded(null);
        onChangePageByDelta(-1);
      } else {
        setExpanded(articleIdOrPageDirection);
      }
    },
    [onChangePageByDelta],
  );

  return (
    <FeedArticleCondensedTableWrapper>
      {feedArticles?.map((feedArticle) => (
        <FeedArticleCondensedTableRow
          key={feedArticle.internalId}
          feedArticle={feedArticle}
          onClick={setExpanded}
        />
      ))}
      {expandedFeedArticle ? (
        <FeedArticleOverlay
          feedArticle={expandedFeedArticle}
          previousArticleId={
            previousArticle?.internalId ?? PageDirection.Previous
          }
          nextArticleId={nextArticle?.internalId ?? PageDirection.Next}
          onChangeArticle={handleChangeArticle}
          onRequestClose={handleSheetRequestClose}
          onArchive={handleArchiveChange}
          onBookmark={handleBookmarkChange}
          onMarkAsRead={handleMarkAsReadChange}
        />
      ) : null}
    </FeedArticleCondensedTableWrapper>
  );
};
