/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommentCreateInput,
    CommentCreateInputFromJSON,
    CommentCreateInputToJSON,
    CommentResponse,
    CommentResponseFromJSON,
    CommentResponseToJSON,
    CommentUpdateInput,
    CommentUpdateInputFromJSON,
    CommentUpdateInputToJSON,
    DateInput,
    DateInputFromJSON,
    DateInputToJSON,
    DocumentLocationList,
    DocumentLocationListFromJSON,
    DocumentLocationListToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ImportanceInput,
    ImportanceInputFromJSON,
    ImportanceInputToJSON,
    TeamMetadataResponse,
    TeamMetadataResponseFromJSON,
    TeamMetadataResponseToJSON,
    UserReference,
    UserReferenceFromJSON,
    UserReferenceToJSON,
} from '../models';

export interface AddArticleAttachmentRequest {
    teamId: string;
    articleId: string;
    documentLocationList: DocumentLocationList;
}

export interface AddArticleTeamAssigneeRequest {
    teamId: string;
    articleId: string;
    userReference: UserReference;
}

export interface AddArticleTeamCommentRequest {
    teamId: string;
    articleId: string;
    commentCreateInput: CommentCreateInput;
}

export interface AddArticleTeamTagRequest {
    teamId: string;
    articleId: string;
    tagId: string;
}

export interface ArchiveArticleRequest {
    teamId: string;
    articleId: string;
}

export interface CompleteArticleTeamAssignmentRequest {
    teamId: string;
    articleId: string;
}

export interface DeleteArticleAttachmentRequest {
    teamId: string;
    articleId: string;
    attachmentId: string;
}

export interface DeleteArticleTeamAssigneeRequest {
    teamId: string;
    articleId: string;
    userReference: UserReference;
}

export interface DeleteArticleTeamCommentRequest {
    teamId: string;
    articleId: string;
    commentId: string;
}

export interface DeleteArticleTeamDueDateRequest {
    teamId: string;
    articleId: string;
}

export interface DeleteArticleTeamImportanceRequest {
    teamId: string;
    articleId: string;
}

export interface DeleteArticleTeamTagRequest {
    teamId: string;
    articleId: string;
    tagId: string;
}

export interface GetArticleMetadataForTeamRequest {
    teamId: string;
    articleId: string;
}

export interface GetArticleTeamCommentRequest {
    teamId: string;
    articleId: string;
    commentId: string;
}

export interface NotifyTeamRequest {
    teamId: string;
    articleId: string;
}

export interface SetArticleTeamDueDateRequest {
    teamId: string;
    articleId: string;
    dateInput: DateInput;
}

export interface SetArticleTeamImportanceRequest {
    teamId: string;
    articleId: string;
    importanceInput: ImportanceInput;
}

export interface UnarchiveArticleRequest {
    teamId: string;
    articleId: string;
}

export interface UncompleteArticleTeamAssignmentRequest {
    teamId: string;
    articleId: string;
}

export interface UpdateArticleTeamCommentRequest {
    teamId: string;
    articleId: string;
    commentId: string;
    commentUpdateInput: CommentUpdateInput;
}

/**
 * TeamActionApi - interface
 * 
 * @export
 * @interface TeamActionApiInterface
 */
export interface TeamActionApiInterface {
    /**
     * 
     * @summary add an attachment
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {DocumentLocationList} documentLocationList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    addArticleAttachmentRaw(requestParameters: AddArticleAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * add an attachment
     */
    addArticleAttachment(requestParameters: AddArticleAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Assign user to article
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {UserReference} userReference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    addArticleTeamAssigneeRaw(requestParameters: AddArticleTeamAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Assign user to article
     */
    addArticleTeamAssignee(requestParameters: AddArticleTeamAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Create a comment
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {CommentCreateInput} commentCreateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    addArticleTeamCommentRaw(requestParameters: AddArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Create a comment
     */
    addArticleTeamComment(requestParameters: AddArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Add team tag to article
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {string} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    addArticleTeamTagRaw(requestParameters: AddArticleTeamTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Add team tag to article
     */
    addArticleTeamTag(requestParameters: AddArticleTeamTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary archive article for team
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    archiveArticleRaw(requestParameters: ArchiveArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * archive article for team
     */
    archiveArticle(requestParameters: ArchiveArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Set the assignment to complete status
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    completeArticleTeamAssignmentRaw(requestParameters: CompleteArticleTeamAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Set the assignment to complete status
     */
    completeArticleTeamAssignment(requestParameters: CompleteArticleTeamAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Removed attached file
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {string} attachmentId the attachment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    deleteArticleAttachmentRaw(requestParameters: DeleteArticleAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Removed attached file
     */
    deleteArticleAttachment(requestParameters: DeleteArticleAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Unassign user from article
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {UserReference} userReference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    deleteArticleTeamAssigneeRaw(requestParameters: DeleteArticleTeamAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Unassign user from article
     */
    deleteArticleTeamAssignee(requestParameters: DeleteArticleTeamAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Delete a comment
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {string} commentId the comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    deleteArticleTeamCommentRaw(requestParameters: DeleteArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Delete a comment
     */
    deleteArticleTeamComment(requestParameters: DeleteArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Delete due date from article
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    deleteArticleTeamDueDateRaw(requestParameters: DeleteArticleTeamDueDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Delete due date from article
     */
    deleteArticleTeamDueDate(requestParameters: DeleteArticleTeamDueDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Delete importance from article
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    deleteArticleTeamImportanceRaw(requestParameters: DeleteArticleTeamImportanceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Delete importance from article
     */
    deleteArticleTeamImportance(requestParameters: DeleteArticleTeamImportanceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Delete team tag from article
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {string} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    deleteArticleTeamTagRaw(requestParameters: DeleteArticleTeamTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Delete team tag from article
     */
    deleteArticleTeamTag(requestParameters: DeleteArticleTeamTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Get team metadata to article
     * @param {string} teamId the team id
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    getArticleMetadataForTeamRaw(requestParameters: GetArticleMetadataForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Get team metadata to article
     */
    getArticleMetadataForTeam(requestParameters: GetArticleMetadataForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary get comment
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {string} commentId the comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    getArticleTeamCommentRaw(requestParameters: GetArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CommentResponse>>;

    /**
     * get comment
     */
    getArticleTeamComment(requestParameters: GetArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CommentResponse>;

    /**
     * 
     * @summary Notifies team of article
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    notifyTeamRaw(requestParameters: NotifyTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Notifies team of article
     */
    notifyTeam(requestParameters: NotifyTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Set due date
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {DateInput} dateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    setArticleTeamDueDateRaw(requestParameters: SetArticleTeamDueDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Set due date
     */
    setArticleTeamDueDate(requestParameters: SetArticleTeamDueDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Set importance of article
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {ImportanceInput} importanceInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    setArticleTeamImportanceRaw(requestParameters: SetArticleTeamImportanceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Set importance of article
     */
    setArticleTeamImportance(requestParameters: SetArticleTeamImportanceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary remove article from archive for team
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    unarchiveArticleRaw(requestParameters: UnarchiveArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * remove article from archive for team
     */
    unarchiveArticle(requestParameters: UnarchiveArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Set the assignment to not completed status
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    uncompleteArticleTeamAssignmentRaw(requestParameters: UncompleteArticleTeamAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Set the assignment to not completed status
     */
    uncompleteArticleTeamAssignment(requestParameters: UncompleteArticleTeamAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

    /**
     * 
     * @summary Update comment
     * @param {string} teamId The team id
     * @param {string} articleId the article id
     * @param {string} commentId the comment id
     * @param {CommentUpdateInput} commentUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamActionApiInterface
     */
    updateArticleTeamCommentRaw(requestParameters: UpdateArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>>;

    /**
     * Update comment
     */
    updateArticleTeamComment(requestParameters: UpdateArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse>;

}

/**
 * 
 */
export class TeamActionApi extends runtime.BaseAPI implements TeamActionApiInterface {

    /**
     * add an attachment
     */
    async addArticleAttachmentRaw(requestParameters: AddArticleAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling addArticleAttachment.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling addArticleAttachment.');
        }

        if (requestParameters.documentLocationList === null || requestParameters.documentLocationList === undefined) {
            throw new runtime.RequiredError('documentLocationList','Required parameter requestParameters.documentLocationList was null or undefined when calling addArticleAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/attachments`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentLocationListToJSON(requestParameters.documentLocationList),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * add an attachment
     */
    async addArticleAttachment(requestParameters: AddArticleAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.addArticleAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assign user to article
     */
    async addArticleTeamAssigneeRaw(requestParameters: AddArticleTeamAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling addArticleTeamAssignee.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling addArticleTeamAssignee.');
        }

        if (requestParameters.userReference === null || requestParameters.userReference === undefined) {
            throw new runtime.RequiredError('userReference','Required parameter requestParameters.userReference was null or undefined when calling addArticleTeamAssignee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/assign`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserReferenceToJSON(requestParameters.userReference),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Assign user to article
     */
    async addArticleTeamAssignee(requestParameters: AddArticleTeamAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.addArticleTeamAssigneeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a comment
     */
    async addArticleTeamCommentRaw(requestParameters: AddArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling addArticleTeamComment.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling addArticleTeamComment.');
        }

        if (requestParameters.commentCreateInput === null || requestParameters.commentCreateInput === undefined) {
            throw new runtime.RequiredError('commentCreateInput','Required parameter requestParameters.commentCreateInput was null or undefined when calling addArticleTeamComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/comment`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentCreateInputToJSON(requestParameters.commentCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Create a comment
     */
    async addArticleTeamComment(requestParameters: AddArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.addArticleTeamCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add team tag to article
     */
    async addArticleTeamTagRaw(requestParameters: AddArticleTeamTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling addArticleTeamTag.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling addArticleTeamTag.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling addArticleTeamTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/tags/{tagId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Add team tag to article
     */
    async addArticleTeamTag(requestParameters: AddArticleTeamTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.addArticleTeamTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * archive article for team
     */
    async archiveArticleRaw(requestParameters: ArchiveArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling archiveArticle.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling archiveArticle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/archive`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * archive article for team
     */
    async archiveArticle(requestParameters: ArchiveArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.archiveArticleRaw(requestParameters, initOverrides);
    }

    /**
     * Set the assignment to complete status
     */
    async completeArticleTeamAssignmentRaw(requestParameters: CompleteArticleTeamAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling completeArticleTeamAssignment.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling completeArticleTeamAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/assign-status`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Set the assignment to complete status
     */
    async completeArticleTeamAssignment(requestParameters: CompleteArticleTeamAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.completeArticleTeamAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removed attached file
     */
    async deleteArticleAttachmentRaw(requestParameters: DeleteArticleAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteArticleAttachment.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling deleteArticleAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteArticleAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/attachments/{attachmentId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Removed attached file
     */
    async deleteArticleAttachment(requestParameters: DeleteArticleAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.deleteArticleAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unassign user from article
     */
    async deleteArticleTeamAssigneeRaw(requestParameters: DeleteArticleTeamAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteArticleTeamAssignee.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling deleteArticleTeamAssignee.');
        }

        if (requestParameters.userReference === null || requestParameters.userReference === undefined) {
            throw new runtime.RequiredError('userReference','Required parameter requestParameters.userReference was null or undefined when calling deleteArticleTeamAssignee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/assign`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserReferenceToJSON(requestParameters.userReference),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Unassign user from article
     */
    async deleteArticleTeamAssignee(requestParameters: DeleteArticleTeamAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.deleteArticleTeamAssigneeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a comment
     */
    async deleteArticleTeamCommentRaw(requestParameters: DeleteArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteArticleTeamComment.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling deleteArticleTeamComment.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling deleteArticleTeamComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/comment/{commentId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Delete a comment
     */
    async deleteArticleTeamComment(requestParameters: DeleteArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.deleteArticleTeamCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete due date from article
     */
    async deleteArticleTeamDueDateRaw(requestParameters: DeleteArticleTeamDueDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteArticleTeamDueDate.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling deleteArticleTeamDueDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/due-date`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Delete due date from article
     */
    async deleteArticleTeamDueDate(requestParameters: DeleteArticleTeamDueDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.deleteArticleTeamDueDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete importance from article
     */
    async deleteArticleTeamImportanceRaw(requestParameters: DeleteArticleTeamImportanceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteArticleTeamImportance.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling deleteArticleTeamImportance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/importance`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Delete importance from article
     */
    async deleteArticleTeamImportance(requestParameters: DeleteArticleTeamImportanceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.deleteArticleTeamImportanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete team tag from article
     */
    async deleteArticleTeamTagRaw(requestParameters: DeleteArticleTeamTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteArticleTeamTag.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling deleteArticleTeamTag.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling deleteArticleTeamTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/tags/{tagId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Delete team tag from article
     */
    async deleteArticleTeamTag(requestParameters: DeleteArticleTeamTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.deleteArticleTeamTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get team metadata to article
     */
    async getArticleMetadataForTeamRaw(requestParameters: GetArticleMetadataForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getArticleMetadataForTeam.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getArticleMetadataForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Get team metadata to article
     */
    async getArticleMetadataForTeam(requestParameters: GetArticleMetadataForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.getArticleMetadataForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get comment
     */
    async getArticleTeamCommentRaw(requestParameters: GetArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CommentResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getArticleTeamComment.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getArticleTeamComment.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling getArticleTeamComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/comment/{commentId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentResponseFromJSON(jsonValue));
    }

    /**
     * get comment
     */
    async getArticleTeamComment(requestParameters: GetArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CommentResponse> {
        const response = await this.getArticleTeamCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Notifies team of article
     */
    async notifyTeamRaw(requestParameters: NotifyTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling notifyTeam.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling notifyTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/notify`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Notifies team of article
     */
    async notifyTeam(requestParameters: NotifyTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.notifyTeamRaw(requestParameters, initOverrides);
    }

    /**
     * Set due date
     */
    async setArticleTeamDueDateRaw(requestParameters: SetArticleTeamDueDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling setArticleTeamDueDate.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling setArticleTeamDueDate.');
        }

        if (requestParameters.dateInput === null || requestParameters.dateInput === undefined) {
            throw new runtime.RequiredError('dateInput','Required parameter requestParameters.dateInput was null or undefined when calling setArticleTeamDueDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/due-date`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DateInputToJSON(requestParameters.dateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Set due date
     */
    async setArticleTeamDueDate(requestParameters: SetArticleTeamDueDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.setArticleTeamDueDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set importance of article
     */
    async setArticleTeamImportanceRaw(requestParameters: SetArticleTeamImportanceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling setArticleTeamImportance.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling setArticleTeamImportance.');
        }

        if (requestParameters.importanceInput === null || requestParameters.importanceInput === undefined) {
            throw new runtime.RequiredError('importanceInput','Required parameter requestParameters.importanceInput was null or undefined when calling setArticleTeamImportance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/importance`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImportanceInputToJSON(requestParameters.importanceInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Set importance of article
     */
    async setArticleTeamImportance(requestParameters: SetArticleTeamImportanceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.setArticleTeamImportanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * remove article from archive for team
     */
    async unarchiveArticleRaw(requestParameters: UnarchiveArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling unarchiveArticle.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling unarchiveArticle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/archive`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * remove article from archive for team
     */
    async unarchiveArticle(requestParameters: UnarchiveArticleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.unarchiveArticleRaw(requestParameters, initOverrides);
    }

    /**
     * Set the assignment to not completed status
     */
    async uncompleteArticleTeamAssignmentRaw(requestParameters: UncompleteArticleTeamAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling uncompleteArticleTeamAssignment.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling uncompleteArticleTeamAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/assign-status`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Set the assignment to not completed status
     */
    async uncompleteArticleTeamAssignment(requestParameters: UncompleteArticleTeamAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.uncompleteArticleTeamAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update comment
     */
    async updateArticleTeamCommentRaw(requestParameters: UpdateArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamMetadataResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling updateArticleTeamComment.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling updateArticleTeamComment.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling updateArticleTeamComment.');
        }

        if (requestParameters.commentUpdateInput === null || requestParameters.commentUpdateInput === undefined) {
            throw new runtime.RequiredError('commentUpdateInput','Required parameter requestParameters.commentUpdateInput was null or undefined when calling updateArticleTeamComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-activities/{teamId}/{articleId}/comment/{commentId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CommentUpdateInputToJSON(requestParameters.commentUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Update comment
     */
    async updateArticleTeamComment(requestParameters: UpdateArticleTeamCommentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamMetadataResponse> {
        const response = await this.updateArticleTeamCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
