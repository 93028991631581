import { useTranslation } from 'react-i18next';
import {
  AccessControl as BaseAccessControl,
  AccessControlProps as BaseAccessControlProps,
  useAccessControl as useBaseAccessControl,
} from '@fcg-tech/regtech-components';
import { array, arrayIntersect, arrayUnion } from '@fcg-tech/regtech-utils';
import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { Permissions } from '../../types';
import { NoContentMessage } from '../generic';
import { useIamContext } from '../IamContext';
import { useTeamContext } from '../TeamContext/TeamContext';

const globalPermRe = /^regeye:global:/;
const teamPermRe = /^regeye:team:/;
const companyPermRe = /^regeye:company:/;

interface AccessControlProps
  extends Omit<BaseAccessControlProps<Permissions>, 'permissions'> {
  filterGlobalPermissionsInTeamContext?: boolean;
  noAccessMessageKey?: string;
  forAllTeams?: boolean;
  forAnyTeam?: boolean;
  teamId?: string;
  companyId?: string;
}

export const AccessControl: FunctionComponent<
  PropsWithChildren<AccessControlProps>
> = ({
  noAccessMessageKey,
  requiredPermissions,
  filterGlobalPermissionsInTeamContext,
  forAllTeams,
  forAnyTeam,
  teamId,
  companyId,
  ...props
}) => {
  const { t } = useTranslation();
  const [permissions, filteredRequiredPermissions] = usePermissions(
    requiredPermissions,
    {
      forAllTeams,
      forAnyTeam,
      filterGlobalPermissionsInTeamContext,
      teamId,
      companyId,
    },
  );

  const renderNoAccess = useMemo(
    () =>
      props.renderNoAccess ??
      (noAccessMessageKey
        ? () => <NoContentMessage>{t(noAccessMessageKey)}</NoContentMessage>
        : null),
    [noAccessMessageKey, props.renderNoAccess, t],
  );

  return (
    <BaseAccessControl
      {...props}
      renderNoAccess={renderNoAccess}
      requiredPermissions={filteredRequiredPermissions}
      permissions={permissions}
    />
  );
};

export const useAccessControl = (
  requiredPermissions: Permissions | Array<Permissions>,
  opts: {
    inverted?: boolean;
    filterGlobalPermissionsInTeamContext?: boolean;
    forAllTeams?: boolean;
    forAnyTeam?: boolean;
    teamId?: string;
    companyId?: string;
  } = {},
) => {
  const [permissions, filteredRequiredPermissions] = usePermissions(
    requiredPermissions,
    {
      filterGlobalPermissionsInTeamContext:
        opts.filterGlobalPermissionsInTeamContext,
      forAllTeams: opts.forAllTeams,
      forAnyTeam: opts.forAnyTeam,
    },
  );

  const allowed = useBaseAccessControl(
    permissions,
    filteredRequiredPermissions,
  );
  return opts.inverted ? !allowed : allowed;
};

const usePermissions = (
  requiredPermissions: Permissions | Array<Permissions>,
  opts: {
    filterGlobalPermissionsInTeamContext?: boolean;
    forAllTeams?: boolean;
    forAnyTeam?: boolean;
    teamId?: string;
    companyId?: string;
  } = {},
) => {
  const iam = useIamContext();
  const team = useTeamContext();

  const {
    filterGlobalPermissionsInTeamContext,
    forAllTeams,
    forAnyTeam,
    teamId: forceTeamId,
    companyId: forceCompanyId,
  } = opts;

  const teamId = forceTeamId ?? team?.teamId;
  const companyId = forceCompanyId ?? team?.companyId;

  const permissions = useMemo<Array<Permissions>>(() => {
    if (forAllTeams) {
      return [
        ...iam.globalPermissions,
        ...arrayIntersect(...Object.values(iam.teamPermissions)),
        ...((companyId ? iam.companyPermissions[companyId] : null) ?? []),
      ];
    }
    if (forAnyTeam) {
      return [
        ...iam.globalPermissions,
        ...arrayUnion(...Object.values(iam.teamPermissions)),
        ...((companyId ? iam.companyPermissions[companyId] : null) ?? []),
      ];
    }

    return [
      ...iam.globalPermissions,
      ...((teamId ? iam.teamPermissions[teamId] : null) ?? []),
      ...((companyId ? iam.companyPermissions[companyId] : null) ?? []),
    ];
  }, [
    forAllTeams,
    forAnyTeam,
    iam.globalPermissions,
    iam.teamPermissions,
    iam.companyPermissions,
    teamId,
    companyId,
  ]);

  const filteredRequiredPermissions = useMemo(() => {
    let filtered = array(requiredPermissions);
    if (!teamId && !forAllTeams && !forAnyTeam) {
      // Not in a team context, so team-specific permissions can be removed
      filtered = filtered.filter((perm) => !perm.match(teamPermRe));
    }

    if (!companyId) {
      // Not in a company context, so company-specific permissions can be removed
      filtered = filtered.filter((perm) => !perm.match(companyPermRe));
    }

    if ((teamId || companyId) && filterGlobalPermissionsInTeamContext) {
      filtered = filtered.filter((perm) => !perm.match(globalPermRe));
    }

    return filtered;
  }, [
    companyId,
    filterGlobalPermissionsInTeamContext,
    forAllTeams,
    forAnyTeam,
    requiredPermissions,
    teamId,
  ]);

  return [permissions, filteredRequiredPermissions];
};
