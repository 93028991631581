import React, { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { ActionsArticlesTable } from './ActionsArticlesTable';

type Params = {
  teamId?: string;
  companyId?: string;
};

export const ActionsArticlesPage: FunctionComponent = () => {
  const params = useParams<Params>();
  const location = useLocation();

  return (
    <ErrorBoundary key={location.pathname}>
      <SuspenseLoader>
        <ActionsArticlesTable
          companyId={params.companyId}
          teamId={params.teamId}
        />
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
