import { Importance, truncateSentence } from '@fcg-tech/regtech-components';
import { RecentTeamActivity } from '@fcg-tech/regtech-types/regeye';
import { escapeHTML, stripHtml } from '@fcg-tech/regtech-utils';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useSettings } from '../../api/hooks';
import { TeamAction } from '../../api/schema';
import { ArticleTeamEventTag } from '../../components/Tag';
import { MessageKeys } from '../../translations/translationTypes';
import {
  formatDate,
  formatName,
  formatTeamActivityAction,
} from '../../utils/formatters';
import { FeedArticleTextContent } from '../FeedArticleTable/FeedArticleTable.styles';
import {
  TeamArticlesEventDescripionHighlight,
  TeamArticlesEventDescripionWrapper,
} from './TeamArticlesEventDescripion.styles';
import { TeamArticlesEventDescriptionImportance } from './TeamArticlesEventDescriptionImportance';

interface TeamActivityEventDescriptionProps {
  truncate?: number;
  recentTeamActivity: RecentTeamActivity;
}

export const TeamActivityEventDescription: FunctionComponent<
  TeamActivityEventDescriptionProps
> = ({ truncate, recentTeamActivity }) => {
  const { t } = useTranslation();
  const { data: settings } = useSettings({ suspense: false });
  const theme = useTheme();

  if (!recentTeamActivity) {
    return null;
  }

  const createdByUser = formatName(
    recentTeamActivity.createdByUser,
    settings?.userNamePreference,
  );

  const targetUser = formatName(
    recentTeamActivity.targetUser,
    settings?.userNamePreference,
  );

  const truncateLength = truncate ?? Number.MAX_VALUE;
  let children = null;
  const title = formatTeamActivityAction(recentTeamActivity, t, settings);

  switch (recentTeamActivity.action) {
    case TeamAction.Assigned:
    case TeamAction.Unassigned:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          {t(
            recentTeamActivity.action === 'ASSIGNED'
              ? MessageKeys.TeamActivityDescriptionAssigned
              : MessageKeys.TeamActivityDescriptionUnAssigned,
            {
              targetName: escapeHTML(targetUser),
              name: escapeHTML(createdByUser),
            },
          )}
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.Commented:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={MessageKeys.TeamActivityDescriptionCommented as string}
            values={{
              name: escapeHTML(createdByUser),
              comment: truncateSentence(
                recentTeamActivity.value,
                truncateLength,
              ),
            }}
            components={{
              comment: <TeamArticlesEventDescripionHighlight />,
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.EditedComment:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={
              MessageKeys.TeamActivityDescriptionUpdatedComment as string
            }
            values={{
              name: escapeHTML(createdByUser),
              comment: truncateSentence(
                recentTeamActivity.value,
                truncateLength,
              ),
            }}
            components={{
              comment: <TeamArticlesEventDescripionHighlight />,
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.RemovedComment:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={
              MessageKeys.TeamActivityDescriptionRemovedComment as string
            }
            values={{
              name: escapeHTML(createdByUser),
              comment: truncateSentence(
                recentTeamActivity.value,
                truncateLength,
              ),
            }}
            components={{
              comment: <TeamArticlesEventDescripionHighlight />,
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.Tagged:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={MessageKeys.TeamActivityDescriptionTagged as string}
            values={{
              name: escapeHTML(createdByUser),
            }}
            components={{
              tag: (
                <ArticleTeamEventTag
                  theme={theme}
                  className="team-tag"
                  tag={recentTeamActivity.value}
                />
              ),
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.Untagged:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={MessageKeys.TeamActivityDescriptionUntagged as string}
            values={{ name: escapeHTML(createdByUser) }}
            components={{
              tag: (
                <ArticleTeamEventTag
                  className="team-tag"
                  theme={theme}
                  tag={recentTeamActivity.value}
                  disabled
                />
              ),
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.SetDueDate:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={MessageKeys.TeamActivityDescriptionSetDueDate as string}
            values={{
              name: escapeHTML(createdByUser),
              date: formatDate(recentTeamActivity.value, settings),
            }}
            components={{
              date: <TeamArticlesEventDescripionHighlight />,
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.UpdatedDueDate:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={MessageKeys.TeamActivityDescriptionUpdateDueDate as string}
            values={{
              name: escapeHTML(createdByUser),
              date: formatDate(recentTeamActivity.value, settings),
              previousDate: recentTeamActivity.targetValue
                ? formatDate(recentTeamActivity.targetValue, settings)
                : 'N/A',
            }}
            components={{
              date: <TeamArticlesEventDescripionHighlight />,
              previousDate: (
                <TeamArticlesEventDescripionHighlight deemphasize />
              ),
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.RemovedDueDate:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={
              MessageKeys.TeamActivityDescriptionRemovedDueDate as string
            }
            values={{
              name: escapeHTML(createdByUser),
              date: formatDate(recentTeamActivity.value, settings),
            }}
            components={{
              date: <TeamArticlesEventDescripionHighlight />,
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.SetImportance:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={MessageKeys.TeamActivityDescriptionSetImportance as string}
            values={{ name: escapeHTML(createdByUser) }}
            components={{
              importance: (
                <TeamArticlesEventDescriptionImportance
                  importance={Number(recentTeamActivity.value) as Importance}
                />
              ),
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.RemovedImportance:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={
              MessageKeys.TeamActivityDescriptionRemovedImportance as string
            }
            values={{ name: escapeHTML(createdByUser) }}
            components={{
              importance: (
                <TeamArticlesEventDescriptionImportance
                  importance={Number(recentTeamActivity.value) as Importance}
                />
              ),
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.UpdatedImportance:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          <Trans
            i18nKey={
              MessageKeys.TeamActivityDescriptionUpdatedImportance as string
            }
            values={{ name: escapeHTML(createdByUser) }}
            components={{
              importance: (
                <TeamArticlesEventDescriptionImportance
                  importance={Number(recentTeamActivity.value) as Importance}
                />
              ),
              previousImportance: recentTeamActivity.targetValue ? (
                <TeamArticlesEventDescriptionImportance
                  importance={
                    Number(recentTeamActivity.targetValue) as Importance
                  }
                />
              ) : (
                <span>N/A</span>
              ),
            }}
          />
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.Attached:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          {t(MessageKeys.TeamActivityDescriptionAttachmentAdded, {
            name: createdByUser,
            attachmentName: recentTeamActivity.value,
          })}
        </FeedArticleTextContent>
      );
      break;
    case TeamAction.Unattached:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          {t(MessageKeys.TeamActivityDescriptionAttachmentRemoved, {
            name: createdByUser,
            attachmentName: recentTeamActivity.value,
          })}
        </FeedArticleTextContent>
      );
      break;

    case TeamAction.CompletedAssigned:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          {t(MessageKeys.TeamActivityDescriptionCompletedAssigned, {
            name: createdByUser,
          })}
        </FeedArticleTextContent>
      );
      break;

    case TeamAction.UncompletedAssigned:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          {t(MessageKeys.TeamActivityDescriptionUncompletedAssigned, {
            name: createdByUser,
          })}
        </FeedArticleTextContent>
      );
      break;

    case TeamAction.Archived:
    case TeamAction.Unarchived:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          {t(
            recentTeamActivity.action === TeamAction.Unarchived
              ? MessageKeys.TeamActivityDescriptionUnarchived
              : MessageKeys.TeamActivityDescriptionArchived,
            {
              name: createdByUser,
            },
          )}
        </FeedArticleTextContent>
      );
      break;

    case TeamAction.Notified:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          {t(MessageKeys.TeamActivityDescriptionNotified, {
            name: createdByUser,
          })}
        </FeedArticleTextContent>
      );
      break;

    default:
      children = (
        <FeedArticleTextContent title={stripHtml(title)}>
          {recentTeamActivity.action}
        </FeedArticleTextContent>
      );
  }

  return (
    <TeamArticlesEventDescripionWrapper>
      {children}
    </TeamArticlesEventDescripionWrapper>
  );
};
