/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PublisherList,
    PublisherListFromJSON,
    PublisherListToJSON,
    SubscriptionUpdate,
    SubscriptionUpdateFromJSON,
    SubscriptionUpdateToJSON,
} from '../models';

export interface CompanyGetTeamSubscriptionRequest {
    companyId: string;
    teamId: string;
}

export interface CompanyUpdateTeamSubscriptionRequest {
    companyId: string;
    teamId: string;
    subscriptionUpdate: SubscriptionUpdate;
}

export interface GetTeamSubscriptionRequest {
    teamId: string;
}

export interface UpdateTeamSubscriptionRequest {
    teamId: string;
    subscriptionUpdate: SubscriptionUpdate;
}

/**
 * PublishersApi - interface
 * 
 * @export
 * @interface PublishersApiInterface
 */
export interface PublishersApiInterface {
    /**
     * 
     * @summary List all publisher with indicator if subscribed in team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApiInterface
     */
    companyGetTeamSubscriptionRaw(requestParameters: CompanyGetTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PublisherList>>;

    /**
     * List all publisher with indicator if subscribed in team
     */
    companyGetTeamSubscription(requestParameters: CompanyGetTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PublisherList>;

    /**
     * 
     * @summary Update subscriptions of team
     * @param {string} companyId the company id
     * @param {string} teamId the team id
     * @param {SubscriptionUpdate} subscriptionUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApiInterface
     */
    companyUpdateTeamSubscriptionRaw(requestParameters: CompanyUpdateTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update subscriptions of team
     */
    companyUpdateTeamSubscription(requestParameters: CompanyUpdateTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary List all publisher
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApiInterface
     */
    getPublishersRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PublisherList>>;

    /**
     * List all publisher
     */
    getPublishers(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PublisherList>;

    /**
     * 
     * @summary List all publisher with indicator if subscribed in team
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApiInterface
     */
    getTeamSubscriptionRaw(requestParameters: GetTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PublisherList>>;

    /**
     * List all publisher with indicator if subscribed in team
     */
    getTeamSubscription(requestParameters: GetTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PublisherList>;

    /**
     * 
     * @summary Update subscriptions of team
     * @param {string} teamId the team id
     * @param {SubscriptionUpdate} subscriptionUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApiInterface
     */
    updateTeamSubscriptionRaw(requestParameters: UpdateTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update subscriptions of team
     */
    updateTeamSubscription(requestParameters: UpdateTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

}

/**
 * 
 */
export class PublishersApi extends runtime.BaseAPI implements PublishersApiInterface {

    /**
     * List all publisher with indicator if subscribed in team
     */
    async companyGetTeamSubscriptionRaw(requestParameters: CompanyGetTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PublisherList>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyGetTeamSubscription.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyGetTeamSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/subscription`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublisherListFromJSON(jsonValue));
    }

    /**
     * List all publisher with indicator if subscribed in team
     */
    async companyGetTeamSubscription(requestParameters: CompanyGetTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PublisherList> {
        const response = await this.companyGetTeamSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update subscriptions of team
     */
    async companyUpdateTeamSubscriptionRaw(requestParameters: CompanyUpdateTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling companyUpdateTeamSubscription.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling companyUpdateTeamSubscription.');
        }

        if (requestParameters.subscriptionUpdate === null || requestParameters.subscriptionUpdate === undefined) {
            throw new runtime.RequiredError('subscriptionUpdate','Required parameter requestParameters.subscriptionUpdate was null or undefined when calling companyUpdateTeamSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/companies/{companyId}/teams/{teamId}/subscription`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionUpdateToJSON(requestParameters.subscriptionUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update subscriptions of team
     */
    async companyUpdateTeamSubscription(requestParameters: CompanyUpdateTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.companyUpdateTeamSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     * List all publisher
     */
    async getPublishersRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PublisherList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/publishers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublisherListFromJSON(jsonValue));
    }

    /**
     * List all publisher
     */
    async getPublishers(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PublisherList> {
        const response = await this.getPublishersRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all publisher with indicator if subscribed in team
     */
    async getTeamSubscriptionRaw(requestParameters: GetTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PublisherList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/subscription`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublisherListFromJSON(jsonValue));
    }

    /**
     * List all publisher with indicator if subscribed in team
     */
    async getTeamSubscription(requestParameters: GetTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PublisherList> {
        const response = await this.getTeamSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update subscriptions of team
     */
    async updateTeamSubscriptionRaw(requestParameters: UpdateTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling updateTeamSubscription.');
        }

        if (requestParameters.subscriptionUpdate === null || requestParameters.subscriptionUpdate === undefined) {
            throw new runtime.RequiredError('subscriptionUpdate','Required parameter requestParameters.subscriptionUpdate was null or undefined when calling updateTeamSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/teams/{teamId}/subscription`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionUpdateToJSON(requestParameters.subscriptionUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update subscriptions of team
     */
    async updateTeamSubscription(requestParameters: UpdateTeamSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.updateTeamSubscriptionRaw(requestParameters, initOverrides);
    }

}
