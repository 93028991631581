import { GridLayoutMainWrapper } from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useAllArticles } from '../../api/hooks';
import { GenericPaginatedFeedArticlePage } from '../GenericPaginatedFeedArticlePage/GenericPaginatedFeedArticlePage';

export const FeedArticleSearchPage: FunctionComponent = () => {
  return (
    <GridLayoutMainWrapper className="overflow-hidden">
      <GenericPaginatedFeedArticlePage
        useFeedArticles={useAllArticles}
        withFilterToggle
      />
    </GridLayoutMainWrapper>
  );
};
