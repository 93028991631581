import { formatApiDate } from '@fcg-tech/regtech-api-utils';
import { DateRangeInterval } from '@fcg-tech/regtech-filter';
import { ApiDateFilter } from '@fcg-tech/regtech-types/regeye';

export const dateConverter = <T, K extends keyof T>(
  item: T,
  ...dateProperties: Array<K>
): Omit<T, K> & { [k in K]?: Date } => {
  if (!item) {
    return undefined;
  }
  const converted = { ...item };
  for (const key of dateProperties) {
    if (item[key]) {
      converted[key] = new Date(
        item[key] as unknown as string,
      ) as unknown as T[K];
    }
  }

  return converted as unknown as Omit<T, K> & { [k in K]?: Date };
};

export const convertToDateFilter = ({
  from,
  to,
  interval,
}: DateRangeInterval = {}): ApiDateFilter | undefined => {
  // Do not save dates if there is an interval set.
  return from || to || interval
    ? {
        from: from && !interval ? formatApiDate(from) : undefined,
        to: to && !interval ? formatApiDate(to) : undefined,
        interval: interval?.toString(),
      }
    : undefined;
};
