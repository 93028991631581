import { useTranslation } from 'react-i18next';
import {
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  TextArea,
  TextField,
  TooltipNext as Tooltip,
} from '@fcg-tech/regtech-components';
import { AlertCircle } from 'lucide-react';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import { getEmailValidator } from '../../utils/validators';
import {
  TeamMultiInvitationTip,
  TeamMultiInvitationWrapper,
} from './TeamMultiInvitation.styles';
import {
  getEmails,
  getNewlineSeparatedEmails,
} from './teamMultiInvitationUtils';
import { classNames } from '@fcg-tech/regtech-utils';

interface TeamMultiInvitationProps {
  placeholder?: string;
  onChange: (emails: Array<string>) => void;
}

export const TeamMultiInvitation: FunctionComponent<
  TeamMultiInvitationProps
> = ({ placeholder, onChange }) => {
  const { t } = useTranslation();
  const emailValidator = useMemo(() => getEmailValidator(t), [t]);
  const [value, setValue] = useState<string>('');
  const [emails, setEmails] = useState<Array<string>>([]);
  const [multiMode, setMultiMode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const textFieldRef = useRef<HTMLInputElement>();
  const textAreaRef = useRef<HTMLTextAreaElement>();

  const focusDone = useRef<boolean>(false);

  const [inputError, setInputError] = useState<boolean>(false);

  const handleChange = useCallback(
    (
      event:
        | React.FormEvent<HTMLInputElement>
        | React.FormEvent<HTMLTextAreaElement>,
    ) => {
      const value = (event.target as HTMLInputElement | HTMLTextAreaElement)
        .value;
      setValue(value);
      try {
        setInputError(false);
        const emails = getEmails(value);

        const errorMessage = emails.reduce(
          (msg, email) => msg ?? (email ? emailValidator(email) : undefined),
          null,
        );

        setErrorMessage(errorMessage);
        if (errorMessage) {
          setEmails(undefined);
          onChange(undefined);
        } else {
          setEmails(emails);
          onChange(emails);
        }
      } catch (e) {
        setEmails(undefined);
      }
    },
    [emailValidator, onChange],
  );

  const handleTextFieldKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      focusDone.current = false;
      setMultiMode(true);
      setValue((old) => old + '\n');
      setEmails((old) => (old ? [...old, ''] : old));
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  const handleBulkUpdate = useCallback(
    (value: string) => {
      setValue(value);
      const emails = getEmails(value);

      setMultiMode((old) => old || emails.length > 1);

      const errorMessage = emails.reduce(
        (msg, email) => msg ?? (email ? emailValidator(email) : undefined),
        null,
      );

      setErrorMessage(errorMessage);
      if (errorMessage) {
        setEmails(undefined);
        onChange(undefined);
      } else {
        setEmails(emails);
        onChange(emails);
      }
    },
    [emailValidator, onChange],
  );

  const handlePasteOrBlur = useCallback(
    (
      event:
        | React.ClipboardEvent<HTMLTextAreaElement | HTMLInputElement>
        | React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      if (event.type === 'blur') {
        focusDone.current = true;
        setInputError(
          Boolean(
            document.activeElement !== textFieldRef.current &&
              document.activeElement !== textAreaRef.current &&
              errorMessage?.length,
          ),
        );
      }
      const target = event.currentTarget;
      setTimeout(() => {
        const value = getNewlineSeparatedEmails(target.value);
        handleBulkUpdate(value);
      }, 1);
    },
    [errorMessage?.length, handleBulkUpdate],
  );

  useEffect(() => {
    if (multiMode && !focusDone.current) {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
        textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd =
          textAreaRef.current?.value.length ?? 0;
        focusDone.current = true;
      }
    } else {
      textFieldRef.current?.focus();
    }
  }, [multiMode]);

  const control = (
    <FormGridControl key="control">
      <TeamMultiInvitationWrapper className={classNames(multiMode && 'multi')}>
        {multiMode ? (
          <TextArea
            ref={textAreaRef}
            name="members"
            error={inputError}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onPaste={handlePasteOrBlur}
            onBlur={handlePasteOrBlur}
          />
        ) : (
          <TextField
            ref={textFieldRef}
            name="members"
            error={inputError}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onKeyDown={handleTextFieldKeyDown}
            onPaste={handlePasteOrBlur}
            onBlur={handlePasteOrBlur}
          />
        )}
        {!multiMode && emails?.[0]?.length ? (
          <TeamMultiInvitationTip>
            <AlertCircle size={18} />
            {t(MessageKeys.TeamMultiInvitationEnableMultiTip)}
          </TeamMultiInvitationTip>
        ) : null}
        {multiMode ? (
          <TeamMultiInvitationTip>
            <AlertCircle size={18} />
            {t(MessageKeys.TeamMultiInvitationSeparateWithNewlineTip)}
          </TeamMultiInvitationTip>
        ) : null}
      </TeamMultiInvitationWrapper>
    </FormGridControl>
  );

  return (
    <FormGridRow
      className={classNames(
        errorMessage && 'invalid',
        !errorMessage && emails?.length && 'valid',
      )}
    >
      <Tooltip
        content={errorMessage}
        forceOpen={inputError}
        placement="right"
        error={inputError}
      >
        {control}
      </Tooltip>
      <FormGridLabel>{t(MessageKeys.LabelEmailMaybePlural)}</FormGridLabel>
    </FormGridRow>
  );
};
