import {
  BooleanTreeItem,
  UseTooltipResult,
} from '@fcg-tech/regtech-components';
import { SortingRule } from '@fcg-tech/regtech-datatable';
import {
  AllowedFilter,
  RelativeDateInterval,
  StoredFilter as GenericStoredFilter,
} from '@fcg-tech/regtech-filter';
import { Descendant } from 'slate';
import { SWRConfiguration, SWRResponse } from 'swr';
import {
  ArchivedOptions,
  Comment as ApiComment,
  Company,
  CompanyActions,
  DateFilter as ApiDateFilter,
  DocumentLocation as ApiDocumentLocation,
  FeedArticle as ApiFeedArticle,
  Filter as ApiFilter,
  FilterType,
  GlobalActions,
  OrderOptions,
  Pagination,
  StoredFilter as ApiStoredFilter,
  StoredFilterList as ApiStoredFilterList,
  Tag as ApiTag,
  Team,
  TeamAction,
  TeamActions,
  TeamArticleMetadata as ApiTeamArticleMetadata,
  TeamAttachment as ApiTeamAttachment,
  User,
} from './api/schema';

export type { Translator } from '@fcg-tech/regtech-types';
export {
  CompanyActions,
  Exclude as FilterExclude,
  FilterSearchField,
  FilterType,
  GlobalActions,
  InsightsSelector,
  OrderOptions,
  TeamAction,
  TeamActions,
  NotificationSetting,
} from './api/schema';
export type {
  Article,
  ArticleExpandedMetadata,
  ArticlesApi,
  TeamsApi,
  FiltersApi,
  InitApi,
  TeamActionApi,
  ExcelApi,
  AssignedUser,
  AvailableEventLogFilters,
  AvailableInsightFilters,
  Company,
  CompanyInput,
  CompanyUpdateInput,
  Count,
  CreationMetadata,
  DateFilter as ApiDateFilter,
  DocumentLocation as ApiDocumentLocation,
  FeedArticle as ApiFeedArticle,
  FeedArticleList,
  FileInfoWithS3Url,
  Filter as ApiFilter,
  FilterListItem,
  FilterSearchResult,
  GetTeamActivityLogArticlesRequest,
  GetUserActivityLogArticlesRequest,
  ImportanceInput,
  InitData,
  Pagination,
  Publisher,
  PublisherList,
  RecentTeamActivity,
  Region,
  SearchAvailableArticleFiltersRequest,
  StoredFilter as ApiStoredFilter,
  StoredFilterList as ApiStoredFilterList,
  SubscriptionUpdate,
  SubscriptionUpdateItemsInner,
  Tag as ApiTag,
  Team,
  TeamArticleMetadata as ApiTeamArticleMetadata,
  TeamInvitation,
  TeamInvitationList,
  TeamList,
  TeamMetadataResponse,
  TeamReadMetadata,
  TeamTagList,
  TranslatedFilter,
  User,
} from './api/schema';

export type Tag = ApiTag & {
  originalName?: string;
};

export type Comment = Omit<ApiComment, 'markup'> & {
  markup: Array<Descendant>;
};

export type TeamArticleMetadata = Omit<ApiTeamArticleMetadata, 'comments'> & {
  comments: Array<Comment>;
};

export type IndexSignatureHack<T> = {
  [K in keyof T]: IndexSignatureHack<T[K]>;
};

export type FeedArticle = ApiFeedArticle & {
  internalId?: string;
};

export type Username = User['username'];
export type ID = string;

export enum TableType {
  Generic = 'generic',
  Actions = 'actions',
  ActionLog = 'actionsLog',
}

export enum TableSubType {
  Archive = 'archive',
  Search = 'search',
}

export enum FeedArticleDomain {
  Personal = 'personal',
  Team = 'team',
  Company = 'company',
  Search = 'search',
}

export enum PersonalDomain {
  AllArticles = 'allarticles',
  Bookmarked = 'bookmarked',
  Assigned = 'assigned',
  ActionLog = 'actionlog',
  Commented = 'commented',
}

export type Ids = {
  teamId?: string;
  regionId?: string;
  publisherId?: string;
  articleTypeId?: string;
};

export enum NameOrder {
  Alias = 'alias',
  Email = 'email',
}

export type TeamSettings = {
  id: string;
  hidden?: boolean;
};

export type CompanySettings = {
  id: string;
  hidden?: boolean;
};

export enum RegeyeShowcase {
  NewUser = 'newUser',
  GenericFeedArticleTable = 'genericFeedArticleTable',
  TeamActions = 'teamActions',
  NewDataTable = 'newDataTableShowcase',
  NewTabs = 'newTabsShowcase',
  MarkAsRead = 'markAsReadShowcase',

  WhatsNewAugust2022 = 'whatsNewAugustShowcase2022',
}

export type Settings = {
  version?: number;
  dateFormat?: string;
  dateTimeformat?: string;
  userNamePreference?: NameOrder;
  startOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 = sunday
  dateFnsLocale?: 'sv';
  markReadAfterMs?: number;
  pinnedFilters?: Array<string>;
  feedArticleReaderWidth?: number;
  companies?: Array<CompanySettings>;
  teams?: Array<TeamSettings>;
  deferredShowcases?: Array<RegeyeShowcase>;
  flags?: {
    hideFilterDraftRestoredNotification?: boolean;
    automaticallyRestoreFilterDrafts?: boolean;
    doNotConfirmSendArticleTeamNotifications?: boolean;
  };
};

export type SettingsV1Diff = {
  columns: Record<TableType, Array<FeedArticleTableColumns>>;
};

export type WithDates<T, K extends keyof T> = Omit<T, K> & {
  [Key in K]?: Date;
};

export type GenericFeedArticleFetchContext = {
  filter?: FilterValues;
  bookmarked?: boolean;

  teamId?: string;
};

export type GenericFeedArticleHook = (
  context: GenericFeedArticleFetchContext,
  page?: number,
  pageSize?: number,
  config?: SWRConfiguration,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => SWRResponse<PaginatedItems<FeedArticle>, any>;

export enum FeedArticleTableColumns {
  Action = 'action',
  ActionDate = 'actiondate',
  Assigned = 'assigned',
  Content = 'content',
  Date = 'date',
  DueDate = 'duedate',
  Importance = 'importance',
  Publisher = 'publisher',
  Tags = 'tags',
  TeamTags = 'teamtags',
  Teams = 'teams',
  Title = 'title',
  Type = 'type',
}

export enum TagType {
  Tag = 'tag',
  TeamTag = 'teamTag',
  ArticleTypeTag = 'articleTypeTag',
}

export type StoredFeedArticleTableColumnOptions = {
  width: number | string;
  order: number;
  visible: boolean;
};

export type FeedArticleTableSortableColumns =
  | FeedArticleTableColumns.Publisher
  | FeedArticleTableColumns.Title
  | FeedArticleTableColumns.Content
  | FeedArticleTableColumns.ActionDate
  | FeedArticleTableColumns.Date;

export type FeedArticleTableCustomColumn = {
  label: string;
  getContent: (feedArticle: FeedArticle) => string | React.ReactNode;
  width?: string;
};

export const isCustomColumnDescriptor = (
  obj: unknown,
): obj is FeedArticleTableCustomColumn =>
  obj && typeof obj === 'object' && Reflect.has(obj, 'label');

export type FeedArticleTableSortOrder = SortingRule<FeedArticle>;

export type FeedArticleMetadataMutationCallbacks = {
  onBookmark?: (isBookmarked: boolean, articleId: string) => void;
  onMarkAsRead?: (isRead: boolean, articleId: string) => void;
  onArchive?: (isArchived: boolean, articleId: string, teamId: string) => void;
};

export type FeeedArticleTableRowContext = {
  onBookmark?: () => void;
  onArchive?: () => void;
  onMarkAsRead?: () => void;
  markAsReadTooltip?: UseTooltipResult<HTMLButtonElement>;
  bookmarkTooltip?: UseTooltipResult<HTMLButtonElement>;
  archivedTooltip?: UseTooltipResult<HTMLButtonElement>;
  teamId?: string;
  team?: Team;
  companyId?: string;
  company?: Company;
};

export enum PageDirection {
  Next = 1,
  Previous = -1,
}

export type FilterLike = {
  id: string;
  name: string;
  personalFilter: boolean;
};

export type FilterOptionItem<T = ID> = { id: T; name: string };

export type DocumentLocation = Omit<ApiDocumentLocation, 'ref'> & {
  ref: File | string;
};

export type TeamAttachment = Omit<ApiTeamAttachment, 'documentLocation'> & {
  documentLocation?: DocumentLocation;
};

export type AttachmentDiff = {
  newFiles: Array<DocumentLocation>;
  removedFiles: Array<DocumentLocation>;
};

export type PaginatedItems<T> = {
  items: Array<T>;
  pagination: Pagination;
};

export type DateInterval = {
  from?: Date;
  to?: Date;
};

export type DateFilter = Omit<ApiDateFilter, 'interval'> & {
  interval?: RelativeDateInterval;
};

export type Filter = AllowedFilter<ApiFilter>;

export type StoredFilter = GenericStoredFilter<Filter> &
  Omit<ApiStoredFilter, 'filter'> & {
    filter: Filter;
  };

export type StoredFilterList = Omit<ApiStoredFilterList, 'items'> & {
  items: Array<StoredFilter>;
};

export type FilterValues = Filter & {
  performedBy?: Array<Username>;
  actions?: Array<TeamAction>;

  orderBy?: Array<OrderOptions>;
  archived?: ArchivedOptions;
  unreadOnly?: boolean;
};

export type FilterConfig = {
  companyId?: string;
  teamId?: string;
  tableType?: TableType;
  tableSubType?: TableSubType;
};

export type ExtendedApiFilter = Filter & {
  orderBy?: Array<OrderOptions>;
  skip?: number;
  limit?: number;
  unreadOnly?: boolean;
};

//export type FilterSearchField = SearchAvailableArticleFiltersRequest['filterSearchField'];

export enum Importance {
  Unspecified = 0,
  Unimportant = 1,
  SlightlyImportant = 2,
  ModeratelyImportant = 3,
  Important = 4,
  VeryImportant = 5,
}

export type CustomFeedTagsAndTypes = {
  tags?: Array<Tag>;
  types?: Array<Tag>;
};

export enum SubscriptionEnumType {
  Region,
  Publisher,
  ArticleType,
}

export type SubscriptionItemValue = {
  id?: string;
  name?: string;
  abbreviation?: string;
  publisherId?: string;
  type: SubscriptionEnumType;
  nrOfArticleTypes?: number;
  nrOfSubscribedArticleTypes?: number;
  regionName?: string;
};

export type SubscriptionItem = BooleanTreeItem<SubscriptionItemValue>;

export type SearchRow = {
  id: string;
  inverted?: boolean;
  operator?: 'AND' | 'OR';
  text: string;
};

export enum GlobalSearchMode {
  GlobalNoFilter = 'gn',
  GlobalWithFilter = 'gw',
  LocalNoFilter = 'ln',
  LocalWithFilter = 'lw',
}

export type MutatorParamType = PaginatedItems<FeedArticle>;
export interface FeedArticleListMutator {
  (value: MutatorParamType): MutatorParamType | Promise<MutatorParamType>;
}

export type BooleanToggleCallback = (
  value: boolean,
  ...args: Array<unknown>
) => unknown;

export type Permissions = GlobalActions | TeamActions | CompanyActions;

export type BaseDraftIdent = Record<string, string> | string;

export type BaseDraft<T extends BaseDraftIdent> = {
  id: T;
  updated?: Date;
};

export type BaseRichTextDraft<T extends BaseDraftIdent> = BaseDraft<T> & {
  type: string;
  content?: string;
  markup?: Array<Descendant>;
  active?: boolean;
};

export type CommentDraftIdent = {
  articleId: string;
  teamId: string;
  editId?: string;
  replyToId?: string;
};

export type CommentDraft = BaseRichTextDraft<CommentDraftIdent>;

export type FilterDraftIdent = {
  teamId?: string;
  filterType?: FilterType | 'event-log' | string;
};

export type FilterDraft = BaseDraft<FilterDraftIdent> & {
  stringifiedFilter: string;
};

export type DraftStorage<T extends BaseDraftIdent, D extends BaseDraft<T>> = {
  drafts?: Array<D>;
};
