import { useTranslation } from 'react-i18next';
import { AlertBox } from '@fcg-tech/regtech-components';
import { MessageLevel } from '@fcg-tech/regtech-types';
import {
  Company,
  Team,
  TeamInvitation as TeamInvitationType,
} from '@fcg-tech/regtech-types/regeye';
import React, { FunctionComponent, useMemo } from 'react';
import { useAllTeams, useCompanies, useInvitations } from '../../api/hooks';
import { AccessControl } from '../../components/AccessControl';
import { useIamContext } from '../../components/IamContext';
import { MessageKeys } from '../../translations/translationTypes';
import { GlobalActions } from '../../types';
import { canViewPageCompaniesAdministration } from '../../utils/iamHelpers';
import { MyProfileCompany } from './MyProfileCompany';
import { MyProfileSection } from './MyProfilePage.styles';

export const MyProfileMyCompaniesAndTeamsTab: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data: teams, error: teamsLoadError } = useAllTeams();
  const { data: companies } = useCompanies();
  const { data: invitations } = useInvitations();
  const iamContext = useIamContext();

  const isAdmin = canViewPageCompaniesAdministration(iamContext);

  const companyTeams = useMemo<
    Array<
      [company: Company, teams: Array<Team>, invites: Array<TeamInvitationType>]
    >
  >(() => {
    if (!teams) {
      return [];
    }

    const companiesAndTeams = companies.map<
      [company: Company, teams: Array<Team>, invites: Array<TeamInvitationType>]
    >((company) => {
      const companyTeams = teams.filter(
        (team) => team.company.id === company.id,
      );

      const companyTeamInvitations = invitations.filter(
        (invitation) => invitation.team.company.id === company.id,
      );
      return [company, companyTeams, companyTeamInvitations];
    });

    const newCompanies = new Set(
      invitations
        .filter(
          (invitation) =>
            !companies.some((c) => c.id === invitation.team.company.id),
        )
        .map((invitation) => invitation.team.company.id),
    );

    const newCompanyInvitations = Array.from(newCompanies).map<
      [company: Company, teams: Array<Team>, invites: Array<TeamInvitationType>]
    >((id) => {
      const companyInvitations = invitations.filter(
        (i) => i.team.company.id === id,
      );

      return [companyInvitations[0].team.company, [], companyInvitations];
    });

    return [...newCompanyInvitations, ...companiesAndTeams];
  }, [companies, teams, invitations]);

  return (
    <MyProfileSection>
      <AccessControl
        requiredPermissions={GlobalActions.MyTeamList}
        noAccessMessageKey={MessageKeys.AccessErrorPersonalTeamList}
      >
        {companyTeams?.map(([company, teams, invitations]) => (
          <MyProfileCompany
            key={company.id}
            company={company}
            teams={teams}
            invitations={invitations}
          />
        ))}
      </AccessControl>
      {teamsLoadError ? (
        <AlertBox level={MessageLevel.Info}>
          {t(
            isAdmin
              ? MessageKeys.FallbackNoCompaniesHasTeamsMessageForAdmin
              : MessageKeys.FallbackNoCompaniesHasTeamsMessage,
          )}
        </AlertBox>
      ) : null}
    </MyProfileSection>
  );
};
