import { AuthError, getResponse } from '@fcg-tech/regtech-api-utils';
import { Component, PropsWithChildren } from 'react';
import { getLogger } from '../../api/logger';
import { ErrorMessage } from './ErrorMessage';

export class ErrorBoundary extends Component<
  PropsWithChildren<{ silentAuthError?: boolean }>
> {
  override state = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: Boolean(error), error };
  }

  override componentDidCatch(error: Error) {
    const response = getResponse(this.state.error);
    getLogger().error('A UI error occured', {
      path: window.location.toString(),
      userAgent: window.navigator.userAgent,
      name: error.name,
      message: error.message,
      stack: error.stack,
      requestUrl: response?.url ?? 'N/A',
      responseCode: response?.status ?? 'N/A',
    });
  }

  override render() {
    if (this.state.hasError) {
      if (this.state.error instanceof AuthError) {
        throw this.state.error;
      }
      const response = getResponse(this.state.error);
      if (response?.status === 401) {
        if (this.props.silentAuthError) {
          return null;
        }
        throw new AuthError('Not authenticated: 401');
      }
      if (response?.status === 403 && this.props.silentAuthError) {
        return null;
      }

      return <ErrorMessage error={this.state.error} />;
    }

    return this.props.children;
  }
}
