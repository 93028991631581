import { useCallback, useEffect, useRef, useState } from 'react';

export type UsePanelResizeProps<
  W extends HTMLElement,
  R extends HTMLElement,
  P extends HTMLElement,
> = {
  paneRef: React.RefObject<P>;
  resizeHandleRef: React.RefObject<R>;
  wrapperRef?: React.RefObject<W>;
  initialWidth?: number | undefined | null;
  onAfterResize?: (width: number) => number | undefined;
  onAfterResizeEnded?: (width: number) => number | undefined;
};

export type UsePanelResizeState = {
  isDragging: boolean;
  getWidth: () => number | undefined;
};

export const usePaneResize = <
  W extends HTMLElement,
  R extends HTMLElement,
  P extends HTMLElement,
>({
  paneRef,
  resizeHandleRef,
  initialWidth,
  onAfterResize,
  onAfterResizeEnded,
  wrapperRef,
}: UsePanelResizeProps<W, R, P>): UsePanelResizeState => {
  const [isDragging, setIsDragging] = useState(false);
  const mouseDownX = useRef<number>();
  const contentWidth = useRef<number | undefined>();

  const getWidth = useCallback(() => contentWidth.current, []);

  const eventListeners = useRef<{
    onMouseUp?: (event: MouseEvent) => void;
    onMouseMove?: (event: MouseEvent) => void;
  }>({});

  const stopDragging = useCallback((unmounting = false) => {
    if (eventListeners.current?.onMouseUp) {
      window.removeEventListener('mouseup', eventListeners.current.onMouseUp);
    }
    if (eventListeners.current?.onMouseMove) {
      window.removeEventListener(
        'mousemove',
        eventListeners.current.onMouseMove,
      );
    }
    document.body.setAttribute('class', '');
    // Avoid state updates if we're already unmounting this component
    if (!unmounting) {
      setIsDragging(false);
    }
  }, []);

  // Make sure to release event listeners when we unmount
  useEffect(() => {
    if (initialWidth) {
      const wrapper = wrapperRef?.current ?? paneRef?.current?.parentElement;
      if (wrapper && paneRef.current) {
        const wrapperWidth = wrapper.getBoundingClientRect().width;
        contentWidth.current = initialWidth;
        if (contentWidth.current > 1) {
          // Old value. Should be 0-1
          contentWidth.current = Math.min(
            1,
            contentWidth.current / wrapperWidth,
          );
        }
        paneRef.current.setAttribute(
          'style',
          `width: ${contentWidth.current * 100}%`,
        );

        // Reset width again, since a width of 100% most likely will not be 100%
        const newWidth = paneRef.current.getBoundingClientRect().width;
        contentWidth.current = Math.min(1, newWidth / wrapperWidth);
        paneRef.current.setAttribute(
          'style',
          `width: ${contentWidth.current * 100}%`,
        );

        resizeHandleRef.current?.setAttribute(
          'style',
          `left: ${(contentWidth.current ?? 0.5) * 100}%`,
        );
      }
    }
    return () => stopDragging(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResizerMouseDown = useCallback(
    (event: MouseEvent) => {
      const wrapper = wrapperRef?.current ?? paneRef?.current?.parentElement;
      setIsDragging(true);
      document.body.setAttribute('class', 'no-select');
      eventListeners.current.onMouseMove = (event: MouseEvent) => {
        if (event.buttons === 0) {
          // This can happen if the user releases the mouse button over some area where we can't receive events
          stopDragging();
        } else {
          const diff = event.pageX - (mouseDownX.current ?? 0);
          if (wrapper && paneRef.current) {
            const wrapperWidth = wrapper.getBoundingClientRect().width;
            contentWidth.current =
              (contentWidth.current ?? 0) + diff / wrapperWidth;

            paneRef.current.setAttribute(
              'style',
              `width: ${contentWidth.current * 100}%`,
            );

            mouseDownX.current = event.pageX;

            // Reset contentWidth - the browser might have stopped the div from growing
            contentWidth.current =
              paneRef.current.getBoundingClientRect().width / wrapperWidth;

            const overrideWidth = onAfterResize?.(contentWidth.current);
            if (overrideWidth !== undefined) {
              contentWidth.current = overrideWidth;
            }

            paneRef.current.setAttribute(
              'style',
              `width: ${contentWidth.current * 100}%`,
            );

            resizeHandleRef.current?.setAttribute?.(
              'style',
              `left: ${(contentWidth.current ?? 0.5) * 100}%`,
            );
          }
        }
      };

      eventListeners.current.onMouseUp = () => {
        stopDragging();

        const overrideWidth = onAfterResizeEnded?.(contentWidth.current ?? 0);
        if (overrideWidth !== undefined) {
          contentWidth.current = overrideWidth;
        }
        if (paneRef.current) {
          paneRef.current.setAttribute(
            'style',
            `width: ${(contentWidth.current ?? 0) * 100}%`,
          );

          resizeHandleRef.current?.setAttribute?.(
            'style',
            `left: ${(contentWidth.current ?? 0.5) * 100}%`,
          );
        }
      };

      window.addEventListener('mouseup', eventListeners.current.onMouseUp);
      window.addEventListener('mousemove', eventListeners.current.onMouseMove);

      mouseDownX.current = event.pageX;
    },
    [
      onAfterResize,
      onAfterResizeEnded,
      paneRef,
      resizeHandleRef,
      stopDragging,
      wrapperRef,
    ],
  );

  useEffect(() => {
    const resizer = resizeHandleRef.current;
    if (resizer) {
      resizer.addEventListener('mousedown', handleResizerMouseDown);
    }

    return () => {
      if (resizer) {
        resizer.removeEventListener('mousedown', handleResizerMouseDown);
      }
    };
  });

  return {
    isDragging,
    getWidth,
  };
};
