import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  GridLayoutToolbarWrapper,
  TabBarNavigation,
  TabBarNavigationItem,
  TabBarPanel,
  ToolbarItemWrapper,
  ToolbarRow,
  ToolbarWrapper,
  useTabLinkState,
} from '@fcg-tech/regtech-components';
import { Clipboard, Layers, Settings } from 'lucide-react';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { FeedArticleTableContextProvider } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { getTableTypeByPath } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { FeedArticleTableTopActionBar } from '../../components/FeedArticleTable/FeedArticleTableTopActionBar';
import { useIamContext } from '../../components/IamContext';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routeFragments, routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import {
  canGetCompanyActionsArticles,
  canGetCompanyActivityLogArticles,
  canViewCompanyAdministration,
} from '../../utils/iamHelpers';
import { getFeedArticleDomain } from '../../utils/misc';
import { CompanyOutletWrapper } from './CompanyOutlet.styles';

export const CompanyOutlet: FunctionComponent = () => {
  const { t } = useTranslation();
  const tabState = useTabLinkState();
  const location = useLocation();
  const navigate = useNavigate();
  const iamContext = useIamContext();
  const params = useParams<{ companyId: string; settingKey?: string }>();
  const { tableType, tableSubType } = useMemo(
    () => getTableTypeByPath(location.pathname) ?? {},
    [location.pathname],
  );

  const domain = useMemo(
    () => getFeedArticleDomain(params.companyId, null),
    [params.companyId],
  );

  useEffect(() => {
    if (matchPath(routeFragments.companyBase, location.pathname)) {
      navigate(
        constructUrl(routes.companyActions, {
          companyId: params.companyId,
        }),
      );
    }
  }, [location.pathname, navigate, params.settingKey, params.companyId]);

  const items = useMemo<Array<TabBarNavigationItem>>(
    () =>
      [
        canGetCompanyActionsArticles(params.companyId, iamContext)
          ? {
              to: constructUrl(routes.companyActions, {
                companyId: params.companyId,
              }),
              label: () => (
                <ToolbarItemWrapper>
                  <Clipboard size="18" />
                  {t(MessageKeys.SideMenuTeamActivityInsights)}
                </ToolbarItemWrapper>
              ),
            }
          : null,

        canGetCompanyActivityLogArticles(params.companyId, iamContext)
          ? {
              to: constructUrl(routes.companyActionsLog, {
                companyId: params.companyId,
              }),
              label: () => (
                <ToolbarItemWrapper>
                  <Layers size="18" />
                  {t(MessageKeys.SideMenuTeamArticlesEventLog)}
                </ToolbarItemWrapper>
              ),
            }
          : null,
        canViewCompanyAdministration(params.companyId, iamContext)
          ? {
              to: constructUrl(routes.companyAdministrationCatchAll, {
                companyId: params.companyId,
              }),
              selectedForActiveSubPaths: true,
              label: () => (
                <ToolbarItemWrapper>
                  <Settings size="18" />
                  {t(MessageKeys.SideMenuCompanyAdministration)}
                </ToolbarItemWrapper>
              ),
            }
          : null,
      ].filter(Boolean),
    [iamContext, params.companyId, t],
  );

  useEffect(() => {
    if (
      matchPath(routeFragments.companyBase, location.pathname) &&
      items.length
    ) {
      navigate(items[0].to);
    }
  }, [items, location.pathname, navigate, params.companyId]);

  const content = (
    <>
      <GridLayoutToolbarWrapper>
        <ToolbarWrapper>
          <ToolbarRow>
            <TabBarNavigation
              tabLinkState={tabState}
              tabBarAriaLabel="Team Toolbar"
              items={items}
            />
            {tableType ? (
              <ErrorBoundary>
                <SuspenseLoader silent>
                  <FeedArticleTableTopActionBar />
                </SuspenseLoader>
              </ErrorBoundary>
            ) : null}
          </ToolbarRow>
        </ToolbarWrapper>
      </GridLayoutToolbarWrapper>
      <CompanyOutletWrapper>
        <TabBarPanel
          state={tabState}
          tabId={tabState.selectedId || undefined}
          className="tab-panel"
        >
          <ErrorBoundary>
            <SuspenseLoader>
              <Outlet />
            </SuspenseLoader>
          </ErrorBoundary>
        </TabBarPanel>
      </CompanyOutletWrapper>
    </>
  );

  return tableType ? (
    <FeedArticleTableContextProvider
      key={`${tableType}-${tableSubType}-${domain}`}
      tableType={tableType}
      tableSubType={tableSubType}
      domain={domain}
    >
      {content}
    </FeedArticleTableContextProvider>
  ) : (
    content
  );
};
