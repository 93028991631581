import { Team } from '@fcg-tech/regtech-types/regeye';
import update from 'immutability-helper';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  useCompanyActions,
  useCompanyTeams,
  useCurrentUser,
  useTeamInvitationActions,
  useTeamMemberActions,
} from '../../api/hooks';

export enum TeamAdministrationTabID {
  TeamDetails = 'team-details',
  TeamMembers = 'team-members',
  TeamSubscriptions = 'team-subscriptions',
  TeamFilters = 'team-filters',
  TeamTags = 'team-tags',
}

export const useTeamAdmin = (companyId: string, teamId: string) => {
  const { data: user } = useCurrentUser();
  const { data: teams, mutate, isValidating } = useCompanyTeams(companyId);
  const lastTeamId = useRef(teamId);

  useEffect(() => {
    if (teamId !== lastTeamId.current) {
      mutate();
      lastTeamId.current = teamId;
    }
  }, [mutate, teamId]);

  const {
    createTeam,
    updateTeam,
    deleteTeam,
    removeMemberFromTeam,
    addSelfToTeam,
  } = useCompanyActions();

  const { companyCreateTeamInvitation, companyRemoveTeamInvitation } =
    useTeamInvitationActions(teamId);

  const { leaveTeam } = useTeamMemberActions(teamId);

  const handleTeamDelete = useCallback(
    async (companyId: string, teamId: string) => {
      await deleteTeam(companyId, teamId);
      mutate((old) => {
        return old.filter((team) => team.id !== teamId);
      });
    },
    [deleteTeam, mutate],
  );

  const handleTeamUpdate = useCallback(
    async (team: Team) => {
      await updateTeam(team.company.id, team.id, team);
      mutate((old) => {
        const index = old.findIndex((o) => o.id === team.id);
        if (index >= 0) {
          return update(old, { [index]: { $set: team } });
        }
        return old;
      });
      return team;
    },
    [mutate, updateTeam],
  );

  const handleRemoveMemberFromTeam = useCallback(
    async (companyId: string, teamId: string, username: string) => {
      await removeMemberFromTeam(companyId, teamId, username);
      mutate((old) => {
        const index = old.findIndex((o) => o.id === teamId);
        if (index >= 0) {
          return update(old, {
            [index]: {
              members: {
                $set: old[index].members.filter((m) => m.username !== username),
              },
            },
          });
        }
        return old;
      });
    },
    [mutate, removeMemberFromTeam],
  );

  const handleLeaveTeam = useCallback(
    async (teamId: string) => {
      await leaveTeam(teamId);
      mutate((old) => {
        const index = old.findIndex((o) => o.id === teamId);
        if (index >= 0) {
          return update(old, {
            [index]: {
              members: {
                $set: old[index].members.filter(
                  (m) => m.username !== user.username,
                ),
              },
            },
          });
        }
        return old;
      });
    },
    [leaveTeam, mutate, user.username],
  );

  const handleCreateTeamInvitation = useCallback(
    (
      companyId: string,
      emails: string | Array<string>,
      message: string,
      teamId: string,
    ) => {
      return companyCreateTeamInvitation(companyId, emails, message, teamId);
    },
    [companyCreateTeamInvitation],
  );

  const handleRemoveTeamInvitation = useCallback(
    async (companyId: string, invitationId: string, teamId: string) => {
      await companyRemoveTeamInvitation(companyId, invitationId, teamId);
    },
    [companyRemoveTeamInvitation],
  );

  const handleAddSelfToTeam = useCallback(
    async (companyId: string, teamId: string) => {
      await addSelfToTeam(companyId, teamId);
      mutate();
    },
    [addSelfToTeam, mutate],
  );

  const handleCreateNewTeam = useCallback(
    async (companyId: string, teamName: string, addUserToTeam: boolean) => {
      const team = await createTeam(companyId, teamName);
      if (addUserToTeam) {
        await addSelfToTeam(companyId, team.id);
      }
      await mutate();
      return team;
    },
    [addSelfToTeam, createTeam, mutate],
  );

  return {
    team: useMemo(() => teams.find((t) => t.id === teamId), [teams, teamId]),
    teams,
    isValidatingTeams: isValidating,
    teamsMutate: mutate,
    handleTeamDelete,
    handleTeamUpdate,
    handleRemoveMemberFromTeam,
    handleLeaveTeam,
    handleCreateTeamInvitation,
    handleRemoveTeamInvitation,
    handleAddSelfToTeam,
    handleCreateNewTeam,
  };
};
