import update from 'immutability-helper';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnSelector } from '../../components/ColumnSelector';
import { useFeedArticleTableContext } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { getTableTypeColumns } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { FeedArticleTableColumns } from '../../types';
import { MyProfileTableColumnSelectorWrapper } from './MyProfilePage.styles';

export const MyProfileTableColumnSelector: FunctionComponent = () => {
  const { t } = useTranslation();
  const { columnOptions, updateColumnOptions, tableType, domain } =
    useFeedArticleTableContext();
  const allowedColumns = useMemo(() => {
    return getTableTypeColumns(tableType, domain, t);
  }, [domain, t, tableType]);

  const handleChange = useCallback(
    (selected: Array<FeedArticleTableColumns>) => {
      updateColumnOptions((prevColumnOptions) => {
        const selectedSet = new Set(selected);
        let updatedOptions = prevColumnOptions;
        allowedColumns.forEach((c) => {
          if (!updatedOptions[c.id]) {
            updatedOptions = update(updatedOptions, {
              [c.id]: {
                $set: {
                  visible: selectedSet.has(c.id as FeedArticleTableColumns),
                },
              },
            });
          } else {
            updatedOptions = update(updatedOptions, {
              [c.id]: {
                visible: {
                  $set: selectedSet.has(c.id as FeedArticleTableColumns),
                },
              },
            });
          }
        });
        return updatedOptions;
      });
    },
    [allowedColumns, updateColumnOptions],
  );

  const selectedColumns = useMemo(() => {
    return allowedColumns
      .filter((c) => columnOptions?.[c.id]?.visible ?? true)
      .map((c) => c.id);
  }, [allowedColumns, columnOptions]);

  return (
    <MyProfileTableColumnSelectorWrapper>
      <ColumnSelector
        allowedColumns={allowedColumns}
        selectedColumns={selectedColumns as Array<FeedArticleTableColumns>}
        onChange={handleChange}
      />
    </MyProfileTableColumnSelectorWrapper>
  );
};
