import {
  ArticlesApi,
  Count,
  FeedArticle,
  FeedArticleDomain,
  FeedArticleList,
  FilterValues,
  GenericFeedArticleFetchContext,
  GetTeamActivityLogArticlesRequest,
  GetUserActivityLogArticlesRequest,
  ID,
  PaginatedItems,
  RecentTeamActivity,
  TeamActions,
} from '@fcg-tech/regtech-types/regeye';
import { useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { useAccessControl } from '../../components/AccessControl';
import { useInitContext } from '../../components/InitContext';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import {
  convertFeedArticleData,
  convertFeedArticleList,
} from '../../converters/feedArticleConverter';
import {
  buildInsightsFilterQuery,
  getApiQueryValues,
  getFeedArticlesFetcherQuery,
  useApi,
} from '../apiUtils';
import { buildQueryKey } from '../cacheKeysUtils';
import {
  ArchivedOptions,
  CreateCompanyExcelReportRequest,
  CreateExcelReportRequest,
  Excel,
  ExcelApi,
  GetAllArticlesRequest,
  GetAllTeamActionsRequest,
  GetCompanyActionsArticlesRequest,
  GetCompanyActivityLogArticlesRequest,
  GetTeamActionsArticlesRequest,
} from '../schema';
import {
  actionsArticlesByEventKey,
  activityLogArticlesKey,
  allArticlesKey,
  articlesForTeamKey,
  assignedToMeArticlesKey,
  assignedToMeCount,
  companyActionsArticlesByEventKey,
  feedArticleKey,
  myActivityArticlesKey,
  teamActionsArticlesByEventKey,
  teamActivitiesForArticleKey,
  teamActivityArticlesKey,
  teamOverdueCountKey,
} from './cacheKeys';

const feedArticleResponseConverter = async (
  request: Promise<FeedArticleList>,
): Promise<PaginatedItems<FeedArticle>> => {
  const result = await request;
  const feedArticles = (result.items ?? []).map(convertFeedArticleData);
  return {
    ...result,
    items: feedArticles,
  };
};

export const useAllArticles = (
  context: Omit<GenericFeedArticleFetchContext, 'archived'>,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const query = getFeedArticlesFetcherQuery(
    page,
    pageSize,
    context.filter,
  ) as GetAllArticlesRequest;
  query.bookmarked = context.bookmarked;
  return useSWR<PaginatedItems<FeedArticle>>(
    allArticlesKey(buildQueryKey(query as Record<string, unknown>)),
    () => feedArticleResponseConverter(api.getAllArticles(query)),
    { suspense: true, ...config },
  );
};

export const useAllTeamActions = (
  context: GenericFeedArticleFetchContext,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const query = getFeedArticlesFetcherQuery(
    page,
    pageSize,
    context.filter ?? {},
  );
  return useSWR<PaginatedItems<FeedArticle>>(
    assignedToMeArticlesKey(buildQueryKey(query as Record<string, unknown>)),
    () => feedArticleResponseConverter(api.getAllTeamActions(query)),
    { suspense: true, ...config },
  );
};

export const getAssignedToMeCount = async (api: ArticlesApi) => {
  const result = await api.countAssignments();
  return result.count;
};

export const useAssignedToMeCount = (config: SWRConfiguration = {}) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const { error: initDataError } = useInitContext();
  return useSWR<number>(
    initDataError ? null : assignedToMeCount,
    () => getAssignedToMeCount(api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const useSubscriptionArticles = (
  context: GenericFeedArticleFetchContext,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  config: SWRConfiguration = {},
) => {
  const teamId = context.teamId;
  if (!teamId) {
    throw new Error('teamId is required for useSubscriptionArticles');
  }
  const api = useApi<ArticlesApi>('ArticlesApi');
  const query = getFeedArticlesFetcherQuery(page, pageSize, context.filter);

  return useSWR<PaginatedItems<FeedArticle>>(
    articlesForTeamKey(teamId, query),
    () =>
      feedArticleResponseConverter(
        api.getArticlesForTeam({ teamId, ...query }),
      ),
    {
      suspense: true,
      ...config,
    },
  );
};

export const useArchivedArticles = (
  context: GenericFeedArticleFetchContext,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  config: SWRConfiguration = {},
) => {
  return useSubscriptionArticles(
    {
      ...context,
      filter: { ...(context.filter ?? {}), archived: ArchivedOptions.Archived },
    },
    page,
    pageSize,
    config,
  );
};

export const getAllTeamsActionsArticles = async (
  query: GetAllTeamActionsRequest,
  api: ArticlesApi,
): Promise<FeedArticleList> => {
  const result = await api.getAllTeamActions(query);

  return {
    ...result,
    items: result.items.map(convertFeedArticleData),
  };
};

export const getCompanyActionsArticles = async (
  query: GetCompanyActionsArticlesRequest,
  api: ArticlesApi,
): Promise<FeedArticleList> => {
  const result = await api.getCompanyActionsArticles(query);

  return {
    ...result,
    items: result.items.map(convertFeedArticleData),
  };
};

export const useCompanyActionsArticles = (
  companyId: string,
  teams: Array<string>,
  filter: FilterValues,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const query = useMemo<GetCompanyActionsArticlesRequest>(
    () => ({
      companyId,
      teams,
      ...buildInsightsFilterQuery(page, pageSize, filter),
    }),
    [companyId, filter, page, pageSize, teams],
  );

  const cacheKey = companyActionsArticlesByEventKey(
    companyId,
    teams,
    page,
    pageSize,
    query,
  );

  return useSWR<FeedArticleList>(
    cacheKey,
    () => getCompanyActionsArticles(query, api),
    { suspense: true, ...config },
  );
};

export const getTeamActionsArticles = async (
  query: GetTeamActionsArticlesRequest,
  api: ArticlesApi,
): Promise<FeedArticleList> => {
  const result = await api.getTeamActionsArticles(query);

  return {
    ...result,
    items: result.items.map(convertFeedArticleData),
  };
};

export const useTeamActionsArticles = (
  teamId: string | null,
  filter: FilterValues,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const query = useMemo<GetTeamActionsArticlesRequest>(
    () => ({
      teamId,
      ...buildInsightsFilterQuery(page, pageSize, filter),
    }),
    [filter, page, pageSize, teamId],
  );

  const cacheKey = teamActionsArticlesByEventKey(teamId, page, pageSize, query);

  return useSWR<FeedArticleList>(
    cacheKey,
    () => getTeamActionsArticles(query, api),
    { suspense: true, ...config },
  );
};

export const useActionsArticles = (
  companyOrTeamId: string,
  domain: FeedArticleDomain,
  filter: FilterValues,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const filterQuery = buildInsightsFilterQuery(page, pageSize, filter);

  const cacheKey = actionsArticlesByEventKey(
    companyOrTeamId ?? 'personal',
    page,
    pageSize,
    filterQuery,
  );

  return useSWR<FeedArticleList>(
    cacheKey,
    () => {
      if (domain === FeedArticleDomain.Company && companyOrTeamId) {
        return getCompanyActionsArticles(
          { ...filterQuery, companyId: companyOrTeamId },
          api,
        );
      } else if (domain === FeedArticleDomain.Team && companyOrTeamId) {
        return getTeamActionsArticles(
          {
            ...filterQuery,
            teamId: companyOrTeamId,
          },
          api,
        );
      } else if (domain === FeedArticleDomain.Personal) {
        return getAllTeamsActionsArticles(filterQuery, api);
      }
      throw new Error(
        'domain must be either "company" or "team", and companyOrTeamId must be provided',
      );
    },
    { suspense: true, ...config },
  );
};

export const getUserActivityLogArticles = async (
  query: GetUserActivityLogArticlesRequest,
  api: ArticlesApi,
): Promise<FeedArticleList> => {
  const result = await api.getUserActivityLogArticles(getApiQueryValues(query));

  return convertFeedArticleList(result);
};

export const useUserActivityLogArticlesByEvent = (
  filter: FilterValues,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const cacheKey = myActivityArticlesKey(page, pageSize, filter);

  const query: GetUserActivityLogArticlesRequest = {
    skip: (page - 1) * pageSize,
    limit: pageSize,
    ...filter,
  };

  return useSWR<FeedArticleList>(
    cacheKey,
    () => getUserActivityLogArticles(query, api),
    { suspense: true, ...config },
  );
};

const getTeamActivityLogArticles = async (
  query: GetTeamActivityLogArticlesRequest,
  api: ArticlesApi,
): Promise<FeedArticleList> => {
  const result = await api.getTeamActivityLogArticles(getApiQueryValues(query));
  return convertFeedArticleList(result);
};

export const useTeamActivityArticles = (
  teamId: ID,
  filter: FilterValues,
  page: number,
  pageSize: number,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const query: GetTeamActivityLogArticlesRequest = {
    skip: (page - 1) * pageSize,
    limit: pageSize,
    teamId,
    ...filter,
  };
  return useSWR<FeedArticleList>(
    teamActivityArticlesKey(teamId, page, pageSize, filter),
    () => getTeamActivityLogArticles(query, api),
    {
      suspense: true,
      ...config,
    },
  );
};

const getCompanyActivityLogArticles = async (
  query: GetCompanyActivityLogArticlesRequest,
  api: ArticlesApi,
): Promise<FeedArticleList> => {
  const result = await api.getCompanyActivityLogArticles(
    getApiQueryValues(query),
  );
  return convertFeedArticleList(result);
};

export const useActivityLogArticles = (
  companyOrTeamId: string,
  domain: FeedArticleDomain,
  filter: FilterValues,
  page: number,
  pageSize: number,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const query:
    | GetCompanyActivityLogArticlesRequest
    | GetTeamActivityLogArticlesRequest
    | GetUserActivityLogArticlesRequest = {
    skip: (page - 1) * pageSize,
    limit: pageSize,
    ...filter,
  };

  return useSWR<FeedArticleList>(
    activityLogArticlesKey(companyOrTeamId, domain, page, pageSize, filter),
    () => {
      if (domain === FeedArticleDomain.Company && companyOrTeamId) {
        return getCompanyActivityLogArticles(
          {
            ...query,
            companyId: companyOrTeamId,
          } as GetCompanyActionsArticlesRequest,
          api,
        );
      } else if (domain === FeedArticleDomain.Team && companyOrTeamId) {
        return getTeamActivityLogArticles(
          {
            ...query,
            teamId: companyOrTeamId,
          } as GetTeamActionsArticlesRequest,
          api,
        );
      }
      return getUserActivityLogArticles(
        query as GetUserActivityLogArticlesRequest,
        api,
      );
    },
    {
      suspense: true,
      ...config,
    },
  );
};

const getTeamActivityLogForArticle = async (
  teamId: string,
  articleId: string,
  api: ArticlesApi,
): Promise<Array<RecentTeamActivity>> => {
  const result = await api.getTeamActivityLogForArticle({ teamId, articleId });
  return result.items;
};

export const useTeamActivityLogForArticle = (
  teamId: ID,
  articleId: ID,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  return useSWR<Array<RecentTeamActivity>>(
    teamActivitiesForArticleKey(teamId, articleId),
    () => getTeamActivityLogForArticle(teamId, articleId, api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const getArticle = async (articleId: ID, api: ArticlesApi) => {
  const result = await api.getArticle({ articleId });
  return convertFeedArticleData(result.feedArticle);
};

export const useFeedArticle = (
  articleId: ID,
  config: SWRConfiguration = {},
) => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  return useSWR<FeedArticle>(
    feedArticleKey(articleId),
    () => getArticle(articleId, api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const useTeamOverdueArticlesCount = (teamId: string): number => {
  const api = useApi<ArticlesApi>('ArticlesApi');
  const allowed = useAccessControl(TeamActions.CountOverdueArticles);

  const result = useSWR<Count>(
    allowed && teamId !== 'all' ? teamOverdueCountKey(teamId) : null,
    () => api.countOverdueArticles({ teamId }),
    { suspense: false },
  );

  if (teamId == 'all') {
    return 0;
  }

  return result?.data?.count ?? 0;
};

export const useExportActionsArticles = (
  id: string,
  domain: FeedArticleDomain,
) => {
  const api = useApi<ExcelApi>('ExcelApi');

  return {
    export: async (filter: FilterValues) => {
      const query: Partial<
        CreateCompanyExcelReportRequest & CreateExcelReportRequest
      > = buildInsightsFilterQuery(1, 100, filter);

      if (domain === FeedArticleDomain.Company) {
        query.companyId = id;
      } else {
        query.teamId = id;
      }
      const response = await (domain === FeedArticleDomain.Company
        ? api.createCompanyExcelReportRaw(
            query as CreateCompanyExcelReportRequest,
          )
        : api.createExcelReportRaw(query as CreateExcelReportRequest));

      const result = (await response.raw.json()) as Excel;

      return {
        data: result.data,
        contentType: response.raw.headers.get('content-type'),
        limitExceeded: result.totalRows > result.maxRows,
        maxRows: result.maxRows,
      };
    },
  };
};
