/*
 * Cache keys for SWR. Note that these "urls" do not always map the actual urls used by the apis
 */

import {
  ExtendedApiFilter,
  FeedArticleDomain,
  FilterType,
} from '@fcg-tech/regtech-types/regeye';
import { flattenJSON } from '@fcg-tech/regtech-utils';
import { stringify } from 'query-string';
import { ActionsArticlesFilterQuery } from '../apiUtils';
import { GetCompanyActionsArticlesRequest } from '../schema';

export const initDataKey = () => '/init';
export const teamDashboardDataKey = () => '/dashboard';

export const articleMetaDataKey = (articleId: string) =>
  `/teams/articles/${articleId}/metadata`;

export const articleMetaDataForTeamKey = (teamId: string, articleId: string) =>
  `/teams/articles/${articleId}/metadata/team/${teamId}`;

export const teamInvitationsKey = (teamId: string) =>
  `/invitations/team/${teamId}`;

export const invitationsForUserKey = () => `/invitations/personal`;

export const allTeamsKey = () => '/teams/all';
export const teamKey = (teamId: string) => `/teams/${teamId}`;
export const presignedFileUrlForCustomFeedKey = (
  feedId: string,
  fileId: string,
) => `/custom-files/presigned/${feedId}/${fileId}`;

export const tagsForTeamKey = (teamId: string) => `/team/${teamId}/tags`;

export const allArticlesKey = (query: string) => `/articles/${query}`;

export const bookmarkedArticlesKey = (query: string) =>
  `/articles/bookmarked/${query}`;

export const assignedToMeArticlesKey = (query: string) =>
  `/articles/assignedToMe/${query}`;

export const assignedToMeCount = '/assigned-count';

export const articlesForTeamKey = (teamId: string, query: ExtendedApiFilter) =>
  `/articles/team/${teamId}?${stringify(flattenJSON(query) as never)}`;

export const teamActivityArticlesKey = (
  teamId: string,
  page: number,
  pageSize: number,
  query: ExtendedApiFilter,
) =>
  `/articles/activity/team/${teamId}?${stringify({
    page,
    pageSize,
  })}&${stringify(flattenJSON(query) as never)}`;

export const activityLogArticlesKey = (
  companyOrTeamId: string | null | undefined,
  domain: FeedArticleDomain,
  page: number,
  pageSize: number,
  query: ExtendedApiFilter,
) =>
  `/articles/activity/?${stringify({
    page,
    pageSize,
    companyOrTeamId,
    domain,
  })}&${stringify(flattenJSON(query) as never)}`;

export const companyActionsArticlesByEventKey = (
  companyId: string,
  teams: Array<string>,
  page: number,
  pageSize: number,
  query: Partial<GetCompanyActionsArticlesRequest>,
) =>
  `/articles/activity/company/${companyId}/team/${teams
    .sort()
    .join(',')}/events?${stringify({
    page,
    pageSize,
  })}&${stringify(flattenJSON(query) as never)}`;

export const teamActionsArticlesByEventKey = (
  teamId: string,
  page: number,
  pageSize: number,
  query: Partial<GetCompanyActionsArticlesRequest>,
) =>
  `/articles/activity/team/${teamId}/events?${stringify({
    page,
    pageSize,
  })}&${stringify(flattenJSON(query) as never)}`;

export const actionsArticlesByEventKey = (
  companyOrTeamId: string,
  page: number,
  pageSize: number,
  filter: ActionsArticlesFilterQuery,
) =>
  `/actions/articles/${companyOrTeamId}/events?${stringify({
    page,
    pageSize,
  })}&${stringify(flattenJSON(filter) as never)}`;

export const teamActivitiesForArticleKey = (
  teamId: string,
  articleId: string,
) => `/articles/${articleId}/activity/team/${teamId}`;

export const teamOverdueCountKey = (teamId: string) =>
  `/team/${teamId}/overdue`;

export const myActivityArticlesKey = (
  page: number,
  pageSize: number,
  query: ExtendedApiFilter,
) =>
  `/articles/activity?${stringify({ page, pageSize })}&${stringify(
    flattenJSON(query) as never,
  )}`;

export const feedArticleKey = (articleId: string) => `/articles/${articleId}`;

export const feedArticlesSearchKey = (
  page: number,
  pageSize: number,
  query: ExtendedApiFilter,
) =>
  `/articles/search?${stringify({ page, pageSize })}&${stringify(
    flattenJSON(query) as never,
  )}`;

export const teamFiltersKey = (teamId: string, filterType?: FilterType) =>
  `/filters/team/${teamId ?? 'unknown'}/${filterType ?? 'all'}`;

export const myFiltersKey = (filterType?: FilterType) =>
  `/filters/personal/${filterType ?? 'all'}`;

export const availableArticleFiltersKey = (query: string) =>
  `/filters/article?${query}`;

export const publishersKey = () => '/publishers';
export const publishersForTeamKey = (teamId: string) =>
  `/publishers/team/${teamId}`;

export const mySettingsKey = () => '/personal/settings';
export const myNotificationsSettingsKey = () =>
  '/personal/notifications/settings';
export const currentUserKey = () => '/personal/currentuser';

export const companiesKey = () => '/companies';
export const companyKey = (companyId: string) => `/companies/${companyId}`;
export const companyTeamsKey = (companyId: string) =>
  `/companies/${companyId}/teams`;
