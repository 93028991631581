import {
  excludeProps,
  mobile,
  UnstyledButton,
} from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const CommentEntryWrapper = styled.div.withConfig(
  excludeProps('isReply'),
)<{
  isReply?: boolean;
}>`
  ${({ isReply }) => css`
    padding-left: ${isReply ? '4.2rem' : '0'};
    display: flex;
    & + & {
      margin-top: 1rem;
    }
    position: relative;
  `}
`;

export const CommentEntryAvatar = styled.div`
  width: 3rem;
  height: 3rem;

  ${mobile(
    () => css`
      display: none;
    `,
  )}
`;

export const CommentEntryContent = styled.div`
  flex: 1 0 0;
  min-width: 0;
  margin: 0;
  padding: 0 0 0 1rem;
`;

export const CommentEntryMetadata = styled.div`
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 0.7rem;
  padding-right: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const CommentEntryMetadataName = styled.span`
  font-weight: 600;
  ${mobile(
    () => css`
      margin-bottom: 0.5rem;
    `,
  )}
`;

export const CommentEntryBody = styled.div`
  margin: 0;
  font-size: 1.4rem;
  padding: 0;
  line-height: 2rem;
`;

export const CommentEntryActions = styled.div`
  margin-top: 0.8rem;
`;

export const CommentEntryActionButton = styled(UnstyledButton)`
  font-size: 1.2rem;
  padding: 0.4rem 0;
  border-bottom: 1px dashed #999;
  position: relative;
  left: -0.1rem;
  opacity: 0.75;

  &:hover,
  &:active {
    opacity: 1;
    border-bottom: 1px dashed #999;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const CommentEntryDeletedMessage = styled.span`
  font-style: italic;
  opacity: 0.8;
`;

export const CommentMention = styled.span`
  ${({ theme }) => css`
    font-weight: 600;
    color: ${theme.colors?.info?.dark ?? 'darkblue'};
    &.current-user {
      color: ${theme.palette?.MainColorAccent?.dark ?? 'darkblue'};
    }
  `}
`;
