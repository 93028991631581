import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ShowcaseReel } from '../../types/showcase';
import { ModalWrapper } from '../Modal';
import {
  ShowcaseAction,
  ShowcaseActionEmpty,
  ShowcaseActions,
  ShowcaseActionWrapper,
  ShowcaseContent,
  ShowcaseIllustration,
  ShowcaseModalBackdrop,
  ShowcaseModalContent,
  ShowcasePadddedContent,
  ShowcaseWrapper,
} from './Showcase.styles';
import { ShowcaseErrorBoundary } from './ShowcaseErrorBoundary';
import { ShowcaseHighlight } from './ShowcaseHighlight';
import { ShowcaseParagraph } from './ShowcaseParagraph';

interface ShowcaseProps {
  reel: ShowcaseReel;
  labels?: {
    skip?: string;
    next?: string;
    previous?: string;
    done?: string;
  };
  onExit?: (reelId: string, exitAtIndex?: number) => void;
}

export const Showcase: FunctionComponent<ShowcaseProps> = ({
  reel,
  labels,
  onExit,
}) => {
  const [enabled, setEnabled] = useState(false);
  const [index, setIndex] = useState(0);
  const timer = useRef<ReturnType<typeof setInterval>>();

  const entry = useMemo(() => reel.entries[index], [index, reel]);

  useEffect(() => {
    if (!enabled && index === 0) {
      const qs = reel.waitForQuerySelector ?? reel.entries[0].querySelector;

      if (!qs) {
        setEnabled(true);
      }

      const el = typeof qs === 'string' ? document.querySelector(qs) : qs;

      if (el) {
        setEnabled(true);
        clearInterval(timer.current);
      } else if (!timer.current && typeof qs === 'string') {
        timer.current = setInterval(() => {
          const el = document.querySelector(qs);
          if (el) {
            setEnabled(true);
            clearInterval(timer.current);
          }
        }, 350);
      }
    }
  }, [enabled, index, reel.entries, reel.waitForQuerySelector]);

  const handleNext = useCallback(() => setIndex((old) => old + 1), []);
  const handlePrev = useCallback(() => setIndex((old) => old - 1), []);

  const handleDoneOrSkip = useCallback(() => {
    onExit(reel.id, index);
  }, [index, onExit, reel.id]);

  const showcaseActions = (
    <ShowcaseActions>
      {index > 0 ? (
        <ShowcaseActionWrapper>
          <ShowcaseAction onClick={handlePrev}>
            {labels?.previous ?? 'Previous'}
          </ShowcaseAction>
        </ShowcaseActionWrapper>
      ) : (
        <ShowcaseActionEmpty />
      )}
      {reel.skippable && index !== reel.entries.length - 1 ? (
        <ShowcaseActionWrapper>
          <ShowcaseAction onClick={handleDoneOrSkip}>
            {labels?.skip ?? 'Skip'}
          </ShowcaseAction>
        </ShowcaseActionWrapper>
      ) : (
        <ShowcaseActionEmpty />
      )}
      {index <= reel.entries.length - 1 ? (
        <ShowcaseActionWrapper>
          <ShowcaseAction
            onClick={
              index === reel.entries.length - 1 ? handleDoneOrSkip : handleNext
            }
          >
            {index === reel.entries.length - 1
              ? labels?.done ?? 'Done'
              : labels?.next ?? 'Next'}
          </ShowcaseAction>
        </ShowcaseActionWrapper>
      ) : (
        <ShowcaseActionEmpty />
      )}
    </ShowcaseActions>
  );

  const content =
    typeof entry?.content === 'function' ? (
      entry.content(entry)
    ) : (
      <ShowcasePadddedContent style={entry?.paddedContentStyle}>
        <ShowcaseParagraph content={entry?.content} />
      </ShowcasePadddedContent>
    );

  const handleError = useCallback(
    (error: Error) => {
      console.error(error);
      onExit(reel.id, index);
    },
    [index, onExit, reel.id],
  );

  return enabled ? (
    <ShowcaseErrorBoundary onError={handleError}>
      <ShowcaseWrapper>
        {entry?.querySelector ? (
          <ShowcaseHighlight
            key={entry.querySelector.toString()}
            element={entry.querySelector}
            padding={entry.padding}
          >
            {entry.illustration ? (
              <ShowcaseIllustration title={entry.illustrationAlt}>
                {entry.illustration(entry)}
              </ShowcaseIllustration>
            ) : null}
            {entry.illustrationSrc ? (
              <ShowcaseIllustration
                title={entry.illustrationAlt}
                style={{
                  backgroundImage: `url(${entry.illustrationSrc})`,
                  backgroundColor: entry.illustrationBg,
                  ...(entry?.illustrationStyle ?? {}),
                }}
              />
            ) : null}
            <ShowcaseContent>{content}</ShowcaseContent>
            {showcaseActions}
          </ShowcaseHighlight>
        ) : null}
        {entry && !entry.querySelector ? (
          <ModalWrapper role="dialog" aria-modal="true">
            <ShowcaseModalBackdrop />
            <ShowcaseModalContent style={entry.contentStyle}>
              {entry.illustrationSrc ? (
                <ShowcaseIllustration
                  title={entry.illustrationAlt}
                  style={{
                    backgroundImage: `url(${entry.illustrationSrc})`,
                    backgroundColor: entry.illustrationBg,
                    ...(entry?.illustrationStyle ?? {}),
                  }}
                />
              ) : null}
              <ShowcaseContent>{content}</ShowcaseContent>
              {showcaseActions}
            </ShowcaseModalContent>
          </ModalWrapper>
        ) : null}
      </ShowcaseWrapper>
    </ShowcaseErrorBoundary>
  ) : null;
};
