import { Component, PropsWithChildren } from 'react';

export class ShowcaseErrorBoundary extends Component<
  PropsWithChildren<{ onError: (error: Error) => void }>
> {
  override state = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: Boolean(error), error };
  }

  override componentDidCatch(error, errorInfo) {
    this.props.onError(error);
  }

  override render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
