import { MessageKeys } from '../translations/translationTypes';
import { Importance } from '../types';

export const ImportanceMessageKeyMap: Record<Importance, MessageKeys> = {
  [Importance.Unspecified]: MessageKeys.ArticleImportanceUnspecified,
  [Importance.Unimportant]: MessageKeys.ArticleImportanceUnimportant,
  [Importance.SlightlyImportant]: MessageKeys.ArticleImportanceSlightly,
  [Importance.ModeratelyImportant]: MessageKeys.ArticleImportanceModerately,
  [Importance.Important]: MessageKeys.ArticleImportanceImportant,
  [Importance.VeryImportant]: MessageKeys.ArticleImportanceVeryImportant,
};

export const ImportanceMessageKeys: Array<{
  importance: Importance;
  key: MessageKeys;
}> = Object.entries(ImportanceMessageKeyMap).map<{
  importance: Importance;
  key: MessageKeys;
}>(([importance, key]) => ({
  importance: Number(importance) as Importance,
  key,
}));
