import { TagIcon } from '@fcg-tech/regtech-components';
import styled from 'styled-components';
import { Tag } from './Tag';
import { StyledTagIcon } from './Tag.styles';

export const TEAM_TAG_BG = '#ECF9EC';
export const TEAM_TAG_FG = '#277227';

export const ARTICLE_TAG_BG = 'rgba(243, 185, 65, 0.1)';
export const ARTICLE_TAG_FG = '#F3B941';

export const ARTICLE_TYPE_TAG_BG = 'rgba(60, 120, 143, 0.1)';
export const ARTICLE_TYPE_TAG_FG = '#3C7891';

export const ArticleTypeTag = styled(Tag)`
  border-color: ${ARTICLE_TYPE_TAG_FG};
  color: #000;
  background-color: ${ARTICLE_TYPE_TAG_BG};
`;

export const ArticleTeamEventTag = styled(Tag)`
  border-color: ${TEAM_TAG_FG};
  color: black;
  background-color: ${TEAM_TAG_BG};

  &.inactive {
    opacity: 0.5;
  }
`;

export const ArticleTag = styled(Tag).attrs({ Icon: TagIcon })`
  background-color: ${ARTICLE_TAG_BG};
  color: black;
  border-color: ${ARTICLE_TAG_FG};
`;

export const ArticleTeamTag = styled(ArticleTeamEventTag).attrs({
  Icon: StyledTagIcon,
})``;
