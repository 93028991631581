/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ArticleExpandedMetadata,
    ArticleExpandedMetadataFromJSON,
    ArticleExpandedMetadataToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    TagInput,
    TagInputFromJSON,
    TagInputToJSON,
    TagInputList,
    TagInputListFromJSON,
    TagInputListToJSON,
    TeamDashboard,
    TeamDashboardFromJSON,
    TeamDashboardToJSON,
    TeamList,
    TeamListFromJSON,
    TeamListToJSON,
    TeamResponse,
    TeamResponseFromJSON,
    TeamResponseToJSON,
    TeamRoleInput,
    TeamRoleInputFromJSON,
    TeamRoleInputToJSON,
    TeamTagList,
    TeamTagListFromJSON,
    TeamTagListToJSON,
    TeamTagResponse,
    TeamTagResponseFromJSON,
    TeamTagResponseToJSON,
    TeamTagsCreateResult,
    TeamTagsCreateResultFromJSON,
    TeamTagsCreateResultToJSON,
    UserList,
    UserListFromJSON,
    UserListToJSON,
    UserReference,
    UserReferenceFromJSON,
    UserReferenceToJSON,
} from '../models';

export interface CreateTagsForTeamRequest {
    teamId: string;
    tagInputList: TagInputList;
}

export interface DeleteTagForTeamRequest {
    teamId: string;
    tagId: string;
}

export interface GetArticleMetadataRequest {
    articleId: string;
}

export interface GetMembersForTeamRequest {
    teamId: string;
}

export interface GetTagForTeamRequest {
    teamId: string;
    tagId: string;
}

export interface GetTagsForTeamRequest {
    teamId: string;
}

export interface GetTeamRequest {
    teamId: string;
}

export interface RemoveMemberFromTeamRequest {
    teamId: string;
    userReference: UserReference;
}

export interface UpdateMemberRoleRequest {
    teamId: string;
    teamRoleInput: TeamRoleInput;
}

export interface UpdateTagForTeamRequest {
    teamId: string;
    tagId: string;
    tagInput: TagInput;
}

/**
 * TeamsApi - interface
 * 
 * @export
 * @interface TeamsApiInterface
 */
export interface TeamsApiInterface {
    /**
     * 
     * @summary Create tag
     * @param {string} teamId the team id
     * @param {TagInputList} tagInputList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    createTagsForTeamRaw(requestParameters: CreateTagsForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamTagsCreateResult>>;

    /**
     * Create tag
     */
    createTagsForTeam(requestParameters: CreateTagsForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamTagsCreateResult>;

    /**
     * 
     * @summary Delete tag
     * @param {string} teamId the team id
     * @param {string} tagId the tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    deleteTagForTeamRaw(requestParameters: DeleteTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete tag
     */
    deleteTagForTeam(requestParameters: DeleteTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Get metadata of expanded article
     * @param {string} articleId the article id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getArticleMetadataRaw(requestParameters: GetArticleMetadataRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ArticleExpandedMetadata>>;

    /**
     * Get metadata of expanded article
     */
    getArticleMetadata(requestParameters: GetArticleMetadataRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ArticleExpandedMetadata>;

    /**
     * 
     * @summary List all members in team
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getMembersForTeamRaw(requestParameters: GetMembersForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserList>>;

    /**
     * List all members in team
     */
    getMembersForTeam(requestParameters: GetMembersForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserList>;

    /**
     * 
     * @summary Get tag
     * @param {string} teamId the team id
     * @param {string} tagId the tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getTagForTeamRaw(requestParameters: GetTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamTagResponse>>;

    /**
     * Get tag
     */
    getTagForTeam(requestParameters: GetTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamTagResponse>;

    /**
     * 
     * @summary List all tags in team
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getTagsForTeamRaw(requestParameters: GetTagsForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamTagList>>;

    /**
     * List all tags in team
     */
    getTagsForTeam(requestParameters: GetTagsForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamTagList>;

    /**
     * 
     * @summary Get team
     * @param {string} teamId the team id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getTeamRaw(requestParameters: GetTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamResponse>>;

    /**
     * Get team
     */
    getTeam(requestParameters: GetTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamResponse>;

    /**
     * 
     * @summary Get dashboard data for teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getTeamDashboardDataRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamDashboard>>;

    /**
     * Get dashboard data for teams
     */
    getTeamDashboardData(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamDashboard>;

    /**
     * 
     * @summary List all teams of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getTeamsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamList>>;

    /**
     * List all teams of user
     */
    getTeams(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamList>;

    /**
     * 
     * @summary Remove member from team
     * @param {string} teamId the team id
     * @param {UserReference} userReference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    removeMemberFromTeamRaw(requestParameters: RemoveMemberFromTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Remove member from team
     */
    removeMemberFromTeam(requestParameters: RemoveMemberFromTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Update the permissiosn role of user
     * @param {string} teamId the team id
     * @param {TeamRoleInput} teamRoleInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    updateMemberRoleRaw(requestParameters: UpdateMemberRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update the permissiosn role of user
     */
    updateMemberRole(requestParameters: UpdateMemberRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary Update tag
     * @param {string} teamId the team id
     * @param {string} tagId the tag id
     * @param {TagInput} tagInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    updateTagForTeamRaw(requestParameters: UpdateTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamTagResponse>>;

    /**
     * Update tag
     */
    updateTagForTeam(requestParameters: UpdateTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamTagResponse>;

}

/**
 * 
 */
export class TeamsApi extends runtime.BaseAPI implements TeamsApiInterface {

    /**
     * Create tag
     */
    async createTagsForTeamRaw(requestParameters: CreateTagsForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamTagsCreateResult>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling createTagsForTeam.');
        }

        if (requestParameters.tagInputList === null || requestParameters.tagInputList === undefined) {
            throw new runtime.RequiredError('tagInputList','Required parameter requestParameters.tagInputList was null or undefined when calling createTagsForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/teams/{teamId}/tags`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagInputListToJSON(requestParameters.tagInputList),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamTagsCreateResultFromJSON(jsonValue));
    }

    /**
     * Create tag
     */
    async createTagsForTeam(requestParameters: CreateTagsForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamTagsCreateResult> {
        const response = await this.createTagsForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete tag
     */
    async deleteTagForTeamRaw(requestParameters: DeleteTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteTagForTeam.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling deleteTagForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/tags/{tagId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete tag
     */
    async deleteTagForTeam(requestParameters: DeleteTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteTagForTeamRaw(requestParameters, initOverrides);
    }

    /**
     * Get metadata of expanded article
     */
    async getArticleMetadataRaw(requestParameters: GetArticleMetadataRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ArticleExpandedMetadata>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getArticleMetadata.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/articles/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleExpandedMetadataFromJSON(jsonValue));
    }

    /**
     * Get metadata of expanded article
     */
    async getArticleMetadata(requestParameters: GetArticleMetadataRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ArticleExpandedMetadata> {
        const response = await this.getArticleMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all members in team
     */
    async getMembersForTeamRaw(requestParameters: GetMembersForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getMembersForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/members`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListFromJSON(jsonValue));
    }

    /**
     * List all members in team
     */
    async getMembersForTeam(requestParameters: GetMembersForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserList> {
        const response = await this.getMembersForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get tag
     */
    async getTagForTeamRaw(requestParameters: GetTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamTagResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTagForTeam.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling getTagForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/tags/{tagId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamTagResponseFromJSON(jsonValue));
    }

    /**
     * Get tag
     */
    async getTagForTeam(requestParameters: GetTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamTagResponse> {
        const response = await this.getTagForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all tags in team
     */
    async getTagsForTeamRaw(requestParameters: GetTagsForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamTagList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTagsForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}/tags`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamTagListFromJSON(jsonValue));
    }

    /**
     * List all tags in team
     */
    async getTagsForTeam(requestParameters: GetTagsForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamTagList> {
        const response = await this.getTagsForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get team
     */
    async getTeamRaw(requestParameters: GetTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamResponseFromJSON(jsonValue));
    }

    /**
     * Get team
     */
    async getTeam(requestParameters: GetTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamResponse> {
        const response = await this.getTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get dashboard data for teams
     */
    async getTeamDashboardDataRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamDashboard>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams/dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDashboardFromJSON(jsonValue));
    }

    /**
     * Get dashboard data for teams
     */
    async getTeamDashboardData(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamDashboard> {
        const response = await this.getTeamDashboardDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all teams of user
     */
    async getTeamsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/teams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamListFromJSON(jsonValue));
    }

    /**
     * List all teams of user
     */
    async getTeams(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamList> {
        const response = await this.getTeamsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Remove member from team
     */
    async removeMemberFromTeamRaw(requestParameters: RemoveMemberFromTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling removeMemberFromTeam.');
        }

        if (requestParameters.userReference === null || requestParameters.userReference === undefined) {
            throw new runtime.RequiredError('userReference','Required parameter requestParameters.userReference was null or undefined when calling removeMemberFromTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/teams/{teamId}/members`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserReferenceToJSON(requestParameters.userReference),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove member from team
     */
    async removeMemberFromTeam(requestParameters: RemoveMemberFromTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.removeMemberFromTeamRaw(requestParameters, initOverrides);
    }

    /**
     * Update the permissiosn role of user
     */
    async updateMemberRoleRaw(requestParameters: UpdateMemberRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling updateMemberRole.');
        }

        if (requestParameters.teamRoleInput === null || requestParameters.teamRoleInput === undefined) {
            throw new runtime.RequiredError('teamRoleInput','Required parameter requestParameters.teamRoleInput was null or undefined when calling updateMemberRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/teams/{teamId}/permissions`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamRoleInputToJSON(requestParameters.teamRoleInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the permissiosn role of user
     */
    async updateMemberRole(requestParameters: UpdateMemberRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.updateMemberRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Update tag
     */
    async updateTagForTeamRaw(requestParameters: UpdateTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TeamTagResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling updateTagForTeam.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling updateTagForTeam.');
        }

        if (requestParameters.tagInput === null || requestParameters.tagInput === undefined) {
            throw new runtime.RequiredError('tagInput','Required parameter requestParameters.tagInput was null or undefined when calling updateTagForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/teams/{teamId}/tags/{tagId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagInputToJSON(requestParameters.tagInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamTagResponseFromJSON(jsonValue));
    }

    /**
     * Update tag
     */
    async updateTagForTeam(requestParameters: UpdateTagForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TeamTagResponse> {
        const response = await this.updateTagForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
