import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  ReactSelectOption,
  TabBarNavigation,
  TabBarNavigationItem,
  ToolbarItemWrapper,
  UsersPlusIcon,
  useTabLinkState,
  useToggle,
} from '@fcg-tech/regtech-components';
import { Building2, Users } from 'lucide-react';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  useCompanies,
  useCompany,
  useCompanyActions,
  useCompanyTeams,
} from '../../api/hooks';
import { CompanyAdministrationEditCompanyModal } from '../../components/Administration/CompanyAdministrationEditCompanyModal';
import { TeamAdministrationCreateTeamModal } from '../../components/Administration/TeamAdministrationCreateTeamModal';
import { portalRoot } from '../../components/Portal';
import { CompanySelectOption } from '../../components/selectComponents';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { Company } from '../../types';
import {
  CompanyAdministrationPageOutletWrapper,
  companySelectStyles,
} from './CompanyAdministrationPage.styles';

interface CompanyAdministrationPageOutletProps {
  superAdmin?: boolean;
}

export const CompanyAdministrationPageOutlet: FunctionComponent<
  CompanyAdministrationPageOutletProps
> = ({ superAdmin }) => {
  const { t } = useTranslation();
  const tabState = useTabLinkState();
  const navigate = useNavigate();
  const params = useParams<{ companyId: string; teamId: string }>();
  const detailsMatch = useMatch(routes.companyAdministrationDetails);
  const { data: teams } = useCompanyTeams(params.companyId);
  const { data: company } = useCompany(params.companyId);
  const { data: companies } = useCompanies();
  const [showCreateTeamModal, , toggleShowCreateTeamModal] = useToggle(false);

  const companyOptions = useMemo<Array<ReactSelectOption<Company | 'create'>>>(
    () => [
      {
        label: t(MessageKeys.AdministrationCompanyCreateHeading),
        value: 'create',
      },
      ...companies.map<ReactSelectOption<Company>>((company) => ({
        label: company.name,
        value: company,
      })),
    ],
    [companies, t],
  );

  const selectedOption = useMemo<ReactSelectOption<Company | 'create'>>(
    () =>
      companyOptions.find(
        (opt) => opt.value !== 'create' && opt.value.id === params.companyId,
      ),
    [companyOptions, params.companyId],
  );

  const [showCreateCompanyModal, setShowCreateCompanyModal] = useToggle();

  const handleCompanyChange = useCallback(
    (option: ReactSelectOption<Company | 'create'>) => {
      if (option.value === 'create') {
        setShowCreateCompanyModal(true);
      } else {
        navigate(
          constructUrl(routes.superAdminCompanyDetails, {
            companyId: option.value.id,
          }),
        );
      }
    },
    [navigate, setShowCreateCompanyModal],
  );

  const items = useMemo<Array<TabBarNavigationItem>>(() => {
    const items: Array<TabBarNavigationItem> = [
      superAdmin
        ? {
            id: 'select-company',
            className: 'select-company',
            label: (
              <Select
                options={companyOptions}
                value={selectedOption}
                styles={companySelectStyles}
                menuPortalTarget={portalRoot}
                components={{
                  Option: CompanySelectOption,
                }}
                onChange={handleCompanyChange}
              />
            ),
          }
        : null,
      {
        to: constructUrl(
          superAdmin
            ? routes.superAdminCompanyDetails
            : routes.companyAdministrationDetails,
          {
            companyId: params.companyId,
          },
        ),
        id: 'details',
        label: (
          <ToolbarItemWrapper>
            <Building2 size="18" />
            {t(MessageKeys.AdministrationTabBarTeamDetailsLabel)}
          </ToolbarItemWrapper>
        ),
        selectedForActiveSubPaths: true,
      },
      {
        label: t(MessageKeys.AdministrationTabBarTeamsLabel),
        id: 'teams',
      },
      {
        label: (
          <ToolbarItemWrapper>
            <UsersPlusIcon size="18" />
            {t(MessageKeys.AdministrationTeamTabCreateNewTeamLabel)}
          </ToolbarItemWrapper>
        ),
        id: 'create-new',
        className: 'margin-bottom',
        onClick: toggleShowCreateTeamModal,
      },
      ...teams?.map<TabBarNavigationItem>((team) => ({
        to: constructUrl(
          superAdmin
            ? routes.superAdminCompanyTeam
            : routes.companyTeamAdministration,
          {
            companyId: params.companyId,
            teamId: team.id,
          },
        ),
        id: team.id,
        label: () => (
          <ToolbarItemWrapper>
            <Users size="18" />
            {team.name}
          </ToolbarItemWrapper>
        ),
        selectedForActiveSubPaths: true,
      })),
    ];

    return items.filter(Boolean);
  }, [
    companyOptions,
    handleCompanyChange,
    params.companyId,
    selectedOption,
    superAdmin,
    t,
    teams,
    toggleShowCreateTeamModal,
  ]);

  useEffect(() => {
    if (!params.teamId && !detailsMatch) {
      if (superAdmin) {
        navigate(
          constructUrl(routes.superAdminCompanyDetails, {
            companyId: params.companyId,
          }),
        );
      } else {
        navigate(
          constructUrl(routes.companyAdministrationDetails, {
            companyId: params.companyId,
          }),
        );
      }
    }
  }, [
    detailsMatch,
    navigate,
    params.companyId,
    params.teamId,
    superAdmin,
    teams,
  ]);

  const { createTeam, addSelfToTeam } = useCompanyActions();

  const handleCreateNewTeam = useCallback(
    async (companyId: string, teamName: string, doAddSelfToTeam: boolean) => {
      const team = await createTeam(companyId, teamName);
      if (doAddSelfToTeam) {
        await addSelfToTeam(company.id, team.id);
      }
      if (team) {
        navigate({
          pathname: constructUrl(routes.companyTeamAdministration, {
            companyId,
            teamId: team.id,
          }),
        });
      }
    },
    [addSelfToTeam, company.id, createTeam, navigate],
  );

  const handleEditCompanyModalRequestClose = useCallback(
    (deleted?: boolean, created?: Company) => {
      setShowCreateCompanyModal(false);
      if (created) {
        navigate(
          constructUrl(routes.superAdminCompanyDetails, {
            companyId: created.id,
          }),
          { replace: true },
        );
      }
    },
    [navigate, setShowCreateCompanyModal],
  );

  return (
    <CompanyAdministrationPageOutletWrapper>
      {showCreateTeamModal ? (
        <TeamAdministrationCreateTeamModal
          companies={[company]}
          initialCompanyId={company.id}
          onSubmit={handleCreateNewTeam}
          onCancel={toggleShowCreateTeamModal}
          onRequestClose={toggleShowCreateTeamModal}
        />
      ) : null}
      {showCreateCompanyModal ? (
        <CompanyAdministrationEditCompanyModal
          onRequestClose={handleEditCompanyModalRequestClose}
        />
      ) : null}
      <div className="menu">
        <TabBarNavigation
          vertical
          items={items}
          tabLinkState={tabState}
          tabBarAriaLabel="Teams"
        />
      </div>
      <div className="content">
        <Outlet />
      </div>
    </CompanyAdministrationPageOutletWrapper>
  );
};
