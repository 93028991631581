import { useCallback } from 'react';
import {
  FilterValue,
  FilterValues,
  HandleClear,
  HandleFilterValueChange,
} from './types';

export const useFilterRow = <T extends FilterValues>(
  filterPropKey: keyof T,
  filterId: string | undefined,
  handleFilterValueChange: HandleFilterValueChange<T>,
  handleFilterValueClear: HandleClear<T>,
) => {
  const onChange = useCallback(
    (value: FilterValue) => {
      handleFilterValueChange?.(filterPropKey, value, filterId);
    },
    [filterId, filterPropKey, handleFilterValueChange],
  );

  const onClear = useCallback(
    () => handleFilterValueClear(filterPropKey),
    [filterPropKey, handleFilterValueClear],
  );

  return {
    onChange,
    onClear,
  };
};
