import { Modal } from '../Modal';
import styled, { css } from 'styled-components';
import { FormButtonRow } from '../FormLayout';

export const AlertModal = styled(Modal)`
  ${({ theme }) => css`
    max-width: 40vw;

    @media (max-width: ${theme?.breakpoints?.mobile ?? 730}px) {
      width: 90vw;
      max-width: 90vw;
    }
  `}
`;

export const AlertDialogButtonRow = styled(FormButtonRow)`
  margin-top: 2rem;
`;
