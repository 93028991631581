import {
  ConfirmDialog,
  DangerButton,
  DangerZone,
  FormGridControl,
  FormGridLabel,
  FormGridRow,
  FormGridToggle,
  FormRow,
  H3,
  multiSelectInLabelStyles,
  PrimaryButton,
  ReactSelectOption,
  useConfirmDialog,
  useFlash,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useSettings, useSettingsActions } from '../../api/hooks';
import {
  STORED_FILTER_DRAFTS_KEY,
  STORED_RICH_TEXT_DRAFTS_KEY,
} from '../../constants';

import { clearTableColumnOptions } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { FormWrapper } from '../../components/Form/Form.styles';
import { MessageKeys } from '../../translations/translationTypes';
import { RegeyeShowcase } from '../../types';
import {
  MyProfileFormRowLabel,
  MyProfileSection,
} from './MyProfilePage.styles';
import { useSettingsFlag } from './myProfilePageUtils';

export const MyProfileAdvancedSettings: FunctionComponent = () => {
  const { t } = useTranslation();
  const addFlash = useFlash();
  const { data: settings } = useSettings();
  const { patchSettings, resetSettings } = useSettingsActions();

  const [showResetAllConfirm, handleResetAllConfirm, confirmResetAll] =
    useConfirmDialog();

  const handleResetColumns = useCallback(() => {
    clearTableColumnOptions();
    addFlash({
      level: 'info',
      content: t(MessageKeys.MyProfileTabMySettingsWidthsResetMessage),
    });
  }, [addFlash, t]);

  const handleResetDrafts = useCallback(() => {
    localStorage.removeItem(STORED_RICH_TEXT_DRAFTS_KEY);
    addFlash({
      level: 'info',
      content: t(MessageKeys.MyProfileTabMySettingsDraftsResetMessage),
    });
  }, [addFlash, t]);

  const handleToggleAutomaticallySaveDrafts = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        localStorage.removeItem(STORED_FILTER_DRAFTS_KEY);
      }
      patchSettings({
        flags: {
          ...(settings?.flags ?? {}),
          automaticallyRestoreFilterDrafts: event.target.checked,
        },
      });
    },
    [patchSettings, settings?.flags],
  );

  const handleToggleHideFilterDraftsRestoredMessage = useSettingsFlag(
    'hideFilterDraftRestoredNotification',
  );

  const handleDoNotConfirmTeamArticleNotificationFlagChange = useSettingsFlag(
    'doNotConfirmSendArticleTeamNotifications',
  );

  const showcaseOptions = useMemo<
    Array<ReactSelectOption<RegeyeShowcase>>
  >(() => {
    return Object.keys(RegeyeShowcase).map((showcase) => ({
      value: RegeyeShowcase[showcase],
      label: t(MessageKeys[`MyProfileTabMySettingsShowcase${showcase}`]),
    }));
  }, [t]);

  const hiddenShowcaseOptions = useMemo<
    Array<ReactSelectOption<RegeyeShowcase>>
  >(
    () =>
      showcaseOptions.filter((option) =>
        settings.deferredShowcases?.includes(option.value),
      ) ?? [],
    [showcaseOptions, settings.deferredShowcases],
  );

  const handleHiddenShowcaseChange = useCallback(
    (selected: Array<ReactSelectOption<RegeyeShowcase>>) => {
      patchSettings({
        deferredShowcases: selected.map((option) => option.value),
      });
    },
    [patchSettings],
  );

  const handleResetAll = useCallback(async () => {
    if (await confirmResetAll()) {
      await resetSettings();
      window.location.href = window.location.toString();
    }
  }, [confirmResetAll, resetSettings]);

  return (
    <>
      {showResetAllConfirm ? (
        <ConfirmDialog
          title={t(MessageKeys.MyProfileTabMySettingsResetAll)}
          body={t(MessageKeys.MyProfileTabMySettingsResetAllConfirmMessage)}
          confirmText={t(MessageKeys.LabelConfirmLabel)}
          cancelText={t(MessageKeys.LabelCancel)}
          onChoice={handleResetAllConfirm}
        />
      ) : null}
      <MyProfileSection className="side-padding">
        <H3>{t(MessageKeys.MyProfileTabMySettingsFilterSettings)}</H3>
        <FormWrapper>
          <FormGridRow className="with-toggle">
            <FormGridLabel>
              {t(MessageKeys.MyProfileTabMySettingsRestoreFilterDraftsMessage)}
            </FormGridLabel>
            <FormGridControl>
              <FormGridToggle
                checked={settings?.flags?.automaticallyRestoreFilterDrafts}
                onChange={handleToggleAutomaticallySaveDrafts}
              />
            </FormGridControl>
          </FormGridRow>
          <FormGridRow className="with-select">
            <FormGridLabel>Hidden showcases</FormGridLabel>
            <FormGridControl>
              <Select
                options={showcaseOptions}
                value={hiddenShowcaseOptions}
                isMulti
                styles={multiSelectInLabelStyles}
                onChange={handleHiddenShowcaseChange}
              />
            </FormGridControl>
          </FormGridRow>
        </FormWrapper>
      </MyProfileSection>
      <MyProfileSection className="side-padding">
        <H3>
          {t(
            MessageKeys.MyProfileTabMySettingsUserInterfaceNotificationSettings,
          )}
        </H3>
        <FormWrapper>
          <FormGridRow className="with-toggle">
            <FormGridLabel>
              {t(
                MessageKeys.MyProfileTabMySettingsHideRestoreFilterDraftsMessage,
              )}
            </FormGridLabel>
            <FormGridControl>
              <FormGridToggle
                checked={settings?.flags?.hideFilterDraftRestoredNotification}
                onChange={handleToggleHideFilterDraftsRestoredMessage}
              />
            </FormGridControl>
          </FormGridRow>
          <FormGridRow className="with-toggle">
            <FormGridLabel>
              {t(
                MessageKeys.MyProfileTabMySettingsDoNotShowConfirmTeamArticleNotificationsMessage,
              )}
            </FormGridLabel>
            <FormGridControl>
              <FormGridToggle
                checked={
                  settings?.flags?.doNotConfirmSendArticleTeamNotifications
                }
                onChange={handleDoNotConfirmTeamArticleNotificationFlagChange}
              />
            </FormGridControl>
          </FormGridRow>
        </FormWrapper>
      </MyProfileSection>
      <MyProfileSection className="side-padding">
        <H3>{t(MessageKeys.MyProfileTabMySettingsDeviceSettings)}</H3>
        <FormRow>
          <MyProfileFormRowLabel>
            {t(MessageKeys.MyProfileTabMySettingsTableColumnsReset)}
          </MyProfileFormRowLabel>
          <PrimaryButton onClick={handleResetColumns}>
            {t(MessageKeys.MyProfileTabMySettingsTableColumnsResetButtonLabel)}
          </PrimaryButton>
        </FormRow>
        <FormRow>
          <MyProfileFormRowLabel>
            {t(MessageKeys.MyProfileTabMySettingsDraftsReset)}
          </MyProfileFormRowLabel>
          <PrimaryButton onClick={handleResetDrafts}>
            {t(MessageKeys.MyProfileTabMySettingsDraftsResetButtonLabel)}
          </PrimaryButton>
        </FormRow>
      </MyProfileSection>
      <MyProfileSection className="side-padding">
        <DangerZone>
          <H3>{t(MessageKeys.LabelDangerZone)}</H3>
          <FormRow>
            <MyProfileFormRowLabel>
              {t(MessageKeys.MyProfileTabMySettingsResetAll)}
            </MyProfileFormRowLabel>
            <DangerButton onClick={handleResetAll}>
              {t(MessageKeys.LabelReset)}
            </DangerButton>
          </FormRow>
        </DangerZone>
      </MyProfileSection>
    </>
  );
};
