import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { GridLayoutMainWrapper } from '@fcg-tech/regtech-components';
import { FunctionComponent, useEffect } from 'react';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import { useCompanies } from '../../api/hooks';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routeFragments, routes } from '../../routes';
import { CompanyAdministrationPage } from '../CompanyAdministrationPage';
import { CompanyAdministrationDetails } from '../CompanyAdministrationPage/CompanyAdministrationDetails';
import { CompanyAdministrationPageOutlet } from '../CompanyAdministrationPage/CompanyAdministrationPageOutlet';

export const AdministrationBaseContainer: FunctionComponent = () => {
  const { data: companies } = useCompanies();
  const navigate = useNavigate();
  const match = useMatch(routes.superAdminCompanyCatchAll);
  const isMatch = Boolean(match);
  const { companyId } = isMatch ? match.params : { companyId: null };

  useEffect(() => {
    if (!isMatch && companies.length) {
      navigate(
        constructUrl(routes.superAdminCompany, {
          companyId: companies[0].id,
        }),
      );
    }
  }, [companies, isMatch, navigate]);

  return (
    <ErrorBoundary>
      <SuspenseLoader>
        <GridLayoutMainWrapper>
          <Routes>
            <Route
              element={<CompanyAdministrationPageOutlet superAdmin />}
              path={routeFragments.companyBase}
            >
              <Route
                path={routeFragments.companyAdministrationDetails}
                element={
                  <CompanyAdministrationDetails superAdmin key={companyId} />
                }
              />
              <Route
                path={routeFragments.teamBase}
                element={<CompanyAdministrationPage />}
              />
            </Route>
          </Routes>
        </GridLayoutMainWrapper>
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
