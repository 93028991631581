/* tslint:disable */
/* eslint-disable */
/**
 * RegEye
 * This API handles articles for RegEye
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FileInfoList,
    FileInfoListFromJSON,
    FileInfoListToJSON,
    FileInfoWithS3Url,
    FileInfoWithS3UrlFromJSON,
    FileInfoWithS3UrlToJSON,
    FileInput,
    FileInputFromJSON,
    FileInputToJSON,
} from '../models';

export interface CreatePresignedUploadUrlForTeamRequest {
    teamId: string;
    fileInput: FileInput;
}

export interface DeleteFileForTeamRequest {
    teamId: string;
    fileId: string;
}

export interface GetFilesForTeamRequest {
    teamId: string;
    skip?: number;
    limit?: number;
}

export interface GetPresignedFileUrlForTeamRequest {
    teamId: string;
    fileId: string;
}

export interface UpdateFileForTeamRequest {
    teamId: string;
    fileId: string;
    fileInput: FileInput;
}

/**
 * TeamAttachmentsApi - interface
 * 
 * @export
 * @interface TeamAttachmentsApiInterface
 */
export interface TeamAttachmentsApiInterface {
    /**
     * 
     * @summary Create a presigned upload url
     * @param {string} teamId the team id
     * @param {FileInput} fileInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamAttachmentsApiInterface
     */
    createPresignedUploadUrlForTeamRaw(requestParameters: CreatePresignedUploadUrlForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileInfoWithS3Url>>;

    /**
     * Create a presigned upload url
     */
    createPresignedUploadUrlForTeam(requestParameters: CreatePresignedUploadUrlForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileInfoWithS3Url>;

    /**
     * 
     * @summary Delete file
     * @param {string} teamId the team id
     * @param {string} fileId the file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamAttachmentsApiInterface
     */
    deleteFileForTeamRaw(requestParameters: DeleteFileForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete file
     */
    deleteFileForTeam(requestParameters: DeleteFileForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void>;

    /**
     * 
     * @summary List all files uploaded to team attachments
     * @param {string} teamId the team id
     * @param {number} [skip] number of records to skip for pagination
     * @param {number} [limit] maximum number of records to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamAttachmentsApiInterface
     */
    getFilesForTeamRaw(requestParameters: GetFilesForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileInfoList>>;

    /**
     * List all files uploaded to team attachments
     */
    getFilesForTeam(requestParameters: GetFilesForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileInfoList>;

    /**
     * 
     * @summary Create a presigned get url
     * @param {string} teamId the team id
     * @param {string} fileId the file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamAttachmentsApiInterface
     */
    getPresignedFileUrlForTeamRaw(requestParameters: GetPresignedFileUrlForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileInfoWithS3Url>>;

    /**
     * Create a presigned get url
     */
    getPresignedFileUrlForTeam(requestParameters: GetPresignedFileUrlForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileInfoWithS3Url>;

    /**
     * 
     * @summary Update file reference and create a presigned upload url
     * @param {string} teamId the team id
     * @param {string} fileId the file id
     * @param {FileInput} fileInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamAttachmentsApiInterface
     */
    updateFileForTeamRaw(requestParameters: UpdateFileForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileInfoWithS3Url>>;

    /**
     * Update file reference and create a presigned upload url
     */
    updateFileForTeam(requestParameters: UpdateFileForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileInfoWithS3Url>;

}

/**
 * 
 */
export class TeamAttachmentsApi extends runtime.BaseAPI implements TeamAttachmentsApiInterface {

    /**
     * Create a presigned upload url
     */
    async createPresignedUploadUrlForTeamRaw(requestParameters: CreatePresignedUploadUrlForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileInfoWithS3Url>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling createPresignedUploadUrlForTeam.');
        }

        if (requestParameters.fileInput === null || requestParameters.fileInput === undefined) {
            throw new runtime.RequiredError('fileInput','Required parameter requestParameters.fileInput was null or undefined when calling createPresignedUploadUrlForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-files/teams/{teamId}/files`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileInputToJSON(requestParameters.fileInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileInfoWithS3UrlFromJSON(jsonValue));
    }

    /**
     * Create a presigned upload url
     */
    async createPresignedUploadUrlForTeam(requestParameters: CreatePresignedUploadUrlForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileInfoWithS3Url> {
        const response = await this.createPresignedUploadUrlForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete file
     */
    async deleteFileForTeamRaw(requestParameters: DeleteFileForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteFileForTeam.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling deleteFileForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-files/teams/{teamId}/files/{fileId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete file
     */
    async deleteFileForTeam(requestParameters: DeleteFileForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteFileForTeamRaw(requestParameters, initOverrides);
    }

    /**
     * List all files uploaded to team attachments
     */
    async getFilesForTeamRaw(requestParameters: GetFilesForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileInfoList>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getFilesForTeam.');
        }

        const queryParameters: any = {};

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-files/teams/{teamId}/files`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileInfoListFromJSON(jsonValue));
    }

    /**
     * List all files uploaded to team attachments
     */
    async getFilesForTeam(requestParameters: GetFilesForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileInfoList> {
        const response = await this.getFilesForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a presigned get url
     */
    async getPresignedFileUrlForTeamRaw(requestParameters: GetPresignedFileUrlForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileInfoWithS3Url>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getPresignedFileUrlForTeam.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling getPresignedFileUrlForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/main/team-files/teams/{teamId}/files/{fileId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileInfoWithS3UrlFromJSON(jsonValue));
    }

    /**
     * Create a presigned get url
     */
    async getPresignedFileUrlForTeam(requestParameters: GetPresignedFileUrlForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileInfoWithS3Url> {
        const response = await this.getPresignedFileUrlForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update file reference and create a presigned upload url
     */
    async updateFileForTeamRaw(requestParameters: UpdateFileForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FileInfoWithS3Url>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling updateFileForTeam.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling updateFileForTeam.');
        }

        if (requestParameters.fileInput === null || requestParameters.fileInput === undefined) {
            throw new runtime.RequiredError('fileInput','Required parameter requestParameters.fileInput was null or undefined when calling updateFileForTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/main/team-files/teams/{teamId}/files/{fileId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FileInputToJSON(requestParameters.fileInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileInfoWithS3UrlFromJSON(jsonValue));
    }

    /**
     * Update file reference and create a presigned upload url
     */
    async updateFileForTeam(requestParameters: UpdateFileForTeamRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FileInfoWithS3Url> {
        const response = await this.updateFileForTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
