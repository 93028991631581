import { ArrowBigUp, Users } from 'lucide-react';
import styled from 'styled-components';

export const DataTableActionBarWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 4px 0 0 4px;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1.4rem;
`;

export const DataTableActionBarShiftIcon = styled(ArrowBigUp)`
  position: relative;
  top: 3px;
`;

export const DataTableActionBarTeamIcon = styled(Users)`
  position: relative;
  top: 3px;
`;
