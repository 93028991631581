import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalButtonRow,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
} from '@fcg-tech/regtech-components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { getAuthState, setAuthState } from '../../authFlow';
import { AuthMessageKeys } from '../../lang/authMessageKeys';
import {
  SessionExpiredModalUsername,
  SessionExpiredModalUsernameWrapper,
} from './SessionExpiredModal.styles';

interface SessionExpiredModalProps {
  onSubmit: () => void;
}

export const SessionExpiredModal: FunctionComponent<SessionExpiredModalProps> =
  ({ onSubmit }) => {
    const { t } = useTranslation();
    const authState = getAuthState();
    const [email, setEmail] = useState<string | undefined | null>(
      authState.email,
    );

    const handleNotYou = useCallback(() => {
      setEmail(null);
      setAuthState({
        ...getAuthState(),
        email: undefined,
      });
    }, []);

    const handleReAuthenticate = useCallback(() => {
      onSubmit();
    }, [onSubmit]);

    return (
      <Modal blurBackground>
        <ModalHeader>
          <ModalTitle>
            {t(AuthMessageKeys.AuthSessionExpired)}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            {t(AuthMessageKeys.AuthSessionExpiredMessage)}
          </p>
          {email ? (
            <>
              <p>
                {t(AuthMessageKeys.AuthSessionExpiredUsername)}
              </p>
              <SessionExpiredModalUsernameWrapper>
                <SessionExpiredModalUsername>
                  {email}
                </SessionExpiredModalUsername>
                <SecondaryButton onClick={handleNotYou}>
                  {t(AuthMessageKeys.AuthSessionExpiredNotYou)}
                </SecondaryButton>
              </SessionExpiredModalUsernameWrapper>
            </>
          ) : null}
          <ModalButtonRow>
            <PrimaryButton onClick={handleReAuthenticate}>
              {t(AuthMessageKeys.AuthSessionExpiredReAuthenticate)}
            </PrimaryButton>
          </ModalButtonRow>
        </ModalBody>
      </Modal>
    );
  };
