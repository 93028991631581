import update, { Spec } from 'immutability-helper';
import { useCallback, useState } from 'react';
import { BooleanTree } from '../../../../libs/regtech-components/src';
import { Publisher } from '../api/schema';
import { SubscriptionEnumType, SubscriptionItemValue } from '../types';

const getUpdateArticleTypeSelectionInstructions = (
  publisher: Publisher,
  subscribed: boolean,
): Spec<Publisher> => {
  const instructions: Spec<Publisher> = {
    subscribedToEntirePublisher: { $set: subscribed },
    articleTypes: {},
  };

  publisher.articleTypes.forEach((_, index) => {
    instructions.articleTypes[index] = { subscribed: { $set: subscribed } };
  });

  return instructions;
};

const getUpdateRegionSelectionInstructions = (
  items: Array<Publisher>,
  regionId: string,
  selected: boolean,
): Spec<Array<Publisher>> => {
  const instructions: Spec<Array<Publisher>> = {};

  items.forEach((publisher, index) => {
    if (publisher.region.id === regionId) {
      instructions[index] = getUpdateArticleTypeSelectionInstructions(
        publisher,
        selected,
      );
    }
  });

  return instructions;
};

export const useSubscriptionsChangeHandler = (
  initialPublishers: Array<Publisher>,
): [
  Array<Publisher>,
  React.ComponentPropsWithoutRef<typeof BooleanTree>['onChange'],
] => {
  const [items, setItems] = useState(initialPublishers);
  const handleChange = useCallback(
    (
      changedItem: {
        id: string | number;
        parentId?: string | number;
        value?: SubscriptionItemValue;
      },
      checked: boolean,
    ) => {
      setItems((old) => {
        const { value, parentId } = changedItem;
        if (value) {
          if (value.type === SubscriptionEnumType.ArticleType) {
            const index = old.findIndex(({ id }) => id === parentId);
            if (!value.id) {
              // All current and future article types checkbox selected or deselected
              const instructions: Spec<Array<Publisher>> = {};
              if (checked) {
                instructions[index] = getUpdateArticleTypeSelectionInstructions(
                  old[index],
                  true,
                );
              } else {
                instructions[index] = {
                  subscribedToEntirePublisher: { $set: false },
                };
              }
              return update(old, instructions);
            } else {
              // Actual article type
              const articleTypeIndex = old[index].articleTypes.findIndex(
                ({ id }) => id === value.id,
              );
              return update(old, {
                [index]: {
                  articleTypes: {
                    [articleTypeIndex]: { subscribed: { $set: checked } },
                  },
                  subscribedToEntirePublisher: {
                    $set: false,
                  },
                },
              });
            }
          } else if (value.type === SubscriptionEnumType.Region) {
            return update(
              old,
              getUpdateRegionSelectionInstructions(old, value.id, checked),
            );
          } else {
            const index = old.findIndex(({ id }) => id === value.id);
            return update(old, {
              [index]: getUpdateArticleTypeSelectionInstructions(
                old[index],
                checked,
              ),
            });
          }
        }
        return old;
      });
    },
    [],
  );

  return [items, handleChange];
};
