/* eslint-disable no-constant-condition */
import {
  Pagination,
  SearchPlusIcon,
  usePagination,
  useToggle,
} from '@fcg-tech/regtech-components';
import {
  FilterActionRowIconWrapper,
  FilterActionsRow,
  FilterActionsRowButton,
  FilterActionsRowWrapper,
  FilterBarIconButton,
  FilterDateRangeInterval,
  FilterSelect,
  FilterTextField,
  StoredFilterType,
} from '@fcg-tech/regtech-filter';
import {
  FeedArticle,
  GenericFeedArticleHook,
  PersonalDomain,
  SearchRow,
  StoredFilter,
  TableType,
  TagType,
} from '@fcg-tech/regtech-types/regeye';
import { single, useDevice } from '@fcg-tech/regtech-utils';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ArchivedOptions, Exclude } from '../../api/schema';
import { ErrorMessage } from '../../components/ErrorBoundary';
import { FeedArticleTable } from '../../components/FeedArticleTable';
import {
  FeedArticleTableAndFilterWrapper,
  StyledFilterBar,
} from '../../components/FeedArticleTable/FeedArticleTable.styles';
import { useFeedArticleTableContext } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { useTableColumnOptions } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { Filter, FilterBorderWrapper } from '../../components/Filter';
import { AdvancedSearchFilterDialog } from '../../components/Filter/AdvancedSearchFilterDialog';
import { FilterBarTeamFilterItem } from '../../components/Filter/FilterBarTeamFilterItem';
import { SearchHelpModal } from '../../components/modals';
import { useFilterSelectStyles } from '../../components/selectStyles';
import { GenericFeedArticleTableShowcase } from '../../components/showcases/GenericFeedArticleTableShowcase';
import { NewDataTableShowcase } from '../../components/showcases/NewDataTableShowcase';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { team } from '../../state/teamState';
import { MessageKeys } from '../../translations/translationTypes';
import { updateArticleUserMetadata } from '../../utils/articleMutations';
import {
  mergeFilterOptionItems,
  publicationDateIntervals,
} from '../../utils/filterHelpers';
import { useFilter, useTagAndTypeClickHandlers } from '../../utils/filterHooks';
import { parseQuery, useNonEphemeralData } from '../../utils/historyHelpers';
import { getSearchRows, getSearchString } from '../../utils/searchUtils';

interface GenericPaginatedFeedArticleTableProps {
  useFeedArticles: GenericFeedArticleHook;
  withFilterToggle?: boolean;
}

export const GenericPaginatedFeedArticleTable: FunctionComponent<
  GenericPaginatedFeedArticleTableProps
> = ({ useFeedArticles, withFilterToggle }) => {
  const { t } = useTranslation();

  const [page, setPage] = usePagination(1);
  const location = useLocation();
  const parsedQuery = parseQuery(location.search);

  const context = useFeedArticleTableContext();

  const selectedTeam = useRecoilValue(team(context?.teamId));
  const { isMobile } = useDevice();

  const {
    setFilterDisabled,
    domain,
    personalDomain,
    isFilterDisabled,
    isFilterVisible,
    setFilterVisible,
    handleFilterToggle,
  } = useFeedArticleTableContext();

  const handleFilterRequestClose = useCallback(
    () => setFilterVisible(false),
    [setFilterVisible],
  );

  const wrapper = useRef<HTMLDivElement>();

  const archived = parsedQuery.archived as ArchivedOptions;

  const {
    filter,
    filterHash,
    extendedFilter,
    filterId,
    storedFilters,
    pinnedFilters,
    translatedFilter,
    sortBy,
    hasInitialFilter,
    getFilterQuery,
    handleFilterChange,
    handleFilterClear,
    handleFilterDeleted,
    handleFilterPinned,
    handleFilterSaved,
    handleFilterValueChange,
    handleFilterValueClear,
    handleFilterValueExclude,
    handlePinnedFilterSortOrderChange,
    handleSearch,
    handleSortByChange,
    handleStoredFilterSelected,
  } = useFilter({
    teamId: context?.teamId,
    tableType: TableType.Generic,
    tableSubType: context.tableSubType,
  });

  const headingProps: Record<string, unknown> = {};
  if (context.teamId) {
    headingProps.team = selectedTeam?.name ?? '...';
  }

  const [columns, handleColumnOptionsChange] = useTableColumnOptions(
    TableType.Generic,
    domain,
  );

  const { data, mutate, isValidating, error } = useFeedArticles(
    {
      filter: extendedFilter,
      bookmarked: context?.personalDomain === PersonalDomain.Bookmarked,
      teamId: context?.teamId,
    },
    page,
    DEFAULT_PAGE_SIZE,
    { suspense: false },
  );

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (data?.pagination) {
        const totalPages = Math.ceil(
          data.pagination?.totalResults / data.pagination.limit,
        );
        if (pageNumber <= totalPages && pageNumber >= 1) {
          setPage(pageNumber);
          return true;
        }
      }
      return false;
    },
    [data?.pagination, setPage],
  );

  const handlePageChangeByDelta = useCallback(
    (direction: 1 | -1) => {
      return handlePageChange(page + direction);
    },
    [handlePageChange, page],
  );

  const handleArticleMutated = useCallback(
    (articleId: string, data: Partial<FeedArticle['userMetadata']>) => {
      updateArticleUserMetadata(articleId, data, mutate);
    },
    [mutate],
  );

  const tagAndTypeHandlers = useTagAndTypeClickHandlers(
    filter,
    getFilterQuery,
    true,
    handleFilterChange,
  );
  const paginationData = useNonEphemeralData(data?.pagination);
  const staticFilterData = useNonEphemeralData(translatedFilter);
  const isLoading = !data && isValidating;

  useEffect(() => {
    setFilterDisabled(!staticFilterData);
  }, [setFilterDisabled, staticFilterData]);

  const [showSearchHelpModal, , toggleSearchHelpModal] = useToggle(false);

  const [regions, publishers, tags, articleTypes] = useMemo(() => {
    return [
      mergeFilterOptionItems(filter.regions, translatedFilter.regions),
      mergeFilterOptionItems(filter.publishers, translatedFilter.publishers),
      mergeFilterOptionItems(filter.tags, translatedFilter.tags),
      mergeFilterOptionItems(filter.types, translatedFilter.types),
    ];
  }, [
    filter?.publishers,
    filter?.regions,
    filter?.tags,
    filter?.types,
    translatedFilter?.publishers,
    translatedFilter?.regions,
    translatedFilter?.tags,
    translatedFilter?.types,
  ]);

  const [advancedSearchRows, setAdvancedSearchRows] =
    useState<Array<SearchRow>>();

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const handleShowAdvancedSearchClick = useCallback(() => {
    setShowAdvancedSearch(true);
    setAdvancedSearchRows(getSearchRows(single(filter.searchString) ?? ''));
  }, [filter.searchString]);

  const handleRequestCloseAdvancedSearch = useCallback(
    () => setShowAdvancedSearch(false),
    [],
  );

  const handleSubmitAdvancedSearch = useCallback(
    (rows: Array<SearchRow>) => {
      const str = getSearchString(rows);
      setShowAdvancedSearch(false);
      handleFilterValueChange('searchString', str, filterId);
    },
    [filterId, handleFilterValueChange],
  );

  const handleArticleMetadataMutated = useCallback(() => {
    mutate();
  }, [mutate]);

  const excludeStyles = useFilterSelectStyles(undefined, true);
  const includeStyles = useFilterSelectStyles(undefined, false);

  const tagStyles = useFilterSelectStyles(
    TagType.Tag,
    filter.exclude?.includes(Exclude.Tags),
  );

  const articleTypeStyles = useFilterSelectStyles(
    TagType.ArticleTypeTag,
    filter.exclude?.includes(Exclude.Types),
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      {showSearchHelpModal ? (
        <SearchHelpModal onHide={toggleSearchHelpModal} />
      ) : null}
      {showAdvancedSearch ? (
        <AdvancedSearchFilterDialog
          rows={advancedSearchRows}
          onRequestClose={handleRequestCloseAdvancedSearch}
          onSubmit={handleSubmitAdvancedSearch}
        />
      ) : null}

      <GenericFeedArticleTableShowcase />
      <NewDataTableShowcase />

      <StyledFilterBar
        filters={pinnedFilters}
        currentFilterId={filterId}
        components={{
          [StoredFilterType.MultiUserFilter]: FilterBarTeamFilterItem,
        }}
        singleUserFilterToolTipMessageKey={
          MessageKeys.FeedArticleFilterPersonalFilterTooltipLabel
        }
        multiUserFilterToolTipMessageKey={
          MessageKeys.FeedArticleFilterTeamFilterTooltipLabel
        }
        onPinnedFilterClick={handleStoredFilterSelected}
        onPinnedFilterSortOrderChange={handlePinnedFilterSortOrderChange}
      >
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(
            paginationData?.totalResults / paginationData?.limit ?? 1,
          )}
          disabled={isLoading}
          narrow={isMobile}
          onPageClick={handlePageChange}
        />
        {withFilterToggle ? (
          <FilterBarIconButton
            disabled={isFilterDisabled}
            toggled={isFilterVisible}
            onClick={handleFilterToggle}
          />
        ) : null}
      </StyledFilterBar>
      <>
        <FeedArticleTableAndFilterWrapper ref={wrapper}>
          <FeedArticleTable
            feedArticles={data?.items}
            filterHash={filterHash}
            columns={columns}
            loading={isLoading}
            allowArrowKeyNavigation
            removeArchived={archived !== ArchivedOptions.All}
            removeUnbookmarked={personalDomain === PersonalDomain.Bookmarked}
            removeRead={filter?.unreadOnly === true}
            sortBy={sortBy}
            parentElement={wrapper.current}
            mutateArticle={handleArticleMutated}
            mutateFeedArticleList={mutate}
            onColumnsChange={handleColumnOptionsChange}
            onChangePageByDelta={handlePageChangeByDelta}
            onSortByChange={handleSortByChange}
            onArticleMetadataMutated={handleArticleMetadataMutated}
            {...tagAndTypeHandlers}
          />
          {isFilterVisible && translatedFilter ? (
            <FilterBorderWrapper>
              <Filter
                filter={filter}
                filterId={filterId}
                allowTeamFilters={Boolean(selectedTeam)}
                storedFilters={storedFilters as Array<StoredFilter>}
                excludePropertyKey="exclude"
                clearLabel={t(MessageKeys.LabelClear)}
                excludeLabel={t(MessageKeys.LabelExclude)}
                noItemsLabel={t(MessageKeys.FeedArticleFilterTagsNoTags)}
                showDraftRestoredNotification={hasInitialFilter}
                onDeleteFilter={handleFilterDeleted}
                onFilterChange={handleFilterChange}
                onFilterClear={handleFilterClear}
                onFilterLoad={handleStoredFilterSelected}
                onFilterValueChange={handleFilterValueChange}
                onFilterValueClear={handleFilterValueClear}
                onFilterValueExclude={handleFilterValueExclude}
                onPinFilter={handleFilterPinned}
                onRequestClose={handleFilterRequestClose}
                onSaveFilter={handleFilterSaved}
              >
                <FilterTextField
                  filterPropKey="searchString"
                  label="Keywords"
                  onHelpClick={toggleSearchHelpModal}
                >
                  <FilterActionsRow className="flex-end-horizontal important">
                    <FilterActionsRowWrapper>
                      <FilterActionsRowButton
                        onClick={handleShowAdvancedSearchClick}
                      >
                        {t(
                          MessageKeys.FeedArticleFilterShowAdvancedKeywordSearchButtonLabel,
                        )}
                        <FilterActionRowIconWrapper>
                          <SearchPlusIcon size="16" />
                        </FilterActionRowIconWrapper>
                      </FilterActionsRowButton>
                    </FilterActionsRowWrapper>
                  </FilterActionsRow>
                </FilterTextField>

                <FilterSelect
                  filterPropKey="regions"
                  label={t(MessageKeys.FeedArticleFilterRegionFilter)}
                  options={regions}
                  searchOnOpen
                  allowMultipleSelect
                  allowExclude
                  selectStyles={
                    filter.exclude?.includes(Exclude.Regions)
                      ? excludeStyles
                      : includeStyles
                  }
                  noItemsLabel={t(MessageKeys.FeedArticleFilterRegionNoItems)}
                  onSearch={handleSearch}
                />
                <FilterSelect
                  filterPropKey="publishers"
                  label={t(MessageKeys.FeedArticleFilterPublisherFilter)}
                  options={publishers}
                  searchOnOpen
                  allowMultipleSelect
                  allowExclude
                  selectStyles={
                    filter.exclude?.includes(Exclude.Publishers)
                      ? excludeStyles
                      : includeStyles
                  }
                  noItemsLabel={t(
                    MessageKeys.FeedArticleFilterPublisherNoItems,
                  )}
                  onSearch={handleSearch}
                />
                <FilterSelect
                  filterPropKey="types"
                  label={t(MessageKeys.FeedArticleFilterArticleTypeFilter)}
                  allowMultipleSelect
                  allowExclude
                  options={articleTypes}
                  searchOnOpen
                  selectStyles={articleTypeStyles}
                  noItemsLabel={t(
                    MessageKeys.FeedArticleFilterArticleTypeNoItems,
                  )}
                  onSearch={handleSearch}
                />
                <FilterSelect
                  filterPropKey="tags"
                  label={t(MessageKeys.FeedArticleFilterArticleTagsFilter)}
                  allowMultipleSelect
                  allowExclude
                  options={tags}
                  searchOnOpen
                  selectStyles={tagStyles}
                  noItemsLabel={t(MessageKeys.FeedArticleFilterTagsNoTags)}
                  onSearch={handleSearch}
                />
                <FilterDateRangeInterval
                  filterPropKey="publicationDate"
                  label={t(MessageKeys.FeedArticleFilterPublicationDateFilter)}
                  intervals={publicationDateIntervals}
                />
              </Filter>
            </FilterBorderWrapper>
          ) : null}
        </FeedArticleTableAndFilterWrapper>
      </>
    </>
  );
};
