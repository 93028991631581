import React, { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { ActivityLogArticlesTable } from './ActivityLogArticlesTable';

type Params = {
  teamId?: string;
  companyId?: string;
};

export const ActivityLogArticlesPage: FunctionComponent = () => {
  const params = useParams<Params>();
  const location = useLocation();

  return (
    <ErrorBoundary key={location.pathname}>
      <SuspenseLoader>
        <ActivityLogArticlesTable
          teamId={params?.teamId}
          companyId={params?.companyId}
        />
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
