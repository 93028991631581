import { GenericFeedArticleHook } from '@fcg-tech/regtech-types/regeye';
import { FunctionComponent } from 'react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { useFeedArticleTableContext } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { GenericPaginatedFeedArticleTable } from './GenericPaginatedFeedArticleTable';

export interface GenericPaginatedFeedArticlePageProps {
  withFilterToggle?: boolean;
  useFeedArticles: GenericFeedArticleHook;
}

export const GenericPaginatedFeedArticlePage: FunctionComponent<
  GenericPaginatedFeedArticlePageProps
> = ({ withFilterToggle, useFeedArticles }) => {
  const context = useFeedArticleTableContext();

  return (
    <ErrorBoundary>
      <SuspenseLoader>
        <GenericPaginatedFeedArticleTable
          useFeedArticles={useFeedArticles}
          withFilterToggle={withFilterToggle}
          key={`${context.teamId ?? context.companyId ?? 'personal'}-${
            context.tableSubType ?? 'default'
          }`}
        />
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
