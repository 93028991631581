import styled, { css } from 'styled-components';

import { CheckIcon, ChevronRightIcon } from '../Icons';
import { Menu, MenuItem } from '../Menu';

export const Checkbox = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors?.border ?? 'rgba(0,0,0,0.3)'};
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
  `}
`;

export const Checkmark = styled(CheckIcon)`
  ${({ theme }) => css`
    color: ${theme?.palette?.MainColor?.main ??
    theme?.palette?.MainBlack ??
    '#000'};
    width: 1.2rem;
    height: 1.2rem;
  `}
`;

export const StyledMenu = styled(Menu)`
  position: absolute;
  top: 100%;
  right: 0;
  max-height: 30rem;
  z-index: 1;
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const ChevronDown = styled(ChevronRightIcon)`
  transform: rotate(90deg);
`;
