import { excludeProps } from '@fcg-tech/regtech-components';
import styled, { css } from 'styled-components';

export const TeamArticlesEventDescripionWrapper = styled.div`
  display: inline;
`;

export const TeamArticlesEventDescripionHighlight = styled.span.withConfig(
  excludeProps('deemphasize'),
)<{ deemphasize?: boolean }>`
  ${({ deemphasize }) => css`
    background-color: #ffed93;
    padding: 0.1rem 0.2rem 0;
    opacity: ${deemphasize ? 0.6 : 1};
  `}
`;

export const TeamArticlesEventDescriptionImportanceWrapper = styled.div`
  display: inline-block;
  height: 0;
  position: relative;
  margin: 0 0.3ch;
  width: 3rem;

  & > .importance-indicator {
    position: absolute;
    top: 0;

    transform: scale(75%) translateY(-100%);
  }
`;
