import {
  ListNotificationsRequest,
  useNotifications as useNotificationsBase,
  useNotificationsReadActions as useNotificationsReadActionsBase,
} from '@fcg-tech/notifications';
import { useCallback, useMemo } from 'react';
import { SWRConfiguration } from 'swr';
import { NOTIFICATIONS_REGEYE } from '../../constants';
import { environment } from '../../environments/environment';
import { useApi } from '../apiUtils';

export const useNotifications = (
  params: Pick<ListNotificationsRequest, 'offset' | 'unreadOnly'> = {},
  config: SWRConfiguration = {},
) =>
  useNotificationsBase(
    environment.notificationApiBaseUrl,
    NOTIFICATIONS_REGEYE,
    params,
    config,
  );

export const useNotificationsReadActions = (currentOffset?: string) => {
  const { markNotificationsAsRead, markNotificationsAsUnread } =
    useNotificationsReadActionsBase(
      environment.notificationApiBaseUrl,
      currentOffset,
    );

  const overloadedMarkNotificationsAsRead = useCallback(
    (ids: string[]) => markNotificationsAsRead(NOTIFICATIONS_REGEYE, ids),
    [markNotificationsAsRead],
  );

  const overloadedMarkNotificationsAsUnread = useCallback(
    (ids: string[]) => markNotificationsAsUnread(NOTIFICATIONS_REGEYE, ids),
    [markNotificationsAsUnread],
  );

  return useMemo(
    () => ({
      markNotificationsAsRead: overloadedMarkNotificationsAsRead,
      markNotificationsAsUnread: overloadedMarkNotificationsAsUnread,
    }),
    [overloadedMarkNotificationsAsRead, overloadedMarkNotificationsAsUnread],
  );
};
