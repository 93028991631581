import { messages as filterMessages } from '@fcg-tech/regtech-filter';
import { messages as authMessages } from '@fcg-tech/regtech-auth';
import { MessageKeys, TranslationLocale } from './translationTypes';
import { plural } from '@fcg-tech/regtech-utils';

const feedArticleActionBarTip =
  '<b>Tip:</b> Hold <shift/> (Shift) to keep toolbars visible';

const en: TranslationLocale = {
  language: 'en',
  messages: {
    [MessageKeys.AccessErrorAddAssignee]:
      'You are not authorized to assign team members',
    [MessageKeys.AccessErrorAddTeamTag]:
      'You are not authorized to assign team tags',
    [MessageKeys.AccessErrorChangeAttachments]:
      'You are not authorized to change attachments',
    [MessageKeys.AccessErrorPersonalFilterList]:
      'You are not authorized to list filters',
    [MessageKeys.AccessErrorPersonalTeamList]:
      'You are not authorized to list teams',
    [MessageKeys.AccessErrorSetDueDate]:
      'You are not authorized to set the due date',
    [MessageKeys.AccessErrorTeamFilterList]:
      'You are not authorized to list team filters',
    [MessageKeys.AccessErrorTeamMemberList]:
      'You are not authorized to list team members',
    [MessageKeys.AccessErrorTeamSubscriptionList]:
      'You are not authorized to list subscriptions',
    [MessageKeys.AccessErrorTeamTagList]:
      'You are not authorized to list team tags',
    [MessageKeys.ActionsArticlesCompanyActionsHeading]: '{{name}}: Actions',
    [MessageKeys.ActivityLogArticlesCompanyHeading]: '{{name}}: Activity log',
    [MessageKeys.AdministrationCompanyCreateHeading]: 'Create new company',
    [MessageKeys.AdministrationCompanyDeleteButtonLabel]: 'Delete company',
    [MessageKeys.AdministrationCompanyDeleteConfirm]:
      'Are you sure you wish to delete {{name}}? This action is irrevocable',
    [MessageKeys.AdministrationCompanyDeleteFailure]:
      'Failed to delete company',
    [MessageKeys.AdministrationCompanyDeleteSuccess]: 'Company deleted',
    [MessageKeys.AdministrationCompanyEditHeading]: 'Edit {{name}}',
    [MessageKeys.AdministrationCompanyHeading]: '{{name}}: Administration',
    [MessageKeys.AdministrationCompanyNameConflictErrorMessage]:
      'Failed to save - company name already in use',
    [MessageKeys.AdministrationCompanyStringIdConflictErrorMessage]:
      'Failed to save - company string id already in use',
    [MessageKeys.AdministrationFiltersDeleteFilterFailureLabel]:
      'Failed to delete filter',
    [MessageKeys.AdministrationFiltersDeleteFilterSuccessLabel]:
      'Filter successfully deleted',
    [MessageKeys.AdministrationTabBarTeamDetailsLabel]: 'Company Details',
    [MessageKeys.AdministrationTabBarTeamFiltersLabel]: 'Article Filters',
    [MessageKeys.AdministrationTabBarTeamMembersLabel]: 'Members',
    [MessageKeys.AdministrationTabBarTeamsLabel]: 'Teams',
    [MessageKeys.AdministrationTabBarTeamSubscriptionsLabel]: 'Subscriptions',
    [MessageKeys.AdministrationTabBarTeamTagsLabel]: 'Tags',
    [MessageKeys.AdministrationTeamCreateFailure]: 'Failed to create team',
    [MessageKeys.AdministrationTeamCreateSuccess]: 'Team successfully created',
    [MessageKeys.AdministrationTeamDetails]: 'Team details',
    [MessageKeys.AdministrationTeamDetailsMembers]: 'Members',
    [MessageKeys.AdministrationTeamDetailsAddMeToThisTeam]:
      'Add me to this team',
    [MessageKeys.AdministrationTeamDetailsCreateTagSuccessLabel]:
      'Tag successfully created',
    [MessageKeys.AdministrationTeamDetailsDangerZoneLabel]: 'Danger zone',
    [MessageKeys.AdministrationTeamDetailsDeleteTeamConfirmLabel]:
      'Really delete team {{name}}',
    [MessageKeys.AdministrationTeamDetailsDeleteTeamFailureLabel]:
      'Failed to delete filter',
    [MessageKeys.AdministrationTeamDetailsDeleteTeamLabel]:
      'Delete team {{name}}',
    [MessageKeys.AdministrationTeamDetailsDeleteTeamSuccessLabel]:
      'Team successfully deleted',
    [MessageKeys.AdministrationTeamDetailsRemoveTagSuccessLabel]:
      'Tag sucessfully removed',
    [MessageKeys.AdministrationTeamDetailsTagActiveLabel]: 'Active',
    [MessageKeys.AdministrationTeamDetailsTagConfirmLabel]:
      'Really remove tag? This action is irrevocable and will affect all articles tagged with this tag',
    [MessageKeys.AdministrationTeamDetailsTagCreateLabel]: 'Create new tag',
    [MessageKeys.AdministrationTeamDetailsTagEditLabel]: 'Edit tag',
    [MessageKeys.AdministrationTeamDetailsTeamName]: 'Team name',
    [MessageKeys.AdministrationTeamDetailsUpdateTagSuccessLabel]:
      'Tag successfully updated',
    [MessageKeys.AdministrationTeamEditFailure]: 'Failed to update team',
    [MessageKeys.AdministrationTeamEditSuccess]: 'Team successfully updated',
    [MessageKeys.AdministrationTeamDeleteFailure]: 'Failed to Delete team',
    [MessageKeys.AdministrationTeamDeleteSuccess]: 'Team successfully deleted',
    [MessageKeys.AdministrationTeamFiltersHowToCreateFilters]:
      'You can create personal filters as well as team filters on any article or team actions page',
    [MessageKeys.AdministrationTeamHeading]: '{{team}}: Administration',
    [MessageKeys.AdministrationTeamMembersEmptyMessage]:
      'No members found. You can invite new members to this team by clicking the button above',
    [MessageKeys.AdministrationTeamMembersHeading]:
      'Team member administration',
    [MessageKeys.AdministrationTeamMembersInvitationMessage]:
      'Invitation message',
    [MessageKeys.AdministrationTeamMembersInvitationsHeading]: 'Invitations',
    [MessageKeys.AdministrationTeamMembersJoinTeamButtonLabel]: 'Join team',
    [MessageKeys.AdministrationTeamMembersJoinTeamSuccessMessage]:
      'You successfully joined {{name}}',
    [MessageKeys.AdministrationTeamMembersLeaveTeamButtonLabel]: 'Leave team',
    [MessageKeys.AdministrationTeamMembersLeaveTeamConfirmLabel]:
      'Do you really want to leave team {{name}}',
    [MessageKeys.AdministrationTeamMembersLeaveTeamSuccessMessage]:
      'You successfully left {{name}}',
    [MessageKeys.AdministrationTeamSubscriptionsHeading]:
      'Team subscription administration',
    [MessageKeys.AdministrationTeamSubscriptionsInactiveArticleTypeWarning]:
      'This article type was first added {{addedDate}} and was last updated {{updatedDate}} - {{since}}',
    [MessageKeys.AdministrationTeamTabCreateNewTeamLabel]: 'Create new Team',
    [MessageKeys.AdministrationTeamTabHeading]: 'Administration',
    [MessageKeys.AdministrationTeamTabNoTeamsMessage]: 'No teams found',
    [MessageKeys.AllArticleTypes]: 'All article types',
    [MessageKeys.ArticleArchivedForTeamButtonTooltip]:
      'Click to <b>un-archive</b> for <teamIcon/> {{name}}',
    [MessageKeys.ArticleArchivedForTeamButtonTooltipActionBar]: `Click to <b>un-archive</b> for <teamIcon/> {{name}}<br/>${feedArticleActionBarTip}`,
    [MessageKeys.ArticleArchivedForTeamButtonNotAllowedTooltip]:
      'You are not allowed to change the archive status in {{name}}',
    [MessageKeys.ArticleArchiveForMultipleTeamsButtonTooltip]:
      'Edit archive settings for all your teams',
    [MessageKeys.ArticleArchiveSettingsModalPreamble]:
      'Select for which team this article should be archived. Please note that changes are saved immediately. If you make a mistake, simply tick/untick the erroneous box again.',
    [MessageKeys.ArticleArchiveSettingsModalTitle]:
      'Article team archive settings',
    [MessageKeys.ArticleAssignedToMeFeed]: 'Assignments',
    [MessageKeys.ArticleBookmarkButtonTooltipIsBookmarked]:
      'Click to remove bookmark',
    [MessageKeys.ArticleBookmarkButtonTooltipIsBookmarkedActionBar]: `Click to remove bookmark.<br/>${feedArticleActionBarTip}`,
    [MessageKeys.ArticleBookmarkButtonTooltipIsNotBookmarked]:
      'Click to bookmark',
    [MessageKeys.ArticleBookmarkButtonTooltipIsNotBookmarkedActionBar]: `Click to bookmark.<br/>${feedArticleActionBarTip}`,
    [MessageKeys.ArticleBookmarkedFeed]: 'Bookmarked',
    [MessageKeys.ArticleCommentedByMeFeed]: 'Comments',
    [MessageKeys.ArticleFeed]: 'Article feed',
    [MessageKeys.ArticleFeedNoItems]: 'No articles found',
    [MessageKeys.ArticleFeedTableArchive]: 'Archive',
    [MessageKeys.ArticleFeedTableAssignedTo]: 'Assignees',
    [MessageKeys.ArticleFeedTableArticleType]: 'Article types',
    [MessageKeys.ArticleFeedTableBookmark]: 'Bookmark',
    [MessageKeys.ArticleFeedTableDate]: 'Publication date',
    [MessageKeys.ArticleFeedTableDescription]: 'Content',
    [MessageKeys.ArticleFeedTableDueDate]: 'Due date',
    [MessageKeys.ArticleFeedTableImportance]: 'Importance',
    [MessageKeys.ArticleFeedTableNoContent]: 'No articles found',
    [MessageKeys.ArticleFeedTablePublisher]: 'Publisher',
    [MessageKeys.ArticleFeedTableTeam]: 'Team',
    [MessageKeys.ArticleFeedTableRead]: 'Read',
    [MessageKeys.ArticleFeedTableTags]: 'Article tags',
    [MessageKeys.ArticleFeedTableTeamTags]: 'Team tags',
    [MessageKeys.ArticleFeedTableTitle]: 'Title',
    [MessageKeys.ArticleImportanceImportant]: 'Important',
    [MessageKeys.ArticleImportanceModerately]: 'Moderately important',
    [MessageKeys.ArticleImportanceSlightly]: 'Slightly important',
    [MessageKeys.ArticleImportanceUnimportant]: 'Unimportant',
    [MessageKeys.ArticleImportanceUnspecified]: 'Unspecified',
    [MessageKeys.ArticleImportanceVeryImportant]: 'Very important',
    [MessageKeys.ArticleMarkAsReadButtonTooltipIsNotRead]:
      'Click to mark as read, or press <code class="envelope">R</code> on your keyboard',
    [MessageKeys.ArticleMarkAsReadButtonTooltipIsNotReadActionBar]: `Click to mark as read<br/>${feedArticleActionBarTip}`,
    [MessageKeys.ArticleMarkAsReadButtonTooltipIsRead]:
      'Click to mark as unread, or press <code class="envelope">R</code> on your keyboard',
    [MessageKeys.ArticleMarkAsReadButtonTooltipIsReadActionBar]: `Click to mark as unread.<br/>${feedArticleActionBarTip}`,
    [MessageKeys.ArticleMetadataActivitiesLabel]: 'Activity log',
    [MessageKeys.ArticleMetadataAssigned]: 'Assigned',
    [MessageKeys.ArticleMetadataAssignReadByUser]: 'Read by {{name}}',
    [MessageKeys.ArticleMetadataAssignSelectPlaceholder]:
      'Select a team member...',
    [MessageKeys.ArticleMetadataAssignUnreadByUser]: 'Not read by {{name}}',
    [MessageKeys.ArticleMetadataAttachments]: 'Attachments',
    [MessageKeys.ArticleMetadataAttachmentsEmpty]: 'No attachments found',
    [MessageKeys.ArticleMetadataConfirmRemoveAttachment]:
      'Are you sure you wish to delete this attachment?',
    [MessageKeys.ArticleMetadataCreateTeamTagConflict]:
      'A tag with that name already exists',
    [MessageKeys.ArticleMetadataDueDate]: 'Due date',
    [MessageKeys.ArticleMetadataImportance]: 'Importance',
    [MessageKeys.ArticleMetadataNrOfActivities]: '1 activity',
    [plural(MessageKeys.ArticleMetadataNrOfActivities)]: '{{count}} activities',
    [MessageKeys.ArticleMetadataNrOfComments]: '1 comment',
    [plural(MessageKeys.ArticleMetadataNrOfComments)]: '{{count}} comments',
    [MessageKeys.ArticleMetadataRemoveAssignee]: 'Unassign {{name}}',
    [MessageKeys.ArticleMetadataStatus]: 'Status',
    [MessageKeys.ArticleMetadataStatusClosed]: 'Closed',
    [MessageKeys.ArticleMetadataStatusOpen]: 'Open',
    [MessageKeys.ArticleMetadataTeamTags]: 'Team tags',
    [MessageKeys.ArticleMetadataTeamTagsAddToFilter]:
      'Add this team tag to your current filter',
    [MessageKeys.ArticleMetadataTeamTagsClickWillNavigate]:
      'Clicking on any team tag will navigate to the actions view for team "{{name}}" with the clicked team tag as filter',
    [MessageKeys.ArticleMetadataNotifyButtonTooltip]:
      'Notify everyone in <teamIcon/> {{name}} about this article',
    [MessageKeys.ArticleMetadataAlreadyNotififedButtonTooltip]:
      'A notification was previously sent at <b>{{date}}</b> by <b>{{username}}</b>.',
    [MessageKeys.ArticleMetadataNotificationTimeout]:
      'A new notification can be sent in {{minutes}} minute(s)',
    [MessageKeys.ArticleMetadataNotifySuccessMessage]: 'Notifications sent!',
    [MessageKeys.ArticleMetadataNotifyConfirmTitle]: 'Confirm notification',
    [MessageKeys.ArticleMetadataNotifyConfirmBody]:
      'Are you sure you want to notify team {{team}}? This will send a notification to all members of the team',
    [MessageKeys.ArticleMetadataNotifyConfirmButtonLabel]: 'Send notification',
    [MessageKeys.ArticleMetadataCompanyIsEmpty]: ' - No active teams found',
    [MessageKeys.ArticlePermalinkCopiedMessage]: 'Permalink copied!',
    [MessageKeys.ArticlePermalinkTooltip]:
      'Click to copy the permanent link to this article',
    [MessageKeys.ArticlesFromMyTeams]: 'Subscribed articles in all my teams',
    [MessageKeys.ArticlesFromMyTeamsShort]: 'Articles',
    [MessageKeys.ArticleTrendingFeed]: 'Trending articles',
    [MessageKeys.ArticleTypes]: 'Article types',
    [MessageKeys.ArticleUnArchivedForTeamButtonTooltip]: `Click to <b>archive</b> for <teamIcon/> {{name}}`,
    [MessageKeys.ArticleUnArchivedForTeamButtonTooltipActionBar]: `Click to <b>archive</b> for <teamIcon/> {{name}}<br/>${feedArticleActionBarTip}`,
    [MessageKeys.ArticleViaPublisher]: 'via {{publisher}}',
    [MessageKeys.AttachmentUploadActiveMessage]: 'Drag files here',
    [MessageKeys.AttachmentUploadAddUrlButtonLabel]: 'Select URL',
    [MessageKeys.AttachmentUploadAddUrlTextAreaLabel]:
      'Add one or multiple URLs separated by newlines',
    [MessageKeys.AttachmentUploadDragFilesHintLabel]:
      'Hint: You can also drag files here to upload them',
    [MessageKeys.AttachmentUploadHoverMessage]: 'Drop the files to upload',
    [MessageKeys.AttachmentUploadSelectFilesButtonLabel]: 'Select files',
    [MessageKeys.AttachmentUploadUploadErrorMessage]: 'Failed to upload',
    [MessageKeys.AttachmentUploadUploadInProgressMessage]:
      'Uploading file(s)...',
    [MessageKeys.CommentEditingYourCommentFrom]:
      'Editing your comment from {{date}}',
    [MessageKeys.CommentFieldSubmitLabel]: 'Submit comment',
    [MessageKeys.CommentFieldPlaceholderLabel]: 'Write a comment...',
    [MessageKeys.CommentFieldSubmitReplyLabel]: 'Submit reply',
    [MessageKeys.CommentFieldCancelReplyLabel]: 'Cancel reply',
    [MessageKeys.CommentFieldCancelEditLabel]: 'Cancel edit',
    [MessageKeys.CommentWasDeleted]: 'Comment was deleted',
    [MessageKeys.CommentHintMentions]:
      'Hint: Type @ to mention other users in your team',
    [MessageKeys.CommentFieldNavigationWarning]:
      'You have an unsubmited comment - do you want to abandon it?',
    [MessageKeys.CommentFieldDraftRestored]:
      'A previously saved draft was restored',
    [MessageKeys.CommentFieldDraftFromDateRestored]:
      'A saved draft from {{date}} was restored',
    [MessageKeys.CommentFieldDeleteDraft]: 'Discard draft',
    [MessageKeys.CustomFeedEditPostFormArticleTags]: 'Article tag(s)',
    [MessageKeys.CustomFeedEditPostFormArticleType]: 'Article type(s)',
    [MessageKeys.CustomFeedEditPostFormAttachments]: 'Attachments',
    [MessageKeys.CustomFeedEditPostFormModalHeading]: 'Edit post',
    [MessageKeys.CustomFeedEditPostFormReference]: 'Document',
    [MessageKeys.CustomFeedEditPostFormSelectOrCreateTypeOptionText]:
      'Press enter to create article type',
    [MessageKeys.CustomFeedEditPostFormSelectOrCreateTypePlaceholder]:
      'Select or create an article type..',
    [MessageKeys.CustomFeedEditPostFormTypeToSelectOrCreateTypePlaceholder]:
      'Type to select or create an article type...',
    [MessageKeys.CustomFeedEditPostFormUploadFile]: 'Upload file',
    [MessageKeys.CustomFeedEditPostFormUploadLink]: 'URL',
    [MessageKeys.CustomFeedEditPostFormUploadLinkPlaceholder]:
      'Enter a web address...',
    [MessageKeys.CustomFeedEditPostFormUploadSelectFile]: 'Select file',
    [MessageKeys.CustomFeedNewPostFormModalHeading]: 'Add new post',
    [MessageKeys.CustomFeedPageRemoveFilesProgressLabel]:
      'Removing files {{progress}}/{{total}}',
    [MessageKeys.CustomFeedPageSaveCompletedLabel]: 'Post successfully saved',
    [MessageKeys.CustomFeedPageUploadFilesProgressLabel]:
      'Uploading files {{progress}}/{{total}}',
    [MessageKeys.CustomFeedsEditFormDescriptionLabel]: 'Description',
    [MessageKeys.CustomFeedsEditFormFeedPrivacyLabel]: 'Feed privacy',
    [MessageKeys.CustomFeedsEditFormModalCreateHeading]:
      'Create new custom feed',
    [MessageKeys.CustomFeedsEditFormModalEditHeading]: 'Edit {{name}}',
    [MessageKeys.CustomFeedsEditFormTitleLabel]: 'Name of custom feed',
    [MessageKeys.CustomFeedsListCreateNew]: 'Create new custom feed',
    [MessageKeys.CustomFeedsListFeedCreateFailure]:
      'Custom feed could not be created',
    [MessageKeys.CustomFeedsListFeedCreateNameConflict]:
      'Custom feed name already exists, please use a different name',
    [MessageKeys.CustomFeedsListFeedCreateSuccess]:
      'Custom feed created successfully',
    [MessageKeys.CustomFeedsListHeading]: 'Custom feeds',
    [MessageKeys.CustomPostsAddNewPost]: 'Add new post',
    [MessageKeys.CustomPostsTableCreatedByLabel]: 'Created by',
    [MessageKeys.CustomPostsTableCreatedLabel]: 'Created',
    [MessageKeys.CustomPostsTableLastModifiedByLabel]: 'Last modified by',
    [MessageKeys.CustomPostsTableLastModifiedLabel]: 'Last modified',
    [MessageKeys.CustomPostsTableNoPostsFound]: 'No posts created yet',
    [MessageKeys.CustomPostsTableTitleLabel]: 'Title',
    [MessageKeys.DefaultPlaceholder]: 'Enter {{subject}}',
    [MessageKeys.DashboardNotificationCount]:
      '<1>{{countStr}}</1> unread notifications',
    [MessageKeys.DashboardBookmarkCount]: '<1>{{countStr}}</1> bookmarks',
    [MessageKeys.DashboardOverdueCount]: '<1>{{countStr}}</1> overdue articles',
    [MessageKeys.DashboardAssignedCount]:
      '<1>{{countStr}}</1> assigned articles',
    [MessageKeys.DashboardAndCountMoreArticles]:
      '...and {{count}} more articles',
    [MessageKeys.DashboardAndCountMoreNotifications]:
      '...and {{count}} more notifications',
    [MessageKeys.DashboardViewAllNotifications]: 'View all notifications',
    [MessageKeys.DashboardViewAllBookmarks]: 'View all bookmarks',
    [MessageKeys.DashboardViewAllOverdue]: 'View all overdue articles',
    [MessageKeys.DashboardViewAllAssigned]: 'View all assigned articles',
    [MessageKeys.DashboardViewAllArticles]: 'View all team articles',
    [MessageKeys.DashboardAllCaughtUp]: 'All caught up!',
    [MessageKeys.DashboardNoOverdue]: 'No overdue articles',
    [MessageKeys.DashboardNoAssigned]: 'No assigned articles',
    [MessageKeys.DashboardNoBookmarks]: 'No bookmarks saved yet',
    [MessageKeys.DocumentationLinkTooltip]:
      'Click here to open the RegEye user guide',
    [MessageKeys.ErrorAuthorizationRequired]: 'Authorization required',
    [MessageKeys.ErrorInvalidEmail]: 'Not a valid e-mail address',
    [MessageKeys.ErrorInvalidUrl]: 'Not a valid URL',
    [MessageKeys.ErrorNotFoundHeading]:
      'Hmm, we could not find what you were looking for',
    [MessageKeys.ErrorNotFoundInfo]:
      'The page/article you requested could not be found. Please check the address and try again. If you believe this resource should exist, please contact support.',
    [MessageKeys.ErrorTimeoutHeading]:
      'Apologies, that took longer than expected',
    [MessageKeys.ErrorTimeoutInfo]:
      'The server did not respond in time. Please try again later. If the problem persists, please contact support.',
    [MessageKeys.ErrorReportToSupportMessage]:
      'If you choose to contact support, please supply them with the error identifier code below',
    [MessageKeys.ErrorServerError]: 'A fatal error occurred',
    [MessageKeys.ErrorGeneric]:
      'An unknown error occurred. Please try again later',
    [MessageKeys.ExportLimitExceeded]:
      'The export was successful, but was limited to {{limit}} articles. Please refine your filter if you need to export additional articles',
    [MessageKeys.FallbackNoCompaniesHasTeamsMessage]:
      'You belong to at least one company, but you are not a member of any team yet. Please contact your company administrator.',
    [MessageKeys.FallbackNoCompaniesHasTeamsMessageForAdmin]:
      'You do not belong to any team, you can add yourself to a team in Administration',
    [MessageKeys.FallbackNoCompaniesMessage]:
      'You do not belong to any company yet, or your access to this team/company has been revoked. Please contact your company administrator.',
    [MessageKeys.FallbackNoTeamsMessage]:
      'There are no teams in any of your companies. Please create at least one team.',
    [MessageKeys.FeedArticleFilterAdvancedKeywordDialogHeading]:
      'Advanced keyword search',
    [MessageKeys.FeedArticleFilterAdvancedKeywordSearchAddAnotherClause]:
      'Add another clause',
    [MessageKeys.FeedArticleFilterAdvancedKeywordSearchFinalString]:
      'Generated search',
    [MessageKeys.FeedArticleFilterAdvancedKeywordSearchMustInclude]:
      'Must include',
    [MessageKeys.FeedArticleFilterAdvancedKeywordSearchOrLabel]: '- OR -',
    [MessageKeys.FeedArticleFilterAdvancedKeywordSearchTip]:
      'Tip: Enclose multiple search terms in citationmarks (e.g "fintech regulation") to find exact sentances',
    [MessageKeys.FeedArticleFilterAdvancedKeywordSearchWhitespaceSeparation]:
      'Separate multiple search terms with whitespace',
    [MessageKeys.FeedArticleFilterAllUsers]: 'All users',
    [MessageKeys.FeedArticleFilterArticleTagsFilter]: 'Article tags',
    [MessageKeys.FeedArticleFilterArticleTypeFilter]: 'Article types',
    [MessageKeys.FeedArticleFilterArticleTypeNoItems]:
      'No article types available',
    [MessageKeys.FeedArticleFilterArchiveStatusFilter]: 'Archive status',
    [MessageKeys.FeedArticleFilterAssigneeFilter]: 'Assigned to',
    [MessageKeys.FeedArticleFilterAssigneeNoAssignees]:
      'No assignees available',
    [MessageKeys.FeedArticleFilterAttachedByFilter]: 'Attached by',
    [MessageKeys.FeedArticleFilterAttachedByNoAttachments]:
      'No attachments found',
    [MessageKeys.FeedArticleFilterCommentedByFilter]: 'Commented by',
    [MessageKeys.FeedArticleFilterCommentedNoCommenters]: 'No commenters found',
    [MessageKeys.FeedArticleFilterDateIntervalFrom]: 'From',
    [MessageKeys.FeedArticleFilterDateIntervalTo]: 'To',
    [MessageKeys.FeedArticleFilterDueDateFilter]: 'Due date',
    [MessageKeys.FeedArticleFilterFilterSaveFailure]: 'Failed to save filter',
    [MessageKeys.FeedArticleFilterFilterSaveSuccess]:
      'Filter successfully saved',
    [MessageKeys.FeedArticleFilterHeading]: 'Filter',
    [MessageKeys.FeedArticleFilterImportanceFilter]: 'Importance',
    [MessageKeys.FeedArticleFilterIncludeClosedFilter]: 'Include archived',
    [MessageKeys.FeedArticleFilterIncludeCompletedFilter]: 'Include completed',
    [MessageKeys.FeedArticleFilterLoadFilterLabel]: 'Load filter',
    [MessageKeys.FeedArticleFilterNoSearchResultsMessage]: 'No search results',
    [MessageKeys.FeedArticleFilterNoUsers]: 'No users available',
    [MessageKeys.FeedArticleFilterPerformedDateFilter]: 'Action date',
    [MessageKeys.FeedArticleFilterPersonalFilterTooltipLabel]:
      'Pinned personal filter "{{name}}"',
    [MessageKeys.FeedArticleFilterPinFilterButtonLabel]: 'Pin this filter',
    [MessageKeys.FeedArticleFilterPublicationDateFilter]: 'Publication date',
    [MessageKeys.FeedArticleFilterPublisherFilter]: 'Publishers',
    [MessageKeys.FeedArticleFilterPublisherNoItems]: 'No publishers available',
    [MessageKeys.FeedArticleFilterRegionFilter]: 'Regions',
    [MessageKeys.FeedArticleFilterRegionNoItems]: 'No regions available',
    [MessageKeys.FeedArticleFilterSavedUpdatedFilterButtonLabel]:
      'Save updated filter',
    [MessageKeys.FeedArticleFilterSaveFilterButtonLabel]: 'Save as new filter',
    [MessageKeys.FeedArticleFilterSaveFilterFilterNameLabel]: 'Filter name',
    [MessageKeys.FeedArticleFilterSaveFilterTeamFilterLabel]:
      'Create as team filter',
    [MessageKeys.FeedArticleFilterSearchInProgressMessage]: 'Searching...',
    [MessageKeys.FeedArticleFilterShowAdvancedKeywordSearchButtonLabel]:
      'Advanced',
    [MessageKeys.FeedArticleFilterSomePropertiesOverwrittenWarning]:
      'Some filter properties from your stored filter have been temporarily overwritten and disabled',
    [MessageKeys.FeedArticleFilterSortOrderSelectorHeading]:
      'Select sort order',
    [MessageKeys.FeedArticleFilterSubmitLabel]: 'Filter',
    [MessageKeys.FeedArticleFilterSummaryLabel]: '1 filter active',
    [plural(MessageKeys.FeedArticleFilterSummaryLabel)]:
      '{{count}} filters active',
    [MessageKeys.FeedArticleFilterTagsNoTags]: 'No tags available',
    [MessageKeys.FeedArticleFilterTeamActivityDateFilter]: 'Action performed',
    [MessageKeys.FeedArticleFilterTeamActivityEventFilter]: 'Action type',
    [MessageKeys.FeedArticleFilterTeamActivityPerformedByFilter]:
      'Performed by',
    [MessageKeys.FeedArticleFilterTeamFilterTooltipLabel]:
      'Pinned team filter "{{name}}"',
    [MessageKeys.FeedArticleFilterTeamFilter]: 'Teams',
    [MessageKeys.FeedArticleFilterTeamTagsFilter]: 'Team tags',
    [MessageKeys.FeedArticleFilterTeamTagsIncludeInactive]: 'Include inactive',
    [MessageKeys.FeedArticleFilterTooManySearchResultsMessage]:
      'Too many results - please refine your search',
    [MessageKeys.FeedArticleFilterTypeMoreToSearchMessage]:
      'Start typing to search...',
    [MessageKeys.FeedArticleFilterUnpinFilterButtonLabel]: 'Unpin filter',
    [MessageKeys.FeedArticleFilterUnreadOnlyFilter]: 'Show unread only',
    [MessageKeys.FeedArticleFilterDraftRestoredNotification]:
      'The previously used filter on this page was automatically restored',
    [MessageKeys.FeedArticleFilterDraftRestoredChangeThisBehaviour]:
      'Change this behaviour',
    [MessageKeys.FeedArticleFilterToggleUnreadOnlyTooltip]:
      'Viewing unread articles. Click to view all articles.',
    [MessageKeys.FeedArticleFilterToggleAllReadStatusTooltip]:
      'Viewing all articles. Click to view only unread articles.',
    [MessageKeys.FeedArticleFilterToggleUnarchivedOnlyTooltip]:
      'Viewing active articles. Click to include archived articles',
    [MessageKeys.FeedArticleFilterToggleAllArchivedStatusTooltip]:
      'Viewing all articles. Click to view only active articles',
    [MessageKeys.FeedArticleInfoArticleTypes]: 'Article types',
    [MessageKeys.FeedArticleInfoSourceTags]: 'Tags from source',
    [MessageKeys.FeedArticleModalNoArticleMessage]: 'No article found',
    [MessageKeys.FeedArticleOverlayBackToArticle]: 'Back to article',
    [MessageKeys.FeedArticleSearchResultsHeading]: 'Search results for',
    [MessageKeys.FeedArticleSearchResultsNoSearchTermHeading]:
      'Please provide a search term...',
    [MessageKeys.FeedArticleSearchResultSummary]:
      '<bold>{{totalResults}}</bold>  items in total found',
    [MessageKeys.FeedArticleTeamMetadataNotSubscribed]: '(Not subscribed)',
    [MessageKeys.FilterListTableNoContent]: 'No filters found',
    [MessageKeys.FilterTypeActionsFilter]: 'Action filter',
    [MessageKeys.FilterTypeArticleFilter]: 'Article filter',
    [MessageKeys.LabelAccept]: 'Accept',
    [MessageKeys.LabelAlias]: 'Alias',
    [MessageKeys.LabelAllTeams]: 'all teams',
    [MessageKeys.LabelArchived]: 'Archived',
    [MessageKeys.LabelNotArvchived]: 'Not archived',
    [MessageKeys.LabelAll]: 'All',
    [MessageKeys.LabelAscending]: 'Ascending',
    [MessageKeys.LabelCancel]: 'Cancel',
    [MessageKeys.LabelChangesMadeNavigationWarning]:
      'You have unsaved changes, are you sure you wish to leave this page?',
    [MessageKeys.LabelChangesSaved]: 'Changes saved successfully',
    [MessageKeys.LabelClear]: 'Clear',
    [MessageKeys.LabelClose]: 'Close',
    [MessageKeys.LabelCompany]: 'Company',
    [MessageKeys.LabelCompanyName]: 'Company name',
    [MessageKeys.LabelCompanyStringId]: 'Company string ID',
    [MessageKeys.LabelConfirmAction]: 'Please confirm',
    [MessageKeys.LabelConfirmLabel]: 'Confirm',
    [MessageKeys.LabelCouldNotSave]: 'An error occured, changes were not saved',
    [MessageKeys.LabelDangerZone]: 'Danger zone!',
    [MessageKeys.LabelDelete]: 'Delete',
    [MessageKeys.LabelDeleteFilterConfirmation]:
      'Are you sure you want to delete this filter?',
    [MessageKeys.LabelDescending]: 'Descending',
    [MessageKeys.LabelDescription]: 'Description',
    [MessageKeys.LabelDone]: 'Done',
    [MessageKeys.LabelDoNotShowThisAgain]: 'Do not show this again',
    [MessageKeys.LabelEdit]: 'Edit',
    [MessageKeys.LabelEmail]: 'E-mail',
    [MessageKeys.LabelEmailMaybePlural]: 'E-mail(s)',
    [MessageKeys.LabelExclude]: 'Exclude',
    [MessageKeys.LabelExport]: 'Export',
    [MessageKeys.LabelFilename]: 'Filename',
    [MessageKeys.LabelFilterName]: 'Filter name',
    [MessageKeys.LabelFilterType]: 'Filter type',
    [MessageKeys.LabelHide]: 'Hide',
    [MessageKeys.LabelInfo]: 'Information',
    [MessageKeys.LabelLogicalAnd]: 'and',
    [MessageKeys.LabelLogicalOr]: 'or',
    [MessageKeys.LabelLogIn]: 'Log in',
    [MessageKeys.LabelLogOut]: 'Log out',
    [MessageKeys.LabelMarkAsDone]: 'Mark as done',
    [MessageKeys.LabelMarkAsComplete]: 'Mark as complete',
    [MessageKeys.LabelMarkedAsComplete]: 'Marked as complete',
    [MessageKeys.LabelMarkAsNotCompleted]: 'Mark as not completed',
    [MessageKeys.LabelNotCompleted]: 'Not yet completed',
    [MessageKeys.LabelMessage]: 'Message',
    [MessageKeys.LabelNext]: 'Next',
    [MessageKeys.LabelNoFiltersFound]: 'No filters found',
    [MessageKeys.LabelNoTagsFound]: 'No tags found',
    [MessageKeys.LabelNoTeamsFound]: 'No teams found',
    [MessageKeys.LabelNothingFound]: 'Nothing found',
    [MessageKeys.LabelPrevious]: 'Previous',
    [MessageKeys.LabelReject]: 'Reject',
    [MessageKeys.LabelReset]: 'Reset',
    [MessageKeys.LabelRemoveTeamTag]: 'Remove team tag',
    [MessageKeys.LabelReply]: 'Reply',
    [MessageKeys.LabelReplyingTo]: 'Replying to {{name}}:',
    [MessageKeys.LabelSaveChanges]: 'Save changes',
    [MessageKeys.LabelSearch]: 'Keywords',
    [MessageKeys.LabelShow]: 'Show',
    [MessageKeys.LabelSubmit]: 'Save',
    [MessageKeys.LabelTagDescription]: 'Tag description',
    [MessageKeys.LabelTagName]: 'Tag name',
    [MessageKeys.LabelTeam]: 'Team',
    [MessageKeys.LabelTeamName]: 'team {{team}}',
    [MessageKeys.LabelTitle]: 'Title',
    [MessageKeys.LabelSelectAll]: 'Select all',
    [MessageKeys.LabelUsername]: 'E-mail',
    [MessageKeys.LabelWarning]: 'Warning',
    [MessageKeys.MyProfileTabMySettingsTableColumnsReset]: 'Table columns',
    [MessageKeys.MyProfileTabMySettingsDraftsReset]: 'Comment drafts',
    [MessageKeys.MyProfileTabMySettingsActionsColumns]: 'Action table columns',
    [MessageKeys.MyProfileTabMySettingsActionsLogColumns]:
      'Action log table columns',
    [MessageKeys.MyProfileTabMySettingsAdvancedSettings]: 'Advanced',
    [MessageKeys.MyProfileTabMySettingsAlias]: 'Your alias',
    [MessageKeys.MyProfileTabMySettingsDeviceSettings]:
      'Device-sepecific settings',
    [MessageKeys.MyProfileTabMySettingsFilterSettings]: 'Filter settings',
    [MessageKeys.MyProfileTabMySettingsUserInterfaceNotificationSettings]:
      'User interface notification settings',
    [MessageKeys.MyProfileTabMySettingsDisplaySettings]: 'Display settings',
    [MessageKeys.MyProfileTabMySettingsColumnSettings]: 'Table columns',
    [MessageKeys.MyProfileTabMySettingsFeedArticleColumns]:
      'Article table columns',
    [MessageKeys.MyProfileTabMySettingsFilters]: 'Personal filters',
    [MessageKeys.MyProfileTabMySettingsInvitationsHeading]:
      'Pending team invitations',
    [MessageKeys.MyProfileTabMySettingsLabel]: 'Profile',
    [MessageKeys.MyProfileTabMySettingsMarkAsRead]:
      'Automatically mark open articles as read',
    [MessageKeys.MyProfileTabMySettingsMarkAsReadAfterSeconds]:
      'after this many seconds (0 = immediately)',
    [MessageKeys.MyProfileTabMySettingsNamePreference]:
      'User name display preference',
    [MessageKeys.MyProfileTabMySettingsNamePreferenceAlias]: 'Alias',
    [MessageKeys.MyProfileTabMySettingsNamePreferenceEmail]: 'Email',
    [MessageKeys.MyProfileTabMySettingsNotifications]: 'Notifications',
    [MessageKeys.MyProfileTabMySettingsNotificationsEnableEmails]:
      'Enable e-mail notifications',
    [MessageKeys.MyProfileTabMySettingsNotificationsSelectTypes]:
      'Select notification types',
    [MessageKeys.MyProfileTabMySettingsTableColumnsResetButtonLabel]:
      'Reset table columns widths and order',
    [MessageKeys.MyProfileTabMySettingsDraftsResetButtonLabel]:
      'Clear all stored comment drafts',
    [MessageKeys.MyProfileTabMySettingsCompaniesAndTeamsHeading]:
      'Companies & Teams',
    [MessageKeys.MyProfileTabMySettingsWidthsResetMessage]:
      'Columns were reset',
    [MessageKeys.MyProfileTabMySettingsDraftsResetMessage]:
      'Drafts were cleared',
    [MessageKeys.MyProfileTabMySettingsRestoreFilterDraftsMessage]:
      'Automatically restore previously used filters',
    [MessageKeys.MyProfileTabMySettingsHideRestoreFilterDraftsMessage]:
      'Hide notification concerning automatically restored filters',
    [MessageKeys.MyProfileTabMySettingsShowcaseNewUser]: 'New user showcase',
    [MessageKeys.MyProfileTabMySettingsShowcaseMarkAsRead]:
      'Mark as read showcase',
    [MessageKeys.MyProfileTabMySettingsDoNotShowConfirmTeamArticleNotificationsMessage]:
      'Do not ask me to confirm sending article notifications to teams',
    [MessageKeys.MyProfileTabMySettingsShowcaseGenericFeedArticleTable]:
      'Feed article introduction',
    [MessageKeys.MyProfileTabMySettingsShowcaseTeamActions]:
      'Team actions introduction',
    [MessageKeys.MyProfileTabMySettingsShowcaseNewDataTable]:
      'New Table look & feel',
    [MessageKeys.MyProfileTabMySettingsShowcaseNewTabs]: 'New tabs/layout',
    [MessageKeys.MyProfileTabMySettingsShowcaseWhatsNewAugust2022]:
      "What's new - August 2022",
    [MessageKeys.MyProfileTabMyTeamsCompanyTeamsAndInvitations]:
      'Teams & Invitations',
    [MessageKeys.MyProfileTabMyTeamsHideTeamTooltip]:
      'Hiding a team will remove it from your side menu as well as anywhere else teams might be displayed',
    [MessageKeys.MyProfileTabMyTeamsInvitation]: 'Invitation',
    [MessageKeys.MyProfileTabMyTeamsInvitationByWithMessage]:
      ' - Invited by {{name}} with message "{{message}}"',
    [MessageKeys.MyProfileTabMySettingsResetAll]: 'Reset ALL personal settings',
    [MessageKeys.MyProfileTabMySettingsResetAllConfirmMessage]:
      'Are you sure you wish to reset all your personal settings?',
    [MessageKeys.NotificationsEmptyMessage]: 'No notifications found',
    [MessageKeys.NotificationsLabel]: 'Notifications',
    [MessageKeys.NotificationsPageHeading]: 'Notifications',
    [MessageKeys.NotificationsSettingsLabel]: 'Settings',
    [MessageKeys.NotificationsSettingsSavedFailure]:
      'Failed to update settings',
    [MessageKeys.NotificationsSettingsSavedSuccess]:
      'Successfully updated settings',
    [MessageKeys.NotificationsSettingsText]: 'Notifications via E-mail',
    [MessageKeys.NotificationsSettingsToggleTextOff]: 'Unsubscribe',
    [MessageKeys.NotificationsSettingsToggleTextOn]: 'Subscribe',
    [MessageKeys.NotificationsUnreadCountTooltip]: '1 unread notification',
    [plural(MessageKeys.NotificationsUnreadCountTooltip)]:
      '{{count}} unread notifications',

    [MessageKeys.NotificationTypeAssigned]: 'Assigned to article',
    [MessageKeys.NotificationTypeCommentReply]: 'Comment reply',
    [MessageKeys.NotificationTypeCommentMention]: 'Comment mention',
    [MessageKeys.NotificationTypeTeamInvite]: 'Team invite',
    [MessageKeys.NotificationTypeTeamNewMember]: 'New team member',
    [MessageKeys.NotificationTypeTeamRemovedMember]: 'Team member removed',
    [MessageKeys.NotificationTypeTeamNotify]: 'Article team notifications',
    [MessageKeys.NotificationTypeUnassigned]: 'Unassigned from article',
    [MessageKeys.NrOfHiddenTeamsMessage]: '1 team hidden',
    [plural(MessageKeys.NrOfHiddenTeamsMessage)]: '{{count}} teams hidden',
    [MessageKeys.NrOfHiddenCompaniesMessage]: '{{count}} company hidden',
    [plural(MessageKeys.NrOfHiddenCompaniesMessage)]:
      '{{count}} companies hidden',
    [MessageKeys.AllTeamsHiddenMessage]: 'All ({{count}}) teams hidden',
    [MessageKeys.RelativeDateIntervalCustom]: 'Custom',
    [MessageKeys.RelativeDateIntervalLastMonth]: 'Last 30 days',
    [MessageKeys.RelativeDateIntervalLastQuarter]: 'Last 90 days',
    [MessageKeys.RelativeDateIntervalLastWeek]: 'Last 7 days',
    [MessageKeys.RelativeDateIntervalLastYear]: 'Last 1 year',
    [MessageKeys.RelativeDateIntervalNextMonth]: 'Coming 30 days',
    [MessageKeys.RelativeDateIntervalNextWeek]: 'Coming 7 days',
    [MessageKeys.RelativeDateIntervalOverdue]: 'Overdue',
    [MessageKeys.RelativeDateIntervalThisMonth]: 'This month',
    [MessageKeys.RelativeDateIntervalThisQuarter]: 'This quarter',
    [MessageKeys.RelativeDateIntervalThisWeek]: 'This week',
    [MessageKeys.RelativeDateIntervalThisYear]: 'This year',
    [MessageKeys.RelativeDateIntervalToday]: 'Today',
    [MessageKeys.RemoveInvitation]: 'Remove invitation',
    [MessageKeys.RemoveInvitationConfirm]:
      'Do you really want to remove the invitation sent to {{user}}?',
    [MessageKeys.SearchBarAdvancedSearchButtonTooltip]:
      'Open advanced keyword search',
    [MessageKeys.SearchBarHelpModalAdvancedSearchDescription]:
      'You can click "Advanced" below the keyword search field to display the advanced keyword search dialog. Using this dialog makes it easier to create long keyword combinations. Every new textfield is separated by an OR-clause(see above), and will generally result in a larger search result',
    [MessageKeys.SearchBarHelpModalExample1Description]:
      'returns all articles containing the phrase “anti money laundering” or the word “aml”, but not containing the word “bank”',
    [MessageKeys.SearchBarHelpModalExample1Label]:
      '"anti money laundering" or aml -bank',
    [MessageKeys.SearchBarHelpModalExampleTextLabel]: 'Example phrase:',
    [MessageKeys.SearchBarHelpModalFieldsDescription]:
      'The following fields are included during keyword search: Article title, article description, article text, article tags and article types',
    [MessageKeys.SearchBarHelpModalOrTextDescription]:
      '"or" between keywords will generally widen your search result to include more articles',
    [MessageKeys.SearchBarHelpModalOrTextLabel]: 'OR:',
    [MessageKeys.SearchBarHelpModalQuotedTextDescription]:
      'requires that the phrase is found somewhere in the text',
    [MessageKeys.SearchBarHelpModalQuotedTextLabel]: 'Quoted text:',
    [MessageKeys.SearchBarHelpModalTitle]: 'Keyword search help',
    [MessageKeys.SearchBarHelpModalUnquotedTextDescription]:
      'requires each word to be found somewhere in the text',
    [MessageKeys.SearchBarHelpModalUnquotedTextLabel]: 'Unquoted text:',
    [MessageKeys.SearchBarSearchGloballyNoFilter]:
      'Search globally without filter',
    [MessageKeys.SearchBarSearchGloballyWithFilter]:
      'Search globally with current filter',
    [MessageKeys.SearchBarSearchLocallyNoFilter]:
      'Search in this context without filter',
    [MessageKeys.SearchBarSearchLocallyWithFilter]:
      'Search in this context with current filter',
    [MessageKeys.SearchBarTextFieldPlaceholder]: 'Search articles...',
    [MessageKeys.SideMenuAdministration]: 'Companies administration',
    [MessageKeys.SideMenuDashboard]: 'Dashboard',
    [MessageKeys.SideMenuAssignedCountBadgeTooltip]: 'You have one assignment',
    [plural(MessageKeys.SideMenuAssignedCountBadgeTooltip)]:
      'You have {{count}} assignments',
    [MessageKeys.SideMenuInvitationsBadgeTooltip]:
      'You have 1 pending team invitation',
    [plural(MessageKeys.SideMenuInvitationsBadgeTooltip)]:
      'You have {{count}} pending team invitations',
    [MessageKeys.SideMenuMyArticlesEventLog]: 'Activity log',
    [MessageKeys.SideMenuOverdueBadgeTooltip]:
      'This team has 1 overdue article',
    [plural(MessageKeys.SideMenuOverdueBadgeTooltip)]:
      'This team has {{count}} overdue articles',
    [MessageKeys.SideMenuPersonal]: 'Personal',
    [MessageKeys.SideMenuSourceOrigin]: '{{source}} sources',
    [MessageKeys.SideMenuTeamAbout]: 'About',
    [MessageKeys.SideMenuTeamActivitiesAssigned]: 'Assigned',
    [MessageKeys.SideMenuTeamActivitiesAttachment]: 'Attachments',
    [MessageKeys.SideMenuTeamActivitiesCommented]: 'Commented',
    [MessageKeys.SideMenuTeamActivitiesDueDate]: 'With Due date',
    [MessageKeys.SideMenuTeamActivitiesImportance]: 'With Importance',
    [MessageKeys.SideMenuTeamActivitiesTags]: 'Tags',
    [MessageKeys.SideMenuTeamActivityInsights]: 'Actions',
    [MessageKeys.SideMenuTeamAdministration]: 'Team administration',
    [MessageKeys.SideMenuTeamArticlesEventLog]: 'Activity log',
    [MessageKeys.SideMenuTeamArticlesArchive]: 'Archive',
    [MessageKeys.SideMenuCompanyAdministration]: 'Company administration',
    [MessageKeys.SideMenuEmptyCompanyMessage]: 'No team memberships',
    [MessageKeys.SideMenuEmptyCompanyMessageTooltip]:
      'Contact your company administrator to invite you to a team',
    [MessageKeys.SubscribedArticles]: 'Subscribed articles',
    [MessageKeys.SubscribedArticlesByTeam]: '{{team}}: Subscribed articles',
    [MessageKeys.TagListOverflowMessage]: '+{{count}} more',
    [MessageKeys.TagListShowLessLabel]: 'Show less',
    [MessageKeys.TagListShowMoreLabel]: 'Show more',
    [MessageKeys.TagSelectorCreateOptionText]: 'Create tag: {{tag}}',
    [MessageKeys.TagSelectorSelectOrCreateTagPlaceholder]:
      'Select or create a tag..',
    [MessageKeys.TagSelectorSelectTagPlaceholder]: 'Select a tag..',
    [MessageKeys.TagSelectorTypeToSelectOrCreateTagPlaceholder]:
      'Type to select or create a tag...',
    [MessageKeys.TagSelectorTypeToSelectTagPlaceholder]: 'Type to select a tag',
    [MessageKeys.TagSelectorNoTagsFoundTypeToCreate]:
      'No tags found, type to create your first tag',
    [MessageKeys.TagTableItemNoDescription]: 'No description added',
    [MessageKeys.TeamActivityActionsHeading]: '{{team}}: Actions',
    [MessageKeys.TeamActivityArticlesTableAction]: 'Action',
    [MessageKeys.TeamActivityArticlesTableDate]: 'Action date',
    [MessageKeys.TeamActivityCalendarAgendaLabel]: 'Agenda',
    [MessageKeys.TeamActivityCalendarCalendarLabel]: 'Calendar',
    [MessageKeys.TeamActivityCalendarDueDateOnlyInfoMessage]:
      'Note: Only articles with a set due date will appear in the calendar below',
    [MessageKeys.TeamActivityCalendarFeedArticleModalNoContentMessage]:
      'No articles found for {{month}}',
    [MessageKeys.TeamActivityCalendarNextMonthTooltip]: 'Go to next month',
    [MessageKeys.TeamActivityCalendarPrevMonthTooltip]: 'Go to previous month',
    [MessageKeys.TeamActivityCalendarNextArticleTooltip]: 'Go to next article',
    [MessageKeys.TeamActivityCalendarPrevArticleTooltip]:
      'Go to previous article',
    [MessageKeys.TeamActivityCalendarTodayLabel]: 'Today',
    [MessageKeys.TeamActivityDescriptionArchived]: 'Archived by {{name}}',
    [MessageKeys.TeamActivityDescriptionAssigned]:
      'Assigned to {{targetName}} by {{name}}',
    [MessageKeys.TeamActivityDescriptionAttachmentAdded]:
      '{{name}} added attachment {{attachmentName}}',
    [MessageKeys.TeamActivityDescriptionAttachmentRemoved]:
      '{{name}} removed attachment {{attachmentName}}',
    [MessageKeys.TeamActivityDescriptionCommented]:
      '{{name}} commented <comment>{{comment}}</comment>',
    [MessageKeys.TeamActivityDescriptionRemovedComment]:
      '{{name}} removed the comment <comment>{{comment}}</comment>',
    [MessageKeys.TeamActivityDescriptionRemovedDueDate]:
      '{{name}} removed due date <date>{{date}}</date>',
    [MessageKeys.TeamActivityDescriptionRemovedImportance]:
      '{{name}} removed importance <importance>{{importance}}</importance>',
    [MessageKeys.TeamActivityDescriptionSetDueDate]:
      '{{name}} set the due date to <date>{{date}}</date>',
    [MessageKeys.TeamActivityDescriptionSetImportance]:
      '{{name}} set importance to <importance>{{importance}}</importance>',
    [MessageKeys.TeamActivityDescriptionTagged]:
      '{{name}} added tag <tag>{{tag}}</tag>',
    [MessageKeys.TeamActivityDescriptionUnarchived]: 'Unarchived by {{name}}',
    [MessageKeys.TeamActivityDescriptionUnAssigned]:
      'Unassigned from {{targetName}} by {{name}}',
    [MessageKeys.TeamActivityDescriptionUntagged]:
      '{{name}} removed tag <tag>{{tag}}</tag>',
    [MessageKeys.TeamActivityDescriptionUpdatedComment]:
      '{{name}} updated a comment to <comment>{{comment}}</comment>',
    [MessageKeys.TeamActivityDescriptionUpdatedImportance]:
      '{{name}} updated importance from <previousImportance>{{previousImportance}}</previousImportance> to <importance>{{importance}}</importance>',
    [MessageKeys.TeamActivityDescriptionUpdateDueDate]:
      '{{name}} updated the due date from <previousDate>{{previousDate}}</previousDate> to <date>{{date}}</date>',
    [MessageKeys.TeamActivityDescriptionCompletedAssigned]:
      '{{name}} marked as completed',
    [MessageKeys.TeamActivityDescriptionUncompletedAssigned]:
      '{{name}} marked as not completed',
    [MessageKeys.TeamActivityDescriptionNotified]:
      '{{name}} sent a notification',
    [MessageKeys.TeamActivityEventArchived]: 'Archived',
    [MessageKeys.TeamActivityEventCompleted]: 'Completed assigned',
    [MessageKeys.TeamActivityEventUncompleted]: 'Uncompleted assigned',
    [MessageKeys.TeamActivityEventAssigned]: 'Assigned',
    [MessageKeys.TeamActivityEventAttached]: 'Attachment added',
    [MessageKeys.TeamActivityEventAttachment]: 'Attachments',
    [MessageKeys.TeamActivityEventCommented]: 'Commented',
    [MessageKeys.TeamActivityEventDueDate]: 'Due date',
    [MessageKeys.TeamActivityEventEditComment]: 'Edit comment',
    [MessageKeys.TeamActivityEventImportance]: 'Importance',
    [MessageKeys.TeamActivityEventNotified]: 'Notified',
    [MessageKeys.TeamActivityEventRemoveComment]: 'Remove comment',
    [MessageKeys.TeamActivityEventRemovedDueDate]: 'Remove due date',
    [MessageKeys.TeamActivityEventRemovedImportance]: 'Remove importance',
    [MessageKeys.TeamActivityEventSetDueDate]: 'Set due date',
    [MessageKeys.TeamActivityEventSetImportance]: 'Set importance',
    [MessageKeys.TeamActivityEventTagged]: 'Tagged',
    [MessageKeys.TeamActivityEventUnArchived]: 'Unarchived',
    [MessageKeys.TeamActivityEventUnassigned]: 'Unassigned',
    [MessageKeys.TeamActivityEventUnAttached]: 'Attachment removed',
    [MessageKeys.TeamActivityEventUntagged]: 'Untagged',
    [MessageKeys.TeamActivityEventUpdatedDueDate]: 'Update due date',
    [MessageKeys.TeamActivityEventUpdatedImportance]: 'Update importance',
    [MessageKeys.TeamArticlesEventLogHeading]: '{{team}}: Activity log',
    [MessageKeys.TeamInvitationAcceptedMessage]:
      'Invitation to {{name}} accepted',
    [MessageKeys.TeamInvitationRejectedMessage]:
      'Invitation to {{name}} rejected',
    [MessageKeys.TeamInvitationsSender]: 'Invited by',
    [MessageKeys.TeamSettingsMembersHeading]: 'Members',
    [MessageKeys.TeamSettingsSubscriptionsHeading]: 'Team subscriptions',
    [MessageKeys.TeamSettingsTabFiltersLabel]: 'Team filters',
    [MessageKeys.TeamSettingsCopyTeamFiltersLabel]:
      'Copy team filters to {{team}}',
    [MessageKeys.TeamSettingsTabMembersInviteMember]: 'Invite new member',
    [MessageKeys.TeamSettingsTabMembersInviteMemberPartiallySuccessful]:
      'Some users could not be invited: {{name}}. These e-mail addresses were either badly formated, or already in use in this team.',
    [MessageKeys.TeamSettingsTabMembersInviteMemberSuccessful]:
      'Member(s) successfully invited to team {{name}}',
    [MessageKeys.TeamSettingsTabMembersInviteMemberFailure]:
      'No users could be invited. Provided e-mail addresses were either badly formated, or already in use in this team.',
    [MessageKeys.TeamSettingsTabMembersLabel]: 'Members',
    [MessageKeys.TeamSettingsTabMembersRemoveInvitationSuccessful]:
      'Invitation removed',
    [MessageKeys.TeamSettingsTabMembersRemoveMemberConfirmLabel]:
      'Really remove member?',
    [MessageKeys.TeamSettingsTabMembersRemoveMemberSuccessful]:
      'Member successfully removed from team {{name}}',
    [MessageKeys.TeamSettingsTabMembersTooltip]:
      'These are the members of the team. Administrators can edit members under "Administration"',
    [MessageKeys.TeamSettingsTabSubscriptionsAllArticleTypes]:
      'All current and future article types',
    [MessageKeys.TeamSettingsTabSubscriptionsLabel]: 'Subscriptions',
    [MessageKeys.TeamSettingsTabSubscriptionsTooltip]:
      'These are the subscribed sources within the team. Administrators can change the subscription under "Administration"',
    [MessageKeys.TeamSettingsTabSubscriptionsHeading]: 'Source by region',
    [MessageKeys.TeamSettingsTabSubscriptionsDescription]:
      'Tick the checkbox to subscribe to a source. Click <chevron/> to expand and refine the subscription further',
    [MessageKeys.TeamSettingsTabSubscriptionsCopyFromTeam]:
      'Copy subscriptions from other team',
    [MessageKeys.TeamSettingsTabSubscriptionsNrOfArticleTypes]:
      'Total no. of article types',
    [MessageKeys.TeamSettingsTabSubscriptionsNrOfSubscribedArticleTypes]:
      'Total no. of subscribed article types',
    [MessageKeys.TeamSettingsTabTagsLabel]: 'Team tags',
    [MessageKeys.TeamSettingsTabTagsNameClashErrorMesssage]:
      'Could not create/update tag. A tag with that name already exists',
    [MessageKeys.TeamSettingsTabTagsTooltip]:
      'These are the tags of the team. Administrators can edit tags under "Administration"',
    [MessageKeys.TeamSettingsCopySubscriptionsLabel]: 'Copy subscriptions',
    [MessageKeys.TeamSettingsCopyTeamTagsLabel]: 'Copy team tags',
    [MessageKeys.TeamSettingsCopyTeamFilterLabel]: 'Copy team filters',
    [MessageKeys.TeamSettingsCopyTeamFilterSubmitLabel]: 'Copy filters',
    [MessageKeys.TeamSettingsCopyFromLabel]: 'Copy from team',
    [MessageKeys.TeamSettingsSelectTeamTags]: 'Select team tags',
    [MessageKeys.TeamSettingsSelectTeamFilters]: 'Select team filters',
    [MessageKeys.TeamSettingsIncludeDescription]: 'Also copy tag description',
    [MessageKeys.TeamSettingsCopyTagsSuccessful]:
      'Tags were successfully copied',
    [MessageKeys.TeamSettingsCopyTagsPartiallySuccessful]:
      'Some tags were successfully copied, but due to naming conflicts the following tags were skipped: {{tags}}',
    [MessageKeys.TeamSettingsCopyTagsFailed]:
      'Failed to copy tags, please try again later',
    [MessageKeys.TeamSettingsCopyFiltersSuccessful]:
      'Filters were successfully copied',
    [MessageKeys.TeamSettingsCopyFiltersPartiallySuccessful]:
      'Some filters were successfully copied, the following filters failed: {{filters}}',
    [MessageKeys.TeamSettingsCopyFiltersFailed]:
      'Failed to copy filters, please try again later',
    [MessageKeys.TeamSettingsTabSubscriptionsMatchedRegions]: 'Matched regions',
    [MessageKeys.TeamSettingsTabSubscriptionsMatchedPublishers]:
      'Matched publishers',
    [MessageKeys.TeamSettingsTabSubscriptionsMatchedArticleTypes]:
      'Matched article types',
    [MessageKeys.TeamSettingsTabSubscriptionsSearchPublishersPlaceholder]:
      'Search publishers...',
    [MessageKeys.TeamMultiInvitationEnableMultiTip]:
      'Tip: Press enter to add more members',
    [MessageKeys.TeamMultiInvitationSeparateWithNewlineTip]:
      'Tip: Separate member email addresses with newlines',
    [MessageKeys.WizardAdminIntro]:
      'This guide will help you set up your first team, invite members and create a subscription.',
    [MessageKeys.WizardCreateYourFirstTeam]: 'Create your first team',
    [MessageKeys.WizardDoneButtonLabel]: 'Done! Create my team',
    [MessageKeys.WizardDoneMessage]:
      'All done! You can now close this dialog and start using RegEye.',
    [MessageKeys.WizardDoneWithErrorsMessage]:
      'Your team was created, but there were some issues when inviting members and/or configuring your sources. Please try again in the Administration section. If the problem persists, please contact support.',
    [MessageKeys.WizardEmailValidationErrors]:
      'One or more e-mail addresses were removed due to either being malformed, or duplicates',
    [MessageKeys.WizardFailedToCreateTeamMessage]:
      'Could not create your team. Please contact support',
    [MessageKeys.WizardInvitationsIntro]:
      'It seems like you have your first team invitiation(s). ',
    [MessageKeys.WizardInviteEmailPlaceholder]:
      'Invite multiple co-workers by separating e-mails with whitespace or newline',
    [MessageKeys.WizardInviteMembers]: 'Invite members',
    [MessageKeys.WizardLabelAllArticleType]: 'All article types',
    [MessageKeys.WizardLabelCountArticleType]: '{{count}} article type(s)',
    [MessageKeys.WizardLabelSubscriptions]: 'Subscriptions',
    [MessageKeys.WizardLabelTeamMembers]: 'Team members',
    [MessageKeys.WizardLabelTeamName]: 'Team name',
    [MessageKeys.WizardLabelYouWillBeAddedToThisTeam]:
      'You will be added to this team',
    [MessageKeys.WizardLetsGo]: `Let's go!`,
    [MessageKeys.WizardNoTeamsHasInvitesIntro]:
      'It seems like you have your first team invitiation. ',
    [MessageKeys.WizardNoTeamsIntro]:
      'It seems like you have do not have any team invitiations yet. Please contact your company administrator to invite you to a team!',
    [MessageKeys.WizardProgressConfiguringSubscriptions]:
      'Configuring your sources ...',
    [MessageKeys.WizardProgressCreatingTeam]: 'Creating team ...',
    [MessageKeys.WizardProgressInvitingMembers]: 'Inviting members ...',
    [MessageKeys.WizardSelectSubscriptions]: `Select your team's sources`,
    [MessageKeys.WizardWelcomeToRegeye]: 'Welcome to RegEye',
    [MessageKeys.WizardJoinExistingTeam]: 'Join existing team',
    [MessageKeys.WizardJoinExistingTeamInstead]: 'Join existing team instead',
    [MessageKeys.WizardCreateNewTeam]: 'Create and join new team',
    [MessageKeys.WizardCreateNewTeamInstead]:
      'Create and join new team instead',
    [MessageKeys.WizardJoinTeamsButtonLabel]: 'Join',
    [MessageKeys.WizardCreateTeamButtonLabel]: 'Create and join',
    [MessageKeys.WizardJoinTeamSuccessMessage]: 'Team(s) successfully joined',

    [MessageKeys.ShowCaseWhatsNewMonthHeading]: "What's new - {{month}}",
    [MessageKeys.ShowCaseNewUserWelcomeToRegeyeHeading]: 'Welcome to RegEye',
    [MessageKeys.ShowCaseNewUserWelcomeToRegeyeBody]:
      'RegEye is a new regulatory horizon scanning platform focused on simplicity, relevance, and collaboration. It allows you to proactively manage regulatory change.\nIt automatically tracks, collects, and compiles news and updates from national and international authorities and associations around the world. As a result, busy compliance professionals can easily review and filter relevant regulatory change from hundreds of global sources.\nWith RegEye, you can collaborate with colleagues and external advisors, act on the results and follow the progress in your team. As a result, RegEye will enable you make better decisions and act on change in a timely manner.\nPowerful tracking and auditing make it simple to demonstrate quality in your process and report documented actions to stakeholders and regulators alike.',
    [MessageKeys.ShowCaseNewUserWelcomeToRegeyeFilterBody]:
      'Clicking the <i>Filter</i> button will show the filter sidebar. Here you can filter the articles according to your preferences.\nSatisfied with your filter? You can also save the filter and pin it to the header bar!',
    [MessageKeys.ShowCaseNewUserWelcomeToRegeyeColumnPickerBody]:
      'Clicking the <i>Column picker</i> button shows the available columns for the current view. The setting is personal and automatically saved.\nSelect according to your personal preference!',
    [MessageKeys.ShowCaseNewUserWelcomeToRegeyeNotificationsBody]:
      'Some actions in the system will generate notifications to your team members. Clicking the <i>Notifications</i> button will show all your notifications.\nWant to turn off email notifications?  This can be done in <i>Notifications settings</i> in your profile. ',
    [MessageKeys.ShowCaseNewUserWelcomeToRegeyeDocumentationBody]:
      'Do you want to know more about RegEye? Clicking the <i>User guide</i> button will open a new tab with the RegEye documentation!',

    [MessageKeys.ShowCaseTeamActionsWelcomeHeading]: 'Welcome to Actions',
    [MessageKeys.ShowCaseTeamActionsWelcomeBody]: `Team collaboration is a central part of RegEye. In the <i>Actions</i> section you will find the articles where your team has performed an action.

    Actions to take upon an article within a team includes:
    <ul class="showcase-narrow-list">
      <li>Tagging</li>
      <li>Assigning</li>
      <li>Setting importance</li>
      <li>Setting due date</li>
      <li>Adding attachments</li>
      <li>Comment</li>
     </ul>
    `,
    [MessageKeys.ShowCaseTeamActionsCalendarBody]:
      'Clicking the <i>Calendar</i> button will show all articles with a due date within the team in a calendar/agenda format.',
    [MessageKeys.ShowCaseTeamActionsExportBody]:
      'Do you need an attachment to a board report or want to continue working outside of RegEye? By clicking the <i>Download</i> button you are able to download a snapshot of the actions within your team in Excel format.',

    [MessageKeys.ShowCaseNewDataTableWelcomeHeading]: 'New article table look',
    [MessageKeys.ShowCaseNewDataTablePage1WelcomeBody]:
      "We have updated the look & feel of the article table. Read/unread status can now be gleaned from the article rows' text and background color.\n\nThe table is now also scrollable horizontally. As a result, you can now resize the columns to occupy more space than your viewport can display.",
    [MessageKeys.ShowCaseNewDataTablePage2WelcomeBody]:
      'The old buttons/icons for read/bookmarked/archived have been moved to a floating bar that appears as you hover your mouse over each individual row. If an article is read, bookmarked or archived, their respective buttons will be displayed in a dark blue color. In addition, archived articles will now be removed instantly from the table, unless you have a filter set to view both archived and not archived articles',

    [MessageKeys.ShowCaseNewTabsWelcomeHeading]: 'New tab layout',
    [MessageKeys.ShowCaseNewTabsWelcomeBody]:
      "We have a new look! For those users with multiple companies and teams, we've reduced the side menu navigation and moved all company and team related links to a tab bar. This should significantly improve navigation.\n\nWhere there was previously tab bar navigation, such as in your user profile, you will now find these options as a nested and vertical tab bar.",

    [MessageKeys.ShowCaseMarkAsReadWelcomeHeading]:
      'Automatically mark articles as read',
    [MessageKeys.ShowCaseMarkAsReadWelcomeBody]:
      'Did you know that you can configure how you want RegEye to mark articles as read? You can choose to mark an article as read immediately after opening it, or to wait certain number of seconds before doing so. You can also opt out completely from this behaviour. This can be configured under <b>Personal > Display settings</b>.',

    [MessageKeys.ShowCaseWhatsNewAugustBody]:
      "Hello! Over the summer we made some updates, and we added some new features to RegEye. Read on to discover that we've done.<br/><h2>Article count limit</h2><p>We’ve amassed a lot of content on RegEye. We have now limited the number of browsable articles to 5000, which is about 100 pages. Please note that you can always apply a filter and/or sort to find what you are looking for.<h2>Additional email notifications settings</h2><p>Previously you were only able to enable or disable email notifications. We have fined tuned your email notifications options. To update your settings and to find out more, head to Personal > Profile > Notifications.</p><h2>New read and archive toggles</h2><p>Read and archive status toggles have been moved from the filter and can now be found on the action bar above the article table. Why have we done this? It is more useful having the filter working on an article's core properties.</p>",
  },
};

export default {
  ...filterMessages.en.messages,
  ...authMessages.en.messages,
  ...en.messages,
};
