import styled from 'styled-components';

export const ImportanceSelectorWrapper = styled.div`
  display: inline-flex;
  position: relative;

  &.with-explanation {
    padding-bottom: 2rem;
  }
`;

export const ImportanceButton = styled.div.attrs({ role: 'button' })`
  position: relative;
  cursor: pointer;
  opacity: 0.5;
  will-change: opacity;

  & ~ & {
    margin-left: 1px;
  }

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    height: 2px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .has-selected & {
    opacity: 0.3;
  }

  &:hover {
    opacity: 1;
  }

  .has-selected &.selected {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &:hover:after {
    display: none;
  }

  &:hover {
    & .importance-indicator-chevron-wrapper {
      top: 1px;
    }
  }
`;

export const ImportanceButtonExplanation = styled.div`
  position: absolute;
  top: 100%;
  margin-top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  color: #666666;
  opacity: 0.5;
  display: none;
  white-space: nowrap;

  .with-explanation ${ImportanceButton}.selected + & {
    opacity: 1;
    display: block;
  }

  .with-explanation:hover ${ImportanceButton} + & {
    display: none;
  }

  .with-explanation ${ImportanceButton}:hover + & {
    display: block;
  }
`;
