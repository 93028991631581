import { useTranslation } from 'react-i18next';
import {
  ArticleExpandedMetadata,
  Tag,
  Team,
  TeamActions,
} from '@fcg-tech/regtech-types/regeye';
import update from 'immutability-helper';
import { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import { useInitContext } from '../../components/InitContext';
import { convertTag } from '../../converters';
import { teamState } from '../../state/teamState';
import { MessageKeys } from '../../translations/translationTypes';
import { checkConflict, useApi } from '../apiUtils';
import { TagInputList, TeamDashboard, TeamsApi } from '../schema';
import {
  allTeamsKey,
  articleMetaDataKey,
  assignedToMeCount,
  companiesKey,
  initDataKey,
  tagsForTeamKey,
  teamDashboardDataKey,
  teamKey,
} from './cacheKeys';
import { useCurrentUser, useSettings } from './personalApiHooks';

export const getAllTeams = async (
  api: TeamsApi,
  setTeams: (teams: Array<Team>) => void,
): Promise<Array<Team>> => {
  const result = await api.getTeams();
  let items = result.items as Array<Team>;
  try {
    if (
      process.env.NODE_ENV === 'development' &&
      localStorage.getItem('debug') &&
      localStorage.getItem('omitPermissions')
    ) {
      const omits = JSON.parse(
        localStorage.getItem('omitPermissions'),
      ) as Array<TeamActions>;
      items = items.map((team) => {
        team.teamActions = team.teamActions.filter((a) => !omits.includes(a));
        return team;
      });
    }
  } catch (err) {
    // NOOP
  }

  setTeams?.(items);

  return items;
};

export const useAllTeamsApi = (config: SWRConfiguration = {}) => {
  const api = useApi<TeamsApi>('TeamsApi');
  const { error: initDataError } = useInitContext();
  const setTeams = useSetRecoilState(teamState);

  return useSWR<Array<Team>>(
    initDataError ? null : allTeamsKey(),
    () => getAllTeams(api, setTeams),
    {
      suspense: true,
      dedupingInterval: 30000,
      revalidateOnFocus: false,
      focusThrottleInterval: 30000,
      ...config,
    },
  );
};

export const useAllTeams = (config: SWRConfiguration = {}) => {
  const { data: settings } = useSettings(config);
  const result = useAllTeamsApi(config);

  const items = useMemo(
    () =>
      result.data?.map((team) => {
        return {
          ...team,
          settings: settings.teams.find(({ id }) => team.id === id),
        };
      }),
    [result.data, settings.teams],
  );

  return { ...result, data: items };
};

export const getTagsForTeam = async (teamId: string, api: TeamsApi) => {
  const result = await api.getTagsForTeam({ teamId });
  return result.items.map((tag) => convertTag(tag, false));
};

export const useTeamTags = (
  teamId: string | null,
  config: SWRConfiguration = {},
) => {
  const api = useApi<TeamsApi>('TeamsApi');
  return useSWR<Array<Tag>>(
    teamId ? tagsForTeamKey(teamId) : null,
    () => getTagsForTeam(teamId, api),
    { suspense: true, ...config },
  );
};

export const useTeamTagActions = (teamId: string) => {
  const { t } = useTranslation();
  const api = useApi<TeamsApi>('TeamsApi');
  const { mutate } = useSWRConfig();
  return useMemo(
    () => ({
      /**
       *
       * @param tags TagInputList
       * @returns tuple of created tags and failed tags
       */
      createTags: async (
        tags: TagInputList['items'],
      ): Promise<[createdTags: Array<Tag>, failedTags: Array<string>]> => {
        try {
          const result = await api.createTagsForTeam({
            teamId,
            tagInputList: { items: tags },
          });

          if (result?.succeed) {
            const converted = result.succeed.map((tag) => convertTag(tag));
            mutate(tagsForTeamKey(teamId), async (current: Array<Tag>) => {
              return update(current, {
                $push: converted,
              });
            });

            return [converted, result?.failed ?? []];
          }
          return [[], result?.failed ?? []];
        } catch (err) {
          checkConflict(
            err,
            t(MessageKeys.TeamSettingsTabTagsNameClashErrorMesssage),
          );
        }
      },

      removeTag: async (tagId: string) => {
        mutate(
          tagsForTeamKey(teamId),
          async (current: Array<Tag>) => {
            const index = current.findIndex(({ id }) => id === tagId);
            if (index >= 0) {
              return update(current, { $splice: [[index, 1]] });
            }
            return current;
          },
          false,
        );

        await api.deleteTagForTeam({ tagId, teamId });
        return { id: tagId };
      },
      updateTag: async (tag: Tag) => {
        try {
          const result = await api.updateTagForTeam({
            tagId: tag.id,
            teamId,
            tagInput: tag,
          });

          if (result?.teamTag) {
            mutate(tagsForTeamKey(teamId), async (current: Array<Tag>) => {
              const index = current.findIndex(({ id }) => id === tag.id);
              return update(current, {
                [index]: {
                  $set: convertTag(result.teamTag),
                },
              });
            });
            return result.teamTag;
          }
        } catch (err) {
          checkConflict(
            err,
            t(MessageKeys.TeamSettingsTabTagsNameClashErrorMesssage),
          );
        }

        throw new Error(`Could not create tag for team ${teamId}`);
      },
    }),
    [api, mutate, t, teamId],
  );
};

export const getTeam = async (teamId: string, api: TeamsApi) => {
  const result = await api.getTeam({ teamId });
  return result.team;
};

export const useTeam = (teamId: string, config: SWRConfiguration = {}) => {
  const api = useApi<TeamsApi>('TeamsApi');
  return useSWR<Team>(teamKey(teamId), () => getTeam(teamId, api), {
    suspense: true,
    ...config,
  });
};

export const useArticleMetadata = (
  articleId: string,
  config: SWRConfiguration = {},
) => {
  const api = useApi<TeamsApi>('TeamsApi');
  return useSWR<ArticleExpandedMetadata>(
    articleMetaDataKey(articleId),
    () => api.getArticleMetadata({ articleId }),
    { suspense: true, ...config },
  );
};

export const useTeamMemberActions = (teamId: string | null | undefined) => {
  const api = useApi<TeamsApi>('TeamsApi');
  const { data: currentUser } = useCurrentUser();
  const { mutate } = useSWRConfig();

  return useMemo(
    () => ({
      removeMemberFromTeam: async (
        username: string,
        overrideTeamId?: string,
      ) => {
        await api.removeMemberFromTeam({
          teamId: overrideTeamId ?? teamId,
          userReference: { username },
        });
        if (username === currentUser?.username) {
          mutate(teamKey(teamId));
          mutate(allTeamsKey());
          mutate(companiesKey);
          mutate(assignedToMeCount);
          mutate(initDataKey());
        } else {
          mutate(teamKey(teamId));
          mutate(allTeamsKey());
        }
      },

      leaveTeam: async (overrideTeamId?: string) => {
        await api.removeMemberFromTeam({
          teamId: overrideTeamId ?? teamId,
          userReference: { username: currentUser.username },
        });
        mutate(teamKey(teamId));
        mutate(allTeamsKey());
        mutate(companiesKey);
        mutate(assignedToMeCount);
        mutate(initDataKey());
      },
    }),
    [api, currentUser.username, mutate, teamId],
  );
};

export const useTeamDashboard = (config: SWRConfiguration = {}) => {
  const api = useApi<TeamsApi>('TeamsApi');
  const key = teamDashboardDataKey();

  return useSWR<TeamDashboard>(key, () => api.getTeamDashboardData(), {
    suspense: true,
    ...config,
  });
};
