import { Article } from '@fcg-tech/regtech-types/regeye';
import { findAll, getAttributeValue } from 'domutils';
import getOuterHTML from 'dom-serializer';
import { parseDocument } from 'htmlparser2';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';

import { isAnchor, isImage } from '../../utils/domUtils';
import {
  feedArticleFrameCss,
  FeedArticleFrameInner,
  FeedArticleFrameWrapper,
} from './FeedArticleFrame.styles';
import { debounce } from '@fcg-tech/regtech-utils';

const httpHref = /^https?:/;

interface FeedArticleFrameProps {
  articleText: string;
  article: Article;
  onContentLoad?: () => void;
}

export const FeedArticleFrame: FunctionComponent<FeedArticleFrameProps> = ({
  articleText,
  article,
  onContentLoad,
}) => {
  const theme = useTheme();
  const [initialRender, setInitialRender] = useState(true);
  const fullHtml = useMemo(() => {
    const html = `
    <html>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap" rel="stylesheet">
        <style>${feedArticleFrameCss(theme)}</style>
      </head>
      ${htmlDecode(articleText)}
    </html>`;

    const dom = parseDocument(html);

    const anchors = findAll(isAnchor, dom.children);
    anchors.forEach((anchor) => {
      const href = getAttributeValue(anchor, 'href');
      if (href?.match(httpHref)) {
        anchor.attribs.target = '_blank';
      }
    });

    const images = findAll(isImage, dom.children);
    images.forEach((image) => {
      const src = getAttributeValue(image, 'src');
      if (src) {
        if (initialRender) {
          image.attribs.src = '';
        } else if (src.match(/icons/)) {
          image.attribs.style = 'max-width: 24px; max-height: 24px;';
        }
      }
    });

    return getOuterHTML(dom.children);
  }, [articleText, initialRender, theme]);

  const [height, setHeight] = useState(0);
  const frameRef = useRef<HTMLIFrameElement>();
  const frameWidth = useRef<number>();

  const handleResize = useCallback(() => {
    const newHeight =
      frameRef.current.contentWindow.document.body?.scrollHeight;
    if (newHeight && newHeight !== height) {
      if (newHeight > 50 || (newHeight > 0 && articleText?.length > 20))
        setHeight(newHeight + 50);
    }
  }, [articleText?.length, height]);

  const handleIframeLoad = useCallback(() => {
    setInitialRender(false);
    onContentLoad?.();
    // eslint-disable-next-line prefer-const
    let interval: NodeJS.Timeout;
    let watchdogCounter = 0;
    const checkResize = () => {
      if (frameRef.current.contentWindow.document.body) {
        handleResize();
        clearInterval(interval);
        return true;
      }
      watchdogCounter += 1;
      if (watchdogCounter > 30) {
        clearInterval(interval);
        console.warn('Failed to acquire article body within 3000ms');
        return false;
      }
      return false;
    };

    interval = setInterval(() => checkResize(), 100);
  }, [handleResize, onContentLoad]);

  useEffect(() => {
    const onResize = debounce(() => {
      handleResize();
    }, 500);
    window.addEventListener('resize', onResize);

    const interval = setInterval(() => {
      const bounds = frameRef.current.getBoundingClientRect();
      if (frameWidth.current && frameWidth.current !== bounds.width) {
        handleResize();
      }
      frameWidth.current = bounds.width;
    }, 1000);

    handleResize();

    return () => {
      window.removeEventListener('resize', onResize);
      clearInterval(interval);
    };
  }, [handleResize, height, onContentLoad]);

  return (
    <FeedArticleFrameWrapper>
      <FeedArticleFrameInner
        ref={frameRef}
        src={article.documentLocation?.ref}
        srcDoc={fullHtml}
        sandbox="allow-same-origin allow-popups-to-escape-sandbox allow-popups"
        seamless
        style={{ height, visibility: height === 0 ? 'hidden' : 'visible' }}
        onLoad={handleIframeLoad}
      />
    </FeedArticleFrameWrapper>
  );
};

function htmlDecode(input: string) {
  const ampLess = input.replace(/&amp;/g, '&');

  return ampLess;
}
