import { BaseEditor, Descendant } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

type CustomElement =
  | { type: 'paragraph'; children: Array<CustomText | RichTextMentionElement> }
  | RichTextMentionElement;
type CustomText = { text: string };

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export enum RichTextAnnotation {
  Tag,
  Mention,
}

export type RichTextElementType = 'mention' | 'tag';

export type RichTextMentionElement = {
  type: string;
  id?: string;
  children: Array<Descendant>;
};

export type RichTextMentionListComponentProps<T> = {
  items: Array<T>;
  onSelect(id: string, type: RichTextAnnotation): void;
};

export type RichTextMentionListComponent<T> = (
  props: RichTextMentionListComponentProps<T>,
) => JSX.Element;

export type RichTextAreaSearchOptions = {
  search: string;
  type: RichTextAnnotation;
};
