import update from 'immutability-helper';
import { useCallback } from 'react';

export const removeWithId = (targetId: string) => <T extends { id: string }>(
  data: Array<T>,
) => {
  const index = data.findIndex(({ id }) => id === targetId);
  if (index >= 0) {
    return update(data, { $splice: [[index, 1]] });
  }
  return data;
};

export const upsertWithId = <T extends { id: string }>(
  targetId: string | null,
  item: T,
) => (items: Array<T>) => {
  if (targetId) {
    const index = items.findIndex(({ id }) => id === targetId);
    if (index >= 0) {
      return update(items, { [index]: { $set: item } });
    }
  } else {
    return update(items, { $push: [item] });
  }
  return items;
};

export const upsertWithNestedId = <T extends unknown>(
  targetId: string | null,
  item: T,
  getId: (item: T) => string,
) => (items: Array<T>) => {
  if (targetId) {
    const index = items.findIndex((item) => getId(item) === targetId);
    if (index >= 0) {
      return update(items, { [index]: { $set: item } });
    }
  } else {
    return update(items, { $push: [item] });
  }
  return items;
};

export const useUpdateWithChangeEvent = <T extends { value: string }, U>(
  key: keyof U,
  setValues: React.Dispatch<React.SetStateAction<U>>,
) =>
  useCallback(
    (event: React.ChangeEvent<T>) => {
      const value = event.target?.value;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValues((old: any) => update(old, { [key]: { $set: value ?? '' } }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, setValues],
  );

export const mergeItems = <T extends { id: string }>(
  left: Array<T>,
  right: Array<T> = [],
  overwriteRightToLeft = false,
): Array<T> => {
  if (!left) {
    return undefined;
  }
  const leftIds = new Set(left.map(({ id }) => id));
  const rightIds = new Set(right.map(({ id }) => id));
  return [
    ...left.filter(({ id }) => {
      if (rightIds.has(id) && overwriteRightToLeft) {
        return false;
      }
      return true;
    }),
    ...right.filter(({ id }) => {
      if (!leftIds.has(id) || overwriteRightToLeft) {
        return true;
      }
      return false;
    }),
  ];
};

export const without = <T extends string | number>(
  arr: Array<T>,
  val: T,
): Array<T> => {
  const index = arr?.indexOf(val);
  if (!index || index < 0) {
    return arr;
  }
  return update(arr, { $splice: [[index, 1]] });
};
