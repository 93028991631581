import { SortingRule } from '@fcg-tech/regtech-datatable';
import {
  DateRangeInterval,
  FilterSelectOption,
  isDateRangeInterval,
  RelativeDateInterval,
  StoredFilter,
} from '@fcg-tech/regtech-filter';
import {
  DateFilter,
  FeedArticle,
  FeedArticleTableColumns,
  FilterListItem,
  FilterValues,
  OrderOptions,
  TableType,
} from '@fcg-tech/regtech-types/regeye';
import { cleanUndefined, single } from '@fcg-tech/regtech-utils';
import {
  addDays,
  addWeeks,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';
import update from 'immutability-helper';
import hash from 'object-hash';
import { FilterType } from '../api/schema';
import { MessageKeys } from '../translations/translationTypes';

export const isStoredFilterChanged = (
  storedFilter: StoredFilter<FilterValues>,
  filterValues: FilterValues,
) => {
  const filterValuesWithoutSort = update(filterValues, {
    $unset: ['orderBy'],
    archived: { $set: single(filterValues.archived) ?? undefined },
    searchString: {
      $set: single(filterValues.searchString)?.toString() ?? undefined,
    },
  });

  const storedFilterValues = cleanDateIntervals(
    cleanUndefined(storedFilter.filter),
  );

  const cleanedFilterValues = cleanDateIntervals(
    cleanUndefined(filterValuesWithoutSort),
  );

  return hash(storedFilterValues) !== hash(cleanedFilterValues);
};

export const cleanDateIntervals = (
  filterValues: FilterValues,
): FilterValues => {
  const updated = { ...filterValues };
  Object.entries(filterValues).forEach(([key, value]) => {
    if (isDateRangeInterval(value) && value.interval) {
      const interval: DateRangeInterval = { interval: value.interval };
      updated[key] = interval;
    }
  });

  return updated;
};

const emptyArray = [];

export const itemize = <T extends { id: number | string }>(
  idList: Array<string>,
  items: Array<T>,
): Array<T> => {
  return (
    idList
      ?.map<T>((id) => items.find((item) => item.id === id))
      .filter(Boolean) ?? emptyArray
  );
};

export const itemizeFilterSelectOptions = <T extends { id: number | string }>(
  idList: Array<string>,
  items: Array<FilterSelectOption<T>>,
): Array<FilterSelectOption<T>> => {
  return (
    idList
      ?.map<FilterSelectOption<T>>((id) =>
        items.find((item) => item.value.id === id),
      )
      .filter(Boolean) ?? emptyArray
  );
};

export const mergeFilterOptionItems = (
  filterValue: Array<string>,
  translatedFilterValue: Array<FilterListItem> = [],
): Array<FilterSelectOption<FilterListItem>> | undefined => {
  if (!filterValue) {
    return undefined;
  }

  return filterValue
    .map((id) => {
      const translatedItem = translatedFilterValue?.find((i) => i.id === id);
      return translatedItem
        ? {
            value: translatedItem,
            label: translatedItem?.name ?? id,
          }
        : null;
    })
    .filter(Boolean);
};

export const convertFilterListItemToFilterSelectOption = (
  item: FilterListItem,
): FilterSelectOption<FilterListItem> | null =>
  item
    ? {
        value: item,
        label: item.name,
      }
    : null;

export const getNoResultsMessageKey = (
  searchString: string,
  truncated: boolean,
  outOfOptions: boolean,
) => {
  if (searchString.length === 0 && !outOfOptions) {
    return MessageKeys.FeedArticleFilterTypeMoreToSearchMessage;
  }

  return MessageKeys.FeedArticleFilterNoSearchResultsMessage;
};

export const dueDateIntervals: Array<RelativeDateInterval> = [
  RelativeDateInterval.Overdue,
  RelativeDateInterval.Today,
  RelativeDateInterval.NextWeek,
  RelativeDateInterval.NextMonth,
];

export const performedDateIntervals: Array<RelativeDateInterval> = [
  RelativeDateInterval.Today,
  RelativeDateInterval.ThisPartialWeek,
  RelativeDateInterval.ThisPartialMonth,
  RelativeDateInterval.ThisPartialQuarter,
  RelativeDateInterval.ThisPartialYear,
  RelativeDateInterval.LastWeek,
  RelativeDateInterval.LastMonth,
];

export const publicationDateIntervals: Array<RelativeDateInterval> = [
  RelativeDateInterval.Today,
  RelativeDateInterval.ThisPartialWeek,
  RelativeDateInterval.ThisPartialMonth,
  RelativeDateInterval.ThisPartialQuarter,
  RelativeDateInterval.ThisPartialYear,
  RelativeDateInterval.LastThreeDays,
  RelativeDateInterval.LastWeek,
  RelativeDateInterval.LastTwoWeeks,
  RelativeDateInterval.LastMonth,
];

/** @deprecated */
export const getDateFilterForInterval = (
  interval: RelativeDateInterval,
): DateFilter => {
  let from: Date;
  let to: Date;

  switch (interval) {
    case RelativeDateInterval.Today:
      from = new Date();
      to = from;
      break;

    case RelativeDateInterval.Overdue:
      to = subDays(new Date(), 1);
      break;

    case RelativeDateInterval.LastWeek:
      from = subWeeks(new Date(), 1);
      to = new Date();
      break;

    case RelativeDateInterval.ThisPartialWeek:
      from = startOfWeek(new Date());
      to = new Date();
      break;

    case RelativeDateInterval.LastMonth:
      from = subMonths(new Date(), 1);
      to = new Date();
      break;

    case RelativeDateInterval.ThisPartialMonth:
      from = startOfMonth(new Date());
      to = new Date();
      break;

    case RelativeDateInterval.LastQuarter:
      from = subQuarters(new Date(), 1);
      to = new Date();
      break;

    case RelativeDateInterval.ThisPartialQuarter:
      from = startOfQuarter(new Date());
      to = new Date();
      break;

    case RelativeDateInterval.LastYear:
      from = subYears(new Date(), 1);
      to = new Date();
      break;

    case RelativeDateInterval.ThisPartialYear:
      from = startOfYear(new Date());
      to = new Date();
      break;

    case RelativeDateInterval.NextWeek:
      from = new Date();
      to = addWeeks(new Date(), 1);
      break;

    case RelativeDateInterval.NextMonth:
      from = new Date();
      to = addDays(new Date(), 30);
      break;
  }

  return {
    from,
    to,
    interval,
  };
};

export const getOrderBy = (sortBy?: SortingRule<FeedArticle>): OrderOptions => {
  if (!sortBy || sortBy.desc === undefined) {
    return undefined;
  }
  switch (sortBy.id) {
    case FeedArticleTableColumns.Assigned:
      return !sortBy.desc
        ? OrderOptions.AssignedAsc
        : OrderOptions.AssignedDesc;
    case FeedArticleTableColumns.Title:
      return !sortBy.desc ? OrderOptions.TitleAsc : OrderOptions.TitleDesc;
    case FeedArticleTableColumns.Type:
      return !sortBy.desc
        ? OrderOptions.ArticleTypesAsc
        : OrderOptions.ArticleTypesDesc;
    case FeedArticleTableColumns.Tags:
      return !sortBy.desc
        ? OrderOptions.ArticleTagsAsc
        : OrderOptions.ArticleTagsDesc;
    case FeedArticleTableColumns.TeamTags:
      return !sortBy.desc
        ? OrderOptions.TeamTagsAsc
        : OrderOptions.TeamTagsDesc;
    case FeedArticleTableColumns.Content:
      return !sortBy.desc
        ? OrderOptions.DescriptionAsc
        : OrderOptions.DescriptionDesc;
    case FeedArticleTableColumns.Publisher:
      return !sortBy.desc
        ? OrderOptions.PublisherAsc
        : OrderOptions.PublisherDesc;
    case FeedArticleTableColumns.Date:
      return !sortBy.desc ? OrderOptions.PubDateAsc : OrderOptions.PubDateDesc;
    case FeedArticleTableColumns.ActionDate:
      return !sortBy.desc ? OrderOptions.CreatedAsc : OrderOptions.CreatedDesc;
    case FeedArticleTableColumns.Action:
      return !sortBy.desc ? OrderOptions.ActionAsc : OrderOptions.ActionDesc;
    case FeedArticleTableColumns.Importance:
      return !sortBy.desc
        ? OrderOptions.ImportanceAsc
        : OrderOptions.ImportanceDesc;
    case FeedArticleTableColumns.DueDate:
      return !sortBy.desc ? OrderOptions.DueAsc : OrderOptions.DueDesc;
    case FeedArticleTableColumns.Teams:
      return !sortBy.desc ? OrderOptions.TeamAsc : OrderOptions.TeamDesc;
    default:
      // May or may not work
      return (
        !sortBy.desc ? `${sortBy.id}Asc` : `${sortBy.id}Desc`
      ) as OrderOptions;
  }
};

export const getSortBy = (
  orderBy: OrderOptions | Array<OrderOptions>,
): SortingRule<FeedArticle> | undefined => {
  switch (single(orderBy)) {
    case OrderOptions.ActionAsc:
      return { desc: false, id: FeedArticleTableColumns.Action };
    case OrderOptions.ActionDesc:
      return { desc: true, id: FeedArticleTableColumns.Action };
    case OrderOptions.AssignedAsc:
      return { desc: false, id: FeedArticleTableColumns.Assigned };
    case OrderOptions.AssignedDesc:
      return { desc: true, id: FeedArticleTableColumns.Assigned };
    case OrderOptions.TitleAsc:
      return { desc: false, id: FeedArticleTableColumns.Title };
    case OrderOptions.ArticleTypesAsc:
      return { desc: false, id: FeedArticleTableColumns.Type };
    case OrderOptions.ArticleTypesDesc:
      return { desc: true, id: FeedArticleTableColumns.Type };
    case OrderOptions.ArticleTagsAsc:
      return { desc: false, id: FeedArticleTableColumns.Tags };
    case OrderOptions.ArticleTagsDesc:
      return { desc: true, id: FeedArticleTableColumns.Tags };
    case OrderOptions.TeamTagsAsc:
      return { desc: false, id: FeedArticleTableColumns.TeamTags };
    case OrderOptions.TeamTagsDesc:
      return { desc: true, id: FeedArticleTableColumns.TeamTags };
    case OrderOptions.TitleDesc:
      return { desc: true, id: FeedArticleTableColumns.Title };
    case OrderOptions.DescriptionAsc:
      return { desc: false, id: FeedArticleTableColumns.Content };
    case OrderOptions.DescriptionDesc:
      return { desc: true, id: FeedArticleTableColumns.Content };
    case OrderOptions.PublisherAsc:
      return { desc: false, id: FeedArticleTableColumns.Publisher };
    case OrderOptions.PublisherDesc:
      return { desc: true, id: FeedArticleTableColumns.Publisher };
    case OrderOptions.ImportanceAsc:
      return { desc: false, id: FeedArticleTableColumns.Importance };
    case OrderOptions.ImportanceDesc:
      return {
        desc: true,
        id: FeedArticleTableColumns.Importance,
      };
    case OrderOptions.PubDateAsc:
      return { desc: false, id: FeedArticleTableColumns.Date };
    case OrderOptions.PubDateDesc:
      return { desc: true, id: FeedArticleTableColumns.Date };
    case OrderOptions.DueAsc:
      return { desc: false, id: FeedArticleTableColumns.DueDate };
    case OrderOptions.DueDesc:
      return { desc: true, id: FeedArticleTableColumns.DueDate };
    case OrderOptions.CreatedAsc:
      return { desc: false, id: FeedArticleTableColumns.ActionDate };
    case OrderOptions.CreatedDesc:
      return {
        desc: true,
        id: FeedArticleTableColumns.ActionDate,
      };
    case OrderOptions.TeamAsc:
      return { desc: false, id: FeedArticleTableColumns.Teams };
    case OrderOptions.TeamDesc:
      return { desc: true, id: FeedArticleTableColumns.Teams };
    default:
      return single(orderBy)
        ? {
            desc: single(orderBy).endsWith('Desc'),
            id: single(orderBy).replace(
              /(Asc|Desc)$/,
              '',
            ) as FeedArticleTableColumns,
          }
        : undefined;
  }
};

export const getFilterTypeFromTableType = (
  tableType: TableType,
): FilterType | null => {
  switch (tableType) {
    case TableType.Generic:
      return FilterType.ArticleFilter;
    case TableType.Actions:
      return FilterType.InsightFilter;
    default:
      return null;
  }
};
