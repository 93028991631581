import { constructUrl } from '@fcg-tech/regtech-api-utils';
import {
  SideNavigationItem,
  SideNavigationItemIcon,
  SideNavigationItemLinkLabel,
  SideNavigationMenu,
  UserIcon,
} from '@fcg-tech/regtech-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAssignedToMeCount, useInvitations } from '../../api/hooks';
import { routes } from '../../routes';
import { MessageKeys } from '../../translations/translationTypes';
import { Tooltip } from '../Tooltip';
import {
  MainSideNavigationBadge,
  MainSideNavigationBadgeWrapper,
} from './MainSideNavigation.styles';
import { TeamSideNavigationWrapper } from './TeamSideNavigation.styles';

export const PersonalSideNavigation: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data: invitations } = useInvitations({ suspense: false });
  const { data: assignedCount = 0 } = useAssignedToMeCount({ suspense: false });

  const personalBadges = (
    <MainSideNavigationBadgeWrapper>
      {invitations?.length ? (
        <Tooltip
          content={t(MessageKeys.SideMenuInvitationsBadgeTooltip, {
            count: invitations?.length,
          })}
          placement="right"
        >
          <MainSideNavigationBadge>
            {invitations.length}
          </MainSideNavigationBadge>
        </Tooltip>
      ) : null}
      {assignedCount ? (
        <Tooltip
          placement="right"
          content={t(MessageKeys.SideMenuAssignedCountBadgeTooltip, {
            count: assignedCount,
          })}
        >
          <MainSideNavigationBadge>{assignedCount}</MainSideNavigationBadge>
        </Tooltip>
      ) : null}
    </MainSideNavigationBadgeWrapper>
  );

  return (
    <TeamSideNavigationWrapper>
      <SideNavigationMenu>
        <SideNavigationItem href={constructUrl(routes.personal)} end={false}>
          <SideNavigationItemIcon as={UserIcon} />
          <SideNavigationItemLinkLabel>
            {t(MessageKeys.SideMenuPersonal)}
          </SideNavigationItemLinkLabel>
          {personalBadges}
        </SideNavigationItem>
      </SideNavigationMenu>
    </TeamSideNavigationWrapper>
  );
};
