import { Tag, ApiTag } from '@fcg-tech/regtech-types/regeye';
import { customTagConversion } from '../constants';

export const convertTag = (tag: ApiTag, convertCasing = true): Tag => {
  const { lastUpdate, ...rest } = tag;
  const customConversion = customTagConversion[tag.name];
  return {
    ...rest,
    lastUpdate: lastUpdate ? new Date(lastUpdate) : undefined,
    originalName: tag.name,
    name:
      customConversion && typeof customConversion === 'string'
        ? customConversion
        : convertCasing && tag.name?.length > 3 && !customConversion
        ? tag.name
            .toLocaleLowerCase()
            .replace(/^[\w\u00C0-\u02AF]/i, (w: string) =>
              w.toLocaleUpperCase(),
            )
        : tag.name,
  };
};
