import styled, { css } from 'styled-components';

export const MultiTenantAuthProviderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const MultiTenantAuthProviderErrorWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 100%;
    margin-bottom: 2rem;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 2.6rem;
    background-color: ${theme?.colors?.error?.main ?? 'red'};
    color: ${theme?.colors?.error?.contrast ?? 'white'};
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
  `}
`;
