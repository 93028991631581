import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCompany, useCompanyTeams } from '../../api/hooks';
import {
  TeamAdministration,
  useTeamAdmin,
} from '../../components/Administration';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { routes } from '../../routes';

interface CompanyTeamAdministrationProps {
  teamId: string;
  companyId: string;
}

export const CompanyTeamAdministration: FunctionComponent<
  CompanyTeamAdministrationProps
> = ({ teamId, companyId }) => {
  const { data: company } = useCompany(companyId);
  const { data: teams } = useCompanyTeams(companyId);
  const navigate = useNavigate();

  const {
    team,
    handleAddSelfToTeam,
    handleCreateTeamInvitation,
    handleLeaveTeam,
    handleRemoveMemberFromTeam,
    handleRemoveTeamInvitation,
    handleTeamDelete,
    handleTeamUpdate,
  } = useTeamAdmin(companyId, teamId);

  const handleTeamDeleteOverride = useCallback<typeof handleTeamDelete>(
    async (companyId, teamId) => {
      await handleTeamDelete(companyId, teamId);
      const filteredTeams = teams.filter((team) => team.id !== teamId);
      if (filteredTeams.length) {
        navigate(
          constructUrl(routes.companyTeamAdministration, {
            teamId: filteredTeams[0].id,
            companyId,
          }),
        );
      } else {
        navigate(
          constructUrl(routes.companyAdministrationDetails, { companyId }),
        );
      }
    },
    [handleTeamDelete, navigate, teams],
  );

  return (
    <ErrorBoundary>
      <SuspenseLoader>
        {team ? (
          <TeamAdministration
            key={teamId}
            company={company}
            team={team}
            onTeamDelete={handleTeamDeleteOverride}
            onTeamUpdate={handleTeamUpdate}
            onCreateTeamInvitation={handleCreateTeamInvitation}
            onRemoveTeamInvitation={handleRemoveTeamInvitation}
            onLeaveTeam={handleLeaveTeam}
            onAddSelfToTeam={handleAddSelfToTeam}
            onRemoveTeamMember={handleRemoveMemberFromTeam}
          />
        ) : null}
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
