import { constructUrl } from '@fcg-tech/regtech-api-utils';
import { TabBarPanel, useTabLinkState } from '@fcg-tech/regtech-components';
import { FunctionComponent, useEffect, useMemo } from 'react';
import {
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { FeedArticleTableContextProvider } from '../../components/FeedArticleTable/FeedArticleTableContext';
import { getTableTypeByPath } from '../../components/FeedArticleTable/feedArticleTableHelpers';
import { SuspenseLoader } from '../../components/SuspensLoader';
import { TeamToolbar } from '../../components/TeamToolbar/TeamToolbar';
import { routeFragments, routes } from '../../routes';
import { getFeedArticleDomain } from '../../utils/misc';
import { TeamOutletWrapper } from './TeamOutlet.styles';

export const TeamOutlet: FunctionComponent = () => {
  const tabState = useTabLinkState();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ teamId: string; companyId?: string }>();
  const { tableType, tableSubType } = useMemo(
    () => getTableTypeByPath(location.pathname) ?? {},
    [location.pathname],
  );

  const domain = useMemo(
    () => getFeedArticleDomain(params.companyId, params.teamId),
    [params.companyId, params.teamId],
  );

  useEffect(() => {
    if (matchPath(routeFragments.teamBase, location.pathname)) {
      navigate(
        constructUrl(routes.teamSubscriptions, { teamId: params.teamId }),
      );
    }
  }, [location.pathname, navigate, params.teamId]);

  const content = (
    <>
      <ErrorBoundary silentAuthError>
        <TeamToolbar tabState={tabState} />
      </ErrorBoundary>
      <TeamOutletWrapper>
        <ErrorBoundary silentAuthError>
          <SuspenseLoader>
            <TabBarPanel
              state={tabState}
              tabId={tabState.selectedId || undefined}
              className="tab-panel"
            >
              <Outlet />
            </TabBarPanel>
          </SuspenseLoader>
        </ErrorBoundary>
      </TeamOutletWrapper>
    </>
  );

  return tableType ? (
    <FeedArticleTableContextProvider
      key={`${tableType}-${tableSubType}-${domain}`}
      tableType={tableType}
      tableSubType={tableSubType}
      domain={domain}
      teamId={params.teamId}
      companyId={params.companyId}
    >
      {content}
    </FeedArticleTableContextProvider>
  ) : (
    content
  );
};
