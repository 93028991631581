import deepmerge from 'deepmerge';
import { TeamActions } from '@fcg-tech/regtech-types/regeye';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { useMatch } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { useArticleMetadataForTeam, useSettings } from '../../api/hooks';
import { TeamReadMetadata } from '../../api/schema';
import { SLOW_FOCUS_REVALIDATE_INTERVAL } from '../../constants';
import { routes } from '../../routes';
import { AccessControl } from '../AccessControl';
import { portalRoot } from '../Portal';
import { useTeamContext } from '../TeamContext/TeamContext';
import {
  compactTheme,
  FeedArticleTeamMetadataContentColumn,
  FeedArticleTeamMetadataContentSection,
  FeedArticleTeamMetadataContentWrapper,
} from './FeedArticleTeamMetadata.styles';
import { FeedArticleTeamMetadataActivities } from './FeedArticleTeamMetadataActivities';
import { FeedArticleTeamMetadataAssignees } from './FeedArticleTeamMetadataAssignees';
import { FeedArticleTeamMetadataAttachments } from './FeedArticleTeamMetadataAttachments';
import { FeedArticleTeamMetadataComments } from './FeedArticleTeamMetadataComments';
import { FeedArticleTeamMetadataDueDate } from './FeedArticleTeamMetadataDueDate';
import { FeedArticleTeamMetadataImportance } from './FeedArticleTeamMetadataImportance';
import { FeedArticleTeamMetadataTags } from './FeedArticleTeamMetadataTags';
import { RichTextAreaProps } from '@fcg-tech/regtech-richtext';

export interface FeedArticleTeamMetadataContentProps
  extends Pick<RichTextAreaProps, 'portalContainer'> {
  articleId: string;
  teamReadMetadata: TeamReadMetadata;
  initiallyExpanded?: boolean;
  disabled?: boolean;
  onTeamTagClick?: (tagId: string, teamId: string) => void;
  onArticleMetadataMutated?: (articleId: string) => void;
}

export const FeedArticleTeamMetadataContent: FunctionComponent<
  FeedArticleTeamMetadataContentProps
> = ({
  articleId,
  teamReadMetadata,
  initiallyExpanded,
  disabled,
  portalContainer,
  onTeamTagClick,
  onArticleMetadataMutated,
}) => {
  const defaultTheme = useTheme();
  const { data: settings } = useSettings({ suspense: false });
  const theme = useMemo(
    () => deepmerge(defaultTheme, compactTheme),
    [defaultTheme],
  );
  const isMutated = useRef<boolean>(false);
  const { teamId: contextTeamId } = useTeamContext();

  const { data: metadata } = useArticleMetadataForTeam(
    teamReadMetadata.team.id,
    articleId,
    {
      focusThrottleInterval: SLOW_FOCUS_REVALIDATE_INTERVAL,
    },
  );

  const isTeamActionsView = Boolean(useMatch(routes.teamActions));

  const handleMutated = useCallback(() => {
    onArticleMetadataMutated?.(articleId);
    isMutated.current = true;
  }, [articleId, onArticleMetadataMutated]);

  return (
    <ThemeProvider theme={theme}>
      <FeedArticleTeamMetadataContentWrapper>
        <FeedArticleTeamMetadataContentSection>
          <FeedArticleTeamMetadataContentColumn>
            <FeedArticleTeamMetadataTags
              articleId={articleId}
              metadata={metadata}
              teamReadMetadata={teamReadMetadata}
              contextTeamId={contextTeamId}
              disabled={disabled}
              isTeamActionsView={isTeamActionsView}
              portalRoot={portalRoot}
              onMutated={handleMutated}
              onTeamTagClick={onTeamTagClick}
            />
            <FeedArticleTeamMetadataAssignees
              settings={settings}
              articleId={articleId}
              metadata={metadata}
              teamReadMetadata={teamReadMetadata}
              disabled={disabled}
              portalRoot={portalRoot}
              onMutated={handleMutated}
            />
            <FeedArticleTeamMetadataImportance
              articleId={articleId}
              teamId={teamReadMetadata.team.id}
              metadata={metadata}
              disabled={disabled}
              onMutated={handleMutated}
            />
            <FeedArticleTeamMetadataDueDate
              articleId={articleId}
              teamId={teamReadMetadata.team.id}
              metadata={metadata}
              disabled={disabled}
              onMutated={handleMutated}
            />
            <FeedArticleTeamMetadataAttachments
              articleId={articleId}
              teamId={teamReadMetadata.team.id}
              metadata={metadata}
              disabled={disabled}
              onMutated={handleMutated}
            />
          </FeedArticleTeamMetadataContentColumn>
        </FeedArticleTeamMetadataContentSection>
        <AccessControl requiredPermissions={TeamActions.ArticleCommentGet}>
          <FeedArticleTeamMetadataContentSection>
            <FeedArticleTeamMetadataContentColumn>
              <FeedArticleTeamMetadataComments
                articleId={articleId}
                team={teamReadMetadata.team}
                metadata={metadata}
                disabled={disabled}
                portalContainer={portalContainer}
              />
            </FeedArticleTeamMetadataContentColumn>
          </FeedArticleTeamMetadataContentSection>
        </AccessControl>
        <AccessControl
          requiredPermissions={TeamActions.GetTeamActivityLogForArticle}
        >
          <FeedArticleTeamMetadataContentSection>
            <FeedArticleTeamMetadataContentColumn>
              <FeedArticleTeamMetadataActivities
                articleId={articleId}
                teamId={teamReadMetadata.team.id}
                initiallyExpanded={initiallyExpanded}
              />
            </FeedArticleTeamMetadataContentColumn>
          </FeedArticleTeamMetadataContentSection>
        </AccessControl>
      </FeedArticleTeamMetadataContentWrapper>
    </ThemeProvider>
  );
};
