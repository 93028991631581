import {
  H3,
  Modal,
  ModalBody,
  ModalPage,
  ProgressList,
} from '@fcg-tech/regtech-components';
import styled from 'styled-components';
import { ModalForm } from '../modals';

export const SetupWizardModalWrapper = styled(Modal)`
  width: 50rem;
  height: 50rem;
`;

export const SetupWizardModalPage = styled(ModalPage)`
  & > ${H3} {
    margin-bottom: 4rem;
  }

  &.center > ${ModalBody} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > ${ModalBody} {
    padding-bottom: 4rem;
  }
`;

export const SetupWizardModalFormWrapper = styled(ModalForm)`
  width: 100%;
  min-width: initial;
  overflow: visible;
  height: 100%;
`;

export const NrOfSubscribedArticleTypes = styled.span`
  font-size: 1.2rem;
  opacity: 0.8;
`;

export const AdminUserSetupWizardProgress = styled(ProgressList)`
  width: 28rem;
`;

export const AdminUserSetupWizardResultIconWrapper = styled.div`
  margin-bottom: 5rem;
`;

export const InvitationsSetupWizardItemWrapper = styled.div`
  padding: 1.6rem;
  border: 1px solid #ddd;

  & + & {
    margin-top: 2rem;
  }
`;
