import { Translator } from '@fcg-tech/regtech-types';
import { RecentTeamActivity, User } from '@fcg-tech/regtech-types/regeye';
import { format } from 'date-fns';
import { Publisher } from '../api/schema';
import { MessageKeys } from '../translations/translationTypes';
import { NameOrder, Settings } from '../types';
import { ImportanceMessageKeyMap } from './translationMaps';

export const formatName = (
  user: User,
  preference: NameOrder = NameOrder.Alias,
) => {
  if (!user) {
    return '';
  }
  switch (preference) {
    case NameOrder.Alias:
      return user.alias ?? user.username ?? 'N/A';
    case NameOrder.Email:
      return user.username ?? user.alias ?? 'N/A';
  }
};

export const formatDate = (date: Date | string, settings: Settings) => {
  if (!date) {
    return '';
  }
  try {
    return format(
      typeof date === 'string' ? new Date(date) : date,
      settings?.dateFormat ?? 'yyyy-MM-dd',
    );
  } catch (err) {
    console.error(err);
    return 'N/A';
  }
};

export const formatDateTime = (date: Date | string, settings: Settings) => {
  if (!date) {
    return '';
  }
  try {
    return format(
      typeof date === 'string' ? new Date(date) : date,
      settings?.dateTimeformat ?? 'yyyy-MM-dd',
    );
  } catch (err) {
    console.error(err);
    return 'N/A';
  }
};

export const formatTeamActivityAction = (
  recentTeamActivity: RecentTeamActivity,
  t: Translator,
  settings: Settings,
): string => {
  if (!recentTeamActivity) {
    return null;
  }

  const createdByUser = formatName(
    recentTeamActivity.createdByUser,
    settings?.userNamePreference,
  );
  const targetUser = formatName(
    recentTeamActivity.targetUser,
    settings?.userNamePreference,
  );

  switch (recentTeamActivity.action) {
    case 'ASSIGNED':
    case 'UNASSIGNED':
      return t(
        recentTeamActivity.action === 'ASSIGNED'
          ? MessageKeys.TeamActivityDescriptionAssigned
          : MessageKeys.TeamActivityDescriptionUnAssigned,
        { name: createdByUser, targetName: targetUser },
      );
    case 'ARCHIVED':
    case 'UNARCHIVED':
      return t(
        recentTeamActivity.action === 'ARCHIVED'
          ? MessageKeys.TeamActivityDescriptionArchived
          : MessageKeys.TeamActivityDescriptionUnarchived,
        { name: createdByUser },
      );

    case 'COMMENTED':
      return t(MessageKeys.TeamActivityDescriptionCommented, {
        name: createdByUser,
        comment: recentTeamActivity.value,
      });

    case 'EDITED COMMENT':
      return t(MessageKeys.TeamActivityDescriptionUpdatedComment, {
        name: createdByUser,
        comment: recentTeamActivity.value,
      });
    case 'REMOVED COMMENT':
      return t(MessageKeys.TeamActivityDescriptionRemovedComment, {
        name: createdByUser,
        comment: recentTeamActivity.value,
      });
    case 'TAGGED':
      return t(MessageKeys.TeamActivityDescriptionTagged, {
        name: createdByUser,
        tag: recentTeamActivity.value,
      });
    case 'UNTAGGED':
      return t(MessageKeys.TeamActivityDescriptionUntagged, {
        name: createdByUser,
        tag: recentTeamActivity.value,
      });
    case 'SET DUE DATE':
      return t(MessageKeys.TeamActivityDescriptionSetDueDate, {
        name: createdByUser,
        date: formatDate(recentTeamActivity.value, settings),
      });
    case 'UPDATED DUE DATE':
      return t(MessageKeys.TeamActivityDescriptionUpdateDueDate, {
        name: createdByUser,
        date: formatDate(recentTeamActivity.value, settings),
        previousDate: recentTeamActivity.targetValue
          ? formatDate(recentTeamActivity.targetValue, settings)
          : 'N/A',
      });
    case 'REMOVED DUE DATE':
      return t(MessageKeys.TeamActivityDescriptionRemovedDueDate, {
        name: createdByUser,
        date: formatDate(recentTeamActivity.value, settings),
      });
    case 'SET IMPORTANCE':
      return t(MessageKeys.TeamActivityDescriptionSetImportance, {
        name: createdByUser,
        importance: t(
          ImportanceMessageKeyMap[Number(recentTeamActivity.value)],
        ),
      });
    case 'REMOVED IMPORTANCE':
      return t(MessageKeys.TeamActivityDescriptionRemovedImportance, {
        name: createdByUser,
        importance: t(
          ImportanceMessageKeyMap[Number(recentTeamActivity.value)],
        ),
      });
    case 'UPDATED IMPORTANCE':
      return t(MessageKeys.TeamActivityDescriptionUpdatedImportance, {
        name: createdByUser,
        previousImportance: recentTeamActivity.targetValue
          ? t(ImportanceMessageKeyMap[Number(recentTeamActivity.targetValue)])
          : 'N/A',

        importance: t(
          ImportanceMessageKeyMap[Number(recentTeamActivity.value)],
        ),
      });

    case 'ATTACHED':
      return t(MessageKeys.TeamActivityDescriptionAttachmentAdded, {
        name: createdByUser,
        attachmentName: recentTeamActivity.value,
      });
    case 'UNATTACHED':
      return t(MessageKeys.TeamActivityDescriptionAttachmentRemoved, {
        name: createdByUser,
        attachmentName: recentTeamActivity.value,
      });
    case 'NOTIFIED':
      return t(MessageKeys.TeamActivityDescriptionNotified, {
        name: createdByUser,
      });
    case 'UNCOMPLETED ASSIGNED':
      return t(MessageKeys.TeamActivityDescriptionUncompletedAssigned, {
        name: createdByUser,
      });
    case 'COMPLETED ASSIGNED':
      return t(MessageKeys.TeamActivityDescriptionCompletedAssigned, {
        name: createdByUser,
      });

    default:
      return recentTeamActivity.action;
  }
};

export const formatPublisherName = (publisher: Publisher, maxChars = 70) => {
  const label =
    (publisher.name.length > maxChars
      ? publisher.abbreviation
      : `${publisher.name} ${
          publisher.abbreviation ? `(${publisher.abbreviation})` : ''
        }`) ?? publisher.name;

  return label;
};
