import { Article } from '@fcg-tech/regtech-types/regeye';
import { convertTag } from './tagConverter';

export const convertArticle = (article: Article): Article => {
  if (!article) {
    return null;
  }
  const { publicationDate, articleTypes, articleTags, ...rest } = article;

  return {
    ...rest,
    publicationDate: publicationDate ? new Date(publicationDate) : undefined,
    articleTags: articleTags.map((tag) => convertTag(tag)),
    articleTypes: articleTypes.map((tag) => convertTag(tag)),
  };
};
