import { Maybe } from '@fcg-tech/regtech-types';
import { Company } from '@fcg-tech/regtech-types/regeye';
import update from 'immutability-helper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import { MessageKeys } from '../../translations/translationTypes';

import { checkConflict, useApi } from '../apiUtils';
import {
  CompaniesApi,
  CompanyActions,
  CompanyInput,
  CompanyUpdateInput,
  Team,
} from '../schema';
import {
  allTeamsKey,
  assignedToMeCount,
  companiesKey,
  companyKey,
  companyTeamsKey,
  initDataKey,
  teamKey,
} from './cacheKeys';
import {
  reloadCompanyTeamInvitations,
  reloadInvitations,
} from './invitationsApiHooks';

export const getCompanies = async (
  api: CompaniesApi,
): Promise<Array<Company>> => {
  const result = await api.listCompanies();
  try {
    if (
      process.env.NODE_ENV === 'development' &&
      localStorage.getItem('debug') &&
      localStorage.getItem('omitPermissions')
    ) {
      const omits = JSON.parse(
        localStorage.getItem('omitPermissions'),
      ) as Array<CompanyActions>;
      result.items.forEach(
        (company) =>
          (company.companyActions = company.companyActions.filter(
            (a) => !omits.includes(a),
          )),
      );
    }
  } catch (err) {
    // NOOP
  }
  return result.items;
};

export const useCompanies = (
  disable?: boolean,
  config: SWRConfiguration = {},
) => {
  const api = useApi<CompaniesApi>('CompaniesApi');

  return useSWR<Array<Company>>(
    disable ? null : companiesKey(),
    async () => {
      return getCompanies(api);
    },
    {
      suspense: true,
      ...config,
    },
  );
};

export const getCompany = async (
  companyId: string,
  api: CompaniesApi,
): Promise<Company> => {
  const result = await api.getCompany({ companyId });
  return result;
};

export const useCompany = (
  companyId?: string,
  config: SWRConfiguration = {},
) => {
  const api = useApi<CompaniesApi>('CompaniesApi');

  return useSWR<Company>(
    companyId ? companyKey(companyId) : null,
    async () => {
      return getCompany(companyId, api);
    },

    {
      suspense: true,
      ...config,
    },
  );
};

export const getCompanyTeams = async (
  companyId: string,
  api: CompaniesApi,
): Promise<Array<Team>> => {
  const result = await api.companyTeamList({ companyId });
  return result.items;
};

export const useCompanyTeams = (
  companyId: Maybe<string>,
  config: SWRConfiguration = {},
) => {
  const api = useApi<CompaniesApi>('CompaniesApi');

  return useSWR<Array<Team>>(
    companyId ? companyTeamsKey(companyId) : null,
    () => {
      return getCompanyTeams(companyId, api);
    },
    {
      suspense: true,
      ...config,
    },
  );
};

export const getCompanyTeam = async (
  companyId: string,
  teamId: string,
  api: CompaniesApi,
): Promise<Team> => {
  try {
    const result = await api.companyTeamGet({ companyId, teamId });
    return result.team;
  } catch (errorResponse) {
    if (errorResponse.status === 404 || errorResponse.status === 409) {
      return null;
    }
    throw errorResponse;
  }
};

export const useCompanyTeam = (
  companyId: string,
  teamId: string,
  config: SWRConfiguration = {},
) => {
  const api = useApi<CompaniesApi>('CompaniesApi');
  return useSWR<Team>(
    teamId ? teamKey(teamId) : null,
    () => getCompanyTeam(companyId, teamId, api),
    {
      suspense: true,
      ...config,
    },
  );
};

export const useCompanyActions = () => {
  const api = useApi<CompaniesApi>('CompaniesApi');
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  return useMemo(
    () => ({
      createTeam: async (companyId: string, teamName: string) => {
        const result = await api.companyTeamCreate({
          companyId,
          teamInput: { teamName },
        });

        mutate(allTeamsKey());

        return result.team;
      },

      updateTeam: async (
        companyId: string,
        teamId: string,
        data: Pick<Team, 'name'>,
      ) => {
        await api.companyTeamUpdate({
          companyId,
          teamId,
          teamInput: { teamName: data.name },
        });

        mutate(allTeamsKey());
        mutate(teamKey(teamId));
        mutate(companyTeamsKey(companyId));
      },

      addSelfToTeam: async (companyId: string, teamId: string) => {
        await api.companyTeamAddUser({ companyId, teamId });

        mutate(teamKey(teamId));
        mutate(assignedToMeCount);
        mutate(allTeamsKey());
        mutate(initDataKey());
        reloadCompanyTeamInvitations(teamId, mutate);
        reloadInvitations(mutate);
      },

      removeMemberFromTeam: async (
        companyId: string,
        teamId: string,
        username: string,
      ) => {
        await api.companyTeamMemberDelete({
          companyId,
          teamId,
          userReference: { username },
        });

        // Update cache for single team
        mutate(teamKey(teamId), (old: Team) => {
          const index = old?.members.findIndex((m) => m.username === username);
          if (old && index >= 0) {
            return update(old, { members: { $splice: [[index, 1]] } });
          }
          return old;
        });

        // Update cache for all teams
        mutate(allTeamsKey(), (old: Array<Team>) => {
          return old.map((team) => {
            const index = team.members.findIndex(
              (m) => m.username === username,
            );
            if (index >= 0) {
              return update(team, { members: { $splice: [[index, 1]] } });
            }
            return team;
          });
        });
      },

      createCompany: async (companyInput: CompanyInput) => {
        try {
          const company = await api.createCompany({ companyInput });
          mutate(companiesKey());
          return company;
        } catch (err) {
          checkConflict(
            err,
            t(MessageKeys.AdministrationCompanyStringIdConflictErrorMessage),
          );
        }
      },

      updateCompany: async (
        companyId: string,
        companyUpdateInput: CompanyUpdateInput,
      ) => {
        try {
          await api.updateCompany({ companyId, companyUpdateInput });
          mutate(companiesKey());
          mutate(companyKey(companyId));
        } catch (err) {
          checkConflict(
            err,
            t(MessageKeys.AdministrationCompanyStringIdConflictErrorMessage),
          );
        }
      },

      updateCompanyElevated: async (
        companyId: string,
        companyInput: CompanyInput,
      ) => {
        try {
          await api.updateCompanyElevated({ companyId, companyInput });
          mutate(companiesKey());
          mutate(companyKey(companyId));
        } catch (err) {
          checkConflict(
            err,
            t(MessageKeys.AdministrationCompanyStringIdConflictErrorMessage),
          );
        }
      },

      deleteTeam: async (companyId: string, teamId: string) => {
        await api.companyTeamDelete({ companyId, teamId });
        mutate(allTeamsKey());
        mutate(
          companyTeamsKey(companyId),
          (old: Array<Team>) => {
            const index = old.findIndex((t) => t.id === teamId);
            if (index >= 0) {
              return update(old, { $splice: [[index, 1]] });
            }
            return old;
          },
          true,
        );
      },

      deleteCompany: async (companyId: string) => {
        await api.companyDelete({ companyId });
        mutate(companiesKey());
        mutate(allTeamsKey());
      },
    }),
    [api, mutate, t],
  );
};
