import { EmptyPropsWithChildren } from '@fcg-tech/regtech-types';
import {
  FormRow,
  H3,
  InputCheckbox,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  TextField,
  useInput,
  useToggle,
} from '@fcg-tech/regtech-components';
import { byId, useEscapeKeyOld, useHotkeysOld } from '@fcg-tech/regtech-utils';
import update from 'immutability-helper';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useAllTeams, useCompanies } from '../../api/hooks';
import {
  Company,
  CompanyActions,
  GlobalActions,
  Permissions,
  Team,
  TeamActions,
} from '../../types';
import { IamContext, IamContextProps, useIamContext } from '../IamContext';
import { DevModal, PermissionName, PermissionRow } from './DevMode.styles';

let lastFilter = '';

interface PermissionSelectorProps {
  permissions: Partial<IamContextProps>;
  onChange: (
    action: Permissions,
    enabled: boolean,
    opts?: { teamId?: string; companyId?: string },
  ) => void;
  onRequestClose: () => void;
  onSetInitialIamContextValues: () => void;
  onEnableAllPermissions: () => void;
  onDisableAllPermissions: () => void;
}
const PermissionSelector: FunctionComponent<PermissionSelectorProps> = ({
  permissions,
  onChange,
  onRequestClose,
  onSetInitialIamContextValues,
  onEnableAllPermissions,
  onDisableAllPermissions,
}) => {
  const { data: teams } = useAllTeams();
  const { data: companies } = useCompanies();

  const [filter, onFilterChange] = useInput(lastFilter);

  lastFilter = filter;

  const globalPermChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.value as Permissions, event.target.checked),
    [onChange],
  );

  const teamOrCompanyPermChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.value as Permissions, event.target.checked, {
        teamId: event.target.getAttribute('data-teamid'),
        companyId: event.target.getAttribute('data-companyid'),
      }),
    [onChange],
  );

  const filterRe = useMemo(
    () => (filter.length ? new RegExp(filter, 'i') : null),
    [filter],
  );

  const filterFn = useCallback(
    (str: string) => {
      if (!filter.length) {
        return true;
      }

      return Boolean(str.match(filterRe));
    },
    [filter.length, filterRe],
  );

  return (
    <DevModal onHide={onRequestClose}>
      <ModalHeader>
        <ModalTitle>Set permissions</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormRow>
          <PrimaryButton onClick={onSetInitialIamContextValues}>
            Set initial(loaded) permissions
          </PrimaryButton>

          <PrimaryButton onClick={onEnableAllPermissions}>
            Enable all permissions
          </PrimaryButton>

          <PrimaryButton onClick={onDisableAllPermissions}>
            Disable all permissions
          </PrimaryButton>
        </FormRow>
        <FormRow>
          <TextField
            placeholder="Filter permissions"
            value={filter}
            selectAllOnFocus
            autoFocus
            onChange={onFilterChange}
          />
        </FormRow>

        <H3>Global permissions</H3>
        <div>
          {Object.values(GlobalActions)
            .filter(filterFn)
            .map((action) => (
              <PermissionRow key={action}>
                <InputCheckbox
                  value={action}
                  checked={permissions.globalPermissions?.includes(action)}
                  onChange={globalPermChange}
                />
                <PermissionName>{action}</PermissionName>
              </PermissionRow>
            ))}
        </div>
        {Object.entries(permissions.companyPermissions).map(([key, perms]) => (
          <React.Fragment key={key}>
            <H3>Company '{byId(companies, key)?.name ?? key}' permissions</H3>
            <div>
              {Object.values(CompanyActions)
                .filter(filterFn)
                .map((action) => (
                  <PermissionRow key={action}>
                    <InputCheckbox
                      value={action}
                      data-companyid={key}
                      checked={perms.includes(action)}
                      onChange={teamOrCompanyPermChange}
                    />
                    <PermissionName>{action}</PermissionName>
                  </PermissionRow>
                ))}
            </div>
          </React.Fragment>
        ))}
        {Object.entries(permissions.teamPermissions).map(([key, perms]) => (
          <React.Fragment key={key}>
            <H3>Team '{byId(teams, key)?.name ?? key}' permissions</H3>
            <div>
              {Object.values(TeamActions)
                .filter(filterFn)
                .map((action) => (
                  <PermissionRow key={action}>
                    <InputCheckbox
                      value={action}
                      data-teamid={key}
                      checked={perms.includes(action)}
                      onChange={teamOrCompanyPermChange}
                    />
                    <PermissionName>{action}</PermissionName>
                  </PermissionRow>
                ))}
            </div>
          </React.Fragment>
        ))}
      </ModalBody>
    </DevModal>
  );
};

export const DevPermissionSelector: FunctionComponent<EmptyPropsWithChildren> = ({ children }) => {
  const [showSelector, setShowSelector, toggleShowSelector] = useToggle(false);
  const { data: teams } = useAllTeams();
  const { data: companies } = useCompanies();
  const defaultIamContext = useIamContext();

  const iamContextValue = useMemo<IamContextProps>(() => {
    const value: IamContextProps = {
      globalPermissions: Object.values(GlobalActions),
      teamPermissions:
        teams?.reduce((obj, team) => {
          obj[team.id] = Object.values(TeamActions);
          return obj;
        }, {}) ?? {},
      companyPermissions:
        companies?.reduce((obj, team) => {
          obj[team.id] = Object.values(CompanyActions);
          return obj;
        }, {}) ?? {},
      updateTeamPermissions: () => null,
    };

    return value;
  }, [companies, teams]);

  const [permissions, setPermissions] = useState(iamContextValue);
  const handlePermChange = useCallback(
    (
      action: Permissions,
      enabled: boolean,
      opts?: { teamId?: string; companyId?: string },
    ) => {
      if (opts?.teamId) {
        setPermissions((old) => {
          return update(old, {
            teamPermissions: {
              [opts.teamId]: enabled
                ? { $push: [action as TeamActions] }
                : {
                    $splice: [
                      [
                        old.teamPermissions[opts.teamId].indexOf(
                          action as TeamActions,
                        ),
                        1,
                      ],
                    ],
                  },
            },
          });
        });
      } else if (opts?.companyId) {
        setPermissions((old) => {
          return update(old, {
            companyPermissions: {
              [opts.companyId]: enabled
                ? { $push: [action as CompanyActions] }
                : {
                    $splice: [
                      [
                        old.companyPermissions[opts.companyId].indexOf(
                          action as CompanyActions,
                        ),
                        1,
                      ],
                    ],
                  },
            },
          });
        });
      } else {
        setPermissions((old) => {
          return update(old, {
            globalPermissions: enabled
              ? { $push: [action as GlobalActions] }
              : {
                  $splice: [
                    [old.globalPermissions.indexOf(action as GlobalActions), 1],
                  ],
                },
          });
        });
      }
    },
    [],
  );

  useHotkeysOld('P', toggleShowSelector, { modifier: ['ctrlKey', 'shiftKey'] });

  const handleSetInitialIamContextValues = useCallback(
    () =>
      setPermissions({
        ...iamContextValue,
        globalPermissions: defaultIamContext.globalPermissions,
        teamPermissions: defaultIamContext.teamPermissions,
        companyPermissions: defaultIamContext.companyPermissions,
      }),
    [
      defaultIamContext.companyPermissions,
      defaultIamContext.globalPermissions,
      defaultIamContext.teamPermissions,
      iamContextValue,
    ],
  );

  const handleEnableAllPermissions = useCallback(
    () =>
      setPermissions({
        ...iamContextValue,
        ...enableAllPermissions(teams, companies),
      }),
    [companies, iamContextValue, teams],
  );

  const handleDisableAllPermissions = useCallback(
    () =>
      setPermissions({
        ...iamContextValue,
        ...disableAllPermissions(teams, companies),
      }),
    [companies, iamContextValue, teams],
  );

  return (
    <React.Fragment>
      {showSelector ? (
        <PermissionSelector
          permissions={permissions}
          onChange={handlePermChange}
          onRequestClose={toggleShowSelector}
          onSetInitialIamContextValues={handleSetInitialIamContextValues}
          onEnableAllPermissions={handleEnableAllPermissions}
          onDisableAllPermissions={handleDisableAllPermissions}
        />
      ) : null}
      <IamContext.Provider value={permissions}>{children}</IamContext.Provider>
    </React.Fragment>
  );
};

const enableAllPermissions = (
  teams: Array<Team>,
  companies: Array<Company>,
) => {
  const value: Partial<IamContextProps> = {
    globalPermissions: Object.values(GlobalActions),
    teamPermissions:
      teams?.reduce((obj, team) => {
        obj[team.id] = Object.values(TeamActions);
        return obj;
      }, {}) ?? {},
    companyPermissions:
      companies?.reduce((obj, team) => {
        obj[team.id] = Object.values(CompanyActions);
        return obj;
      }, {}) ?? {},
  };

  return value;
};

const disableAllPermissions = (
  teams: Array<Team>,
  companies: Array<Company>,
) => {
  const value: Partial<IamContextProps> = {
    globalPermissions: [],
    teamPermissions:
      teams?.reduce((obj, team) => {
        obj[team.id] = [];
        return obj;
      }, {}) ?? {},
    companyPermissions:
      companies?.reduce((obj, team) => {
        obj[team.id] = [];
        return obj;
      }, {}) ?? {},
  };

  return value;
};
