import { useTranslation } from 'react-i18next';
import {
  BooleanTree,
  BooleanTreeItem,
  BooleanTreeItemValue,
} from '@fcg-tech/regtech-components';
import { Translator } from '@fcg-tech/regtech-types';
import update from 'immutability-helper';
import React, { FunctionComponent, useCallback } from 'react';
import { MessageKeys } from '../../translations/translationTypes';
import { FeedArticle, FeedArticleTableColumns } from '../../types';
import { DataTableColumn } from '@fcg-tech/regtech-datatable';
import { getColumnLabel } from '../FeedArticleTable/feedArticleTableHelpers';

interface ColumnSelectorProps {
  allowedColumns: Array<DataTableColumn<FeedArticle>>;
  selectedColumns: Array<FeedArticleTableColumns>;
  onChange: (selectedColumns: Array<FeedArticleTableColumns>) => void;
}

export const ColumnSelector: FunctionComponent<ColumnSelectorProps> = ({
  allowedColumns,
  selectedColumns,
  onChange,
}) => {
  const { t } = useTranslation();
  const handleChange = useCallback(
    (
      itemValues: BooleanTreeItemValue<FeedArticleTableColumns>,
      selected: boolean,
    ) => {
      const newSelectedColumns = selected
        ? update(selectedColumns, { $push: [itemValues.value] })
        : update(selectedColumns, {
            $splice: [[selectedColumns.indexOf(itemValues.value), 1]],
          });

      onChange(newSelectedColumns);
    },
    [onChange, selectedColumns],
  );

  const items: Array<BooleanTreeItem> = allowedColumns.map<BooleanTreeItem>(
    (column) => {
      return {
        id: column.id,
        value: column.id,
        label: getColumnLabel(column, t),
        selected: Boolean(
          selectedColumns?.includes(column.id as FeedArticleTableColumns),
        ),
      };
    },
    [],
  );

  return <BooleanTree items={items} onChange={handleChange} />;
};
