import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import regeye404 from '../../assets/regeye-404.svg';
import { MessageKeys } from '../../translations/translationTypes';
import {
  ErrorImage,
  ErrorInfo,
  ErrorPageWrapper,
  ErrorStatusHeading,
} from './ErrorMessage.styles';

interface Error408Props {
  response?: Response;
}

export const Error408: FunctionComponent<Error408Props> = () => {
  const { t } = useTranslation();
  return (
    <ErrorPageWrapper>
      <ErrorImage src={regeye404} />
      <ErrorStatusHeading>
        {t(MessageKeys.ErrorTimeoutHeading)}
      </ErrorStatusHeading>
      <ErrorInfo>
        {t(MessageKeys.ErrorTimeoutInfo)}
      </ErrorInfo>
    </ErrorPageWrapper>
  );
};
